"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.logError = exports.getAppVersion = void 0;
var _axios = require("axios");
const UI_SERVER_API = window.location.origin;
const getAppVersion = async () => {
  const {
    status,
    data
  } = await (0, _axios.get)("".concat(UI_SERVER_API, "/version"));
  if (status !== 200) throw new Error('Invalid status');
  return data;
};
exports.getAppVersion = getAppVersion;
const logError = async _ref => {
  let {
    username,
    error,
    href
  } = _ref;
  return (0, _axios.post)("".concat(UI_SERVER_API, "/errors/log"), {
    username,
    error,
    href
  });
};
exports.logError = logError;