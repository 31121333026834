import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  customChipClass: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  customGetOptionSelected: PropTypes.func,
  customLabelClass: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  filterChipColor: PropTypes.string,
  getOptionLabel: PropTypes.func,
  helperText: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isWithPaceholderLabel: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  labelClassName: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  limit: PropTypes.number,
  name: PropTypes.string,
  'ns-target': PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
      label: PropTypes.any,
      labelId: PropTypes.any,
      value: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  popupIconClass: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  shouldDisableClearable: PropTypes.bool,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
        label: PropTypes.any,
        labelId: PropTypes.any,
        value: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
      }),
    ),
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
      label: PropTypes.any,
      labelId: PropTypes.any,
      value: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
    }),
  ]),
} as any;
