/**
 * Starting point of application
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { vendorConfig, i18n } from '@northstar/core-ui';
import { MAFTracking, MAFGlobalStyle, MAFOauth2Provider, theme } from '@asfi/maf';
import { selectThemeSettings } from '@northstar/core-ui/modules/userProfile/userProfileSelectors';
import { selectCookieConsent } from '@northstar/core-ui/modules/app/appSelectors';
import { LanguageProvider, TranslationToolProvider } from '@northstar/core-ui/providers';
import { logUtils, propTypesUtils } from '@northstar/core-ui/utils';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';

import Routes from 'routes';

import { unregister } from './registerServiceWorker';
/**
 * Language support
 */
import { createStoreInstance } from './store';
import './polyfill';

propTypesUtils.initiateErrorThrower();
vendorConfig.setLocale();

const NorthstarRoot = ({ messages, isServiceUnavailable = false }) => {
  const themeSettings = useSelector(selectThemeSettings());
  const constructedTheme = theme.constructTheme(themeSettings);
  const themeCurrent = createTheme(constructedTheme);
  const cookieConsent = useSelector(selectCookieConsent());

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeCurrent}>
        <MAFGlobalStyle />
        <LanguageProvider messages={messages}>
          <TranslationToolProvider>
            <MAFOauth2Provider>
              <Routes isServiceUnavailable={isServiceUnavailable} />
            </MAFOauth2Provider>
          </TranslationToolProvider>
        </LanguageProvider>
        <MAFTracking cookieConsent={cookieConsent} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const render = (messages, isServiceUnavailable) => {
  ReactDOM.render(
    <Provider store={createStoreInstance()}>
      <NorthstarRoot messages={messages} isServiceUnavailable={isServiceUnavailable} />
    </Provider>,
    document.getElementById('root'),
  );
  unregister();
};

const renderWithIntl = async () => {
  if (!window.Intl) {
    await new Promise((resolve) => {
      resolve(import('intl'));
    });
    await Promise.all([
      import('intl/locale-data/jsonp/en.js'),
      import('intl/locale-data/jsonp/se.js'),
      import('intl/locale-data/jsonp/nn-NO.js'),
      import('intl/locale-data/jsonp/fi.js'),
    ]);
  }
};

const renderWithAvailabilityCheck = async () => {
  let isServiceUnavailable = false;

  try {
    await renderWithIntl();
  } catch (e) {
    isServiceUnavailable = true;
  }
  render(i18n.translationMessages, isServiceUnavailable);
};

renderWithAvailabilityCheck();

if (process.env.ENVIRONMENT !== 'production' && module.hot) {
  module.hot.accept('routes', () => {
    logUtils.log('Routes reloaded');
    renderWithAvailabilityCheck();
  });
}
