import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  buttonText: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
    .isRequired,
  children: PropTypes.node,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]).isRequired,
  loanAmount: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
    .isRequired,
  onClick: PropTypes.func.isRequired,
  purposeText: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
} as any;
