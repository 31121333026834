"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectSystemMessage = exports.selectIsLoadingSystemMessage = exports.selectActiveSystemMessage = void 0;
var _reselect = require("reselect");
const selectSupportState = state => state.support;
const selectActiveSystemMessage = () => (0, _reselect.createSelector)(selectSupportState, state => state.get('activeSystemMessage').toJS());
exports.selectActiveSystemMessage = selectActiveSystemMessage;
const selectSystemMessage = () => (0, _reselect.createSelector)(selectSupportState, state => state.get('systemMessage').toJS());
exports.selectSystemMessage = selectSystemMessage;
const selectIsLoadingSystemMessage = () => (0, _reselect.createSelector)(selectSupportState, state => state.get('isLoadingSystemMessage'));
exports.selectIsLoadingSystemMessage = selectIsLoadingSystemMessage;