import { logUtils, reduxUtils } from '@northstar/core-ui/utils';
import { routerMiddleware } from 'connected-react-router';
import createHistory from 'history/createBrowserHistory';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import appSaga from 'modules/app/appSaga';

import createGlobalReducer from './global-reducer';
import { globalSagas, caseManagementSagas } from './global-sagas';

export const history = createHistory();
const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  reduxUtils.reduxErrorMiddleware,
  // Middleware for intercepting and dispatching navigation actions
  routerMiddleware(history),
  sagaMiddleware,
];
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let appSagaTask;
let globalSagaTask;
let caseSagaTask;

const setupHotReload = (store) => {
  if (process.env.ENVIRONMENT !== 'production' && module.hot) {
    // Enable Webpack hot module replacement for reducers
    // This will keep any previous state
    module.hot.accept('./global-reducer', () => {
      logUtils.log('reset reducers');
      const nextRootReducer = createGlobalReducer();
      store.replaceReducer(nextRootReducer);
    });

    // Enable Webpack hot module replacement for sagas
    // This will reset any ongoing sagas
    module.hot.accept('./global-sagas', () => {
      logUtils.log('Reset sagas');
      appSagaTask.cancel();
      appSagaTask.done.then(() => {
        appSagaTask = sagaMiddleware.run(appSaga);
      });

      globalSagaTask.cancel();
      globalSagaTask.done.then(() => {
        globalSagaTask = sagaMiddleware.run(globalSagas);
      });
    });
  }
};

export const runSagas = () => {
  globalSagaTask = sagaMiddleware.run(globalSagas);
  caseSagaTask = sagaMiddleware.run(caseManagementSagas);
};
export const restartCaseManagementSagas = () => {
  caseSagaTask.cancel();
  caseSagaTask = sagaMiddleware.run(caseManagementSagas);
};

export const stopSagas = () => {
  globalSagaTask.cancel();
  caseSagaTask.cancel();
};

export const createStoreInstance = () => {
  const store = createStore(
    createGlobalReducer(history),
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  // We want to have appsaga running all the time
  appSagaTask = sagaMiddleware.run(appSaga);
  runSagas();
  setupHotReload(store);
  return store;
};
