"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: _ref => {
    let {
      spacing
    } = _ref;
    return {
      marginBottom: spacing(2)
    };
  }
};