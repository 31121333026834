import { SxProps, TableHead, Theme } from '@mui/material';

import { IMAFTableData, IMAFTableField, TMAFTableDirection } from '../MAFTable.types';

import MAFSortableCell from './MAFCell/MAFSortableCell';
import MAFTableRow from './MAFTableRow/MAFTableRow';
import { propTypes } from './MAFTableHeader.propTypes';

interface IMAFTableHeaderProps<T extends IMAFTableData> {
  onOrder?: (value: string) => void;
  orderBy?: string;
  direction?: TMAFTableDirection;
  labels: IMAFTableField<T>[];
  isUplift?: boolean;
  sx?: SxProps<Theme>;
  shouldAdjustHeaderPosition?: boolean;
}

function MAFTableHeader<T extends IMAFTableData>({
  onOrder,
  orderBy,
  direction,
  labels,
  isUplift,
  sx,
  shouldAdjustHeaderPosition,
}: IMAFTableHeaderProps<T>) {
  return (
    <TableHead>
      <MAFTableRow isHead isUplift={isUplift}>
        {labels.map(({ name, label, sortField, isUnsortable, sortTitle }) => (
          <MAFSortableCell
            key={name}
            direction={direction}
            name={name}
            onOrder={onOrder ? () => onOrder(sortField || name) : undefined}
            orderBy={orderBy}
            sortField={sortField}
            isUnsortable={isUnsortable}
            sx={sx}
            isUplift={isUplift}
            shouldAdjustHeaderPosition={shouldAdjustHeaderPosition}
            sortTitle={sortTitle}
          >
            {label}
          </MAFSortableCell>
        ))}
      </MAFTableRow>
    </TableHead>
  );
}

MAFTableHeader.propTypes = propTypes;

export default MAFTableHeader;
