import { CAR_CONFIGURATION_EQUIPMENT_FORM } from '@northstar/core-ui/modules';
import { getFormValues, isValid } from 'redux-form';
import { createSelector } from 'reselect';

import { selectCase } from '../caseDetail/caseDetailSelectors';

import {
  HP_FORM,
  HP_FORM_CAR_PRICE,
  HP_FORM_DOWN_PAYMENT,
  HP_FORM_TRADE_IN_CAR,
  HP_FORM_TRADE_IN_VALUE,
  HP_FORM_TRADE_IN_DEBT,
  HP_FORM_DURATION,
  HP_FORM_INTEREST_RATE,
} from './hirePurchaseConstants';
import { formMappers } from './hirePurchaseMapper';

const selectHirePurchaseState = (state) => state.hirePurchase;

export const selectHirePurchaseFormValues = () =>
  createSelector(getFormValues(HP_FORM), (formValues) => formValues);

const selectInterestRate = () =>
  createSelector(selectHirePurchaseFormValues(), (form) => {
    if (form) {
      const value = form[HP_FORM_INTEREST_RATE];
      const number = Number(value);
      return Number.isNaN(number) ? value : number;
    }
    return 0;
  });

const selectCalculationData = () =>
  createSelector(selectHirePurchaseState, (state) => state.get('calculationData').toJS());

export const selectMonthlyPayment = () =>
  createSelector(selectCalculationData(), (state) => state.quote);

export const selectCalculationNumbersProps = () =>
  createSelector(
    selectHirePurchaseFormValues(),
    selectCalculationData(),
    selectInterestRate(),
    (form, calculationData, interestRate) => {
      const {
        principalValue,
        futureValue,
        invoiceFee,
        documentFee,
        downPaymentTotal,
        downPaymentPercentage,
        maxResidualPercentage,
        aprPercentage,
        totalCarPrice,
        bulletPercentage,
      } = calculationData;
      return {
        carPrice: totalCarPrice || (form && form[HP_FORM_CAR_PRICE]),
        principalValue,
        downPayment: form && form[HP_FORM_DOWN_PAYMENT],
        tradeinCar: form && form[HP_FORM_TRADE_IN_CAR],
        tradeinCarValue: form && form[HP_FORM_TRADE_IN_VALUE],
        tradeinCarDebt: form && form[HP_FORM_TRADE_IN_DEBT],
        duration: form && form[HP_FORM_DURATION],
        futureValue,
        interestRate,
        startupFee: documentFee,
        invoiceFee,
        downPaymentTotal,
        downPaymentPercentage,
        maxResidualPercentage,
        aprPercentage,
        bulletPercentage,
      };
    },
  );

export const selectCalculatingHP = () =>
  createSelector(selectHirePurchaseState, (state) => state.get('calculating'));

export const selectHirePurchaseRateInitialValues = () =>
  createSelector(selectHirePurchaseState, (state) => state.get('initialValues').toJS());

export const selectIsHpFormsValid = () =>
  createSelector(
    isValid(HP_FORM),
    isValid(CAR_CONFIGURATION_EQUIPMENT_FORM),
    (isHpFormValid, isEquipmentFormValid) => isHpFormValid && isEquipmentFormValid,
  );

export const selectInitialValues = () =>
  createSelector(selectCase(), (currentCase) => {
    const {
      asset: {
        leasePeriod,
        vendorTermsId,
        detailsHP: { tradeInCarRegistrationNumber, quoteDetails = {} } = {},
      } = {},
    } = currentCase;
    if (quoteDetails.includeVatForAsset !== undefined && quoteDetails.includeVatForAsset !== null) {
      return formMappers.mapQuoteToForm({
        ...quoteDetails,
        tradeInCarRegistrationNumber,
        leasePeriod,
        vendorTermsId,
      });
    }
  });
