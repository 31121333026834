import { Grid } from '@mui/material';
import { FC, ComponentType, ReactChild } from 'react';

import { useScreenSize } from '../../utils';
import { TMAFTextFieldOnChangeEvent } from '../MAFTextField';
import MAFNumberField from '../MAFNumberField/MAFNumberField';
import { IMAFSliderProps } from '../MAFSlider/MAFSlider';

import { styles } from './MAFSliderInput.styles';
import { propTypes } from './MAFSliderInput.propTypes';

export interface IMAFSliderInputProps extends IMAFSliderProps {
  Component: ComponentType<IMAFSliderProps>;
  label?: ReactChild;
  value: number;
  custom?: ReactChild;
  name?: string;
  isFullWidth?: boolean;
  isPaddedTop?: boolean;
  'data-testid'?: string;
}

const MAFSliderInput: FC<IMAFSliderInputProps> = ({
  Component,
  label,
  value,
  custom,
  name,
  isFullWidth,
  isPaddedTop = false,
  'data-testid': dataTestid,
  onChange,
  onChangeCommitted,
  ...sliderProps
}) => {
  const { isDesktop } = useScreenSize();
  const handleOnChange = (e: TMAFTextFieldOnChangeEvent) => {
    onChange && onChange(Number(e.target.value));
    onChangeCommitted();
  };
  const inputProps = {
    label,
    value,
    custom,
    name,
    onChange: handleOnChange,
    disabled: sliderProps.disabled,
    fullWidth: isFullWidth,
  };

  const numberFieldProps = !isDesktop && {
    isError: sliderProps.isError,
    helperText: sliderProps.helperText,
  };

  return (
    <Grid container alignItems="flex-start" justifyContent="center" data-testid={dataTestid}>
      <Grid item xs={isDesktop ? 2 : 12} sx={[styles.field, isPaddedTop && styles.input]}>
        <MAFNumberField {...inputProps} {...numberFieldProps} />
      </Grid>
      {isDesktop && (
        <Grid item xs={10}>
          <Component
            value={value}
            onChange={onChange}
            onChangeCommitted={onChangeCommitted}
            {...sliderProps}
          />
        </Grid>
      )}
    </Grid>
  );
};

MAFSliderInput.propTypes = propTypes;

export default MAFSliderInput;
