import { useState, useRef, useEffect, FC, SetStateAction } from 'react';

import { TMAFTextFieldOnChangeEvent, TMAFTextFieldOnFocusEvent } from '../MAFTextField';
import MAFTextFieldWithIcon, {
  IMAFTextFieldWithIconProps,
} from '../MAFTextFieldWithIcon/MAFTextFieldWithIcon';

import { propTypes } from './MAFTextFieldMasked.propTypes';

interface IMAFTexFieldMaskedGetCleanValueParams {
  strippedTargetValue: string;
  strippedPlaceholder: string;
}

export interface IMAFTextFieldMaskedProps extends IMAFTextFieldWithIconProps {
  maskFormat: string;
  specialCharPattern: RegExp;
  onChangeValidate?: (value: string) => boolean;
  shouldAutofillOnBlur?: boolean;
  value?: string;
  placeholder: string;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const MAFTextFieldMasked: FC<IMAFTextFieldMaskedProps> = ({
  onChange,
  onBlur = () => {},
  maskFormat,
  value,
  placeholder,
  icon,
  specialCharPattern,
  inputRef: ref,
  onChangeValidate,
  shouldAutofillOnBlur = false,
  ...rest
}) => {
  const inputRef = ref || useRef();
  const [valueWithSpecialCharacters, setValueWithSpecialCharacters] = useState('');
  const [adjustedValue, setAdjustedValue] = useState('');
  const [selectionPosition, setSelectionPosition] = useState(null);

  const getAdjustedValue = ({ cleanValue }: { cleanValue: string }) => {
    let indexAdjustment = 0;
    const withSpecialCharacters = cleanValue.split('').reduce((result, val, index) => {
      if (maskFormat[index + indexAdjustment] !== '#') {
        let tempValue = '';
        while (maskFormat[index + indexAdjustment] !== '#') {
          tempValue += maskFormat[index + indexAdjustment];
          indexAdjustment += 1;
        }
        return `${result}${tempValue}${val}`;
      }
      return `${result}${val}`;
    }, '');
    setValueWithSpecialCharacters(withSpecialCharacters);

    const valueWithPlaceholder = `${withSpecialCharacters}${placeholder.substring(
      withSpecialCharacters.length,
      maskFormat.length,
    )}`;
    setAdjustedValue(valueWithPlaceholder);

    return { withSpecialCharacters, valueWithPlaceholder };
  };

  useEffect(() => {
    if (value && (!adjustedValue || adjustedValue !== value)) {
      getAdjustedValue({ cleanValue: value.replace(specialCharPattern, '') });
    } else if (!value && adjustedValue) {
      setAdjustedValue('');
    }
  }, [value]);

  const updateSelectionRange = () => {
    if (selectionPosition) {
      inputRef.current?.setSelectionRange(
        selectionPosition?.[0] || null,
        selectionPosition?.[1] || null,
      );
    }
  };

  useEffect(() => {
    if (inputRef.current) updateSelectionRange();
  }, [inputRef.current, selectionPosition]);

  const getCleanValue = ({
    strippedTargetValue,
    strippedPlaceholder,
  }: IMAFTexFieldMaskedGetCleanValueParams) =>
    strippedPlaceholder
      .split('')
      .reduce((result, val) => result.replace(val, ''), strippedTargetValue);

  const resetValuesAndPosition = (initialValue = '') => {
    setValueWithSpecialCharacters(initialValue);
    setAdjustedValue(initialValue);
    setSelectionPosition(null);
  };

  const handleOnSelect = () => {
    if (
      (inputRef.current?.selectionStart &&
        inputRef.current.selectionStart > valueWithSpecialCharacters.length) ||
      (inputRef.current?.selectionEnd &&
        inputRef.current.selectionEnd > valueWithSpecialCharacters.length)
    ) {
      updateSelectionRange();
    } else {
      setSelectionPosition([
        inputRef.current?.selectionStart,
        inputRef.current?.selectionEnd,
      ] as unknown as SetStateAction<null>);
    }
  };

  const handleOnChange = (e: TMAFTextFieldOnChangeEvent) => {
    const targetValue = e.target.value;

    const strippedTargetValue = targetValue.replace(specialCharPattern, '');
    const strippedPlaceholder = placeholder.replace(specialCharPattern, '');
    const strippedAdjustedValue = adjustedValue?.replace(specialCharPattern, '') || '';

    const differenceLength = strippedTargetValue.length - strippedAdjustedValue.length;
    const isDeleting = strippedAdjustedValue.length > strippedTargetValue.length;

    if (isDeleting && inputRef.current?.selectionEnd === valueWithSpecialCharacters.length) {
      return;
    }

    if (strippedPlaceholder.includes(strippedTargetValue)) {
      resetValuesAndPosition();
      handleOnSelect();
      e.target.value = '';
      onChange && onChange(e);
      return;
    }

    let cleanValue = getCleanValue({ strippedTargetValue, strippedPlaceholder });

    if (onChangeValidate && !onChangeValidate(cleanValue)) return;

    if (cleanValue.length > strippedPlaceholder.length) {
      cleanValue = cleanValue.substring(0, strippedPlaceholder.length);
    }

    const { withSpecialCharacters } = getAdjustedValue({ cleanValue });

    const newSelectionPosition =
      !selectionPosition || (!isDeleting && differenceLength !== 0)
        ? withSpecialCharacters.length
        : inputRef.current?.selectionStart;
    setSelectionPosition([
      newSelectionPosition,
      newSelectionPosition,
    ] as unknown as SetStateAction<null>);

    e.target.value = cleanValue;
    onChange && onChange(e);
  };

  const handleOnBlur = (e: TMAFTextFieldOnFocusEvent) => {
    const targetValue = e.target.value;
    const cleanValue = getCleanValue({
      strippedTargetValue: targetValue.replace(specialCharPattern, ''),
      strippedPlaceholder: placeholder.replace(specialCharPattern, ''),
    });

    if (shouldAutofillOnBlur) {
      const { withSpecialCharacters, valueWithPlaceholder } = getAdjustedValue({ cleanValue });
      if (withSpecialCharacters.length < valueWithPlaceholder.length) {
        resetValuesAndPosition(value);
      }
    }

    e.target.value = cleanValue;
    onBlur(e);
  };

  return (
    <MAFTextFieldWithIcon
      icon={icon}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      {...rest}
      placeholder={placeholder}
      value={adjustedValue}
      onSelect={handleOnSelect}
      inputRef={inputRef}
    />
  );
};

MAFTextFieldMasked.propTypes = propTypes;

export default MAFTextFieldMasked;
