"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var Route = (function () {
    function Route(args) {
        this.rabbitExchange = args.rabbitExchange;
        this.internalHttpMethod = args.internalHttpMethod;
        this.httpMethod = args.httpMethod;
        this.buildUrl = args.buildUrl;
        this.buildUrlExternal = args.buildUrlExternal;
    }
    Route.prototype.bindApiAgent = function (apiAgent) {
        this.apiAgent = apiAgent;
    };
    Route.prototype.call = function (_a) {
        if (_a === void 0) { _a = {}; }
        var urlParams = _a.urlParams, body = _a.body, additional = tslib_1.__rest(_a, ["urlParams", "body"]);
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_b) {
                url = this.buildUrl(urlParams);
                return [2, this.internalCall(url, body, additional)];
            });
        });
    };
    Route.prototype.callExternal = function (_a) {
        if (_a === void 0) { _a = {}; }
        var urlParams = _a.urlParams, body = _a.body, additional = tslib_1.__rest(_a, ["urlParams", "body"]);
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_b) {
                url = this.buildUrlExternal(urlParams);
                return [2, this.externalCall(url, body, additional)];
            });
        });
    };
    Route.prototype.internalCall = function (url, body, additional) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!this.apiAgent) {
                    throw new Error('Api agent has not been initialized');
                }
                return [2, this.apiAgent[this.internalHttpMethod || this.httpMethod](tslib_1.__assign(tslib_1.__assign({}, additional), { url: url,
                        body: body }))];
            });
        });
    };
    Route.prototype.externalCall = function (url, body, additional) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!this.apiAgent) {
                    throw new Error('Api agent has not been initialized');
                }
                return [2, this.apiAgent[this.httpMethod](tslib_1.__assign(tslib_1.__assign({}, additional), { url: url,
                        body: body }))];
            });
        });
    };
    return Route;
}());
exports.default = Route;
