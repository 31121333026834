"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledEngine = require("@mui/styled-engine");
var _templateObject;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const fadeIn = (0, _styledEngine.keyframes)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n"])));
const fadeInAnimation = {
  animation: "".concat(fadeIn),
  animationIterationCount: 1,
  animationTimingFunction: 'linear',
  animationDuration: '0.5s'
};
const styles = exports.styles = {
  container: _ref => {
    let {
      breakpoints
    } = _ref;
    return _objectSpread(_objectSpread({
      width: '100%'
    }, fadeInAnimation), {}, {
      [breakpoints.down('lg')]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    });
  },
  text: _ref2 => {
    let {
      typography: {
        title
      },
      fontSizes: {
        extraMedium
      }
    } = _ref2;
    return {
      fontFamily: title,
      fontSize: extraMedium,
      letterSpacing: '0.76px',
      lineHeight: '32px',
      padding: '0px 50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      '& svg': {
        margin: '0 10px'
      }
    };
  },
  subtext: _ref3 => {
    let {
      typography: {
        title
      },
      fontSizes: {
        small
      }
    } = _ref3;
    return {
      fontFamily: title,
      fontSize: small,
      textAlign: 'center'
    };
  },
  textResponsive: _ref4 => {
    let {
      breakpoints,
      fontSizes: {
        small
      }
    } = _ref4;
    return {
      [breakpoints.down('lg')]: {
        fontSize: small,
        padding: 0,
        lineHeight: '16px',
        '& svg': {
          width: 20,
          height: 20
        },
        '& > div': {
          width: '30px !important',
          height: '20px !important'
        }
      }
    };
  },
  reasoningTitle: _ref5 => {
    let {
      fontSizes: {
        extraSmall
      }
    } = _ref5;
    return {
      fontSize: extraSmall,
      fontWeight: 'bold'
    };
  },
  reasoningText: _ref6 => {
    let {
      fontSizes: {
        extraSmall
      }
    } = _ref6;
    return {
      fontSize: extraSmall,
      fontWeight: 'normal',
      marginTop: '10px'
    };
  },
  additionalTitle: _ref7 => {
    let {
      fontSizes: {
        extraSmall
      },
      colors
    } = _ref7;
    return {
      fontSize: extraSmall,
      fontWeight: 'bold',
      display: 'flex',
      color: colors.solid.lightRed,
      marginBottom: '10px'
    };
  }
};