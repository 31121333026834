"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseDefaultDateToDayjs = exports.getTodaysDateInDefaultFormat = exports.getParamsFromQuery = exports.downloadBinaryFile = exports.NSConnect = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _dayjs = _interopRequireDefault(require("dayjs"));
var _vendor = require("../vendor.config");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * Wrap component to array of higher order components.
 * @param {array} args array of higher order components
 */
const NSConnect = function () {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  return Component => args.reverse().reduce((component, hoc) => Array.isArray(hoc) ? hoc.shift()(component, ...hoc) : hoc(component), Component);
};
exports.NSConnect = NSConnect;
const parseDefaultDateToDayjs = value => (0, _dayjs.default)(value, _vendor.DEFAULT_DATE_FORMAT);
exports.parseDefaultDateToDayjs = parseDefaultDateToDayjs;
const getTodaysDateInDefaultFormat = () => (0, _dayjs.default)().format(_vendor.DEFAULT_DATE_FORMAT);
exports.getTodaysDateInDefaultFormat = getTodaysDateInDefaultFormat;
const downloadBinaryFile = _ref => {
  let {
    name,
    binary
  } = _ref;
  const nameWithExtention = "".concat(name, ".pdf").replace(/\s+\/\s+|\s+/g, '-');
  const decodedBinary = atob(binary.replace(/\s/g, ''));
  if (window.navigator.msSaveBlob) {
    // IE
    // decode base64 string, remove space for IE compatibility
    const len = decodedBinary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i += 1) {
      view[i] = decodedBinary.charCodeAt(i);
    }
    const blob = new Blob([view], {
      type: 'application/pdf;base64'
    });
    window.navigator.msSaveOrOpenBlob(blob, nameWithExtention);
  } else {
    const link = document.createElement('a');
    link.href = "data:application/pdf;base64,".concat(encodeURIComponent(binary));
    link.download = nameWithExtention;
    link.dispatchEvent(new MouseEvent('click'));
  }
};
exports.downloadBinaryFile = downloadBinaryFile;
const getParamsFromQuery = query => {
  const params = query.match(/[\w+[\]]+=[A-Za-z0-9#%.]+/g);
  return params ? params.reduce((prev, curr) => {
    const isArrayParam = curr.includes('[]=');
    const param = isArrayParam ? curr.split('[]=') : curr.split('=');
    const [paramName, paramValue] = param;
    let arrayParamValue;
    if (isArrayParam || prev[paramName]) {
      arrayParamValue = prev[paramName] ? [...(Array.isArray(prev[paramName]) ? prev[paramName] : [prev[paramName]]), paramValue] : [paramValue];
    }
    return _objectSpread(_objectSpread({}, prev), {}, {
      [paramName]: arrayParamValue || paramValue
    });
  }, []) : {};
};
exports.getParamsFromQuery = getParamsFromQuery;