export const HP_FORM = 'hirePurchaseForm';
export const HP_FORM_CAR_PRICE = 'carPrice';
export const HP_FORM_DOWN_PAYMENT = 'downPayment';
export const HP_FORM_TRADE_IN_CAR = 'tradeinCar';
export const HP_FORM_TRADE_IN_REGISTRATION_NUMBER = 'tradeinCarRegistrationNumber';
export const HP_FORM_TRADE_IN_VALUE = 'tradeinCarValue';
export const HP_FORM_TRADE_IN_DEBT = 'tradeinCarDebt';
export const HP_FORM_FUTURE_PERCENTAGE = 'futurePercentage';
export const HP_FORM_VENDOR_TERMS_ID = 'vendorTermsId';
export const HP_FORM_DURATION = 'duration';
export const HP_FORM_CAR_REGISTRATION_DATE = 'carRegistrationDate';
export const HP_FORM_INTEREST_RATE = 'interestRate';
export const HP_FORM_VAT_TOGGLE = 'vatToggle';
export const HP_SEARCH_FORM = 'HPSearchForm';

export const HP_FORM_CAR_PRICE_MAPPED_FIELD = 'carPriceInVat';
export const HP_FORM_DOWN_PAYMENT_MAPPED_FIELD = 'cashPaymentInVat';
export const HP_FORM_TRADE_IN_VALUE_MAPPED_FIELD = 'tradeInCarValueInVat';
export const HP_FORM_TRADE_IN_DEBT_MAPPED_FIELD = 'tradeInCarRemainingDebtInVat';
export const HP_FORM_DURATION_MAPPED_FIELD = 'durationMonths';
export const HP_FORM_INTEREST_RATE_MAPPED_FIELD = 'totalYearlyInterestRate';
