"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  role: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
    '& + div': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  cellBody: {
    height: 'auto'
  },
  content: {
    alignItems: 'center'
  },
  row: {
    height: 30
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  roleLabel: _ref => {
    let {
      colors
    } = _ref;
    return {
      color: colors.alpha(colors.solid.black, 0.4)
    };
  },
  activeRole: _ref2 => {
    let {
      colors
    } = _ref2;
    return {
      fontWeight: 500,
      color: colors.solid.black
    };
  }
};