import { Radio, RadioProps, FormControlLabel, Box, SxProps, Theme } from '@mui/material';
import { FC, ReactChild } from 'react';

import MAFHelperText from '../MAFHelperText/MAFHelperText';
import { handleCustomSx } from '../../utils';

import { useStyles } from './MAFRadioButton.styles';
import { propTypes } from './MAFRadioButton.propTypes';

export type TMAFRadioButtonLabelPlacement = 'bottom' | 'end' | 'top' | 'start';

export interface IMAFRadioButton extends RadioProps {
  label?: ReactChild;
  labelClasses?: SxProps<Theme>;
  labelPlacement?: TMAFRadioButtonLabelPlacement;
  radioButtonClasses?: SxProps<Theme>;
  isError?: boolean;
  helperText?: ReactChild;
  onChange?: () => void;
}

const MAFRadioButton: FC<IMAFRadioButton> = ({
  label = '',
  labelClasses = {},
  disabled: isDisabled,
  labelPlacement = 'end',
  radioButtonClasses = {},
  isError,
  helperText,
  checked: isChecked,
  onChange,
  ...props
}) => {
  const styles = useStyles({ isDisabled, isError });
  return (
    <>
      <FormControlLabel
        label={label}
        disabled={isDisabled}
        sx={[styles.rootLabel, ...handleCustomSx(labelClasses)]}
        labelPlacement={labelPlacement}
        control={
          <Radio
            icon={<Box sx={styles.icon} />}
            checkedIcon={
              <Box sx={styles.checkedIcon}>
                <Box sx={styles.checkedIconFill} />
              </Box>
            }
            {...props}
            onChange={onChange}
            checked={isChecked}
            disabled={isDisabled}
            sx={[styles.root, styles.colorSecondary, ...handleCustomSx(radioButtonClasses)]}
          />
        }
      />
      {helperText && <MAFHelperText isError={isError}>{helperText}</MAFHelperText>}
    </>
  );
};

MAFRadioButton.propTypes = propTypes;

export default MAFRadioButton;
