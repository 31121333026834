import { FC, ReactChild } from 'react';
import { TextField, InputProps, StandardTextFieldProps, Theme, SxProps } from '@mui/material';

import MAFHelperText, { IMAFHelperTextProps } from '../MAFHelperText/MAFHelperText';
import MAFTooltipLabel from '../MAFTooltipLabel/MAFTooltipLabel';
import { handleCustomSx } from '../../utils';

import { TMAFTextFieldRef } from './MAFTextField.types';
import { useStyles } from './MAFTextField.styles';
import { propTypes } from './MAFTextField.propTypes';

export interface IMAFTextFieldProps extends StandardTextFieldProps {
  placeholder?: string;
  textAlign?: string;
  additionalInputProps?: InputProps;
  sx?: SxProps<Theme>;
  isLabelInside?: boolean;
  tooltipText?: ReactChild;
  label?: ReactChild;
  helperText?: ReactChild;
  textFieldClass?: SxProps<Theme>;
  containerClass?: SxProps<Theme>;
  customHelperText?: ReactChild;
  customLabelClass?: SxProps<Theme>;
  isWithoutOneBorder?: boolean;
  isCompact?: boolean;
  inputRef?: TMAFTextFieldRef<any>;
}

const MAFTextField: FC<IMAFTextFieldProps> = ({
  onChange = () => {},
  placeholder,
  textAlign,
  additionalInputProps = {},
  isLabelInside = false,
  tooltipText,
  label,
  error: isError = false,
  helperText,
  textFieldClass,
  containerClass,
  customHelperText,
  customLabelClass,
  isWithoutOneBorder = false,
  multiline: isMultiline = false,
  isCompact = false,
  fullWidth: isFullWidth,
  disabled: isDisabled,
  ...props
}) => {
  const styles = useStyles({ isMultiline });

  const rootInputClasses = [
    isLabelInside && styles.inputFull,
    isWithoutOneBorder && !isError && styles.noBorder,
    (!isWithoutOneBorder || isError) && styles.input,
    ...handleCustomSx(textFieldClass),
  ];

  const rootLabelClasses = [
    styles.label,
    isLabelInside && styles.labelFull,
    ...handleCustomSx(customLabelClass),
  ];

  const inputClasses = [
    textAlign === 'center' && styles.textAlignCenter,
    textAlign === 'left' && styles.textAlignLeft,
    textAlign === 'right' && styles.textAlignRight,
  ];

  const containerClasses = [
    styles.container,
    isCompact && styles.containerCompact,
    ...handleCustomSx(containerClass),
  ];

  const adjustedLabel =
    tooltipText && !isLabelInside ? (
      <MAFTooltipLabel tooltipText={tooltipText} label={label} />
    ) : (
      label
    );

  return (
    <TextField
      onChange={onChange}
      autoComplete="off"
      placeholder={placeholder}
      sx={containerClasses}
      label={adjustedLabel}
      InputProps={{
        ...additionalInputProps,
        sx: [
          ...rootInputClasses,
          ...inputClasses,
          styles.disabled,
          styles.underline,
          styles.focused,
          styles.error,
        ],
      }}
      InputLabelProps={{
        shrink: true,
        sx: rootLabelClasses,
      }}
      FormHelperTextProps={
        {
          component: MAFHelperText,
          isError, // we have to keep this prop as 'isError' because FormHelperTextProps does not pass on default MUI props like 'error' to the provided component
        } as IMAFHelperTextProps
      }
      disabled={isDisabled}
      {...props}
      helperText={helperText || customHelperText}
      error={isError}
      multiline={isMultiline}
      variant="standard"
      fullWidth={isFullWidth}
    />
  );
};

MAFTextField.propTypes = propTypes;

export default MAFTextField;
