"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: {
    width: '100%',
    minHeight: '52px'
  },
  inputCell: {
    textAlign: 'center'
  },
  displayCell: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  focused: _ref => {
    let {
      colors
    } = _ref;
    return {
      background: colors.alpha(colors.solid.black, 0.07)
    };
  }
};