import NumberFormat, { NumberFormatProps } from 'react-number-format';

import MAFTextFieldWithIcon, {
  IMAFTextFieldWithIconProps,
} from '../MAFTextFieldWithIcon/MAFTextFieldWithIcon';

import { propTypes } from './MAFNumberFieldMasked.propTypes';

export interface IMAFNumberFieldMaskedProps extends NumberFormatProps<IMAFTextFieldWithIconProps> {
  maskedFormat: string;
  value: string;
}

const MAFNumberFieldMasked: React.FC<IMAFNumberFieldMaskedProps> = ({
  decimalScale = 0,
  decimalSeparator = ',',
  value,
  maskedFormat,
  ...props
}) => (
  <NumberFormat
    {...props}
    value={value}
    decimalScale={decimalScale}
    decimalSeparator={decimalSeparator}
    format={maskedFormat}
    customInput={MAFTextFieldWithIcon}
  />
);

MAFNumberFieldMasked.propTypes = propTypes;

export default MAFNumberFieldMasked;
