import {
  Select,
  SelectProps,
  MenuItem,
  MenuProps,
  NativeSelect,
  NativeSelectProps,
  SelectChangeEvent,
} from '@mui/material';
import { FC, ReactChild, ChangeEvent } from 'react';

import { useScreenSize, handleCustomSx } from '../../utils';
import ArrowDown from '../Icons/ArrowDown';

import { useStyles } from './MAFSelect.styles';
import MAFSelectFormControl, {
  IMAFSelectFormControlProps,
} from './MAFSelectFormControl/MAFSelectFormControl';
import { propTypes } from './MAFSelect.propTypes';

interface IMenuProps extends Partial<MenuProps> {
  'ns-target'?: string;
}

export interface IMAFSelectOptions {
  label: ReactChild;
  value: string | number | undefined;
}

export interface IMAFSelectProps extends Omit<SelectProps, 'onChange'> {
  wrapperProps?: IMAFSelectFormControlProps;
  options: IMAFSelectOptions[];
  value?: string | number | Array<string | number>;
  onChange: (
    e:
      | SelectChangeEvent
      | ChangeEvent<{
          value: string | number;
        }>,
  ) => void;
  name?: string;
  emptyValueLabel?: ReactChild;
  helperText?: ReactChild;
  shouldShowBorder?: boolean;
  isAutoMinWidth?: boolean;
  emptyValue?: string | number;
  id?: string;
  isWithNativeSelect?: boolean;
}

const MAFSelect: FC<IMAFSelectProps> = ({
  wrapperProps,
  options,
  value,
  onChange,
  name,
  emptyValueLabel,
  error: isError = false,
  helperText,
  shouldShowBorder = false,
  isAutoMinWidth = true,
  emptyValue = '',
  isWithNativeSelect,
  sx,
  disabled: isDisabled,
  multiple: isMultiple,
  ...rest
}) => {
  const styles = useStyles({ isAutoMinWidth, isLabelInside: wrapperProps?.isLabelInside });

  const handleChangeSelect = (event: SelectChangeEvent) => {
    if (event.target.value === emptyValue) return;
    onChange(event);
  };

  const handleChangeNativeSelect = (
    event: ChangeEvent<{
      value: string | number;
    }>,
  ) => {
    if (event.target.value === emptyValue) return;
    onChange(event);
  };

  const { isDesktop } = useScreenSize();

  const selectComponentProps = {
    IconComponent: ArrowDown,
    value,
    name,
    disableUnderline: true,
    disabled: isDisabled,
    sx: [
      styles.select,
      isError && styles.error,
      shouldShowBorder && styles.border,
      Boolean(isDisabled) && styles.iconDisabled,
      !isDisabled && styles.icon,
      styles.disabled,
      ...handleCustomSx(sx),
    ],
  };

  const SelectComponent =
    !isMultiple && !isDesktop && isWithNativeSelect ? (
      <NativeSelect
        onChange={handleChangeNativeSelect}
        {...selectComponentProps}
        {...(rest as NativeSelectProps)}
        ns-target={rest.id}
      >
        <option style={{ display: 'none' }} value="">
          &nbsp;
        </option>
        {options.map(({ value: val, label }, index) => (
          <option key={index} value={val}>
            {label}
          </option>
        ))}
      </NativeSelect>
    ) : (
      <Select
        MenuProps={
          {
            sx: [styles.paper, styles.list],
            'ns-target': rest.id,
          } as IMenuProps
        }
        onChange={handleChangeSelect}
        variant="standard"
        multiple={isMultiple}
        {...selectComponentProps}
        {...rest}
      >
        {emptyValueLabel && (
          <MenuItem value={emptyValue} disabled sx={styles.item}>
            {emptyValueLabel}
          </MenuItem>
        )}

        {options.map(({ value: val, label, ...etc }, index) => (
          <MenuItem {...etc} key={index} value={val} sx={[styles.selected, styles.item]}>
            {label}
          </MenuItem>
        ))}
      </Select>
    );

  return (
    <MAFSelectFormControl {...wrapperProps} isError={isError} helperText={helperText}>
      {SelectComponent}
    </MAFSelectFormControl>
  );
};

MAFSelect.propTypes = propTypes;

export default MAFSelect;
