"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
class AuthSingleton {
  constructor() {
    this.authSecret = '';
    this.identityId = '';
  }
  getAuthSecret() {
    return this.authSecret;
  }
  getIdentityId() {
    return this.identityId;
  }
  setSecrets(authSecret, identityId) {
    this.authSecret = authSecret;
    this.identityId = identityId;
  }
  clearSecrets() {
    this.setSecrets(null, null);
  }
}
var _default = exports.default = new AuthSingleton();