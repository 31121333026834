import { alpha } from '@mui/material/styles';

import { mainColors } from './common';

export const PUBLIC_DEFAULT_IMAGE_PATH = '/images/';

export const baseThemeColors = {
  backgrounds: {
    background: mainColors.solid.black,
    backgroundPosition: 'unset',
    gradient: 'linear-gradient(rgba(0,0,0, 0) 90%, rgba(0,0,0, 1) 100%)',
  },
  carCards: {
    gradients: {
      carCard: 'linear-gradient(180deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0) 75%)',
      carCardHover: alpha(mainColors.solid.white, 0.6),
      carRow: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0) 100%)',
      carRowHover: mainColors.solid.gainsboro,
      carCase: 'linear-gradient(180deg, rgba(255,255,255,1) 50%, rgba(152, 152, 152, 0.3) 100%)',
    },
    solidBackground: mainColors.solid.lightGray,
    header: alpha(mainColors.solid.darkGray, 0.85),
    primary: mainColors.solid.cadedBlue,
  },
  caseImage: {
    primary: mainColors.solid.cadedBlue,
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0) 100%)',
    solidBackground: mainColors.solid.lightGray,
  },
  primaryPR: {
    highlight: {
      background: 'linear-gradient(143.64deg, #009fda 0%, #09b89d 100%)',
      color: mainColors.solid.white,
    },
    action: {
      background: 'linear-gradient(143.64deg, #009fda 0%, #09b89d 100%)',
      color: mainColors.solid.white,
      border: '1px solid #37B0DE',
      '&:active': {
        transition: 'none',
        background: mainColors.solid.blue,
      },
    },
    solid: {
      background: '#009fda',
      color: mainColors.solid.white,
    },
    chip: {
      background: 'linear-gradient(143.64deg, #009fda 0%, #09b89d 100%)',
      color: mainColors.solid.white,
    },
  },
  primaryCM: {
    highlight: {
      background: `linear-gradient(23deg, ${mainColors.solid.purple} 0%, #e65a6d 100%)`,
      color: mainColors.solid.white,
    },
    action: {
      background: `linear-gradient(23deg, ${mainColors.solid.purple} 0%, #e65a6d 100%)`,
      color: mainColors.solid.white,
      border: '1px solid #74489d',
      '&:active': {
        transition: 'none',
        background: mainColors.solid.purple,
      },
    },
    solid: {
      background: mainColors.solid.purple,
      color: mainColors.solid.white,
    },
    chip: {
      background: `linear-gradient(23deg, ${mainColors.solid.purple} 0%, #e65a6d 100%)`,
      color: mainColors.solid.white,
    },
  },
  disabled: {
    background: '#56565a',
    color: mainColors.solid.white,
    border: `1px solid ${mainColors.solid.gray}`,
  },
  warning: '#fbb273',
  background: {
    background: mainColors.solid.lightGray,
    highlight: alpha(mainColors.solid.white, 0.4),
  },
  toolbar: {
    background: alpha(mainColors.solid.white, 0.15),
  },
  sidebar: {
    main: { background: mainColors.solid.cadedGray, color: mainColors.solid.white },
    borderColor: alpha(mainColors.solid.cadedDarkGray, 0.76),
    active: {
      background: mainColors.solid.darkBlueGray,
      color: mainColors.solid.white,
    },
    link: {
      background: alpha(mainColors.solid.cadedDarkGray, 0.76),
    },
  },
  equipmentBox: {
    background: mainColors.solid.darkBlueGray,
  },
  footer: {
    type: 'dark',
    upper: {
      background: mainColors.solid.cadedGray,
      color: mainColors.solid.white,
    },
    lower: {
      background: '#56565a',
      color: mainColors.solid.white,
    },
  },
  select: {
    selected: {
      background: mainColors.solid.blue,
      color: mainColors.solid.white,
    },
  },
  tabs: {
    background: mainColors.solid.lightBlueGray,
    color: mainColors.solid.cadedGray,
    maf: {
      color: mainColors.solid.black,
      selectedColor: mainColors.solid.black,
      divider: alpha(mainColors.solid.black, 0.2),
    },
  },
  headers: {
    background: mainColors.solid.gray,
    color: mainColors.solid.white,
  },
  chatMessage: {
    own: {
      background: `linear-gradient(143.64deg, ${mainColors.solid.blue} 0%, #09b89d 100%)`,
      color: mainColors.solid.white,
    },
    externalReply: {
      background: mainColors.solid.solidGray,
      color: mainColors.solid.white,
    },
    internalReply: {
      background: mainColors.solid.cadedDarkGray,
      color: mainColors.solid.white,
    },
  },
  content: {
    background: mainColors.solid.darkBlueGray,
    color: mainColors.solid.white,
  },
  contentSecondary: {
    background: mainColors.solid.solidGray,
    color: mainColors.solid.white,
  },
  contentAlternative: {
    main: {
      background: mainColors.solid.gainsboro,
      color: mainColors.solid.black,
    },
    highlight: {
      background: alpha(mainColors.solid.black, 0.54),
      color: mainColors.solid.white,
    },
  },
  cardList: {
    card: {
      background: mainColors.solid.darkGray,
      boxShadow: 'rgb(213 213 213 / 80%) 0px 3px 8px 0px',
    },
    title: {
      color: mainColors.solid.white,
    },
    icon: {
      fill: mainColors.solid.white,
    },
    header: {
      backgroundColor: mainColors.solid.darkGray,
      color: mainColors.solid.white,
    },
  },
  chipsAlternative: {
    background: mainColors.solid.gray,
    color: mainColors.solid.white,
  },
  appBar: {
    background: mainColors.solid.black,
    color: mainColors.solid.white,
  },
  toggleElements: {
    background: alpha(mainColors.solid.black, 0.2),
    color: mainColors.solid.blue,
    secondary: mainColors.solid.white,
    border: mainColors.solid.white,
  },
  quoteDocument: {
    background: '#f6fafc',
  },
  fontPrimary: mainColors.solid.white,
  fontSecondary: mainColors.solid.darkGray,
  buttons: {
    secondary: {
      background: `linear-gradient(0deg, #F5F5F5 0%, ${mainColors.solid.white} 50.2%, ${mainColors.solid.white} 100%)`,
      color: mainColors.solid.cadedBlue,
      border: '1px solid #DBE0DE',
    },
    gray: {
      color: mainColors.solid.white,
      backgroundColor: mainColors.solid.lightBlueGray,
      '&:hover': {
        backgroundColor: alpha(mainColors.solid.lightGray, 0.3),
        color: mainColors.solid.white,
      },
    },
    transparentGray: {
      color: mainColors.solid.white,
      backgroundColor: alpha(mainColors.solid.redGray, 0.6),
      '&:hover': {
        backgroundColor: alpha(mainColors.solid.lightGray, 0.3),
      },
    },
    blank: {
      backgroundColor: 'inherit',
      color: alpha(mainColors.solid.white, 0.8),
      '&:hover': {
        color: mainColors.solid.white,
      },
    },
    loading: {
      background: mainColors.solid.gainsboro,
    },
    base: {
      boxShadow: `0 2px 4px 0 ${alpha(mainColors.solid.black, 0.15)}`,
    },
    focus: {
      outline: 'none',
      boxShadow: `${alpha('#04b4f3', 0.7)} 0px 0px 0px 3px !important`,
    },
    sticky: {
      background: `linear-gradient(${alpha(mainColors.danske.primary1, 0)} 0%, ${
        mainColors.danske.primary1
      } 25%, ${mainColors.danske.primary1} 100%)`,
    },
  },
  latestInfo: {
    background: mainColors.solid.cadedGray,
    color: mainColors.solid.white,
  },
  slider: {
    thumb: { backgroundColor: mainColors.solid.solidGray },
    track: { background: 'linear-gradient(143.64deg, #009fda 0%, #09b89d 100%)' },
    trackAlternative: {
      background: `linear-gradient(23deg, ${mainColors.solid.purple} 0%, #e65a6d 100%)`,
    },
  },
  selectLayout: {
    mainBlock: null,
    sidebar: null,
  },
  caseDetail: {
    sidebarSection: {
      borderTop: `1px solid ${alpha(mainColors.solid.black, 0.1)}`,
      borderBottom: `1px solid ${alpha(mainColors.solid.black, 0.1)}`,
    },
    indicatorIcon: {
      color: mainColors.solid.solidGray,
    },
    mainContainer: null,
  },
  specifications: {
    borderBottom: `1px solid ${alpha(mainColors.solid.white, 0.48)}`,
    color: mainColors.solid.white,
  },
  applicationForm: {
    solid: null,
    transparent: { background: alpha(mainColors.solid.cadedGray, 0.4) },
  },
  carousel: {
    pagination: {
      active: {
        backgroundColor: mainColors.solid.cadedBlue,
      },
      inactive: {
        backgroundColor: alpha(mainColors.solid.cadedBlue, 0.12),
      },
    },
  },
  kyc: {
    card: {
      backgroundColor: alpha(mainColors.solid.cadedDarkGray, 0.6),
    },
    cardAction: {
      backgroundColor: mainColors.solid.darkGray,
    },
    dialog: {
      backgroundColor: mainColors.solid.black,
    },
  },
  search: {
    background: mainColors.solid.gray,
    border: {
      border: `1px solid ${alpha(mainColors.solid.black, 0.1)}`,
      borderRight: 0,
    },
  },
  inputFull: {
    input: {
      color: `${mainColors.solid.white} !important`,
      background: '#343B3D',
      border: `1px solid ${alpha(mainColors.solid.black, 0.2)}`,
    },
    disabled: {
      '-webkitTextFillColor': `${alpha(mainColors.solid.white, 0.5)} !important`,
      color: `${alpha(mainColors.solid.white, 0.5)}`,
      background: '#666D6F',
      border: '1px solid #7D8484',
    },
    helper: {
      color: `${mainColors.solid.white} !important`,
      background: mainColors.solid.lightBlue,
    },
    error: {
      color: `${mainColors.solid.white} !important`,
      background: mainColors.solid.red,
      boxShadow: `0px 2px 6px ${alpha(mainColors.solid.black, 0.3)}`,
    },
    hover: {
      border: `1px solid ${mainColors.solid.blue}`,
    },
    label: {
      color: mainColors.solid.white,
    },
  },
  tooltip: {
    color: mainColors.solid.black,
    background: '#F3F5F4',
  },
  spinner: { primaryColor: mainColors.solid.cadedGray, secondaryColor: mainColors.solid.white },
  loginBlock: { background: alpha(mainColors.solid.cadedGray, 0.7) },
  dialog: { background: 'linear-gradient(143.64deg, #5e676a 0%, #3a4344 100%);' },
  icon: { fill: mainColors.solid.white },
  switch: {
    base: null,
    thumb: {
      color: mainColors.solid.white,
    },
    enabled: {
      background: 'linear-gradient(143.64deg, #009fda 0%, #09b89d 100%)',
    },
    disabled: {
      backgroundColor: `${alpha(mainColors.solid.black, 0.2)} !important`,
    },
  },
  calendar: {
    background: {
      backgroundColor: alpha(mainColors.solid.black, 0.2),
    },
    arrow: {
      fill: mainColors.solid.white,
    },
    selected: {
      backgroundColor: 'inherit',
      background: 'linear-gradient(143.64deg, #009fda 0%, #09b89d 100%)',
    },
  },
  dropDown: {
    selected: {
      backgroundColor: mainColors.solid.darkGray,
      color: mainColors.solid.white,
    },
  },
  carSelection: {
    boxShadow: `0 2px 5px 0 ${alpha(mainColors.solid.black, 0.5)}`,
  },
  body: { background: mainColors.solid.cadedGray, color: mainColors.solid.white },
  vendorTerms: { color: mainColors.solid.solidGray },
  filters: { backgroundColor: mainColors.solid.cadedGray },
  accordion: {
    color: mainColors.danske.white,
    bottomColor: mainColors.danske.white,
    background: 'none',
    focusBackground: mainColors.solid.darkBlueGray,
  },
  badge: {
    base: {
      color: mainColors.solid.white,
      backgroundColor: mainColors.solid.blue,
    },
    error: {
      backgroundColor: mainColors.solid.red,
    },
  },
  choosable: {
    background: mainColors.solid.whiteGray,
    color: `${alpha(mainColors.solid.black, 0.87)} !important`,
  },
  cookiesConsent: {
    content: null,
    buttons: {
      background: `linear-gradient(to bottom, ${alpha(mainColors.solid.solidGray, 0)} 0,  ${alpha(
        mainColors.solid.solidGray,
        1,
      )} 66px)`,
    },
  },
  ribbon: {
    PR: {
      background: 'linear-gradient(143.64deg, #009fda 0%, #09b89d 100%)',
      boxShadow:
        '0 1px 2px 0 rgba(102,102,102,0.2), 0 2px 4px 0 rgba(102,102,102,0.2), 0 4px 8px 0 rgba(102,102,102,0.2)',
      '&::before': {
        borderLeft: '2px solid #009fda',
        borderRight: '2px solid transparent',
        borderBottom: '2px solid transparent',
        borderTop: '2px solid #009fda',
      },
      '&::after': {
        borderRight: '2px solid #09b89d',
        borderLeft: '2px solid transparent',
        borderBottom: '2px solid transparent',
        borderTop: '2px solid #09b89d',
      },
    },
    CM: {
      background: `linear-gradient(23deg, ${mainColors.solid.purple} 0%, #e65a6d 100%)`,
      boxShadow:
        '0 1px 2px 0 rgba(102,102,102,0.2), 0 2px 4px 0 rgba(102,102,102,0.2), 0 4px 8px 0 rgba(102,102,102,0.2)',
      '&::before': {
        borderLeft: `2px solid ${mainColors.solid.purple}`,
        borderRight: '2px solid transparent',
        borderBottom: '2px solid transparent',
        borderTop: `2px solid ${mainColors.solid.purple}`,
      },
      '&::after': {
        borderRight: '2px solid #e65a6d',
        borderLeft: '2px solid transparent',
        borderBottom: '2px solid transparent',
        borderTop: '2px solid #e65a6d',
      },
    },
  },
  stepper: {
    intro: {
      subText: {
        color: mainColors.danske.primary70,
      },
    },
    active: mainColors.danske.primary100,
    inactive: mainColors.danske.primary6,
    activeStep: { backgroundColor: mainColors.danske.action },
    underline: mainColors.danske.greyC60,
  },
  radio: {
    select: {
      color: mainColors.solid.white,
      backgroundColor: '#343B3D',
      '&:hover': {
        color: mainColors.solid.white,
        backgroundColor: '#343B3D',
      },
    },
    selected: {
      color: alpha(mainColors.solid.white, 0.5),
    },
    button: {
      borderColor: alpha(mainColors.solid.black, 0.2),
    },
    disabled: {
      color: alpha(mainColors.solid.white, 0.5),
      backgroundColor: '#666D6F',
    },
  },
  summaryEdit: {
    topBar: {
      boxShadow: `${alpha(mainColors.solid.black, 0.3)} 0px -2px 12px 0px`,
    },
    wrapper: {
      boxShadow: 'rgb(102 102 102 / 20%) 0px 2px 4px 0px, rgb(102 102 102 / 20%) 0px 4px 8px 0px',
    },
  },
  cardSelect: {
    dropDown: {
      alternative: {
        background: mainColors.solid.darkGray,
      },
    },
    selected: {
      color: mainColors.solid.white,
    },
  },
  table: {
    borderColor: alpha(mainColors.solid.black, 0.4),
    onHover: {
      backgroundColor: alpha(mainColors.solid.white, 0.4),
    },
  },
};
