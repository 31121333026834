const ArrowUp: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-navigation/Up-collapse/Darkblue">
        <g id="Icon" transform="translate(2.000000, 7.000000)">
          <path
            d="M18.985694,10.3356 C18.742694,10.3356 18.498694,10.2476 18.305694,10.0696 L9.980694,2.3636 L1.679694,10.0676 C1.275694,10.4416 0.642694,10.4196 0.266694,10.0166 C-0.108306,9.6106 -0.0853060003,8.9786 0.319694,8.6036 L9.298694,0.2666 C9.682694,-0.0884 10.275694,-0.0884 10.658694,0.2656 L19.665694,8.6016 C20.070694,8.9776 20.094694,9.6096 19.719694,10.0156 C19.522694,10.2286 19.254694,10.3356 18.985694,10.3356"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowUp;
