"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shallowObjectValuesToSingleArray = exports.overrideObjectProperties = exports.objectToSnakeCase = exports.objectToCamelCase = exports.mapObjectProperties = exports.getObjectPropertyByPath = exports.getNestedObj = exports.getLastDotObjectProperty = exports.getDotObjectPath = exports.filterNullOrUndefined = exports.createNestedObj = exports.applyFormattingFunction = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _StringUtils = require("@northstar/core/utils/StringUtils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// eslint-disable-next-line arrow-body-style
const applyFormattingFunction = func => function () {
  let {
    obj,
    override = {}
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (!obj || JSON.stringify(obj) === '{}') return obj;
  if (Array.isArray(obj)) {
    return obj.map(value => typeof value === 'object' ? applyFormattingFunction(func)({
      obj: value,
      override
    }) : value);
  }
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    const newKey = override[key] || func(key);
    return _objectSpread(_objectSpread({}, acc), {}, {
      [newKey]: typeof value === 'object' ? applyFormattingFunction(func)({
        obj: value,
        override
      }) : value
    });
  }, {});
};
exports.applyFormattingFunction = applyFormattingFunction;
const objectToCamelCase = exports.objectToCamelCase = applyFormattingFunction(_StringUtils.snakeToCamelCase);
const objectToSnakeCase = exports.objectToSnakeCase = applyFormattingFunction(_StringUtils.camelToSnake);
const overrideObjectProperties = exports.overrideObjectProperties = applyFormattingFunction(val => val);

/**
 * Get object prop by providing object path
 *
 * eg. obj = { a: { b: 'banana' }}, so path would be
 *     path = ['a', 'b']
 *
 * @param {object} object value object
 * @param {array} path path till value
 * @param {any} defaultValue default value to return if by path value is not found
 */
const getObjectPropertyByPath = _ref => {
  let {
    obj = {},
    path = [],
    defaultValue
  } = _ref;
  let index = 0;
  const length = path.length;
  while (obj !== null && index < length) {
    if (typeof obj[path[index]] === 'undefined') {
      return defaultValue;
    }
    // eslint-disable-next-line no-param-reassign
    obj = obj[path[index]];
    index += 1;
  }
  return index && index === length ? obj : defaultValue;
};
/**
 * Map object properties by path and provided structure
 * @param {Object} obj object of values
 * @param {Object} map structure to map from with array of maps to property
 */
exports.getObjectPropertyByPath = getObjectPropertyByPath;
const mapObjectProperties = _ref2 => {
  let {
    obj,
    map
  } = _ref2;
  return Object.keys(map).reduce((acc, key) => {
    const paths = map[key];
    const value = paths.reduce((prev, curr) => prev || getObjectPropertyByPath({
      obj,
      path: curr
    }), null);
    return _objectSpread(_objectSpread({}, acc), {}, {
      [key]: value
    });
  }, {});
};
exports.mapObjectProperties = mapObjectProperties;
const shallowObjectValuesToSingleArray = obj => Object.values(obj).reduce((prev, curr) => [...prev, ...curr], []);
exports.shallowObjectValuesToSingleArray = shallowObjectValuesToSingleArray;
const getNestedObj = (object, arrayPath) => arrayPath.reduce((accumulator, current) => accumulator && accumulator[current] !== 'undefined' ? accumulator[current] : null, object);
exports.getNestedObj = getNestedObj;
const createNestedObj = (arrayPath, initialObject) => arrayPath.reduceRight((accumulator, current) => ({
  [current]: accumulator
}), initialObject);
exports.createNestedObj = createNestedObj;
const getDotObjectPath = str => str && typeof str === 'string' && str.split('.').slice(0, -1);
exports.getDotObjectPath = getDotObjectPath;
const getLastDotObjectProperty = str => str && typeof str === 'string' && str.split('.').splice(-1);

/**
 * Delete all null (or undefined, or empty object) properties from an object.
 */
exports.getLastDotObjectProperty = getLastDotObjectProperty;
const filterNullOrUndefined = _ref3 => {
  let {
    obj,
    recurse = false
  } = _ref3;
  return Object.entries(obj).map(_ref4 => {
    let [key, val] = _ref4;
    return [key, val && typeof val === 'object' && recurse ? filterNullOrUndefined({
      obj: val,
      recurse: true
    }) : val];
  }).reduce((acc, _ref5) => {
    let [key, val] = _ref5;
    if (val === null || val === undefined || typeof val === 'object' && Object.keys(val).length === 0) return acc;else return _objectSpread(_objectSpread({}, acc), {}, {
      [key]: val
    });
  }, {});
};
exports.filterNullOrUndefined = filterNullOrUndefined;