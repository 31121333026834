const Save: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20px"
    height="20px"
    viewBox="-2 -2 23 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Desktop-HD"
        transform="translate(-405.000000, -1297.000000)"
        fill="#003755"
        fillRule="nonzero"
      >
        <g id="save_darkblue" transform="translate(405.000000, 1297.000000)">
          <path
            d="M5,12 L14,12 L14,13 L5,13 L5,12 Z M5,15 L14,15 L14,16 L5,16 L5,15 Z M19,19 L1,19 L1,1 L5,1 L5,6 L13,6 L13,1 L15.293,1 L19,4.707 L19,19 Z M10,4.5 L11,4.5 L11,1.5 L10,1.5 L10,4.5 Z M19.854,4.146 L15.854,0.146 C15.76,0.053 15.633,0 15.5,0 L13,0 L5,0 L0.5,0 C0.224,0 0,0.224 0,0.5 L0,19.5 C0,19.776 0.224,20 0.5,20 L19.5,20 C19.776,20 20,19.776 20,19.5 L20,4.5 C20,4.367 19.947,4.24 19.854,4.146 L19.854,4.146 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Save;
