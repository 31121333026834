import { FC, ReactNode, ReactChild, useRef, ElementType } from 'react';
import { Box, Slide, useScrollTrigger } from '@mui/material';

import { useScreenSize } from '../../utils';
import { MAFLink } from '../MAFLink';
import { IMAFLinkProps } from '../MAFLink/MAFLink.types';
import { IMAFSelectProps, MAFSelect } from '../MAFSelect';

import MAFMenu, { IMAFMenuProps } from './MAFMenu/MAFMenu';
import { styles } from './MAFAppBar.styles';
import { propTypes } from './MAFAppBar.propTypes';
import { MAFAppBarTopLinkComponents, TMAFAppBarTopLinkConfig } from './MAFAppBar.types';
import { TopLinkComponents } from './MAFAppBar.constants';

export interface IMAFAppBarProps extends IMAFMenuProps {
  isLoggedIn?: boolean;
  Logo?: ElementType;
  topLinks?: TMAFAppBarTopLinkConfig[];
  links?: IMAFLinkProps[];
  leftComponent?: ReactNode;
  topText?: ReactChild;
  loginButton?: ReactNode;
  languageSelectProps?: IMAFSelectProps;
}

const MAFAppBar: FC<IMAFAppBarProps> = ({
  isLoggedIn,
  Logo,
  links,
  topLinks,
  leftComponent,
  topText,
  loginButton,
  children,
  languageSelectProps,
  ...menuProps
}) => {
  const topLinksRef = useRef<HTMLDivElement>();
  const appBarRef = useRef<HTMLDivElement>();
  const { isDesktop, isTablet, isMobile } = useScreenSize();
  const isScrollingDown = useScrollTrigger({ target: window || undefined });

  const appBarHeight =
    (topLinksRef.current?.getBoundingClientRect()?.height || 0) +
    (appBarRef.current?.getBoundingClientRect()?.height || 0);

  const isAppBarInViewport = appBarHeight > window.scrollY;

  const isMenuVisible = isLoggedIn || (!isLoggedIn && isMobile);

  const bottomLinks = links?.map(({ sx, ...rest }, index) => (
    <Box sx={[(index > 0 || isDesktop) && styles.bottomLink]}>
      <MAFLink isBold {...rest} />
    </Box>
  ));

  const LinksBar = (
    <>
      {leftComponent}
      <Box sx={styles.logoContainer}>
        {Logo && <Logo sx={styles.logo} />}
        {isDesktop && bottomLinks}
      </Box>
      {!isLoggedIn && (
        <Box component="span" sx={styles.loginButtonAndLanguageSelectWrapper}>
          {!isMobile && languageSelectProps && (
            <MAFSelect
              {...languageSelectProps}
              wrapperProps={{
                formControlProps: {
                  sx: styles.languageSelectDesktopFormControlWrapper,
                },
              }}
              sx={styles.languageSelectDesktop}
            />
          )}
          {loginButton}
        </Box>
      )}

      {isMenuVisible && <MAFMenu {...menuProps} />}
      {isTablet && <Box sx={styles.bottomLinkTablet}>{bottomLinks}</Box>}
    </>
  );

  return (
    <>
      {!isMobile && (topLinks || topText) && (
        <Box sx={styles.topLinksRootWrapper}>
          <Box sx={styles.linkWrapper}>
            <Box ref={topLinksRef} sx={styles.top}>
              <Box sx={styles.topLinksWrapper}>
                {topLinks?.map(({ componentType, props }, index) => (
                  <Box
                    key={`MAFAppBarTopLinks-${componentType}-${index}`}
                    sx={[
                      styles.topLinkWrapperText,
                      componentType === MAFAppBarTopLinkComponents.LINK && styles.topLinkWrapper,
                      componentType === MAFAppBarTopLinkComponents.LINK &&
                        Boolean(props.isBold) &&
                        styles.topLinkSelected,
                    ]}
                    {...(componentType === MAFAppBarTopLinkComponents.LINK &&
                    Boolean(props?.levels[0]?.onClick)
                      ? { onClick: props.levels[0].onClick }
                      : {})}
                  >
                    {TopLinkComponents[componentType](props as any)}
                  </Box>
                ))}
              </Box>
              <Box sx={styles.topText}>{topText}</Box>
            </Box>
          </Box>
        </Box>
      )}

      <Slide appear={false} direction="down" in={!isScrollingDown}>
        <Box
          ref={appBarRef}
          sx={[
            !isScrollingDown && !isAppBarInViewport && styles.appBarScrollUp,
            isScrollingDown && isAppBarInViewport && styles.appBarScrollDown,
            styles.appBar,
          ]}
        >
          {isDesktop && <Box sx={styles.linkWrapper}>{LinksBar}</Box>}
          {(isMobile || isTablet) && <>{LinksBar}</>}
        </Box>
      </Slide>
    </>
  );
};

MAFAppBar.propTypes = propTypes;

export default MAFAppBar;
