"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: _ref => {
    let {
      breakpoints,
      fieldSizes
    } = _ref;
    return {
      display: 'flex',
      minHeight: 72,
      [breakpoints.up('sm')]: {
        justifyContent: 'space-between'
      },
      [breakpoints.down('lg')]: {
        maxWidth: fieldSizes.maxWidth
      }
    };
  },
  disabledContainer: {},
  name: _ref2 => {
    let {
      breakpoints
    } = _ref2;
    return {
      minHeight: 0,
      margin: '12px 0px',
      [breakpoints.down('lg')]: {
        margin: '22px 0px 0px'
      },
      [breakpoints.only('xs')]: {
        margin: '44px 0px 0px'
      },
      '& > p': {
        marginBottom: '10px'
      }
    };
  },
  price: _ref3 => {
    let {
      breakpoints
    } = _ref3;
    return {
      minHeight: 0,
      margin: '12px 0px',
      [breakpoints.down('lg')]: {
        margin: '0px 0px 22px'
      },
      [breakpoints.only('xs')]: {
        margin: '0px 0px 16px'
      }
    };
  },
  icon: _ref4 => {
    let {
      colors
    } = _ref4;
    return {
      '& g': {
        fill: colors.custom.fontPrimary
      }
    };
  },
  iconGrid: _ref5 => {
    let {
      breakpoints
    } = _ref5;
    return {
      maxWidth: 48,
      marginLeft: 10,
      [breakpoints.up('lg')]: {
        margin: '17px 0px 0px 5px'
      }
    };
  }
};