import { ISxStyles } from '../../../../theming/theme.type';

export const styles: ISxStyles = {
  noResultsText: ({ fontSizes: { extraMedium }, typography, colors }) => ({
    display: 'flex',
    justifyContent: 'center',
    fontFamily: typography.main,
    color: colors.custom.fontPrimary,
    fontSize: extraMedium,
    marginTop: 4,
  }),
};
