const Loader: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 60 60" width="1em" height="1em" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M27 0v6.053C15.161 7.533 6 17.657 6 29.926c0 13.287 10.745 24.06 24 24.06 8.467 0 15.91-4.396 20.183-11.036l5.201 3.012C50.07 54.397 40.688 60 30 60 13.431 60 0 46.535 0 29.926 0 14.33 11.84 1.509 27 0z"
        fill="#003755"
      />
      <path
        d="M33 0c15.16 1.525 27 14.484 27 30.245 0 3.786-.683 7.411-1.932 10.755l-5.285-2.947c.789-2.41 1.217-5.13 1.217-7.808 0-12.4-9.161-22.632-21-24.128V0z"
        fill="#009FDA"
      />
    </g>
  </svg>
);

export default Loader;
