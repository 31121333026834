import { ISxStyles } from '@asfi/maf';

export const styles: ISxStyles = {
  container: ({ colors: { solid } }) => ({
    backgroundColor: solid.white,
    height: 65,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
    width: '100%',
  }),
  leftContainer: {
    width: '20%',
    textAlign: 'left',
    paddingLeft: 6,
  },
  centerContainer: {
    width: '60%',
    display: 'flex',
    justifyContent: 'center',
  },
  rightContainer: {
    width: '20%',
    textAlign: 'right',
    paddingRight: 6,
  },
  icon: {
    width: 16,
    height: 16,
  },
};
