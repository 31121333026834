"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  wrapper: {
    display: 'flex',
    width: '540px',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    padding: '15px',
    '& > svg': {
      width: '300px',
      margin: '0 auto'
    }
  },
  spinnerWrapper: {
    marginTop: '50px'
  },
  logo: {
    maxWidth: 300
  }
};