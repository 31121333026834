const Edit: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-action/Edit/Darkblue">
        <g id="Icon" transform="translate(2.000000, 2.000000)">
          <path
            d="M14.96,18.96225 L1.032,18.96225 L1.032,5.02725 L13.163,5.02725 L10.014,8.17525 C9.972,8.21825 9.939,8.26925 9.919,8.32425 L9.196,10.26325 C9.138,10.41625 9.176,10.58825 9.291,10.70325 C9.371,10.78225 9.477,10.82525 9.586,10.82525 C9.634,10.82525 9.684,10.81625 9.731,10.79925 L11.669,10.07525 C11.725,10.05425 11.776,10.02225 11.818,9.97925 L14.979,6.81925 L14.96,18.96225 Z M19.847,1.24525 L18.751,0.14625 C18.557,-0.04875 18.238,-0.04875 18.043,0.14625 L14.261,3.92925 L14.195,3.99425 L0.516,3.99425 C0.231,3.99425 0,4.22525 0,4.51125 L0,19.47825 C0,19.76325 0.231,19.99425 0.516,19.99425 L15.476,19.99425 C15.761,19.99425 15.992,19.76325 15.992,19.47825 L15.992,5.80525 L16.07,5.72825 L19.846,1.95225 C20.041,1.75725 20.041,1.44125 19.847,1.24525 L19.847,1.24525 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Edit;
