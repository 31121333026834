import { ChangeEvent, MouseEvent, useState } from 'react';

import { IMAFTableData, IMAFTableProps } from './MAFTable.types';
import { propTypes } from './MAFTable.propTypes';
import MAFTableBody from './components/MAFTableBody/MAFTableBody';
import MAFTableWrapper from './components/MAFTableWrapper/MAFTableWrapper';

function MAFTable<T extends IMAFTableData>({
  data,
  fields,
  orderBy,
  isFetching,
  direction,
  onOrder,
  selectCase,
  onItemClick,
  isWithPagination,
  shouldAdjustHeaderPosition,
  isWithStickyHeader,
  headerClass,
  isUplift,
  tableContentRef,
  nsTarget,
  isWithMobile = false,
  noResultsMessage,
  tableClasses,
  tableContainerSx,
  selectedItems,
}: IMAFTableProps<T>) {
  const hasCheckbox = data.some((item) => item.hasCheckbox);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: Nullable<MouseEvent<HTMLButtonElement>>, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <MAFTableWrapper<T>
      hasData={Boolean(data.length)}
      fields={fields}
      direction={direction}
      isFetching={isFetching}
      // spreading as any to workaround a TS issue. Typechecking of props still works.
      // https://github.com/Microsoft/TypeScript/issues/28938
      {...({ orderBy } as any)}
      isUplift={isUplift}
      isWithMobile={isWithMobile}
      isWithStickyHeader={isWithStickyHeader}
      onOrder={onOrder}
      hasCheckbox={hasCheckbox}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      isWithPagination={isWithPagination}
      shouldAdjustHeaderPosition={shouldAdjustHeaderPosition}
      headerClass={headerClass}
      tableContentRef={tableContentRef}
      nsTarget={nsTarget}
      noResultsMessage={noResultsMessage}
      tableContainerSx={tableContainerSx}
    >
      <MAFTableBody<T>
        data={data}
        fields={fields}
        onItemClick={onItemClick}
        hasCheckbox={hasCheckbox}
        selectCase={selectCase}
        rowsPerPage={rowsPerPage}
        page={page}
        isWithPagination={isWithPagination}
        tableClasses={tableClasses}
        selectedItems={selectedItems}
        isUplift={isUplift}
        isWithMobile={isWithMobile}
      />
    </MAFTableWrapper>
  );
}

MAFTable.propTypes = propTypes;

export default MAFTable;
