"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.postPublicComment = exports.postInternalComment = exports.getPublicComments = exports.getInternalComments = void 0;
var _core = require("@northstar/core");
var _requestHandler = _interopRequireDefault(require("../../utils/request-handler"));
const {
  caseDomain,
  vendorCaseDomain
} = _requestHandler.default;
const getInternalComments = _ref => {
  let {
    resourceId
  } = _ref;
  return caseDomain.CASE_GET_INTERNAL_NOTES.call({
    urlParams: {
      resourceId,
      key: resourceId
    }
  });
};
exports.getInternalComments = getInternalComments;
const postInternalComment = _ref2 => {
  let {
    requestBody
  } = _ref2;
  return caseDomain.CASE_CREATE_INTERNAL_NOTE.call({
    body: requestBody
  });
};
exports.postInternalComment = postInternalComment;
const getPublicComments = _ref3 => {
  let {
    resourceId
  } = _ref3;
  const correctRoute = _core.DomainUtils.getByExternalOrInternal(vendorCaseDomain.CASE_GET_PUBLIC_NOTES, caseDomain.CASE_GET_PUBLIC_NOTES);
  return correctRoute.call({
    urlParams: {
      resourceId,
      key: resourceId
    }
  });
};
exports.getPublicComments = getPublicComments;
const postPublicComment = _ref4 => {
  let {
    requestBody
  } = _ref4;
  const correctRoute = _core.DomainUtils.getByExternalOrInternal(vendorCaseDomain.CASE_CREATE_PUBLIC_NOTE, caseDomain.CASE_CREATE_PUBLIC_NOTE);
  return correctRoute.call({
    body: requestBody
  });
};
exports.postPublicComment = postPublicComment;