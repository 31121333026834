import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  additionalDetails: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          isLongTitle: PropTypes.bool,
          isSubtitle: PropTypes.bool,
          name: PropTypes.node,
          value: PropTypes.node,
        }),
      ).isRequired,
    }),
  ),
  changeCurrentStep: PropTypes.func,
  children: PropTypes.node,
  editLabel: PropTypes.node,
  header: PropTypes.node,
  isStrongVariant: PropTypes.bool,
  summaryDetails: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          isLongTitle: PropTypes.bool,
          isSubtitle: PropTypes.bool,
          name: PropTypes.node,
          value: PropTypes.node,
        }),
      ).isRequired,
    }),
  ).isRequired,
} as any;
