"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  control: _ref => {
    let {
      spacing
    } = _ref;
    return {
      padding: spacing(0, 3)
    };
  }
};