"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkAppVersionSaga = checkAppVersionSaga;
exports.default = app;
exports.redirectAndClearError = redirectAndClearError;
var _effects = require("redux-saga/effects");
var _vendor = require("../../vendor.config");
var _authSelectors = require("../auth/authSelectors");
var _notificationsReducer = require("../notifications/notificationsReducer");
var _reduxSagaUtils = require("../../utils/redux-saga-utils");
var appApi = _interopRequireWildcard(require("./appApi"));
var _appReducer = require("./appReducer");
var _appSelectors = require("./appSelectors");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function* redirectAndClearError() {
  try {
    const action = yield (0, _effects.select)((0, _appSelectors.selectAction)());
    if (action) {
      yield (0, _effects.put)(action);
    }
    yield (0, _effects.put)((0, _appReducer.clearAppState)({
      keepExpanded: true
    }));
  } catch (e) {
    yield (0, _effects.put)((0, _appReducer.clearAppState)({
      keepExpanded: true
    }));
  }
}
function* versionPolling() {
  while (true) {
    yield (0, _effects.delay)(_vendor.LONG_POLL_VERSION_DELAY);
    yield (0, _effects.call)(checkAppVersionSaga);
  }
}
function* checkAppVersionSaga() {
  try {
    const {
      version
    } = yield (0, _effects.call)(appApi.getAppVersion);
    const shouldRefresh = version !== process.env.REACT_APP_VERSION;
    yield (0, _effects.put)((0, _appReducer.setForceRefresh)(shouldRefresh));
  } catch (e) {
    // we do nothing, and will retry in one minute
  }
}
function logErrorSaga(_ref) {
  let {
    payload
  } = _ref;
  return function* () {
    try {
      const {
        error
      } = payload;
      const username = yield (0, _effects.select)((0, _authSelectors.selectUsername)());
      yield (0, _effects.call)(appApi.logError, {
        username,
        error,
        href: window.location.href
      });
    } catch (e) {
      // don't want to show user about failed log request
    }
  }();
}
function updateLongPollingData(_ref2) {
  let {
    type
  } = _ref2;
  return function* () {
    const {
      type: setForceRefreshType
    } = (0, _appReducer.setForceRefresh)();
    const {
      type: getAllNotificationsRequestType
    } = (0, _notificationsReducer.getAllNotificationsRequest)();
    const {
      type: getAllNotificationsResponseType
    } = (0, _notificationsReducer.getAllNotificationsResponse)();
    const {
      type: setLongPollingDataType
    } = (0, _appReducer.setLongPollingData)();
    if (![setForceRefreshType, getAllNotificationsRequestType, getAllNotificationsResponseType, setLongPollingDataType].includes(type)) {
      yield (0, _effects.put)((0, _appReducer.setLongPollingData)({
        lastActionTimestamp: Date.now(),
        isAllowed: true
      }));
    } else {
      const isLongPollingAllowed = yield (0, _effects.select)((0, _appSelectors.selectIsLongPollingAllowed)());
      const lastActionTimestamp = yield (0, _effects.select)((0, _appSelectors.selectLongPollingLastActionTimestamp)());
      if (isLongPollingAllowed && Date.now() - _vendor.LONG_POLL_DISABLE_AFTER > lastActionTimestamp) {
        yield (0, _effects.put)((0, _appReducer.setLongPollingData)({
          lastActionTimestamp,
          isAllowed: false
        }));
      }
    }
  }();
}
function* app() {
  if (process.env.NODE_ENV === 'production') {
    yield (0, _effects.fork)(versionPolling);
  }
  yield (0, _effects.takeLatest)(_appReducer.logError, logErrorSaga);
  yield (0, _effects.takeLatest)(_appReducer.redirectAndClose, redirectAndClearError);
  yield (0, _reduxSagaUtils.takeLatestAndWaitForPreviousToFinish)('*', updateLongPollingData);
}