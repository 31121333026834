const Home: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-main-navigation/Home-unselected/Darkblue">
        <g id="Icon" transform="translate(3.000000, 3.000000)">
          <path
            d="M17.8,7.1 L16,5.6 L16,1.5 C16,1.2 15.8,1 15.5,1 L12.5,1 C12.2,1 12,1.2 12,1.5 L12,2.3 L9.3,0.1 C9.1,-3.63795799e-13 8.9,-3.63795799e-13 8.7,0.1 L0.2,7.1 C0.1,7.2 0,7.4 0,7.5 L0,18.5 C0,18.8 0.2,19 0.5,19 L17.5,19 C17.8,19 18,18.8 18,18.5 L18,7.5 C18,7.3 17.9,7.2 17.8,7.1 L17.8,7.1 Z M17,18 L11.5,18 L11.5,11.5 C11.5,11.2 11.3,11 11,11 L7,11 C6.7,11 6.5,11.2 6.5,11.5 L6.5,18 L1,18 L1,7.7 L9,1.1 L12.2,3.7 C12.3,3.8 12.6,3.8 12.7,3.8 C12.9,3.7 13,3.6 13,3.4 L13,2 L15,2 L15,5.9 C15,6.1 15.1,6.2 15.2,6.3 L17,7.7 L17,18 L17,18 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Home;
