"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.responseMappers = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectUtils = require("../../utils/object-utils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const mapCurrentUsersResponse = response => {
  const camelCasedUsers = (0, _objectUtils.objectToCamelCase)({
    obj: response
  });
  const {
    gdprConsent
  } = camelCasedUsers;
  return _objectSpread(_objectSpread({}, camelCasedUsers), {}, {
    gdprConsent: Boolean(gdprConsent).toString()
  });
};
const responseMappers = exports.responseMappers = {
  mapCurrentUsers: mapCurrentUsersResponse
};
var _default = exports.default = {
  responseMappers
};