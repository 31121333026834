"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.getCase = getCase;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _core = require("@northstar/core");
var _effects = require("redux-saga/effects");
var _shortid = _interopRequireDefault(require("shortid"));
var _objectUtils = require("../../utils/object-utils");
var _getCaseActions = require("./getCaseActions");
var caseAPI = _interopRequireWildcard(require("./getCaseApi"));
var _getCaseMapper = require("./getCaseMapper");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } // Case detail module
function fillAssetInformation(_ref) {
  let {
    asset,
    mappedCase
  } = _ref;
  return function* () {
    const {
      retrofit
    } = _getCaseMapper.responseMappers.mapEditableEquipment(asset);
    const mappedRetrofit = retrofit.filter(_ref2 => {
      let {
        idInAssetCatalogue
      } = _ref2;
      return !idInAssetCatalogue;
    }).map(retro => _objectSpread(_objectSpread({}, retro), {}, {
      id: _shortid.default.generate(),
      dynamic: true
    }));
    yield (0, _effects.put)((0, _getCaseActions.prefillEquipmentFromCase)({
      selected: _getCaseMapper.responseMappers.mapCaseAssetEquipment(mappedCase),
      retrofit: mappedRetrofit
    }));
  }();
}
function getCase(_ref3) {
  let {
    payload
  } = _ref3;
  return function* () {
    const {
      resourceId,
      draftScenarioId,
      redirect,
      shouldStoreAsset = true
    } = payload;
    const response = yield (0, _effects.call)(caseAPI.getCase, {
      resourceId,
      draftScenarioId
    });
    const mappedCase = _getCaseMapper.responseMappers.mapCase(response);
    if (!_core.ObjectUtils.isNullOrEmpty(response.asset) && !payload.skip) {
      const asset = (0, _objectUtils.objectToCamelCase)({
        obj: response.asset
      });
      const {
        detailsPL,
        detailsHP,
        detailsCMOL
      } = mappedCase.asset;
      yield (0, _effects.call)(fillAssetInformation, {
        asset,
        mappedCase
      });
      const isCaseOL = detailsPL || detailsCMOL;
      const assetMapped = isCaseOL ? _getCaseMapper.responseMappers.mapAssetFromOLCaseForDetails(asset) : _getCaseMapper.responseMappers.mapAssetToStore({
        asset,
        details: detailsHP ? 'assetDetailsResponseSehp' : 'assetDetailsResponseSecomfl'
      });
      if (shouldStoreAsset) yield (0, _effects.put)((0, _getCaseActions.storeAssetFromCase)(assetMapped));
      yield (0, _effects.put)((0, _getCaseActions.setQuoteFromCase)(mappedCase.asset));
    }
    const {
      applicant
    } = mappedCase;
    yield (0, _effects.put)((0, _getCaseActions.updateApplicantsFromCase)(_getCaseMapper.responseMappers.mapApplicantsFromCase(applicant)));
    if (typeof redirect === 'function') {
      yield (0, _effects.call)(redirect);
    }
    return mappedCase;
  }();
}
var _default = exports.default = getCase;