import { FC, ReactNode } from 'react';
import { InputAdornment, InputProps as IInputProps, Box, SxProps, Theme } from '@mui/material';

import { handleCustomSx } from '../../utils';
import MAFTextField, { IMAFTextFieldProps } from '../MAFTextField/MAFTextField';

import { styles } from './MAFTextFieldWithIcon.styles';
import { propTypes } from './MAFTextFieldWithIcon.propTypes';

export interface IMAFTextFieldWithIconProps extends IMAFTextFieldProps {
  icon?: ReactNode;
  iconPosition?: 'start' | 'end';
  adornmentClass?: SxProps<Theme>;
}

const MAFTextFieldWithIcon: FC<IMAFTextFieldWithIconProps> = ({
  additionalInputProps,
  icon,
  iconPosition = 'end',
  adornmentClass = {},
  ...rest
}) => {
  const adornment = (
    <InputAdornment position={iconPosition}>
      <Box sx={[styles.adornment, ...handleCustomSx(adornmentClass)]}>{icon}</Box>
    </InputAdornment>
  );

  const adornmentPosition = iconPosition === 'start' ? 'startAdornment' : 'endAdornment';

  const customProps = {
    additionalInputProps: {
      ...additionalInputProps,
      [adornmentPosition]: adornment,
    } as IInputProps,
  };

  return <MAFTextField {...customProps} {...rest} />;
};

MAFTextFieldWithIcon.propTypes = propTypes;

export default MAFTextFieldWithIcon;
