"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.camelToSnake = exports.snakeToCamelCase = void 0;
var normalizeValue = function (value) {
    var type = typeof value;
    if (value === null || value === undefined) {
        return null;
    }
    return {
        object: function () { return JSON.stringify(value); },
        number: function () { return value.toString(); },
        string: function () { return value.toUpperCase(); },
        boolean: function () { return value.toString(); },
    }[type]();
};
var snakeToCamelCase = function (string) {
    return string.replace(/(_\w)/g, function (match) { return match[1].toUpperCase(); });
};
exports.snakeToCamelCase = snakeToCamelCase;
var camelToSnake = function (string) {
    return string.replace(/([A-Z])/g, function ($1) { return "_" + $1.toLowerCase(); });
};
exports.camelToSnake = camelToSnake;
exports.default = {
    normalizeValue: normalizeValue,
    snakeToCamelCase: exports.snakeToCamelCase,
    camelToSnake: exports.camelToSnake,
};
