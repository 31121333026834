import { FC, useState, useEffect, SyntheticEvent } from 'react';
import { SxProps, Theme, Tooltip, TooltipProps } from '@mui/material';

import MAFForwarder from '../MAFForwarder/MAFForwarder';
import { handleCustomSx } from '../../utils';

import { styles } from './MAFTooltip.styles';
import { propTypes } from './MAFTooltip.propTypes';

export interface IMAFTooltipProps extends TooltipProps {
  isError?: boolean;
  additionalTooltipClass?: SxProps<Theme>;
  shouldForwardRef?: boolean;
  leaveTouchDelay?: number;
  forwardRefClass?: SxProps<Theme>;
}

const MAFTooltip: FC<IMAFTooltipProps> = ({
  children,
  placement = 'bottom-start',
  isError = false,
  additionalTooltipClass,
  shouldForwardRef = false,
  forwardRefClass,
  leaveTouchDelay = 10 * 60 * 1000,
  onOpen,
  onClose,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const onScroll = () => setIsOpen(false);
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleOpen = (e: SyntheticEvent) => {
    setIsOpen(true);
    onOpen && onOpen(e);
  };

  const handleClose = (e: SyntheticEvent) => {
    setIsOpen(false);
    onClose && onClose(e);
  };

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: [
            styles.tooltip,
            isError && styles.tooltipError,
            ...handleCustomSx(additionalTooltipClass),
          ],
        },
        arrow: { sx: [styles.arrow, isError && styles.arrowError] },
      }}
      placement={placement}
      arrow
      enterTouchDelay={0}
      leaveTouchDelay={leaveTouchDelay}
      {...rest}
      onOpen={handleOpen}
      onClose={handleClose}
      open={isOpen}
    >
      {shouldForwardRef ? <MAFForwarder sx={forwardRefClass}>{children}</MAFForwarder> : children}
    </Tooltip>
  );
};

MAFTooltip.propTypes = propTypes;

export default MAFTooltip;
