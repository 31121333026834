import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  defaultBreakpoint: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']),
  isFullWidth: PropTypes.bool,
  isSticky: PropTypes.bool,
  isVertical: PropTypes.bool,
  primaryButtonProps: PropTypes.object,
  primaryButtonText: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
    .isRequired,
  secondaryButtonProps: PropTypes.object,
  secondaryButtonText: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
    .isRequired,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
} as any;
