import { fromJS } from 'immutable';
import { createAction, handleActions, combineActions } from 'redux-actions';
import { caseDetail } from '@northstar/core-ui/modules';

import {
  postHirePurchaseQuoteRequest,
  saveCalculationsRequest,
} from 'modules/hirePurchase/hirePurchaseReducer';
import {
  recalculatePriceRequest,
  saveQuoteCalculationsRequestOL,
  saveQuoteCalculationsResponseOL,
  calculateQuoteRequestCMFL,
} from 'modules/quote/quoteReducer';

/**
 * Actions
 */
export const updateCaseWithAssetRequest = createAction(
  'CASE_DETAIL_UPDATE_CASE_WITH_ASSET_REQUEST',
);
export const updateCaseWithAssetResponse = createAction(
  'CASE_DETAIL_UPDATE_CASE_WITH_ASSET_RESPONSE',
);
export const updateHirePurchaseCalculationsRequest = createAction(
  'CASE_DETAIL_UPDATE_HP_CALCULATIONS_REQUEST',
);
export const updateHirePurchaseCalculationsResponse = createAction(
  'CASE_DETAIL_UPDATE_HP_CALCULATIONS_RESPONSE',
);
export const updateAssetPriceCMFLRequest = createAction(
  'CASE_DETAIL_UPDATE_ASSET_PRICE_CMFL_REQUEST',
);
export const updateAssetPriceCMFLResponse = createAction(
  'CASE_DETAIL_UPDATE_ASSET_PRICE_CMFL_RESPONSE',
);
export const prefillCMFLValues = createAction('CASE_DETAIL_PREFILL_CMFL_VALUES');
export const stopUpdatingCase = createAction('CASE_DETAIL_STOP_UPDATING_CASE');
export const resetIsFormEditedState = createAction('CASE_DETAIL_RESET_FORM_EDITED');
export const clearCaseAssetState = createAction('CASE_DETAIL_CLEAR_ASSET_STATE');

/**
 * Initial state
 */
const initialState = fromJS({
  updatingAsset: false,
  isFormEditedAfterSave: false,
  asset: {},
});

/**
 * Actions handlers
 */
export default handleActions(
  {
    [combineActions(
      updateCaseWithAssetRequest,
      updateHirePurchaseCalculationsRequest,
      updateAssetPriceCMFLRequest,
      saveQuoteCalculationsRequestOL,
    )](state) {
      return state.set('updatingAsset', true);
    },
    // These requests are called if case FORM is edited
    [combineActions(
      recalculatePriceRequest,
      postHirePurchaseQuoteRequest,
      calculateQuoteRequestCMFL,
    )](state, { payload }) {
      if (payload && payload.isInitialLoad) {
        return state.set('isFormEditedAfterSave', false);
      }
      return state.set('isFormEditedAfterSave', true);
    },
    [combineActions(
      saveQuoteCalculationsResponseOL,
      saveCalculationsRequest,
      updateAssetPriceCMFLResponse,
      resetIsFormEditedState,
    )](state) {
      return state.set('isFormEditedAfterSave', false);
    },
    [combineActions(
      updateCaseWithAssetResponse,
      updateHirePurchaseCalculationsResponse,
      updateAssetPriceCMFLResponse,
      saveQuoteCalculationsResponseOL,
      stopUpdatingCase,
    )](state) {
      return state.set('updatingAsset', false);
    },
    [caseDetail.storeAssetFromCase](state, { payload, error }) {
      return !error ? state.set('asset', fromJS(payload)) : state;
    },
    [clearCaseAssetState](state) {
      return state.set('asset', fromJS({}));
    },
  },
  initialState,
);
