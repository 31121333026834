import { caseDetail } from '@northstar/core-ui/modules';
import { fromJS } from 'immutable';
import { createAction, handleActions, combineActions } from 'redux-actions';

import {
  updateHirePurchaseCalculationsResponse,
  updateAssetPriceCMFLResponse,
} from '../caseDetail/caseAssetReducer';
import { updateGeneralInformationResponse } from '../caseDetail/caseDetailReducer';
/**
 * Actions
 */
export const getCarInfoRequest = createAction('ASSET_GET_CAR_INFO_REQUEST');
export const getCarInfoResponse = createAction('ASSET_GET_CAR_INFO_RESPONSE');
export const getAssetRequest = createAction('ASSET_GET_ASSET_REQUEST');
export const getAssetResponse = createAction('ASSET_GET_ASSET_RESPONSE');
export const submitAssetResponse = createAction('ASSET_SUBMIT_ASSET_RESPONSE');
export const openAssetContactFormDialog = createAction('ASSET_OPEN_CONTACT_FORM_DIALOG');
export const closeContactFormDialog = createAction('ASSET_CLOSE_CONTACT_FORM_DIALOG');
export const clearAssetState = createAction('ASSET_CLEAR_STATE');
export const changeAssetPreviousLicencePlate = createAction('ASSET_CHANGE_PREVIOUS_LICENCE_PLATE');
export const changeAssetPreviousId = createAction('ASSET_CHANGE_PREVIOUS_ID');
export const clearPreviousAssetProperties = createAction('ASSET_CLEAR_PREVIOUS_PROPERTIES');
export const prefillAssetDataForConfigurationOLRequest = createAction(
  'ASSET_PREFILL_ASSET_DATA_FOR_CONFIGURATION_OL_REQUEST',
);
export const prefillAssetDataForConfigurationHPFLRequest = createAction(
  'ASSET_PREFILL_ASSET_DATA_FOR_CONFIGURATION_HPFL_REQUEST',
);
export const prefillAssetDataForConfigurationResponse = createAction(
  'ASSET_PREFILL_ASSET_DATA_FOR_CONFIGURATION_RESPONSE',
);

/**
 * Initial state
 */
export const initialState = fromJS({
  asset: {},
  assetPreviousLicencePlate: '',
  assetPreviousId: 0,
  brands: [],
  gettingCarInfo: false,
  carInfoDialog: false,
  gettingAsset: false,
  submittingAsset: false,
  assetContactForm: {
    displayed: false,
    submitButtonText: '',
  },
  carInfoError: '',
  prefillingAsset: false,
});

/**
 * Actions handlers
 */
export default handleActions(
  {
    [getCarInfoRequest](state) {
      return state.set('carInfoError', '').set('gettingCarInfo', true);
    },
    [getCarInfoResponse](state, { payload, error }) {
      return !error
        ? state.set('asset', fromJS(payload)).set('carInfoError', '').set('gettingCarInfo', false)
        : state.set('carInfoError', payload.message).set('gettingCarInfo', false);
    },
    [combineActions(submitAssetResponse)](state, { payload, error }) {
      return !error
        ? state.set('asset', fromJS(payload)).set('submittingAsset', false)
        : state.set('submittingAsset', false);
    },
    [getAssetRequest](state) {
      return state.set('gettingAsset', true);
    },
    [combineActions(
      prefillAssetDataForConfigurationOLRequest,
      prefillAssetDataForConfigurationHPFLRequest,
    )](state) {
      return state.set('prefillingAsset', true);
    },
    [prefillAssetDataForConfigurationResponse](state) {
      return state.set('prefillingAsset', false);
    },
    // Currently dublicates with submitAssetResponse action creator.
    // Idea separating them is to ensure that response of asset does
    // not causing conflicts with submitting
    [combineActions(getAssetResponse, caseDetail.storeAssetFromCase)](state, { payload, error }) {
      return !error
        ? state.set('asset', fromJS(payload)).set('gettingAsset', false)
        : state.set('gettingAsset', false);
    },
    [openAssetContactFormDialog](state, { payload }) {
      return state.set('assetContactForm', fromJS({ displayed: true, ...payload }));
    },
    [combineActions(updateGeneralInformationResponse, closeContactFormDialog)](state) {
      return state.set('assetContactForm', fromJS({ displayed: false }));
    },
    [clearAssetState](state) {
      const { assetPreviousLicencePlate, assetPreviousId } = state.toJS();
      return fromJS({ ...initialState.toJS(), assetPreviousLicencePlate, assetPreviousId });
    },
    [updateHirePurchaseCalculationsResponse](state, { payload, error }) {
      return !error
        ? state
            .setIn(['asset', 'mileage'], payload.mileage)
            .setIn(['asset', 'state'], payload.state)
        : state;
    },
    [updateAssetPriceCMFLResponse](state, { payload, error }) {
      return !error ? state.setIn(['asset', 'mileage'], payload.mileage) : state;
    },
    [changeAssetPreviousLicencePlate](state, { payload }) {
      return state.set('assetPreviousLicencePlate', payload.licencePlate);
    },
    [changeAssetPreviousId](state, { payload }) {
      return state.set('assetPreviousId', payload.id);
    },
    [clearPreviousAssetProperties](state) {
      return state.set('assetPreviousLicencePlate', '').set('assetPreviousId', 0);
    },
  },
  initialState,
);
