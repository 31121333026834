"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.responseMappers = exports.requestMappers = exports.formMappers = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _core = require("@northstar/core");
var _maf = require("@asfi/maf");
var objectUtils = _interopRequireWildcard(require("../../utils/object-utils"));
const _excluded = ["framePicFile"],
  _excluded2 = ["vehicleType"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const mapFrame = frame => {
  const _objectUtils$objectTo = objectUtils.objectToCamelCase({
      obj: frame
    }),
    {
      framePicFile
    } = _objectUtils$objectTo,
    mappedFrame = (0, _objectWithoutProperties2.default)(_objectUtils$objectTo, _excluded);
  return _objectSpread(_objectSpread({}, mappedFrame), {}, {
    framePic: framePicFile ? "".concat(_maf.FILE_IMAGE_BASE, "/").concat(framePicFile) : null
  });
};
const mapFrameOptions = options => Object.keys(options).reduce((remappedOptions, optionKey) => _objectSpread(_objectSpread({}, remappedOptions), {}, {
  [_core.StringUtils.snakeToCamelCase(optionKey)]: options[optionKey].map(optionValue => ({
    label: optionValue.toString(),
    value: optionValue.toString().toLowerCase()
  })).sort((_ref, _ref2) => {
    let {
      label: a
    } = _ref;
    let {
      label: b
    } = _ref2;
    if (typeof a === 'string') {
      return a.localeCompare(b);
    }
    return a - b;
  }).filter((_ref3, index, array) => {
    let {
      value
    } = _ref3;
    return array.findIndex(item => item.value === value) === index;
  })
}), {});
const mapFrameParameter = parameter => parameter ? [parameter] : [];
const mapFrameFormToRequest = formValues => {
  if (!_core.ObjectUtils.isNullOrEmpty(formValues)) {
    const {
        vehicleType
      } = formValues,
      restFormValues = (0, _objectWithoutProperties2.default)(formValues, _excluded2);
    const request = Object.keys(restFormValues).reduce((prev, curr) => _objectSpread(_objectSpread({}, prev), {}, {
      [_core.StringUtils.camelToSnake(curr)]: mapFrameParameter(restFormValues[curr])
    }), {});
    return _objectSpread({
      vehicle_type: vehicleType.toUpperCase()
    }, request);
  } else {
    return {};
  }
};
const formMappers = exports.formMappers = {
  mapFrameFormToRequest
};
const requestMappers = exports.requestMappers = {};
const responseMappers = exports.responseMappers = {
  mapFrame,
  mapFrameOptions
};
var _default = exports.default = {
  requestMappers,
  responseMappers,
  formMappers
};