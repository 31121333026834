"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = vendorSaga;
exports.getAllVendorTermsPROL = void 0;
exports.getVendor = getVendor;
exports.getVendorTermsCMOL = getVendorTermsCMOL;
exports.getVendorTermsPROL = getVendorTermsPROL;
exports.updateWinterTiresMatrix = updateWinterTiresMatrix;
var _effects = require("redux-saga/effects");
var vendorApi = _interopRequireWildcard(require("../vendor/vendorApi"));
var _frameFormReducer = require("../frameForm/frameFormReducer");
var _appReducer = require("../app/appReducer");
var _processVariances = require("../processVariances");
var matrixUtils = _interopRequireWildcard(require("../../utils/matrix-utils"));
var _reduxSagaUtils = require("../../utils/redux-saga-utils");
var _vendorDashboardTermsMapper = require("./vendorDashboardTermsMapper");
var _vendorDashboardTermsReducer = require("./vendorDashboardTermsReducer");
var _vendorDashboardTermsSelectors = require("./vendorDashboardTermsSelectors");
var _dashboardSagaUtils = require("./vendorDashboardUtils/dashboard-saga-utils");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function* getVendorTermsPROL() {
  try {
    const response = yield (0, _reduxSagaUtils.callWithAttachers)({
      endpoint: vendorApi.getVendorTermsPROL,
      attachers: [_dashboardSagaUtils.attachRouteVendorId]
    });
    yield (0, _effects.put)((0, _vendorDashboardTermsReducer.getVendorTermsPROLResponse)(_vendorDashboardTermsMapper.responseMappers.mapTermsPROL(response)));
  } catch (e) {
    yield (0, _effects.put)((0, _vendorDashboardTermsReducer.getVendorTermsPROLResponse)(e));
    yield (0, _effects.put)((0, _appReducer.displayError)('errors.failed_to_get_leasing_terms'));
  }
}
function* getVendor() {
  try {
    const response = yield (0, _reduxSagaUtils.callWithAttachers)({
      endpoint: vendorApi.getVendor,
      attachers: [_dashboardSagaUtils.attachRouteVendorId]
    });
    yield (0, _effects.put)((0, _frameFormReducer.setVendorBrands)(response.brands));
    yield (0, _effects.put)((0, _vendorDashboardTermsReducer.getVendorResponse)(_vendorDashboardTermsMapper.responseMappers.mapVendor(response)));
  } catch (e) {
    yield (0, _effects.put)((0, _vendorDashboardTermsReducer.getVendorResponse)(e));
  }
}
const getVendorTermsPRHP = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: vendorApi.getVendorTermsPRHP,
  successActions: [_vendorDashboardTermsReducer.getVendorTermsPRHPResponse],
  responseMapper: _vendorDashboardTermsMapper.responseMappers.mapTermsPRHP,
  errorActions: [_vendorDashboardTermsReducer.getVendorTermsPRHPResponse],
  attachers: [_dashboardSagaUtils.attachRouteVendorId]
});
function getServiceNames(_ref) {
  let {
    payload = {}
  } = _ref;
  return function* () {
    try {
      const response = yield (0, _reduxSagaUtils.callWithAttachers)({
        endpoint: vendorApi.getServiceNames,
        attachers: [_dashboardSagaUtils.attachRouteVendorId]
      });
      yield (0, _effects.put)((0, _vendorDashboardTermsReducer.getServiceNamesResponse)(response));
      const {
        callback
      } = payload;
      if (typeof callback === 'function') {
        yield (0, _reduxSagaUtils.callWithAttachers)({
          endpoint: callback,
          attachers: [_dashboardSagaUtils.attachRouteVendorId]
        });
      }
    } catch (e) {
      yield (0, _effects.put)((0, _vendorDashboardTermsReducer.getServiceNamesResponse)(e));
    }
  }();
}
function* getServiceFirstMatrix() {
  const names = yield (0, _effects.select)((0, _vendorDashboardTermsSelectors.selectServiceRatesNames)());
  if (names.length) {
    yield (0, _effects.put)((0, _vendorDashboardTermsReducer.getServiceMatrixRequest)(names[0].value));
  }
}
function getSingleMatrix(_ref2) {
  let {
    payload
  } = _ref2;
  return function* () {
    try {
      const response = yield (0, _reduxSagaUtils.callWithAttachers)({
        endpoint: vendorApi.getSingleServiceMatrix,
        payload: {
          matrixName: payload
        },
        attachers: [_dashboardSagaUtils.attachRouteVendorId]
      });
      const {
        mileages,
        periods
      } = yield (0, _effects.select)((0, _vendorDashboardTermsSelectors.selectHighestYearlyMileagesAndPeriods)());
      yield (0, _effects.put)((0, _vendorDashboardTermsReducer.getServiceMatrixResponse)(matrixUtils.mapSingleMatrixToStore({
        name: payload,
        rates: response,
        mileages,
        periods
      })));
    } catch (e) {
      yield (0, _effects.put)((0, _vendorDashboardTermsReducer.getServiceMatrixResponse)(e));
    }
  }();
}
function updateSingleMatrix(_ref3) {
  let {
    payload
  } = _ref3;
  return function* () {
    try {
      const {
        formValues,
        name
      } = payload;
      const body = matrixUtils.mapSingleMatrixToRequest({
        formValues,
        name,
        rateFieldName: 'service_rate_ex_vat'
      });
      yield (0, _reduxSagaUtils.callWithAttachers)({
        endpoint: vendorApi.updateSingleServiceMatrix,
        payload: {
          body: {
            body
          }
        },
        attachers: [_dashboardSagaUtils.attachRouteVendorId]
      });
      yield (0, _effects.put)((0, _vendorDashboardTermsReducer.getServiceMatrixRequest)(name));
      yield (0, _effects.put)((0, _appReducer.displaySnackbar)({
        id: 'vendor.edit_service_matrix_success'
      }));
    } catch (e) {
      yield (0, _effects.put)((0, _vendorDashboardTermsReducer.updateServiceMatrixResponse)(e));
    }
  }();
}
function deleteSingleMatrix(_ref4) {
  let {
    payload
  } = _ref4;
  return function* () {
    try {
      const {
        name
      } = payload;
      yield (0, _reduxSagaUtils.callWithAttachers)({
        endpoint: vendorApi.deleteSingleServiceMatrix,
        payload: {
          matrixName: name
        },
        attachers: [_dashboardSagaUtils.attachRouteVendorId]
      });
      yield (0, _effects.put)((0, _vendorDashboardTermsReducer.getServiceNamesRequest)());
      yield (0, _effects.put)((0, _vendorDashboardTermsReducer.getFirstServiceMatrixRequest)());
      yield (0, _effects.put)((0, _appReducer.displaySnackbar)({
        id: 'vendor.delete_service_matrix_success'
      }));
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
      yield (0, _effects.put)((0, _vendorDashboardTermsReducer.deleteServiceMatrixResponse)(e));
    }
  }();
}
function createSingleMatrix(_ref5) {
  let {
    payload
  } = _ref5;
  return function* () {
    try {
      const {
        formValues,
        name
      } = payload;
      const body = matrixUtils.mapSingleMatrixToRequest({
        formValues,
        name,
        rateFieldName: 'service_rate_ex_vat'
      });
      yield (0, _reduxSagaUtils.callWithAttachers)({
        endpoint: vendorApi.createSingleServiceMatrix,
        payload: {
          body: {
            body
          }
        },
        attachers: [_dashboardSagaUtils.attachRouteVendorId]
      });
      yield (0, _effects.put)((0, _vendorDashboardTermsReducer.getServiceNamesRequest)());
      yield (0, _effects.put)((0, _vendorDashboardTermsReducer.getServiceMatrixRequest)(name));
      yield (0, _effects.put)((0, _appReducer.displaySnackbar)({
        id: 'vendor.add_service_matrix_success'
      }));
    } catch (e) {
      yield (0, _effects.put)((0, _vendorDashboardTermsReducer.createServiceMatrixResponse)(e));
    }
  }();
}
function* getVendorTermsCMOL() {
  try {
    const response = yield (0, _reduxSagaUtils.callWithAttachers)({
      endpoint: vendorApi.getVendorTermsCMOL,
      attachers: [_dashboardSagaUtils.attachRouteVendorId]
    });
    yield (0, _effects.put)((0, _vendorDashboardTermsReducer.getVendorTermsCMOLResponse)(_vendorDashboardTermsMapper.responseMappers.mapTermsCMOL(response)));
  } catch (e) {
    yield (0, _effects.put)((0, _vendorDashboardTermsReducer.getVendorTermsCMOLResponse)(e));
    yield (0, _effects.put)((0, _appReducer.displayError)('errors.failed_to_get_leasing_terms'));
  }
}
const getVendorTermsCMFL = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: vendorApi.getVendorTermsCMFL,
  successActions: [_vendorDashboardTermsReducer.getVendorTermsCMFLResponse],
  responseMapper: _vendorDashboardTermsMapper.responseMappers.mapTermsCMFL,
  errorActions: [_vendorDashboardTermsReducer.getVendorTermsCMFLResponse],
  attachers: [_dashboardSagaUtils.attachRouteVendorId]
});
function updateWinterTiresMatrix(_ref6) {
  let {
    payload
  } = _ref6;
  return function* () {
    try {
      const {
        formValues
      } = payload;
      yield (0, _effects.put)((0, _vendorDashboardTermsReducer.saveSingleMatrix)({
        formValues
      }));
      const selectedVendorTerms = yield (0, _effects.select)((0, _vendorDashboardTermsSelectors.selectSelectedVendorTermsFromAll)());
      yield (0, _reduxSagaUtils.callWithAttachers)({
        endpoint: vendorApi.updateVendorTermsPROL,
        payload: _vendorDashboardTermsMapper.requestMappers.mapTermsPROL(selectedVendorTerms),
        attachers: [_dashboardSagaUtils.attachRouteVendorId]
      });
      yield (0, _effects.put)((0, _vendorDashboardTermsReducer.updateWinterTiresMatrixResponse)());
      const isNewMatrix = yield (0, _effects.select)((0, _vendorDashboardTermsSelectors.selectIsNewMatrix)());
      if (isNewMatrix) {
        yield (0, _effects.put)((0, _appReducer.displaySnackbar)({
          id: 'vendor.add_winter_tires_matrix_success'
        }));
      } else {
        yield (0, _effects.put)((0, _appReducer.displaySnackbar)({
          id: 'vendor.edit_winter_tires_matrix_success'
        }));
      }
    } catch (e) {
      yield (0, _effects.put)((0, _vendorDashboardTermsReducer.updateWinterTiresMatrixResponse)(e));
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
    }
  }();
}
const getVendorFeatures = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: vendorApi.getVendorFeatures,
  successActions: [_processVariances.setProcessVariances, _vendorDashboardTermsReducer.getVendorFeaturesResponse],
  responseMapper: _ref7 => {
    let {
      process_configuration: {
        process_variances: processVariances
      }
    } = _ref7;
    return {
      vendorProcessVariances: processVariances
    };
  },
  errorActions: [_vendorDashboardTermsReducer.getVendorFeaturesResponse, _appReducer.displayError]
});
const getAllVendorTermsPROL = exports.getAllVendorTermsPROL = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: vendorApi.getAllVendorTermsPROL,
  attachers: [_dashboardSagaUtils.attachRouteVendorId],
  successActions: [_vendorDashboardTermsReducer.getAllVendorTermsPROLResponse],
  responseMapper: _ref8 => {
    let {
      validity,
      terms
    } = _ref8;
    return _vendorDashboardTermsMapper.responseMappers.mapAllVendorTerms({
      validity,
      terms,
      mapper: _vendorDashboardTermsMapper.responseMappers.mapTermsPROL
    });
  },
  errorActions: [_vendorDashboardTermsReducer.getAllVendorTermsPROLResponse]
});
const getAllVendorTermsPRHP = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: vendorApi.getAllVendorTermsPRHP,
  attachers: [_dashboardSagaUtils.attachRouteVendorId],
  successActions: [_vendorDashboardTermsReducer.getAllVendorTermsPRHPResponse],
  responseMapper: _ref9 => {
    let {
      validity,
      terms
    } = _ref9;
    return _vendorDashboardTermsMapper.responseMappers.mapAllVendorTerms({
      validity,
      terms,
      mapper: _vendorDashboardTermsMapper.responseMappers.mapTermsPRHP
    });
  },
  errorActions: [_vendorDashboardTermsReducer.getAllVendorTermsPRHPResponse]
});
const getAllVendorTermsCMOL = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: vendorApi.getAllVendorTermsCMOL,
  attachers: [_dashboardSagaUtils.attachRouteVendorId],
  successActions: [_vendorDashboardTermsReducer.getAllVendorTermsCMOLResponse],
  responseMapper: _ref10 => {
    let {
      validity,
      terms
    } = _ref10;
    return _vendorDashboardTermsMapper.responseMappers.mapAllVendorTerms({
      validity,
      terms,
      mapper: _vendorDashboardTermsMapper.responseMappers.mapTermsCMOL
    });
  },
  errorActions: [_vendorDashboardTermsReducer.getAllVendorTermsCMOLResponse]
});
const getAllVendorTermsCMFL = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: vendorApi.getAllVendorTermsCMFL,
  attachers: [_dashboardSagaUtils.attachRouteVendorId],
  successActions: [_vendorDashboardTermsReducer.getAllVendorTermsCMFLResponse],
  responseMapper: _ref11 => {
    let {
      validity,
      terms
    } = _ref11;
    return _vendorDashboardTermsMapper.responseMappers.mapAllVendorTerms({
      validity,
      terms,
      mapper: _vendorDashboardTermsMapper.responseMappers.mapTermsCMFL
    });
  },
  errorActions: [_vendorDashboardTermsReducer.getAllVendorTermsCMFLResponse]
});
function* vendorSaga() {
  yield (0, _effects.takeLatest)(_vendorDashboardTermsReducer.getVendorTermsPROLRequest, getVendorTermsPROL);
  yield (0, _effects.takeLatest)(_vendorDashboardTermsReducer.getVendorRequest, getVendor);
  yield (0, _effects.takeLatest)(_vendorDashboardTermsReducer.getVendorTermsPRHPRequest, getVendorTermsPRHP);
  yield (0, _effects.takeLatest)(_vendorDashboardTermsReducer.getServiceNamesRequest, getServiceNames);
  yield (0, _effects.takeLatest)(_vendorDashboardTermsReducer.getServiceMatrixRequest, getSingleMatrix);
  yield (0, _effects.takeLatest)(_vendorDashboardTermsReducer.getFirstServiceMatrixRequest, getServiceFirstMatrix);
  yield (0, _effects.takeLatest)(_vendorDashboardTermsReducer.updateServiceMatrixRequest, updateSingleMatrix);
  yield (0, _effects.takeLatest)(_vendorDashboardTermsReducer.deleteServiceMatrixRequest, deleteSingleMatrix);
  yield (0, _effects.takeLatest)(_vendorDashboardTermsReducer.createServiceMatrixRequest, createSingleMatrix);
  yield (0, _effects.takeLatest)(_vendorDashboardTermsReducer.getVendorTermsCMOLRequest, getVendorTermsCMOL);
  yield (0, _effects.takeLatest)(_vendorDashboardTermsReducer.getVendorTermsCMFLRequest, getVendorTermsCMFL);
  yield (0, _effects.takeLatest)(_vendorDashboardTermsReducer.updateWinterTiresMatrixRequest, updateWinterTiresMatrix);
  yield (0, _effects.takeLatest)(_vendorDashboardTermsReducer.getVendorFeaturesRequest, getVendorFeatures);
  yield (0, _effects.takeEvery)(_vendorDashboardTermsReducer.getAllVendorTermsPROLRequest, getAllVendorTermsPROL);
  yield (0, _effects.takeLatest)(_vendorDashboardTermsReducer.getAllVendorTermsPRHPRequest, getAllVendorTermsPRHP);
  yield (0, _effects.takeEvery)(_vendorDashboardTermsReducer.getAllVendorTermsCMOLRequest, getAllVendorTermsCMOL);
  yield (0, _effects.takeLatest)(_vendorDashboardTermsReducer.getAllVendorTermsCMFLRequest, getAllVendorTermsCMFL);
}