"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: {
    padding: '0px 10px'
  },
  icon: _ref => {
    let {
      colors
    } = _ref;
    return {
      '& g': {
        fill: colors.custom.primaryPR.solid.background
      }
    };
  }
};