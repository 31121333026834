import { ReactChild, FC } from 'react';

import MAFTooltip from '../../MAFTooltip/MAFTooltip';

import { propTypes } from './MAFTooltipWrapper.propTypes';

interface IMAFTooltipWrapper {
  title?: ReactChild;
}

const MAFTooltipWrapper: FC<IMAFTooltipWrapper> = ({ title, children }) =>
  title ? (
    <MAFTooltip title={title} shouldForwardRef>
      <>{children}</>
    </MAFTooltip>
  ) : (
    <>{children}</>
  );

MAFTooltipWrapper.propTypes = propTypes;

export default MAFTooltipWrapper;
