import { keyframes } from '@mui/styled-engine';

import { ISxStyles } from '../../theming/theme.type';

interface IProps {
  variant: 'responsive' | 'large' | 'small';
}

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const useStyles = ({ variant }: IProps): ISxStyles => ({
  spinnerContainer: {
    textAlign: 'center',
    width: '100%',
  },
  icon: ({ breakpoints }) => ({
    animation: `${rotate} infinite 1000ms cubic-bezier(0.25, 0.1, 0.25, 1)`,
    fontSize: variant === 'large' || variant === 'responsive' ? '60px' : '30px',
    [breakpoints.down('md')]: {
      fontSize: variant === 'responsive' ? '30px' : undefined,
    },
  }),
  grey: ({ colors }) => ({
    '& path': {
      '&:first-of-type': {
        fill: colors.custom.spinner.primaryColor,
      },
      '&:last-of-type': {
        fill: colors.custom.spinner.secondaryColor,
      },
    },
  }),
  white: ({ colors }) => ({
    '& path': {
      '&:first-of-type': {
        fill: colors.custom.spinner.secondaryColor,
      },
      '&:last-of-type': {
        fill: colors.custom.spinner.secondaryColor,
      },
    },
  }),
  titleAbove: {
    marginBottom: '20px',
  },
  titleBelow: {
    marginTop: '20px',
  },
});
