"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  input: {
    minWidth: 272
  },
  field: {
    marginBottom: '-20px'
  },
  inputGrid: {
    maxWidth: '280px !important'
  }
};