import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  container: ({ colors }) => ({
    width: '100%',
    '& .MuiInput-underline': {
      '&:before': {
        borderBottomColor: `${colors.solid.darkGray} !important`,
      },
      '&:after': {
        borderBottomColor: `${colors.solid.darkGray} !important`,
      },
    },
  }),
  menuContainer: ({ colors }) => ({
    '& .MuiMenu-paper': {
      ...colors.custom.body,
      '& .Mui-selected': {
        ...colors.custom.sidebar.active,
      },
    },
  }),
  label: ({ colors }) => ({
    color: `${colors.solid.darkGray} !important`,
    margin: '0 0 -4px -14px',
  }),
  icon: ({ colors }) => ({
    '& .MuiSelect-icon': {
      color: `${colors.solid.darkGray} !important`,
      margin: '0 0 -4px -14px',
    },
  }),
  menuField: ({ colors }) => ({
    '& .MuiSelect-select': {
      color: colors.solid.black,
    },
  }),
};
