const Arrows: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M15.1790941,4.14644661 C15.3742555,4.34160808 15.3742555,4.65839192 15.1790941,4.85355339 L11.6435602,8.3890873 C11.4483987,8.58424877 11.1316149,8.58424877 10.9364534,8.3890873 C10.7412919,8.19392582 10.7412919,7.87714199 10.9364534,7.68198052 L13.6185094,4.99992449 L4.34025931,5.0006316 C4.20237349,4.99992449 4.07721559,4.94406306 3.98670592,4.85355339 C3.89619625,4.76304372 3.84033482,4.63788582 3.84033482,4.50070711 C3.84033482,4.22352125 4.06378056,4.00007551 4.34025931,4.00078261 L13.6185094,4.00007551 L10.9364534,1.31801948 C10.7412919,1.12285801 10.7412919,0.806074175 10.9364534,0.610912703 C11.1316149,0.415751232 11.4483987,0.415751232 11.6435602,0.610912703 L15.1790941,4.14644661 Z M0.986705922,13.8535534 C0.791544451,13.6583919 0.791544451,13.3416081 0.986705922,13.1464466 L4.52223983,9.6109127 C4.7174013,9.41575123 5.03418514,9.41575123 5.22934661,9.6109127 C5.42450808,9.80607418 5.42450808,10.122858 5.22934661,10.3180195 L2.54729059,13.0000755 L11.8255407,12.9993684 C11.9634265,13.0000755 12.0885844,13.0559369 12.1790941,13.1464466 C12.2696037,13.2369563 12.3254652,13.3621142 12.3254652,13.4992929 C12.3254652,13.7764788 12.1020194,13.9999245 11.8255407,13.9992174 L2.54729059,13.9999245 L5.22934661,16.6819805 C5.42450808,16.877142 5.42450808,17.1939258 5.22934661,17.3890873 C5.03418514,17.5842488 4.7174013,17.5842488 4.52223983,17.3890873 L0.986705922,13.8535534 Z"
        id="path-1"
      ></path>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-action/Transactions/White">
        <g id="Icon" transform="translate(4.000000, 3.000000)">
          <mask id="mask-2" fill="white">
            <use href="#path-1"></use>
          </mask>
          <use id="Combined-Shape" fill="#FFFFFF" href="#path-1"></use>
        </g>
      </g>
    </g>
  </svg>
);

export default Arrows;
