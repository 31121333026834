import {
  CMFL_FORM_CAR_PRICE,
  CMFL_FORM_DURATION,
  CMFL_FORM_RESIDUAL_VALUE,
} from 'modules/quote/quoteConstants';

import { formMappers as hirePurchaseFormMappers } from '../hirePurchase/hirePurchaseMapper';

const mapFormPRHPtoRequest = (formValues) => {
  const request = hirePurchaseFormMappers.mapFormValuesToRequest(formValues);
  return {
    ...request,
    trade_in_car_registration_number: formValues.tradeinCarRegistrationNumber,
    mileage: formValues.mileage,
    lease_period: request.duration_months,
    selected_car_price_in_vat: request.car_price_in_vat,
  };
};

const mapCalculationsToRequestPRHP = ({ currentCase, asset, frame, equipment, formValues }) => {
  const mappedCalculations = formMappers.mapFormPRHPtoRequest(formValues);
  return {
    resourceId: currentCase.resourceId,
    requestBody: {
      delivery_date: currentCase.deliveryDate,
      ...mappedCalculations,
      plate_number: asset.licencePlate,
      mileage: mappedCalculations.mileage || asset.mileage,
      frame_id: frame.id,
      registration_date: asset.registrationDate,
      vehicle_type: asset.vehicleType || frame.vehicleType,
      registration_number: currentCase.asset.registrationNumber,
      vin_number: currentCase.asset.vinNumber,
      ...equipment,
    },
  };
};

const mapCMFLForm = ({
  formValues: {
    carPrice,
    interestRate,
    duration,
    residualValue,
    climateBonusValue,
    downPaymentPercentage,
    downPaymentValue,
    vendorTermsId,
  },
  useDownPaymentPercentage,
}) => ({
  lease_period: Number(duration),
  selected_car_price_ex_vat: carPrice,
  climate_bonus_value: climateBonusValue,
  car_rv_reference: residualValue,
  down_payment_percentage: useDownPaymentPercentage ? downPaymentPercentage : 0,
  down_payment_value: !useDownPaymentPercentage ? downPaymentValue : 0,
  total_yearly_interest_rate: interestRate,
  vendor_terms_id: vendorTermsId,
});

const mapQuoteToCMFLForm = ({ leasePeriod, carPriceExVat, carRvReference }) => ({
  [CMFL_FORM_CAR_PRICE]: carPriceExVat,
  [CMFL_FORM_DURATION]: leasePeriod,
  [CMFL_FORM_RESIDUAL_VALUE]: carRvReference,
});

const mapCalculationsToRequestCMFL = ({
  currentCase,
  formValues,
  equipment,
  asset,
  frame,
  useDownPaymentPercentage,
}) => ({
  ...mapCMFLForm({ formValues, useDownPaymentPercentage }),
  ...equipment,
  plate_number: asset.licencePlate,
  frame_id: frame.id,
  registration_date: !frame.id ? asset.registrationDate : null,
  delivery_date: currentCase.deliveryDate,
  vehicle_type: asset.vehicleType || frame.vehicleType,
  registration_number: currentCase.asset.registrationNumber,
  vin_number: currentCase.asset.vinNumber,
});

export const requestMappers = {
  mapCalculationsPRHP: mapCalculationsToRequestPRHP,
  mapCalculationsCMFL: mapCalculationsToRequestCMFL,
};

export const formMappers = {
  mapFormPRHPtoRequest,
  mapQuoteToCMFLForm,
};
