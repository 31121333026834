"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.backToFrame = backToFrame;
exports.default = frameSaga;
exports.submitFrame = submitFrame;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _core = require("@northstar/core");
var _reduxForm = require("redux-form");
var _effects = require("redux-saga/effects");
var _frameMappers = require("../asset/frameMappers");
var _frameConstants = require("../frameConstants");
var _appReducer = require("../app/appReducer");
var frameApi = _interopRequireWildcard(require("../asset/frameApi"));
var _frameFormReducer = require("../frameForm/frameFormReducer");
var _vendorDashboardEquipmentReducer = require("./vendorDashboardEquipmentReducer");
var _vendorDashboardFrameReducer = require("./vendorDashboardFrameReducer");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function* submitFrame() {
  try {
    const formValues = yield (0, _effects.select)((0, _reduxForm.getFormValues)(_frameConstants.FRAME_FORM));
    const requestBody = _frameMappers.formMappers.mapFrameFormToRequest(formValues);
    const apiCall = formValues.vehicleType.toLowerCase() === _core.VEHICLE_TYPE_VAN.toLowerCase() ? frameApi.findFrameVan : frameApi.findFrame;
    const response = yield (0, _effects.call)(apiCall, requestBody);
    if (Array.isArray(response) && response.length) {
      yield (0, _effects.put)((0, _vendorDashboardFrameReducer.submitFrameResponse)(_frameMappers.responseMappers.mapFrame(response[0])));
      yield (0, _effects.put)((0, _vendorDashboardEquipmentReducer.getEquipmentRequest)());
      yield (0, _effects.put)((0, _vendorDashboardFrameReducer.changeFormStep)({
        step: 2
      }));
    } else {
      throw new Error('errors.could_not_create_frame');
    }
  } catch (e) {
    yield (0, _effects.put)((0, _vendorDashboardFrameReducer.changeFormStep)({
      step: 1
    }));
    if (e.httpResponse) {
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
    } else {
      yield (0, _effects.put)((0, _appReducer.displayError)(e.message));
    }
  }
}
function backToFrame(_ref) {
  let {
    payload
  } = _ref;
  return function* () {
    yield (0, _effects.put)((0, _vendorDashboardFrameReducer.changeFormStep)({
      step: 1
    }));
    yield (0, _effects.put)((0, _frameFormReducer.revertFields)(_objectSpread({
      latestChange: true
    }, payload)));
  }();
}
function* frameSaga() {
  yield (0, _effects.takeLatest)(_vendorDashboardFrameReducer.submitFrameRequest, submitFrame);
  yield (0, _effects.takeLatest)(_vendorDashboardFrameReducer.backToFrameCreation, backToFrame);
}