import { requestHandler } from '@northstar/core-ui/utils';
const { vendorCaseDomain } = requestHandler;

export const getApplicationStatus = ({ resourceId, importerId, creditApplicationId }) =>
  vendorCaseDomain.CREDIT_GET_STATUS.call({
    urlParams: {
      resourceId,
      importerId,
      creditApplicationId,
    },
  });

export const initiateCredit = ({ resourceId, importerId, draftScenarioId }) =>
  vendorCaseDomain.INITIATE_CREDIT.call({
    urlParams: { resourceId, importerId, draftScenarioId },
  });

export const updateApplicants = ({ resourceId, importerId, draftScenarioId, requestBody }) =>
  vendorCaseDomain.UPDATE_APPLICANTS.call({
    urlParams: { resourceId, importerId },
    body: { ...requestBody, draft_scenario_id: draftScenarioId },
  });

export const updateApplicantsCM = ({ resourceId, importerId, requestBody }) =>
  vendorCaseDomain.UPDATE_APPLICANTS_CM.call({
    urlParams: { resourceId, importerId },
    body: requestBody,
  });

export const updateLegalEntityCM = ({ resourceId, importerId, requestBody }) =>
  vendorCaseDomain.UPDATE_LEGAL_ENTITY_CM.call({
    urlParams: { resourceId, importerId },
    body: requestBody,
  });

export const getCustomerFullName = ({ externalCustomerId }) =>
  vendorCaseDomain.GET_CUSTOMER_FULL_NAME.call({
    urlParams: {
      externalCustomerId,
    },
  });
