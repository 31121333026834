import { ISxStyles } from '../../../theming/theme.type';

export const styles: ISxStyles = {
  mafTabRootStyles: ({ typography, colors }) => ({
    textTransform: 'none',
    fontSize: 16,
    fontFamily: typography.main,
    color: colors.custom.tabs.maf.color,
  }),
};
