import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  direction: PropTypes.oneOf(['asc', 'desc']),
  isUnsortable: PropTypes.bool,
  isUplift: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onOrder: PropTypes.func,
  orderBy: PropTypes.string,
  shouldAdjustHeaderPosition: PropTypes.bool,
  sortField: PropTypes.string,
  sortTitle: PropTypes.node,
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
} as any;
