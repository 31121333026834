import { ReactChild, FC } from 'react';
import { FormHelperText, FormHelperTextProps, SxProps, Theme } from '@mui/material';

import { handleCustomSx } from '../../utils';

import { styles } from './MAFHelperText.styles';
import { propTypes } from './MAFHelperText.propTypes';

export interface IMAFHelperTextProps extends Omit<FormHelperTextProps, 'error'> {
  children?: ReactChild;
  textAlign?: string;
  formHelperTextProps?: FormHelperTextProps;
  sx?: SxProps<Theme>;
  isError?: boolean;
}

const MAFHelperText: FC<IMAFHelperTextProps> = ({
  children: helperText,
  isError = false,
  textAlign,
  formHelperTextProps,
  sx,
  ...rest
}) => {
  const isWithHelperText = Boolean(helperText);
  const helperTextClasses = [
    styles.helperText,
    textAlign === 'right' && styles.helperTextRight,
    isWithHelperText && isError && styles.helperTextVisibleError,
    isWithHelperText && !isError && styles.helperTextFocused,
    isWithHelperText && styles.helperTextVisible,
    ...handleCustomSx(sx),
  ];

  return (
    <FormHelperText sx={helperTextClasses} {...formHelperTextProps} error={isError} {...rest}>
      {helperText}
    </FormHelperText>
  );
};

MAFHelperText.propTypes = propTypes;

export default MAFHelperText;
