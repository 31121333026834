import { ReactNode, FC, useState, MouseEvent } from 'react';
import { Box, SxProps, Theme } from '@mui/material';

import { handleCustomSx } from '../../utils';
import { Close, Info, ErrorSmall, Alert, CheckMark } from '../Icons';
import MAFButton, { IMAFButtonProps } from '../MAFButton/MAFButton';
import MAFIcon from '../MAFIcon/MAFIcon';

import { styles } from './MAFBanner.styles';
import { propTypes } from './MAFBanner.propTypes';

export type TMAFBannerVariant = 'warning' | 'success' | 'info' | 'error';

export interface IMAFButtonPropsExtended extends IMAFButtonProps {
  text?: string;
}

export interface IMAFBannerProps {
  heading?: ReactNode;
  message?: ReactNode;
  actionButtonProps?: IMAFButtonPropsExtended;
  closeButtonProps?: IMAFButtonPropsExtended;
  variant?: TMAFBannerVariant;
  customContent?: ReactNode;
  iconWrapperStyles?: SxProps<Theme>;
  sx?: SxProps<Theme>;
}

const Icons = {
  success: CheckMark,
  info: Info,
  warning: Alert,
  error: ErrorSmall,
};

const MAFBanner: FC<IMAFBannerProps> = ({
  heading,
  message,
  actionButtonProps,
  closeButtonProps,
  variant = 'warning',
  customContent,
  iconWrapperStyles,
  sx,
}) => {
  const [isHidden, setIsHidden] = useState(false);

  const clickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    setIsHidden(true);
    setTimeout(() => closeButtonProps?.onClick && closeButtonProps.onClick(e), 300);
  };

  const Icon = Icons[variant];
  return (
    <Box sx={[styles.container, styles[variant], isHidden && styles.hidden, ...handleCustomSx(sx)]}>
      <Box sx={[styles.iconWrapper, ...handleCustomSx(iconWrapperStyles)]}>
        <MAFIcon Icon={Icon} sx={styles.svgIcon} />
      </Box>
      {!customContent && (
        <Box sx={styles.content}>
          <Box sx={styles.contentText}>
            {heading && <Box sx={styles.heading}>{heading}</Box>}
            <Box sx={styles.message}>{message}</Box>
          </Box>
          {actionButtonProps && (
            <MAFButton size="small" color="secondary" {...actionButtonProps}>
              {actionButtonProps.text}
            </MAFButton>
          )}
        </Box>
      )}

      {customContent}
      {closeButtonProps && (
        <MAFIcon
          Icon={Close}
          sx={styles.svgIcon}
          iconButtonClass={styles.closeButton}
          onClick={clickHandler}
        />
      )}
    </Box>
  );
};

MAFBanner.propTypes = propTypes;

export default MAFBanner;
