import { ISxStyles } from '@asfi/maf';

export const styles: ISxStyles = {
  container: ({ colors: { solid } }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: solid.white,
    padding: '30px 30px 0',
    margin: '20px 0 45px',
    border: `1px solid ${solid.gainsboro}`,
    borderRadius: 2,
  }),
  column: {
    flexDirection: 'column',
    marginBottom: 3,
  },
  tripleColumns: {
    width: '33%',
  },
  doubleColumns: {
    width: '50%',
  },
  value: {
    marginTop: '10px',
  },
};
