import { useState, useEffect, useRef } from 'react';
import { Grid, SxProps, Theme } from '@mui/material';

import MAFSpinner from '../MAFSpinner/MAFSpinner';
import MAFTypography from '../MAFTypography/MAFTypography';
import { handleCustomSx } from '../../utils';

import { useStyles } from './MAFProcessingScreen.styles';
import { propTypes } from './MAFProcessingScreen.propTypes';

export type TMAFProcessingScreenSpinnerVariant = 'large' | 'small' | 'responsive';
export type TMAFProcessingScreenColor = 'grey' | 'white' | 'blue';
export type TMAFProcessingScreenPlacement = 'aboveSpinner' | 'belowSpinner';
export interface IMAFProcessingScreenProps {
  loaderMessages?: React.ReactChild[];
  delay?: number;
  spinnerVariant?: TMAFProcessingScreenSpinnerVariant;
  isTextAboveSpinner?: boolean;
  background?: string;
  spinnerContainerClasses?: SxProps<Theme>;
  spinnerIconClasses?: SxProps<Theme>;
  color?: TMAFProcessingScreenColor;
  sx?: SxProps<Theme>;
  childrenPlacement?: TMAFProcessingScreenPlacement;
  children?: React.ReactNode;
}

const MAFProcessingScreen: React.FC<IMAFProcessingScreenProps> = ({
  loaderMessages,
  delay = 3000,
  spinnerVariant = 'large',
  isTextAboveSpinner,
  background,
  spinnerContainerClasses,
  spinnerIconClasses,
  color,
  sx,
  childrenPlacement = 'aboveSpinner',
  children,
}) => {
  const styles = useStyles({ background });
  const [renderedMessage, setRenderedMessage] = useState(loaderMessages && loaderMessages[0]);
  const currentIndex = useRef(0);
  const handleMessageChange = (messages: React.ReactChild[]) =>
    setInterval(() => {
      if (currentIndex.current === messages.length - 1) {
        currentIndex.current = 0;
        setRenderedMessage(messages[currentIndex.current]);
      } else {
        currentIndex.current += 1;
        setRenderedMessage(messages[currentIndex.current]);
      }
    }, delay);

  useEffect(() => {
    if (loaderMessages && loaderMessages.length > 1) {
      const interval = handleMessageChange(loaderMessages);
      return () => clearInterval(interval);
    }
    return () => {};
  }, [handleMessageChange]);

  return (
    <Grid container sx={[styles.absolute, ...handleCustomSx(sx)]}>
      {childrenPlacement === 'aboveSpinner' && <Grid item> {children}</Grid>}
      <Grid item>
        <MAFSpinner
          variant={spinnerVariant}
          title={<MAFTypography variant="sub1-regular">{renderedMessage}</MAFTypography>}
          color={color}
          isTextAboveSpinner={isTextAboveSpinner}
          containerClass={spinnerContainerClasses}
          iconClass={spinnerIconClasses}
        />
      </Grid>
      {childrenPlacement === 'belowSpinner' && <Grid item>{children}</Grid>}
    </Grid>
  );
};

MAFProcessingScreen.propTypes = propTypes;

export default MAFProcessingScreen;
