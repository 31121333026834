import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  color: PropTypes.oneOf([
    'blank',
    'blue',
    'dark-gray',
    'default',
    'gray',
    'green',
    'primaryCM',
    'primaryCMHighlight',
    'primaryPR',
    'primaryPRHighlight',
    'red',
    'secondary',
    'transparent-dark-gray',
    'transparent-gray',
  ]),
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.string,
} as any;
