"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  title: _ref => {
    let {
      typography: {
        title
      },
      colors,
      breakpoints
    } = _ref;
    return _objectSpread(_objectSpread({
      padding: '30px'
    }, colors.custom.caseDetail.sidebarSection), {}, {
      fontFamily: title,
      color: colors.custom.fontPrimary,
      fontSize: 20,
      [breakpoints.down('lg')]: {
        textAlign: 'center'
      }
    });
  },
  body: _ref2 => {
    let {
      breakpoints
    } = _ref2;
    return {
      display: 'flex',
      flexDirection: 'column',
      padding: '30px',
      [breakpoints.down('lg')]: {
        padding: '44px 90px',
        alignItems: 'center'
      },
      [breakpoints.only('xs')]: {
        padding: '44px 12px'
      }
    };
  },
  topless: {
    padding: '0 30px 30px 15px'
  },
  childrenContainer: _ref3 => {
    let {
      breakpoints
    } = _ref3;
    return {
      [breakpoints.down('lg')]: {
        width: 420
      },
      [breakpoints.only('xs')]: {
        width: 272
      }
    };
  }
};