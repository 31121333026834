"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: {
    alignItems: 'center',
    padding: '32px 0px 19px'
  },
  icon: _ref => {
    let {
      colors
    } = _ref;
    return {
      marginRight: '5px',
      '& g': {
        fill: colors.custom.buttons.secondary.color
      }
    };
  }
};