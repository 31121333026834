interface IErrorIconProps {
  fill?: string;
  isInverted?: boolean;
}

const ErrorIcon: React.FC<IErrorIconProps> = ({ fill, isInverted, ...rest }) => {
  const baseColor = fill || '#fff';
  const stroke = !isInverted ? baseColor : '#3a4344';
  return (
    <svg
      width="364px"
      height="259px"
      viewBox="0 0 364 259"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <polygon
          id="path-error-1"
          points="0.00129 0.775703301 257.742 0.775703301 257.742 250.384752 0.00129 250.384752"
        ></polygon>
        <linearGradient
          x1="136.934694%"
          y1="-37.6123545%"
          x2="-102.605593%"
          y2="205.899741%"
          id="linearGradient-3"
        >
          <stop stopColor="#0AB79E" offset="0%"></stop>
          <stop stopColor="#5672E0" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="Dekstop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="08-404-error" transform="translate(-522.000000, -240.000000)">
          <g id="Group-5" transform="translate(477.000000, 240.000000)">
            <g id="Error-icon" transform="translate(49.000000, 0.000000)">
              <g id="Winter-tire-unselected-Copy" transform="translate(69.000000, 0.000000)">
                <g id="Group-3">
                  <mask id="mask-2" fill="white">
                    <use href="#path-error-1"></use>
                  </mask>
                  <g id="Clip-2"></g>
                  <path
                    d="M130.67829,198.284983 C89.70531,198.284983 56.49297,166.417324 56.49297,127.106841 C56.49297,87.796358 89.70531,55.928699 130.67829,55.928699 C171.64869,55.928699 204.86361,87.796358 204.86361,127.106841 C204.86361,166.417324 171.64869,198.284983 130.67829,198.284983 M0.00129,125.579982 C0.00129,194.507112 24.9365963,221.577365 128.87229,225.458665 C157.885001,226.542095 206.61021,227.68942 228.159126,211.994645 C259.425593,189.222262 250.844443,168.284719 257.74329,125.579982 C268.7402,57.5077113 200.04675,0.77521235 128.87229,0.77521235 C57.69783,0.77521235 0.00129,56.6528523 0.00129,125.579982 Z"
                    id="Fill-1"
                    fill={stroke}
                    mask="url(#mask-2)"
                  ></path>
                </g>
              </g>
              <path
                d="M185.355875,151.268296 C193.273209,143.659879 203.25628,142.513118 215.305088,147.828014"
                id="Path-6"
                stroke={stroke}
                strokeWidth="5"
                strokeLinecap="round"
                transform="translate(200.330481, 147.908785) rotate(10.000000) translate(-200.330481, -147.908785) "
              ></path>
              <polyline
                id="Path-4"
                stroke={stroke}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="166 108 182.818359 112.012695 170.141602 121.326172"
              ></polyline>
              <polyline
                id="Path-4-Copy-2"
                stroke={stroke}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(221.847177, 113.612503) rotate(-165.000000) translate(-221.847177, -113.612503) "
                points="213.437998 106.949417 230.256357 110.962112 217.579599 120.275589"
              ></polyline>
              <polyline
                id="Path-5-Copy-2"
                stroke="url(#linearGradient-3)"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="49.3808594 135.242188 14.0371094 131 43.1289062 158.277344 0 183 52.8945313 187.65625 34.9257812 219.714844 69.8515625 203.171875"
              ></polyline>
              <path
                d="M68,244.94376 C107.970499,232.739663 204.595307,236.62267 357.874424,256.592781"
                id="Path-4-Copy"
                stroke={stroke}
                strokeWidth="4"
                strokeLinecap="round"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ErrorIcon;
