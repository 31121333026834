/**
 * This method performs a check for accessibility navigation check. It only executes the function
 * if it is clicked by Space or Enter
 */
export const handleAccessibilityEvent = (event: unknown, func: () => void) => {
  if (
    typeof event === 'object' &&
    event !== null &&
    !Array.isArray(event) &&
    // eslint-disable-next-line @typescript-eslint/dot-notation
    (event['key'] === 'Enter' || event['key'] === ' ' || event['type'] === 'click')
  ) {
    return func();
  }
};
