"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectVendorBrands = exports.selectStateHistory = exports.selectPreviousOptions = exports.selectIsGettingFrameOptions = exports.selectHasHistory = exports.selectFrameOptions = exports.selectAvailableOptions = void 0;
var _reselect = require("reselect");
const selectState = state => state.frameForm;
const selectFrameOptions = () => (0, _reselect.createSelector)(selectState, state => state.get('options').toJS());
exports.selectFrameOptions = selectFrameOptions;
const selectIsGettingFrameOptions = () => (0, _reselect.createSelector)(selectState, state => state.get('gettingFrameOptions'));
exports.selectIsGettingFrameOptions = selectIsGettingFrameOptions;
const selectStateHistory = () => (0, _reselect.createSelector)(selectState, state => state.get('history').toJS());
exports.selectStateHistory = selectStateHistory;
const selectHasHistory = () => (0, _reselect.createSelector)(selectState, state => state.get('history').size > 1);
exports.selectHasHistory = selectHasHistory;
const selectAvailableOptions = () => (0, _reselect.createSelector)(selectState, state => ({
  brands: state.get('brandNames').toJS(),
  models: state.get('modelNames').toJS(),
  trimLevels: state.get('trimLevels').toJS()
}));
exports.selectAvailableOptions = selectAvailableOptions;
const selectPreviousOptions = () => (0, _reselect.createSelector)(selectState, state => state.get('previousOptions').toJS());
exports.selectPreviousOptions = selectPreviousOptions;
const selectVendorBrands = () => (0, _reselect.createSelector)(selectState, state => state.get('vendorBrands').toJS());
exports.selectVendorBrands = selectVendorBrands;