"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  containerPadding: _ref => {
    let {
      breakpoints
    } = _ref;
    return {
      padding: '44px',
      [breakpoints.down('lg')]: {
        padding: '44px 90px'
      },
      [breakpoints.only('xs')]: {
        padding: '44px 12px'
      }
    };
  },
  container: _ref2 => {
    let {
      breakpoints
    } = _ref2;
    return {
      [breakpoints.down('lg')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }
    };
  },
  background: _ref3 => {
    let {
      colors
    } = _ref3;
    return _objectSpread({}, colors.custom.content);
  },
  header: _ref4 => {
    let {
      breakpoints,
      fontSizes: {
        extraMedium
      },
      typography: {
        title
      }
    } = _ref4;
    return {
      fontFamily: title,
      fontSize: extraMedium,
      textAlign: 'left',
      paddingBottom: '35px',
      [breakpoints.down('lg')]: {
        textAlign: 'center'
      }
    };
  },
  row: _ref5 => {
    let {
      fontSizes: {
        extraSmall
      },
      typography: {
        main
      },
      colors
    } = _ref5;
    return _objectSpread({
      letterSpacing: '0.68px',
      alignItems: 'flex-end',
      fontFamily: main,
      fontSize: extraSmall,
      padding: '10px 0'
    }, colors.custom.specifications);
  },
  rowPrice: _ref6 => {
    let {
      typography: {
        main
      }
    } = _ref6;
    return {
      alignItems: 'flex-end',
      fontFamily: main
    };
  },
  empty: _ref7 => {
    let {
      fontSizes: {
        extraSmall
      },
      typography: {
        main
      }
    } = _ref7;
    return {
      letterSpacing: '0.68px',
      fontFamily: main,
      fontSize: extraSmall,
      paddingLeft: 0
    };
  },
  sectionTitleContainer: _ref8 => {
    let {
      colors,
      fontSizes: {
        small
      },
      typography: {
        main
      }
    } = _ref8;
    return _objectSpread(_objectSpread({
      marginBottom: '25px',
      padding: '10px 0 10px 10px'
    }, colors.custom.content), {}, {
      fontSize: small,
      color: colors.alpha(colors.custom.contentSecondary.color, 0.8),
      fontFamily: main,
      borderLeft: "2px solid ".concat(colors.alpha(colors.custom.contentSecondary.color, 0.8))
    });
  },
  specContainer: _ref9 => {
    let {
      breakpoints
    } = _ref9;
    return {
      [breakpoints.down('lg')]: {
        width: 420
      },
      [breakpoints.only('xs')]: {
        width: 272
      }
    };
  },
  priceContainer: {
    '& > div': {
      margin: 0,
      padding: '0 0 0 15px'
    },
    '& > div:not(:first-child)': {
      padding: '15px'
    },
    '& > div:last-child': {
      paddingTop: '0px'
    }
  },
  padded: {
    paddingLeft: '30px'
  }
};