const Neutral: React.FC<{}> = () => (
  <svg height="48px" width="48px" color="#003755" viewBox="-2 -2 24 24">
    <path
      d="M10,0 C15.523,0 20,4.477 20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 Z M10,1 C5.037,1 1,5.037 1,10 C1,14.963 5.037,19 10,19 C14.963,19 19,14.963 19,10 C19,5.037 14.963,1 10,1 Z M14.5678225,12.8035714 C14.8439649,12.8035714 15.0678225,13.0274291 15.0678225,13.3035714 C15.0678225,13.5797138 14.8439649,13.8035714 14.5678225,13.8035714 L5.44642857,13.8035714 C5.1702862,13.8035714 4.94642857,13.5797138 4.94642857,13.3035714 C4.94642857,13.0274291 5.1702862,12.8035714 5.44642857,12.8035714 L14.5678225,12.8035714 Z M6,7 C6.55228475,7 7,7.44771525 7,8 C7,8.55228475 6.55228475,9 6,9 C5.44771525,9 5,8.55228475 5,8 C5,7.44771525 5.44771525,7 6,7 Z M14,7 C14.5522847,7 15,7.44771525 15,8 C15,8.55228475 14.5522847,9 14,9 C13.4477153,9 13,8.55228475 13,8 C13,7.44771525 13.4477153,7 14,7 Z"
      id="Combined-Shape"
    ></path>
  </svg>
);

export default Neutral;
