const Declined: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M12,22.5 C6.21101269,22.5 1.5,17.7901616 1.5,11.9999477 C1.5,6.21098922 6.21090808,1.5 12,1.5 C17.7901381,1.5 22.5,6.21098922 22.5,11.9999477 C22.5,17.7901616 17.7901381,22.5 12,22.5 M12,0 C5.38317757,0 0,5.3831541 0,11.9999477 C0,18.6168459 5.38317757,24 12,24 C18.6168224,24 24,18.6168459 24,11.9999477 C24,5.3831541 18.6168224,0 12,0 M7.75,11 L16.25,11 C16.6642136,11 17,11.3357864 17,11.75 C17,12.1642136 16.6642136,12.5 16.25,12.5 L7.75,12.5 C7.33578644,12.5 7,12.1642136 7,11.75 C7,11.3357864 7.33578644,11 7.75,11 Z"
        id="declinedIcon"
      ></path>
    </defs>
    <g id="New-affordability-section" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="New-affordability" transform="translate(-174.000000, -1552.000000)">
        <g id="Main-section" transform="translate(120.000000, 104.000000)">
          <g id="Section-6-Copy-2" transform="translate(0.000000, 1317.000000)">
            <g id="Group-5" transform="translate(54.000000, 110.000000)">
              <g id="Group" transform="translate(0.000000, 21.000000)">
                <mask id="mask-2" fill="currentColor">
                  <use xlinkHref="#declinedIcon"></use>
                </mask>
                <use
                  id="Declined-red"
                  fill="currentColor"
                  fillRule="nonzero"
                  xlinkHref="#declinedIcon"
                ></use>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Declined;
