"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  disabledContainer: {
    opacity: '0.5'
  },
  container: {
    minHeight: 48
  },
  checkboxIcon: {
    width: 22,
    height: 22
  }
};