"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const getBorder = _ref => {
  let {
    colors
  } = _ref;
  return "1px solid ".concat(colors.custom.sidebar.borderColor);
};
const styles = exports.styles = {
  container: _ref2 => {
    let {
      colors
    } = _ref2;
    return _objectSpread(_objectSpread({}, colors.custom.appBar), {}, {
      height: 54,
      display: 'flex',
      justifyContent: 'center',
      transform: 'none',
      paddingRight: '0 !important'
    });
  },
  active: _ref3 => {
    let {
      colors
    } = _ref3;
    return _objectSpread({}, colors.custom.sidebar.active);
  },
  inner: {
    flexBasis: 54
  },
  logo: _ref4 => {
    let {
      breakpoints
    } = _ref4;
    return {
      [breakpoints.down('lg')]: {
        margin: 'auto'
      },
      width: '9rem',
      padding: '0 1rem'
    };
  },
  logoContainer: {
    alignItems: 'flex-start'
  },
  badge: {
    padding: '0 15px'
  },
  menu: _ref5 => {
    let {
      colors
    } = _ref5;
    return {
      '.MuiMenu-list': _objectSpread(_objectSpread(_objectSpread({}, colors.custom.body), colors.custom.contentSecondary), {}, {
        '& > :first-child': {
          borderTop: getBorder({
            colors
          }),
          borderBottom: getBorder({
            colors
          })
        },
        '& > *:not(:first-child)': {
          borderBottom: getBorder({
            colors
          })
        }
      })
    };
  },
  iconButtonLeft: {
    marginLeft: 3
  },
  iconButtonRight: {
    marginRight: 3
  }
};