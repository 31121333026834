import { ISxStyles } from '../../theming/theme.type';

const icon = {
  width: 18,
  height: 18,
  cursor: 'pointer',
};

export const styles: ISxStyles = {
  button: {
    '&.MuiButton-root': {
      minWidth: 72,
      padding: 0,
      maxHeight: 48,
      borderRadius: '0 24px 24px 0',
    },
  },
  iconBox: {
    '& button:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconClear: {
    ...icon,
  },
  iconSearch: ({ colors }) => ({
    ...icon,
    '& g': {
      fill: colors.custom.select.selected.color,
    },
  }),
  inputContainer: ({ colors, fieldSizes }) => ({
    maxWidth: `${fieldSizes.maxWidth}px !important`,
    height: 48,
    ...colors.custom.search.border,
    backgroundColor: colors.custom.search.background,
    borderRadius: '24px 0 0 24px',
    '&:hover, &:focus-within': {
      ...colors.custom.inputFull.hover,
    },
  }),
  inputRoot: ({ colors }) => ({
    '&.MuiInputBase-root': {
      color: colors.custom.inputFull.input.color,
      width: '100%',
    },
  }),
  inputInput: ({ colors }) => ({
    '& .MuiInputBase-input': {
      color: colors.custom.inputFull.input.color,
      padding: '12px 0 12px 20px',
      width: '100%',
    },
  }),
};
