"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setProcessVariances = exports.initialState = exports.default = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
const setProcessVariances = exports.setProcessVariances = (0, _reduxActions.createAction)('PROCESS_VARIANCES_SET');
const initialState = exports.initialState = (0, _immutable.fromJS)({
  vendorProcessVariances: {},
  importersProcessVariances: {},
  processVariancesWithImporters: {}
});
var _default = exports.default = (0, _reduxActions.handleActions)({
  [setProcessVariances](state, _ref) {
    let {
      payload
    } = _ref;
    const {
      vendorProcessVariances,
      importersProcessVariances,
      processVariancesWithImporters
    } = payload;
    return state.set('vendorProcessVariances', (0, _immutable.fromJS)(vendorProcessVariances)).set('importersProcessVariances', (0, _immutable.fromJS)(importersProcessVariances || {})).set('processVariancesWithImporters', (0, _immutable.fromJS)(processVariancesWithImporters || {}));
  }
}, initialState);