import { app } from '@northstar/core-ui/modules';
import { PROCESS_VARIANCES } from '@northstar/core';
import { objectUtils } from '@northstar/core-ui/utils';
import { put, takeLatest } from 'redux-saga/effects';
import { callWithAttachers } from '@northstar/core-ui/utils/redux-saga-utils';

import { createAttacherForTermsToUse } from 'modules/vendor/vendorTermsAttachers';

import * as producTermsApi from './productTermsApi';
import {
  getProductTermsPRHPRequest,
  getProductTermsPRHPResponse,
  getProductTermsCMFLRequest,
  getProductTermsCMFLResponse,
} from './productTermsReducer';

export function* getProductTermsPRHP() {
  try {
    const response = yield callWithAttachers({
      endpoint: producTermsApi.getProductTermsPRHP,
      attachers: [createAttacherForTermsToUse(PROCESS_VARIANCES.PRIVATE_HP)],
    });
    yield put(getProductTermsPRHPResponse(objectUtils.objectToCamelCase({ obj: response })));
  } catch (e) {
    yield put(getProductTermsPRHPResponse(e));
    if (e.httpResponse) {
      yield put(app.displayError(e));
    } else {
      yield put(app.displayError(e.message));
    }
  }
}

export function* getProductTermsCMFL() {
  try {
    const response = yield callWithAttachers({
      endpoint: producTermsApi.getProductTermsCMFL,
      attachers: [createAttacherForTermsToUse(PROCESS_VARIANCES.COMMERCIAL_FL)],
    });
    yield put(getProductTermsCMFLResponse(objectUtils.objectToCamelCase({ obj: response })));
  } catch (e) {
    yield put(getProductTermsCMFLResponse(e));
    if (e.httpResponse) {
      yield put(app.displayError(e));
    } else {
      yield put(app.displayError(e.message));
    }
  }
}

export default function* hirePurchase() {
  yield takeLatest(getProductTermsPRHPRequest, getProductTermsPRHP);
  yield takeLatest(getProductTermsCMFLRequest, getProductTermsCMFL);
}
