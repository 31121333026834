"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    '& > :nth-child(2)': {
      justifyContent: 'flex-end'
    },
    '& > :last-child': {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  switch: {
    maxWidth: '60px'
  },
  title: {
    maxWidth: '200px',
    minWidth: '200px'
  },
  field: {
    textAlign: 'left'
  },
  fieldLabel: {
    paddingRight: '5px',
    paddingLeft: '10px'
  },
  disabledText: _ref => {
    let {
      colors
    } = _ref;
    return {
      color: colors.solid.lightRedGray
    };
  },
  rtl: {
    direction: 'rtl'
  },
  adomentDisabled: _ref2 => {
    let {
      colors
    } = _ref2;
    return {
      '& > p': {
        color: colors.solid.lightRedGray
      }
    };
  }
};