export const styles = {
  pageBreak: {
    pageBreakAfter: 'always',
  },
  assetHeader: {
    '@media print': {
      display: 'none',
    },
  },
};
