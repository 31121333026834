import { ISxStyles } from '../../theming/theme.type';

interface IProps {
  topAdjustment: number;
  widthAdjustments?: number;
  hasNoBackground: boolean;
}

export const useStyles = ({
  topAdjustment,
  widthAdjustments,
  hasNoBackground,
}: IProps): ISxStyles => ({
  tableTop: ({ typography: { main }, breakpoints }) => ({
    fontFamily: main,
    width: '100%',
    padding: '28px 0px',
    minHeight: 50,
    [breakpoints.only('xs')]: {
      marginLeft: '12px',
      width: 'calc(100% - 12px)',
    },
  }),
  paper: ({ colors }) => ({
    display: 'flex',
    ...colors.custom.contentAlternative.highlight,
    flexGrow: 1,
    padding: '0px 11px',
  }),
  container: ({ breakpoints }) => ({
    [breakpoints.only('xs')]: {
      flexDirection: 'column-reverse',
    },
  }),
  info: {
    alignSelf: 'center',
    flexGrow: 1,
  },
  actions: {
    cursor: 'pointer',
  },
  actionsAdjusted: ({ breakpoints }) => ({
    cursor: 'pointer',
    [breakpoints.only('xs')]: {
      marginBottom: '10px',
      width: '100%',
      flexWrap: 'nowrap',
    },
  }),
  checkedTop: ({ colors, breakpoints, zIndex }) => ({
    ...colors.custom.contentAlternative.main,
    background: hasNoBackground
      ? colors.danske.white
      : colors.custom.contentAlternative.main.background,
    padding: '28px 28px 28px 0px',
    position: 'fixed',
    // used for detecting IE browser
    '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
      padding: 0,
      position: 'static',
    },
    top: topAdjustment,
    zIndex: zIndex.fab,
    width: `calc(100% - ${widthAdjustments || 145}px)`,
    [breakpoints.down('lg')]: {
      top: topAdjustment,
      width: `calc(100% - ${widthAdjustments || 88}px)`,
    },
    [breakpoints.only('xs')]: {
      padding: '28px 12px',
      top: topAdjustment,
      width: `calc(100% - ${widthAdjustments || 44}px)`,
      marginLeft: '-12px',
    },
  }),
});
