const Logout: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icons-action/Logout/Darkblue">
        <g id="Icon" transform="translate(3.000000, 2.000000)">
          <path
            d="M14.9932,17.5047 C14.9932,18.3307 14.3202,19.0037 13.4932,19.0037 L2.5002,19.0037 C2.0992,19.0037 1.7222,18.8477 1.4392,18.5647 C1.1552,18.2797 0.9992,17.9037 1.0002,17.5027 L1.0062,7.9917 L15.0002,7.9917 L14.9932,17.5047 Z M13.9932,-0.0003 C11.7842,-0.0003 9.9872,1.7967 9.9872,3.9997 L9.9682,6.9917 L0.5062,6.9917 C0.2302,6.9917 0.0062,7.2157 0.0062,7.4917 L0.000200000001,17.5027 C-0.000799999999,18.1697 0.2592,18.7987 0.7312,19.2717 C1.2032,19.7427 1.8312,20.0037 2.5002,20.0037 L13.4932,20.0037 C14.8722,20.0037 15.9932,18.8827 15.9932,17.5047 L16.0002,7.4927 C16.0002,7.3597 15.9472,7.2327 15.8532,7.1387 C15.7602,7.0447 15.6332,6.9917 15.5002,6.9917 L11.9912,6.9687 L11.9912,3.9827 C11.9912,2.8767 12.8872,1.9997 13.9932,1.9997 C15.0992,1.9997 16.0002,2.8997 16.0002,4.0057 C16.0002,4.5577 16.4472,5.0057 17.0002,5.0057 C17.5532,5.0057 18.0002,4.5577 18.0002,4.0057 C18.0002,1.7967 16.2022,-0.0003 13.9932,-0.0003 L13.9932,-0.0003 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Logout;
