"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: {
    padding: '45px',
    color: 'inherit',
    boxShadow: 'none',
    backgroundColor: 'inherit'
  },
  titleText: _ref => {
    let {
      typography: {
        main
      },
      colors
    } = _ref;
    return {
      color: colors.custom.fontPrimary,
      lineHeight: '19px',
      fontWeight: 500,
      fontFamily: main
    };
  },
  expansionSummaryRoot: {
    '&.MuiAccordionSummary-root': {
      padding: 0,
      display: 'flex',
      alignItems: 'flex-start',
      userSelect: 'text'
    }
  },
  expansionSummaryContent: {
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  expansionSummaryWrap: {
    '&:focus': {
      outline: 'none'
    }
  },
  expansionPanelDetails: {
    flexDirection: 'column',
    padding: 0
  },
  icon: {
    '& .MuiAccordionSummary-expandIconWrapper': {
      padding: '12px'
    }
  }
};