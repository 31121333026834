export const styles = {
  icon: {
    margin: '0 6px 0',
    paddingRight: 2,
  },
  iconButton: {
    '&:hover': {
      background: 'transparent',
    },
  },
};
