"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getValueByType = void 0;
var _constants = _interopRequireDefault(require("../../components/NSTable/constants"));
var _stringUtils = require("../../utils/string-utils");
var _translationsUtils = require("../../utils/translations-utils");
const getValueByType = _ref => {
  let {
    type,
    value,
    locale
  } = _ref;
  switch (type) {
    case _constants.default.date:
      return value ? (0, _stringUtils.formatToLocalDate)(value) : _stringUtils.EMPTY_VALUE;
    case _constants.default.timestamp:
      return value ? (0, _stringUtils.formatToLocalTimestamp)(value) : _stringUtils.EMPTY_VALUE;
    case _constants.default.status:
      return value ? (0, _translationsUtils.getTranslationByKey)({
        key: value.text.props.id,
        locale
      }) : _stringUtils.EMPTY_VALUE;
    case _constants.default.productType:
      return value ? (0, _translationsUtils.getTranslationByKey)({
        key: "cases.type.dynamic.".concat(value),
        locale
      }) : _stringUtils.EMPTY_VALUE;
    case _constants.default.productSubType:
      return value ? (0, _translationsUtils.getTranslationByKey)({
        key: "cases.subType.dynamic.".concat(value),
        locale
      }) : _stringUtils.EMPTY_VALUE;
    default:
      return value || _stringUtils.EMPTY_VALUE;
  }
};
exports.getValueByType = getValueByType;