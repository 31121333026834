import { FC, ReactNode } from 'react';
import { SxProps, TableRow, Theme } from '@mui/material';

import { handleCustomSx } from '../../../../utils';

import { styles } from './MAFTableRowMobile.styles';
import { propTypes } from './MAFTableRowMobile.propTypes';

interface IMAFTableRowMobileProps {
  children: ReactNode;
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

const MAFTableRowMobile: FC<IMAFTableRowMobileProps> = ({ children, onClick, sx }) => {
  const rootClasses = [styles.root, Boolean(onClick) && styles.clickable, ...handleCustomSx(sx)];

  return (
    <TableRow onClick={onClick} sx={rootClasses}>
      {children}
    </TableRow>
  );
};

MAFTableRowMobile.propTypes = propTypes;

export default MAFTableRowMobile;
