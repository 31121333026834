import { createSelector } from 'reselect';

import { selectAsset } from '../asset/assetSelectors';
import { selectEditableEquipmentFromCalculator } from '../asset/equipmentSelectors';
import { selectFrame } from '../asset/frameSelectors';

import { requestMappers } from './caseAssetMapper';
import { selectCase } from './caseDetailSelectors';

const selectCaseAssetState = (state) => state.caseAsset;

export const selectIsUpdatingCaseAsset = () =>
  createSelector(selectCaseAssetState, (state) => state.get('updatingAsset'));

export const selectIsCaseFormEditedAfterSave = () =>
  createSelector(selectCaseAssetState, (state) => state.get('isFormEditedAfterSave'));

export const selectCalculationRequestPRHP = (formValues) =>
  createSelector(
    selectCase(),
    selectAsset(),
    selectFrame(),
    selectEditableEquipmentFromCalculator(),
    (currentCase, asset, frame, equipment) =>
      requestMappers.mapCalculationsPRHP({
        currentCase,
        asset,
        frame,
        equipment,
        formValues,
      }),
  );

export const selectCalculationRequestCMFL = ({ formValues, useDownPaymentPercentage }) =>
  createSelector(
    selectCase(),
    selectAsset(),
    selectFrame(),
    selectEditableEquipmentFromCalculator(),
    (currentCase, asset, frame, equipment) =>
      requestMappers.mapCalculationsCMFL({
        currentCase,
        asset,
        frame,
        equipment,
        formValues,
        useDownPaymentPercentage,
      }),
  );

export const selectSavedVendorTermsId = () =>
  createSelector(selectCaseAssetState, (state) => state.getIn(['asset', 'vendorTermsId']));
