import { useState, FC } from 'react';
import { Dialog, DialogContent, Box } from '@mui/material';

import { useScreenSize } from '../../utils';
import MAFTypography from '../MAFTypography/MAFTypography';
import MAFDanskeBankLogo from '../MAFDanskeBankLogo/MAFDanskeBankLogo';
import MAFButton from '../MAFButton/MAFButton';
import ArrowNext from '../Icons/ArrowNext';

import ExpandedContent from './mafCookiesConsent/ExpandedContent/ExpandedContent';
import ExpandedButtons from './mafCookiesConsent/ExpandedButtons/ExpandedButtons';
import { setConsentLevel, getConsentLevel } from './MAFCookiesConsent.util';
import {
  IMAFCookiesConsentConsentLevels,
  IMAFCookiesConsentButtonText,
  IMAFCookiesConsentAcceptAllButtonProps,
  IMAFCookiesConsentGeneralInfo,
  TMAFCookiesConsentHandleAcceptParams,
} from './MAFCookiesConsent.types';
import { styles } from './MAFCookiesConsent.styles';
import { propTypes } from './MAFCookiesConsent.propTypes';

export interface IMAFCookiesConsentProps {
  isOpen?: boolean;
  isStrict?: boolean;
  consentLevels: IMAFCookiesConsentConsentLevels[];
  highestLevel: number;
  highestMandatoryLevel: number;
  buttonText: IMAFCookiesConsentButtonText;
  generalInfo: IMAFCookiesConsentGeneralInfo;
  logConsent: (id: string, data: string) => void;
  acceptAllButtonProps?: IMAFCookiesConsentAcceptAllButtonProps;
}

const MAFCookiesConsent: FC<IMAFCookiesConsentProps> = ({
  isOpen,
  isStrict,
  consentLevels,
  highestLevel,
  highestMandatoryLevel,
  buttonText,
  generalInfo,
  logConsent,
  acceptAllButtonProps,
}) => {
  const { isMobile } = useScreenSize();

  const currentLevel = getConsentLevel();
  const firstLevel = currentLevel ?? consentLevels[0].level;

  const [isExpanded, setIsExpanded] = useState(isStrict);
  const [level, setLevel] = useState(isStrict ? firstLevel : undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(currentLevel === null);

  const handleAccept = ({ shouldAcceptAll }: TMAFCookiesConsentHandleAcceptParams) => {
    if (shouldAcceptAll) setLevel(highestLevel);
    setConsentLevel({ level: shouldAcceptAll ? highestLevel : level, logConsent });
    setIsDialogOpen(false);
  };

  const handleExpand = () => {
    setIsExpanded(true);
    setLevel(firstLevel);
  };

  return (
    <Dialog open={isDialogOpen || Boolean(isOpen)} sx={styles.paper}>
      <DialogContent sx={styles.contentRoot}>
        <Box sx={styles.content}>
          {isMobile && <MAFDanskeBankLogo sx={styles.logo} />}
          <MAFTypography
            variant="sub1-semi-bold"
            fontSize="medium"
            sx={styles.title}
            isWithContainer
          >
            {generalInfo.title}
          </MAFTypography>
          <MAFTypography variant={isMobile ? 'body2' : 'body1'}>{generalInfo.text}</MAFTypography>
          {isExpanded && (
            <ExpandedContent consentLevels={consentLevels} level={level} setLevel={setLevel} />
          )}
        </Box>
        <Box sx={styles.buttons}>
          {isExpanded ? (
            <ExpandedButtons
              isStrict={isStrict}
              level={level}
              highestLevel={highestLevel}
              highestMandatoryLevel={highestMandatoryLevel}
              buttonText={buttonText}
              handleAccept={handleAccept}
              buttonClass={styles.button}
            />
          ) : (
            <>
              <MAFButton
                color="primaryPR"
                sx={styles.button}
                onClick={() => handleAccept({ shouldAcceptAll: true })}
                {...acceptAllButtonProps}
              >
                {buttonText.agreeAll}
              </MAFButton>
              <MAFButton
                color="blank"
                isLink
                sx={styles.buttonLink}
                endIcon={<ArrowNext />}
                onClick={handleExpand}
              >
                {buttonText.moreInfo}
              </MAFButton>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

MAFCookiesConsent.propTypes = propTypes;

export default MAFCookiesConsent;
