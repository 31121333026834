import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  smilesWrapper: {
    display: 'flex',
    marginTop: 1,
  },
  dialogSmilesWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 4,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  iconButton: {
    marginRight: 1,
    cursor: 'pointer',
  },
  verySad: ({ colors }) => ({
    fill: colors.solid.cadedBlue,
    '&:hover': {
      fill: colors.solid.red,
    },
  }),
  verySadFilled: ({ colors }) => ({
    fill: colors.solid.red,
  }),
  sad: ({ colors }) => ({
    fill: colors.solid.cadedBlue,
    '&:hover': {
      fill: colors.danske.attention100,
    },
  }),
  sadFilled: ({ colors }) => ({
    fill: colors.danske.attention100,
  }),
  neutral: ({ colors }) => ({
    fill: colors.solid.cadedBlue,
    '&:hover': {
      fill: colors.danske.caution100,
    },
  }),
  neutralFilled: ({ colors }) => ({
    fill: colors.danske.caution100,
  }),
  happy: ({ colors }) => ({
    fill: colors.solid.cadedBlue,
    '&:hover': {
      fill: colors.danske.success40,
    },
  }),
  happyFilled: ({ colors }) => ({
    fill: colors.danske.success40,
  }),
  veryHappy: ({ colors }) => ({
    fill: colors.solid.cadedBlue,
    '&:hover': {
      fill: colors.danske.success100,
    },
  }),
  veryHappyFilled: ({ colors }) => ({
    fill: colors.danske.success100,
  }),
  textField: ({ breakpoints }) => ({
    width: 400,
    [breakpoints.only('xs')]: {
      width: 272,
    },
    display: 'flex',
    alignItems: 'flex-start',
    '& > textarea': {
      minHeight: 100,
    },
  }),
  textAreaWrapper: {
    marginBottom: 4,
  },
  thankYouLogoWrapper: {
    marginBottom: 4,
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};
