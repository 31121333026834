import { Theme } from '@mui/material/styles';

import { ISxStyles } from '../../../theming/theme.type';

interface IProps {
  isError?: boolean;
}

const getIconBase = ({ typography: { baseFont }, spacing, fontSizes }: Theme) => ({
  ...baseFont,
  padding: '0 5px',
  fontSize: fontSizes.small,
  width: '100%',
  minHeight: spacing(6),
  height: `calc(${spacing(6)} + 1px)`, // This is an IE bugfix (even pixel is skipped by some old bug)
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid',
});

export const useStyles = ({ isError }: IProps): ISxStyles => ({
  icon: (theme) => ({
    ...getIconBase(theme),
    ...theme.colors.custom.radio.button,
    boxSizing: 'border-box',
  }),
  iconChecked: (theme) => ({
    ...getIconBase(theme),
    borderColor: isError ? theme.colors.solid.red : theme.colors.solid.blue,
    color: isError ? theme.colors.solid.red : theme.colors.solid.blue,
    boxSizing: 'border-box',
    zIndex: 1,
  }),
  disabled: ({ colors }) => ({
    ...colors.custom.radio.disabled,
  }),
});
