"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  container: {
    marginLeft: '10px',
    textAlign: 'left'
  },
  username: _ref => {
    let {
      fontSizes: {
        extraSmall
      }
    } = _ref;
    return {
      fontSize: extraSmall,
      width: '100%'
    };
  },
  role: _ref2 => {
    let {
      fontSizes: {
        tiny
      }
    } = _ref2;
    return {
      width: '100%',
      fontSize: tiny,
      fontWeight: 100,
      lineHeight: 1
    };
  },
  subtype: {
    marginLeft: '7px'
  },
  gutters: _ref3 => {
    let {
      colors
    } = _ref3;
    return {
      '&.MuiMenuItem-gutters': {
        padding: '15px',
        borderBottom: "1px solid ".concat(colors.alpha(colors.solid.black, 0.1))
      }
    };
  },
  avatar: _ref4 => {
    let {
      typography: {
        main
      },
      colors
    } = _ref4;
    return _objectSpread(_objectSpread({}, colors.custom.primaryPR.highlight), {}, {
      fontFamily: main,
      fontSize: 24
    });
  },
  vendor: _ref5 => {
    let {
      fontSizes: {
        tiny
      },
      colors
    } = _ref5;
    return {
      fontSize: tiny,
      height: '20px',
      color: colors.custom.fontPrimary,
      textTransform: 'uppercase'
    };
  },
  btn: _ref6 => {
    let {
      colors
    } = _ref6;
    return {
      margin: '15px',
      background: 'gray',
      color: colors.custom.fontPrimary,
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'center',
      '&:hover': {
        color: colors.custom.fontPrimary
      }
    };
  }
};