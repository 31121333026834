import { alpha } from '@mui/material/styles';
import { Property } from 'csstype';

import { THEME_NORTHSTAR } from './theming/constants';
import { fieldSizes, mainColors } from './theming/common';
import {
  baseThemeColors as baseThemeColorsDB,
  PUBLIC_DEFAULT_IMAGE_PATH as PUBLIC_DEFAULT_IMAGE_PATH_DB,
  CUSTOM_LOGO as CUSTOM_LOGO_DB,
} from './theming/DanskeBank';
import {
  baseThemeColors as baseThemeColorsNS,
  PUBLIC_DEFAULT_IMAGE_PATH as PUBLIC_DEFAULT_IMAGE_PATH_NS,
} from './theming/Northstar';

export enum MAFTypographyVariants {
  H1_ITALIC = 'h1-italic',
  H1_REGULAR = 'h1-regular',
  H1_SEMI_BOLD = 'h1-semi-bold',
  H2_ITALIC = 'h2-italic',
  H2_REGULAR = 'h2-regular',
  H2_SEMI_BOLD = 'h2-semi-bold',
  SUB1_ITALIC = 'sub1-italic',
  SUB1_REGULAR = 'sub1-regular',
  SUB1_SEMI_BOLD = 'sub1-semi-bold',
  SUB2_ITALIC = 'sub2-italic',
  SUB2_REGULAR = 'sub2-regular',
  SUB2_SEMI_BOLD = 'sub2-semi-bold',
  LABEL1 = 'label1',
  LABEL2 = 'label2',
  LABEL3 = 'label3',
  BODY1 = 'body1',
  BODY2 = 'body2',
  BODY3 = 'body3',
  HELP1 = 'help1',
  HELP2 = 'help2',
}

const constructTheme = ({
  backgrounds = {},
  customLogo = null,
  customColors = {},
  baseTheme = '',
} = {}) => {
  const isNorthstarTheme = !baseTheme || baseTheme === THEME_NORTHSTAR;

  const baseThemeColors = isNorthstarTheme ? baseThemeColorsNS : baseThemeColorsDB;
  const allCustomColors = { ...baseThemeColors, ...customColors };

  const allBackgrounds = {
    path: isNorthstarTheme ? PUBLIC_DEFAULT_IMAGE_PATH_NS : PUBLIC_DEFAULT_IMAGE_PATH_DB,
    innerBackgroundSize: 200,
    ...backgrounds,
  };

  const adjustedCustomlogo = !customLogo && !isNorthstarTheme ? CUSTOM_LOGO_DB : customLogo;

  const fontSizes = {
    tiny: '12px',
    extraSmall: '14px',
    small: '16px',
    medium: '20px',
    extraMedium: '26px',
    large: '30px',
    extraLarge: '36px',
    huge: '46px',
    giant: '72px',
  };

  const colors = {
    ...mainColors,
    custom: allCustomColors,
  };

  const breakpoints = {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  };
  // if you add it into breakpoints object, it will override the default down function
  const mediaDown = (val: string | number) => `@media (max-width: ${val}px)`;

  /**
   * Typography guidelines: https://danskebankcvi.com/digital/guidelines/typography.php user: dbcvi / pass: design135
   * Note, it is only recommendations. Since MUI follows 8px margins we have adjusted our Typography to MUI.
   */
  const danskeHuman = ['Danske Human', 'Georgia', 'sans-serif'].join(',');
  const danskeText = ['Danske Text v2', 'Verdana', 'sans-serif'].join(',');

  // Font used on entire project as base.
  const baseFont = {
    fontFamily: danskeText,
    fontSize: fontSizes.extraSmall,
    fontWeight: 'normal',
    lineHeight: '19px',
    textAlign: 'center' as Property.TextAlign,
  };

  const typography = {
    baseFont,
    main: danskeText,
    title: danskeHuman,
    button: baseFont,
    fontFamily: danskeText,
    'h1-italic': {
      lineHeight: '48px',
      fontWeight: 'normal',
      fontFamily: danskeHuman,
      fontSize: fontSizes.huge,
      fontStyle: 'italic',
      [mediaDown(breakpoints.values.sm)]: {
        lineHeight: '30px',
        fontSize: fontSizes.extraLarge,
      },
    },
    'h1-regular': {
      lineHeight: '48px',
      fontWeight: 'normal',
      fontFamily: danskeText,
      fontSize: fontSizes.huge,
      fontStyle: 'normal',
      [mediaDown(breakpoints.values.sm)]: {
        lineHeight: '30px',
        fontSize: fontSizes.extraLarge,
      },
    },
    'h1-semi-bold': {
      lineHeight: '48px',
      fontWeight: 'bold',
      fontFamily: danskeText,
      fontSize: fontSizes.huge,
      [mediaDown(breakpoints.values.sm)]: {
        lineHeight: '30px',
        fontSize: fontSizes.extraLarge,
      },
    },
    'h2-italic': {
      lineHeight: '44px',
      fontWeight: 'normal',
      fontFamily: danskeHuman,
      fontSize: fontSizes.extraLarge,
      fontStyle: 'italic',
      [mediaDown(breakpoints.values.sm)]: {
        lineHeight: '28px',
        fontSize: fontSizes.medium,
      },
    },
    'h2-regular': {
      lineHeight: '44px',
      fontWeight: 'normal',
      fontFamily: danskeText,
      fontSize: fontSizes.extraLarge,
      fontStyle: 'normal',
      [mediaDown(breakpoints.values.sm)]: {
        lineHeight: '28px',
        fontSize: fontSizes.medium,
      },
    },
    'h2-semi-bold': {
      lineHeight: '44px',
      fontWeight: 'bold',
      fontFamily: danskeText,
      fontSize: fontSizes.extraLarge,
      fontStyle: 'bold',
      [mediaDown(breakpoints.values.sm)]: {
        lineHeight: '28px',
        fontSize: fontSizes.medium,
      },
    },
    'sub1-italic': {
      lineHeight: '30px',
      fontWeight: 'normal',
      fontFamily: danskeText,
      fontSize: fontSizes.extraMedium,
      fontStyle: 'italic',
      [mediaDown(breakpoints.values.sm)]: {
        fontSize: fontSizes.medium,
      },
    },
    'sub1-regular': {
      lineHeight: '30px',
      fontWeight: 'normal',
      fontFamily: danskeText,
      fontSize: fontSizes.extraMedium,
      fontStyle: 'normal',
      [mediaDown(breakpoints.values.sm)]: {
        fontSize: fontSizes.medium,
      },
    },
    'sub1-semi-bold': {
      lineHeight: '30px',
      fontWeight: 'bold',
      fontFamily: danskeText,
      fontSize: fontSizes.extraMedium,
      fontStyle: 'bold',
      [mediaDown(breakpoints.values.sm)]: {
        fontSize: fontSizes.medium,
      },
    },
    'sub2-semi-bold': {
      lineHeight: '28px',
      fontWeight: 'bold',
      fontFamily: danskeText,
      fontSize: fontSizes.medium,
      fontStyle: 'bold',
      [mediaDown(breakpoints.values.sm)]: {
        fontSize: fontSizes.small,
      },
    },
    'sub2-regular': {
      lineHeight: '28px',
      fontWeight: 'normal',
      fontFamily: danskeText,
      fontSize: fontSizes.medium,
      fontStyle: 'normal',
      [mediaDown(breakpoints.values.sm)]: {
        fontSize: fontSizes.small,
      },
    },
    'sub2-italic': {
      lineHeight: '28px',
      fontWeight: 'normal',
      fontFamily: danskeText,
      fontSize: fontSizes.medium,
      fontStyle: 'italic',
      [mediaDown(breakpoints.values.sm)]: {
        fontSize: fontSizes.small,
      },
    },
    label1: {
      lineHeight: '24px',
      fontWeight: 'bold',
      fontFamily: danskeText,
      fontSize: fontSizes.small,
      fontStyle: 'normal',
      [mediaDown(breakpoints.values.sm)]: {
        fontSize: fontSizes.extraSmall,
      },
    },
    label2: {
      lineHeight: '24px',
      fontWeight: 'bold',
      fontFamily: danskeText,
      fontSize: fontSizes.extraSmall,
      fontStyle: 'normal',
    },
    label3: {
      lineHeight: '24px',
      fontWeight: 'bold',
      fontFamily: danskeText,
      fontSize: fontSizes.tiny,
      fontStyle: 'normal',
    },
    body1: {
      lineHeight: '24px',
      fontWeight: 'normal',
      fontFamily: danskeText,
      fontSize: fontSizes.small,
      fontStyle: 'normal',
      [mediaDown(breakpoints.values.sm)]: {
        fontSize: fontSizes.extraSmall,
      },
    },
    body2: {
      lineHeight: '22px',
      fontWeight: 'normal',
      fontFamily: danskeText,
      fontSize: fontSizes.extraSmall,
      fontStyle: 'normal',
    },
    body3: {
      lineHeight: '18px',
      fontWeight: 'normal',
      fontFamily: danskeText,
      fontSize: fontSizes.tiny,
      fontStyle: 'normal',
    },
    help1: {
      lineHeight: '18px',
      fontWeight: 'normal',
      fontFamily: danskeText,
      fontSize: fontSizes.extraSmall,
      fontStyle: 'normal',
    },
    help2: {
      lineHeight: '16px',
      fontWeight: 'normal',
      fontFamily: danskeText,
      fontSize: fontSizes.tiny,
      fontStyle: 'normal',
    },
    // remove default MUI typography variants
    caption: undefined,
    h1: undefined,
    h2: undefined,
    h3: undefined,
    h4: undefined,
    h5: undefined,
    h6: undefined,
    inherit: undefined,
    overline: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
  };

  const components = {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: allCustomColors.contentSecondary.color,
          fontFamily: typography.main,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: allCustomColors.contentSecondary.background,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: allCustomColors.fontPrimary,
          fontFamily: typography.main,
          minHeight: 33,
          [mediaDown(breakpoints.values.sm)]: {
            fontSize: 'inherit',
          },
        },
        underline: {
          '&:before': {
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: allCustomColors.fontPrimary,
          },
          '&:hover:not($.Mui-disabled):not($.Mui-focused):not($.Mui-error):before': {
            borderBottomWidth: '2px',
            borderBottomStyle: 'solid',
            borderBottomColor: allCustomColors.fontPrimary,
          },
          '&:after': {
            borderBottomWidth: '2px',
            borderBottomStyle: 'solid',
            borderBottomColor: allCustomColors.fontPrimary,
          },
          '&.Mui-disabled:before': {
            borderBottomColor: alpha(mainColors.solid.black, 0.42),
          },
        },
        disabled: {},
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: allCustomColors.fontPrimary,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          lineHeight: 'inherit',
          fontFamily: typography.main,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.custom.fontPrimary,
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        },
        help1: {
          color: colors.danske.primary50,
        },
        help2: {
          color: colors.danske.primary50,
        },
      },
      defaultProps: {
        variantMapping: {
          [MAFTypographyVariants.H1_ITALIC]: 'h1',
          [MAFTypographyVariants.H1_REGULAR]: 'h1',
          [MAFTypographyVariants.H1_SEMI_BOLD]: 'h1',
          [MAFTypographyVariants.H2_ITALIC]: 'h2',
          [MAFTypographyVariants.H2_REGULAR]: 'h2',
          [MAFTypographyVariants.H2_SEMI_BOLD]: 'h2',
          [MAFTypographyVariants.BODY1]: 'p',
          [MAFTypographyVariants.BODY2]: 'p',
          [MAFTypographyVariants.BODY3]: 'p',
          [MAFTypographyVariants.HELP1]: 'p',
          [MAFTypographyVariants.HELP2]: 'p',
          [MAFTypographyVariants.LABEL1]: 'p',
          [MAFTypographyVariants.LABEL2]: 'p',
          [MAFTypographyVariants.LABEL3]: 'p',
          [MAFTypographyVariants.SUB1_ITALIC]: 'h6',
          [MAFTypographyVariants.SUB1_REGULAR]: 'h6',
          [MAFTypographyVariants.SUB1_SEMI_BOLD]: 'h6',
          [MAFTypographyVariants.SUB2_ITALIC]: 'h6',
          [MAFTypographyVariants.SUB2_REGULAR]: 'h6',
          [MAFTypographyVariants.SUB2_SEMI_BOLD]: 'h6',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...allCustomColors.inputFull.label,
          fontFamily: typography.main,
          '&.Mui-focused': {
            ...allCustomColors.inputFull.label,
          },
          '&.Mui-disabled': {
            ...allCustomColors.inputFull.label,
          },
          '&.Mui-error': {
            ...allCustomColors.inputFull.label,
          },
        },
        // it is needed for the rules $focused & $disabled
        error: {},
        focused: {},
        disabled: {},
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: allCustomColors.fontPrimary,
          fontFamily: typography.main,
        },
        label: {
          color: allCustomColors.fontPrimary,
          fontFamily: typography.main,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: mainColors.solid.white,
        },
        indeterminate: {
          animation: 'none',
        },
        svg: {
          animation: 'MuiCircularProgress-keyframes-circular-rotate 1.4s linear infinite',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorAction: {
          color: allCustomColors.fontPrimary,
        },
        colorPrimary: {
          color: allCustomColors.fontPrimary,
        },
        colorSecondary: {
          color: mainColors.solid.lightRed,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: typography.main,
          fontSize: fontSizes.tiny,
        },
      },
      PrivateTabIndicator: {
        colorPrimary: {
          backgroundColor: colors.custom.contentAlternative.main.color,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: typography.main,
          fontSize: fontSizes.tiny,
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          ...allCustomColors.calendar.background,
          color: allCustomColors.fontPrimary,
          fontFamily: typography.main,
          '& > div > div > div > span': {
            color: allCustomColors.fontPrimary,
          },
          '& > div > div > div > div': {
            color: allCustomColors.fontPrimary,
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          ...baseFont,
          color: allCustomColors.fontPrimary,
          backgroundColor: 'inherit',
          '&.Mui-selected': {
            ...allCustomColors.calendar.selected,
            '&:hover': {
              ...allCustomColors.calendar.selected,
            },
          },
          '&.Mui-disabled': {
            color: allCustomColors.fontPrimary,
            opacity: 0.3,
          },
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {
          '& > div > button': {
            ...baseFont,
            color: allCustomColors.fontPrimary,
            '&.Mui-selected': {
              ...allCustomColors.calendar.selected,
              '&:hover': {
                ...allCustomColors.calendar.selected,
              },
            },
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  };

  return {
    components,
    typography,
    colors,
    fieldSizes,
    fontSizes,
    backgrounds: allBackgrounds,
    customLogo: adjustedCustomlogo,
    breakpoints,
  };
};

const { components, typography, colors, fontSizes, backgrounds, customLogo, breakpoints } =
  constructTheme();

export {
  components,
  typography,
  colors,
  fontSizes,
  backgrounds,
  customLogo,
  breakpoints,
  constructTheme,
};
