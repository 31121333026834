import { vendorConfig } from '@northstar/core-ui';
import { translationUtils } from '@northstar/core-ui/utils';
import { camelToSnake } from '@northstar/core/utils/StringUtils';

import { KYC_OTHER } from 'screens/kyc/kyc-constants';

export const transformUserSelections = (selections, locale = vendorConfig.DEFAULT_LOCALE) =>
  Object.keys(selections)
    .filter((key) => selections[key])
    .map((key) =>
      key === KYC_OTHER
        ? selections[key].value || selections[key]
        : translationUtils.getTranslationByKey({
            locale,
            key: `kyc.${camelToSnake(key)}`,
          }),
    );

export const mapCountries = (countries) => countries.map(({ key, value }) => key || value);

export const mapCountriesToString = (countries) => mapCountries(countries).join(', ');

export const mapPosition = (position) =>
  translationUtils.getTranslationByKey({
    locale: vendorConfig.DEFAULT_LOCALE,
    key: `kyc.dynamic.${position}`,
  });

export const mapLineOfIndustry = (industryCode, locale = vendorConfig.DEFAULT_LOCALE) =>
  translationUtils.getTranslationByKey({ locale, key: `kyc.dynamic.industry_${industryCode}` });

export const mapGeneralPersonInformation = ({ email, personalIdNumber, givenNames, surname }) => ({
  email,
  externalCustomerId: personalIdNumber,
  firstName: givenNames,
  lastName: surname,
});

export const mapCountryInfo = ({ id, citizenshipsValues }) => ({
  citizenships:
    citizenshipsValues.other && citizenshipsValues.other[id]
      ? mapCountries(citizenshipsValues.other[id].citizenship)
      : [vendorConfig.DEFAULT_COUNTRY],
  residence:
    citizenshipsValues.other && citizenshipsValues.other[id]
      ? citizenshipsValues.other[id].countryOfResidence.key
      : vendorConfig.DEFAULT_COUNTRY,
});
