import { keyframes } from '@mui/styled-engine';

import { ISxStyles } from '../../theming/theme.type';

const fadeIn = keyframes`
  0% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
  100% {
    max-height: 500px;
    padding: 16px;
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    padding: 16px;
    max-height: 500px;
  }
  100% {
    opacity: 0;
    padding: 0 16px;
    max-height: 0;
  }
`;

export const styles: ISxStyles = {
  container: ({ typography: { main } }) => ({
    fontFamily: main,
    display: 'flex',
    padding: 2,
    position: 'relative',
    boxShadow: 'rgba(102, 102, 102, 0.2) 0px 1px 2px 0px, rgba(102, 102, 102, 0.2) 0px 2px 4px 0px',
    transition: '1s all linear',
    opacity: 1,
    animation: `${fadeIn}`,
    animationDuration: '300ms',
    maxHeight: 500,
    zIndex: 1,
    '&:nth-child(1)': {
      marginTop: '54px',
    },
    '&:nth-last-child(1)': {
      marginBottom: '-54px',
    },
  }),
  hidden: {
    animation: `${fadeOut}`,
    animationDuration: '300ms',
    maxHeight: 0,
    padding: 0,
  },
  iconWrapper: ({ colors, breakpoints }) => ({
    marginTop: '2px',
    marginRight: 2,
    color: colors.solid.white,
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  }),
  content: ({ spacing, colors }) => ({
    boxSizing: 'border-box',
    marginRight: spacing(4),
    color: colors.solid.white,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  }),
  contentText: {
    maxWidth: 764,
  },
  heading: ({ spacing, fontSizes: { small } }) => ({
    fontSize: small,
    fontWeight: 600,
    marginBottom: spacing(1),
  }),
  message: ({ spacing, fontSizes: { extraSmall } }) => ({
    fontSize: extraSmall,
    marginRight: spacing(4),
    marginBottom: spacing(3),
  }),
  success: ({ colors }) => ({
    backgroundColor: colors.solid.green,
  }),
  info: ({ colors }) => ({
    backgroundColor: colors.solid.lightBlue,
  }),
  warning: ({ colors }) => ({
    backgroundColor: colors.custom.warning,
  }),
  error: ({ colors }) => ({
    backgroundColor: colors.solid.red,
  }),
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      height: '20px',
      width: '20px',
    },
  },
  svgIcon: ({ colors }) => ({
    height: '16px',
    width: '16px',
    fill: colors.solid.white,
    '& g': {
      fill: colors.solid.white,
    },
  }),
};
