"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectSelectedVehicleType = exports.selectSelectedProductType = exports.selectSelectedAssets = exports.selectAssets = exports.selectAllAssetsSelected = exports.isAssetsLoading = void 0;
var _reselect = require("reselect");
var _core = require("@northstar/core");
const selectAssetsState = state => state.vendorDashboard.assetList;
const selectAssets = () => (0, _reselect.createSelector)(selectAssetsState, state => state.get('assets').toJS().filter(_ref => {
  let {
    status
  } = _ref;
  return status !== _core.ASSET_STATUS_DEPRECATED;
}));
exports.selectAssets = selectAssets;
const isAssetsLoading = () => (0, _reselect.createSelector)(selectAssetsState, state => state.get('gettingAssets') || state.get('restoringAssets') || state.get('deletingAssets'));
exports.isAssetsLoading = isAssetsLoading;
const selectSelectedAssets = () => (0, _reselect.createSelector)(selectAssetsState, state => state.get('selectedAssets').toJS());
exports.selectSelectedAssets = selectSelectedAssets;
const selectAllAssetsSelected = () => (0, _reselect.createSelector)(selectAssetsState, state => state.get('allAssetsSelected'));
exports.selectAllAssetsSelected = selectAllAssetsSelected;
const selectSelectedProductType = () => (0, _reselect.createSelector)(selectAssetsState, state => state.get('selectedProductType'));
exports.selectSelectedProductType = selectSelectedProductType;
const selectSelectedVehicleType = () => (0, _reselect.createSelector)(selectAssetsState, state => state.get('selectedVehicleType'));
exports.selectSelectedVehicleType = selectSelectedVehicleType;