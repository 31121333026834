const Chat: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    fill="white"
    {...props}
  >
    <path d="M21.202 3c.436 0 .798.36.798.802l-.001 7.199H22V15.8c0 .435-.274.571-.612.286L17.265 12.6h-1.183v4.042a.803.803 0 01-.797.802l-8.551-.001-4.122 3.485c-.338.285-.612.15-.612-.286V8.645c0-.443.362-.802.798-.802h5.119v-4.04c0-.405.304-.746.698-.797l.1-.006zM2.986 19.42l3.235-2.86h8.994V8.812H2.986V19.42z" />
  </svg>
);

export default Chat;
