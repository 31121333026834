"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.submitFrameResponse = exports.submitFrameRequest = exports.openForm = exports.initialFrameState = exports.default = exports.closeForm = exports.changeFormStep = exports.backToFrameCreation = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
const submitFrameRequest = exports.submitFrameRequest = (0, _reduxActions.createAction)('DASHBOARD_SUBMIT_FRAME_REQUEST');
const submitFrameResponse = exports.submitFrameResponse = (0, _reduxActions.createAction)('DASHBOARD_SUBMIT_FRAME_RESPONSE');
const changeFormStep = exports.changeFormStep = (0, _reduxActions.createAction)('DASHBOARD_CHANGE_FORM_STEP');
const openForm = exports.openForm = (0, _reduxActions.createAction)('DASHBOARD_OPEN_FORM');
const closeForm = exports.closeForm = (0, _reduxActions.createAction)('DASHBOARD_CLOSE_FORM');
const backToFrameCreation = exports.backToFrameCreation = (0, _reduxActions.createAction)('DASHBOARD_BACK_TO_FRAME_CREATION');
const initialFrameState = exports.initialFrameState = (0, _immutable.fromJS)({
  gettingFrameEquipment: false,
  formOpened: false,
  submitingFrame: false,
  frameFormStep: 1,
  frame: {
    id: 0,
    popName: '',
    vehicleType: '',
    modelYear: '',
    bodyType: '',
    doors: 0,
    seating: 0,
    fuelType: '',
    engine: 0,
    enginePowerKw: 0,
    enginePowerHp: 0,
    drivingWheel: 0,
    powertrain: '',
    transmissionType: 0,
    trimLevel: 0,
    framePic: '',
    framePicFile: '',
    status: 0,
    brand: {
      id: 0,
      name: '',
      status: ''
    },
    model: {
      id: 0,
      name: '',
      status: ''
    }
  }
});
var _default = exports.default = (0, _reduxActions.handleActions)({
  [(0, _reduxActions.combineActions)(submitFrameRequest)](state) {
    return state.set('submitingFrame', true);
  },
  [(0, _reduxActions.combineActions)(submitFrameResponse)](state, _ref) {
    let {
      payload,
      error
    } = _ref;
    return !error ? state.set('frame', (0, _immutable.fromJS)(payload)).set('submitingFrame', false) : state.set('submitingFrame', false);
  },
  [changeFormStep](state, _ref2) {
    let {
      payload
    } = _ref2;
    return state.set('frameFormStep', payload.step);
  },
  [openForm](state) {
    return state.set('formOpened', true);
  },
  [closeForm](state) {
    return state.set('frameFormStep', 1).set('formOpened', false);
  }
}, initialFrameState);