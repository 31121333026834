import { ISxStyles } from '../../theming/theme.type';

const actionRoot = {
  marginTop: 4,
  display: 'flex',
  justifyContent: 'center',
};

export const styles: ISxStyles = {
  root: ({ colors, breakpoints }) => ({
    '& .MuiDialog-paper': {
      display: 'block',
      maxHeight: 'calc(100vh - 20px)',
      maxWidth: 928,
      width: 928,
      [breakpoints.only('xs')]: {
        margin: '15px',
      },
      ...colors.custom.dialog,
      boxShadow: `0 2px 2px 0 ${colors.alpha(colors.solid.black, 0.15)}`,
      color: colors.custom.fontPrimary,
      alignItems: 'center',
    },
  }),
  contentRoot: ({ breakpoints }) => ({
    '&.MuiDialogContent-root': {
      padding: '64px 128px',
      [breakpoints.only('sm')]: {
        padding: 8,
      },
      [breakpoints.only('xs')]: {
        padding: '64px 32px',
      },
      textAlign: 'center',
      '&:first-child': {
        paddingTop: 8,
      },
    },
  }),
  title: {
    textAlign: 'center',
    marginBottom: 2,
  },
  loading: ({ colors }) => ({
    '&.MuiBackdrop-root': {
      zIndex: 1,
      background: colors.alpha(colors.solid.black, 0.4),
    },
  }),
  actionRoot: {
    ...actionRoot,
    '& > :not(:last-child)': {
      marginRight: '5px',
    },
  },
  confirmDialogActionRoot: {
    ...actionRoot,
  },
  closeBtn: {
    padding: '5px',
    lineHeight: 0,
    outline: 0,
    top: '20px',
    position: 'absolute',
    right: '20px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
};
