"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  container: _ref => {
    let {
      typography: {
        baseFont
      },
      fieldSizes
    } = _ref;
    return _objectSpread(_objectSpread({
      display: 'flex',
      alignItems: 'center',
      maxWidth: fieldSizes.maxWidth,
      margin: '15px 0px'
    }, baseFont), {}, {
      textAlign: 'left',
      width: '100%',
      justifyContent: 'space-between'
    });
  },
  disabledContainer: {},
  switch: _ref2 => {
    let {
      breakpoints
    } = _ref2;
    return {
      maxWidth: 60,
      paddingRight: '12px',
      '& > span': {
        [breakpoints.only('xs')]: {
          margin: '0'
        }
      }
    };
  },
  title: _ref3 => {
    let {
      breakpoints
    } = _ref3;
    return {
      maxWidth: 200,
      minWidth: 160,
      [breakpoints.only('xs')]: {
        minWidth: 102,
        wordBreak: 'break-all'
      },
      width: '100%',
      marginRight: '6px'
    };
  },
  disabledText: _ref4 => {
    let {
      colors
    } = _ref4;
    return {
      color: colors.solid.lightRedGray
    };
  },
  field: _ref5 => {
    let {
      breakpoints
    } = _ref5;
    return {
      width: '100%',
      margin: '12px 0 0 5px',
      minHeight: 52,
      [breakpoints.only('xs')]: {
        minWidth: 115
      }
    };
  }
};