import { handleCustomSx } from 'src/utils';

import { IMAFLinkProps, MAFLink } from '../MAFLink';
import { IMAFSelectProps, MAFSelect } from '../MAFSelect';

import { styles } from './MAFAppBar.styles';
import { MAFAppBarTopLinkComponents } from './MAFAppBar.types';

export const TopLinkComponents = {
  [MAFAppBarTopLinkComponents.SELECT]: (props: IMAFSelectProps) => (
    <MAFSelect
      {...props}
      sx={[styles.select, ...handleCustomSx(props?.sx)]}
      wrapperProps={{
        formControlProps: {
          sx: [
            styles.selectFormControlWrapper,
            ...handleCustomSx(props.wrapperProps?.formControlProps?.sx),
          ],
        },
        ...props.wrapperProps,
      }}
    />
  ),
  [MAFAppBarTopLinkComponents.LINK]: (props: Omit<IMAFLinkProps, 'isLinkActive'>) => (
    <MAFLink
      {...props}
      typographyProps={{
        fontSize: 'tiny',
        ...(props.typographyProps ? props.typographyProps : {}),
      }}
      isBold
    />
  ),
};
