const AgreementSigned: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="38px"
    height="26px"
    viewBox="0 0 38 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="07-Case-detail-all-statuses-assets"
        transform="translate(-124.000000, -294.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="agreement_signed" transform="translate(126.000000, 296.000000)">
          <path
            d="M30.1324567,16.2323228 C30.659969,16.5606058 30.8214762,17.254365 30.4931932,17.7818773 C30.1649102,18.3093896 29.471151,18.4708969 28.9436387,18.1426139 C28.1290343,17.6356669 27.4185449,17.101846 26.8143867,16.5521013 C26.3623524,16.9003907 25.8873966,17.1834698 25.4193901,17.3784185 C23.5964573,18.1377637 22.2321451,18.1377637 19.9635964,16.9045305 C18.9895374,16.3750104 18.5324763,16.5409889 18.023171,17.6551713 C17.7648658,18.2202531 17.0973788,18.4689446 16.532297,18.2106394 C15.9672153,17.9523342 15.7185238,17.2848472 15.976829,16.7197654 C17.0172293,14.4437323 18.8941074,13.762157 21.038221,14.9277447 C22.6673882,15.8133961 23.3251041,15.8133961 24.5542095,15.3014105 C24.7270092,15.2294305 24.9915907,15.0510267 25.285416,14.8102489 C24.4932546,13.6268763 24.1676982,12.4236703 24.3284806,11.2981247 C24.663076,8.95581313 27.1448618,7.9696021 28.7128214,9.86456937 C29.9814228,11.3977454 29.512063,13.3636214 28.376739,14.9396504 C28.8604124,15.3693991 29.4439281,15.8038357 30.1324567,16.2323228 Z M26.9793043,11.2989397 C26.7550353,11.0278981 26.6329948,11.0763945 26.5558697,11.6163036 C26.4947282,12.0443204 26.5897052,12.5367843 26.8536589,13.0672422 C27.3065253,12.3165292 27.3402498,11.7351627 26.9793043,11.2989397 Z"
            id="Path-10-Copy"
          ></path>
          <g id="Group-31">
            <path
              d="M35.27575,6.41666667 L35.27575,19.25 C35.27575,21.4757409 33.4211951,23.27575 31.1666667,23.27575 L6.61111111,23.27575 C4.47507744,23.27575 2.69807816,21.6599803 2.51718567,19.5972176 C0.408008848,19.4383606 -1.27575,17.7041088 -1.27575,15.5833333 L-1.27575,2.75 C-1.27575,0.524259145 0.578804877,-1.27575 2.83333333,-1.27575 L27.3888889,-1.27575 C29.5249226,-1.27575 31.3019218,0.340019693 31.4828143,2.40278241 C33.5919912,2.56163942 35.27575,4.29589116 35.27575,6.41666667 Z M31.1666667,4.94241667 L28.9464722,4.94241667 L28.9464722,2.75 C28.9464722,1.95074085 28.2510271,1.27575 27.3888889,1.27575 L2.83333333,1.27575 C1.97119512,1.27575 1.27575,1.95074085 1.27575,2.75 L1.27575,15.5833333 C1.27575,16.3825925 1.97119512,17.0575833 2.83333333,17.0575833 L5.05352778,17.0575833 L5.05352778,19.25 C5.05352778,20.0492591 5.7489729,20.72425 6.61111111,20.72425 L31.1666667,20.72425 C32.0288049,20.72425 32.72425,20.0492591 32.72425,19.25 L32.72425,6.41666667 C32.72425,5.61740752 32.0288049,4.94241667 31.1666667,4.94241667 Z"
              id="Stroke-1"
            ></path>
            <path
              d="M5.27575,6.7 L5.27575,19.3 C5.27575,20.0685084 5.95881417,20.72425 6.8125,20.72425 L31.1875,20.72425 C32.0411858,20.72425 32.72425,20.0685084 32.72425,19.3 L32.72425,6.7 C32.72425,5.93149161 32.0411858,5.27575 31.1875,5.27575 L6.8125,5.27575 C5.95881417,5.27575 5.27575,5.93149161 5.27575,6.7 Z M2.72425,6.7 C2.72425,4.49850839 4.57243583,2.72425 6.8125,2.72425 L31.1875,2.72425 C33.4275642,2.72425 35.27575,4.49850839 35.27575,6.7 L35.27575,19.3 C35.27575,21.5014916 33.4275642,23.27575 31.1875,23.27575 L6.8125,23.27575 C4.57243583,23.27575 2.72425,21.5014916 2.72425,19.3 L2.72425,6.7 Z"
              id="Stroke-3"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AgreementSigned;
