import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { useOauth2PopupContext } from './Oauth2PopupProvider/Oauth2PopupProviderContext';
import { propTypes } from './MAFOAuth2Popup.propTypes';

interface IWindowProps {
  url: string;
  title?: string;
  width?: number;
  height?: number;
}

interface IPopupProps extends IWindowProps {
  onClose?: () => void;
  onRedirect: (redirectUri: string) => void;
  onClick?: () => void;
}

const MAFOauth2Popup: React.FC<IPopupProps> = ({
  title = 'oauth2_window',
  width = 800,
  height = 800,
  url,
  onRedirect,
  onClose,
  onClick,
  children,
}) => {
  const [popupWindow, setPopupWindow] = useState<Nullable<Window>>(null);
  const { isPopupOpen, setIsPopupOpen } = useOauth2PopupContext();

  const createPopup = () => {
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const externalPopup = window.open(
      url,
      title,
      `width=${width},height=${height},left=${left},top=${top}`,
    );

    setIsPopupOpen(true);
    return externalPopup;
  };

  const onClickContainer = () => {
    if (popupWindow) popupWindow.focus();
    if (!isPopupOpen) {
      setPopupWindow(createPopup());
      if (typeof onClick === 'function') {
        onClick();
      }
    }
  };

  useEffect(() => {
    if (popupWindow) {
      const timer = window.setInterval(() => {
        try {
          /**
           * In here you execute your logic
           * when you get redirected back to your domain
           * after Oauth2 login
           */
          const { pathname, search } = popupWindow.location;
          if (!pathname || !search) return;
          const redirectUrl = `${pathname}${search}`;
          onRedirect(redirectUrl);
          window.clearInterval(timer);
          popupWindow.close();
        } catch (error) {
          /**
           * While you're in a different domain,
           * you will get a CORS error while
           * trying to interact with the browser
           */
        } finally {
          if (popupWindow.closed) {
            onClose && onClose();
            setIsPopupOpen(false);
            window.clearInterval(timer);
          }
        }
      }, 700);
    }
  }, [popupWindow]);

  return (
    <Box component="span" role="button" tabIndex={0} onClick={onClickContainer}>
      {children}
    </Box>
  );
};

MAFOauth2Popup.propTypes = propTypes;

export default MAFOauth2Popup;
