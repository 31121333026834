"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ButtonsSection", {
  enumerable: true,
  get: function () {
    return _ButtonsSection.default;
  }
});
Object.defineProperty(exports, "DialogForm", {
  enumerable: true,
  get: function () {
    return _DialogForm.default;
  }
});
Object.defineProperty(exports, "FrameContent", {
  enumerable: true,
  get: function () {
    return _FrameContent.default;
  }
});
Object.defineProperty(exports, "PlainForm", {
  enumerable: true,
  get: function () {
    return _PlainForm.default;
  }
});
var _FrameContent = _interopRequireDefault(require("./FrameContent/FrameContent"));
var _ButtonsSection = _interopRequireDefault(require("./ButtonsSection/ButtonsSection"));
var _DialogForm = _interopRequireDefault(require("./DialogForm/DialogForm"));
var _PlainForm = _interopRequireDefault(require("./PlainForm/PlainForm"));