import {
  FormControl,
  FormControlProps,
  InputLabel,
  InputLabelProps,
  FormHelperTextProps,
  SxProps,
  Theme,
} from '@mui/material';
import { FC, ReactChild, ReactNode } from 'react';

import MAFHelperText from '../../MAFHelperText/MAFHelperText';
import MAFTooltipLabel from '../../MAFTooltipLabel/MAFTooltipLabel';
import { handleCustomSx } from '../../../utils';

import { styles } from './MAFSelectFormControl.styles';
import { propTypes } from './MAFSelectFormControl.propTypes';

interface IFormControlProps extends Omit<FormControlProps, 'sx'> {
  sx?: SxProps<Theme>;
}

export interface IMAFSelectFormControlProps {
  label?: ReactChild;
  isLabelInside?: boolean;
  formControlProps?: IFormControlProps;
  labelProps?: InputLabelProps;
  helperText?: ReactChild;
  customLabelClass?: SxProps<Theme>;
  isError?: boolean;
  formHelperTextProps?: FormHelperTextProps;
  tooltipText?: ReactNode;
}

const MAFSelectFormControl: FC<IMAFSelectFormControlProps> = ({
  label,
  isLabelInside = false,
  formControlProps = {},
  labelProps = {},
  children,
  helperText,
  customLabelClass,
  isError,
  formHelperTextProps,
  tooltipText,
}) => {
  const rootClasses = [
    styles.formControlRoot,
    Boolean(helperText) && styles.wrapper,
    ...handleCustomSx(formControlProps.sx),
  ];

  return (
    <FormControl {...formControlProps} sx={rootClasses} error={isError}>
      {label ? (
        <MAFTooltipLabel
          tooltipText={!isLabelInside && tooltipText}
          label={
            <InputLabel
              {...labelProps}
              sx={[
                styles.label,
                isLabelInside && styles.isLabelInside,
                ...handleCustomSx(customLabelClass),
              ]}
            >
              {label}
            </InputLabel>
          }
          sx={styles.tooltip}
          additionalIconClass={styles.tooltipIcon}
        />
      ) : undefined}
      {children}
      {helperText && (
        <MAFHelperText formHelperTextProps={formHelperTextProps} isError={isError}>
          {helperText}
        </MAFHelperText>
      )}
    </FormControl>
  );
};

MAFSelectFormControl.propTypes = propTypes;

export default MAFSelectFormControl;
