"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  container: _ref => {
    let {
      typography: {
        baseFont
      }
    } = _ref;
    return _objectSpread(_objectSpread({}, baseFont), {}, {
      display: 'flex'
    });
  },
  image: {
    width: '25%',
    textAlign: 'left'
  },
  title: _ref2 => {
    let {
      typography: {
        main
      },
      colors
    } = _ref2;
    return {
      color: colors.solid.white,
      fontFamily: main,
      alignSelf: 'center',
      '& div': {
        margin: '20px 0'
      },
      '& div:first-child': {
        fontSize: '40px'
      }
    };
  },
  circle: _ref3 => {
    let {
      colors
    } = _ref3;
    return {
      background: colors.alpha(colors.solid.white, 0.8),
      borderRadius: '50%',
      padding: '7px',
      width: '160px',
      height: '160px',
      display: 'flex',
      alignItems: 'center'
    };
  }
};