import { ObjectUtils, PROCESS_VARIANCES } from '@northstar/core';
import { caseDetail, FILTER_VEHICLE_TYPES, assetList } from '@northstar/core-ui/modules';
import { stringUtils, reactUtils } from '@northstar/core-ui/utils';
import { callWithAttachers } from '@northstar/core-ui/utils/redux-saga-utils';
import { push } from 'connected-react-router';
import { all, call, put, select, takeLatest, take } from 'redux-saga/effects';

import { createAttacherForTermsToUse } from 'modules/vendor/vendorTermsAttachers';

import { selectIsCasePrivate, selectIsCaseWithoutType } from '../caseDetail/caseDetailSelectors';
import { updateSelectedLeasingTerm } from '../vendor/vendorTermsReducer';
import { getVendorTermsPROL, getVendorTermsCMOL } from '../vendor/vendorTermsSaga';
import { selectSelectedTerms } from '../vendor/vendorTermsSelectors';

import * as Api from './carListApi';
import { responseMappers, requestMappers } from './carListMapper';
import {
  fetchCarsResponse,
  fetchCarsRequest,
  applyFilters,
  updateLeaseTerms,
  applyQueryFiltersRequest,
  createQueryFiltersRequest,
  getTermsAndCarsRequest,
  getTermsAndCarsResponse,
  clearCarListState,
} from './carListReducer';
import {
  makeSelectRequest,
  makeSelectLastFilter,
  makeSelectFilters,
  makeSelectAppliedFilters,
  makeSelectLeasing,
} from './carListSelectors';
const { responseMappers: assetResponseMappers } = assetList.assetListMappers;

export function* getTermsAndFetchCars({ payload = {} }) {
  if (yield select(selectIsCaseWithoutType())) {
    yield take(caseDetail.getCaseResponse().type);
  }
  const isPrivate = yield select(selectIsCasePrivate());
  const sagaToCall = isPrivate ? getVendorTermsPROL : getVendorTermsCMOL;
  yield call(sagaToCall);
  yield put(clearCarListState({ fullReset: payload.fullReset }));
  yield call(createInitialQueryFilters);
  yield put(getTermsAndCarsResponse());
  yield call(getCars);
}

export function* getCars() {
  try {
    const isPrivate = yield select(selectIsCasePrivate());

    const filtersApiToCall = isPrivate
      ? Api.getFilteredAssetsPrivate
      : Api.getFilteredAssetsCommercial;
    const requestBody = yield select(makeSelectRequest());

    const { assets, filters } = yield callWithAttachers({
      endpoint: filtersApiToCall,
      payload: requestBody,
      attachers: [
        createAttacherForTermsToUse(
          isPrivate ? PROCESS_VARIANCES.PRIVATE_OL : PROCESS_VARIANCES.COMMERCIAL_OL,
        ),
      ],
    });
    // For the filter that was selected last, dont update it's possible filter values
    // because we want multi select to be available for that filter
    const lastFilter = yield select(makeSelectLastFilter());
    const previousFilters = yield select(makeSelectFilters());
    const appliedFilters = yield select(makeSelectAppliedFilters());

    if (
      previousFilters.brands.length > filters.brands.length &&
      lastFilter !== FILTER_VEHICLE_TYPES
    ) {
      filters.brands = previousFilters.brands;
    }
    if (
      !stringUtils.isNullOrEmpty(lastFilter) &&
      filters[lastFilter] &&
      filters[lastFilter].length < previousFilters[lastFilter].length
    ) {
      filters[lastFilter] = previousFilters[lastFilter];
    }

    Object.keys(filters).forEach((key) => {
      if (appliedFilters[key].length === 0) {
        return;
      }

      if (appliedFilters[key].length >= filters[key].length) {
        if (filters[key].length > 0 && typeof filters[key][0] === 'object') {
          appliedFilters[key].forEach((filter) => {
            const isPresentInNewFilters =
              filters[key].findIndex((element) => element.id === filter.value) >= 0;
            if (!isPresentInNewFilters) {
              const index = previousFilters[key].findIndex(
                (element) => element.value === filter.value,
              );
              filters[key] = filters[key].concat(previousFilters[key][index]);
            }
          });
        } else {
          filters[key] = previousFilters[key];
        }
      }
    });
    yield put(
      fetchCarsResponse({
        cars: assetResponseMappers.mapAssets(assets, isPrivate),
        filters: responseMappers.mapFilters(filters),
      }),
    );
  } catch (e) {
    yield put(fetchCarsResponse(e));
  }
}

export function* applyQueryFilters({ payload }) {
  const { historySearch } = payload;
  const params = reactUtils.getParamsFromQuery(historySearch);
  yield all(
    Object.keys(params)
      .map((paramKey) => {
        if (paramKey === 'price') {
          return [
            put(updateLeaseTerms({ key: 'min_price', value: params[paramKey][0] })),
            put(updateLeaseTerms({ key: 'max_price', value: params[paramKey][1] })),
          ];
        } else if (paramKey === 'mileage' || paramKey === 'period') {
          return put(updateSelectedLeasingTerm({ key: paramKey, value: Number(params[paramKey]) }));
        }
        return put(
          applyFilters({
            filter: paramKey,
            value: Array.isArray(params[paramKey])
              ? params[paramKey].map((singleParam) => ({
                  label: decodeURIComponent(singleParam),
                  value: decodeURIComponent(singleParam),
                }))
              : {
                  label: decodeURIComponent(params[paramKey]),
                  value: decodeURIComponent(params[paramKey]),
                },
          }),
        );
      })
      .flat(),
  );
}

const NON_CAR_FIELDS = ['mileage', 'period', 'price'];

export function* createQueryFilters({ payload }) {
  const { key, value, history } = payload;
  const appliedFilters = yield select(makeSelectAppliedFilters());
  let appliedFiltersParams = requestMappers.mapAppliedFilters({ appliedFilters });

  const params = reactUtils.getParamsFromQuery(history.location.search);
  appliedFiltersParams = Object.keys(params).reduce((prev, paramKey) => {
    if (NON_CAR_FIELDS.includes(paramKey)) {
      return { ...prev, [paramKey]: params[paramKey] };
    }
    return prev;
  }, appliedFiltersParams);

  if (key === FILTER_VEHICLE_TYPES) {
    appliedFiltersParams = Object.keys(appliedFiltersParams).reduce(
      (prev, paramKey) =>
        NON_CAR_FIELDS.includes(paramKey)
          ? { ...prev, [paramKey]: appliedFiltersParams[paramKey] }
          : prev,
      {},
    );
  }

  appliedFiltersParams[key] = Array.isArray(value)
    ? value.map((val) => (val.value ? val.value : val))
    : value.value || value;

  const queryParams = ObjectUtils.serialize(appliedFiltersParams);
  yield put(
    push({
      search: queryParams,
    }),
  );
}

export function* createInitialQueryFilters() {
  const appliedFilters = yield select(makeSelectAppliedFilters());
  const leasing = yield select(makeSelectLeasing());
  const terms = yield select(selectSelectedTerms());

  const appliedFiltersParams = {
    ...requestMappers.mapAppliedFilters({ appliedFilters }),
    price: [leasing.min_price, leasing.max_price],
    mileage: terms.mileage,
    period: terms.period,
  };

  const queryParams = ObjectUtils.serialize(appliedFiltersParams);
  yield put(
    push({
      search: queryParams,
    }),
  );
}

export default function* carList() {
  yield takeLatest(fetchCarsRequest, getCars);
  yield takeLatest(applyQueryFiltersRequest, applyQueryFilters);
  yield takeLatest(createQueryFiltersRequest, createQueryFilters);
  yield takeLatest(getTermsAndCarsRequest, getTermsAndFetchCars);
}
