"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: _ref => {
    let {
      colors
    } = _ref;
    return {
      background: colors.custom.carCards.gradients.carCase,
      backgroundColor: colors.custom.carCards.solidBackground,
      minHeight: '360px',
      padding: '24px 44px',
      position: 'relative'
    };
  },
  boxesContainer: _ref2 => {
    let {
      breakpoints
    } = _ref2;
    return {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      [breakpoints.down('lg')]: {
        justifyContent: 'unset'
      }
    };
  },
  title: _ref3 => {
    let {
      typography: {
        main
      },
      colors,
      breakpoints
    } = _ref3;
    return {
      color: colors.custom.carCards.header,
      fontFamily: main,
      fontSize: '40px',
      fontWeight: 'bold',
      letterSpacing: '1px',
      lineHeight: '50px',
      [breakpoints.down('lg')]: {
        textAlign: 'center'
      }
    };
  },
  description: _ref4 => {
    let {
      typography: {
        main
      },
      fontSizes: {
        small
      },
      colors,
      breakpoints
    } = _ref4;
    return {
      color: colors.custom.carCards.primary,
      fontFamily: main,
      fontSize: small,
      padding: '15px 0',
      lineHeight: '14px',
      [breakpoints.down('lg')]: {
        textAlign: 'center'
      }
    };
  },
  content: {
    textAlign: 'center'
  },
  image: {
    padding: '10px',
    maxWidth: '100%'
  }
};