"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.readNotifications = exports.getAllNotifications = void 0;
var _requestHandler = _interopRequireDefault(require("../../utils/request-handler"));
const {
  notificationsDomain
} = _requestHandler.default;
const getAllNotifications = () => notificationsDomain.GET_ALL_NOTIFICATIONS.call();
exports.getAllNotifications = getAllNotifications;
const readNotifications = _ref => {
  let {
    resourceId
  } = _ref;
  return notificationsDomain.READ_NOTIFICATIONS.call({
    body: {
      resourceId
    }
  });
};
exports.readNotifications = readNotifications;