"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectIsNewInsuranceMatrix = exports.selectIsGettingInsuranceNames = exports.selectIsGettingInsuranceMatrix = exports.selectInsuranceNamesWithEmptyOption = exports.selectInsuranceNames = exports.selectCurrentInsuranceMatrix = void 0;
var _reselect = require("reselect");
var _stringUtils = require("../../utils/string-utils");
const selectInsurance = state => state.insurance;
const selectInsuranceNames = () => (0, _reselect.createSelector)(selectInsurance, insuranceState => insuranceState.get('names').toJS());
exports.selectInsuranceNames = selectInsuranceNames;
const selectIsGettingInsuranceNames = () => (0, _reselect.createSelector)(selectInsurance, insuranceState => insuranceState.get('isLoadingNames'));
exports.selectIsGettingInsuranceNames = selectIsGettingInsuranceNames;
const selectInsuranceNamesWithEmptyOption = () => (0, _reselect.createSelector)(selectInsuranceNames(), insuranceNames => {
  const emptyOption = {
    value: _stringUtils.EMPTY_VALUE,
    label: _stringUtils.EMPTY_VALUE
  };
  const options = insuranceNames.map(name => ({
    value: name,
    label: name
  }));
  return [emptyOption, ...options];
});
exports.selectInsuranceNamesWithEmptyOption = selectInsuranceNamesWithEmptyOption;
const selectCurrentInsuranceMatrix = () => (0, _reselect.createSelector)(selectInsurance, insuranceState => {
  var _insuranceState$get;
  return (_insuranceState$get = insuranceState.get('currentMatrix')) === null || _insuranceState$get === void 0 ? void 0 : _insuranceState$get.toJS();
});
exports.selectCurrentInsuranceMatrix = selectCurrentInsuranceMatrix;
const selectIsGettingInsuranceMatrix = () => (0, _reselect.createSelector)(selectInsurance, insuranceState => insuranceState.get('isLoadingMatrix'));
exports.selectIsGettingInsuranceMatrix = selectIsGettingInsuranceMatrix;
const selectIsNewInsuranceMatrix = () => (0, _reselect.createSelector)(selectInsurance, insuranceState => insuranceState.get('isNewMatrix'));
exports.selectIsNewInsuranceMatrix = selectIsNewInsuranceMatrix;