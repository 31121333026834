import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  Component: PropTypes.func.isRequired,
  custom: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  'data-testid': PropTypes.string,
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  isError: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isPaddedTop: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  /**
   * Name attribute of the hidden `input` element.
   */
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onChangeCommitted: PropTypes.func.isRequired,
  /**
   * The value of the slider.
   * For ranged sliders, provide an array with two values.
   */
  value: PropTypes.number.isRequired,
} as any;
