"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.COLOR_YELLOW = exports.COLOR_RED = exports.COLOR_ORANGE = exports.COLOR_GREEN = exports.COLOR_GRAY = exports.COLOR_BLUE = void 0;
var _default = exports.default = {
  action: 'action',
  date: 'date',
  timestamp: 'timestamp',
  status: 'status',
  assetStatus: 'asset_status',
  productType: 'productType',
  productSubType: 'productSubType',
  assetState: 'assetState',
  money: 'money',
  caseId: 'caseId'
};
const COLOR_GREEN = exports.COLOR_GREEN = 'green';
const COLOR_BLUE = exports.COLOR_BLUE = 'blue';
const COLOR_ORANGE = exports.COLOR_ORANGE = 'orange';
const COLOR_RED = exports.COLOR_RED = 'red';
const COLOR_GRAY = exports.COLOR_GRAY = 'gray';
const COLOR_YELLOW = exports.COLOR_YELLOW = 'yellow';