import { createSelector } from 'reselect';

import { hasValueChanged } from './asset-utils';

const selectFrameState = (state) => state.asset.frame;

export const selectFrame = () =>
  createSelector(selectFrameState, (state) => state.get('frame').toJS());

export const isFrameSubmitting = () =>
  createSelector(selectFrameState, (state) => state.get('submitingFrame'));

export const selectHasFrameChanged = () =>
  createSelector(selectFrameState, selectFrame(), (state, frame) =>
    hasValueChanged({
      currentValue: frame.id,
      previousValue: state.get('previousFrameId'),
    }),
  );
