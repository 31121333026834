"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.responseMappers = exports.requestMappers = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _core = require("@northstar/core");
var _utils = require("../utils");
var _authConstants = require("./authConstants");
const _excluded = ["identities", "internal"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const identitySorter = (identityA, identityB) => {
  const aVendorId = Number(Boolean(identityA.vendor.vendor_id));
  const bVendorId = Number(Boolean(identityB.vendor.vendor_id));
  const aRole = identityA.role.base_type;
  const bRole = identityB.role.base_type;
  return bVendorId - aVendorId || aRole.localeCompare(bRole);
};
const mapLoginResponse = _ref => {
  let {
      identities,
      internal
    } = _ref,
    response = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return _objectSpread(_objectSpread({}, response), {}, {
    isInternal: internal,
    identities: Object.values(identities).sort(identitySorter)
  });
};
const mapProcessVariancesToImporters = _ref2 => {
  let {
    vendorName,
    processVariances,
    vendorProcessVariances,
    importers
  } = _ref2;
  return Object.keys(processVariances).reduce((prev, curr) => {
    if (!processVariances[curr]) return prev;
    const importersInfo = importers.filter(_ref3 => {
      let {
        process_configuration: {
          process_variances: importersProcessVariances
        }
      } = _ref3;
      return importersProcessVariances[curr] === _core.PROCESS_VARIANCES_VALUES.PROCESS_VARIANCE_ENABLED;
    }).map(_ref4 => {
      let {
        name,
        importer_id: id
      } = _ref4;
      return {
        name,
        id
      };
    });
    return _objectSpread(_objectSpread({}, prev), {}, {
      [curr]: vendorProcessVariances[curr] === _core.PROCESS_VARIANCES_VALUES.PROCESS_VARIANCE_ENABLED ? [{
        id: _authConstants.USE_CURRENT_VENDOR_TERMS,
        name: vendorName
      }, ...importersInfo] : importersInfo
    });
  }, {});
};
const mapProcessVariances = _ref5 => {
  let {
    name: vendorName,
    process_configuration: {
      process_variances: processVariances
    },
    importers = []
  } = _ref5;
  const importersProcessVariances = importers.map(_ref6 => {
    let {
      process_configuration: {
        process_variances: processVariancesFromImporter
      }
    } = _ref6;
    return processVariancesFromImporter;
  });
  const mergedImportersProcessVariances = (0, _utils.mergeProcessVariances)(importersProcessVariances);
  const mergedProcessVariances = (0, _utils.mergeProcessVariances)([mergedImportersProcessVariances, processVariances]);
  const processVariancesWithImporters = mapProcessVariancesToImporters({
    processVariances: mergedProcessVariances,
    vendorProcessVariances: processVariances,
    importers,
    vendorName
  });
  return {
    vendorProcessVariances: processVariances,
    importersProcessVariances: mergedImportersProcessVariances,
    processVariancesWithImporters
  };
};
const requestMappers = exports.requestMappers = {};
const responseMappers = exports.responseMappers = {
  mapLoginResponse,
  mapProcessVariances
};
var _default = exports.default = {
  requestMappers,
  responseMappers
};