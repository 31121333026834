import { Box, SxProps, Theme } from '@mui/material';
import { useState, useEffect, FC, ImgHTMLAttributes } from 'react';

import { propTypes } from './MAFProgressiveImage.propTypes';

export interface IMAFProgressiveImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
  placeholder: string;
  width?: number;
  onError?: () => void;
  sx?: SxProps<Theme>;
  onLoad?: () => void;
}

const MAFProgressiveImage: FC<IMAFProgressiveImageProps> = ({
  src,
  placeholder,
  width,
  onError,
  sx,
  onLoad,
  alt = '',
  ...rest
}) => {
  const [image, setImage] = useState(placeholder);

  useEffect(() => {
    setImage(src || placeholder);
  }, [src, placeholder]);

  const showPlaceholder = () => {
    setImage(placeholder);
    onError && onError();
  };

  const handleLoad = () => {
    if (image !== placeholder) onLoad && onLoad();
  };

  return (
    <Box
      component="img"
      alt={alt}
      src={image}
      onLoad={handleLoad}
      onError={showPlaceholder}
      width={width}
      sx={sx}
      {...rest}
    />
  );
};

MAFProgressiveImage.propTypes = propTypes;

export default MAFProgressiveImage;
