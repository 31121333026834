import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  data: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      }).isRequired,
      hasCheckbox: PropTypes.bool,
    }),
  ).isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.func,
      fallbackName: PropTypes.string,
      isHidden: PropTypes.bool,
      isUnsortable: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
        .isRequired,
      name: PropTypes.string.isRequired,
      renderFunc: PropTypes.func,
      shouldStopPropagation: PropTypes.bool,
      sortField: PropTypes.string,
      sortTitle: PropTypes.node,
      subName: PropTypes.string,
    }),
  ).isRequired,
  hasCheckbox: PropTypes.bool,
  isUplift: PropTypes.bool,
  isWithMobile: PropTypes.bool,
  isWithPagination: PropTypes.bool,
  onItemClick: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selectCase: PropTypes.func,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  tableClasses: PropTypes.shape({
    rowDesktop: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
      PropTypes.func,
      PropTypes.object,
    ]),
    rowMobile: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
      PropTypes.func,
      PropTypes.object,
    ]),
  }),
} as any;
