"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.responseMappers = exports.requestMappers = exports.formMappers = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _caseStatuses = require("../../utils/case-statuses");
var _objectUtils = require("../../utils/object-utils");
var _translationsUtils = require("../../utils/translations-utils");
var _formatUtils = require("../../utils/format-utils");
var _casesConstants = require("./casesConstants");
var _casesUtils = require("./casesUtils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const mapCasesRequest = _ref => {
  let {
    pagination,
    sorting,
    filter: {
      [_casesConstants.FILTER_FIELD_EMPLOYEE_SELECTED]: employeeSelected,
      [_casesConstants.FILTER_FIELD_DATE_FROM_SELECTED]: dateFromSelected,
      [_casesConstants.FILTER_FIELD_DATE_TO_SELECTED]: dateToSelected,
      [_casesConstants.FILTER_FIELD_PRODUCT_TYPE_SELECTED]: productType,
      [_casesConstants.FILTER_FIELD_PRODUCT_SUB_TYPE_SELECTED]: productSubType,
      [_casesConstants.FILTER_FIELD_STATUS_SELECTED]: selectedStatus,
      [_casesConstants.FILTER_FIELD_IMPORTER_NAME]: importerName
    },
    searchCriteria,
    caseDeletionStatus
  } = _ref;
  return {
    page: pagination.page,
    size: pagination.size,
    created_from: dateFromSelected,
    created_to: dateToSelected,
    employee_emails: employeeSelected,
    sort_params: (0, _formatUtils.createSortingUrlParamsArr)({
      by: sorting.orderBy,
      direction: sorting.direction.toUpperCase()
    }),
    product_type: productType,
    product_sub_type: productSubType,
    importer_name: importerName,
    case_status_filter_list: selectedStatus.map(_caseStatuses.getStatusesWithSameTranslations).reduce((prev, curr) => [...prev, ...curr], []).map(_caseStatuses.getStatusForFilter).reduce((acc, curr) => acc.concat(curr), []),
    search_criteria: searchCriteria,
    case_deletion_status: caseDeletionStatus
  };
};
const mapCaseCount = _ref2 => {
  let {
    case_count: casesCount
  } = _ref2;
  return {
    casesCount
  };
};
const mapCasesResponse = cases => cases.map(singleCase => {
  const camelCasedCase = (0, _objectUtils.objectToCamelCase)({
    obj: singleCase
  });
  return _objectSpread(_objectSpread({}, camelCasedCase), {}, {
    mappedStatus: (0, _caseStatuses.getStatusForCase)(camelCasedCase)
  });
});
const mapCasesForExportResponse = _ref3 => {
  let {
    response,
    columns,
    locale
  } = _ref3;
  const mappedCases = mapCasesResponse(response);
  const cases = mappedCases.map(currentCase => {
    const brandAndModel = "".concat(currentCase.brand || '', " ").concat(currentCase.model || '');
    const applicantName = "".concat(currentCase.firstName || '', " ").concat(currentCase.lastName || '');
    return _objectSpread(_objectSpread({}, currentCase), {}, {
      brandAndModel,
      applicantName
    });
  });
  const columnsNames = columns.map(col => col.name);
  return cases.reduce((prevCases, currCase) => {
    const currentCase = columnsNames.reduce((prevKey, currKey) => {
      const column = columns.find(col => col.name === currKey);
      return _objectSpread(_objectSpread({}, prevKey), {}, {
        [(0, _translationsUtils.getTranslationByKey)({
          key: column.labelId,
          locale
        })]: (0, _casesUtils.getValueByType)({
          type: column.type,
          value: currCase[currKey],
          locale
        })
      });
    }, {});
    return [...prevCases, currentCase];
  }, []);
};
const requestMappers = exports.requestMappers = {
  mapCases: mapCasesRequest
};
const responseMappers = exports.responseMappers = {
  mapCaseCount,
  mapCases: mapCasesResponse,
  mapCasesForExport: mapCasesForExportResponse
};
const formMappers = exports.formMappers = {};
var _default = exports.default = {
  requestMappers,
  responseMappers,
  formMappers
};