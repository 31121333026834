"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  headerCell: _ref => {
    let {
      typography: {
        main
      },
      colors
    } = _ref;
    return _objectSpread(_objectSpread({
      fontFamily: main
    }, colors.custom.contentAlternative.main), {}, {
      fontWeight: 600,
      '&::after': {
        content: '""',
        width: '100%',
        height: '100%',
        '@media not all and (min-resolution:.001dpcm)': {
          height: 56
        },
        display: 'block',
        position: 'absolute',
        bottom: 0,
        margin: '0px -5px',
        borderBottom: "1px solid ".concat(colors.alpha(colors.custom.contentAlternative.main.color, 0.4)),
        zIndex: -1
      },
      '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
        borderBottom: "1px solid ".concat(colors.alpha(colors.custom.contentAlternative.main.color, 0.4))
      }
    });
  },
  headerCellAdjusted: {
    top: 75
  },
  table: _ref2 => {
    let {
      colors
    } = _ref2;
    return _objectSpread({}, colors.custom.contentAlternative.main);
  },
  totalCount: {
    float: 'right',
    marginTop: '-30px'
  },
  icon: _ref3 => {
    let {
      colors
    } = _ref3;
    return {
      '& g': {
        fill: colors.alpha(colors.custom.contentAlternative.main.color, 0.4)
      }
    };
  },
  iconButton: _ref4 => {
    let {
      colors
    } = _ref4;
    return {
      width: 48,
      height: 48,
      '&:hover g': {
        fill: colors.solid.lightBlue
      }
    };
  },
  spinnerContainer: {
    width: 48,
    top: 7,
    height: 'auto',
    display: 'inline-block',
    position: 'relative'
  },
  spinner: {
    fontSize: 24
  },
  checkbox: _ref5 => {
    let {
      colors
    } = _ref5;
    return {
      color: colors.custom.contentAlternative.main.color
    };
  },
  tabs: _ref6 => {
    let {
      fontSizes
    } = _ref6;
    return {
      '& .MuiTab-root': {
        fontSize: fontSizes.extraSmall,
        textTransform: 'uppercase',
        maxWidth: 'fit-content'
      },
      '& .MuiTabs-indicator > span': {
        maxWidth: '100% !important'
      }
    };
  }
};