import { ISxStyles } from '../../../../theming/theme.type';

export const styles: ISxStyles = {
  root: ({ colors }) => ({
    '&.MuiTableCell-root': {
      padding: '0 5px',
      borderBottom: `1px solid ${colors.custom.table.borderColor}`,
    },
  }),
  cellMobile: {
    '&.MuiTableCell-root': {
      width: 'calc(100% - 25px)',
      '&:last-child': {
        width: 'calc(100% - 10px)',
      },
      display: 'inline-block',
    },
  },
  cell: {
    height: '100%',
    justifyContent: 'center',
  },
  cellTypeBody: ({ typography: { main }, colors }) => ({
    '&.MuiTableCell-body': {
      fontFamily: main,
      lineHeight: 1,
      letterSpacing: 0,
      color: colors.custom.contentAlternative.main.color,
      height: 56,
    },
  }),
  contentHeader: ({ fontSizes }) => ({
    letterSpacing: 0.5,
    lineHeight: fontSizes.medium,
  }),
  content: ({ fontSizes }) => ({
    display: 'flex',
    width: '100%',
    fontSize: fontSizes.extraSmall,
  }),
};
