"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  content: {
    padding: '30px'
  },
  sidebar: _ref => {
    let {
      colors
    } = _ref;
    return _objectSpread({
      padding: '0 !important'
    }, colors.custom.contentSecondary);
  },
  contentContainer: _ref2 => {
    let {
      colors
    } = _ref2;
    return _objectSpread({
      width: '1200px',
      margin: '94px auto 60px auto'
    }, colors.custom.selectLayout.mainBlock);
  },
  container: {
    minHeight: 'calc(100vh - 54px)',
    marginTop: '54px'
  },
  contentHideSidebar: {
    padding: "0 !important"
  },
  hideSidebar: {
    display: 'none'
  },
  contentContainerHideSidebar: {
    width: '100%',
    margin: 0,
    minHeight: 'auto !important',
    marginTop: "0 !important"
  }
};