"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PUBLIC_BACKGROUNG_IMAGE_MOBILE_URL = exports.PUBLIC_BACKGROUND_IMAGE_URL = exports.PUBLIC_BACKGROUND_IMAGE_BLUR_URL = exports.PHONE_NUMBER_SHORT = exports.PHONE_NUMBER_LONG = exports.PHONE_NUMBER_LINK = exports.ORGANISATION_AND_SSN_MASK_FORMAT = exports.KYC_BACKGROUND_IMAGE_768_URL = exports.KYC_BACKGROUND_IMAGE_320_URL = exports.EMAIL = exports.DEFAULT_ISO_FORMAT = exports.DATE_REQUEST_FORMAT = exports.CURRENT_YEAR = void 0;
Object.defineProperty(exports, "TABLE_FIELD_TYPES", {
  enumerable: true,
  get: function () {
    return _constants.default;
  }
});
exports.WEBSITE_GDPR = exports.WEBSITE = void 0;
var _constants = _interopRequireDefault(require("./NSTable/constants"));
const DATE_REQUEST_FORMAT = exports.DATE_REQUEST_FORMAT = 'YYYY-MM-DD';
const DEFAULT_ISO_FORMAT = exports.DEFAULT_ISO_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';
const EMAIL = exports.EMAIL = 'support.northstar@danskebank.se';
const PHONE_NUMBER_LINK = exports.PHONE_NUMBER_LINK = 'tel:+46752481818';
const PHONE_NUMBER_LONG = exports.PHONE_NUMBER_LONG = '+46(0)752-48 18 18';
const PHONE_NUMBER_SHORT = exports.PHONE_NUMBER_SHORT = '0752-48 18 18';
const WEBSITE = exports.WEBSITE = 'www.danskebank.se';
const WEBSITE_GDPR = exports.WEBSITE_GDPR = 'www.danskebank.se/gdpr';
const CURRENT_YEAR = exports.CURRENT_YEAR = new Date().getFullYear();
const PUBLIC_BACKGROUND_IMAGE_URL = exports.PUBLIC_BACKGROUND_IMAGE_URL = '/images/bg.jpg';
const PUBLIC_BACKGROUNG_IMAGE_MOBILE_URL = exports.PUBLIC_BACKGROUNG_IMAGE_MOBILE_URL = '/images/bg_mobile.jpg';
const PUBLIC_BACKGROUND_IMAGE_BLUR_URL = exports.PUBLIC_BACKGROUND_IMAGE_BLUR_URL = '/images/bg_blur.jpg';
const KYC_BACKGROUND_IMAGE_320_URL = exports.KYC_BACKGROUND_IMAGE_320_URL = '/images/kycExternal/bg320.png';
const KYC_BACKGROUND_IMAGE_768_URL = exports.KYC_BACKGROUND_IMAGE_768_URL = '/images/kycExternal/bg768.png';
const ORGANISATION_AND_SSN_MASK_FORMAT = exports.ORGANISATION_AND_SSN_MASK_FORMAT = '######-####';