"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledEngine = require("@mui/styled-engine");
var _templateObject, _templateObject2;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const containerPadding = 20;
const iconPadding = 8;
const collapsing = (0, _styledEngine.keyframes)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  100% {\n    height: 50px;\n  }\n"])));
const expanding = (0, _styledEngine.keyframes)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  0% {\n    height: 50px;\n    overflow: hidden;\n  }\n  80% {\n    overflow: inherit;\n  }\n  100% {\n    height: 94px;\n  }\n"])));
const styles = exports.styles = {
  container: _ref => {
    let {
      typography: {
        baseFont
      },
      colors
    } = _ref;
    return _objectSpread(_objectSpread({}, baseFont), {}, {
      padding: "".concat(containerPadding - iconPadding, "px ").concat(containerPadding, "px"),
      color: colors.custom.fontPrimary,
      display: 'flex',
      cursor: 'pointer',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontWeight: 'normal'
    });
  },
  arrowIcon: {
    width: 12,
    height: 12
  },
  expandableContainerPadding: {
    padding: "".concat(containerPadding - iconPadding, "px ").concat(containerPadding, "px ").concat(containerPadding - iconPadding, "px ").concat(containerPadding, "px")
  },
  expandableContainerHover: _ref2 => {
    let {
      colors
    } = _ref2;
    return {
      '&:hover > svg:last-child': _objectSpread({}, colors.custom.contentSecondary)
    };
  },
  expandableContent: {
    overflow: 'hidden'
  },
  active: _ref3 => {
    let {
      colors
    } = _ref3;
    return _objectSpread({}, colors.custom.sidebar.active);
  },
  menuItem: _ref4 => {
    let {
      colors
    } = _ref4;
    return _objectSpread(_objectSpread({}, colors.custom.body), {}, {
      borderTop: "1px solid ".concat(colors.alpha(colors.solid.cadedDarkGray, 0.76))
    });
  },
  paddedMenuItem: {
    paddingLeft: '40px'
  },
  fullWidth: {
    width: '100%',
    animation: "".concat(collapsing, " 0.2s ease-out")
  },
  icon: {
    height: '16px',
    paddingRight: '5px',
    marginBottom: '-2px'
  },
  expandableAnimation: {
    animation: "".concat(expanding, " 0.2s cubic-bezier(0.56, 0.14, 0.24, 0.96)")
  }
};