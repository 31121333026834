import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  actionComponent: PropTypes.node,
  additionalTopAdjustment: PropTypes.number,
  children: PropTypes.node,
  forwardedRef: PropTypes.shape({
    current: (props, propName) => {
      if (props[propName] == null) {
        return null;
      } else if (typeof props[propName] !== 'object' || props[propName].nodeType !== 1) {
        return new Error(`Expected prop '${propName}'  to be of type Element`);
      }
      return null;
    },
  }).isRequired,
  hasNoBackground: PropTypes.bool,
  mainComponent: PropTypes.node,
  selectedItems: PropTypes.number.isRequired,
  selectedItemsActionComponent: PropTypes.node,
  selectedItemsActionLabel: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  widthAdjustments: PropTypes.number,
} as any;
