import MAFSlider, { IMAFSliderProps } from '../MAFSlider/MAFSlider';
import { adjustMarks } from '../MAFSlider/MAFSlider.utils';

import { propTypes } from './MAFSliderWithMarks.propTypes';

export interface IMAFSliderWithMarksProps extends Omit<IMAFSliderProps, 'marks'> {
  marks: number[];
  isCustomStep?: boolean;
}

const MAFSliderWithMarks: React.FC<IMAFSliderWithMarksProps> = ({
  title,
  step,
  min,
  max,
  value,
  marks,
  isCustomStep,
  onChange,
  ...props
}) => {
  const adjustedMarks = adjustMarks({ marks });

  const overrideOnChange = isCustomStep
    ? (presentValue: number) => {
        if (min && step) {
          const newValue =
            presentValue > min
              ? Math.round(presentValue / step) * step
              : Math.ceil(presentValue / step) * step;
          onChange && onChange(newValue);
        }
      }
    : onChange;

  const overrideStep = isCustomStep ? 1 : step;
  const currentValue = value && min && value >= min ? value : undefined;

  return (
    <MAFSlider
      title={title}
      step={overrideStep}
      min={min}
      max={max}
      value={currentValue}
      marks={adjustedMarks}
      {...props}
      onChange={overrideOnChange}
    />
  );
};

MAFSliderWithMarks.propTypes = propTypes;

export default MAFSliderWithMarks;
