"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  paper: _ref => {
    let {
      colors,
      breakpoints
    } = _ref;
    return _objectSpread(_objectSpread({}, colors.custom.contentAlternative.main), {}, {
      margin: '94px 40px 40px',
      padding: '18px 0px 18px 36px',
      minHeight: 'calc(100vh - 170px)',
      minWidth: 'calc(100vw - 112px)',
      display: 'grid',
      [breakpoints.down('lg')]: {
        padding: '5px 0px 5px 15px',
        margin: '74px 20px 20px',
        minHeight: 'calc(100vh - 104px)',
        minWidth: 'calc(100vw - 55px)'
      },
      [breakpoints.only('xs')]: {
        padding: 0,
        margin: '64px 10px 10px',
        minHeight: 'calc(100vh - 75px)',
        minWidth: 'calc(100vw - 20px)'
      }
    });
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  tableContainer: _ref2 => {
    let {
      colors,
      breakpoints
    } = _ref2;
    return {
      marginTop: '10px',
      paddingRight: '36px',
      [breakpoints.down('lg')]: {
        paddingRight: '15px'
      },
      [breakpoints.only('xs')]: {
        paddingRight: 0
      },
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        [breakpoints.up('sm')]: {
          width: '5px !important',
          color: colors.custom.contentAlternative.main.color
        }
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colors.alpha(colors.custom.contentAlternative.main.color, 0.4),
        borderRadius: '4px'
      }
    };
  }
};