"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "EditableDetails", {
  enumerable: true,
  get: function () {
    return _EditableDetails.default;
  }
});
Object.defineProperty(exports, "ReadOnlyDetails", {
  enumerable: true,
  get: function () {
    return _ReadOnlyDetails.default;
  }
});
var _EditableDetails = _interopRequireDefault(require("./EditableDetails/EditableDetails"));
var _ReadOnlyDetails = _interopRequireDefault(require("./ReadOnlyDetails/ReadOnlyDetails"));