"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.responseMappers = exports.requestMappers = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _assetMappers = require("../asset/assetMappers");
var _frameMappers = require("../asset/frameMappers");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const mapAssetsToStore = (assets, isPrivate) => assets.map(asset => _objectSpread(_objectSpread({}, _assetMappers.responseMappers.mapAsset(asset, isPrivate)), {}, {
  frame: _frameMappers.responseMappers.mapFrame(asset.frame || {})
})).sort((assetA, assetB) => new Date(assetB.created) - new Date(assetA.created));
const requestMappers = exports.requestMappers = {};
const responseMappers = exports.responseMappers = {
  mapAssets: mapAssetsToStore
};
var _default = exports.default = {
  requestMappers,
  responseMappers
};