"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VEHICLE_TYPE = exports.TRIM_LEVELS = exports.TRANSMISSION_TYPES = exports.SECONDARY_FIELDS = exports.SEATINGS = exports.POWERTRAINS = exports.MODEL_YEARS = exports.MODEL_NAMES = exports.FUEL_TYPES = exports.FRAME_FORM = exports.FILTER_VEHICLE_TYPES = exports.ENGINES_POWER_KW = exports.ENGINES_POWER_HP = exports.ENGINES = exports.DRIWING_WHEELS = exports.DOORS = exports.BRAND_NAMES = exports.BODY_TYPES = void 0;
const FILTER_VEHICLE_TYPES = exports.FILTER_VEHICLE_TYPES = 'vehicle_types';
const FRAME_FORM = exports.FRAME_FORM = 'frameForm';
const VEHICLE_TYPE = exports.VEHICLE_TYPE = 'vehicleType';
const BRAND_NAMES = exports.BRAND_NAMES = 'brandNames';
const MODEL_NAMES = exports.MODEL_NAMES = 'modelNames';
const TRIM_LEVELS = exports.TRIM_LEVELS = 'trimLevels';
const MODEL_YEARS = exports.MODEL_YEARS = 'modelYears';
const BODY_TYPES = exports.BODY_TYPES = 'bodyTypes';
const FUEL_TYPES = exports.FUEL_TYPES = 'fuelTypes';
const TRANSMISSION_TYPES = exports.TRANSMISSION_TYPES = 'transmissionTypes';
const POWERTRAINS = exports.POWERTRAINS = 'powertrains';
const ENGINES = exports.ENGINES = 'engines';
const ENGINES_POWER_HP = exports.ENGINES_POWER_HP = 'enginesPowerHp';
const ENGINES_POWER_KW = exports.ENGINES_POWER_KW = 'enginesPowerKw';
const DRIWING_WHEELS = exports.DRIWING_WHEELS = 'drivingWheels';
const DOORS = exports.DOORS = 'doors';
const SEATINGS = exports.SEATINGS = 'seatings';
const SECONDARY_FIELDS = exports.SECONDARY_FIELDS = [MODEL_YEARS, BODY_TYPES, FUEL_TYPES, TRANSMISSION_TYPES, POWERTRAINS, ENGINES, ENGINES_POWER_HP, ENGINES_POWER_KW, DRIWING_WHEELS, DOORS, SEATINGS];