"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectPdfs = exports.selectIsSendingDocument = exports.selectIsGettingDocument = void 0;
var _reselect = require("reselect");
const documentsState = state => state.documents;
const selectPdfs = () => (0, _reselect.createSelector)(documentsState, documents => ({
  invoicePdf: documents.get('invoicePdfBinary'),
  signingAgreement: documents.get('signingAgreementPdfBinary'),
  signingAgreementAppendix: documents.get('signingAgreementPdfBinaryAppendix'),
  proofOfDeliveryPaper: documents.get('proofOfDeliveryPaperPdfBinary'),
  guarantorAgreement: documents.get('guarantorAgreementPdfBinary')
}));
exports.selectPdfs = selectPdfs;
const selectIsSendingDocument = () => (0, _reselect.createSelector)(documentsState, state => state.get('isSendingDocument'));
exports.selectIsSendingDocument = selectIsSendingDocument;
const selectIsGettingDocument = () => (0, _reselect.createSelector)(documentsState, state => state.get('isGettingDocument'));
exports.selectIsGettingDocument = selectIsGettingDocument;