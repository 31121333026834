import { reactUtils, constants } from '@northstar/core-ui/utils';
import { DateTimeUtils } from '@northstar/core';

export const hasValueChanged = ({ currentValue, previousValue }) => {
  if (!currentValue && previousValue) return false;
  if (!previousValue) return true;
  return previousValue !== currentValue;
};

export const getCarAgeMonths = (registrationDate) =>
  registrationDate
    ? DateTimeUtils.getDifferenceBetweenDatesInMonths({
        startDate: reactUtils
          .parseDefaultDateToDayjs(registrationDate)
          .format(constants.DATE_REQUEST_FORMAT),
        endDate: new Date(),
      })
    : 0;
