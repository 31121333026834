const Documents: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-action/Docs/White" fill="currentColor">
        <g id="Icon" transform="translate(4.000000, 2.000000)">
          <path
            d="M5.00030518,3 L4.1607704,3 L1.04490623,3 C0.467714141,3 0,3.486872 0,4.08770623 L0,7.33119809 L0,15.6688019 L0,18.9129269 C0,19.513128 0.467714141,20 1.04490623,20 L4.1607704,20 L7.8392296,20 L10.9550938,20 C11.5322859,20 12,19.513128 12,18.9129269 L12,17.0039062 L5.00030518,17.0039062 L5.00030518,3 Z M5.00437927,4 L5.00437927,3.00088501 L3.99943542,3.00088501 L3.99943542,4 L1.8707552,4 C1.38976178,4 1,4.42959294 1,4.95974079 L1,7.82164538 L1,15.1783546 L1,18.0408178 C1,18.5704071 1.38976178,19 1.8707552,19 L4.46730867,19 L7.53269133,19 L10.1292448,19 C10.6102382,19 11,18.5704071 11,18.0408178 L11,17.0040283 L5.00437927,17.0040283 L5.00437927,4 Z M11.0777496,0 L8.1607704,0 L5.04490623,0 C4.46771414,0 4,0.486871997 4,1.08770623 L4,4.33119809 L4,12.6688019 L4,15.9129269 C4,16.513128 4.46771414,17 5.04490623,17 L8.1607704,17 L11.8392296,17 L14.9550938,17 C15.5322859,17 16,16.513128 16,15.9129269 L16,12.6688019 L16,5.1036088 L11.0777496,0 Z M10.9950506,1 L8.46730867,1 L5.8707552,1 C5.38976178,1 5,1.42959294 5,1.95974079 L5,4.82164538 L5,12.1783546 L5,15.0408178 C5,15.5704071 5.38976178,16 5.8707552,16 L8.46730867,16 L11.5326913,16 L14.1292448,16 C14.6102382,16 15,15.5704071 15,15.0408178 L15,12.1783546 L15,5.00408936 L10.9950506,1 Z M10,1 L10.994873,1 L15,5.0065702 L15,6.00506592 L10.9998075,6.00506592 C10.4476291,6.00506592 10,5.56089971 10,5.00424544 L10,1 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Documents;
