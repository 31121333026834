"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: _ref => {
    let {
      breakpoints
    } = _ref;
    return {
      padding: '10px 10px 0px 10px',
      textAlign: 'center',
      width: '20%',
      [breakpoints.down('lg')]: {
        width: '33.3%'
      },
      [breakpoints.only('xs')]: {
        width: '50%'
      }
    };
  },
  narrow: {
    width: '16%'
  },
  subtitle: _ref2 => {
    let {
      typography: {
        main
      },
      fontSizes: {
        small
      },
      colors
    } = _ref2;
    return {
      color: colors.custom.carCards.primary,
      fontFamily: main,
      fontSize: small,
      lineHeight: '29px'
    };
  },
  title: _ref3 => {
    let {
      typography: {
        main
      },
      fontSizes: {
        tiny
      },
      colors
    } = _ref3;
    return {
      color: colors.custom.carCards.primary,
      fontFamily: main,
      fontSize: tiny,
      lineHeight: '16px'
    };
  },
  compact: {
    padding: '10px 0px'
  }
};