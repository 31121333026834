"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectVendorType = exports.selectVendor = exports.selectUsername = exports.selectUser = exports.selectSealer = exports.selectRole = exports.selectRememberUsername = exports.selectRedirectPath = exports.selectIsVendorTypeImporter = exports.selectIsVendorTypeDealership = exports.selectIsVendorAdministratorOrSalesperson = exports.selectIsVendorAdministrator = exports.selectIsSelfOnboarding = exports.selectIsFinancier = exports.selectIsCheckingForNeedOfIdentitySwitch = exports.selectIsAuthenticating = exports.selectIsAuthenticated = exports.selectIsAssetAdministrator = exports.selectImporters = exports.selectIdentityForSwitching = exports.selectIdentities = exports.selectError = exports.selectCurrentIdentity = exports.selectAuthorizations = exports.selectAuth = exports.makeSelectIsChangePasswordOpen = void 0;
var _core = require("@northstar/core");
var _reselect = require("reselect");
const selectAuth = state => state.auth;
exports.selectAuth = selectAuth;
const selectUser = () => (0, _reselect.createSelector)(selectAuth, authState => authState.get('user').toJS());
exports.selectUser = selectUser;
const selectUsername = () => (0, _reselect.createSelector)(selectUser(), user => user.username);
exports.selectUsername = selectUsername;
const selectError = () => (0, _reselect.createSelector)(selectAuth, authState => authState.get('error'));
exports.selectError = selectError;
const selectIsAuthenticating = () => (0, _reselect.createSelector)(selectAuth, authState => authState.get('authenticating'));
exports.selectIsAuthenticating = selectIsAuthenticating;
const makeSelectIsChangePasswordOpen = () => (0, _reselect.createSelector)(selectAuth, authState => authState.get('isChangePasswordOpen'));
exports.makeSelectIsChangePasswordOpen = makeSelectIsChangePasswordOpen;
const selectIsAuthenticated = () => (0, _reselect.createSelector)(selectAuth, authState => authState.get('authenticated'));
exports.selectIsAuthenticated = selectIsAuthenticated;
const selectIdentities = () => (0, _reselect.createSelector)(selectUser(), user => user.identities);
exports.selectIdentities = selectIdentities;
const selectCurrentIdentity = () => (0, _reselect.createSelector)(selectUser(), user => user.currentIdentity);
exports.selectCurrentIdentity = selectCurrentIdentity;
const selectRole = () => (0, _reselect.createSelector)(selectCurrentIdentity(), currentIdentity => currentIdentity.role.base_type);
exports.selectRole = selectRole;
const selectVendor = () => (0, _reselect.createSelector)(selectCurrentIdentity(), currentIdentity => currentIdentity.vendor);
exports.selectVendor = selectVendor;
const selectVendorType = () => (0, _reselect.createSelector)(selectCurrentIdentity(), currentIdentity => currentIdentity.vendor.type);
exports.selectVendorType = selectVendorType;
const selectIsRole = function () {
  for (var _len = arguments.length, roles = new Array(_len), _key = 0; _key < _len; _key++) {
    roles[_key] = arguments[_key];
  }
  return () => (0, _reselect.createSelector)(selectIsAuthenticated(), selectRole(), (isAuthenticated, currentRole) => isAuthenticated && roles.includes(currentRole));
};
const selectIsVendorAdministrator = exports.selectIsVendorAdministrator = selectIsRole(_core.USER_ROLE_VENDOR_ADMIN);
const selectIsVendorAdministratorOrSalesperson = exports.selectIsVendorAdministratorOrSalesperson = selectIsRole(_core.USER_ROLE_VENDOR_ADMIN, _core.USER_ROLE_VENDOR_EMPLOYEE);
const selectIsFinancier = exports.selectIsFinancier = selectIsRole(_core.USER_ROLE_FINANCE);
const selectIsAssetAdministrator = exports.selectIsAssetAdministrator = selectIsRole(_core.USER_ROLE_ASSET_ADMINISTRATOR);
const selectIsVendorTypeImporter = () => (0, _reselect.createSelector)(selectVendorType(), vendorType => vendorType === _core.VENDOR_TYPE_IMPORTER);
exports.selectIsVendorTypeImporter = selectIsVendorTypeImporter;
const selectIsVendorTypeDealership = () => (0, _reselect.createSelector)(selectIsAuthenticated(), selectVendorType(), (isAuthenticated, vendorType) => isAuthenticated && vendorType === _core.VENDOR_TYPE_DEALERSHIP);
exports.selectIsVendorTypeDealership = selectIsVendorTypeDealership;
const selectAuthorizations = () => (0, _reselect.createSelector)(selectCurrentIdentity(), currentIdentity => currentIdentity.role.authorizations);
exports.selectAuthorizations = selectAuthorizations;
const selectSealer = () => (0, _reselect.createSelector)(selectAuth, authState => authState.get('auth').toJS());
exports.selectSealer = selectSealer;
const selectRememberUsername = () => (0, _reselect.createSelector)(selectAuth, state => state.get('rememberUsername'));
exports.selectRememberUsername = selectRememberUsername;
const selectRedirectPath = () => (0, _reselect.createSelector)(selectAuth, state => {
  const redirectPath = state.get('redirectPath');
  return redirectPath && redirectPath.toJS();
});
exports.selectRedirectPath = selectRedirectPath;
const selectImporters = () => (0, _reselect.createSelector)(selectCurrentIdentity(), currentIdentity => {
  var _currentIdentity$vend;
  return ((_currentIdentity$vend = currentIdentity.vendor) === null || _currentIdentity$vend === void 0 ? void 0 : _currentIdentity$vend.importers) || [];
});
exports.selectImporters = selectImporters;
const selectIsSelfOnboarding = () => (0, _reselect.createSelector)(selectAuth, state => state.get('isSelfOnboarding'));
exports.selectIsSelfOnboarding = selectIsSelfOnboarding;
const selectIdentityForSwitching = () => (0, _reselect.createSelector)(selectAuth, authState => {
  const identityForSwitching = authState.get('identityForSwitching');
  return identityForSwitching && identityForSwitching.toJS();
});
exports.selectIdentityForSwitching = selectIdentityForSwitching;
const selectIsCheckingForNeedOfIdentitySwitch = () => (0, _reselect.createSelector)(selectAuth, state => state.get('checkingForNeedOfIdentitySwitch'));
exports.selectIsCheckingForNeedOfIdentitySwitch = selectIsCheckingForNeedOfIdentitySwitch;