import { FormattedMessage } from 'react-intl';
import { MAFButton, TMAFButtonColor, MAFDoubleButtons, Message, useScreenSize } from '@asfi/maf';

import { styles } from './footer.styles';

interface IFooterProps {
  currentCase: {
    id: string;
    owner: {
      vendor: {
        email: string;
      };
    };
  };
  isFirstStep: boolean;
  handleBack: () => void;
  redirectToQuestionaire?: () => void;
}

const Footer: React.FC<IFooterProps> = ({
  currentCase,
  isFirstStep,
  handleBack,
  redirectToQuestionaire,
}) => {
  const { isDesktop } = useScreenSize();
  const {
    id,
    owner = {
      vendor: {
        email: '',
      },
    },
  } = currentCase;
  const handleContactVendor = () => {
    if (redirectToQuestionaire) {
      redirectToQuestionaire();
      return;
    }
    const subject = `Case #${id}`;
    window.location.href = `mailto:${owner.vendor.email}?subject=${subject}`;
  };
  const primaryButtonProps = {
    color: 'primaryPR' as TMAFButtonColor,
    onClick: handleContactVendor,
    endIcon: !redirectToQuestionaire && <Message />,
  };
  const primaryButtonText = redirectToQuestionaire ? (
    <FormattedMessage id="quote.continue_application" />
  ) : (
    <FormattedMessage id="quote.contact_vendor" />
  );
  return !isFirstStep ? (
    <MAFDoubleButtons
      primaryButtonProps={primaryButtonProps}
      primaryButtonText={primaryButtonText}
      secondaryButtonProps={{
        color: 'secondary',
        onClick: handleBack,
      }}
      secondaryButtonText={<FormattedMessage id="quote.back" />}
      sx={styles.doubleButtons}
      isVertical={false}
      isSticky={!isDesktop}
      defaultBreakpoint="sm"
    />
  ) : (
    <MAFButton {...primaryButtonProps} sx={styles.button} isCentered isSticky={!isDesktop}>
      {primaryButtonText}
    </MAFButton>
  );
};

export default Footer;
