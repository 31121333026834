"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSystemMessage = exports.getSystemMessage = exports.getActiveSystemMessage = void 0;
var _requestHandler = _interopRequireDefault(require("../../utils/request-handler"));
const {
  supportDomain
} = _requestHandler.default;
const getActiveSystemMessage = () => supportDomain.GET_SYSTEM_MESSAGE_ACTIVE.call();
exports.getActiveSystemMessage = getActiveSystemMessage;
const getSystemMessage = () => supportDomain.GET_SYSTEM_MESSAGE.call();
exports.getSystemMessage = getSystemMessage;
const updateSystemMessage = body => supportDomain.UPDATE_SYSTEM_MESSAGE.call({
  body
});
exports.updateSystemMessage = updateSystemMessage;