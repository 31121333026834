"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUserIdentities = exports.updateUser = exports.regenerateConfirmationToken = exports.reauthorizeIdentities = exports.getUsersToReauthorize = exports.getUsers = exports.deleteIdentities = exports.createUser = exports.addUserIdentity = void 0;
var _requestHandler = _interopRequireDefault(require("../../utils/request-handler"));
const {
  userDomain
} = _requestHandler.default;
const getUsers = urlParams => userDomain.GET_USERS.call({
  urlParams
});
exports.getUsers = getUsers;
const getUsersToReauthorize = () => userDomain.GET_USERS_TO_REAUTHORIZE.call();
exports.getUsersToReauthorize = getUsersToReauthorize;
const createUser = body => userDomain.CREATE_USER.call({
  body
});
exports.createUser = createUser;
const updateUser = _ref => {
  let {
    username,
    requestBody
  } = _ref;
  return userDomain.UPDATE_USER.call({
    urlParams: {
      username
    },
    body: requestBody
  });
};
exports.updateUser = updateUser;
const regenerateConfirmationToken = body => userDomain.REGENERATE_CONFIRMATION_TOKEN.call({
  body
});
exports.regenerateConfirmationToken = regenerateConfirmationToken;
const addUserIdentity = body => userDomain.ADD_IDENTITY.call({
  body
});
exports.addUserIdentity = addUserIdentity;
const updateUserIdentities = body => userDomain.UPDATE_IDENTITIES.call({
  body
});
exports.updateUserIdentities = updateUserIdentities;
const reauthorizeIdentities = body => userDomain.REAUTHORIZE_IDENTITIES.call({
  body
});
exports.reauthorizeIdentities = reauthorizeIdentities;
const deleteIdentities = _ref2 => {
  let {
    identities
  } = _ref2;
  return userDomain.DELETE_IDENTITIES.call({
    urlParams: {
      identities
    }
  });
};
exports.deleteIdentities = deleteIdentities;