type TMarks = {
  marks: number[];
};

type TAdjustMarksResponse = {
  value: number;
  label: number;
};

export const adjustMarks = ({ marks }: TMarks): TAdjustMarksResponse[] =>
  marks.map((mark) => ({ value: mark, label: mark }));
