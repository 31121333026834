import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  buttonClass: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  buttonText: PropTypes.shape({
    agreeAll: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    agreeMandatory: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    agreeSome: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    moreInfo: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  }).isRequired,
  children: PropTypes.node,
  handleAccept: PropTypes.func.isRequired,
  highestLevel: PropTypes.number.isRequired,
  highestMandatoryLevel: PropTypes.number.isRequired,
  isStrict: PropTypes.bool,
  level: PropTypes.number,
} as any;
