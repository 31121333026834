"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  '@global': {
    body: {
      paddingRight: '0 !important'
    }
  },
  container: _ref => {
    let {
      colors
    } = _ref;
    return {
      '.MuiPopover-paper': _objectSpread(_objectSpread({
        marginTop: '50px',
        minWidth: '260px'
      }, colors.custom.body), {}, {
        boxShadow: "0 2px 2px 0 ".concat(colors.alpha(colors.solid.black, 0.15)),
        border: "1px solid ".concat(colors.alpha(colors.custom.background.background, 0.5))
      })
    };
  },
  label: _ref2 => {
    let {
      typography: {
        baseFont
      },
      colors
    } = _ref2;
    return _objectSpread(_objectSpread({}, baseFont), {}, {
      padding: 15,
      outline: 'none',
      color: colors.custom.fontPrimary,
      letterSpacing: '0.32',
      borderBottom: "2px solid ".concat(colors.alpha(colors.solid.redGray, 0.3))
    });
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0'
  },
  display: _ref3 => {
    let {
      typography: {
        main
      },
      fontSizes: {
        extraSmall
      },
      colors
    } = _ref3;
    return {
      '&.MuiButton-root': {
        color: colors.custom.fontPrimary,
        fontFamily: main,
        fontSize: extraSmall,
        padding: '15px',
        lineHeight: '19px',
        textDecoration: 'none',
        fontWeight: 500,
        outline: 'none',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'transparent'
        },
        '& > span:nth-child(2)': {
          maxHeight: 60
        }
      }
    };
  },
  displayInverted: _ref4 => {
    let {
      colors
    } = _ref4;
    return {
      '&.MuiButton-root': {
        color: colors.solid.cadedGray
      }
    };
  },
  role: {
    fontWeight: 100
  },
  title: _ref5 => {
    let {
      typography: {
        main
      },
      colors
    } = _ref5;
    return {
      color: colors.custom.fontPrimary,
      fontFamily: main,
      padding: '10px 15px',
      lineHeight: '19px',
      textDecoration: 'none',
      outline: 'none',
      letterSpacing: '0.68'
    };
  },
  icon: {
    marginLeft: '10px'
  }
};