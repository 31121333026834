import { ISxStyles } from '../../../theming/theme.type';

export const styles: ISxStyles = {
  wrapper: {
    '&.MuiFormControl-root': {
      marginBottom: '5px',
    },
  },
  label: ({ fontSizes: { extraSmall } }) => ({
    '&.MuiInputLabel-root': {
      display: 'flex',
      alignItems: 'center',
      fontSize: extraSmall,
      position: 'initial',
      marginBottom: '-15px',
      transform: 'none',
    },
  }),
  isLabelInside: {
    '&.MuiInputLabel-root': {
      zIndex: 1,
      marginBottom: -3,
      marginLeft: '12px',
      opacity: 0.7,
    },
  },
  formControlRoot: {
    '&.MuiFormControl-root': {
      minHeight: 70,
      marginTop: '5px',
    },
  },
  tooltip: {
    display: 'flex',
    marginBottom: 1,
  },
  tooltipIcon: {
    marginTop: 1,
  },
};
