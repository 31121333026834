import { requestHandler } from '@northstar/core-ui/utils';
const { assetDomain, productDomain } = requestHandler;

export const postHirePurchaseQuote = ({ importerId, requestBody }) =>
  assetDomain.CALCULATE_PR_HP_QUOTE.call({
    urlParams: { importerId },
    body: requestBody,
  });

export const getInitHPValues = ({ importerId, vendorTermsId }) =>
  productDomain.GET_INIT_VALUES_HP.call({ urlParams: { importerId, vendorTermsId } });
