"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  icon: _ref => {
    let {
      colors
    } = _ref;
    return {
      '& g': {
        fill: colors.custom.contentAlternative.highlight.color
      }
    };
  },
  button: _ref2 => {
    let {
      colors
    } = _ref2;
    return {
      '&:hover': {
        background: colors.alpha(colors.solid.white, 0.1)
      }
    };
  }
};