import { SxProps, Tab, TabProps, Theme } from '@mui/material';

import { handleCustomSx } from '../../../utils';

import { styles } from './MAFTab.styles';
import { propTypes } from './MAFTab.propTypes';

export interface IMAFTabProps extends Omit<TabProps, 'children'> {
  sx?: SxProps<Theme>;
  value: number | string;
}

const MAFTab = ({ sx, ...props }: IMAFTabProps) => (
  <Tab {...props} sx={[styles.mafTabRootStyles, ...handleCustomSx(sx)]} />
);

MAFTab.propTypes = propTypes;

export default MAFTab;
