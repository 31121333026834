"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.patchInsuranceMatrix = exports.getInsuranceNames = exports.getInsuranceMatrix = exports.deleteInsuranceMatrix = exports.createInsuranceMatrix = void 0;
var _requestHandler = _interopRequireDefault(require("../../utils/request-handler"));
const {
  insuranceDomain
} = _requestHandler.default;
const getInsuranceNames = function () {
  let {
    withoutVendorId,
    vendorId
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return insuranceDomain.INSURANCE_GET_NAMES.call({
    urlParams: {
      withoutVendorId,
      vendorId
    }
  });
};
exports.getInsuranceNames = getInsuranceNames;
const createInsuranceMatrix = _ref => {
  let {
    matrixName,
    requestBody
  } = _ref;
  return insuranceDomain.INSURANCE_CREATE_NEW.call({
    urlParams: {
      matrixName
    },
    body: requestBody
  });
};
exports.createInsuranceMatrix = createInsuranceMatrix;
const patchInsuranceMatrix = _ref2 => {
  let {
    matrixName,
    requestBody
  } = _ref2;
  return insuranceDomain.INSURANCE_PATCH_ONE.call({
    urlParams: {
      matrixName
    },
    body: requestBody
  });
};
exports.patchInsuranceMatrix = patchInsuranceMatrix;
const deleteInsuranceMatrix = _ref3 => {
  let {
    matrixName
  } = _ref3;
  return insuranceDomain.INSURANCE_DELETE_ONE.call({
    urlParams: {
      matrixName
    }
  });
};
exports.deleteInsuranceMatrix = deleteInsuranceMatrix;
const getInsuranceMatrix = _ref4 => {
  let {
    matrixName
  } = _ref4;
  return insuranceDomain.INSURANCE_GET_ONE.call({
    urlParams: {
      matrixName
    }
  });
};
exports.getInsuranceMatrix = getInsuranceMatrix;