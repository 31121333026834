import { useState } from 'react';

import { Oauth2PopupContext } from './Oauth2PopupProvider/Oauth2PopupProviderContext';
import { propTypes } from './MAFOauth2Provider.propTypes';

const MAFOauth2Provider: React.FC = ({ children }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <Oauth2PopupContext.Provider value={{ isPopupOpen, setIsPopupOpen }}>
      {children}
    </Oauth2PopupContext.Provider>
  );
};

MAFOauth2Provider.propTypes = propTypes;

export default MAFOauth2Provider;
