"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getDifferenceBetweenDatesInMonths = function (_a) {
    var startDate = _a.startDate, endDate = _a.endDate;
    var startDateObj = new Date(startDate);
    var endDateObj = new Date(endDate);
    if (startDateObj > endDateObj)
        return 0;
    var diffDay = endDateObj.getDate() - startDateObj.getDate();
    var diffMonths = (endDateObj.getFullYear() * 12 + endDateObj.getMonth()) - (startDateObj.getFullYear() * 12 + startDateObj.getMonth());
    return diffMonths + (diffDay > 0 ? 1 : 0);
};
exports.default = {
    getDifferenceBetweenDatesInMonths: getDifferenceBetweenDatesInMonths,
};
