"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: {
    padding: '10px 10px 0 30px',
    alignItems: 'center'
  },
  details: _ref => {
    let {
      typography: {
        main
      },
      fontSizes: {
        extraSmall
      },
      colors,
      breakpoints
    } = _ref;
    return {
      fontFamily: main,
      fontSize: extraSmall,
      color: colors.custom.fontPrimary,
      [breakpoints.only('xs')]: {
        width: 230
      }
    };
  },
  btn: _ref2 => {
    let {
      colors
    } = _ref2;
    return {
      '&.MuiButton-root': {
        color: colors.custom.fontPrimary
      }
    };
  },
  icon: {
    marginLeft: 16
  }
};