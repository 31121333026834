"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  label: _ref => {
    let {
      fontSizes: {
        extraSmall
      }
    } = _ref;
    return {
      '& .MuiFormControlLabel-label': {
        fontSize: extraSmall
      }
    };
  },
  root: {
    '&.MuiFormControlLabel-root': {
      marginBottom: 1
    }
  }
};