import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  root: {
    '&.MuiTabs-root': {
      width: '100%',
    },
  },
  tabsStyles: ({ colors, fontSizes, typography }) => ({
    '& .MuiTab-root': {
      '&.Mui-selected': {
        fontWeight: 600,
        color: colors.custom.tabs.maf.selectedColor,
      },
      fontFamily: typography.main,
      fontSize: fontSizes.small,
      color: colors.custom.tabs.maf.color,
    },
  }),
  dividerStyles: ({ colors }) => ({
    background: colors.custom.tabs.maf.divider,
    marginTop: '-1px',
    width: '100%',
  }),
  indicatorStyles: ({ colors }) => ({
    '&.MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      background: 'transparent',
      '& > span': {
        maxWidth: '20%',
        width: '100%',
        height: 3,
        background: colors.custom.tabs.maf.selectedColor,
      },
    },
  }),
};
