"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleAssetsAll = exports.toggleAssetSelection = exports.restoreAssetsResponse = exports.restoreAssetsRequest = exports.initialAssetListState = exports.hardDeleteAssetsResponse = exports.hardDeleteAssetsRequest = exports.hardDeleteAssets = exports.getAssetsResponse = exports.getAssetsRequest = exports.getAssetsDynamicRequest = exports.getAssetsCommercialResponse = exports.getAssetsCommercialRequest = exports.deleteAssetsResponse = exports.deleteAssetsRequest = exports.default = exports.clearSelectedAssets = exports.changeSelectedVehicleType = exports.changeSelectedProductType = void 0;
var _core = require("@northstar/core");
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
/**
 * Actions
 */
const getAssetsRequest = exports.getAssetsRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_ASSETS_REQUEST');
const getAssetsResponse = exports.getAssetsResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_ASSETS_RESPONSE');
const getAssetsCommercialRequest = exports.getAssetsCommercialRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_ASSETS_COMMERCIAL_REQUEST');
const getAssetsCommercialResponse = exports.getAssetsCommercialResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_ASSETS_COMMERCIAL_RESPONSE');
const getAssetsDynamicRequest = exports.getAssetsDynamicRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_ASSETS_DYNAMIC_REQUEST');
const toggleAssetSelection = exports.toggleAssetSelection = (0, _reduxActions.createAction)('DASHBOARD_TOGGLE_ASSET_SELECTION');
const toggleAssetsAll = exports.toggleAssetsAll = (0, _reduxActions.createAction)('DASHBOARD_TOGGLE_ASSETS_SELECTION');
const hardDeleteAssets = exports.hardDeleteAssets = (0, _reduxActions.createAction)('DASHBOARD_HARD_DELETE_ASSETS');
const deleteAssetsRequest = exports.deleteAssetsRequest = (0, _reduxActions.createAction)('DASHBOARD_DELETE_ASSETS_REQUEST');
const deleteAssetsResponse = exports.deleteAssetsResponse = (0, _reduxActions.createAction)('DASHBOARD_DELETE_ASSETS_RESPONSE');
const restoreAssetsRequest = exports.restoreAssetsRequest = (0, _reduxActions.createAction)('DASHBOARD_RESTORE_ASSETS_REQUEST');
const restoreAssetsResponse = exports.restoreAssetsResponse = (0, _reduxActions.createAction)('DASHBOARD_RESTORE_ASSETS_RESPONSE');
const hardDeleteAssetsRequest = exports.hardDeleteAssetsRequest = (0, _reduxActions.createAction)('DASHBOARD_HARD_DELETE_ASSETS_REQUEST');
const hardDeleteAssetsResponse = exports.hardDeleteAssetsResponse = (0, _reduxActions.createAction)('DASHBOARD_HARD_DELETE_ASSETS_RESPONSE');
const clearSelectedAssets = exports.clearSelectedAssets = (0, _reduxActions.createAction)('DASHBOARD_CLEAR_SELECTED_ASSETS');
const changeSelectedProductType = exports.changeSelectedProductType = (0, _reduxActions.createAction)('DASHBOARD_CHANGE_SELECTED_PRODUCT_TYPE');
const changeSelectedVehicleType = exports.changeSelectedVehicleType = (0, _reduxActions.createAction)('DASHBOARD_CHANGE_SELECTED_VEHICLE_TYPE');

/**
 * Initial state
 */
const initialAssetListState = exports.initialAssetListState = (0, _immutable.fromJS)({
  assets: [],
  gettingAssets: false,
  selectedAssets: [],
  allAssetsSelected: false,
  delettingAssets: false,
  restoringAssets: false,
  selectedProductType: _core.PROCESS_VARIANCES.PRIVATE_OL,
  selectedVehicleType: _core.VEHICLE_TYPE_CAR
});

/**
 * Actions handlers
 */
var _default = exports.default = (0, _reduxActions.handleActions)({
  // Cases count
  [(0, _reduxActions.combineActions)(getAssetsRequest, getAssetsCommercialRequest)](state) {
    return state.set('gettingAssets', true);
  },
  [(0, _reduxActions.combineActions)(getAssetsResponse, getAssetsCommercialResponse)](state, _ref) {
    let {
      payload,
      error
    } = _ref;
    return !error ? state.set('assets', (0, _immutable.fromJS)(payload)).set('gettingAssets', false) : state.set('gettingAssets', false).set('assets', (0, _immutable.fromJS)([]));
  },
  [toggleAssetSelection](state, _ref2) {
    let {
      payload
    } = _ref2;
    const {
      id,
      selected
    } = payload;
    const selectedAssets = state.get('selectedAssets').toJS();
    if (selected) {
      return state.set('selectedAssets', (0, _immutable.fromJS)(selectedAssets.filter(x => x !== id))).set('allAssetsSelected', false);
    }
    const assets = state.get('assets').toJS();
    const newSelectedAssets = [...selectedAssets, id];
    return state.set('selectedAssets', (0, _immutable.fromJS)(newSelectedAssets)).set('allAssetsSelected', newSelectedAssets.length === assets.length);
  },
  [toggleAssetsAll](state) {
    const assets = state.get('assets').toJS();
    const allAssetsSelected = state.get('allAssetsSelected');
    if (allAssetsSelected) {
      return state.set('allAssetsSelected', false).set('selectedAssets', (0, _immutable.fromJS)([]));
    }
    const assetsIds = assets.map(asset => asset.id);
    return state.set('allAssetsSelected', true).set('selectedAssets', (0, _immutable.fromJS)(assetsIds));
  },
  [clearSelectedAssets](state) {
    return state.set('selectedAssets', (0, _immutable.fromJS)([])).set('allAssetsSelected', false);
  },
  [restoreAssetsRequest](state) {
    return state.set('restoringAssets', true);
  },
  [(0, _reduxActions.combineActions)(deleteAssetsRequest, hardDeleteAssetsRequest)](state) {
    return state.set('deletingAssets', true);
  },
  [(0, _reduxActions.combineActions)(deleteAssetsResponse, hardDeleteAssetsResponse, restoreAssetsResponse)](state) {
    return state.set('restoringAssets', false).set('deletingAssets', false).set('allAssetsSelected', false).set('selectedAssets', (0, _immutable.fromJS)([]));
  },
  [changeSelectedProductType](state, _ref3) {
    let {
      payload: {
        productType
      }
    } = _ref3;
    return state.set('selectedProductType', productType).set('selectedVehicleType', _core.VEHICLE_TYPE_CAR).set('selectedAssets', (0, _immutable.fromJS)([]));
  },
  [changeSelectedVehicleType](state, _ref4) {
    let {
      payload: {
        vehicleType
      }
    } = _ref4;
    return state.set('selectedVehicleType', vehicleType);
  }
}, initialAssetListState);