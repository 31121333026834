import { ReactChild, FC } from 'react';
import { useTheme, useMediaQuery, Box, SxProps, Theme } from '@mui/material';

import { handleCustomSx } from '../../utils';
import MAFButton, { IMAFButtonProps } from '../MAFButton/MAFButton';

import MAFTooltipWrapper from './MAFTooltipWrapper/MAFTooltipWrapper';
import { styles } from './MAFDoubleButtons.styles';
import { propTypes } from './MAFDoubleButtons.propTypes';

export interface IMAFButtonExtended extends IMAFButtonProps {
  tooltipTitle?: ReactChild;
  'ns-target'?: string;
}

type TMAFDoubleButtonsVariant = 'text' | 'outlined' | 'contained';

type TMAFDoubleButtonsDefaultBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface IMAFDoubleButtonsProps {
  variant?: TMAFDoubleButtonsVariant;
  isVertical?: boolean;
  isFullWidth?: boolean;
  defaultBreakpoint?: TMAFDoubleButtonsDefaultBreakpoint;
  sx?: SxProps<Theme>;
  primaryButtonText: ReactChild;
  primaryButtonProps?: IMAFButtonExtended;
  secondaryButtonText: ReactChild;
  secondaryButtonProps?: IMAFButtonExtended;
  isSticky?: boolean;
}

const MAFDoubleButtons: FC<IMAFDoubleButtonsProps> = ({
  primaryButtonProps = {},
  primaryButtonText,
  secondaryButtonProps = {},
  secondaryButtonText,
  isVertical = false,
  isFullWidth = false,
  variant,
  sx,
  defaultBreakpoint = 'md',
  isSticky = false,
}) => {
  const theme = useTheme();
  const isAdjustedVertical = isVertical || useMediaQuery(theme.breakpoints.down(defaultBreakpoint));
  const { tooltipTitle: primaryButtonTooltip, ...primaryProps } = primaryButtonProps;
  const { tooltipTitle: secondaryButtonTooltip, ...secondaryProps } = secondaryButtonProps;
  return (
    <Box
      sx={[
        isAdjustedVertical && styles.verticalContainer,
        isFullWidth && styles.fullWidth,
        isSticky && styles.sticky,
        styles.container,
        ...handleCustomSx(sx),
      ]}
    >
      <MAFTooltipWrapper title={secondaryButtonTooltip}>
        <MAFButton
          variant={variant}
          isRounded
          color="secondary"
          sx={[
            isAdjustedVertical && styles.buttonFullWidth,
            !isAdjustedVertical && styles.buttonLeft,
          ]}
          ns-target="secondary-button"
          {...secondaryProps}
        >
          {secondaryButtonText}
        </MAFButton>
      </MAFTooltipWrapper>
      <MAFTooltipWrapper title={primaryButtonTooltip}>
        <MAFButton
          variant={variant}
          isRounded
          color="primaryPR"
          type="submit"
          sx={[styles.buttonRight, isAdjustedVertical && styles.buttonFullWidth]}
          ns-target="primary-button"
          {...primaryProps}
        >
          {primaryButtonText}
        </MAFButton>
      </MAFTooltipWrapper>
    </Box>
  );
};

MAFDoubleButtons.propTypes = propTypes;

export default MAFDoubleButtons;
