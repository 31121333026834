import { fromJS } from 'immutable';
import { handleActions, createAction } from 'redux-actions';

export const updateKycRequest = createAction('KYC_UPDATE_KYC_REQUEST');
export const updateKycResponse = createAction('KYC_UPDATE_KYC_RESPONSE');
export const createKycLinkRequest = createAction('KYC_CREATE_KYC_LINK_REQUEST');
export const createKycLinkResponse = createAction('KYC_CREATE_KYC_LINK_RESPONSE');
export const getKycStatusRequest = createAction('KYC_GET_KYC_STATUS_REQUEST');
export const getKycStatusResponse = createAction('KYC_GET_KYC_STATUS_RESPONSE');
export const getKycExpirationRequest = createAction('KYC_GET_KYC_EXPIRATION_REQUEST');
export const getKycExpirationResponse = createAction('KYC_GET_KYC_EXPIRATION_RESPONSE');
export const validateKycInformationRequest = createAction('KYC_VALIDATE_KYC_INFORMATION_REQUEST');

export const initialState = fromJS({
  successfullKyc: false,
  isLoading: false,
  kycStatus: null,
  isKycExpired: false,
  isKycFormSent: false,
  kycLink: null,
  isCreatingKycLink: false,
});

export default handleActions(
  {
    [getKycStatusResponse](state, { payload, error }) {
      return !error ? state.set('kycStatus', fromJS(payload)) : state;
    },
    [getKycExpirationResponse](state, { payload, error }) {
      return !error ? state.set('isKycExpired', fromJS(payload)) : state;
    },
    [updateKycRequest](state) {
      return state.set('successfullKyc', false).set('isLoading', true);
    },
    [updateKycResponse](state, { error }) {
      return !error
        ? state.set('successfullKyc', true).set('isLoading', false)
        : state.set('isLoading', false);
    },
    [createKycLinkRequest](state) {
      return state.set('isCreatingKycLink', true);
    },
    [createKycLinkResponse](state, { payload: { sendEmail } }) {
      return state.set('isCreatingKycLink', false).set('isKycFormSent', sendEmail);
    },
  },
  initialState,
);
