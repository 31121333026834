"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  bar: _ref => {
    let {
      colors
    } = _ref;
    return {
      height: 2,
      margin: '10px 0',
      width: 20,
      background: colors.alpha(colors.solid.black, 0.35)
    };
  },
  text: _ref2 => {
    let {
      colors,
      fontSizes: {
        extraSmall
      },
      typography: {
        main
      }
    } = _ref2;
    return {
      color: colors.alpha(colors.custom.fontPrimary, 0.6),
      fontFamily: main,
      fontSize: extraSmall,
      letterSpacing: 0.68,
      lineHeight: '19px',
      marginLeft: 2
    };
  },
  content: {
    display: 'flex',
    padding: '5px 0',
    position: 'relative',
    fontWeight: 600
  },
  indicator: {
    marginTop: '5px',
    position: 'absolute',
    right: 0
  },
  indicatorIcon: {
    width: 14,
    height: 14
  },
  active: _ref3 => {
    let {
      colors
    } = _ref3;
    return {
      color: colors.custom.fontPrimary
    };
  }
};