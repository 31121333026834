const CalendarIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-action/Calendar/Darkblue">
        <g id="Icon" transform="translate(2.000000, 2.000000)">
          <path
            d="M1,18.9702 L19.012,18.9702 L19.012,6.9922 L1,6.9922 L1,18.9702 Z M19.512,2.0082 L16.006,2.0082 L16.006,1.0002 C16.006,0.4472 15.558,0.0002 15.006,0.0002 L13.993,0.0002 C13.441,0.0002 12.993,0.4472 12.993,1.0002 L12.993,2.0082 L7.006,2.0082 L7.006,1.0002 C7.006,0.4472 6.558,0.0002 6.006,0.0002 L4.993,0.0002 C4.441,0.0002 3.993,0.4472 3.993,1.0002 L3.993,2.0082 L0.5,2.0082 C0.224,2.0082 0,2.2322 0,2.5082 L0,19.4702 C0,19.7462 0.224,19.9702 0.5,19.9702 L19.512,19.9702 C19.788,19.9702 20.012,19.7462 20.012,19.4702 L20.012,2.5082 C20.012,2.2322 19.788,2.0082 19.512,2.0082 L19.512,2.0082 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default CalendarIcon;
