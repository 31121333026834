import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  card: ({ colors }) => ({
    marginBottom: 4,
    borderRadius: 0,
    ...colors.custom.cardList.card,
  }),
  header: ({ colors }) => ({
    '&.MuiCardHeader-root': colors.custom.cardList.header,
  }),
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
  },
  child: {
    flex: 1,
  },
  icon: ({ colors }) => ({
    height: 20,
    width: 20,
    '& g': colors.custom.cardList.icon,
  }),
  iconButton: {
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  title: ({ fontSizes, typography }) => ({
    fontSize: fontSizes.small,
    fontFamily: typography.main,
    fontWeight: 600,
    display: 'flex',
  }),
};
