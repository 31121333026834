import { useEffect, useState, FC, ReactChild, ElementType } from 'react';

import { IMAFStepperStep } from '../MAFStepperDesktop/MAFStepperDesktop';

import Overlay from './Overlay/Overlay';
import StepsContainer from './StepsContainer/StepsContainer';
import Step from './Step/Step';
import { propTypes } from './MAFStepperMobile.propTypes';

export interface IMAFStepperMobileProps {
  isOpen: boolean;
  lastAvailableStep: number;
  steps: IMAFStepperStep[];
  isSide?: boolean;
  onStepClick: (step: number) => void;
  closeButtonText: ReactChild;
  onClose: () => void;
  isWithAppBar?: boolean;
  topOffset: number;
  customIcon?: ElementType;
}

const MAFStepperMobile: FC<IMAFStepperMobileProps> = ({
  isOpen,
  lastAvailableStep,
  steps,
  isSide = true,
  onStepClick,
  closeButtonText,
  onClose,
  isWithAppBar,
  topOffset,
  customIcon,
}) => {
  const [isInnerOpen, setIsInnerOpen] = useState(false);
  const lastStep = steps.length - 1;

  useEffect(() => {
    if (!isOpen && isInnerOpen) {
      setTimeout(() => setIsInnerOpen(false), 250);
    } else if (isOpen) {
      setIsInnerOpen(true);
    }
  }, [isOpen, isInnerOpen]);

  return isInnerOpen ? (
    <>
      <Overlay onClose={onClose} />
      <StepsContainer
        onClose={onClose}
        isSide={isSide}
        isOpen={isOpen}
        closeButtonText={closeButtonText}
        isWithAppBar={isWithAppBar}
        topOffset={topOffset}
      >
        {steps.map(({ title, isDisabled }, step) => (
          <Step
            key={step}
            step={step}
            title={title}
            lastAvailableStep={lastAvailableStep}
            lastStep={lastStep}
            onStepClick={onStepClick}
            onClose={onClose}
            isDisabled={isDisabled}
            customIcon={customIcon}
          />
        ))}
      </StepsContainer>
    </>
  ) : null;
};

MAFStepperMobile.propTypes = propTypes;

export default MAFStepperMobile;
