import { Table, TableBody, TablePagination, Box } from '@mui/material';
import { handleCustomSx, useScreenSize } from 'src/utils';

import { IMAFTableData, IMAFTableProps } from '../../MAFTable.types';
import MAFNoResults from '../MAFNoResults/MAFNoResults';
import MAFTableHeader from '../MAFTableHeader';

import { propTypes } from './MAFTableWrapper.propTypes';
import { styles } from './MAFTableWrapper.styles';

interface IMAFTableWrapperProps<T extends IMAFTableData>
  extends Omit<IMAFTableProps<T>, 'data' | 'onItemClick' | 'tableClasses' | 'selectCase'> {
  hasData: boolean;
  hasCheckbox?: boolean;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
    page: number,
  ) => void;
  onRowsPerPageChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  children: React.ReactChild;
}

function MAFTableWrapper<T extends IMAFTableData>({
  fields,
  isWithPagination,
  onOrder,
  orderBy,
  direction,
  isFetching,
  isWithStickyHeader,
  headerClass,
  shouldAdjustHeaderPosition,
  isUplift,
  tableContentRef,
  nsTarget,
  isWithMobile = false,
  noResultsMessage,
  children,
  hasCheckbox = false,
  hasData,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  tableContainerSx,
}: IMAFTableWrapperProps<T>) {
  const { isMobile } = useScreenSize();

  const fieldsWithCheckbox = [{ name: 'checkbox', label: '', unsortable: true }, ...fields];

  const labels = hasCheckbox ? fieldsWithCheckbox : fields;

  return isMobile && isWithMobile ? (
    <Box sx={[styles.tableContainer, ...handleCustomSx(tableContainerSx)]}>
      <Table ref={tableContentRef} sx={styles.tableRoot}>
        <TableBody>{children}</TableBody>
      </Table>
      <MAFNoResults
        hasData={hasData}
        isFetching={isFetching}
        isUplift
        noResultsMessage={noResultsMessage}
      />
    </Box>
  ) : (
    <Box sx={[styles.tableContainer, ...handleCustomSx(tableContainerSx)]} ns-target={nsTarget}>
      <Table
        stickyHeader={isWithStickyHeader}
        ref={tableContentRef}
        sx={styles.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <MAFTableHeader
          onOrder={onOrder}
          orderBy={orderBy}
          direction={direction}
          labels={labels}
          sx={headerClass}
          isUplift={isUplift}
          shouldAdjustHeaderPosition={shouldAdjustHeaderPosition}
        />
        <TableBody>{children}</TableBody>
      </Table>
      <MAFNoResults
        hasData={hasData}
        isFetching={isFetching}
        isUplift={isUplift}
        noResultsMessage={noResultsMessage}
      />
      {isWithPagination && (
        <TablePagination
          sx={styles.pagination}
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={25}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </Box>
  );
}

MAFTableWrapper.propTypes = propTypes;

export default MAFTableWrapper;
