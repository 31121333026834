import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  additionalComponent: PropTypes.node,
  children: PropTypes.node,
  drawerMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      custom: PropTypes.node,
      linkProps: PropTypes.shape({
        arrowStyles: PropTypes.oneOfType([
          PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
          ),
          PropTypes.func,
          PropTypes.object,
        ]),
        isBold: PropTypes.bool,
        isLinkActive: PropTypes.bool,
        isWithArrow: PropTypes.bool,
        levels: PropTypes.arrayOf(
          PropTypes.shape({
            isHref: PropTypes.bool,
            label: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
              .isRequired,
            onClick: PropTypes.func,
          }),
        ).isRequired,
        sx: PropTypes.oneOfType([
          PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
          ),
          PropTypes.func,
          PropTypes.object,
        ]),
        typographyProps: PropTypes.shape({
          children: PropTypes.node,
          color: PropTypes.oneOf(['primary']),
          fontFamily: PropTypes.oneOf(['main', 'title']),
          fontSize: PropTypes.oneOf([
            'extraLarge',
            'extraMedium',
            'extraSmall',
            'giant',
            'huge',
            'large',
            'medium',
            'small',
            'tiny',
          ]),
          fontStyle: PropTypes.oneOf(['italic']),
          fontWeight: PropTypes.oneOf(['600', 'bold', 'normal', 400, 600]),
          isWithContainer: PropTypes.bool,
          isWithFontSmoothing: PropTypes.bool,
          style: PropTypes.object,
          sx: PropTypes.oneOfType([
            PropTypes.arrayOf(
              PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
            ),
            PropTypes.func,
            PropTypes.object,
          ]),
          textAlign: PropTypes.oneOf(['center', 'justify', 'left', 'right']),
          variant: PropTypes.oneOf([
            'body1',
            'body2',
            'body3',
            'h1-italic',
            'h1-regular',
            'h1-semi-bold',
            'h2-italic',
            'h2-regular',
            'h2-semi-bold',
            'help1',
            'help2',
            'label1',
            'label2',
            'label3',
            'sub1-italic',
            'sub1-regular',
            'sub1-semi-bold',
            'sub2-italic',
            'sub2-regular',
            'sub2-semi-bold',
          ]),
        }),
      }),
      listItemProps: PropTypes.object,
      sectionTitle: PropTypes.node,
    }),
  ),
  drawerTopTabsProps: PropTypes.object,
  isDrawerOpen: PropTypes.bool.isRequired,
  menuIconLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  onDrawerClose: PropTypes.func,
} as any;
