const Alert: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-action/Alert-something-went-wrong/Darkblue">
        <g id="Icon" transform="translate(2.000000, 2.000000)">
          <path
            d="M9.09,16.2173 L11.132,16.2173 L11.132,14.1573 L9.09,14.1573 L9.09,16.2173 Z M9,3.9998 L9.162,12.9948 L10.935,12.9948 L11.132,3.9998 L9,3.9998 Z M10,19 C5.037,19 1,14.963 1,10 C1,5.037 5.037,1 10,1 C14.963,1 19,5.037 19,10 C19,14.963 14.963,19 10,19 M10,0 C4.477,0 0,4.477 0,10 C0,15.523 4.477,20 10,20 C15.523,20 20,15.523 20,10 C20,4.477 15.523,0 10,0"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Alert;
