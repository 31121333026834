const WaitingForDecision: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="07-Case-overview" transform="translate(-1207.000000, -490.000000)" stroke="#fff">
        <g id="case-waiting-for-decision" transform="translate(1208.000000, 491.000000)">
          <circle id="Oval-5-Copy-2" cx="9" cy="9" r="9.5"></circle>
          <path d="M9.5,2.5 L9.5,9.5" id="Line-4" strokeWidth="1.5" strokeLinecap="round"></path>
          <path
            d="M12.5,11.5 L9.5,9.5"
            id="Line-4-Copy"
            strokeWidth="1.5"
            strokeLinecap="round"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default WaitingForDecision;
