import { ISxStyles } from '../../../../theming/theme.type';

export const styles: ISxStyles = {
  buttonSecondary: ({ breakpoints }) => ({
    marginRight: 1,
    [breakpoints.down('md')]: {
      display: 'block',
      marginTop: 1,
    },
  }),
  doubleButtonContainer: {
    justifyContent: 'left',
  },
};
