const Close: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
      <g id="Icons-navigation/Close/White">
        <g id="Icon" transform="translate(3.000000, 3.000000)">
          <path
            d="M10.1924,8.7781 L17.2634,1.7071 C17.6544,1.3161 17.6544,0.6841 17.2634,0.2931 C16.8734,-0.0979 16.2404,-0.0979 15.8494,0.2931 L8.7784,7.3641 L1.7074,0.2931 C1.3164,-0.0979 0.6834,-0.0979 0.2934,0.2931 C-0.0976,0.6841 -0.0976,1.3161 0.2934,1.7071 L7.3644,8.7781 L0.2934,15.8491 C-0.0976,16.2401 -0.0976,16.8721 0.2934,17.2631 C0.4884,17.4581 0.7444,17.5561 1.0004,17.5561 C1.2554,17.5561 1.5114,17.4581 1.7074,17.2631 L8.7784,10.1921 L15.8494,17.2631 C16.0454,17.4581 16.3004,17.5561 16.5564,17.5561 C16.8124,17.5561 17.0684,17.4581 17.2634,17.2631 C17.6544,16.8721 17.6544,16.2401 17.2634,15.8491 L10.1924,8.7781 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Close;
