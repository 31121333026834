import { FC, ReactChild } from 'react';
import { Box } from '@mui/material';

import { IDeprecatedMAFTableData } from '../../DeprecatedMAFTable.types';

import { styles } from './DeprecatedMAFNoResults.styles';
import { propTypes } from './DeprecatedMAFNoResults.propTypes';

interface IMAFNoResultsProps {
  data: IDeprecatedMAFTableData[];
  isFetching: boolean;
  noResultsMessage?: ReactChild;
}

const DeprecatedMAFNoResults: FC<IMAFNoResultsProps> = ({ data, isFetching, noResultsMessage }) =>
  !data.length && !isFetching ? <Box sx={styles.noResultsText}>{noResultsMessage}</Box> : null;

DeprecatedMAFNoResults.propTypes = propTypes;

export default DeprecatedMAFNoResults;
