"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isInTheMiddle = void 0;
const isInTheMiddle = function (DOMElement) {
  let offet = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  const DOMRect = DOMElement.getBoundingClientRect();
  const absoluteElementTop = DOMRect.top + window.pageYOffset;
  const absoluteElementBottom = DOMRect.bottom + window.pageYOffset;
  const absoluteMiddle = window.pageYOffset + (window.innerHeight / 2 - offet);
  return absoluteMiddle > absoluteElementTop && absoluteMiddle < absoluteElementBottom;
};
exports.isInTheMiddle = isInTheMiddle;