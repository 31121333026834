"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PUBLIC_BACKGROUNG_IMAGE_MOBILE_URL = exports.PUBLIC_BACKGROUND_IMAGE_URL = exports.PUBLIC_BACKGROUND_IMAGE_BLUR_URL = exports.KYC_BACKGROUND_IMAGE_768_URL = exports.KYC_BACKGROUND_IMAGE_320_URL = exports.DEFAULT_ISO_FORMAT = exports.DATE_REQUEST_FORMAT = void 0;
const DATE_REQUEST_FORMAT = exports.DATE_REQUEST_FORMAT = 'YYYY-MM-DD';
const DEFAULT_ISO_FORMAT = exports.DEFAULT_ISO_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';
const PUBLIC_BACKGROUND_IMAGE_URL = exports.PUBLIC_BACKGROUND_IMAGE_URL = 'bg.jpg';
const PUBLIC_BACKGROUNG_IMAGE_MOBILE_URL = exports.PUBLIC_BACKGROUNG_IMAGE_MOBILE_URL = 'bg_mobile.jpg';
const PUBLIC_BACKGROUND_IMAGE_BLUR_URL = exports.PUBLIC_BACKGROUND_IMAGE_BLUR_URL = 'bg_blur.jpg';
const KYC_BACKGROUND_IMAGE_320_URL = exports.KYC_BACKGROUND_IMAGE_320_URL = 'kyc_bg320.png';
const KYC_BACKGROUND_IMAGE_768_URL = exports.KYC_BACKGROUND_IMAGE_768_URL = 'kyc_bg768.png';