import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
  noResultsMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
} as any;
