import { Box, SxProps, Theme } from '@mui/material';
import { ArrowNext, MAFButton, MAFTypography } from '@asfi/maf';
import { FormattedMessage } from 'react-intl';
import { ReactChild } from 'react';

import AssetHeader from '../AssetHeader/AssetHeader';

import QuoteDetails, { IQuoteDetailsProps } from './QuoteDetails/QuoteDetails';
import { styles } from './externalQuote.styles';

interface IOwner {
  vendor: {
    name: string;
    phoneNumber: string;
  };
}

export interface IExternalQuoteProps {
  sx: SxProps<Theme>;
  title: ReactChild;
  subTitle: ReactChild;
  image: ReactChild;
  quoteDetails: IQuoteDetailsProps['details'];
  owner: IOwner;
  handleNext: () => void;
  handleDoubleNext: () => void;
}

const ExternalQuote: React.FC<IExternalQuoteProps> = ({
  title,
  subTitle,
  sx,
  image,
  quoteDetails,
  owner,
  handleNext,
  handleDoubleNext,
}) => {
  const footer = (
    <MAFButton isLink onClick={handleDoubleNext}>
      <MAFTypography variant="sub2-regular" sx={styles.link}>
        <FormattedMessage id="quote.show_agreement_details" />
        <Box component={ArrowNext} sx={styles.icon} />
      </MAFTypography>
    </MAFButton>
  );

  return (
    <>
      <AssetHeader sx={sx} title={title} subTitle={subTitle} image={image} />
      <MAFButton isLink onClick={handleNext} sx={styles.button}>
        <MAFTypography variant="sub2-regular" sx={styles.link}>
          <FormattedMessage id="quote.show_car_specifications" />
          <Box component={ArrowNext} sx={styles.icon} />
        </MAFTypography>
      </MAFButton>
      <QuoteDetails details={quoteDetails} footer={footer} />
      <Box sx={styles.vendorBox}>
        <MAFTypography variant="sub1-regular" fontStyle="italic" sx={styles.vendorBoxTitle}>
          <FormattedMessage id="quote.chat_with_vendor_question" />
        </MAFTypography>
        <Box sx={styles.vendorContacts}>
          <MAFTypography variant="sub1-regular" isWithContainer sx={styles.vendorContactsTitle}>
            {owner.vendor.name}
          </MAFTypography>
          <Box component="a" sx={styles.phone} href={`tel:${owner.vendor.phoneNumber}`}>
            {owner.vendor.phoneNumber}
          </Box>
        </Box>
      </Box>
      <Box sx={styles.pageBreak} />
    </>
  );
};

export default ExternalQuote;
