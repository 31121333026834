import { constants, reactUtils } from '@northstar/core-ui/utils';
import dayjs from 'dayjs';

import {
  HP_FORM_CAR_PRICE,
  HP_FORM_DOWN_PAYMENT,
  HP_FORM_TRADE_IN_CAR,
  HP_FORM_TRADE_IN_REGISTRATION_NUMBER,
  HP_FORM_TRADE_IN_VALUE,
  HP_FORM_TRADE_IN_DEBT,
  HP_FORM_DURATION,
  HP_FORM_INTEREST_RATE,
  HP_FORM_FUTURE_PERCENTAGE,
  HP_FORM_VENDOR_TERMS_ID,
  HP_FORM_CAR_REGISTRATION_DATE,
  HP_FORM_VAT_TOGGLE,
  HP_FORM_CAR_PRICE_MAPPED_FIELD,
  HP_FORM_DOWN_PAYMENT_MAPPED_FIELD,
  HP_FORM_DURATION_MAPPED_FIELD,
  HP_FORM_TRADE_IN_VALUE_MAPPED_FIELD,
  HP_FORM_TRADE_IN_DEBT_MAPPED_FIELD,
  HP_FORM_INTEREST_RATE_MAPPED_FIELD,
} from './hirePurchaseConstants';

const mapFormValuesToRequest = ({
  [HP_FORM_CAR_PRICE]: carprice,
  [HP_FORM_DOWN_PAYMENT]: downpayment,
  [HP_FORM_DURATION]: duration,
  [HP_FORM_INTEREST_RATE]: interestRate,
  [HP_FORM_FUTURE_PERCENTAGE]: residualvalue,
  [HP_FORM_VENDOR_TERMS_ID]: vendorTermsId,
  [HP_FORM_TRADE_IN_DEBT]: tradeinCarDebt,
  [HP_FORM_TRADE_IN_VALUE]: tradeinCarValue,
  [HP_FORM_CAR_REGISTRATION_DATE]: registrationDate,
  [HP_FORM_VAT_TOGGLE]: vatEnabled,
}) => {
  const map = {
    duration_months: duration,
    bullet_percentage: residualvalue,
    vendor_terms_id: vendorTermsId,
    trade_in_car_value_in_vat: tradeinCarValue || 0,
    trade_in_car_remaining_debt_in_vat: tradeinCarDebt || 0,
    cash_payment_in_vat: downpayment || 0,
    total_yearly_interest_rate: interestRate,
    // leaving this as a reference: https://jira.danskebank.com/browse/FDAFCFP-1404
    include_vat_for_asset: vatEnabled !== 'false',
    car_price_in_vat: carprice,
  };

  if (dayjs.isDayjs(registrationDate)) {
    return {
      ...map,
      registration_date: registrationDate.isValid()
        ? registrationDate.format(constants.DATE_REQUEST_FORMAT)
        : null,
    };
  }

  return {
    ...map,
    registration_date: registrationDate
      ? reactUtils.parseDefaultDateToDayjs(registrationDate).format(constants.DATE_REQUEST_FORMAT)
      : null,
  };
};

const mapQuoteToForm = ({
  selectedCarPriceInVat,
  registrationDate,
  totalYearlyInterestRate,
  leasePeriod,
  tradeInCarRegistrationNumber,
  tradeInCarValueInVat,
  tradeInCarRemainingDebtInVat,
  cashPaymentInVat,
  bulletPercentage,
  vendorTermsId,
  includeVatForAsset,
}) => ({
  [HP_FORM_CAR_PRICE]: selectedCarPriceInVat,
  [HP_FORM_CAR_REGISTRATION_DATE]: registrationDate !== null ? dayjs(registrationDate) : null,
  [HP_FORM_INTEREST_RATE]: totalYearlyInterestRate,
  [HP_FORM_DURATION]: leasePeriod,
  [HP_FORM_TRADE_IN_REGISTRATION_NUMBER]: tradeInCarRegistrationNumber,
  [HP_FORM_TRADE_IN_CAR]: tradeInCarValueInVat !== 0,
  [HP_FORM_TRADE_IN_VALUE]: tradeInCarValueInVat,
  [HP_FORM_TRADE_IN_DEBT]: tradeInCarRemainingDebtInVat,
  [HP_FORM_DOWN_PAYMENT]: cashPaymentInVat,
  [HP_FORM_FUTURE_PERCENTAGE]: bulletPercentage,
  [HP_FORM_VENDOR_TERMS_ID]: vendorTermsId,
  [HP_FORM_VAT_TOGGLE]: includeVatForAsset.toString(),
});

const mapQuoteToStore = ({
  pmt_in_vat: quote,
  invoice_fee_in_vat: invoiceFee,
  document_fee_in_vat: documentFee,
  down_payment_in_vat: downPaymentTotal,
  down_payment_percentage: downPaymentPercentage,
  future_value_in_vat: futureValue,
  max_bullet_percentage_value: maxResidualPercentage,
  principal_in_vat: principalValue,
  apr_percentage: aprPercentage,
  total_car_price_in_vat: totalCarPrice,
  bullet_percentage: bulletPercentage,
}) => ({
  quote,
  invoiceFee,
  documentFee,
  downPaymentTotal,
  downPaymentPercentage,
  futureValue,
  maxResidualPercentage,
  principalValue,
  aprPercentage,
  totalCarPrice,
  bulletPercentage: Number(bulletPercentage),
});

const errorToUIFieldValues = [
  {
    errorField: HP_FORM_CAR_PRICE_MAPPED_FIELD,
    uiField: HP_FORM_CAR_PRICE,
  },
  {
    errorField: HP_FORM_DOWN_PAYMENT_MAPPED_FIELD,
    uiField: HP_FORM_DOWN_PAYMENT,
  },
  {
    errorField: HP_FORM_DURATION_MAPPED_FIELD,
    uiField: HP_FORM_DURATION,
  },
  {
    errorField: HP_FORM_TRADE_IN_VALUE_MAPPED_FIELD,
    uiField: HP_FORM_TRADE_IN_VALUE,
  },
  {
    errorField: HP_FORM_TRADE_IN_DEBT_MAPPED_FIELD,
    uiField: HP_FORM_TRADE_IN_DEBT,
  },
  {
    errorField: HP_FORM_INTEREST_RATE_MAPPED_FIELD,
    uiField: HP_FORM_INTEREST_RATE,
  },
];

export const responseMappers = {
  mapQuote: mapQuoteToStore,
  errorToUIFieldValues,
};

export const formMappers = {
  mapFormValuesToRequest,
  mapQuoteToForm,
};
