import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  hasData: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isUplift: PropTypes.bool,
  noResultsMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
    .isRequired,
} as any;
