"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  actionRoot: {
    margin: '50px 0',
    flexDirection: 'column'
  },
  actionAction: {
    width: '100%',
    textAlign: 'center'
  }
};