"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectIsHandlingCommercialAsset = exports.selectCurrentVendorId = void 0;
var _core = require("@northstar/core");
var _reselect = require("reselect");
var _vendorDashboardAssetListSelectors = require("./vendorDashboardAssetListSelectors");
const selectVendorDashboardAppState = state => state.vendorDashboard.vendorDashboardApp;
const selectCurrentVendorId = () => (0, _reselect.createSelector)(selectVendorDashboardAppState, state => state.get('currentDashboardVendorId'));
exports.selectCurrentVendorId = selectCurrentVendorId;
const selectIsHandlingCommercialAsset = () => (0, _reselect.createSelector)((0, _vendorDashboardAssetListSelectors.selectSelectedProductType)(), selectedProductType => {
  const queryCommercialParam = new URLSearchParams(document.location.search).get('isCommercial');
  if (_core.ObjectUtils.isNullOrUndefined(queryCommercialParam)) {
    return selectedProductType === _core.PROCESS_VARIANCES.COMMERCIAL_OL;
  }
  return Boolean(Number(queryCommercialParam));
});
exports.selectIsHandlingCommercialAsset = selectIsHandlingCommercialAsset;