import AgreementDetails, { IAgreementDetailsProps } from './AgreementDetails/AgreementDetails';
import ExternalQuote, { IExternalQuoteProps } from './ExternalQuote/ExternalQuote';
import CarSpecifications, { ICarSpecificationsProps } from './CarSpecifications/CarSpecifications';
import { styles } from './quoteStep.styles';

const EXTERNAL_QUOTE_STEP = 0;
const CAR_SPECIFICATIONS_STEP = 1;
const AGREEMENT_DETAILS_STEP = 2;

interface IQuoteStepProps
  extends IAgreementDetailsProps,
    ICarSpecificationsProps,
    Omit<IExternalQuoteProps, 'sx'> {
  currentStep: number;
  shouldRenderQuote: boolean;
}

const QuoteStep: React.FC<IQuoteStepProps> = ({ currentStep, shouldRenderQuote, ...rest }) => {
  const containerStyles = [shouldRenderQuote && styles.print];
  return (
    <>
      {(shouldRenderQuote || currentStep === EXTERNAL_QUOTE_STEP) && (
        <ExternalQuote sx={containerStyles} {...rest} />
      )}
      {(shouldRenderQuote || currentStep === CAR_SPECIFICATIONS_STEP) && (
        <CarSpecifications {...rest} />
      )}
      {(shouldRenderQuote || currentStep === AGREEMENT_DETAILS_STEP) && (
        <AgreementDetails {...rest} />
      )}
    </>
  );
};

export default QuoteStep;
