import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  consentLevels: PropTypes.arrayOf(
    PropTypes.shape({
      isMandatory: PropTypes.bool.isRequired,
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
      level: PropTypes.number.isRequired,
      text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  level: PropTypes.number,
  setLevel: PropTypes.func.isRequired,
} as any;
