const CheckMark: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g
      id="Radiobuttons-and-checkboxes"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Desktop-HD" transform="translate(-453.000000, -489.000000)">
        <g id="Checkbox/16px/Checked" transform="translate(449.000000, 484.000000)">
          <g id="Icons/Completed/20px" transform="translate(4.000000, 5.000000)">
            <polyline fill="none" points="20 6 9 17 4 12"></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CheckMark;
