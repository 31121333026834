"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createQuoteDocumentLink = createQuoteDocumentLink;
exports.default = documents;
exports.getDocument = void 0;
exports.getDocumentAndDownload = getDocumentAndDownload;
exports.sendAffordabilityQuoteDocument = void 0;
exports.sendDocument = sendDocument;
exports.sendQuoteDocument = void 0;
var _effects = require("redux-saga/effects");
var _appReducer = require("../app/appReducer");
var _getCase = require("../getCase");
var _reactUtils = require("../../utils/react-utils");
var _reduxSagaUtils = require("../../utils/redux-saga-utils");
var Api = _interopRequireWildcard(require("./documentsApi"));
var _documentsReducer = require("./documentsReducer");
var _documentsSelectors = require("./documentsSelectors");
var _documentsUtils = require("./documentsUtils");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function createQuoteDocumentLink(_ref) {
  let {
    payload
  } = _ref;
  return function* () {
    const {
      resourceId,
      redirect,
      draftScenarioId
    } = payload;
    try {
      const quoteDocumentLink = yield (0, _effects.call)(Api.createQuoteDocumentLink, {
        resourceId,
        draftScenarioId
      });
      if (typeof redirect === 'function') {
        redirect({
          quoteDocumentLink
        });
      }
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
    }
  }();
}
const sendQuoteDocument = exports.sendQuoteDocument = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: Api.sendQuoteDocument,
  requestMapper: _ref2 => {
    let {
      resourceId,
      draftScenarioId,
      receiverEmails
    } = _ref2;
    return {
      resourceId,
      draftScenarioId,
      requestBody: {
        receiverEmails
      }
    };
  },
  successActions: [_documentsReducer.sendQuoteDocumentResponse],
  snackbarId: 'case.offer_document_sent_success',
  errorActions: [_documentsReducer.sendQuoteDocumentResponse, _appReducer.displayError]
});
const sendAffordabilityQuoteDocument = exports.sendAffordabilityQuoteDocument = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: Api.sendAffordabilityQuoteDocument,
  successActions: [_documentsReducer.sendAffordabilityQuoteDocumentResponse],
  snackbarId: 'case.affordability_document_sent_success',
  errorActions: [_documentsReducer.sendAffordabilityQuoteDocumentResponse, _appReducer.displayError]
});
const getDocument = exports.getDocument = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: Api.getDocument,
  successActions: [_documentsReducer.getDocumentResponse],
  errorActions: [_documentsReducer.getDocumentResponse, _appReducer.displayError]
});
function sendDocument(_ref3) {
  let {
    payload
  } = _ref3;
  return function* () {
    const {
      resourceId,
      documentType,
      signeeType
    } = payload;
    try {
      yield (0, _effects.call)(Api.initiateSigning, {
        resourceId,
        requestBody: {
          document_type: documentType,
          signee_type: signeeType
        }
      });
      yield (0, _effects.put)((0, _documentsReducer.sendDocumentResponse)());
      yield (0, _effects.put)((0, _getCase.getCaseRequest)({
        resourceId
      }));
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)({
        error: 'errors.could_not_send_for_digital_signing',
        action: (0, _getCase.getCaseRequest)({
          resourceId
        })
      }));
      yield (0, _effects.put)((0, _documentsReducer.sendDocumentResponse)(e));
    }
  }();
}
function getDocumentAndDownload() {
  let {
    payload
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function* () {
    try {
      const {
        resourceId,
        documentType,
        documentName,
        appendix
      } = payload || {};
      yield (0, _effects.call)(getDocument, {
        payload: {
          resourceId,
          documentType
        }
      });
      const pdfs = yield (0, _effects.select)((0, _documentsSelectors.selectPdfs)());
      const {
        document = '',
        appendixDocument = ''
      } = (0, _documentsUtils.getDocumentsByType)({
        type: documentType,
        pdfs
      });
      if (document) {
        yield (0, _effects.call)(_reactUtils.downloadBinaryFile, {
          name: documentName,
          binary: document
        });
      }
      if (appendixDocument) {
        yield (0, _effects.call)(_reactUtils.downloadBinaryFile, {
          name: appendix.name,
          binary: appendixDocument
        });
      }
      yield (0, _effects.put)((0, _documentsReducer.getDocumentAndDownloadResponse)());
    } catch (e) {
      yield (0, _effects.put)((0, _documentsReducer.getDocumentAndDownloadResponse)(e));
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
    }
  }();
}
function* documents() {
  yield (0, _effects.all)([(0, _effects.takeLatest)(_documentsReducer.sendDocumentRequest, sendDocument), (0, _effects.takeLatest)(_documentsReducer.createQuoteDocumentLinkRequest, createQuoteDocumentLink), (0, _effects.takeLatest)(_documentsReducer.sendAffordabilityQuoteDocumentRequest, sendAffordabilityQuoteDocument), (0, _effects.takeLatest)(_documentsReducer.sendQuoteDocumentRequest, sendQuoteDocument), (0, _effects.takeLatest)(_documentsReducer.getDocumentRequest, getDocument), (0, _effects.takeLatest)(_documentsReducer.getDocumentAndDownloadRequest, getDocumentAndDownload)]);
}