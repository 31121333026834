import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  tooltip: ({ colors, fontSizes: { extraSmall } }) => ({
    fontSize: extraSmall,
    padding: 2,
    position: 'relative',
    borderRadius: '2px',
    ...colors.custom.tooltip,
    boxShadow: `0px 2px 10px 0px ${colors.alpha(colors.solid.black, 0.3)}`,
    maxWidth: 208,
  }),
  tooltipError: ({ colors }) => colors.custom.inputFull.error,
  arrow: ({ colors }) => ({
    color: colors.custom.tooltip.background,
  }),
  arrowError: ({ colors }) => ({
    color: colors.custom.inputFull.error.background,
  }),
};
