import { FC, ReactElement, ReactChild } from 'react';
import { SxProps, Theme } from '@mui/material';

import MAFCard from '../MAFCard/MAFCard';
import { CloseOutlined } from '../Icons';

import AddCard from './AddCard/AddCard';
import { propTypes } from './MAFCardList.propTypes';

export interface IMAFCardListComponents {
  component: ReactElement;
  title?: ReactChild;
}

export interface IMAFCardListProps {
  components: IMAFCardListComponents[];
  addLabel: ReactChild;
  onAdd: () => void;
  onDelete: (index: number) => void;
  sx?: SxProps<Theme>;
  isError?: boolean;
  helperText?: ReactChild;
  maxCards?: number;
}

const MAFCardList: FC<IMAFCardListProps> = ({
  components,
  addLabel,
  onAdd,
  onDelete,
  sx,
  isError,
  helperText,
  maxCards,
}) => (
  <>
    {components.map(({ component, title }, index) => (
      <MAFCard key={index} Icon={CloseOutlined} title={title} onClick={() => onDelete(index)}>
        {component}
      </MAFCard>
    ))}

    {components.length !== maxCards && (
      <AddCard title={addLabel} onAdd={onAdd} sx={sx} isError={isError} helperText={helperText} />
    )}
  </>
);

MAFCardList.propTypes = propTypes;

export default MAFCardList;
