import { ObjectUtils } from '@northstar/core';
import { FILE_IMAGE_BASE } from '@asfi/maf';
import { isInvalid } from 'redux-form';
import { createSelector } from 'reselect';

import {
  selectActiveOLterms,
  selectIsCaseCommercialOperationalLease,
  selectIsCasePrivateOperationalLease,
} from '../caseDetail/caseDetailSelectors';
import { selectIsUpdatingPrice, selectQuoteDataOL } from '../quote/quoteSelectors';
import {
  selectCommissionTerms,
  selectMaxDiscountPercentage,
  selectSelectedTerms,
} from '../vendor/vendorTermsSelectors';

import { hasValueChanged } from './asset-utils';
import { selectSelectedEquipment } from './equipmentSelectors';
import { selectFrame } from './frameSelectors';

const selectAssetState = (state) => state.asset.asset;

export const selectAsset = () =>
  createSelector(selectAssetState, (state) => state.get('asset').toJS());

export const selectIsAssetExist = () =>
  createSelector(selectAsset(), (asset) => !ObjectUtils.isNullOrEmpty(asset));

export const selectAssetContactForm = () =>
  createSelector(selectAssetState, (state) => state.get('assetContactForm').toJS());

export const selectAssetDetailsProps = () =>
  createSelector(selectAssetState, selectAsset(), selectFrame(), (state, asset, frame) => ({
    loading: state.get('gettingAsset'),
    id: asset.id,
    popName: frame.shortPopName,
    brand: frame.brand.name,
    model: frame.model.name,
    transmissionType: frame.transmissionType,
    fuelType: frame.fuelType,
    engine: frame.engine,
    seating: frame.seating,
    doors: frame.doors,
    modelYear: frame.modelYear,
    image: frame.framePic || null,
    enginePowerHp: frame.enginePowerHp,
    bodyType: frame.bodyType,
    drivingWheel: frame.drivingWheel,
    vehicleType: asset.vehicleType,
    powertrain: frame.powertrain,
  }));

export const selectSideBarPropsOL = () =>
  createSelector(
    selectIsCasePrivateOperationalLease(),
    selectIsUpdatingPrice(),
    selectQuoteDataOL(),
    selectSelectedTerms(),
    selectActiveOLterms(),
    selectSelectedEquipment(),
    selectCommissionTerms(),
    selectMaxDiscountPercentage(),
    isInvalid('priceDeductionForm'),
    selectAsset(),
    (
      isCasePrivateOperationalLease,
      isUpdatingPrice,
      quoteData,
      { mileage, period },
      terms,
      selectedEquipment,
      commission,
      defaultMaxDiscountPercentage,
      commissionSectionError,
      asset,
    ) => {
      const [vendorTermsId] = isCasePrivateOperationalLease
        ? asset.privateVendorTermsIds || []
        : asset.commercialVendorTermsIds || [];

      const currentTerms =
        (vendorTermsId && terms.find((term) => term.vendorTermsId === vendorTermsId)) ||
        terms.find((term) => term.isDefault) ||
        {};

      const { yearlyMileages = [], leasePeriods = [] } = currentTerms;

      return {
        loading: isUpdatingPrice,

        ...quoteData,

        optional: Object.keys(selectedEquipment.optional).map(
          (key) => selectedEquipment.optional[key],
        ),
        services: Object.keys(selectedEquipment.services).map(
          (key) => selectedEquipment.services[key],
        ),
        mileages: yearlyMileages,
        periods: leasePeriods,
        selectedMileage: mileage,
        selectedPeriod: period,
        commissionTermsMaxValue: commission ? commission.maxValue : undefined,
        defaultMaxDiscountPercentage,
        commissionSectionError,
        climateBonusValue: asset.climateBonusValue,
      };
    },
  );

export const selectSideBarPropsHPFL = () =>
  createSelector(
    selectIsUpdatingPrice(),
    selectQuoteDataOL(),
    selectCommissionTerms(),
    selectMaxDiscountPercentage(),
    isInvalid('priceDeductionForm'),
    (
      isUpdatingPrice,
      quoteData,
      commission,
      defaultMaxDiscountPercentage,
      commissionSectionError,
    ) => ({
      loading: isUpdatingPrice,
      ...quoteData,
      commissionTermsMaxValue: commission ? commission.maxValue : undefined,
      defaultMaxDiscountPercentage,
      commissionSectionError,
    }),
  );

export const isLoadingCarInfo = () =>
  createSelector(selectAssetState, (state) => state.get('gettingCarInfo'));

export const selectCarInfoError = () =>
  createSelector(selectAssetState, (state) => state.get('carInfoError'));

export const selectIsAssetNew = () =>
  createSelector(
    selectFrame(),
    selectAsset(),
    selectIsCaseCommercialOperationalLease(),
    selectIsCasePrivateOperationalLease(),
    ({ id }, { frameId }, isCaseCommercialOperationalLease, isCasePrivateOperationalLease) =>
      id > 0 || frameId > 0 || isCaseCommercialOperationalLease || isCasePrivateOperationalLease,
  );

export const selectAssetHPorFL = () =>
  createSelector(selectAsset(), selectFrame(), (asset, frame) => {
    const assetFramePic = asset.framePicFile ? `${FILE_IMAGE_BASE}/${asset.framePicFile}` : null;

    return {
      registrationDate: asset.registrationDate,
      state: asset.state,
      modelYear: asset.modelYear || frame.modelYear,
      transmissionType: asset.transmissionType || frame.transmissionType,
      fuelType: asset.fuelType || frame.fuelType,
      enginePowerHp: asset.enginePowerHp || frame.enginePowerHp,
      drivingWheel: asset.drivingWheel || frame.drivingWheel,
      colour: asset.colour,
      popName: asset.popName || frame.popName,
      brand: asset.brand || frame.brand.name,
      model: asset.model || frame.model.name,
      licencePlate: asset.licencePlate,
      vin: asset.vin,
      mileage: asset.mileage,
      vehicleType: asset.vehicleType || frame.vehicleType,
      image: frame.framePic || assetFramePic,
      powertrain: asset.powertrain || frame.powertrain,
    };
  });

export const selectIsAssetWithWinterTires = () =>
  createSelector(selectAsset(), ({ winterTires }) => Boolean(winterTires));

export const selectHasAssetPlateChanged = () =>
  createSelector(selectAssetState, selectAsset(), (state, asset) =>
    hasValueChanged({
      currentValue: asset.licencePlate,
      previousValue: state.get('assetPreviousLicencePlate'),
    }),
  );

export const selectHasAssetIdChanged = () =>
  createSelector(selectAssetState, selectAsset(), (state, asset) =>
    hasValueChanged({
      currentValue: asset.id,
      previousValue: state.get('assetPreviousId'),
    }),
  );

export const selectSavedAssetId = () =>
  createSelector(selectAssetState, selectAsset(), (state) => state.get('assetPreviousId'));

export const selectVehicleType = () =>
  createSelector(
    selectAsset(),
    selectFrame(),
    (asset, frame) => frame.vehicleType || asset.vehicleType,
  );

export const selectIsPrefillingAsset = () =>
  createSelector(selectAssetState, (state) => state.get('prefillingAsset'));
