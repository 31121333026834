import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  iconWrapper: {
    outline: 'none',
  },
  icon: ({ colors, typography, fontSizes }) => ({
    color: colors.solid.black,
    fontSize: fontSizes.extraSmall,
    fontFamily: typography.main,
    backgroundColor: colors.danske.greyC60,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    height: 20,
    width: 20,
    borderRadius: '50%',
    marginLeft: '4px',
    '&:hover': {
      backgroundColor: colors.solid.lighterGray,
    },
  }),
};
