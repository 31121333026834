"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateDayJsLocale = exports.setInitialDayJSLocale = void 0;
var _dayjs = _interopRequireDefault(require("dayjs"));
var _updateLocale = _interopRequireDefault(require("dayjs/plugin/updateLocale"));
var _customParseFormat = _interopRequireDefault(require("dayjs/plugin/customParseFormat"));
var _localStorageUtils = require("./localStorage-utils");
require('dayjs/locale/sv');
require('dayjs/locale/nn');
require('dayjs/locale/fi');
require('dayjs/locale/en-gb');
const LOCALE_MAP = {
  en: 'en-gb',
  se: 'sv',
  no: 'nn',
  fi: 'fi'
};
_dayjs.default.extend(_updateLocale.default);
_dayjs.default.extend(_customParseFormat.default);
const setInitialDayJSLocale = _ref => {
  let {
    defaultLocale
  } = _ref;
  const currentLocale = (0, _localStorageUtils.getValueByKey)(_localStorageUtils.KEYS.LOCALE);
  updateDayJsLocale({
    locale: currentLocale || defaultLocale
  });
};
exports.setInitialDayJSLocale = setInitialDayJSLocale;
const updateDayJsLocale = _ref2 => {
  let {
    locale
  } = _ref2;
  return _dayjs.default.locale(LOCALE_MAP[locale]);
};
exports.updateDayJsLocale = updateDayJsLocale;