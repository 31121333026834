import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  paper: ({ colors }) => ({
    '& .MuiDialog-paper': {
      color: colors.custom.fontPrimary,
      borderRadius: 0,
      width: 'calc(100% - 40px)',
      maxWidth: 1200,
      maxHeight: 'calc(100% - 40px)',
      margin: 0,
      ...colors.custom.cookiesConsent.content,
    },
  }),
  contentRoot: {
    '&.MuiDialogContent-root': {
      padding: '0 !important',
    },
  },
  content: ({ spacing, breakpoints }) => ({
    padding: `${spacing(9)} ${spacing(7)} 0px !important`,
    lineHeight: '27.4px',
    [breakpoints.only('xs')]: {
      padding: `${spacing(4)} ${spacing(4)} 0px ${spacing(4)} !important`,
      lineHeight: '1.7',
    },
  }),
  logo: {
    width: 200,
    marginBottom: 3,
  },
  title: ({ spacing, breakpoints }) => ({
    marginBottom: spacing(1),
    [breakpoints.only('xs')]: {
      lineHeight: '1.4',
    },
  }),
  buttons: ({ spacing, breakpoints, colors }) => ({
    padding: `${spacing(7)} 0px ${spacing(9)} ${spacing(7)}`,
    [breakpoints.only('xs')]: {
      padding: `${spacing(9)} 0px ${spacing(4)} 0px`,
      textAlign: 'center',
    },
    ...colors.custom.cookiesConsent.buttons,
    position: 'sticky',
    bottom: 0,
  }),
  button: ({ spacing, breakpoints, fontSizes: { extraSmall } }) => ({
    '&.MuiButton-root': {
      height: 48,
      minWidth: 184,
      marginRight: spacing(3),
      [breakpoints.only('xs')]: {
        marginRight: spacing(1),
        height: 41,
        fontSize: extraSmall,
      },
    },
  }),
  buttonLink: ({ colors, breakpoints, fontSizes: { extraSmall } }) => ({
    '&.MuiButton-root': {
      color: colors.solid.blue,
      [breakpoints.only('xs')]: {
        fontSize: extraSmall,
      },
    },
  }),
};
