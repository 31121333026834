import { RadioGroup, Box, SxProps, Theme } from '@mui/material';
import { FC, ReactChild, ReactNode } from 'react';

import MAFHelperText from '../MAFHelperText/MAFHelperText';
import MAFRadioButton, { IMAFRadioButton } from '../MAFRadioButton/MAFRadioButton';

import { styles } from './MAFRadioControl.styles';
import { propTypes } from './MAFRadioControl.propTypes';

export interface IMAFRadioControlOptions extends IMAFRadioButton {
  label: ReactChild;
  value: ReactChild;
}

export interface IMAFRadioControlProps {
  helperText?: ReactChild;
  isError?: boolean;
  options: IMAFRadioControlOptions[];
  AdditionalComponent?: ReactNode;
  onChange: (value: ReactChild) => void;
  value: ReactChild;
  containerStyles?: SxProps<Theme>;
}

const MAFRadioControl: FC<IMAFRadioControlProps> = ({
  helperText,
  isError,
  options,
  AdditionalComponent,
  onChange,
  containerStyles,
  ...props
}) => (
  <Box sx={styles.container}>
    <Box sx={containerStyles}>
      <RadioGroup
        {...props}
        sx={styles.radioButtonsContainer}
        onBlur={(e) => {
          e.preventDefault();
        }}
      >
        {options &&
          options.map(({ label, value, ...rest }, index) => (
            <MAFRadioButton
              {...rest}
              key={index}
              label={label}
              checked={props.value === value}
              onChange={() => onChange(value)}
            />
          ))}
      </RadioGroup>
      {helperText && <MAFHelperText isError={isError}>{helperText}</MAFHelperText>}
    </Box>
    {AdditionalComponent}
  </Box>
);

MAFRadioControl.propTypes = propTypes;

export default MAFRadioControl;
