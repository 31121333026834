"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUserResponse = exports.updateUserRequest = exports.regenerateConfirmationTokenResponse = exports.regenerateConfirmationTokenRequest = exports.reauthorizeIdentitiesResponse = exports.reauthorizeIdentitiesRequest = exports.initialState = exports.getUserListResponse = exports.getUserListRequest = exports.deleteIdentitiesResponse = exports.deleteIdentitiesRequest = exports.default = exports.deactivateIdentitiesResponse = exports.deactivateIdentitiesRequest = exports.createUserResponse = exports.createUserRequest = exports.createIdentityResponse = exports.createIdentityRequest = exports.clearState = exports.changeRelatedImporterRequest = exports.changeNeedToReauthorize = exports.changeFormVisibility = exports.activateIdentitiesResponse = exports.activateIdentitiesRequest = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
/**
 * Actions
 */
const getUserListRequest = exports.getUserListRequest = (0, _reduxActions.createAction)('USER_MANAGEMENT_GET_USER_LIST_REQUEST');
const getUserListResponse = exports.getUserListResponse = (0, _reduxActions.createAction)('USER_MANAGEMENT_GET_USER_LIST_RESPONSE');
const changeNeedToReauthorize = exports.changeNeedToReauthorize = (0, _reduxActions.createAction)('USER_MANAGEMENT_CHANGE_NEED_TO_REAUTHORIZE');
const clearState = exports.clearState = (0, _reduxActions.createAction)('USER_MANAGEMENT_CLEAR_STATE');
const createUserRequest = exports.createUserRequest = (0, _reduxActions.createAction)('USER_MANAGEMENT_CREATE_USER_REQUEST');
const createUserResponse = exports.createUserResponse = (0, _reduxActions.createAction)('USER_MANAGEMENT_CREATE_USER_RESPONSE');
const updateUserRequest = exports.updateUserRequest = (0, _reduxActions.createAction)('USER_MANAGEMENT_UPDATE_USER_REQUEST');
const updateUserResponse = exports.updateUserResponse = (0, _reduxActions.createAction)('USER_MANAGEMENT_UPDATE_USER_RESPONSE');
const changeFormVisibility = exports.changeFormVisibility = (0, _reduxActions.createAction)('USER_MANAGEMENT_CHANGE_FORM_VISIBILITY');
const regenerateConfirmationTokenRequest = exports.regenerateConfirmationTokenRequest = (0, _reduxActions.createAction)('USER_MANAGEMENT_REGENERATE_CONFIRMATION_TOKEN_REQUEST');
const regenerateConfirmationTokenResponse = exports.regenerateConfirmationTokenResponse = (0, _reduxActions.createAction)('USER_MANAGEMENT_REGENERATE_CONFIRMATION_TOKEN_RESPONSE');
const createIdentityRequest = exports.createIdentityRequest = (0, _reduxActions.createAction)('USER_MANAGEMENT_CREATE_IDENTITY_REQUEST');
const createIdentityResponse = exports.createIdentityResponse = (0, _reduxActions.createAction)('USER_MANAGEMENT_CREATE_IDENTITY_RESPONSE');
const reauthorizeIdentitiesRequest = exports.reauthorizeIdentitiesRequest = (0, _reduxActions.createAction)('USER_MANAGEMENT_REAUTHORIZE_IDENTITIES_REQUEST');
const reauthorizeIdentitiesResponse = exports.reauthorizeIdentitiesResponse = (0, _reduxActions.createAction)('USER_MANAGEMENT_REAUTHORIZE_IDENTITIES_RESPONSE');
const activateIdentitiesRequest = exports.activateIdentitiesRequest = (0, _reduxActions.createAction)('USER_MANAGEMENT_ACTIVATE_IDENTITIES_REQUEST');
const activateIdentitiesResponse = exports.activateIdentitiesResponse = (0, _reduxActions.createAction)('USER_MANAGEMENT_ACTIVATE_IDENTITIES_RESPONSE');
const deactivateIdentitiesRequest = exports.deactivateIdentitiesRequest = (0, _reduxActions.createAction)('USER_MANAGEMENT_DEACTIVATE_IDENTITIES_REQUEST');
const deactivateIdentitiesResponse = exports.deactivateIdentitiesResponse = (0, _reduxActions.createAction)('USER_MANAGEMENT_DEACTIVATE_IDENTITIES_RESPONSE');
const deleteIdentitiesRequest = exports.deleteIdentitiesRequest = (0, _reduxActions.createAction)('USER_MANAGEMENT_DELETE_IDENTITIES_REQUEST');
const deleteIdentitiesResponse = exports.deleteIdentitiesResponse = (0, _reduxActions.createAction)('USER_MANAGEMENT_DELETE_IDENTITIES_RESPONSE');
const changeRelatedImporterRequest = exports.changeRelatedImporterRequest = (0, _reduxActions.createAction)('USER_MANAGEMENT_CHANGE_RELATED_IMPORTER_REQUEST');
/**
 * Initial state
 */
const initialState = exports.initialState = (0, _immutable.fromJS)({
  list: [],
  sorting: {
    orderBy: 'status',
    direction: 'asc'
  },
  pagination: {
    page: 1,
    totalPages: 1,
    size: 200
  },
  gettingUsers: false,
  creatingUser: false,
  updatingUser: false,
  verifyingUser: false,
  userVerified: undefined,
  createUserForm: false,
  isLoadingUser: false,
  updatingIdentities: false,
  needToReauthorize: false,
  relatedImporter: null
});

/**
 * Actions handlers
 */
var _default = exports.default = (0, _reduxActions.handleActions)({
  [getUserListRequest](state) {
    return state.set('gettingUsers', true);
  },
  [getUserListResponse](state, _ref) {
    let {
      payload,
      error
    } = _ref;
    return !error ? state.set('list', (0, _immutable.fromJS)(payload)).set('gettingUsers', false) : state.set('gettingUsers', false);
  },
  [createUserRequest](state) {
    return state.set('creatingUser', true);
  },
  [createUserResponse](state) {
    return state.set('creatingUser', false).set('createUserForm', false);
  },
  [updateUserRequest](state) {
    return state.set('updatingUser', true);
  },
  [updateUserResponse](state) {
    return state.set('updatingUser', false);
  },
  [clearState]() {
    return initialState;
  },
  [changeFormVisibility](state, _ref2) {
    let {
      payload
    } = _ref2;
    const {
      form,
      visibility
    } = payload;
    return state.set(form, visibility);
  },
  [(0, _reduxActions.combineActions)(createIdentityRequest, reauthorizeIdentitiesRequest, activateIdentitiesRequest, deactivateIdentitiesRequest, deleteIdentitiesRequest)](state) {
    return state.set('updatingIdentities', true);
  },
  [(0, _reduxActions.combineActions)(createIdentityResponse, reauthorizeIdentitiesResponse, activateIdentitiesResponse, deactivateIdentitiesResponse, deleteIdentitiesResponse)](state) {
    return state.set('updatingIdentities', false);
  },
  [changeNeedToReauthorize](state, _ref3) {
    let {
      payload
    } = _ref3;
    return state.set('needToReauthorize', payload);
  },
  [changeRelatedImporterRequest](state, _ref4) {
    let {
      payload
    } = _ref4;
    return state.set('relatedImporter', (0, _immutable.fromJS)(payload.importer)).set('gettingUsers', true);
  }
}, initialState);