"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectUsersRequest = exports.selectUsers = exports.selectUserGettingState = exports.selectRelatedImporter = exports.selectNeedToReauthorize = exports.selectIsUpdatingIdentities = exports.isUserVerified = exports.isUserBeingVerified = exports.isUserBeingCreated = exports.isCreateUserFormOpen = void 0;
var _reselect = require("reselect");
const selectUsersState = state => state.users;
const selectUsers = () => (0, _reselect.createSelector)(selectUsersState, userState => userState.get('list').toJS());
exports.selectUsers = selectUsers;
const selectUserGettingState = () => (0, _reselect.createSelector)(selectUsersState, userState => userState.get('gettingUsers'));
exports.selectUserGettingState = selectUserGettingState;
const isUserBeingVerified = () => (0, _reselect.createSelector)(selectUsersState, userState => userState.get('verifyingUser'));
exports.isUserBeingVerified = isUserBeingVerified;
const isUserVerified = () => (0, _reselect.createSelector)(selectUsersState, userState => userState.get('userVerified'));
exports.isUserVerified = isUserVerified;
const isUserBeingCreated = () => (0, _reselect.createSelector)(selectUsersState, userState => userState.get('creatingUser'));
exports.isUserBeingCreated = isUserBeingCreated;
const selectRelatedImporter = () => (0, _reselect.createSelector)(selectUsersState, state => {
  var _state$get;
  return (_state$get = state.get('relatedImporter')) === null || _state$get === void 0 ? void 0 : _state$get.toJS();
});
exports.selectRelatedImporter = selectRelatedImporter;
const selectUsersRequest = () => (0, _reselect.createSelector)(selectRelatedImporter(), relatedImporter => ({
  partner_id: relatedImporter === null || relatedImporter === void 0 ? void 0 : relatedImporter.value
}));
exports.selectUsersRequest = selectUsersRequest;
const isCreateUserFormOpen = () => (0, _reselect.createSelector)(selectUsersState, state => state.get('createUserForm'));
exports.isCreateUserFormOpen = isCreateUserFormOpen;
const selectIsUpdatingIdentities = () => (0, _reselect.createSelector)(selectUsersState, state => state.get('updatingIdentities'));
exports.selectIsUpdatingIdentities = selectIsUpdatingIdentities;
const selectNeedToReauthorize = () => (0, _reselect.createSelector)(selectUsersState, state => state.get('needToReauthorize'));
exports.selectNeedToReauthorize = selectNeedToReauthorize;