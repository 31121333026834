import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  background: PropTypes.string,
  children: PropTypes.node,
  childrenPlacement: PropTypes.oneOf(['aboveSpinner', 'belowSpinner']),
  color: PropTypes.oneOf(['blue', 'grey', 'white']),
  delay: PropTypes.number,
  isTextAboveSpinner: PropTypes.bool,
  loaderMessages: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]).isRequired,
  ),
  spinnerContainerClasses: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  spinnerIconClasses: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  spinnerVariant: PropTypes.oneOf(['large', 'responsive', 'small']),
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
} as any;
