"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: _ref => {
    let {
      breakpoints
    } = _ref;
    return {
      [breakpoints.up('md')]: {
        minWidth: 355
      },
      cursor: 'default',
      [breakpoints.up('lg')]: {
        marginRight: '15px'
      }
    };
  }
};