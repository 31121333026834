import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  calendar: {
    padding: '4px',
  },
  icon: ({ spacing, colors }) => ({
    '& > svg': {
      height: spacing(2),
      width: spacing(2),
      '& g': {
        ...colors.custom.calendar.arrow,
      },
    },
  }),
};
