import { memo } from 'react';
import { useScreenSize } from 'src/utils';

import { MAFRow } from '..';
import { IMAFTableClasses, IMAFTableData, IMAFTableField } from '../../MAFTable.types';

import { propTypes } from './MAFTableBody.propTypes';

export interface IMAFTableBodyProps<T extends IMAFTableData> {
  data: { data: T; hasCheckbox?: boolean }[];
  fields: IMAFTableField<T>[];
  hasCheckbox?: boolean;
  isWithPagination?: boolean;
  rowsPerPage?: number;
  page?: number;
  selectCase?: (item: T) => void;
  onItemClick?: (item: T) => void;
  tableClasses?: IMAFTableClasses;
  selectedItems?: string[];
  isUplift?: boolean;
  isWithMobile?: boolean;
}

function MAFTableBody<T extends IMAFTableData>({
  data,
  selectCase,
  fields,
  onItemClick,
  isWithPagination,
  hasCheckbox,
  rowsPerPage = 25,
  page = 1,
  selectedItems,
  tableClasses,
  isUplift = false,
  isWithMobile = false,
}: IMAFTableBodyProps<T>) {
  const { isMobile } = useScreenSize();

  const desktopData =
    isWithPagination && rowsPerPage > 0
      ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : data;

  const dataToUse = isMobile && isWithMobile ? data : desktopData;

  return (
    <>
      {dataToUse.map((entry) => (
        <MAFRow<T>
          key={entry.data.id}
          fields={fields}
          item={entry}
          selectCase={selectCase}
          hasCheckbox={hasCheckbox}
          onItemClick={onItemClick}
          isSelected={selectedItems && selectedItems.includes(`${entry.data.id}`)}
          tableClasses={tableClasses}
          isUplift={isUplift}
        />
      ))}
    </>
  );
}

MAFTableBody.propTypes = propTypes;

// casting MAFTableBody type because React.memo does not infer it by default.
export default memo(MAFTableBody) as unknown as typeof MAFTableBody;
