import { FC, ReactChild } from 'react';
import { Box } from '@mui/material';

import { styles } from './MAFNoResults.styles';
import { propTypes } from './MAFNoResults.propTypes';

interface IMAFNoResultsProps {
  hasData: boolean;
  isFetching: boolean;
  isUplift?: boolean;
  noResultsMessage: ReactChild;
}

const MAFNoResults: FC<IMAFNoResultsProps> = ({
  hasData,
  isFetching,
  isUplift = false,
  noResultsMessage,
}) =>
  !hasData && !isFetching ? (
    <Box sx={[styles.noResultsText, isUplift && styles.isUplift]}>{noResultsMessage}</Box>
  ) : null;

MAFNoResults.propTypes = propTypes;

export default MAFNoResults;
