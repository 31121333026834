import {
  app,
  caseDetail,
  asset as assetModule,
  CAR_CONFIGURATION_EQUIPMENT_FORM,
  EQUIPMENT_FORM_ADDITIONAL,
  EQUIPMENT_FORM_COLORS,
  EQUIPMENT_FORM_RETROFIT,
} from '@northstar/core-ui/modules';
import { errorUtils, objectUtils, uiErrorUtils } from '@northstar/core-ui/utils';
import { PROCESS_VARIANCES } from '@northstar/core';
import { change, getFormValues, startSubmit } from 'redux-form';
import { delay, call, put, takeLatest, select, all, take } from 'redux-saga/effects';
import { callWithAttachers } from '@northstar/core-ui/utils/redux-saga-utils';

import { changeAssetPreviousLicencePlate } from 'modules/asset/assetReducer';
import { selectAsset } from 'modules/asset/assetSelectors';
import { changeFramePreviousId } from 'modules/asset/frameReducer';
import { selectFrame } from 'modules/asset/frameSelectors';
import { prefillNewCarsFlowEq, displayQuoteErrors } from 'modules/quote/quoteSaga';
import { createAttacherForTermsToUse } from 'modules/vendor/vendorTermsAttachers';
import { selectVendorTermsPRHP } from 'modules/vendor/vendorTermsSelectors';

import { prefillEquipmentCategory } from '../asset/equipmentSaga';
import {
  selectEquipment,
  selectEditableEquipmentFromCalculator,
} from '../asset/equipmentSelectors';
import {
  updateHirePurchaseCalculationsRequest,
  updateHirePurchaseCalculationsResponse,
} from '../caseDetail/caseAssetReducer';
import { selectCaseAsset } from '../caseDetail/caseDetailSelectors';
import { selectProductTermsPRHP } from '../productTerms/productTermsSelectors';

import * as hirePurchaseApi from './hirePurchaseApi';
import {
  HP_FORM,
  HP_FORM_DOWN_PAYMENT,
  HP_FORM_INTEREST_RATE,
  HP_FORM_FUTURE_PERCENTAGE,
  HP_FORM_VENDOR_TERMS_ID,
} from './hirePurchaseConstants';
import { formMappers, responseMappers } from './hirePurchaseMapper';
import {
  postHirePurchaseQuoteRequest,
  postHirePurchaseQuoteResponse,
  getInitValuesHPRequest,
  getInitValuesHPResponse,
  prefillHPValues,
  startCalculatingState,
  stopCalculatingState,
  saveCalculationsRequest,
} from './hirePurchaseReducer';
import { selectIsHpFormsValid, selectInitialValues } from './hirePurchaseSelector';
import { calculateTotalEquipmentPrice, calculateMinimumDownPayment } from './hirePurchaseUtils';
const { equipmentMappers } = assetModule;
const { formMappers: equipmentFormMappers } = equipmentMappers;

export function* adjustMinimumDownPayment({ formValues, equipmentValues }) {
  const { downPaymentMinPercentage } = yield select(selectProductTermsPRHP());
  const minimumDownPayment = calculateMinimumDownPayment({
    carPrice: formValues.carPrice,
    minimumPercentage: downPaymentMinPercentage,
    tradeinCarValue: formValues.tradeinCarValue,
    tradeinCarDebt: formValues.tradeinCarDebt,
    equipmentPrice: calculateTotalEquipmentPrice({ equipmentFormValues: equipmentValues }),
  });
  const downPayment =
    formValues.downPayment < minimumDownPayment ? minimumDownPayment : formValues.downPayment;
  yield put(change(HP_FORM, HP_FORM_DOWN_PAYMENT, downPayment));
  return {
    ...formValues,
    [HP_FORM_DOWN_PAYMENT]: downPayment,
  };
}

export function* calculateQuote({ payload }) {
  try {
    yield put(startSubmit(HP_FORM));
    yield put(startCalculatingState());
    yield delay(1000);
    const formsValid = yield select(selectIsHpFormsValid());
    const allValidTerms = yield select(selectVendorTermsPRHP());
    if (
      !formsValid ||
      !allValidTerms.some(({ vendorTermsId }) => vendorTermsId === payload.formValues.vendorTermsId)
    ) {
      yield put(stopCalculatingState());
      return;
    }
    const { precalculate, formValues } = payload;
    const equipmentValues = yield select(getFormValues(CAR_CONFIGURATION_EQUIPMENT_FORM));
    const updatedFormValues = yield call(adjustMinimumDownPayment, { formValues, equipmentValues });
    /**
     * Since max residual rate is dynamic value, it depends on car age and duration of the agreement.
     * We send initial request without specifying max residual percentage at first,
     * so in that way we get the upper limit.
     */
    // TODO refactor this
    let body = formMappers.mapFormValuesToRequest(updatedFormValues);
    if (precalculate) {
      const calculationResults = yield callWithAttachers({
        endpoint: hirePurchaseApi.postHirePurchaseQuote,
        payload: {
          requestBody: {
            ...formMappers.mapFormValuesToRequest(updatedFormValues),
            bullet_percentage: 0,
          },
        },
        attachers: [createAttacherForTermsToUse(PROCESS_VARIANCES.PRIVATE_HP)],
      });
      body = yield call(updateFuturePercentageAndUpdateRequest, {
        response: calculationResults,
        futurePercentage: formValues[HP_FORM_FUTURE_PERCENTAGE],
        body,
      });
    }
    const equipment = yield select(selectEditableEquipmentFromCalculator());
    const calculationResults = yield callWithAttachers({
      endpoint: hirePurchaseApi.postHirePurchaseQuote,
      payload: {
        requestBody: {
          ...body,
          ...equipment,
        },
      },
      attachers: [createAttacherForTermsToUse(PROCESS_VARIANCES.PRIVATE_HP)],
    });
    yield put(postHirePurchaseQuoteResponse(responseMappers.mapQuote(calculationResults)));
    yield put(stopCalculatingState());
  } catch (e) {
    yield put(postHirePurchaseQuoteResponse(e));

    yield call(displayQuoteErrors, {
      fieldLevelError: errorUtils.mapResponseExceptionsToError({
        response: e,
        customExceptionMapper: uiErrorUtils.constructCustomErrorsToUIFieldsMapper(
          responseMappers.errorToUIFieldValues,
        ),
      }),
      formName: HP_FORM,
    });
  }
}

// TODO refactor this
export function* updateFuturePercentageAndUpdateRequest({ response, futurePercentage, body }) {
  const mappedResponse = responseMappers.mapQuote(response);
  const { maxResidualPercentage } = mappedResponse;
  let bulletPercentage = futurePercentage;
  if (maxResidualPercentage < futurePercentage) {
    yield put(change(HP_FORM, HP_FORM_FUTURE_PERCENTAGE, maxResidualPercentage));
    bulletPercentage = maxResidualPercentage;
  }
  return { ...body, bullet_percentage: bulletPercentage };
}

export function* getInitValues({
  payload: { applyInitialValue, vendorTermsId, additionalFunction },
}) {
  try {
    const response = yield callWithAttachers({
      endpoint: hirePurchaseApi.getInitHPValues,
      payload: { vendorTermsId },
      attachers: [createAttacherForTermsToUse(PROCESS_VARIANCES.PRIVATE_HP)],
    });
    const mappedResponse = objectUtils.objectToCamelCase({ obj: response });
    yield put(getInitValuesHPResponse(mappedResponse));
    if (applyInitialValue) {
      yield put(change(HP_FORM, HP_FORM_VENDOR_TERMS_ID, mappedResponse.vendorTermsId));
      yield put(
        change(HP_FORM, HP_FORM_INTEREST_RATE, mappedResponse.defaultTotalYearlyInterestRate),
      );
    }
    if (typeof additionalFunction === 'function') {
      additionalFunction();
    }
  } catch (e) {
    yield put(getInitValuesHPResponse(e));
  }
}

export function* prefillValues({ payload }) {
  try {
    if (payload.resourceId) {
      // Calling case again to prefill asset
      yield put(
        caseDetail.getCaseRequest({
          resourceId: payload.resourceId,
          draftScenarioId: payload.draftScenarioId,
        }),
      );
      yield take(caseDetail.getCaseResponse().type);
    }

    const initialValues = yield select(selectInitialValues());
    if (initialValues) {
      yield all(
        Object.keys(initialValues).map((key) => put(change(HP_FORM, key, initialValues[key]))),
      );

      if (payload.resourceId) {
        const { frameId } = yield select(selectCaseAsset());
        if (frameId) yield call(prefillNewCarsFlowEq, { payload: { frameId } });
      }

      const { additional, colors, retrofit } = yield select(selectEquipment());

      yield prefillEquipmentCategory({
        equipment: equipmentFormMappers.mapEquipmentToFormValues(additional),
        category: EQUIPMENT_FORM_ADDITIONAL,
      });
      yield prefillEquipmentCategory({
        equipment: equipmentFormMappers.mapEquipmentToFormValues(colors),
        category: EQUIPMENT_FORM_COLORS,
      });
      yield prefillEquipmentCategory({
        equipment: equipmentFormMappers.mapRetrofitToFormValues({
          retrofit,
          isWithVAT: true,
        }),
        category: EQUIPMENT_FORM_RETROFIT,
      });

      yield put(
        postHirePurchaseQuoteRequest({
          formValues: initialValues,
          precalculate: true,
          isInitialLoad: true,
        }),
      );
    }
  } catch (e) {
    yield put(app.displayError('errors.could_not_retrieve_quote'));
  }
}

export function* saveCalculations({ payload }) {
  const formValues = yield select(getFormValues(HP_FORM));
  const formsValid = yield select(selectIsHpFormsValid());
  const currentAsset = yield select(selectAsset());
  const currentFrame = yield select(selectFrame());
  yield put(changeAssetPreviousLicencePlate({ licencePlate: currentAsset?.licencePlate }));
  yield put(changeFramePreviousId({ id: currentFrame?.id }));

  if (formsValid) {
    const { redirect, mileage } = payload;
    yield put(updateHirePurchaseCalculationsRequest({ mileage, ...formValues }));
    yield take(updateHirePurchaseCalculationsResponse().type);
    if (typeof redirect === 'function') {
      yield call(redirect);
    }
  } else {
    yield put(stopCalculatingState());
  }
}

export default function* hirePurchase() {
  yield takeLatest(postHirePurchaseQuoteRequest, calculateQuote);
  yield takeLatest(getInitValuesHPRequest, getInitValues);
  yield takeLatest(prefillHPValues, prefillValues);
  yield takeLatest(saveCalculationsRequest, saveCalculations);
}
