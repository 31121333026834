const Add: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-action/Add/Darkblue" fill="#003755">
        <g id="Icon" transform="translate(2.000000, 2.000000)">
          <path
            d="M10.5511111,9.44444444 L10.5511111,5.55555556 C10.5511111,5.24888889 10.3022222,5 9.99555556,5 C9.68888889,5 9.44,5.24888889 9.44,5.55555556 L9.44,9.44444444 L5.55555556,9.44444444 C5.24888889,9.44444444 5,9.69333333 5,10 C5,10.3066667 5.24888889,10.5555556 5.55555556,10.5555556 L9.44,10.5555556 L9.44,14.4444444 C9.44,14.7511111 9.68888889,15 9.99555556,15 C10.3022222,15 10.5511111,14.7511111 10.5511111,14.4444444 L10.5511111,10.5555556 L14.4444444,10.5555556 C14.7511111,10.5555556 15,10.3066667 15,10 C15,9.69333333 14.7511111,9.44444444 14.4444444,9.44444444 L10.5511111,9.44444444 Z M10,19 C5.038,19 1,14.963 1,10 C1,5.038 5.038,1 10,1 C14.963,1 19,5.038 19,10 C19,14.963 14.963,19 10,19 M10,0 C4.486,0 0,4.486 0,10 C0,15.514 4.486,20 10,20 C15.514,20 20,15.514 20,10 C20,4.486 15.514,0 10,0"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Add;
