import { ElementType, ReactNode, FC, MouseEventHandler } from 'react';
import { IconButton, SvgIcon, SxProps, Theme } from '@mui/material';

import { useThrottle, handleCustomSx } from '../../utils';

import { styles } from './MAFIcon.styles';
import { propTypes } from './MAFIcon.propTypes';

export interface IMAFIconProps {
  Icon?: ElementType;
  sx?: SxProps<Theme>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  iconButtonClass?: SxProps<Theme>;
  isDisabled?: boolean;
  children?: ReactNode;
  nsTarget?: string;
  clickThrottleTime?: number;
}

const MAFIcon: FC<IMAFIconProps> = ({
  Icon,
  sx,
  onClick,
  iconButtonClass,
  isDisabled,
  children,
  nsTarget,
  clickThrottleTime = 250,
}) => {
  const renderedIcon = Icon && (
    <SvgIcon component={Icon} sx={[styles.icon, ...handleCustomSx(sx)]} inheritViewBox />
  );

  const handleClick = onClick ? useThrottle(onClick, clickThrottleTime) : undefined;

  return onClick ? (
    <IconButton
      sx={[styles.tabIndex, ...handleCustomSx(iconButtonClass)]}
      onClick={handleClick}
      disabled={isDisabled}
      ns-target={nsTarget}
      size="large"
      tabIndex={0}
    >
      {renderedIcon}
      {children}
    </IconButton>
  ) : (
    <>{renderedIcon}</>
  );
};

MAFIcon.propTypes = propTypes;

export default MAFIcon;
