"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectIsGettingEquipment = exports.selectEquipment = void 0;
var _reselect = require("reselect");
const selectEquipmentState = state => state.vendorDashboard.equipment;
const selectEquipment = () => (0, _reselect.createSelector)(selectEquipmentState, state => state.get('equipment').toJS());
exports.selectEquipment = selectEquipment;
const selectIsGettingEquipment = () => (0, _reselect.createSelector)(selectEquipmentState, state => state.get('gettingEquipment'));
exports.selectIsGettingEquipment = selectIsGettingEquipment;