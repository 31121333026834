import { v4 } from 'uuid';

import { COOKIE_NAME } from './MAFCookiesConsent.constants';

export const getConsentLevel = (): Nullable<number> => {
  try {
    const cookiePayload = document.cookie.split('; ').find((row) => row.startsWith(COOKIE_NAME));
    const [, consentPayload] = cookiePayload?.split('=') || [];
    const [consentLevel] = consentPayload.split('.');
    return parseInt(consentLevel, 10);
  } catch (e) {
    return null;
  }
};

type TGetConsentSubmitPayloadResponse = {
  level?: number;
  type: number;
  time: number;
  'version[content]'?: string;
  'version[layout]': number;
  id: string;
  info: string;
};

const getConsentSubmitPayload = (
  type: number,
  time: number,
  level?: number,
  dialogLayoutVersion = 1, // based on hui cookie consent
  dialogContentVersion?: string,
): TGetConsentSubmitPayloadResponse => {
  const ua = navigator.userAgent;
  const loc = window.location.href;
  const info = window.btoa ? window.btoa(`${loc} ${ua}`) : '';
  const id = v4().split('-').join('');

  return {
    level,
    type,
    time,
    'version[content]': dialogContentVersion,
    'version[layout]': dialogLayoutVersion,
    id,
    info,
  };
};

export const encodePayload = (payload: TGetConsentSubmitPayloadResponse): string =>
  Object.keys(payload).reduce((prev, key, i) => {
    const row = [encodeURI(key), payload[key]].join('=');
    if (i === 0) {
      return row;
    }
    return [prev, row].join('&');
  }, '');

type TSetConsentLevelParams = {
  level?: number;
  logConsent: (id: string, data: string) => void;
};

const STATISTICAL_CONSENT_LEVEL_VALID_DAYS = 390;
const NECESSARY_AND_FUNCTIONAL_CONSENT_LEVEL_VALID_DAYS = 90;

export const setConsentLevel = ({ level, logConsent }: TSetConsentLevelParams): void => {
  const validDays =
    level && level > 1
      ? STATISTICAL_CONSENT_LEVEL_VALID_DAYS
      : NECESSARY_AND_FUNCTIONAL_CONSENT_LEVEL_VALID_DAYS;
  const now = new Date();
  const time = Math.round(now.getTime() / 1000);
  now.setDate(now.getDate() + validDays);
  const consentType = 1; // based on hui cookie consent
  const payload = getConsentSubmitPayload(consentType, time, level);
  const consentDetails = `${level}.${consentType}.${time}.${payload.id}`;
  const consentPayload = [COOKIE_NAME, consentDetails].join('=');
  const expires = ['expires', now.toUTCString()].join('=');
  const path = ['path', '/'].join('=');
  document.cookie = [consentPayload, expires, path].join('; ');
  logConsent(payload.id, encodePayload(payload));
};
