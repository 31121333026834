"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SIGNING_NAME = exports.SIGNING_APPENDIX_NAME = exports.SIGNEE_TYPE_CO_APPLICANT = exports.SIGNEE_TYPE_APPLICANT = exports.QUOTE_NAME = exports.INVOICE_NAME = exports.GUARANTOR_AGREEMENT_NAME = exports.DOCUMENT_TYPE_VENDOR_INVOICE = exports.DOCUMENT_TYPE_SIGNING_AGREEMENT = exports.DOCUMENT_TYPE_QUOTE_DOCUMENT = exports.DOCUMENT_TYPE_PROOF_OF_DELIVERY = exports.DOCUMENT_TYPE_GUARANTOR_AGREEMENT = exports.DELIVERY_NAME = void 0;
const DOCUMENT_TYPE_SIGNING_AGREEMENT = exports.DOCUMENT_TYPE_SIGNING_AGREEMENT = 'SIGNING_AGREEMENT';
const DOCUMENT_TYPE_PROOF_OF_DELIVERY = exports.DOCUMENT_TYPE_PROOF_OF_DELIVERY = 'PROOF_OF_DELIVERY';
const DOCUMENT_TYPE_QUOTE_DOCUMENT = exports.DOCUMENT_TYPE_QUOTE_DOCUMENT = 'QUOTE_DOCUMENT';
const DOCUMENT_TYPE_VENDOR_INVOICE = exports.DOCUMENT_TYPE_VENDOR_INVOICE = 'VENDOR_INVOICE';
const DOCUMENT_TYPE_GUARANTOR_AGREEMENT = exports.DOCUMENT_TYPE_GUARANTOR_AGREEMENT = 'GUARANTOR_AGREEMENT';
const QUOTE_NAME = exports.QUOTE_NAME = 'quote';
const INVOICE_NAME = exports.INVOICE_NAME = 'invoice';
const DELIVERY_NAME = exports.DELIVERY_NAME = 'delivery';
const SIGNING_NAME = exports.SIGNING_NAME = 'signing';
const SIGNING_APPENDIX_NAME = exports.SIGNING_APPENDIX_NAME = 'signingAppendix';
const GUARANTOR_AGREEMENT_NAME = exports.GUARANTOR_AGREEMENT_NAME = 'guarantorAgreement';
const SIGNEE_TYPE_APPLICANT = exports.SIGNEE_TYPE_APPLICANT = 'APPLICANT';
const SIGNEE_TYPE_CO_APPLICANT = exports.SIGNEE_TYPE_CO_APPLICANT = 'CO_APPLICANT';