import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  acceptAllButtonProps: PropTypes.shape({
    'apoc-target': PropTypes.string,
  }),
  buttonText: PropTypes.shape({
    agreeAll: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    agreeMandatory: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    agreeSome: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    moreInfo: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  }).isRequired,
  children: PropTypes.node,
  consentLevels: PropTypes.arrayOf(
    PropTypes.shape({
      isMandatory: PropTypes.bool.isRequired,
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
      level: PropTypes.number.isRequired,
      text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  generalInfo: PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]).isRequired,
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  highestLevel: PropTypes.number.isRequired,
  highestMandatoryLevel: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  isStrict: PropTypes.bool,
  logConsent: PropTypes.func.isRequired,
} as any;
