import { ISxStyles } from 'src/theming/theme.type';

export const styles: ISxStyles = {
  item: ({ colors }) => ({
    background: 'white',
    boxShadow: `inset 0px -1px 0px ${colors.danske.greyC60}`,
    minHeight: 48,
  }),
  sectionTitle: ({ spacing, colors }) => ({
    boxShadow: `inset 0px -1px 0px ${colors.danske.greyC60}`,
    marginTop: spacing(4),
  }),
  link: {
    width: '100%',
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  linkColor: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
};
