"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setValueByKey = exports.removeValueByKey = exports.getValueByKey = exports.checkValuesByConsentLevel = exports.KEYS = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _localStorage = _interopRequireDefault(require("local-storage"));
var _maf = require("@asfi/maf");
var _core = require("@northstar/core");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const FUNCTIONAL_KEYS = {
  USERNAME: 'USERNAME',
  LOCALE: 'LOCALE',
  DEFAULT_TERMS: 'DEFAULT_TERMS',
  SYSTEM_MESSAGE: 'SYSTEM_MESSAGE',
  CASE_OVERVIEW_HIDDEN_COLUMNS: 'CASE_OVERVIEW_HIDDEN_COLUMNS'
};
const KEYS = exports.KEYS = _objectSpread(_objectSpread({}, FUNCTIONAL_KEYS), {}, {
  AUTH_SECRET: 'AUTH_COOKIE',
  IDENTITY_ID: 'IDENTITY_ID',
  KYC_FILLED: 'KYC_FILLED',
  CASE_RESOURCE_ID: 'CASE_RESOURCE_ID',
  THEME_SETTINGS: 'THEME_SETTINGS'
});
const getValueByKey = key => _localStorage.default.get(key);
exports.getValueByKey = getValueByKey;
const setValueByKey = (key, value) => {
  const currentLevel = (0, _maf.getConsentLevel)();
  if (process.env.REACT_APP_CURR_PROJECT === _core.EXTERNAL_PROJECT_NAME && (currentLevel === null || currentLevel < 1 && Object.keys(FUNCTIONAL_KEYS).includes(key))) return;
  _localStorage.default.set(key, value);
};
exports.setValueByKey = setValueByKey;
const removeValueByKey = key => {
  _localStorage.default.remove(key);
};
exports.removeValueByKey = removeValueByKey;
const checkValuesByConsentLevel = () => {
  const currentLevel = (0, _maf.getConsentLevel)();
  if (currentLevel < 1) {
    Object.keys(FUNCTIONAL_KEYS).forEach(removeValueByKey);
  }
};
exports.checkValuesByConsentLevel = checkValuesByConsentLevel;