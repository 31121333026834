import { Box } from '@mui/material';
import { ReactChild } from 'react';

import AssetHeader from '../AssetHeader/AssetHeader';
import Details from '../Details/Details';
import { IAgreementDetails, ISpecifications, TFormatTextFunc } from 'types';

import SpecificationBox from './SpecificationBox/SpecificationBox';
import { styles } from './carSpecifications.styles';

export interface ICarSpecificationsProps {
  title: ReactChild;
  subTitle: ReactChild;
  image: ReactChild;
  specifications: ISpecifications[];
  details: IAgreementDetails[];
  formatText: TFormatTextFunc;
}

const CarSpecifications: React.FC<ICarSpecificationsProps> = ({
  title,
  subTitle,
  image,
  specifications,
  details,
  formatText,
}) => (
  <>
    <AssetHeader title={title} subTitle={subTitle} sx={styles.assetHeader} image={image} />
    <SpecificationBox specifications={specifications} />
    <Details details={details} formatText={formatText} />
    <Box sx={styles.pageBreak}></Box>
  </>
);

export default CarSpecifications;
