import { Switch, SwitchProps } from '@mui/material';

import { styles } from './MAFSwitch.styles';
import { propTypes } from './MAFSwitch.propTypes';

export interface IMAFSwitchProps extends SwitchProps {}

const MAFSwitch: React.FC<IMAFSwitchProps> = ({
  disabled: isDisabled = false,
  checked: isChecked = false,
  ...rest
}) => (
  <Switch
    sx={[
      styles.root,
      styles.switchBase,
      styles.thumb,
      styles.track,
      isChecked && styles.checked,
      isDisabled && styles.disabled,
    ]}
    checked={isChecked}
    disabled={isDisabled}
    {...rest}
  />
);

MAFSwitch.propTypes = propTypes;

export default MAFSwitch;
