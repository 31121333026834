import { FC, useEffect, useState, ReactNode, ReactChild } from 'react';
import { Paper, Grid, Box } from '@mui/material';

import { useScreenSize } from '../../utils';

import { useStyles } from './MAFTableEnhancedToolbar.styles';
import { propTypes } from './MAFTableEnhancedToolbar.propTypes';

enum Adjustments {
  DESKTOP = 75,
  TABLET = 63,
  MOBILE = 55,
}

export interface IMAFTableEnhancedToolbarProps {
  mainComponent: ReactNode;
  actionComponent: ReactNode;
  selectedItems: number;
  selectedItemsActionComponent: ReactNode;
  selectedItemsActionLabel: ReactChild;
  forwardedRef: React.MutableRefObject<Element | undefined>;
  additionalTopAdjustment?: number;
  widthAdjustments?: number;
  hasNoBackground?: boolean;
}

const MAFTableEnhancedToolbar: FC<IMAFTableEnhancedToolbarProps> = ({
  mainComponent,
  actionComponent,
  selectedItems,
  selectedItemsActionComponent,
  selectedItemsActionLabel,
  forwardedRef,
  additionalTopAdjustment,
  widthAdjustments,
  hasNoBackground = false,
}) => {
  const { isMobile, isTablet } = useScreenSize();
  const initialTopAdjustment =
    (forwardedRef.current?.getBoundingClientRect().top || 0) + (additionalTopAdjustment || 0) + 55;
  const [topAdjustment, setTopAdjustment] = useState(initialTopAdjustment);
  const styles = useStyles({ topAdjustment, widthAdjustments, hasNoBackground });

  const getAdjustments = () => {
    if (isMobile) {
      return Adjustments.MOBILE;
    }
    if (isTablet) {
      return Adjustments.TABLET;
    }
    return Adjustments.DESKTOP;
  };

  useEffect(() => {
    const onScrollHandler = () => {
      if (selectedItems > 0 && forwardedRef.current) {
        const scrollTop =
          forwardedRef.current.getBoundingClientRect().top >= 0
            ? forwardedRef.current.getBoundingClientRect().top
            : 0;
        const maxAdjustment = getAdjustments();

        setTopAdjustment(
          scrollTop > maxAdjustment + (additionalTopAdjustment || 0)
            ? scrollTop
            : maxAdjustment + (additionalTopAdjustment || 0),
        );
      }
    };
    onScrollHandler();
    if (forwardedRef && forwardedRef.current) {
      window.addEventListener('scroll', onScrollHandler);
    }
    return () => {
      if (forwardedRef && forwardedRef.current) {
        window.removeEventListener('scroll', onScrollHandler);
      }
    };
  }, [selectedItems, forwardedRef, getAdjustments]);

  return (
    <Box sx={styles.tableTop}>
      {selectedItems > 0 ? (
        <Box sx={styles.checkedTop}>
          <Paper elevation={3} sx={styles.paper}>
            <Box sx={styles.info}>{selectedItemsActionLabel}</Box>
            <Box sx={styles.actions}>{selectedItemsActionComponent}</Box>
          </Paper>
        </Box>
      ) : (
        <Grid container sx={styles.container}>
          <Grid item xs>
            {mainComponent}
          </Grid>
          <Grid item xs sx={styles.actionsAdjusted}>
            {actionComponent}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

MAFTableEnhancedToolbar.propTypes = propTypes;

export default MAFTableEnhancedToolbar;
