"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  disabled: {
    '& .MuiFormControlLabel-label.Mui-disabled': {
      opacity: 0.5,
      color: 'inherit !important'
    }
  }
};