"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PRODUCT_COMMERCIAL_OPERATIONAL_LEASE = exports.CUSTOMER_TYPE_PRIVATE = exports.CUSTOMER_TYPE_COMMERCIAL = void 0;
Object.defineProperty(exports, "PRODUCT_SUB_TYPE_FINANCIAL_LEASE", {
  enumerable: true,
  get: function () {
    return _core.PRODUCT_SUB_TYPE_FINANCIAL_LEASE;
  }
});
Object.defineProperty(exports, "PRODUCT_SUB_TYPE_HIRE_PURCHASE", {
  enumerable: true,
  get: function () {
    return _core.PRODUCT_SUB_TYPE_HIRE_PURCHASE;
  }
});
Object.defineProperty(exports, "PRODUCT_SUB_TYPE_OPERATIONAL_LEASE", {
  enumerable: true,
  get: function () {
    return _core.PRODUCT_SUB_TYPE_OPERATIONAL_LEASE;
  }
});
Object.defineProperty(exports, "PRODUCT_TYPE_COMMERCIAL", {
  enumerable: true,
  get: function () {
    return _core.PRODUCT_TYPE_COMMERCIAL;
  }
});
Object.defineProperty(exports, "PRODUCT_TYPE_PRIVATE", {
  enumerable: true,
  get: function () {
    return _core.PRODUCT_TYPE_PRIVATE;
  }
});
var _core = require("@northstar/core");
const PRODUCT_COMMERCIAL_OPERATIONAL_LEASE = exports.PRODUCT_COMMERCIAL_OPERATIONAL_LEASE = 'COMMERCIAL_OL';
const CUSTOMER_TYPE_PRIVATE = exports.CUSTOMER_TYPE_PRIVATE = 'private';
const CUSTOMER_TYPE_COMMERCIAL = exports.CUSTOMER_TYPE_COMMERCIAL = 'commercial';