import { DomainUtils, CASE_STATUS, DOCUMENT_STATUS } from '@northstar/core';
import { utils } from '@northstar/core-ui';
import {
  PRODUCT_TYPE_PRIVATE,
  app,
  auth,
  userProfile,
  caseDetail,
  cases,
  asset,
  documents as documentsModule,
  DOCUMENT_TYPE_PROOF_OF_DELIVERY,
} from '@northstar/core-ui/modules';
import { objectUtils } from '@northstar/core-ui/utils';
import { callWithAttachers, createApiSaga } from '@northstar/core-ui/utils/redux-saga-utils';
import { delay, call, put, takeLatest, select, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import Paths from 'paths';

import { createAttacherForTermsToUse } from '../vendor/vendorTermsAttachers';
// Asset module
import { clearAssetState } from '../asset/assetReducer';
import {
  prefillAssetDataForConfigurationOL,
  prefillAssetDataForConfigurationHPFL,
} from '../asset/assetSaga';
// Credit module
import { responseMappers as creditResponseMappers } from '../credit/creditMapper';
import { updateApplicants, checkApplicationStatusPollRequest } from '../credit/creditReducer';
import { clearQuoteState } from '../quote/quoteReducer';
// Leasing terms module
import { clearSelectedTerms } from '../vendor/vendorTermsReducer';

import { updateCaseWithAsset } from './caseAssetSaga';
import * as caseAPI from './caseDetailApi';
import { requestMappers } from './caseDetailMapper';
import {
  createCaseRequest,
  createCaseResponse,
  updateGeneralInformationRequest,
  updateGeneralInformationResponse,
  updateCompanyInformationRequest,
  updateCompanyInformationResponse,
  sendPdfRequest,
  sendPdfResponse,
  changeCaseFlowRequest,
  changeCaseFlowResponse,
  setAssetDeliveredRequest,
  setAssetDeliveredResponse,
  createDraftScenarioRequest,
  createDraftScenarioResponse,
  proceedFromCustomerDataGatheringStepRequest,
  proceedFromCustomerDataGatheringStepResponse,
} from './caseDetailReducer';
import {
  selectCase,
  selectCaseContactPerson,
  selectIsCaseOperationalLease,
  selectCanReapplyForCredit,
  selectIsCasePrivate,
  selectIsWaitingForAffordabilityFormUrl,
} from './caseDetailSelectors';
import { clearCaseAssetState } from './caseAssetReducer';

function validateResourceId({ resourceId }) {
  if (!resourceId) {
    throw new Error('errors.case_not_found');
  }
}

export function* createCaseAndRedirect({ payload }) {
  const { redirect, assignAssetId, assetVersionId, vendorTermsId, ...rest } = payload || {};

  yield put(clearQuoteState());
  yield put(clearAssetState());
  yield put(clearCaseAssetState());
  yield put(clearQuoteState());

  try {
    yield call(createCase, { payload: rest });
    yield put(clearSelectedTerms());
    const { resourceId } = yield select(selectCase());

    if (assignAssetId) {
      yield call(updateCaseWithAsset, {
        payload: {
          resourceId,
          id: assignAssetId,
          assetVersionId,
          vendorTermsId,
          isAssetCommercial: rest.productType !== PRODUCT_TYPE_PRIVATE,
          initializeDeliveryDate: true,
        },
      });
    }
    if (typeof redirect === 'function') {
      yield call(redirect, { resourceId });
    }
  } catch (e) {
    yield put(createCaseResponse(e));
    yield put(app.displayError('errors.could_not_create_case'));
  }
}

export function* sendPdf({ payload: { receiver, documents } }) {
  try {
    const requestBodies = documents.map((doc) => ({
      document_type: doc,
      receiver,
    }));
    const { resourceId } = yield select(selectCase());
    yield all(
      requestBodies.map((requestBody) =>
        callWithAttachers({
          endpoint: caseAPI.sendPdf,
          payload: { resourceId, requestBody },
          attachers: [createAttacherForTermsToUse()],
        }),
      ),
    );
    yield put(sendPdfResponse());
    yield put(app.displaySnackbar({ id: 'case.document_sent_success' }));
  } catch (e) {
    yield put(sendPdfResponse(e));
    yield put(app.displayError('errors.could_retrieve_documents'));
  }
}

const getCustomerDetails = ({ firstName, lastName, email, phone, companyName }) =>
  [firstName, lastName, phone, email].filter(Boolean).length >= 2
    ? {
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        company_name: companyName,
      }
    : {};

export function* createCase({ payload }) {
  const { firstName, lastName, phone, email, productType, productSubType, companyName } = payload;

  const employeeDetails = yield select(userProfile.selectUserContactDetails());
  const requestBody = {
    ...getCustomerDetails({ firstName, lastName, phone, email, companyName }),
    employee: objectUtils.objectToSnakeCase({ obj: employeeDetails }),
    product_type: productType,
    product_sub_type: productSubType,
  };
  const response = yield callWithAttachers({
    endpoint: caseAPI.createCase,
    payload: { requestBody },
    attachers: [
      createAttacherForTermsToUse(DomainUtils.getFinancialType({ productType, productSubType })),
    ],
  });
  yield put(createCaseResponse(caseDetail.responseMappers.mapCase(response)));
}

export function* updateCaseCompanyInformation({ payload }) {
  try {
    const { resourceId } = payload;
    const requestBody = requestMappers.mapGeneralInformationForm(payload);
    const caseResource = yield callWithAttachers({
      endpoint: caseAPI.updateCase,
      payload: { resourceId, requestBody },
      attachers: [createAttacherForTermsToUse()],
    });
    const mappedCase = caseDetail.responseMappers.mapCase(caseResource);
    yield put(caseDetail.getCaseResponse(mappedCase));
    yield put(updateCompanyInformationResponse());
  } catch (e) {
    yield put(updateCompanyInformationResponse(e));
    yield put(app.displayError('errors.could_not_update_case'));
  }
}

export function* updateCaseGeneralInformation({ payload }) {
  try {
    const { includeAction, draftScenarioId, referenceNumber, ...formValues } = payload;
    const { resourceId, creditApplicationStatus, proofOfDeliveryPaperStatus } = yield select(
      selectCase(),
    );
    const isOperationalLease = yield select(selectIsCaseOperationalLease());
    if (referenceNumber) {
      yield call(setReferenceNumber, {
        resourceId,
        referenceNumber,
        endpoint: isOperationalLease
          ? caseAPI.setReferenceNumberPROL
          : caseAPI.setReferenceNumberPRHP,
      });
      if (
        proofOfDeliveryPaperStatus === DOCUMENT_STATUS.AWAITING_SIGNING ||
        proofOfDeliveryPaperStatus === DOCUMENT_STATUS.SIGNED
      ) {
        yield put(updateGeneralInformationResponse());
        return;
      }
    }
    const hasAppliedForCredit = Boolean(creditApplicationStatus);
    const requestBody = requestMappers.mapGeneralInformationForm({
      ...formValues,
      draftScenarioId,
      hasAppliedForCredit,
    });

    const plateNumber = requestBody.asset?.registration_number;
    if (isOperationalLease && plateNumber) {
      try {
        const carInfoResponse = yield call(asset.getCarInfo, {
          plateNumber,
        });
        requestBody.asset.vin_number = carInfoResponse.vin;
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        requestBody.asset.vin_number ||= '';
      }
    }

    const { firstName, lastName } = yield select(selectCaseContactPerson());
    const body = draftScenarioId
      ? {
          ...requestBody,
          applicant: { ...requestBody.applicant, first_name: firstName, last_name: lastName },
        }
      : requestBody;
    const caseResource = yield callWithAttachers({
      endpoint: caseAPI.updateCase,
      payload: {
        resourceId,
        requestBody: body,
      },
      attachers: [createAttacherForTermsToUse()],
    });
    const mappedCase = caseDetail.responseMappers.mapCase(caseResource);
    yield put(caseDetail.getCaseResponse(mappedCase));
    yield put(updateGeneralInformationResponse());
    const { applicant } = mappedCase;
    yield put(updateApplicants(creditResponseMappers.mapApplicants(applicant)));
    const { proofOfDeliveryPaper } = yield select(documentsModule.selectPdfs());
    if (proofOfDeliveryPaper) {
      yield put(
        documentsModule.getDocumentResponse({
          type: DOCUMENT_TYPE_PROOF_OF_DELIVERY,
          content: '',
        }),
      );
    }
    if (typeof includeAction === 'function') {
      yield call(includeAction);
    }
  } catch (e) {
    yield put(updateGeneralInformationResponse(e));
    yield put(app.displayError('errors.could_not_update_case'));
  }
}

export function* changeCaseFlow({ payload }) {
  try {
    const { resourceId, productSubType, redirect } = payload;
    yield callWithAttachers({
      endpoint: caseAPI.swithCaseFlow,
      payload: {
        resourceId,
        requestBody: { new_product_sub_type: productSubType },
      },
      attachers: [createAttacherForTermsToUse()],
    });
    if (typeof redirect === 'function') {
      yield call(redirect, { resourceId });
    }
    yield put(changeCaseFlowResponse({ productSubType }));
  } catch (e) {
    yield put(changeCaseFlowResponse(e));
  }
}

export function* getCase({ payload }) {
  try {
    validateResourceId(payload);
    const mappedCase = yield call(caseDetail.getCaseGenerator, { payload });
    yield put(caseDetail.getCaseResponse(mappedCase));

    yield call(checkCaseResourcePolling, { payload: { ...payload, mappedCase } });
  } catch (e) {
    yield put(caseDetail.getCaseResponse(e));
    yield put(app.displayError('errors.could_not_get_case'));
  }
}

export function* checkCaseResourcePolling({ payload }) {
  const {
    withCreditPollCheck,
    withAffordabilityFormUrlPoll,
    withContractProcessedPollCheck,
    mappedCase,
  } = payload;
  if (withCreditPollCheck) {
    const caseStatus = utils.caseStatuses.getStatusForCase(mappedCase).name;
    if (caseStatus === CASE_STATUS.CREDIT_AWAITING_AUTOMATIC_DECISION) {
      yield put(
        checkApplicationStatusPollRequest({
          resourceId: mappedCase.resourceId,
        }),
      );
    }
  }

  if (withAffordabilityFormUrlPoll) {
    const isWaitingForAffordabilityFormUrl = yield select(selectIsWaitingForAffordabilityFormUrl());
    if (isWaitingForAffordabilityFormUrl) {
      yield call(checkAffordabilityFormUrlPoll, {
        payload: { resourceId: mappedCase.resourceId },
      });
    }
  }

  if (withContractProcessedPollCheck) {
    const isCasePrivate = yield select(selectIsCasePrivate());
    const canReapplyForCredit = yield select(selectCanReapplyForCredit());
    if (isCasePrivate && canReapplyForCredit && !mappedCase.contractProcessed) {
      yield call(checkContractProcessedPoll, {
        payload: {
          resourceId: mappedCase.resourceId,
        },
      });
    }
  }
}

export function* checkAffordabilityFormUrlPoll({ payload: { resourceId } }) {
  while (true) {
    const mappedCase = yield call(caseDetail.getCaseGenerator, {
      payload: { resourceId, skip: true },
    });
    yield put(caseDetail.getCaseResponse(mappedCase));

    const isWaitingForAffordabilityFormUrl = yield select(selectIsWaitingForAffordabilityFormUrl());
    if (!isWaitingForAffordabilityFormUrl) break;
    yield delay(10 * 1000);
  }
}

export function* checkContractProcessedPoll({ payload: { resourceId } }) {
  while (true) {
    const mappedCase = yield call(caseDetail.getCaseGenerator, {
      payload: { resourceId, skip: true },
    });
    const { contractProcessed } = mappedCase;
    const canReapplyForCredit = yield select(selectCanReapplyForCredit());
    if (!canReapplyForCredit || contractProcessed) {
      yield put(caseDetail.getCaseResponse(mappedCase));
      break;
    }
    yield delay(20 * 1000);
  }
}

export function* setAssetDelivered({ payload }) {
  try {
    validateResourceId(payload);
    const { resourceId } = payload;
    yield callWithAttachers({
      endpoint: caseAPI.setAssetDelivered,
      payload: { resourceId },
      attachers: [createAttacherForTermsToUse()],
    });
    yield put(setAssetDeliveredResponse());
    yield put(caseDetail.getCaseRequest({ resourceId }));
  } catch (e) {
    yield put(setAssetDeliveredResponse(e));
  }
}

export function* deleteAndGetCase({ payload }) {
  try {
    const { resourceId } = payload;
    yield call(cases.deleteCaseSaga, { payload });
    yield put(caseDetail.getCaseRequest({ resourceId }));
    yield put(cases.deleteAndGetCaseResponse());
    yield put(app.displaySnackbar({ id: 'case.delete_success' }));
  } catch (e) {
    yield put(app.displayError('errors.could_not_delete_case'));
    yield put(cases.deleteAndGetCaseResponse(e));
  }
}

export function* restoreAndGetCase({ payload }) {
  try {
    const { resourceId } = payload;
    yield call(cases.restoreCaseSaga, { payload });
    yield put(caseDetail.getCaseRequest({ resourceId }));
    yield put(cases.restoreAndGetCaseResponse());
    yield put(app.displaySnackbar({ id: 'case.restore_success' }));
  } catch (e) {
    yield put(app.displayError('errors.could_not_restore_case'));
    yield put(cases.restoreAndGetCaseResponse(e));
  }
}

export function* updateImporter() {
  try {
    const isVendorTypeDealership = yield select(auth.selectIsVendorTypeDealership());
    const importers = yield select(auth.selectImporters());
    if (isVendorTypeDealership && importers.length) {
      const { resourceId } = yield select(selectCase());
      yield callWithAttachers({
        endpoint: caseAPI.updateCaseImporter,
        payload: { resourceId },
        attachers: [createAttacherForTermsToUse()],
      });
    }
  } catch (e) {
    yield put(app.displayError(e));
  }
}

export function* setReferenceNumber({ resourceId, referenceNumber, endpoint }) {
  try {
    const response = yield callWithAttachers({
      endpoint,
      payload: { resourceId, requestBody: { referenceNumber } },
      attachers: [createAttacherForTermsToUse()],
    });
    yield put(caseDetail.getCaseResponse(caseDetail.responseMappers.mapCase(response)));
  } catch (e) {
    yield put(app.displayError(e));
  }
}

export function* createDraftScenario({ payload }) {
  try {
    const { resourceId, isCasePROL } = payload;
    const { draftScenarioId } = yield callWithAttachers({
      endpoint: caseAPI.createDraftScenario,
      payload: { resourceId },
      attachers: [createAttacherForTermsToUse()],
    });
    const prefillAssetDataForConfigurationAction = isCasePROL
      ? prefillAssetDataForConfigurationOL
      : prefillAssetDataForConfigurationHPFL;
    yield call(prefillAssetDataForConfigurationAction, { payload: { getTerms: true } });
    const path = isCasePROL ? Paths.CAR_CONFIGURATION_PR_OL : Paths.CAR_CONFIGURATION_PR_HP;
    yield put(push(path.buildPath({ resourceId, draftScenarioId })));
    yield put(createDraftScenarioResponse());
  } catch (e) {
    yield put(createDraftScenarioResponse(e));
  }
}

export const proceedFromCustomerDataGatheringStep = createApiSaga({
  endpoint: caseAPI.proceedCustomerDataGathering,
  responseMapper: caseDetail.responseMappers.mapCase,
  successActions: [proceedFromCustomerDataGatheringStepResponse],
  errorActions: [app.displayError, proceedFromCustomerDataGatheringStepResponse],
});

export default function* caseSaga() {
  yield takeLatest(caseDetail.getCaseRequest, getCase);
  yield takeLatest(createCaseRequest, createCaseAndRedirect);
  yield takeLatest(updateGeneralInformationRequest, updateCaseGeneralInformation);
  yield takeLatest(updateCompanyInformationRequest, updateCaseCompanyInformation);
  yield takeLatest(sendPdfRequest, sendPdf);
  yield takeLatest(changeCaseFlowRequest, changeCaseFlow);
  yield takeLatest(setAssetDeliveredRequest, setAssetDelivered);
  yield takeLatest(cases.deleteAndGetCaseRequest, deleteAndGetCase);
  yield takeLatest(cases.restoreAndGetCaseRequest, restoreAndGetCase);
  yield takeLatest(createDraftScenarioRequest, createDraftScenario);
  yield takeLatest(
    proceedFromCustomerDataGatheringStepRequest,
    proceedFromCustomerDataGatheringStep,
  );
}
