"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const getBorder = colors => "1px solid ".concat(colors.solid.gainsboro);
const cellPadding = '4px 12px';
const styles = exports.styles = {
  invisibleRow: {
    height: 0
  },
  row: _ref => {
    let {
      colors
    } = _ref;
    return {
      borderLeft: getBorder(colors),
      borderRight: getBorder(colors),
      '&:last-child': {
        borderBottom: getBorder(colors)
      },
      '&:hover:not(:first-child)': {
        background: colors.alpha(colors.solid.black, 0.05)
      }
    };
  },
  label: _ref2 => {
    let {
      typography,
      fontSizes,
      colors
    } = _ref2;
    return {
      fontFamily: typography.main,
      padding: 0,
      width: 'auto',
      textAlign: 'center',
      fontSize: fontSizes.small,
      border: getBorder(colors),
      '&:last-child': {
        padding: 0
      }
    };
  },
  header: _ref3 => {
    let {
      typography,
      fontSizes
    } = _ref3;
    return {
      fontFamily: typography.main,
      padding: cellPadding,
      maxWidth: '60px',
      border: 'none',
      fontSize: fontSizes.extraSmall,
      fontWeight: 'bold'
    };
  },
  cell: _ref4 => {
    let {
      colors
    } = _ref4;
    return {
      textAlign: 'center',
      padding: cellPadding,
      borderBottom: 'none',
      borderRight: getBorder(colors),
      borderLeft: getBorder(colors),
      maxWidth: '30px',
      '&:hover': {
        background: "".concat(colors.alpha(colors.custom.background.background, 0.5), " !important")
      }
    };
  },
  cellText: {
    margin: '10px'
  }
};