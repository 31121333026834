import { FC, ReactNode } from 'react';

import MAFSpinner, { IMAFSpinnerProps } from './MAFSpinner';
import { propTypes } from './MAFSpinnerOrComponent.propTypes';

export interface IMAFSpinnerOrComponentProps extends IMAFSpinnerProps {
  isLoading: boolean;
  component: ReactNode;
}

const MAFSpinnerOrComponent: FC<IMAFSpinnerOrComponentProps> = ({
  isLoading,
  component,
  containerClass,
  iconClass,
  color,
  ...rest
}) =>
  isLoading ? (
    <MAFSpinner
      ns-target="spinner-loading"
      containerClass={containerClass}
      iconClass={iconClass}
      color={color}
      {...rest}
    />
  ) : (
    <>{component}</>
  );

MAFSpinnerOrComponent.propTypes = propTypes;

export default MAFSpinnerOrComponent;
