import { FC } from 'react';
import { Box, SxProps, Theme } from '@mui/material';

import { handleCustomSx } from '../../utils';

import { styles } from './MAFDanskeBankLogo.styles';
import LogoDB from './danske_bank_logo_DB.png';
import LogoLB from './danske_bank_logo_LB.png';
import { propTypes } from './MAFDanskeBankLogo.propTypes';

export interface IMAFDanskeBankLogoProps {
  sx?: SxProps<Theme>;
  isDarkBackground?: boolean;
}

const MAFDanskeBankLogo: FC<IMAFDanskeBankLogoProps> = ({ sx, isDarkBackground }) => (
  <Box
    component="img"
    alt=""
    src={isDarkBackground ? LogoDB : LogoLB}
    sx={[styles.image, ...handleCustomSx(sx)]}
  />
);

MAFDanskeBankLogo.propTypes = propTypes;

export default MAFDanskeBankLogo;
