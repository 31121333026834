const ProofOfDeliveryExpired: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="38px"
    height="27px"
    viewBox="0 0 38 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="Screens"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <g
        id="07-Case-detail-all-statuses-assets"
        transform="translate(-477.000000, -549.000000)"
        stroke="#FFFFFF"
        strokeWidth="2.25"
      >
        <g id="Group-18" transform="translate(478.000000, 551.000000)">
          <path d="M0.449442987,13.125 L11.2360747,13.125" id="Line-Copy-18"></path>
          <path d="M4.88677614,8.5 L12.55,8.5" id="Line-Copy-20"></path>
          <path
            d="M12.1592103,2.54145682e-16 L33.6804051,0 C34.5088322,-2.35955288e-15 35.1804051,0.671572875 35.1804051,1.5 L35.1804051,21.5 C35.1804051,22.3284271 34.5088322,23 33.6804051,23 L8,23"
            id="Rectangle-15-Copy-6"
          ></path>
          <polyline
            id="Path-11-Copy"
            strokeLinejoin="round"
            points="26.990364 21.474383 29.5231953 16.740395 25 13.1603945 29.2016337 7"
          ></polyline>
        </g>
      </g>
    </g>
  </svg>
);

export default ProofOfDeliveryExpired;
