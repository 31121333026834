import { vendorConfig } from '@northstar/core-ui';
import { objectUtils, translationUtils } from '@northstar/core-ui/utils';
import { camelToSnake } from '@northstar/core/utils/StringUtils';

import { mapCountriesToString } from '../kyc/kyc-utils';

const mapSingleApplicant = ({ externalCustomerId, firstName, lastName, email, phone }) => ({
  external_customer_id: externalCustomerId,
  contact_person_details: {
    first_name: firstName,
    last_name: lastName,
    email,
    phone: phone || null,
  },
});

const mapDownPaymentData = ({ downpaymentSourceSavings, otherDownpaymentSource }) => ({
  downpayment_source_savings: downpaymentSourceSavings === 'true',
  other_downpayment_source: otherDownpaymentSource || null,
});

const mapApplicantPrivateToRequest = ({ mainApplicant, coApplicant, downPayment }) => ({
  applicant_details: mapSingleApplicant(mainApplicant),
  co_applicant_details: coApplicant ? mapSingleApplicant(coApplicant) : null,
  down_payment_data_dto: downPayment ? mapDownPaymentData(downPayment) : null,
});

const mapApplicant = (applicant) => {
  if (!applicant) {
    return {
      contactPerson: {},
      company: {},
    };
  }
  const { contactPersonDetails, companyDetails, ...generalApplicantDetails } = applicant;
  return {
    contactPerson: contactPersonDetails,
    company: {
      name: companyDetails.companyName,
      number: generalApplicantDetails.externalCustomerId,
      address: companyDetails.companyAddress,
    },
    ...generalApplicantDetails,
  };
};

const mapApplicants = (applicants) => {
  const { applicantDetails, coApplicantDetails } = objectUtils.objectToCamelCase({
    obj: applicants,
  });
  return {
    applicant: mapApplicant(applicantDetails),
    coApplicant: mapApplicant(coApplicantDetails),
  };
};

const getDownPaymentOption = (downPayment) => [
  translationUtils.getTranslationByKey({
    locale: vendorConfig.DEFAULT_LOCALE,
    key: `credit.${camelToSnake(downPayment)}`,
  }) || downPayment,
];

const mapApplicantSTToRequest = ({
  externalCustomerId,
  nationalities,
  email,
  phone,
  isSweden,
  countryOfResidence,
  downPaymentSource,
  isPep,
}) => ({
  applicant_details: {
    external_customer_id: externalCustomerId,
    nationalities: nationalities.map(({ value }) => value),
    contact_person_details: {
      email,
      phone,
    },
    sole_trader_details: {
      applicant_residence:
        isSweden === 'false' ? countryOfResidence.key : mapCountriesToString(nationalities),
      downpayment_come_from: downPaymentSource ? getDownPaymentOption(downPaymentSource) : [],
      pep: isPep,
    },
  },
});

const mapApplicantSMEToRequest = (applicant) => {
  const { company, contactPerson, downPayment } = applicant;

  return {
    company_name: company.name,
    external_customer_id: company.number,
    company_address: company.address,
    downpayment_come_from: downPayment ? getDownPaymentOption(downPayment.downPaymentSource) : null,
    contact_person_details: {
      first_name: contactPerson.firstName,
      last_name: contactPerson.lastName,
      email: contactPerson.email,
      phone: contactPerson.phone,
    },
  };
};

const mapToApplicantFromFullnameAndSSN = ({
  externalCustomerId,
  first_name: firstName,
  last_name: lastName,
  address: companyAddress,
}) => ({
  externalCustomerId,
  contactPerson: {
    firstName,
    lastName,
  },
  companyAddress,
});

export const requestMappers = {
  mapApplicantPrivate: mapApplicantPrivateToRequest,
  mapApplicantSME: mapApplicantSMEToRequest,
  mapApplicantST: mapApplicantSTToRequest,
};

export const responseMappers = {
  mapApplicants,
  mapToApplicantFromFullnameAndSSN,
};
