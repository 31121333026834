const Info: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 7 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-HD" transform="translate(-1237.000000, -1005.000000)">
        <g id="Group" transform="translate(1228.000000, 1003.000000)">
          <path
            d="M11.1961933,3.61054173 C11.1961933,3.16153997 11.3523654,2.78087028 11.6647145,2.46852123 C11.9770635,2.15617218 12.3577332,2 12.806735,2 C13.2557367,2 13.636408,2.15617218 13.9487555,2.46852123 C14.2611045,2.78087028 14.4172767,3.16153997 14.4172767,3.61054173 C14.4172767,4.05954348 14.2611045,4.44021474 13.9487555,4.75256223 C13.636408,5.06491127 13.2557367,5.22108346 12.806735,5.22108346 C12.3577332,5.22108346 11.9770635,5.06491127 11.6647145,4.75256223 C11.3523654,4.44021474 11.1961933,4.05954348 11.1961933,3.61054173 Z M11.6061493,18.1054173 C11.4499748,18.983899 11.4158122,19.5597839 11.5036603,19.8330893 C11.5915085,20.1063947 11.8208864,20.2430454 12.1918009,20.2430454 C12.5822372,20.2430454 12.9580265,20.0771124 13.3191801,19.7452416 C13.6803337,19.4133707 14.0854053,18.9643757 14.534407,18.398243 L15.3543192,18.9838946 C14.7686647,19.9599854 14.1781387,20.7066836 13.5827233,21.2240117 C12.9873079,21.7413398 12.2308489,22 11.3133236,22 C10.5714946,22 10.0053704,21.7999044 9.61493411,21.3997072 C9.2244978,20.99951 9.11712943,20.2723332 9.29282577,19.2181552 L10.8740849,9.64275256 L9,9.64275256 L9.17569546,8.61786237 L13.2459736,7.9443631 L11.6061493,18.1054173 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Info;
