export const CUSTOMER_KYC_FORM_NAME = 'customerFacingKYC';
export const WELCOME = 'welcome';
export const LEASING_CONTRACT_SIGNEES = 'leasingContractSignees';
export const OWNERS = 'owners';
export const LEGAL_ENTITIES = 'legalEntities';
export const SHAREHOLDERS = 'shareholders';
export const CITIZENSHIPS = 'citizenships';
export const PEP = 'pep';
export const INDUSTRY = 'industry';
export const CUSTOMER_TYPES = 'customerTypes';
export const SALES_CHANNELS = 'salesChannels';
export const SUPPLIERS = 'suppliers';
export const PROOF_OF_DELIVERY_SIGNEES = 'proofOfDeliverySignees';
export const SUMMARY = 'summary';
export const ADDED_PREFIX = 'added';
export const SELECTED_PREFIX = 'selected';
export const POSITION_OWNER = 'beneficial_owner';
export const POSITION_SIGNEE = 'company_signee';
export const KYC_OTHER = 'otherField';
