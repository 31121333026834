"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayUtils = exports.forceConvertToArray = exports.hasSameMembers = exports.ObjectUtils = void 0;
var tslib_1 = require("tslib");
var StringUtils_1 = require("./StringUtils");
var isNullOrEmpty = function (value) {
    return typeof value === 'number'
        ? isNullOrUndefined(value)
        : isNullOrUndefined(value) || Object.keys(value).length === 0;
};
var isNullOrUndefined = function (value) { return typeof value === 'undefined' || value === null; };
var deepEquals = function (firstObj, secondObj) {
    var firstType = typeof firstObj;
    var secondType = typeof secondObj;
    if (firstType !== secondType) {
        return false;
    }
    switch (firstType) {
        case 'object': {
            if (!firstObj && !secondObj) {
                return StringUtils_1.default.normalizeValue(firstObj) === StringUtils_1.default.normalizeValue(secondObj);
            }
            if (Array.isArray(firstObj) && Array.isArray(secondObj)) {
                return exports.hasSameMembers(firstObj, secondObj);
            }
            var firstKeys = Object.keys(firstObj);
            var secondKeys = Object.keys(secondObj);
            if (!exports.hasSameMembers(firstKeys, secondKeys)) {
                return false;
            }
            return !firstKeys.some(function (key) { return !deepEquals(firstObj[key], secondObj[key]); });
        }
        default: {
            return StringUtils_1.default.normalizeValue(firstObj) === StringUtils_1.default.normalizeValue(secondObj);
        }
    }
};
var serialize = function (data) {
    if (isNullOrEmpty(data)) {
        return '';
    }
    var filteredKeys = Object.keys(data).filter(function (key) {
        var element = data[key];
        return Array.isArray(element) ? element.length > 0 : !isNullOrUndefined(element);
    });
    if (filteredKeys.length === 0) {
        return '';
    }
    var mappedQueryParams = filteredKeys
        .filter(function (key) { return data[key]; })
        .map(function (key) {
        return Array.isArray(data[key])
            ? data[key]
                .map(function (element) { return encodeURIComponent(key) + "=" + encodeURIComponent(element); })
                .join('&')
            : encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
    })
        .join('&');
    return "?" + mappedQueryParams;
};
var isObject = function (item) { return item && typeof item === 'object' && !Array.isArray(item); };
var mergeDeep = function (target, source) {
    var output = tslib_1.__assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(function (key) {
            var _a, _b, _c;
            if (isObject(source[key])) {
                if (!(key in target))
                    Object.assign(output, (_a = {}, _a[key] = source[key], _a));
                else
                    output[key] = mergeDeep(target[key], source[key]);
            }
            else {
                var isArray = Array.isArray(source[key]);
                if (isArray) {
                    var targetValue = target[key] || [];
                    Object.assign(output, (_b = {},
                        _b[key] = tslib_1.__spreadArray([], Array.from(new Set(tslib_1.__spreadArray(tslib_1.__spreadArray([], targetValue), source[key])))),
                        _b));
                }
                else {
                    Object.assign(output, (_c = {},
                        _c[key] = source[key],
                        _c));
                }
            }
        });
    }
    return output;
};
exports.ObjectUtils = {
    mergeDeep: mergeDeep,
    isNullOrEmpty: isNullOrEmpty,
    isNullOrUndefined: isNullOrUndefined,
    deepEquals: deepEquals,
    serialize: serialize,
};
var containsAll = function (firstArray, secondArray) {
    return secondArray.every(function (secondItem) {
        return firstArray.find(function (firstItem) { return deepEquals(firstItem, secondItem); });
    });
};
var hasSameMembers = function (firstArray, secondArray) {
    return containsAll(firstArray, secondArray) && containsAll(secondArray, firstArray);
};
exports.hasSameMembers = hasSameMembers;
var containsNormalizedValue = function (array, value) {
    return array.some(function (arrayValue) { return StringUtils_1.default.normalizeValue(arrayValue) === StringUtils_1.default.normalizeValue(value); });
};
var isEmptyOrContainsValue = function (array, value) {
    return isNullOrEmpty(array) || array.some(function (arrayValue) { return arrayValue === value; });
};
var forEachAsync = function (iterableObject, fn) { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
    var array, _i, array_1, t;
    return tslib_1.__generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                array = Object.values(iterableObject);
                _i = 0, array_1 = array;
                _a.label = 1;
            case 1:
                if (!(_i < array_1.length)) return [3, 4];
                t = array_1[_i];
                return [4, fn(t)];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                _i++;
                return [3, 1];
            case 4: return [2];
        }
    });
}); };
var forEachAsyncParallel = function (iterableObject, fn) { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
    var array;
    return tslib_1.__generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                array = Object.values(iterableObject);
                return [4, Promise.all(array.map(fn))];
            case 1:
                _a.sent();
                return [2];
        }
    });
}); };
var forceConvertToArray = function (value) {
    return value && !Array.isArray(value) ? [value] : value;
};
exports.forceConvertToArray = forceConvertToArray;
exports.ArrayUtils = {
    containsNormalizedValue: containsNormalizedValue,
    isEmptyOrContainsValue: isEmptyOrContainsValue,
    forEachAsync: forEachAsync,
    forEachAsyncParallel: forEachAsyncParallel,
    forceConvertToArray: exports.forceConvertToArray,
};
exports.default = {
    ObjectUtils: exports.ObjectUtils,
    ArrayUtils: exports.ArrayUtils,
};
