"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  dialogRoot: {
    '&.MuiDialog-root': {
      padding: '15px'
    }
  }
};