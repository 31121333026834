import { Box, SxProps, Theme } from '@mui/material';
import { ReactChild } from 'react';
import { MAFTypography } from '@asfi/maf';

import { styles } from './assetHeader.styles';

interface IAssetHeaderProps {
  title: ReactChild;
  subTitle: ReactChild;
  sx: SxProps<Theme>;
  image: ReactChild;
}

const AssetHeader: React.FC<IAssetHeaderProps> = ({ title, subTitle, sx, image }) => (
  <Box sx={sx}>
    {image}
    <MAFTypography variant="h1-italic" isWithContainer>
      {title}
    </MAFTypography>
    <MAFTypography variant="body1" isWithContainer sx={styles.subtitle}>
      {subTitle}
    </MAFTypography>
  </Box>
);

export default AssetHeader;
