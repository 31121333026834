import { FC, ReactNode, ReactChild, MouseEvent } from 'react';
import { TableCell, Grid, Box, SxProps, Theme } from '@mui/material';

import MAFTypography from '../../../MAFTypography/MAFTypography';
import { handleCustomSx } from '../../../../utils';

import { styles } from './DeprecatedMAFCellMobile.styles';
import { propTypes } from './DeprecatedMAFCellMobile.propTypes';

interface IMAFCellMobileProps {
  children: ReactNode;
  label?: ReactChild;
  shouldStopPropagation?: boolean;
  sx?: SxProps<Theme>;
}

const DeprecatedMAFCellMobile: FC<IMAFCellMobileProps> = ({
  children,
  label,
  shouldStopPropagation,
  sx,
}) => {
  const rootClasses = [
    styles.root,
    !shouldStopPropagation && styles.cellMobile,
    ...handleCustomSx(sx),
  ];

  const propagationStop = (e: MouseEvent) => e.stopPropagation();

  return (
    <TableCell
      sx={[...rootClasses, styles.cellTypeBody]}
      onClick={shouldStopPropagation ? propagationStop : undefined}
    >
      <Grid container direction="column" sx={styles.cell}>
        {!shouldStopPropagation && (
          <Grid item sx={styles.contentHeader}>
            <MAFTypography variant="label2">{label}</MAFTypography>
          </Grid>
        )}

        <Grid item>
          <Box sx={styles.content}>{children}</Box>
        </Grid>
      </Grid>
    </TableCell>
  );
};

DeprecatedMAFCellMobile.propTypes = propTypes;

export default DeprecatedMAFCellMobile;
