import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  image: {
    width: 136,
    imageRendering: '-webkit-optimize-contrast',
    '@media not all and (min-resolution:.001dpcm)': {
      height: 'intrinsic',
    },
  },
};
