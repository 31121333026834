import { LEGAL_ENTITY_SMALL_MEDIUM_ENTERPRISE } from '@northstar/core-ui/modules';
import { createSelector } from 'reselect';

import { selectAsset } from '../asset/assetSelectors';
import { selectSelectedEquipmentWithServices } from '../asset/equipmentSelectors';
import { selectCase } from '../caseDetail/caseDetailSelectors';
import { selectQuoteDataOL } from '../quote/quoteSelectors';
import { selectSelectedTerms } from '../vendor/vendorTermsSelectors';

const selectCredit = (state) => state.credit;

export const selectDebtor = () =>
  createSelector(selectCredit, (creditState) => creditState.get('debtor').toJS());

export const selectCoDebtor = () =>
  createSelector(selectCredit, (creditState) => creditState.get('coDebtor').toJS());

export const selectPrefillDebtor = () =>
  createSelector(selectCredit, (creditState) => creditState.get('prefillDebtor').toJS());

export const selectPrefillCoDebtor = () =>
  createSelector(selectCredit, (creditState) => creditState.get('prefillCoDebtor').toJS());

export const selectStep = () =>
  createSelector(selectCredit, (creditState) => creditState.get('step'));

export const selectCurrentLegalEntity = () =>
  createSelector(selectCredit, (creditState) => creditState.get('currentLegalEntity'));

export const selectDecisionPropsForHp = () =>
  createSelector(selectDecisionProps(), selectCase(), (decitionProps, currentCase) => ({
    ...decitionProps,
    period: currentCase.asset ? currentCase.asset.leasePeriod : 0,
  }));

export const selectDecisionProps = () =>
  createSelector(
    selectCredit,
    selectAsset(),
    selectSelectedTerms(),
    selectCase(),
    selectQuoteDataOL(),
    selectSelectedEquipmentWithServices(),
    (creditState, car, { period, mileage }, currentCase, quoteData, { optional, services }) => ({
      currentCase,
      debtor: creditState.get('debtor').toJS(),
      car,
      period,
      mileage,
      fee: quoteData.quote,
      optional,
      services,
      quoteData,
    }),
  );

export const selectError = () =>
  createSelector(selectCredit, (creditState) => creditState.get('error'));

export const selectIsWaitingForCreditDecision = () =>
  createSelector(selectCredit, (creditState) => creditState.get('error'));

export const selectIsSME = () =>
  createSelector(
    selectDebtor(),
    (debtor) => debtor.legalEntity === LEGAL_ENTITY_SMALL_MEDIUM_ENTERPRISE,
  );

export const selectIsFetchingCustomerInformation = () =>
  createSelector(selectCredit, (creditState) => creditState.get('fetchingCustomerInformation'));

export const selectIsWithCoApplicant = () =>
  createSelector(selectCredit, (creditState) => creditState.get('isWithCoApplicant'));

export const selectIsResourceWaitingForDecision = () =>
  createSelector(selectCredit, (creditState) => creditState.get('resourceWaitingForDecision'));

export const selectCheckingIfCreditDecisionExpired = () =>
  createSelector(selectCredit, (creditState) => creditState.get('checkingIfCreditDecisionExpired'));

export const selectIsCreditDecisionExpired = () =>
  createSelector(selectCredit, (creditState) => creditState.get('isCreditDecisionExpired'));

export const selectIsUpdatingApplicants = () =>
  createSelector(selectCredit, (creditState) => creditState.get('isUpdatingApplicants'));
