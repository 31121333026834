import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  wrapper: ({ colors, breakpoints }) => ({
    color: colors.custom.fontPrimary,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [breakpoints.down('md')]: {
      textAlign: 'center',
    },
  }),
  title: {
    lineHeight: '19px',
    paddingBottom: '5px',
    fontWeight: 600,
  },
  titleSecondary: {
    opacity: 0.5,
  },
  value: ({ typography, fontSizes: { extraSmall } }) => ({
    fontWeight: 500,
    marginTop: 'auto',
    fontFamily: typography.main,
    fontSize: extraSmall,
  }),
  valueSecondary: {
    fontWeight: 600,
  },
  valueNumber: ({ typography }) => ({
    fontFamily: typography.main,
    fontWeight: 600,
  }),
  withEllipsis: {
    textOverflow: 'ellipsis',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};
