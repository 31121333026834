import { FC, ReactNode } from 'react';
import { SxProps, TableRow, Theme } from '@mui/material';

import { handleCustomSx } from '../../../../utils';

import { styles } from './DeprecatedMAFTableRow.styles';
import { propTypes } from './DeprecatedMAFTableRow.propTypes';

interface IMAFTableRowProps {
  children: ReactNode;
  isUplift?: boolean;
  isHead?: boolean;
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

const DeprecatedMAFTableRow: FC<IMAFTableRowProps> = ({
  children,
  isUplift = false,
  isHead = false,
  onClick,
  sx,
}) => {
  const rootClasses = [
    isHead && styles.headTableRow,
    isHead && isUplift && styles.headTableRowUplift,
    !isHead && styles.hoverableRow,
    !isHead && isUplift && styles.hoverableRowUplift,
    Boolean(onClick) && styles.clickable,
    ...handleCustomSx(sx),
  ];

  return (
    <TableRow onClick={onClick} sx={rootClasses}>
      {children}
    </TableRow>
  );
};

DeprecatedMAFTableRow.propTypes = propTypes;

export default DeprecatedMAFTableRow;
