import { ReactNode, FC, MouseEventHandler, ReactChild, MouseEvent } from 'react';
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogContentProps,
  DialogContentText,
  Backdrop,
  Zoom,
  Box,
  SxProps,
  Theme,
} from '@mui/material';

import Close from '../Icons/Close';
import MAFIcon from '../MAFIcon/MAFIcon';
import MAFSpinner from '../MAFSpinner/MAFSpinner';
import MAFTypography from '../MAFTypography/MAFTypography';
import { handleCustomSx } from '../../utils';

import { styles } from './MAFDialog.styles';
import { propTypes } from './MAFDialog.propTypes';

export type TMAFDialogProps = Omit<DialogProps, 'title' | 'onClose'>;

export interface IMAFDialogProps extends TMAFDialogProps {
  title?: ReactChild;
  message?: ReactChild;
  content?: ReactNode;
  children?: ReactNode;
  isLoading?: boolean;
  dialogContentProps?: DialogContentProps;
  titleClassname?: SxProps<Theme>;
  contentRootClassname?: SxProps<Theme>;
  shouldDisableBackdropClick?: boolean;
  onClose?: () => void;
  header?: ReactNode;
  sx?: SxProps<Theme>;
}

const MAFDialog: FC<IMAFDialogProps> = ({
  title,
  message,
  content,
  children,
  isLoading = false,
  open: isOpen,
  dialogContentProps = {},
  titleClassname,
  contentRootClassname,
  onClose,
  shouldDisableBackdropClick,
  header,
  sx,
  ...props
}) => {
  const handleOnClose = (_: MouseEvent<HTMLDivElement>, reason: string) => {
    if (onClose && (!shouldDisableBackdropClick || reason !== 'backdropClick')) {
      onClose();
    }
  };

  return (
    <Dialog
      {...props}
      onClose={handleOnClose}
      open={Boolean(isOpen)}
      TransitionComponent={Zoom}
      sx={[styles.root, ...handleCustomSx(sx)]}
    >
      <DialogContent
        sx={[styles.contentRoot, ...handleCustomSx(contentRootClassname)]}
        {...dialogContentProps}
      >
        <Backdrop open={isLoading} sx={styles.loading}>
          <MAFSpinner ns-target="dialog-isLoading" />
        </Backdrop>
        {header}
        {title && (
          <MAFTypography
            variant="h2-italic"
            sx={[styles.title, ...handleCustomSx(titleClassname)]}
            isWithContainer
          >
            {title}
          </MAFTypography>
        )}

        <Box
          component="span"
          sx={styles.closeBtn}
          tabIndex={0}
          role="button"
          aria-pressed="false"
          onClick={onClose as MouseEventHandler<HTMLButtonElement>}
        >
          <MAFIcon Icon={Close} />
        </Box>
        {content}
        {!content && (
          <DialogContentText>
            <MAFTypography variant="body1">{message}</MAFTypography>
          </DialogContentText>
        )}

        {children && <Box sx={styles.actionRoot}>{children}</Box>}
      </DialogContent>
    </Dialog>
  );
};

MAFDialog.propTypes = propTypes;

export default MAFDialog;
