import { FC, ReactNode, ReactChild, MouseEvent } from 'react';
import { TableCell, Grid, Box, SxProps, Theme } from '@mui/material';

import { MAFTypography } from '../../../MAFTypography';
import { handleCustomSx } from '../../../../utils';

import { styles } from './MAFCellMobile.styles';
import { propTypes } from './MAFCellMobile.propTypes';

interface IMAFCellMobileProps {
  children: ReactNode;
  label?: ReactChild;
  shouldStopPropagation?: boolean;
  sx?: SxProps<Theme>;
  handleItemClick?: () => void;
}

const MAFCellMobile: FC<IMAFCellMobileProps> = ({
  children,
  label,
  shouldStopPropagation = false,
  sx,
  handleItemClick,
}) => {
  const rootClasses = [
    styles.root,
    !shouldStopPropagation && styles.cellMobile,
    ...handleCustomSx(sx),
  ];

  const handleItemClickWithoutPropagation = (e: MouseEvent) => {
    e.stopPropagation();
    handleItemClick && handleItemClick();
  };

  return (
    <TableCell
      sx={[...rootClasses, styles.cellTypeBody]}
      onClick={shouldStopPropagation ? handleItemClickWithoutPropagation : handleItemClick}
    >
      <Grid container direction="column" sx={styles.cell}>
        {!shouldStopPropagation && (
          <Grid item sx={styles.contentHeader}>
            <MAFTypography variant="label2">{label}</MAFTypography>
          </Grid>
        )}

        <Grid item>
          <Box sx={styles.content}>{children}</Box>
        </Grid>
      </Grid>
    </TableCell>
  );
};

MAFCellMobile.propTypes = propTypes;

export default MAFCellMobile;
