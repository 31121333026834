"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vendor = require("../../vendor.config");
var _stringUtils = require("../../utils/string-utils");
var _translationsUtils = require("../../utils/translations-utils");
const StatusOrValue = _ref => {
  let {
    state
  } = _ref;
  switch (state) {
    case 'NEW':
      return (0, _translationsUtils.getTranslationByKey)({
        locale: _vendor.DEFAULT_LOCALE,
        key: 'asset.new'
      });
    case 'USED':
      return (0, _translationsUtils.getTranslationByKey)({
        locale: _vendor.DEFAULT_LOCALE,
        key: 'asset.used'
      });
    default:
      return (0, _stringUtils.capitalizeFirstLetter)(state);
  }
};
const NSAssetState = _ref2 => {
  let {
    state
  } = _ref2;
  return state ? StatusOrValue({
    state
  }) : _stringUtils.EMPTY_VALUE;
};
var _default = exports.default = NSAssetState;