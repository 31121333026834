"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CreateRole", {
  enumerable: true,
  get: function () {
    return _CreateRole.default;
  }
});
var _CreateRole = _interopRequireDefault(require("./CreateRole/CreateRole"));