import { Box } from '@mui/material';
import { MAFTypography } from '@asfi/maf';
import { ReactChild } from 'react';

import { TFormatTextFunc } from 'types';

import DetailList from './DetailList/DetailList';
import { styles } from './detail.styles';

interface IDetailValue {
  label: ReactChild;
  value: number;
  isIndented: boolean;
  isNumber: boolean;
  content: [];
  shouldRender: boolean;
}

interface IDetailProps {
  title: ReactChild;
  values: IDetailValue[];
  isWithLine: boolean;
  formatText: TFormatTextFunc;
}

const Detail: React.FC<IDetailProps> = ({ title, values, isWithLine, formatText }) => (
  <Box sx={styles.column}>
    <MAFTypography variant="sub2-semi-bold" sx={styles.title}>
      {title}
    </MAFTypography>
    {values
      .filter(({ shouldRender = true }) => Boolean(shouldRender))
      .map(({ label, isIndented, value, isNumber, content, ...rest }, index) => (
        <>
          <Box sx={styles.row} key={index}>
            <MAFTypography variant="body1" textAlign="left" sx={[isIndented && styles.indented]}>
              {label}
            </MAFTypography>
            {(value || value === 0) && (
              <MAFTypography variant="body1" fontWeight={600} textAlign="right">
                {formatText({ text: value, formatRules: { ...rest } })}
              </MAFTypography>
            )}
          </Box>
          {content && content.length !== 0 && <DetailList list={content} />}
        </>
      ))}
    {isWithLine && <Box sx={styles.line} />}
  </Box>
);

export default Detail;
