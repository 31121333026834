"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sendQuoteDocument = exports.sendAffordabilityQuoteDocument = exports.initiateSigning = exports.getDocument = exports.createQuoteDocumentLink = void 0;
var _core = require("@northstar/core");
var _requestHandler = _interopRequireDefault(require("../../utils/request-handler"));
const {
  caseDomain,
  vendorCaseDomain
} = _requestHandler.default;
const createQuoteDocumentLink = _ref => {
  let {
    resourceId,
    draftScenarioId
  } = _ref;
  const correctRoute = _core.DomainUtils.getByExternalOrInternal(vendorCaseDomain.CREATE_QUOTE_DOCUMENT_LINK, caseDomain.CREATE_QUOTE_DOCUMENT_LINK);
  return correctRoute.call({
    urlParams: {
      resourceId,
      draftScenarioId
    }
  });
};
exports.createQuoteDocumentLink = createQuoteDocumentLink;
const sendQuoteDocument = _ref2 => {
  let {
    resourceId,
    draftScenarioId,
    requestBody
  } = _ref2;
  return vendorCaseDomain.SEND_QUOTE_DOCUMENT.call({
    urlParams: {
      resourceId,
      draftScenarioId
    },
    body: requestBody
  });
};
exports.sendQuoteDocument = sendQuoteDocument;
const sendAffordabilityQuoteDocument = _ref3 => {
  let {
    resourceId
  } = _ref3;
  return vendorCaseDomain.SEND_AFFORDABILITY_QUOTE_DOCUMENT.call({
    urlParams: {
      resourceId
    }
  });
};
exports.sendAffordabilityQuoteDocument = sendAffordabilityQuoteDocument;
const getDocument = _ref4 => {
  let {
    resourceId,
    documentType
  } = _ref4;
  const correctRoute = _core.DomainUtils.getByExternalOrInternal(vendorCaseDomain.GET_DOCUMENT, caseDomain.GET_DOCUMENT);
  return correctRoute.call({
    urlParams: {
      resourceId,
      documentType
    }
  });
};
exports.getDocument = getDocument;
const initiateSigning = _ref5 => {
  let {
    resourceId,
    requestBody
  } = _ref5;
  return vendorCaseDomain.INITIATE_SIGNING.call({
    urlParams: {
      resourceId
    },
    body: requestBody
  });
};
exports.initiateSigning = initiateSigning;