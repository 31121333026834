import { ISxStyles } from '../../../../theming/theme.type';

export const styles: ISxStyles = {
  root: {
    '&.MuiTableCell-root': {
      borderBottom: 'none',
      padding: '0 5px',
    },
  },
  cellTypeBody: ({ typography: { main }, colors }) => ({
    '&.MuiTableCell-body': {
      color: colors.solid.cadedGray,
      fontFamily: main,
      fontWeight: 500,
      lineHeight: '15px',
      height: 76,
      border: colors.solid.cadedGray,
    },
  }),
  cellTypeBodyUplift: ({ typography: { main }, colors }) => ({
    '&.MuiTableCell-body': {
      fontFamily: main,
      lineHeight: 1,
      letterSpacing: 0,
      color: colors.custom.contentAlternative.main.color,
      borderBottom: `1px solid ${colors.custom.table.borderColor}`,
      height: 56,
    },
  }),
  icon: ({ colors }) => ({
    '& .MuiTableSortLabel-icon': {
      marginLeft: '5px',
      width: 16,
      height: 18,
      '& g': {
        fill: colors.custom.contentAlternative.main.color,
      },
    },
  }),
  headTypeBody: ({ typography: { main }, colors }) => ({
    '&.MuiTableCell-head': {
      color: colors.custom.contentAlternative.main.color,
      fontFamily: main,
      letterSpacing: '0.68px',
      lineHeight: '19px',
      paddingLeft: 0,
      paddingRight: 0,
      height: 56,
      fontWeight: 600,
    },
  }),
  headTypeBodyUplift: ({ typography: { main }, colors }) => ({
    '&.MuiTableCell-head, &.MuiTableSortLabel-root': {
      fontFamily: main,
      lineHeight: '1',
      letterSpacing: 0,
      fontWeight: 600,
      color: colors.custom.contentAlternative.main.color,
    },
  }),
  underline: ({ colors }) => ({
    height: '1px',
    background: colors.solid.black,
    marginTop: '5px',
    marginRight: '5px',
  }),
  compact: {
    marginTop: '3px',
  },
  aligned: {
    '&.MuiTableCell-root': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  content: {
    display: 'flex',
    width: '100%',
  },
  indicatorContent: {
    justifyContent: 'center',
  },
  indicatorCell: {
    '&.MuiTableCell-root': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  adjusted: {
    top: 75,
  },
};
