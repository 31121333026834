"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  container: {
    height: '100%'
  },
  tableContainer: {
    overflow: 'auto',
    marginTop: '10px'
  },
  cell: _ref => {
    let {
      typography: {
        baseFont
      },
      colors
    } = _ref;
    return {
      '&.MuiTableCell-root': _objectSpread(_objectSpread({}, baseFont), {}, {
        textAlign: 'left',
        color: colors.solid.cadedGray,
        height: 76
      })
    };
  },
  headCell: _ref2 => {
    let {
      fontSizes: {
        extraSmall
      },
      colors
    } = _ref2;
    return {
      '&.MuiTableCell-root': {
        background: colors.custom.background.background,
        fontSize: extraSmall,
        borderBottom: '1px solid black',
        '&.MuiTableCell-head': {
          color: colors.solid.cadedGray,
          fontWeight: 500,
          padding: '5px'
        }
      }
    };
  },
  buttonsContainer: {
    width: '50%'
  },
  tabs: _ref3 => {
    let {
      fontSizes: {
        extraSmall
      }
    } = _ref3;
    return {
      '& .MuiTab-root': {
        fontSize: extraSmall,
        textTransform: 'uppercase',
        maxWidth: 'fit-content'
      },
      '& .MuiTabs-indicator > span': {
        maxWidth: '100% !important'
      }
    };
  }
};