"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findVendorTermsSet = void 0;
const findVendorTermsSet = _ref => {
  let {
    terms,
    selectedVendorTermsId
  } = _ref;
  return terms.find(_ref2 => {
    let {
      vendorTermsId
    } = _ref2;
    return vendorTermsId === selectedVendorTermsId;
  });
};
exports.findVendorTermsSet = findVendorTermsSet;