import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  cardWrapper: {
    maxWidth: 420,
    width: '100%',
  },
  cardHeader: ({ colors }) => ({
    backgroundColor: colors.solid.white,
    textAlign: 'center',
    borderTop: `1px solid ${colors.danske.greyC60}`,
    borderRight: `1px solid ${colors.danske.greyC60}`,
    borderLeft: `1px solid ${colors.danske.greyC60}`,
    borderImage: 'initial',
    position: 'relative',
    borderBottom: 'none',
    borderRadius: '2px 2px 0px 0px',
    padding: 2,
  }),
  date: ({ fontSizes, typography }) => ({
    width: '100%',
    textAlign: 'right',
    fontSize: fontSizes.tiny,
    fontFamily: typography.main,
  }),
  iconWrapper: ({ colors }) => ({
    height: 64,
    width: 64,
    margin: '0 auto',
    paddingBottom: 1,
    '& svg': {
      width: '100%',
      height: '100%',
    },
    '& g': {
      fill: colors.solid.cadedBlue,
    },
  }),
  purposeText: ({ fontSizes, typography }) => ({
    marginBottom: 2,
    fontSize: fontSizes.small,
    fontFamily: typography.main,
    fontWeight: 600,
  }),
  purposeDividerWrapper: ({ spacing }) => ({
    padding: spacing(0, 8),
    marginBottom: 2,
  }),
  purposeDivider: ({ colors }) => ({
    width: '100%',
    height: '1px',
    background: colors.danske.greyC60,
  }),
  loanLabel: ({ spacing, fontSizes, typography }) => ({
    fontSize: fontSizes.extraSmall,
    fontFamily: typography.main,
    margin: `${spacing(1)} 0`,
  }),
  loanAmount: ({ fontSizes, typography }) => ({
    fontSize: fontSizes.medium,
    fontFamily: typography.main,
    fontWeight: 600,
  }),
  cardSeparator: ({ colors }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
    height: 22,
    padding: '0 11px',
    boxSizing: 'border-box',
    '&:before': {
      // eslint-disable-next-line @typescript-eslint/quotes
      content: `''`,
      position: 'absolute',
      top: 0,
      left: -11,
      width: 22,
      height: 22,
      borderRadius: '100%',
      boxShadow: `${colors.solid.white} 0px 0px 0px 150px`,
      zIndex: 2,
      border: `1px solid ${colors.danske.greyC60}`,
      boxSizing: 'border-box',
    },
    '&:after': {
      // eslint-disable-next-line @typescript-eslint/quotes
      content: `''`,
      position: 'absolute',
      top: 0,
      right: -11,
      width: 22,
      height: 22,
      borderRadius: '100%',
      boxShadow: `${colors.solid.white} 0px 0px 0px 150px`,
      zIndex: 2,
      border: `1px solid ${colors.danske.greyC60}`,
      boxSizing: 'border-box',
    },
  }),
  cardBorderWrapper: ({ colors }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: colors.solid.white,
  }),
  cardBorder: ({ colors }) => ({
    height: '1px',
    width: '100%',
    zIndex: 100,
    borderBottom: `1px dashed ${colors.danske.greyC60}`,
  }),
  cardFooter: ({ colors, spacing }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.solid.white,
    borderRight: `1px solid ${colors.danske.greyC60}`,
    borderBottom: `1px solid ${colors.danske.greyC60}`,
    borderLeft: `1px solid ${colors.danske.greyC60}`,
    position: 'relative',
    borderTop: 'none',
    borderRadius: '0px 0px 2px 2px',
    padding: `${spacing(2)} ${spacing(4)} ${spacing(4)}`,
  }),
  footerButton: ({ colors, fontSizes }) => ({
    '&.MuiButton-root': {
      color: colors.danske.action,
      fontSize: fontSizes.small,
      padding: 0,
      height: 16,
      '&:hover': {
        '& span': {
          left: 8,
        },
      },
    },
  }),
  footerArrow: ({ colors }) => ({
    transition: '150ms cubic-bezier(0, 0.3, 0.7, 1) 0s',
    position: 'relative',
    left: 4,
    top: 1,
    '& g': {
      fill: colors.danske.action,
    },
  }),
};
