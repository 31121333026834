import { CSSProperties } from '@mui/styles';

import { ISxStyles } from '../../theming/theme.type';

interface IAnimateAppbarProps {
  transformPercent: number;
}

const animateAppbar = ({ transformPercent }: IAnimateAppbarProps): CSSProperties => ({
  position: 'sticky',
  transform: `translate3d(0px, ${transformPercent}%, 0px)`,
  transition: 'all 0.3s ease-in-out 0s',
});

export const APPBAR_MIN_HEIGHT_DESKTOP = 96;
export const APPBAR_MIN_HEIGHT_MOBILE = 64;

export const styles: ISxStyles = {
  top: ({ colors }) => ({
    background: colors.danske.primary6,
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  }),
  topText: ({ typography, colors, fontSizes }) => ({
    fontFamily: typography.main,
    color: colors.danske.primary70,
    fontSize: fontSizes.tiny,
  }),
  appBar: ({ colors, zIndex, spacing, breakpoints }) => ({
    minHeight: APPBAR_MIN_HEIGHT_DESKTOP,
    background: colors.solid.white,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(0, 8),
    top: 0,
    left: 0,
    right: 0,
    zIndex: zIndex.appBar,
    position: 'sticky',
    transform: 'translate3d(0px, 0px, 0px)',
    [breakpoints.only('xs')]: {
      minHeight: APPBAR_MIN_HEIGHT_MOBILE,
      padding: spacing(0, 2),
    },
    [breakpoints.down('sm')]: {
      zIndex: zIndex.drawer + 1,
    },
  }),
  appBarScrollUp: {
    '&': {
      ...animateAppbar({ transformPercent: 0 }),
    },
  },
  appBarScrollDown: {
    '&': {
      ...animateAppbar({ transformPercent: -100 }),
    },
  },
  logoContainer: ({ spacing, breakpoints }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: spacing(4, 0),
    [breakpoints.only('xs')]: {
      padding: spacing(3, 0),
    },
  }),
  logo: ({ breakpoints }) => ({
    width: '216px !important',
    height: 32,
    [breakpoints.only('xs')]: {
      width: '108px !important',
      height: 16,
    },
  }),
  loginButtonAndLanguageSelectWrapper: ({ spacing }) => ({
    marginLeft: 'auto',
    marginRight: spacing(3),
    display: 'flex',
    alignItems: 'center',
  }),
  topLinksWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
  },
  topLinkSelected: ({ colors }) => ({
    background: colors.danske.white,
    height: 'inherit',
    display: 'inherit',
    alignItems: 'center',
  }),
  topLinkWrapper: ({ spacing }) => ({
    padding: spacing(0, 5),
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  topLinkWrapperText: {
    textTransform: 'uppercase',
  },
  bottomLink: {
    paddingLeft: 4,
  },
  bottomLinkTablet: ({ breakpoints, spacing }) => ({
    display: 'flex',
    [breakpoints.down('lg')]: {
      width: '100%',
      padding: spacing(0, 0, 4),
    },
  }),
  select: ({ fontSizes, colors }) => ({
    width: 154,
    '& > div': {
      background: `${colors.danske.primary6} !important`,
      border: 'none !important',
      '&:focus': {
        border: `1px solid ${colors.danske.action} !important`,
      },
    },
    '& .MuiSelect-select': {
      textAlign: 'center',
      fontSize: fontSizes.tiny,
      '&:hover': {
        color: `${colors.danske.action} !important`,
      },
    },
  }),
  selectFormControlWrapper: {
    minHeight: 'unset !important',
    maxHeight: '42px',
    marginTop: 'unset !important',
  },
  linkWrapper: {
    maxWidth: '1200px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 'auto',
  },
  topLinksRootWrapper: ({ colors, spacing }) => ({
    background: colors.danske.primary6,
    padding: spacing(0, 8),
  }),
  languageSelectDesktopFormControlWrapper: { justifyContent: 'center' },
  languageSelectDesktop: ({ colors }) => ({
    '& > div': {
      border: 'none !important',
      '&:focus': {
        border: `1px solid ${colors.danske.action} !important`,
      },
    },
    '& .MuiSelect-select': {
      '&:hover': {
        color: `${colors.danske.action} !important`,
      },
    },
    '& .MuiSelect-select, .MuiNativeSelect-select': {
      fontWeight: 'bold',
    },
  }),
};
