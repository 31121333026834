const VeryHappyFilled: React.FC<{}> = () => (
  <svg height="48px" width="48px" color="#003755" viewBox="-2 -2 24 24">
    <path
      d="M10,0 C15.523,0 20,4.477 20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 Z M14.7870612,11.7592494 C14.5752005,11.6782096 14.3377577,11.7842609 14.2567179,11.9961216 C13.5926141,13.7322738 12.2023473,14.5874332 9.99836578,14.5874332 C7.79441175,14.5874332 6.40441877,13.7323065 5.74078362,11.9962079 C5.65979149,11.784329 5.42237259,11.6782243 5.21049364,11.7592164 C4.9986147,11.8402085 4.89250997,12.0776274 4.9735021,12.2895064 C5.7651384,14.3604616 7.46929403,15.4088618 9.99836578,15.4088618 C12.52741,15.4088618 14.2317831,14.3604943 15.0239333,12.2895927 C15.1049732,12.077732 14.9989219,11.8402892 14.7870612,11.7592494 Z M6,7 C5.44771525,7 5,7.44771525 5,8 C5,8.55228475 5.44771525,9 6,9 C6.55228475,9 7,8.55228475 7,8 C7,7.44771525 6.55228475,7 6,7 Z M14,7 C13.4477153,7 13,7.44771525 13,8 C13,8.55228475 13.4477153,9 14,9 C14.5522847,9 15,8.55228475 15,8 C15,7.44771525 14.5522847,7 14,7 Z"
      id="Combined-Shape"
    ></path>
  </svg>
);

export default VeryHappyFilled;
