import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  verticalContainer: {
    alignItems: 'center',
    flexDirection: 'column-reverse',
  },
  fullWidth: {
    width: '100%',
  },
  buttonLeft: {
    marginRight: 2,
  },
  buttonRight: {
    marginBottom: 2,
  },
  buttonFullWidth: {
    width: '100%',
  },
  sticky: ({ colors }) => ({
    position: 'sticky',
    bottom: -2,
    background: colors.custom.buttons.sticky.background,
  }),
};
