import { createContext, useContext } from 'react';

export const Oauth2PopupContext = createContext<{
  isPopupOpen: boolean;
  setIsPopupOpen: (state: boolean) => void;
} | null>(null);

export const useOauth2PopupContext = () => {
  const context = useContext(Oauth2PopupContext);
  if (!context)
    throw new Error('Oauth2PopupProvider is required in order to use Oauth2Popup component');
  return context;
};
