"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RATES_TYPE_WINTER_TIRES = exports.RATES_TYPE_SERVICE = exports.DEFAULT_MIN_PERIOD = exports.DEFAULT_MIN_MILEAGE = exports.DEFAULT_MAX_PERIOD = exports.DEFAULT_MAX_MILEAGE = void 0;
const RATES_TYPE_SERVICE = exports.RATES_TYPE_SERVICE = 'serviceRates';
const RATES_TYPE_WINTER_TIRES = exports.RATES_TYPE_WINTER_TIRES = 'winterTiresRates';
const DEFAULT_MIN_MILEAGE = exports.DEFAULT_MIN_MILEAGE = 15000;
const DEFAULT_MAX_MILEAGE = exports.DEFAULT_MAX_MILEAGE = 45000;
const DEFAULT_MIN_PERIOD = exports.DEFAULT_MIN_PERIOD = 12;
const DEFAULT_MAX_PERIOD = exports.DEFAULT_MAX_PERIOD = 36;