import { ISxStyles } from '@asfi/maf';

export const styles: ISxStyles = {
  container: ({ breakpoints }) => ({
    minHeight: 110,
    [breakpoints.up('sm')]: {
      minHeight: 175,
    },
  }),
};
