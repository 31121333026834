const KebabMenu: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M12,18 C13.105,18 14,18.895 14,20 C14,21.105 13.105,22 12,22 C10.895,22 10,21.105 10,20 C10,18.895 10.895,18 12,18 Z M12,10 C13.105,10 14,10.895 14,12 C14,13.105 13.105,14 12,14 C10.895,14 10,13.105 10,12 C10,10.895 10.895,10 12,10 Z M12,2 C13.105,2 14,2.895 14,4 C14,5.105 13.105,6 12,6 C10.895,6 10,5.105 10,4 C10,2.895 10.895,2 12,2 Z"
        id="path-1"
      ></path>
    </g>
  </svg>
);

export default KebabMenu;
