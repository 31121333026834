"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allowExternal = exports.allowInternal = exports.allowNonAnonymous = exports.allowVendorAdministratorForStandaloneAndDealerships = exports.allowVendorAdministrator = exports.allowVendorSalesPerson = exports.allowOnboarder = exports.allowAssetAdministrator = exports.allowAssetsEvaluator = exports.allowOperations = exports.allowFinance = exports.allowAny = exports.allowVendorType = exports.allowReadonly = exports.allowRole = exports.isInternalRole = void 0;
var Constants_1 = require("./Constants");
var isInternalRole = function (req) {
    return [
        Constants_1.USER_ROLE_FINANCE,
        Constants_1.USER_ROLE_OPERATIONS,
        Constants_1.USER_ROLE_ASSETS,
        Constants_1.USER_ROLE_ONBOARDER,
        Constants_1.USER_ROLE_ASSET_ADMINISTRATOR,
    ].includes(req.roleBaseType);
};
exports.isInternalRole = isInternalRole;
var allowRole = function (_a) {
    var roleBaseType = _a.roleBaseType, expectedRole = _a.expectedRole, roleSubType = _a.roleSubType;
    return roleBaseType === expectedRole && roleSubType === Constants_1.USER_ROLE_SUB_TYPE_DEFAULT;
};
exports.allowRole = allowRole;
var allowReadonly = function (rule) {
    return function (_a) {
        var roleBaseType = _a.roleBaseType, roleSubType = _a.roleSubType;
        if (roleSubType === Constants_1.USER_ROLE_SUB_TYPE_READONLY) {
            return rule({ roleBaseType: roleBaseType, roleSubType: Constants_1.USER_ROLE_SUB_TYPE_DEFAULT });
        }
        return false;
    };
};
exports.allowReadonly = allowReadonly;
var allowVendorType = function (rule, allowedVendorTypes) {
    return function (_a) {
        var roleBaseType = _a.roleBaseType, roleSubType = _a.roleSubType, vendorType = _a.vendorType;
        if (vendorType && allowedVendorTypes.includes(vendorType)) {
            return rule({ roleBaseType: roleBaseType, roleSubType: roleSubType });
        }
        return false;
    };
};
exports.allowVendorType = allowVendorType;
var allowAny = function () { return true; };
exports.allowAny = allowAny;
var allowFinance = function (_a) {
    var roleBaseType = _a.roleBaseType, roleSubType = _a.roleSubType;
    return exports.allowRole({ roleBaseType: roleBaseType, expectedRole: Constants_1.USER_ROLE_FINANCE, roleSubType: roleSubType });
};
exports.allowFinance = allowFinance;
var allowOperations = function (_a) {
    var roleBaseType = _a.roleBaseType, roleSubType = _a.roleSubType;
    return exports.allowRole({ roleBaseType: roleBaseType, expectedRole: Constants_1.USER_ROLE_OPERATIONS, roleSubType: roleSubType });
};
exports.allowOperations = allowOperations;
var allowAssetsEvaluator = function (_a) {
    var roleBaseType = _a.roleBaseType, roleSubType = _a.roleSubType;
    return exports.allowRole({ roleBaseType: roleBaseType, expectedRole: Constants_1.USER_ROLE_ASSETS, roleSubType: roleSubType });
};
exports.allowAssetsEvaluator = allowAssetsEvaluator;
var allowAssetAdministrator = function (_a) {
    var roleBaseType = _a.roleBaseType, roleSubType = _a.roleSubType;
    return exports.allowRole({ roleBaseType: roleBaseType, expectedRole: Constants_1.USER_ROLE_ASSET_ADMINISTRATOR, roleSubType: roleSubType });
};
exports.allowAssetAdministrator = allowAssetAdministrator;
var allowOnboarder = function (_a) {
    var roleBaseType = _a.roleBaseType, roleSubType = _a.roleSubType;
    return exports.allowRole({ roleBaseType: roleBaseType, expectedRole: Constants_1.USER_ROLE_ONBOARDER, roleSubType: roleSubType });
};
exports.allowOnboarder = allowOnboarder;
var allowVendorSalesPerson = function (_a) {
    var roleBaseType = _a.roleBaseType, roleSubType = _a.roleSubType;
    return exports.allowRole({ roleBaseType: roleBaseType, expectedRole: Constants_1.USER_ROLE_VENDOR_EMPLOYEE, roleSubType: roleSubType });
};
exports.allowVendorSalesPerson = allowVendorSalesPerson;
var allowVendorAdministrator = function (_a) {
    var roleBaseType = _a.roleBaseType, roleSubType = _a.roleSubType;
    return exports.allowRole({ roleBaseType: roleBaseType, expectedRole: Constants_1.USER_ROLE_VENDOR_ADMIN, roleSubType: roleSubType });
};
exports.allowVendorAdministrator = allowVendorAdministrator;
var allowVendorAdministratorForStandaloneAndDealerships = function (payload) {
    return exports.allowVendorType(exports.allowVendorAdministrator, [
        Constants_1.VENDOR_TYPE_STANDALONE_VENDOR,
        Constants_1.VENDOR_TYPE_DEALERSHIP,
    ])(payload);
};
exports.allowVendorAdministratorForStandaloneAndDealerships = allowVendorAdministratorForStandaloneAndDealerships;
var allowNonAnonymous = function (_a) {
    var roleBaseType = _a.roleBaseType, roleSubType = _a.roleSubType;
    return exports.allowOnboarder({ roleBaseType: roleBaseType, roleSubType: roleSubType }) ||
        exports.allowFinance({ roleBaseType: roleBaseType, roleSubType: roleSubType }) ||
        exports.allowOperations({ roleBaseType: roleBaseType, roleSubType: roleSubType }) ||
        exports.allowAssetsEvaluator({ roleBaseType: roleBaseType, roleSubType: roleSubType }) ||
        exports.allowVendorSalesPerson({ roleBaseType: roleBaseType, roleSubType: roleSubType }) ||
        exports.allowVendorAdministrator({ roleBaseType: roleBaseType, roleSubType: roleSubType }) ||
        exports.allowAssetAdministrator({ roleBaseType: roleBaseType, roleSubType: roleSubType });
};
exports.allowNonAnonymous = allowNonAnonymous;
var allowInternal = function (_a) {
    var roleBaseType = _a.roleBaseType, roleSubType = _a.roleSubType;
    return exports.allowOnboarder({ roleBaseType: roleBaseType, roleSubType: roleSubType }) ||
        exports.allowFinance({ roleBaseType: roleBaseType, roleSubType: roleSubType }) ||
        exports.allowOperations({ roleBaseType: roleBaseType, roleSubType: roleSubType }) ||
        exports.allowAssetsEvaluator({ roleBaseType: roleBaseType, roleSubType: roleSubType }) ||
        exports.allowAssetAdministrator({ roleBaseType: roleBaseType, roleSubType: roleSubType });
};
exports.allowInternal = allowInternal;
var allowExternal = function (_a) {
    var roleBaseType = _a.roleBaseType, roleSubType = _a.roleSubType;
    return exports.allowVendorSalesPerson({ roleBaseType: roleBaseType, roleSubType: roleSubType }) ||
        exports.allowVendorAdministrator({ roleBaseType: roleBaseType, roleSubType: roleSubType });
};
exports.allowExternal = allowExternal;
exports.default = {
    allowFinance: exports.allowFinance,
    allowOperations: exports.allowOperations,
    allowAssetsEvaluator: exports.allowAssetsEvaluator,
    allowVendorSalesPerson: exports.allowVendorSalesPerson,
    allowVendorAdministrator: exports.allowVendorAdministrator,
    allowVendorAdministratorForStandaloneAndDealerships: exports.allowVendorAdministratorForStandaloneAndDealerships,
    allowOnboarder: exports.allowOnboarder,
    allowAssetAdministrator: exports.allowAssetAdministrator,
    allowReadonly: exports.allowReadonly,
    allowNonAnonymous: exports.allowNonAnonymous,
    allowExternal: exports.allowExternal,
    allowAny: exports.allowAny,
    isInternalRole: exports.isInternalRole,
};
