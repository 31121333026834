"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.translationMessages = exports.getReactIntlLocale = exports.formatTranslationMessages = exports.DEFAULT_LOCALE = void 0;
var _en = _interopRequireDefault(require("./translations/en.json"));
var _fi = _interopRequireDefault(require("./translations/fi.json"));
var _no = _interopRequireDefault(require("./translations/no.json"));
var _se = _interopRequireDefault(require("./translations/se.json"));
const DEFAULT_LOCALE = exports.DEFAULT_LOCALE = 'se';
const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages = locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, _en.default) : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key];
    return Object.assign(formattedMessages, {
      [key]: formattedMessage
    });
  }, {});
};
exports.formatTranslationMessages = formatTranslationMessages;
const translationMessages = exports.translationMessages = {
  en: formatTranslationMessages('en', _en.default),
  se: formatTranslationMessages('se', _se.default),
  no: formatTranslationMessages('no', _no.default),
  fi: formatTranslationMessages('fi', _fi.default)
};
const getReactIntlLocale = _ref => {
  let {
    locale
  } = _ref;
  const localeMap = {
    se: 'sv'
  };
  return localeMap[locale] || locale;
};
exports.getReactIntlLocale = getReactIntlLocale;