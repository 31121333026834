import { Grid, Box } from '@mui/material';

import MAFTypography from '../../../MAFTypography/MAFTypography';
import MAFCheckbox from '../../../MAFCheckbox/MAFCheckbox';
import { IMAFCookiesConsentConsentLevels } from '../../MAFCookiesConsent.types';

import { styles } from './ExpandedContent.styles';
import { propTypes } from './ExpandedContent.propTypes';

interface IExpandedContentProps {
  consentLevels: IMAFCookiesConsentConsentLevels[];
  level?: number;
  setLevel: (level: number) => void;
}

const ExpandedContent: React.FC<IExpandedContentProps> = ({ consentLevels, level, setLevel }) => {
  const handleCheckboxClick = (newLevel: number) => () =>
    setLevel(level && newLevel <= level ? newLevel - 1 : newLevel);

  return (
    <Box sx={styles.checkboxContent}>
      {consentLevels.map(({ level: itemLevel, isMandatory, label, text }) => {
        const isChecked = (level && level >= itemLevel) || isMandatory;
        return (
          <Grid container sx={styles.singleCheckbox} key={itemLevel}>
            <Grid item>
              <MAFCheckbox
                checked={isChecked}
                disabled={isMandatory}
                onClick={handleCheckboxClick(itemLevel)}
              />
            </Grid>
            <Grid item xs sx={styles.checkboxText}>
              <MAFTypography variant="label1" isWithContainer>
                {label}
              </MAFTypography>
              <MAFTypography variant="body1">{text}</MAFTypography>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

ExpandedContent.propTypes = propTypes;

export default ExpandedContent;
