"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateVendorTermsPROL = exports.updateSingleServiceMatrix = exports.getVendorTermsPROL = exports.getVendorTermsPRHP = exports.getVendorTermsCMOL = exports.getVendorTermsCMFL = exports.getVendorFeatures = exports.getVendor = exports.getSingleServiceMatrix = exports.getServiceNames = exports.getAllVendorTermsPROL = exports.getAllVendorTermsPRHP = exports.getAllVendorTermsCMOL = exports.getAllVendorTermsCMFL = exports.deleteSingleServiceMatrix = exports.createSingleServiceMatrix = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _requestHandler = _interopRequireDefault(require("../../utils/request-handler"));
const _excluded = ["vendorId"];
const {
  vendorDomain,
  featureDomain
} = _requestHandler.default;
const getVendorTermsPROL = _ref => {
  let {
    importerId,
    vendorId
  } = _ref;
  return vendorDomain.GET_VENDOR_TERMS_PL.call({
    urlParams: {
      importerId,
      vendorId
    }
  });
};
exports.getVendorTermsPROL = getVendorTermsPROL;
const getVendorTermsPRHP = _ref2 => {
  let {
    importerId,
    vendorId
  } = _ref2;
  return vendorDomain.GET_VENDOR_TERMS_HP.call({
    urlParams: {
      importerId,
      vendorId
    }
  });
};
exports.getVendorTermsPRHP = getVendorTermsPRHP;
const getVendorTermsCMOL = _ref3 => {
  let {
    importerId,
    vendorId
  } = _ref3;
  return vendorDomain.GET_VENDOR_TERMS_CM_OL.call({
    urlParams: {
      importerId,
      vendorId
    }
  });
};
exports.getVendorTermsCMOL = getVendorTermsCMOL;
const getVendorTermsCMFL = _ref4 => {
  let {
    importerId,
    vendorId
  } = _ref4;
  return vendorDomain.GET_VENDOR_TERMS_CM_FL.call({
    urlParams: {
      importerId,
      vendorId
    }
  });
};
exports.getVendorTermsCMFL = getVendorTermsCMFL;
const updateVendorTermsPROL = _ref5 => {
  let {
      vendorId
    } = _ref5,
    body = (0, _objectWithoutProperties2.default)(_ref5, _excluded);
  return vendorDomain.UPDATE_VENDOR_TERMS_PL.call({
    urlParams: {
      vendorId
    },
    body
  });
};
exports.updateVendorTermsPROL = updateVendorTermsPROL;
const getVendor = _ref6 => {
  let {
    vendorId,
    importerId
  } = _ref6;
  return vendorDomain.GET_VENDOR.call({
    urlParams: {
      vendorId,
      importerId
    }
  });
};
exports.getVendor = getVendor;
const getServiceNames = _ref7 => {
  let {
    vendorId
  } = _ref7;
  return vendorDomain.GET_VENDOR_SERVICE_NAMES.call({
    urlParams: {
      vendorId
    }
  });
};
exports.getServiceNames = getServiceNames;
const getSingleServiceMatrix = _ref8 => {
  let {
    matrixName,
    vendorId
  } = _ref8;
  return vendorDomain.GET_VENDOR_SERVICE_RATES_MATRIX.call({
    urlParams: {
      matrixName,
      vendorId
    }
  });
};
exports.getSingleServiceMatrix = getSingleServiceMatrix;
const createSingleServiceMatrix = _ref9 => {
  let {
    body,
    vendorId
  } = _ref9;
  return vendorDomain.CREATE_VENDOR_SERVICE_RATES_MATRIX.call({
    urlParams: {
      vendorId
    },
    body
  });
};
exports.createSingleServiceMatrix = createSingleServiceMatrix;
const updateSingleServiceMatrix = _ref10 => {
  let {
    body,
    vendorId
  } = _ref10;
  return vendorDomain.UPDATE_VENDOR_SERVICE_RATES_MATRIX.call({
    urlParams: {
      vendorId
    },
    body
  });
};
exports.updateSingleServiceMatrix = updateSingleServiceMatrix;
const deleteSingleServiceMatrix = _ref11 => {
  let {
    matrixName,
    vendorId
  } = _ref11;
  return vendorDomain.DELETE_VENDOR_SERVICE_RATES_MATRIX.call({
    urlParams: {
      matrixName,
      vendorId
    }
  });
};
exports.deleteSingleServiceMatrix = deleteSingleServiceMatrix;
const getVendorFeatures = _ref12 => {
  let {
    vendorId
  } = _ref12;
  return featureDomain.GET_FEATURES_VENDOR.call({
    urlParams: {
      vendorId
    }
  });
};
exports.getVendorFeatures = getVendorFeatures;
const getAllVendorTermsPROL = _ref13 => {
  let {
    vendorId,
    importerId,
    validity,
    lightResponse
  } = _ref13;
  return vendorDomain.GET_ALL_VENDOR_TERMS_PR_OL.call({
    urlParams: {
      vendorId,
      importerId,
      validity,
      lightResponse
    }
  });
};
exports.getAllVendorTermsPROL = getAllVendorTermsPROL;
const getAllVendorTermsPRHP = _ref14 => {
  let {
    vendorId,
    importerId,
    validity,
    lightResponse
  } = _ref14;
  return vendorDomain.GET_ALL_VENDOR_TERMS_PR_HP.call({
    urlParams: {
      vendorId,
      importerId,
      validity,
      lightResponse
    }
  });
};
exports.getAllVendorTermsPRHP = getAllVendorTermsPRHP;
const getAllVendorTermsCMOL = _ref15 => {
  let {
    vendorId,
    importerId,
    validity,
    lightResponse
  } = _ref15;
  return vendorDomain.GET_ALL_VENDOR_TERMS_CM_OL.call({
    urlParams: {
      vendorId,
      importerId,
      validity,
      lightResponse
    }
  });
};
exports.getAllVendorTermsCMOL = getAllVendorTermsCMOL;
const getAllVendorTermsCMFL = _ref16 => {
  let {
    vendorId,
    importerId,
    validity,
    lightResponse
  } = _ref16;
  return vendorDomain.GET_ALL_VENDOR_TERMS_CM_FL.call({
    urlParams: {
      vendorId,
      importerId,
      validity,
      lightResponse
    }
  });
};
exports.getAllVendorTermsCMFL = getAllVendorTermsCMFL;