"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectIsLoadingCaseForQuote = exports.selectIsCasePROL = exports.selectIsCasePRHP = exports.selectIsCaseCMOL = exports.selectIsCaseCMFL = exports.selectCaseForQuoteError = exports.selectCaseForQuote = void 0;
var _reselect = require("reselect");
var _caseDetailConstants = require("../caseDetailConstants");
const selectCaseState = state => state.caseForQuote;
const selectIsLoadingCaseForQuote = () => (0, _reselect.createSelector)(selectCaseState, state => state.get('loadingCaseForQuote'));
exports.selectIsLoadingCaseForQuote = selectIsLoadingCaseForQuote;
const selectCaseForQuote = () => (0, _reselect.createSelector)(selectCaseState, state => state.get('caseForQuote').toJS());
exports.selectCaseForQuote = selectCaseForQuote;
const selectIsCasePROL = () => (0, _reselect.createSelector)(selectCaseForQuote(), _ref => {
  let {
    productType,
    productSubType
  } = _ref;
  return productType === _caseDetailConstants.PRODUCT_TYPE_PRIVATE && productSubType === _caseDetailConstants.PRODUCT_SUB_TYPE_OPERATIONAL_LEASE;
});
exports.selectIsCasePROL = selectIsCasePROL;
const selectIsCasePRHP = () => (0, _reselect.createSelector)(selectCaseForQuote(), _ref2 => {
  let {
    productType,
    productSubType
  } = _ref2;
  return productType === _caseDetailConstants.PRODUCT_TYPE_PRIVATE && productSubType === _caseDetailConstants.PRODUCT_SUB_TYPE_HIRE_PURCHASE;
});
exports.selectIsCasePRHP = selectIsCasePRHP;
const selectIsCaseCMOL = () => (0, _reselect.createSelector)(selectCaseForQuote(), _ref3 => {
  let {
    productType,
    productSubType
  } = _ref3;
  return productType === _caseDetailConstants.PRODUCT_TYPE_COMMERCIAL && productSubType === _caseDetailConstants.PRODUCT_SUB_TYPE_OPERATIONAL_LEASE;
});
exports.selectIsCaseCMOL = selectIsCaseCMOL;
const selectIsCaseCMFL = () => (0, _reselect.createSelector)(selectCaseForQuote(), _ref4 => {
  let {
    productType,
    productSubType
  } = _ref4;
  return productType === _caseDetailConstants.PRODUCT_TYPE_COMMERCIAL && productSubType === _caseDetailConstants.PRODUCT_SUB_TYPE_FINANCIAL_LEASE;
});
exports.selectIsCaseCMFL = selectIsCaseCMFL;
const selectCaseForQuoteError = () => (0, _reselect.createSelector)(selectCaseState, state => state.get('error'));
exports.selectCaseForQuoteError = selectCaseForQuoteError;