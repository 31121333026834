import { fromJS } from 'immutable';
import { createAction, handleActions, combineActions } from 'redux-actions';

export const submitFrameRequest = createAction('ASSET_SUBMIT_FRAME_REQUEST');
export const submitFrameResponse = createAction('ASSET_SUBMIT_FRAME_RESPONSE');
export const clearFrameState = createAction('ASSET_FRAME_CLEAR_STATE');
export const changeFrameProperty = createAction('ASSET_CHANGE_FRAME_PROPERTY');
export const changeFramePreviousId = createAction('ASSET_FRAME_CHANGE_PREVIOUS_FRAME_ID');
export const getFrameResponse = createAction('ASSET_FRAME_GET_FRAME_RESPONSE');

export const initialState = fromJS({
  gettingFrameEquipment: false,
  submitingFrame: false,
  previousFrameId: 0,
  frame: {
    id: 0,
    popName: '',
    vehicleType: '',
    modelYear: '',
    bodyType: '',
    doors: 0,
    seating: 0,
    fuelType: '',
    engine: 0,
    enginePowerKw: 0,
    enginePowerHp: 0,
    drivingWheel: 0,
    transmissionType: 0,
    trimLevel: 0,
    framePic: '',
    framePicFile: '',
    status: 0,
    brand: {
      id: 0,
      name: '',
      status: '',
    },
    model: {
      id: 0,
      name: '',
      status: '',
    },
  },
});

export default handleActions(
  {
    [combineActions(submitFrameResponse, getFrameResponse)](state, { payload, error }) {
      return !error
        ? state.set('frame', fromJS(payload)).set('submitingFrame', false)
        : state.set('submitingFrame', false);
    },
    [clearFrameState](state) {
      const { previousFrameId } = state.toJS();
      return fromJS({ ...initialState.toJS(), previousFrameId });
    },
    [changeFrameProperty](state, { payload }) {
      const { property, value } = payload;
      return state.setIn(['frame', property], value);
    },
    [changeFramePreviousId](state, { payload }) {
      return state.set('previousFrameId', payload.id);
    },
  },
  initialState,
);
