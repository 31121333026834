import { FC, ReactNode } from 'react';
import { TableSortLabel, TableCell, TableCellProps, Box, SxProps, Theme } from '@mui/material';

import { handleCustomSx } from '../../../../utils';
import ArrowDescending from '../../../Icons/ArrowDescending';
import MAFTooltip from '../../../MAFTooltip/MAFTooltip';
import { TDeprecatedMAFTableDirection } from '../../DeprecatedMAFTable.types';

import { styles } from './DeprecatedMAFCell.styles';
import { propTypes } from './DeprecatedMAFSortableCell.propTypes';

interface IMAFSortableCellProps extends TableCellProps {
  children: ReactNode;
  onOrder?: () => void;
  name: string;
  orderBy?: string;
  direction?: TDeprecatedMAFTableDirection;
  isUnsortable?: boolean;
  sortField?: string;
  isUplift?: boolean;
  sx?: SxProps<Theme>;
  shouldAdjustHeaderPosition?: boolean;
  sortTitle?: ReactNode;
}

const DeprecatedMAFSortableCell: FC<IMAFSortableCellProps> = ({
  children,
  onOrder,
  name,
  orderBy,
  direction,
  isUnsortable,
  sortField,
  isUplift = false,
  sx,
  shouldAdjustHeaderPosition = false,
  sortTitle,
  ...rest
}) => {
  const tableSortLabel = (
    <TableSortLabel
      active={orderBy === name || orderBy === sortField}
      direction={direction}
      sx={[isUplift && styles.headTypeBodyUplift, styles.icon]}
      IconComponent={ArrowDescending}
      onClick={onOrder}
    >
      {children}
    </TableSortLabel>
  );

  return (
    <TableCell
      sx={[
        shouldAdjustHeaderPosition && styles.adjusted,
        styles.root,
        styles.cellTypeBody,
        styles.headTypeBody,
        ...handleCustomSx(sx),
      ]}
      {...rest}
    >
      {!isUnsortable ? (
        sortTitle ? (
          <MAFTooltip title={sortTitle} enterDelay={300}>
            {tableSortLabel}
          </MAFTooltip>
        ) : (
          tableSortLabel
        )
      ) : (
        children
      )}

      {!isUplift && <Box sx={styles.underline} />}
    </TableCell>
  );
};

DeprecatedMAFSortableCell.propTypes = propTypes;

export default DeprecatedMAFSortableCell;
