import numeral from 'numeral';

import { formatSafeValueToMoney, convertToNumberWithoutDots } from '../../../utils';
import {
  TMAFTextFieldOnChange,
  TMAFTextFieldOnChangeEvent,
  TMAFTextFieldOnFocus,
  TMAFTextFieldOnFocusEvent,
} from '../../MAFTextField/MAFTextField.types';

import { IGetFormattingProps } from './formatting.types';

const isNumber = (value?: Nullable<string | number>) => value && Number.isNaN(Number(value));

const parseValueForNumberValidation = (value: string | number) => {
  if (!value && value !== 0) return '';
  const regex = /^[-+]?\d*(\.\d*)*(,|,\d+)?$/;
  if (typeof value === 'string' && !regex.test(value)) {
    return value;
  }
  return numeral(value).value();
};

const overrideInputEventMoneyOnChange =
  (event: TMAFTextFieldOnChange, shouldAllowCents?: boolean) => (e: TMAFTextFieldOnChangeEvent) => {
    const value = convertToNumberWithoutDots(e.currentTarget.value);
    const adjustedValue = shouldAllowCents ? parseValueForNumberValidation(value) : value;
    const isValidNumber = !isNumber(adjustedValue);
    if (!isValidNumber) {
      e.preventDefault();
      return;
    }
    e.currentTarget.value = value.toString();
    return event(e);
  };

const overrideInputEventMoneyOnBlur =
  (event: TMAFTextFieldOnFocus, shouldAllowCents?: boolean) => (e: TMAFTextFieldOnFocusEvent) => {
    const value = convertToNumberWithoutDots(e.currentTarget.value);
    const adjustedValue = shouldAllowCents ? parseValueForNumberValidation(value) : value;
    const isValidNumber = !isNumber(adjustedValue);
    if (!isValidNumber) {
      e.preventDefault();
      return;
    }
    e.currentTarget.value = value.toString();
    return event(e);
  };

const overrideInputEventOnChange =
  (event: TMAFTextFieldOnChange, isPercentage?: boolean, isWithoutDecimals?: boolean) =>
  (e: TMAFTextFieldOnChangeEvent) => {
    const value = e.currentTarget.value;
    const adjustedValue = isPercentage ? value.replace(',', '.') : value;
    const isValidNumber = !isNumber(adjustedValue);
    if (!isValidNumber) {
      e.preventDefault();
      return;
    }
    if (isWithoutDecimals) {
      e.currentTarget.value = `${convertToNumberWithoutDots(value)}`;
    }
    return event(e);
  };

const overrideInputEventOnBlur =
  (event: TMAFTextFieldOnFocus, isPercentage?: boolean, isWithoutDecimals?: boolean) =>
  (e: TMAFTextFieldOnFocusEvent) => {
    const value = e.currentTarget.value;
    const adjustedValue = isPercentage ? value.replace(',', '.') : value;
    const isValidNumber = !isNumber(adjustedValue);
    if (!isValidNumber) {
      e.preventDefault();
      return;
    }
    if (isWithoutDecimals) {
      e.currentTarget.value = `${convertToNumberWithoutDots(value)}`;
    }
    return event(e);
  };

export const getFormattingProps = ({
  value,
  onChange,
  onBlur,
  isMoney,
  isPercentage,
  custom,
  shouldAllowCents,
  isWithoutDecimals,
}: IGetFormattingProps) => {
  const genericEventsOverriden = {
    overridedOnChange: onChange
      ? overrideInputEventOnChange(onChange, isPercentage, isWithoutDecimals)
      : undefined,
    overrideOnBlur: onBlur
      ? overrideInputEventOnBlur(onBlur, isPercentage, isWithoutDecimals)
      : undefined,
  };
  if (isMoney) {
    return {
      adornment: 'kr',
      overridedValue: value || value === 0 ? formatSafeValueToMoney(value) : undefined,
      overridedOnChange: onChange
        ? overrideInputEventMoneyOnChange(onChange, shouldAllowCents)
        : undefined,
      overrideOnBlur: onBlur ? overrideInputEventMoneyOnBlur(onBlur, shouldAllowCents) : undefined,
    };
  } else if (isPercentage) {
    return {
      adornment: '%',
      ...genericEventsOverriden,
    };
  } else if (custom) {
    return {
      adornment: custom,
      ...genericEventsOverriden,
    };
  }
  return {
    ...genericEventsOverriden,
  };
};
