"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeRetro = exports.initialEquipmentState = exports.getEquipmentResponse = exports.getEquipmentRequest = exports.enableEquipmentsByIds = exports.enableEquipmentByIdWithPrice = exports.enableEquipment = exports.default = exports.createRetro = exports.clearEquipmentState = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
var _shortid = _interopRequireDefault(require("shortid"));
/**
 * Actions
 */
const getEquipmentRequest = exports.getEquipmentRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_FRAME_EQUIPMENT_REQUEST');
const getEquipmentResponse = exports.getEquipmentResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_FRAME_EQUIPMENT_RESPONSE');
const createRetro = exports.createRetro = (0, _reduxActions.createAction)('DASHBOARD_CREATE_RETRO_EQUIPMENT');
const removeRetro = exports.removeRetro = (0, _reduxActions.createAction)('DASHBOARD_REMOVE_RETRO_EQUIPMENT');
const enableEquipment = exports.enableEquipment = (0, _reduxActions.createAction)('DASHBOARD_ENABLE_EQUIPMENT');
const clearEquipmentState = exports.clearEquipmentState = (0, _reduxActions.createAction)('DASHBOARD_EQUIPMENT_CLEAR_STATE');
const enableEquipmentsByIds = exports.enableEquipmentsByIds = (0, _reduxActions.createAction)('DASHBOARD_ASSET_ENABLE_EQUIPMENTS_BY_IDS');
const enableEquipmentByIdWithPrice = exports.enableEquipmentByIdWithPrice = (0, _reduxActions.createAction)('DASHBOARD_ASSET_ENABLE_EQUIPMENT_BY_ID_WITH_PRICE');

/**
 * Initial state
 */
const initialSelectedState = {
  optional: [],
  services: []
};
const initialEquipmentState = exports.initialEquipmentState = (0, _immutable.fromJS)({
  equipment: {
    standard: [],
    additional: [],
    preconfigured: [],
    retrofit: [],
    colors: []
  },
  selected: initialSelectedState,
  gettingEquipment: false,
  formOpened: false
});

/**
 * Actions handlers
 */
var _default = exports.default = (0, _reduxActions.handleActions)({
  [createRetro](state) {
    return state.updateIn(['equipment', 'retrofit'], list => list.push({
      id: _shortid.default.generate(),
      name: '',
      price: '',
      dynamic: true
    }));
  },
  [removeRetro](state, _ref) {
    let {
      payload
    } = _ref;
    return state.updateIn(['equipment', 'retrofit'], list => (0, _immutable.fromJS)(list.toJS().filter(item => item.id !== payload.id)));
  },
  [enableEquipment](state, _ref2) {
    let {
      payload
    } = _ref2;
    return state.setIn(['equipment', payload.category, payload.index, 'enabled'], payload.enabled);
  },
  [enableEquipmentByIdWithPrice](state, _ref3) {
    let {
      payload
    } = _ref3;
    const {
      category,
      id,
      equipmentPrice = 0
    } = payload;
    const equipment = state.get('equipment').toJS();
    equipment[category].forEach((eq, i) => {
      if (eq.id === id) {
        equipment[category][i].enabled = true;
        equipment[category][i].equipmentPrice = equipmentPrice;
      }
    });
    return state.set('equipment', (0, _immutable.fromJS)(equipment));
  },
  [clearEquipmentState]() {
    return initialEquipmentState;
  },
  [getEquipmentRequest](state) {
    return state.set('gettingEquipment', true);
  },
  [getEquipmentResponse](state, _ref4) {
    let {
      payload,
      error
    } = _ref4;
    return !error ? state.set('equipment', (0, _immutable.fromJS)(payload)).set('gettingEquipment', false) : state.set('gettingEquipment', false);
  }
}, initialEquipmentState);