const Phone: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 21 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g>
      <path
        d="M3.6908,1.207 L1.8368,3.061 C1.2958,3.602 0.9988,4.32 0.9988,5.085 C0.9988,5.85 1.2958,6.569 1.8368,7.11 L13.0438,18.317 C14.1588,19.432 15.9048,19.362 17.0228,18.247 L18.8758,16.392 L15.3408,12.857 L13.9738,14.224 C13.7858,14.412 13.4538,14.412 13.2668,14.224 L5.8528,6.81 C5.6578,6.615 5.6578,6.298 5.8528,6.103 L7.2198,4.736 L3.6908,1.207 Z M14.9978,20.082 C14.0088,20.082 13.0198,19.707 12.2668,18.954 L1.1298,7.817 C-0.3762,6.311 -0.3762,3.86 1.1298,2.353 L3.3368,0.146 C3.5328,-0.049 3.8488,-0.049 4.0448,0.146 L8.2798,4.382 C8.4758,4.578 8.4758,4.894 8.2798,5.089 L6.9138,6.456 L13.6198,13.163 L14.9868,11.796 C15.1828,11.601 15.4988,11.601 15.6938,11.796 L19.9368,16.039 C20.1318,16.234 20.1318,16.55 19.9368,16.746 L17.7298,18.954 C16.9768,19.707 15.9868,20.082 14.9978,20.082 L14.9978,20.082 Z"
        id="path-1"
      />
    </g>
  </svg>
);

export default Phone;
