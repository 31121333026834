import { MouseEventHandler, ReactNode, FC } from 'react';
import { Box, SxProps, Theme } from '@mui/material';

import MAFTooltip from '../MAFTooltip/MAFTooltip';
import { handleCustomSx } from '../../utils';

import { styles } from './MAFTooltipLabel.styles';
import { propTypes } from './MAFTooltipLabel.propTypes';

export type TMAFTooltipLabelAlignment = 'start' | 'end';
export type TMAFTooltipLabelSide = 'top' | 'bottom' | 'left' | 'right';
export type TMAFTooltipLabelPlacement =
  | TMAFTooltipLabelSide
  | `${TMAFTooltipLabelSide}-${TMAFTooltipLabelAlignment}`;

export interface IMAFTooltipLabelProps {
  tooltipText?: ReactNode;
  additionalTooltipClass?: SxProps<Theme>;
  additionalIconClass?: SxProps<Theme>;
  label?: ReactNode;
  shouldDisableHoverListener?: boolean;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  placement?: TMAFTooltipLabelPlacement;
  sx?: SxProps<Theme>;
  leaveDelay?: number;
  leaveTouchDelay?: number;
}

const MAFTooltipLabel: FC<IMAFTooltipLabelProps> = ({
  tooltipText,
  additionalTooltipClass,
  additionalIconClass = {},
  label,
  shouldDisableHoverListener = false,
  onClick,
  placement = 'top',
  sx,
  leaveDelay,
  leaveTouchDelay,
}) =>
  tooltipText ? (
    <Box component="span" sx={sx}>
      {label}
      <Box
        component="span"
        onClick={onClick}
        sx={[styles.iconWrapper, ...handleCustomSx(additionalIconClass)]}
        role="button"
        tabIndex={-1}
      >
        <MAFTooltip
          placement={placement}
          title={tooltipText}
          disableHoverListener={shouldDisableHoverListener}
          leaveDelay={leaveDelay}
          leaveTouchDelay={leaveTouchDelay}
          additionalTooltipClass={additionalTooltipClass}
        >
          <Box component="span" sx={styles.icon}>
            ?
          </Box>
        </MAFTooltip>
      </Box>
    </Box>
  ) : (
    <>{label}</>
  );

MAFTooltipLabel.propTypes = propTypes;

export default MAFTooltipLabel;
