import { fromJS } from 'immutable';
import { createAction, handleActions, combineActions } from 'redux-actions';

/**
 * Actions
 */
export const getProductTermsPRHPRequest = createAction(
  'PRODUCT_TERMS_GET_PRODUCT_TERMS_PRIVATE_HP_REQUEST',
);
export const getProductTermsPRHPResponse = createAction(
  'PRODUCT_TERMS_GET_PRODUCT_TERMS_PRIVATE_HP_RESPONSE',
);
export const getProductTermsCMFLRequest = createAction(
  'PRODUCT_TERMS_GET_PRODUCT_TERMS_COMMERCIAL_FL_REQUEST',
);
export const getProductTermsCMFLResponse = createAction(
  'PRODUCT_TERMS_GET_PRODUCT_TERMS_COMMERCIAL_FL_RESPONSE',
);

/**
 * Initial state
 */
export const initialState = fromJS({
  loadingProductTerms: false,
  productTermsPRHP: {},
  productTermsCMFL: {},
});

/**
 * Actions handlers
 */
export default handleActions(
  {
    [combineActions(getProductTermsPRHPRequest, getProductTermsCMFLRequest)](state) {
      return state.set('loadingProductTerms', true);
    },
    [getProductTermsPRHPResponse](state, { payload, error }) {
      return !error
        ? state.set('productTermsPRHP', fromJS(payload)).set('loadingProductTerms', false)
        : state.set('loadingProductTerms', false);
    },
    [getProductTermsCMFLResponse](state, { payload, error }) {
      return !error
        ? state.set('productTermsCMFL', fromJS(payload)).set('loadingProductTerms', false)
        : state.set('loadingProductTerms', false);
    },
  },
  initialState,
);
