const Happy: React.FC<{}> = () => (
  <svg height="48px" width="48px" color="#003755" viewBox="-2 -2 24 24">
    <path
      id="d"
      d="M6 9a1 1 0 100-2 1 1 0 000 2zm8.316 4.062c-.064.038-.19.107-.37.197a11.92 11.92 0 01-1 .445c-.995.39-1.986.629-2.895.641h-.074c-.927 0-1.935-.239-2.945-.64a11.459 11.459 0 01-.983-.445 6.9 6.9 0 01-.361-.196.455.455 0 00-.467.78c.08.048.22.126.414.226.32.163.677.327 1.062.48 1.107.44 2.221.705 3.28.705h.086c1.035-.015 2.126-.277 3.216-.704.389-.153.75-.316 1.076-.48.197-.1.34-.177.42-.224a.455.455 0 10-.46-.785zM14 9a1 1 0 100-2 1 1 0 000 2zM1 10c0-4.963 4.037-9 9-9s9 4.037 9 9-4.037 9-9 9-9-4.037-9-9zm-1 0c0 5.523 4.477 10 10 10s10-4.477 10-10S15.523 0 10 0 0 4.477 0 10z"
    ></path>
  </svg>
);

export default Happy;
