"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  icon: _ref => {
    let {
      colors
    } = _ref;
    return {
      '& g': {
        fill: colors.alpha(colors.custom.contentAlternative.main.color, 0.4)
      }
    };
  },
  iconButton: _ref2 => {
    let {
      colors
    } = _ref2;
    return {
      width: 48,
      height: 48,
      '&:hover g': {
        fill: colors.solid.lightBlue
      }
    };
  },
  menu: _ref3 => {
    let {
      colors
    } = _ref3;
    return {
      '& .MuiMenu-paper': _objectSpread({}, colors.custom.body)
    };
  },
  menuItem: _ref4 => {
    let {
      typography: {
        main
      },
      colors
    } = _ref4;
    return {
      fontFamily: main,
      color: colors.custom.fontPrimary,
      padding: '0px 10px'
    };
  },
  menuIcon: _ref5 => {
    let {
      colors
    } = _ref5;
    return {
      color: "".concat(colors.custom.fontPrimary, " !important")
    };
  }
};