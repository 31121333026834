import { FC, ReactNode, useEffect } from 'react';
import { Box } from '@mui/material';

import { isScrolledToBottom, isScrolledToElementBottom } from '../../utils';
import MAFSpinner from '../MAFSpinner/MAFSpinner';

import { styles } from './MAFInfiniteScroll.styles';
import { propTypes } from './MAFInfiniteScroll.propTypes';

export interface IMAFInfiniteScrollProps {
  page: number;
  totalPages?: number;
  isLoading?: boolean;
  loadPage: (value: number) => void;
  component: ReactNode;
  upcomingPage?: number;
  shouldUseRef?: boolean;
  forwardedRef?: React.RefObject<Element>;
}

type TCreateScrollHandlerParams = {
  page: number;
  totalPages?: number;
  isLoading?: boolean;
};

const MAFInfiniteScroll: FC<IMAFInfiniteScrollProps> = ({
  page,
  totalPages,
  isLoading,
  loadPage,
  component,
  upcomingPage,
  shouldUseRef,
  forwardedRef,
}) => {
  useEffect(() => {
    const createScrollHandler =
      ({
        page: currentPage,
        totalPages: currentTotalPages,
        isLoading: isCurrentLoading,
      }: TCreateScrollHandlerParams) =>
      () => {
        if (
          currentTotalPages &&
          currentPage < currentTotalPages &&
          !isCurrentLoading &&
          (shouldUseRef ? isScrolledToElementBottom(forwardedRef?.current) : isScrolledToBottom())
        ) {
          loadPage(page + 1);
        }
      };
    const handler = createScrollHandler({ page, totalPages, isLoading });

    if (shouldUseRef) {
      if (forwardedRef && forwardedRef.current) {
        forwardedRef.current.addEventListener('scroll', handler, false);
      }
    } else {
      window.addEventListener('scroll', handler, false);
    }

    return () => {
      if (shouldUseRef) {
        if (forwardedRef && forwardedRef.current) {
          forwardedRef.current.removeEventListener('scroll', handler, false);
        }
      } else {
        window.removeEventListener('scroll', handler, false);
      }
    };
  }, [page, totalPages, isLoading, loadPage, forwardedRef]);

  const spinnerComponent = isLoading ? <MAFSpinner /> : <Box sx={styles.spinnerAlternative} />;

  return (
    <>
      {upcomingPage !== 1 || !isLoading ? component : undefined}
      {spinnerComponent}
    </>
  );
};

MAFInfiniteScroll.propTypes = propTypes;

export default MAFInfiniteScroll;
