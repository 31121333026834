"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: _ref => {
    let {
      colors
    } = _ref;
    return {
      backgroundColor: colors.custom.equipmentBox.background,
      borderRadius: '4px',
      minHeight: '100px',
      margin: '12px',
      padding: '0 12px',
      '& > div:first-child': {
        maxWidth: 385
      }
    };
  }
};