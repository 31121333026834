export const styles = {
  title: {
    margin: '25px 0 20px',
    textAlign: 'left',
  },
  paragraph: {
    marginBottom: 2,
    textAlign: 'justify',
  },
};
