import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  bar: {
    '&.MuiSnackbar-root': {
      width: '345px',
      maxWidth: 'calc(100vw - 16px)',
    },
  },
  success: ({ colors }) => ({
    '&.MuiSnackbarContent-root': {
      backgroundColor: colors.solid.green,
    },
  }),
  error: ({ colors }) => ({
    '&.MuiSnackbarContent-root': {
      backgroundColor: colors.solid.red,
    },
  }),
  bottomCenter: {
    '&.MuiSnackbar-anchorOriginBottomCenter': {
      margin: 'auto',
    },
  },
};
