import { IMAFColors, ISxStyles } from '../../theming/theme.type';

interface IProps {
  isMultiline?: boolean;
}

export const useStyles = ({ isMultiline }: IProps): ISxStyles => {
  const getInputMain = (colors: IMAFColors) => ({
    ...colors.custom.inputFull.input,
    padding: '12px',
    borderRadius: '2px',
    height: isMultiline ? 'unset' : 48,
    lineHeight: '20px',
    transition: 'border-color 150ms ease-in 0s',
    '&:hover:not(.Mui-disabled)': colors.custom.inputFull.hover,
  });
  return {
    container: {
      '&.MuiTextField-root': {
        minHeight: 70,
        marginTop: '5px',
        maxWidth: '100%',
      },
    },
    containerCompact: {
      '&.MuiTextField-root': {
        minHeight: 'auto',
      },
    },
    noBorder: ({ colors, breakpoints }) => ({
      '&.MuiInput-root': {
        ...getInputMain(colors),
        [breakpoints.up('lg')]: {
          borderRight: 'none',
        },
        [breakpoints.down('lg')]: {
          borderBottom: 'none',
        },
      },
    }),
    input: ({ colors }) => ({
      '&.MuiInput-root': getInputMain(colors),
    }),
    inputFull: {
      '&.MuiInput-root': {
        marginTop: 0,
        paddingTop: '30px !important',
        minHeight: 60,
      },
    },
    disabled: ({ colors }) => ({
      '&.MuiInput-root.Mui-disabled': {
        ...colors.custom.inputFull.disabled,
        '& > input': {
          ...colors.custom.inputFull.disabled,
          border: 'none',
        },
      },
    }),
    focused: ({ colors }) => ({
      '&.MuiInput-root.Mui-focused': colors.custom.inputFull.hover,
    }),
    error: ({ colors }) => ({
      '&.MuiInput-root.Mui-error': {
        border: `1px solid ${colors.custom.inputFull.error.background} !important`,
      },
    }),
    label: {
      '&.MuiInputLabel-root': {
        fontSize: 14,
        position: 'initial',
        textAlign: 'left',
        marginBottom: '-15px',
        transform: 'none',
        whiteSpace: 'normal',
      },
    },
    labelFull: {
      '&.MuiInputLabel-root': {
        position: 'absolute',
        zIndex: 1,
        marginTop: '5px',
        marginLeft: '12px',
      },
    },
    underline: {
      '&.MuiInput-underline': {
        '&:after': {
          borderBottom: 'none',
        },
        '&:before': {
          borderBottom: 'none !important',
        },
        '&.Mui-disabled:before': {
          borderBottom: 'none',
        },
        '&.Mui-error:after': {
          borderBottom: 'none',
        },
      },
    },
    textAlignRight: {
      '& .MuiInput-input': {
        textAlign: 'right',
      },
    },
    textAlignCenter: {
      '& .MuiInput-input': {
        textAlign: 'center',
      },
    },
    textAlignLeft: {
      '& .MuiInput-input': {
        textAlign: 'left',
      },
    },
  };
};
