const ArrowDown: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20px"
    height="11px"
    viewBox="0 0 20 11"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-HD" transform="translate(-404.000000, -377.000000)">
        <g id="Group" transform="translate(402.000000, 370.000000)">
          <g id="Page-1" transform="translate(2.000000, 7.000000)">
            <path
              d="M10.005231,10.3366 C9.76123097,10.3366 9.51723097,10.2476 9.32623097,10.0696 L0.320230969,1.7346 C-0.084769031,1.3596 -0.108769031,0.7266 0.266230969,0.3216 C0.642230969,-0.0844 1.27323097,-0.1094 1.67923097,0.2666 L10.004231,7.9736 L18.305231,0.2676 C18.709231,-0.1084 19.342231,-0.0854 19.719231,0.3206 C20.094231,0.7256 20.070231,1.3576 19.666231,1.7336 L10.685231,10.0696 C10.494231,10.2476 10.249231,10.3366 10.005231,10.3366"
              id="path-1"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowDown;
