import { FC, ReactElement, ReactNode, ChangeEvent, Children } from 'react';
import { Tabs, TabsProps, Divider, Box } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';

import { handleCustomSx } from '../../utils';

import { styles } from './MAFTabs.styles';
import MAFTab, { IMAFTabProps } from './MAFTab/MAFTab';
import { propTypes } from './MAFTabs.propTypes';

export interface IMAFTabsProps extends Omit<TabsProps, 'onChange'> {
  children?: ReactNode;
  tabs: IMAFTabProps[];
  customDivider?: ReactElement;
  isSwipeable?: boolean;
  onChange?: (event: ChangeEvent<{}>, value: any) => void;
}

const MAFTabs: FC<IMAFTabsProps> = ({
  children,
  value,
  tabs,
  customDivider,
  isSwipeable = false,
  sx,
  centered: isCentered = true,
  onChange,
  ...rest
}) => (
  <>
    <Tabs
      sx={[styles.root, styles.tabsStyles, ...handleCustomSx(sx)]}
      centered={isCentered}
      onChange={onChange}
      variant="fullWidth"
      TabIndicatorProps={{
        children: <Box component="span" />,
        sx: styles.indicatorStyles,
      }}
      {...rest}
      value={value}
    >
      {tabs.map((config, index) => (
        <MAFTab key={index} {...config} value={config.value} />
      ))}
    </Tabs>
    {customDivider || <Divider sx={styles.dividerStyles} />}
    {isSwipeable ? (
      <SwipeableViews index={tabs.findIndex((tab) => tab.value === value)}>
        {children}
      </SwipeableViews>
    ) : (
      Children.map(children, (child, index) => tabs[index].value === value && child)
    )}
  </>
);

MAFTabs.propTypes = propTypes;

export default MAFTabs;
