import { Box } from '@mui/material';

import { styles } from './MAFLoader.styles';
import { propTypes } from './MAFLoader.propTypes';

const MAFLoader: React.FC = () => (
  <Box>
    <Box component="span" sx={[styles.bubble, styles.first]}></Box>
    <Box component="span" sx={[styles.bubble, styles.second]}></Box>
    <Box component="span" sx={[styles.bubble, styles.third]}></Box>
  </Box>
);

MAFLoader.propTypes = propTypes;

export default MAFLoader;
