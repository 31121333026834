"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.attachIsCommercial = attachIsCommercial;
exports.attachRouteVendorId = attachRouteVendorId;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _effects = require("redux-saga/effects");
var _vendorDashboardAppSelectors = require("../vendorDashboardAppSelectors");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function attachRouteVendorId() {
  let payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function* () {
    const vendorId = yield (0, _effects.select)((0, _vendorDashboardAppSelectors.selectCurrentVendorId)());
    return _objectSpread(_objectSpread({}, payload), {}, {
      vendorId
    });
  }();
}
function attachIsCommercial() {
  let payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function* () {
    const isHandlingCommercialAsset = yield (0, _effects.select)((0, _vendorDashboardAppSelectors.selectIsHandlingCommercialAsset)());
    return _objectSpread(_objectSpread({}, payload), {}, {
      isCommercial: isHandlingCommercialAsset
    });
  }();
}