"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateApplicantsFromCase = exports.storeAssetFromCase = exports.setQuoteFromCase = exports.prefillEquipmentFromCase = exports.getCaseResponse = exports.getCaseRequest = void 0;
var _reduxActions = require("redux-actions");
/**
 * Actions
 */
const getCaseRequest = exports.getCaseRequest = (0, _reduxActions.createAction)('CASE_DETAIL_GET_CASE_REQUEST');
const getCaseResponse = exports.getCaseResponse = (0, _reduxActions.createAction)('CASE_DETAIL_GET_CASE_RESPONSE');
const prefillEquipmentFromCase = exports.prefillEquipmentFromCase = (0, _reduxActions.createAction)('CASE_DETAIL_EQUIPMENT_UPDATE_FROM_CASE');
const setQuoteFromCase = exports.setQuoteFromCase = (0, _reduxActions.createAction)('CASE_DETAIL_SET_QUOTE_FROM_CASE');
const storeAssetFromCase = exports.storeAssetFromCase = (0, _reduxActions.createAction)('CASE_DETAIL_STORE_ASSET_FROM_CASE');
const updateApplicantsFromCase = exports.updateApplicantsFromCase = (0, _reduxActions.createAction)('CASE_DETAIL_UPDATE_APPLICANTS_FROM_CASE');