/* eslint-disable camelcase */

import { ArrayUtils } from '@northstar/core/utils';
import { requestHandler } from '@northstar/core-ui/utils';
const { assetDomain } = requestHandler;

export const getFilteredAssetsPrivate = ({
  lease_period,
  yearly_mileage,
  price_min,
  price_max,
  body_types,
  transmission_types,
  powertrains,
  fuel_types,
  engines,
  engine_power_hps,
  engine_power_kws,
  trim_levels,
  models,
  brands,
  importerId,
} = {}) =>
  assetDomain.GET_FILTERS_PRIVATE.call({
    body: {
      lease_period,
      yearly_mileage,
      price_min,
      price_max,
      body_types: ArrayUtils.forceConvertToArray(body_types),
      transmission_types: ArrayUtils.forceConvertToArray(transmission_types),
      powertrains: ArrayUtils.forceConvertToArray(powertrains),
      fuel_types: ArrayUtils.forceConvertToArray(fuel_types),
      engines: ArrayUtils.forceConvertToArray(engines),
      engine_power_hps: ArrayUtils.forceConvertToArray(engine_power_hps),
      engine_power_kws: ArrayUtils.forceConvertToArray(engine_power_kws),
      trim_levels: ArrayUtils.forceConvertToArray(trim_levels),
      models: ArrayUtils.forceConvertToArray(models),
      brands: ArrayUtils.forceConvertToArray(brands),
      importerId,
    },
  });

export const getFilteredAssetsCommercial = ({
  lease_period,
  yearly_mileage,
  price_min,
  price_max,
  body_types,
  transmission_types,
  powertrains,
  fuel_types,
  engines,
  engine_power_hps,
  engine_power_kws,
  trim_levels,
  models,
  brands,
  vehicle_types,
  importerId,
} = {}) =>
  assetDomain.GET_FILTERS_COMMERCIAL.call({
    body: {
      lease_period,
      yearly_mileage,
      price_min,
      price_max,
      body_types: ArrayUtils.forceConvertToArray(body_types),
      transmission_types: ArrayUtils.forceConvertToArray(transmission_types),
      powertrains: ArrayUtils.forceConvertToArray(powertrains),
      fuel_types: ArrayUtils.forceConvertToArray(fuel_types),
      engines: ArrayUtils.forceConvertToArray(engines),
      engine_power_hps: ArrayUtils.forceConvertToArray(engine_power_hps),
      engine_power_kws: ArrayUtils.forceConvertToArray(engine_power_kws),
      trim_levels: ArrayUtils.forceConvertToArray(trim_levels),
      models: ArrayUtils.forceConvertToArray(models),
      brands: ArrayUtils.forceConvertToArray(brands),
      vehicle_type: vehicle_types,
      importerId,
    },
  });
