import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  root: ({ colors }) => ({
    '&.MuiSwitch-root': {
      width: 40,
      height: 20,
      padding: 0,
      margin: '9px',
      overflow: 'visible',
      borderRadius: '1000px',
      ...colors.custom.switch.base,
    },
  }),
  switchBase: ({ colors }) => ({
    '& .MuiSwitch-switchBase': {
      padding: '1px',
      '&.Mui-checked': {
        '& + $track': {
          ...colors.custom.switch.disabled,
          '&:after': {
            opacity: '1',
          },
        },
      },
    },
  }),
  thumb: ({ colors }) => ({
    '& .MuiSwitch-thumb': {
      height: 18,
      width: 18,
      ...colors.custom.switch.thumb,
    },
  }),
  track: ({ colors }) => ({
    '& .MuiSwitch-track': {
      opacity: '1 !important',
      borderRadius: '1000px',
      ...colors.custom.switch.disabled,
    },
  }),
  checked: ({ colors }) => ({
    '& .MuiSwitch-track': colors.custom.switch.enabled,
  }),
  disabled: {
    '&.MuiSwitch-root.Mui-disabled': {
      opacity: '0.5 !important',
    },
  },
};
