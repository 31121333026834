import { FC, ReactChild } from 'react';
import { Box } from '@mui/material';

import ArrowNext from '../Icons/ArrowNext';
import MAFButton from '../MAFButton/MAFButton';

import { styles } from './MAFSummaryCard.styles';
import { propTypes } from './MAFSummaryCard.propTypes';

export interface IMAFSummaryCardProps {
  date?: Date | string;
  icon?: ReactChild;
  purposeText?: ReactChild;
  label: ReactChild;
  loanAmount: ReactChild;
  buttonText: ReactChild;
  onClick: () => void;
}

const MAFSummaryCard: FC<IMAFSummaryCardProps> = ({
  date,
  icon,
  purposeText,
  label,
  loanAmount,
  buttonText,
  onClick,
}) => (
  <Box sx={styles.cardWrapper}>
    <Box sx={styles.cardHeader}>
      {date && <Box sx={styles.date}>{date}</Box>}
      {icon && <Box sx={styles.iconWrapper}>{icon}</Box>}
      {purposeText && (
        <Box>
          <Box sx={styles.purposeText}>{purposeText}</Box>
          <Box sx={styles.purposeDividerWrapper}>
            <Box sx={styles.purposeDivider} />
          </Box>
        </Box>
      )}

      <Box sx={styles.loanLabel}>{label}</Box>
      <Box sx={styles.loanAmount}>{loanAmount}</Box>
    </Box>
    <Box sx={styles.cardSeparator}>
      <Box sx={styles.cardBorderWrapper}>
        <Box sx={styles.cardBorder} />
      </Box>
    </Box>
    <Box sx={styles.cardFooter}>
      <MAFButton isLink onClick={onClick} sx={styles.footerButton}>
        <>
          {buttonText}
          <Box component="span" sx={styles.footerArrow}>
            <ArrowNext height={12} width={12} />
          </Box>
        </>
      </MAFButton>
    </Box>
  </Box>
);

MAFSummaryCard.propTypes = propTypes;

export default MAFSummaryCard;
