import { FC, ReactChild, SyntheticEvent } from 'react';
import {
  FormHelperText,
  Popper,
  Grow,
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  Box,
  SxProps,
  Theme,
} from '@mui/material';

import MAFTextField from '../MAFTextField/MAFTextField';
import MAFIcon from '../MAFIcon/MAFIcon';
import ArrowDown from '../Icons/ArrowDown';
import { ObjectUtils, handleCustomSx } from '../../utils';

import MAFChip from './MAFChip/MAFChip';
import { styles } from './MAFAutoSuggestion.styles';
import { propTypes } from './MAFAutoSuggestion.propTypes';

const AUTO_COMPLETE_DISABLE = 'off';

export interface IMAFAutoSuggestionValue {
  label?: Nullable<ReactChild>;
  labelId?: Nullable<string>;
  value?: ReactChild;
  key?: ReactChild;
}

export type TMAFAutoSuggestionValue = IMAFAutoSuggestionValue | IMAFAutoSuggestionValue[];

export interface IMAFAutoSuggestionProps {
  name?: string;
  isError?: boolean;
  helperText?: ReactChild;
  isMultiple?: boolean;
  options: IMAFAutoSuggestionValue[];
  label?: ReactChild;
  placeholder?: string;
  sx?: SxProps<Theme>;
  shouldDisableClearable?: boolean;
  isWithPaceholderLabel?: boolean;
  labelClassName?: SxProps<Theme>;
  limit?: number;
  value?: TMAFAutoSuggestionValue;
  onChange: (value: TMAFAutoSuggestionValue) => void;
  filterChipColor?: string;
  customChipClass?: SxProps<Theme>;
  popupIconClass?: SxProps<Theme>;
  customGetOptionSelected?: (
    value: IMAFAutoSuggestionValue,
    option: IMAFAutoSuggestionValue,
  ) => boolean;
  customLabelClass?: SxProps<Theme>;
  isDisabled?: boolean;
  'ns-target'?: string;
  getOptionLabel?: (option: IMAFAutoSuggestionValue) => string;
  onBlur?: (value?: TMAFAutoSuggestionValue) => string;
  id?: string;
}

const MAFAutoSuggestion: FC<IMAFAutoSuggestionProps> = ({
  id,
  name,
  isError,
  helperText,
  isMultiple = false,
  options,
  label,
  placeholder,
  sx,
  shouldDisableClearable = true,
  isWithPaceholderLabel,
  labelClassName,
  limit,
  value,
  onChange,
  filterChipColor,
  customChipClass,
  popupIconClass,
  customGetOptionSelected,
  customLabelClass,
  isDisabled,
  'ns-target': nsTarget,
  getOptionLabel,
  onBlur,
}) => {
  const handleOnChange = (
    _: SyntheticEvent,
    changeValue: TMAFAutoSuggestionValue,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<IMAFAutoSuggestionValue>,
  ) => {
    if (reason === 'removeOption' && Array.isArray(value)) {
      onChange(value.filter((item) => item.value !== details.option.value));
      return;
    }
    if (!limit || (Array.isArray(changeValue) && changeValue.length <= limit)) {
      onChange(changeValue);
    }
  };

  const handleOnBlur = () => onBlur && onBlur(value);

  const emptyValue = isMultiple ? [] : null;

  const isWithValue = !ObjectUtils.isNullOrEmpty(value);

  const getOptionSelected =
    customGetOptionSelected && isWithValue
      ? (option: IMAFAutoSuggestionValue) =>
          customGetOptionSelected(value as IMAFAutoSuggestionValue, option)
      : undefined;

  return (
    <Box sx={sx} id={id}>
      {isWithPaceholderLabel && isWithValue && (
        <FormHelperText
          sx={[isMultiple && styles.multiValueLabel, ...handleCustomSx(labelClassName)]}
        >
          {placeholder}
        </FormHelperText>
      )}

      <Autocomplete
        PopperComponent={(params) => (
          <Popper
            {...params}
            disablePortal
            modifiers={[
              {
                name: 'flip',
                enabled: false,
              },
            ]}
            transition
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Box>{params.children}</Box>
              </Grow>
            )}
          </Popper>
        )}
        openOnFocus
        popupIcon={<MAFIcon Icon={ArrowDown} sx={styles.arrowDownIcon} />}
        onChange={handleOnChange}
        disableClearable={shouldDisableClearable}
        value={isWithValue ? value : emptyValue}
        options={options}
        getOptionLabel={
          getOptionLabel || ((option: IMAFAutoSuggestionValue) => option?.label?.toString() || '')
        }
        isOptionEqualToValue={getOptionSelected}
        multiple={isMultiple}
        renderTags={(_, getTagProps) =>
          isWithValue &&
          (value as IMAFAutoSuggestionValue[]).map((option, index) => (
            <MAFChip
              isCompact
              customChipClass={customChipClass}
              label={option.label || undefined}
              {...getTagProps({ index })}
              key={index}
              color={filterChipColor}
              disabled={isDisabled}
              onBlur={onBlur}
            />
          ))
        }
        renderInput={({
          InputProps,
          disabled: isInputDisabled,
          fullWidth: isFullWidth,
          inputProps,
        }) => (
          <MAFTextField
            disabled={isInputDisabled}
            fullWidth={isFullWidth}
            inputProps={{
              ...inputProps,
              autoComplete: AUTO_COMPLETE_DISABLE,
            }}
            name={name}
            label={label}
            additionalInputProps={InputProps}
            error={isError}
            helperText={helperText}
            placeholder={!isWithValue ? placeholder : ''}
            ns-target={nsTarget}
            textFieldClass={styles.textField}
            customLabelClass={customLabelClass}
          />
        )}
        componentsProps={{
          paper: { sx: [styles.paper, styles.noOptions, styles.option, styles.listBox] },
        }}
        sx={[
          styles.root,
          styles.icon,
          ...handleCustomSx(popupIconClass),
          isWithValue && styles.clearIndicator,
          styles.input,
        ]}
        disabled={isDisabled}
        onBlur={handleOnBlur}
      />
    </Box>
  );
};

MAFAutoSuggestion.propTypes = propTypes;

export default MAFAutoSuggestion;
