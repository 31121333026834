"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    '&:not(:last-child)': {
      marginBottom: '15px'
    }
  },
  title: _ref => {
    let {
      fontSizes: {
        small
      }
    } = _ref;
    return {
      fontWeight: 500,
      marginBottom: '5px',
      fontSize: small
    };
  }
};