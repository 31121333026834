import { keyframes } from '@mui/styled-engine';

import { IMAFColors, ISxStyles } from '../../theming/theme.type';

const getLoadingAnimation = (colors: IMAFColors) => keyframes`
  0% { background: transparent }
  50% { background: ${colors.custom.buttons.loading.background} }
  100% { background: transparent }
`;

export const styles: ISxStyles = {
  bubble: {
    background: 'transparent',
    borderRadius: '50%',
    display: 'inline-block',
    height: 10,
    width: 10,
    margin: '0px 3px',
  },
  first: ({ colors }) => ({
    animation: `1.5s linear 0.15s infinite normal none running ${getLoadingAnimation(colors)}`,
  }),
  second: ({ colors }) => ({
    animation: `1.5s linear 0.3s infinite normal none running ${getLoadingAnimation(colors)}`,
  }),
  third: ({ colors }) => ({
    animation: `1.5s linear 0.45s infinite normal none running ${getLoadingAnimation(colors)}`,
  }),
};
