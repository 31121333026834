import { PROCESS_VARIANCES, ObjectUtils } from '@northstar/core';
import { app, asset as assetModule } from '@northstar/core-ui/modules';
import { callWithAttachers } from '@northstar/core-ui/utils/redux-saga-utils';
import { reactUtils } from '@northstar/core-ui/utils';
import dayjs from 'dayjs';
import { change } from 'redux-form';
import { call, put, takeLatest, select } from 'redux-saga/effects';

import { calculateMaximumAvailableDuration } from 'utils/domain-utils';

import { updateImporter, getCase } from '../caseDetail/caseDetailSaga';
import {
  selectCase,
  selectCaseAsset,
  selectIsCasePrivateOperationalLease,
} from '../caseDetail/caseDetailSelectors';
import {
  HP_FORM,
  HP_FORM_CAR_REGISTRATION_DATE,
  HP_FORM_DURATION,
} from '../hirePurchase/hirePurchaseConstants';
import { CMFL_FORM, CMFL_FORM_DURATION, DURATION_STEP_SIZE } from '../quote/quoteConstants';
import { getProductTermsPRHP, getProductTermsCMFL } from '../productTerms/productTermsSaga';
import {
  selectProductTermsCMFL,
  selectProductTermsPRHP,
} from '../productTerms/productTermsSelectors';
import {
  getVendorTermsPROL,
  getVendorTermsCMOL,
  getVendorTermsCMFL,
  getVendorTermsPRHP,
} from '../vendor/vendorTermsSaga';
import { updateSelectedLeasingTerm } from '../vendor/vendorTermsReducer';
import { selectVendorTermsCMFL } from '../vendor/vendorTermsSelectors';
import { createAttacherForTermsToUse } from '../vendor/vendorTermsAttachers';
import { prefillNewCarsFlowEq } from '../quote/quoteSaga';

import { getCarAgeMonths } from './asset-utils';
import {
  getAssetRequest,
  getAssetResponse,
  getCarInfoRequest,
  getCarInfoResponse,
  prefillAssetDataForConfigurationOLRequest,
  prefillAssetDataForConfigurationHPFLRequest,
  prefillAssetDataForConfigurationResponse,
  changeAssetPreviousLicencePlate,
  changeAssetPreviousId,
} from './assetReducer';
// Frame Module
import { getEquipmentResponse } from './equipmentReducer';
import { submitFrameResponse, changeFramePreviousId } from './frameReducer';
import { getFrame } from './frameSaga';

// Equipment module
// Case detail module
// Hire purchase form
const { frameMappers, assetMappers, equipmentMappers } = assetModule;
const { responseMappers: frameResponseMappers } = frameMappers;
const { responseMappers: equipmentResponseMappers } = equipmentMappers;
const { responseMappers } = assetMappers;

export function* getAsset({ payload }) {
  try {
    const {
      assetId,
      assetVersionId,
      isAssetCommercial,
      configuration,
      skipEquipment,
      redirect,
      keepAssetFromCase,
    } = payload;
    const apiToCall = isAssetCommercial ? assetModule.getAssetCommercial : assetModule.getAsset;
    const response = yield callWithAttachers({
      endpoint: apiToCall,
      payload: {
        assetId,
        assetVersionId,
      },
      attachers: [
        createAttacherForTermsToUse(
          isAssetCommercial ? PROCESS_VARIANCES.COMMERCIAL_OL : PROCESS_VARIANCES.PRIVATE_OL,
        ),
      ],
    });
    yield put(submitFrameResponse(frameResponseMappers.mapFrame(response.frame)));
    if (configuration) {
      const isCasePROL = yield select(selectIsCasePrivateOperationalLease());
      const termsRequest = isCasePROL ? getVendorTermsPROL : getVendorTermsCMOL;
      yield call(termsRequest);
      const mappedEquipment =
        equipmentResponseMappers.mapEquipmentResponseFromConfiguration(response);
      yield put(getEquipmentResponse(mappedEquipment));
    } else if (!skipEquipment) {
      yield put(
        getEquipmentResponse(
          equipmentResponseMappers.mapAssetEquipment({
            equipment: response.optional_equipment,
            preconfiguredEquipment: response.preconfigured_equipment,
            currentEquipment: { standard: response.included_equipment },
            withoutStandard: true,
          }),
        ),
      );
    }
    if (keepAssetFromCase) return;
    yield put(getAssetResponse(responseMappers.mapAsset(response, !isAssetCommercial)));
    if (typeof redirect === 'function') {
      yield call(redirect);
    }
  } catch (e) {
    yield put(getAssetResponse(e));
    yield put(app.displayError('errors.could_not_get_asset'));
  }
}

export function* adjustRegistrationDateAndDurationPRHP(registrationDate) {
  yield call(getProductTermsPRHP);
  const { maxDurationMonths, maxCarAgeMonths } = yield select(selectProductTermsPRHP());
  if (registrationDate !== null) {
    yield put(
      change(
        HP_FORM,
        HP_FORM_CAR_REGISTRATION_DATE,
        registrationDate ? dayjs(registrationDate) : null,
      ),
    );
  }
  const carAgeMonths = getCarAgeMonths(registrationDate);
  const duration = calculateMaximumAvailableDuration({
    carAgeMonths,
    maxDurationMonths,
    maxCarAgeMonths,
  });
  yield put(change(HP_FORM, HP_FORM_DURATION, duration));
}

export function* adjustDurationCMFL(registrationDate) {
  yield call(getProductTermsCMFL);
  let vendorTermsCMFL = yield select(selectVendorTermsCMFL());
  const isTermsLoaded = Boolean(vendorTermsCMFL.defaultLeasePeriod);
  if (!isTermsLoaded) {
    yield call(getVendorTermsCMFL);
    vendorTermsCMFL = yield select(selectVendorTermsCMFL());
  }
  const { maxCarAgeMonthsEnd } = yield select(selectProductTermsCMFL());
  const carAgeMonths = getCarAgeMonths(registrationDate);
  const duration = calculateMaximumAvailableDuration({
    carAgeMonths,
    maxCarAgeMonths: maxCarAgeMonthsEnd,
  });
  const adjustedDuration = Math.floor(duration / DURATION_STEP_SIZE) * DURATION_STEP_SIZE;
  if (adjustedDuration < vendorTermsCMFL.defaultLeasePeriod) {
    yield put(change(CMFL_FORM, CMFL_FORM_DURATION, adjustedDuration));
  }
}

export function* getCarInfo({ payload }) {
  const { plateNumber, redirect, productType } = payload;
  try {
    const response = yield callWithAttachers({
      endpoint: assetModule.getCarInfo,
      payload: {
        plateNumber,
        productType,
      },
      attachers: [createAttacherForTermsToUse()],
    });
    if (Object.keys(response).length === 0) {
      throw new Error(`Car ${plateNumber} does not exist`);
    }
    const { registrationDate, ...mappedResponse } = responseMappers.mapAssetCarInfo(response);

    yield put(getCarInfoResponse({ registrationDate, ...mappedResponse }));
    yield call(updateImporter);

    const { resourceId } = yield select(selectCase());

    if (redirect) {
      yield call(redirect, { resourceId });
    }

    if (productType === PROCESS_VARIANCES.PRIVATE_HP) {
      yield call(adjustRegistrationDateAndDurationPRHP, registrationDate);
    } else {
      yield call(adjustDurationCMFL, registrationDate);
    }
  } catch (e) {
    if (e.httpResponse?.status === 404) {
      yield put(getCarInfoResponse(new Error('car_selection.plate_not_found')));
    } else {
      yield put(getCarInfoResponse(e));
    }
  }
}
export function* prefillAssetDataForConfigurationOL({ payload }) {
  try {
    const {
      isCaseCommercial,
      redirect,
      getTerms,
      keepAssetFromCase,
      getCase: shouldGetCase,
    } = payload;

    if (getTerms) {
      yield call(isCaseCommercial ? getVendorTermsCMOL : getVendorTermsPROL);
    }

    if (shouldGetCase) {
      const { resourceId, draftScenarioId } = yield select(selectCase());
      yield call(getCase, { payload: { resourceId, draftScenarioId } });
    }

    const currentCase = yield select(selectCase());
    const { asset: currentCaseAsset } = currentCase || {};
    const { id: assetId, leasePeriod, detailsPL, detailsCMOL } = currentCaseAsset || {};

    yield put(updateSelectedLeasingTerm({ key: 'period', value: leasePeriod }));

    const detailsOL = detailsPL || detailsCMOL;
    if (detailsOL) {
      const { yearlyMileage } = detailsOL;
      yield put(updateSelectedLeasingTerm({ key: 'mileage', value: yearlyMileage }));
    }

    if (assetId) {
      yield put(changeAssetPreviousId({ id: assetId }));
      yield call(getAsset, {
        payload: {
          assetId,
          isAssetCommercial: isCaseCommercial,
          redirect,
          keepAssetFromCase,
        },
      });
    } else if (typeof redirect === 'function') {
      redirect();
    }
    yield put(prefillAssetDataForConfigurationResponse());
  } catch (e) {
    yield put(app.displayError(e));
    yield put(prefillAssetDataForConfigurationResponse(e));
  }
}

export function* prefillAssetDataForConfigurationHPFL({ payload }) {
  try {
    const {
      isCaseCommercial,
      redirect,
      getTerms,
      historySearch = '',
      getCase: shouldGetCase,
    } = payload;

    if (getTerms) {
      yield call(isCaseCommercial ? getVendorTermsCMFL : getVendorTermsPRHP);
    }

    if (shouldGetCase) {
      const { resourceId, draftScenarioId } = yield select(selectCase());
      yield call(getCase, { payload: { resourceId, draftScenarioId } });
    }

    const currentCase = yield select(selectCase());
    const caseAsset = yield select(selectCaseAsset());
    const { frameId } = caseAsset;

    const {
      registrationNumber: queryParamRegistrationNumber,
      frameId: queryParamFrameId,
      vehicleType,
    } = reactUtils.getParamsFromQuery(historySearch);

    if (ObjectUtils.isNullOrEmpty(currentCase.asset)) {
      if (queryParamRegistrationNumber) {
        yield call(getCarInfo, {
          payload: {
            plateNumber: queryParamRegistrationNumber,
            productType: isCaseCommercial
              ? PROCESS_VARIANCES.COMMERCIAL_FL
              : PROCESS_VARIANCES.PRIVATE_HP,
          },
        });
      } else if (queryParamFrameId) {
        yield call(getFrame, { payload: { frameId: queryParamFrameId, vehicleType } });
      }
    }

    const { asset: currentCaseAsset } = currentCase || {};
    const { leasePeriod, registrationNumber } = currentCaseAsset || {};

    if (leasePeriod) {
      yield put(updateSelectedLeasingTerm({ key: 'period', value: leasePeriod }));
    }

    if (registrationNumber || queryParamRegistrationNumber) {
      yield put(
        changeAssetPreviousLicencePlate({
          licencePlate: registrationNumber || queryParamRegistrationNumber,
        }),
      );
    }

    if (frameId || queryParamFrameId) {
      yield put(changeFramePreviousId({ id: frameId || queryParamFrameId }));
      yield call(prefillNewCarsFlowEq, {
        payload: { frameId: frameId || queryParamFrameId, redirect },
      });
    } else if (typeof redirect === 'function') {
      redirect();
    }
    yield put(prefillAssetDataForConfigurationResponse());
  } catch (e) {
    yield put(app.displayError(e));
    yield put(prefillAssetDataForConfigurationResponse(e));
  }
}

export default function* asset() {
  yield takeLatest(getCarInfoRequest, getCarInfo);
  yield takeLatest(getAssetRequest, getAsset);
  yield takeLatest(prefillAssetDataForConfigurationOLRequest, prefillAssetDataForConfigurationOL);
  yield takeLatest(
    prefillAssetDataForConfigurationHPFLRequest,
    prefillAssetDataForConfigurationHPFL,
  );
}
