"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  subtitle: _ref => {
    let {
      typography: {
        title
      },
      fontSizes: {
        small
      }
    } = _ref;
    return {
      textAlign: 'left',
      padding: '20px 0',
      width: '100%',
      fontFamily: title,
      fontWeight: 500,
      fontSize: small,
      '&span:first-child': {
        marginRight: '5px'
      }
    };
  }
};