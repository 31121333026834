import { PROCESS_VARIANCES } from '@northstar/core';
import { app, assetList } from '@northstar/core-ui/modules';
import { callWithAttachers } from '@northstar/core-ui/utils/redux-saga-utils';
import { put, takeLatest } from 'redux-saga/effects';

import { createAttacherForTermsToUse } from 'modules/vendor/vendorTermsAttachers';

import * as assetsApi from './assetsApi';
import {
  getAssetsPrivateRequest,
  getAssetsPrivateResponse,
  getAssetsCommercialRequest,
  getAssetsCommercialResponse,
  getAssetsDynamicRequest,
} from './assetsReducer';
const { assetListMappers } = assetList;
const { responseMappers } = assetListMappers;

export function* getAssetListDynamic({ payload = {} }) {
  try {
    const { productType } = payload;
    const actionToDispatch =
      productType === PROCESS_VARIANCES.PRIVATE_OL
        ? getAssetsPrivateRequest
        : getAssetsCommercialRequest;
    yield put(actionToDispatch());
  } catch (e) {
    yield put(app.displayError('errors.could_not_switch_customer_type'));
  }
}

export function* getAssetListPrivate() {
  try {
    const response = yield callWithAttachers({
      endpoint: assetsApi.getAssetsPrivate,
      attachers: [createAttacherForTermsToUse(PROCESS_VARIANCES.PRIVATE_OL)],
    });
    yield put(getAssetsPrivateResponse(responseMappers.mapAssets(response, true)));
  } catch (e) {
    yield put(getAssetsPrivateResponse(e));
    yield put(app.displayError('errors.could_not_get_asset_list'));
  }
}

export function* getAssetListCommercial() {
  try {
    const response = yield callWithAttachers({
      endpoint: assetsApi.getAssetsCommercial,
      attachers: [createAttacherForTermsToUse(PROCESS_VARIANCES.COMMERCIAL_OL)],
    });
    yield put(getAssetsCommercialResponse(responseMappers.mapAssets(response)));
  } catch (e) {
    yield put(getAssetsCommercialResponse(e));
    yield put(app.displayError('errors.could_not_get_asset_list'));
  }
}

export default function* assets() {
  yield takeLatest(getAssetsPrivateRequest, getAssetListPrivate);
  yield takeLatest(getAssetsCommercialRequest, getAssetListCommercial);
  yield takeLatest(getAssetsDynamicRequest, getAssetListDynamic);
}
