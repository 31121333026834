import { ISxStyles } from '@asfi/maf';

export const styles: ISxStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  body: ({ breakpoints }) => ({
    width: 300,
    [breakpoints.up('sm')]: {
      width: 600,
    },
    '@media print': {
      width: 600,
    },
  }),
};
