import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  /**
   * @ignore
   */
  children: PropTypes.node,
  customHelperText: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  /**
   * Callback fired when the value is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange: PropTypes.func,
  phonePrefix: PropTypes.string.isRequired,
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value: PropTypes.any,
} as any;
