"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAssetListPrivate = exports.getAssetListCommercial = void 0;
var _requestHandler = _interopRequireDefault(require("../../utils/request-handler"));
const {
  assetDomain
} = _requestHandler.default;
const getAssetListPrivate = _ref => {
  let {
    status,
    active,
    importerId,
    vendorId
  } = _ref;
  return assetDomain.GET_LIST_ADMIN_PRIVATE.call({
    urlParams: {
      status,
      active,
      importerId,
      vendorId
    }
  });
};
exports.getAssetListPrivate = getAssetListPrivate;
const getAssetListCommercial = _ref2 => {
  let {
    status,
    active,
    vehicleType,
    importerId,
    vendorId
  } = _ref2;
  return assetDomain.GET_LIST_ADMIN_COMMERCIAL.call({
    urlParams: {
      status,
      active,
      vehicle_type: vehicleType,
      importerId,
      vendorId
    }
  });
};
exports.getAssetListCommercial = getAssetListCommercial;