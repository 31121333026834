"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.propTypes = void 0;
var PropTypes = _interopRequireWildcard(require("prop-types"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const propTypes /* remove-proptypes */ = exports.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  actionComponent: PropTypes.node,
  additionalTopAdjustment: PropTypes.number,
  children: PropTypes.node,
  forwardedRef: PropTypes.shape({
    current: (props, propName) => {
      if (props[propName] == null) {
        return null;
      } else if (typeof props[propName] !== 'object' || props[propName].nodeType !== 1) {
        return new Error("Expected prop '".concat(propName, "'  to be of type Element"));
      }
      return null;
    }
  }).isRequired,
  mainComponent: PropTypes.node,
  selectedItems: PropTypes.number.isRequired,
  selectedItemsActionComponent: PropTypes.node,
  selectedItemsActionLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]).isRequired,
  widthAdjustments: PropTypes.number
};