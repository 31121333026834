import { Box } from '@mui/material';

import { styles } from './Overlay.styles';
import { propTypes } from './Overlay.propTypes';

interface IOverlayProps {
  onClose: () => void;
}

const Overlay: React.FC<IOverlayProps> = ({ onClose }) => (
  <Box onClick={onClose} sx={styles.overlay} />
);

Overlay.propTypes = propTypes;

export default Overlay;
