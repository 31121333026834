"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  popoverRoot: _ref => {
    let {
      typography,
      colors,
      breakpoints
    } = _ref;
    return {
      '& .MuiPaper-root': _objectSpread(_objectSpread(_objectSpread({
        paddingBottom: '20px'
      }, typography.baseFont), colors.custom.body), {}, {
        textAlign: 'left',
        maxHeight: 350,
        [breakpoints.up('sm')]: {
          transform: 'translate3d(20px, 0, 0) !important',
          width: '100%',
          maxWidth: 744
        },
        [breakpoints.up('md')]: {
          transform: 'translate3d(10px, 0, 0) !important'
        },
        [breakpoints.only('xs')]: {
          width: '100%',
          left: 10
        }
      })
    };
  },
  title: _ref2 => {
    let {
      fontSizes
    } = _ref2;
    return {
      padding: '10px 20px',
      fontSize: fontSizes.small,
      fontWeight: 600
    };
  }
};