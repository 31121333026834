"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  iconInverted: _ref => {
    let {
      colors
    } = _ref;
    return {
      fill: colors.custom.fontPrimary
    };
  },
  iconContainer: {
    padding: '25px 20px 0px'
  },
  iconWithTextContainer: {
    padding: '0px 10px'
  },
  hoverable: {
    cursor: 'pointer'
  },
  container: _ref2 => {
    let {
      breakpoints
    } = _ref2;
    return {
      width: 'auto',
      [breakpoints.down('lg')]: {
        padding: '10px 0px'
      }
    };
  },
  icon: _ref3 => {
    let {
      colors
    } = _ref3;
    return {
      '& path': {
        fill: colors.custom.fontPrimary
      }
    };
  }
};