import { MAFTypography } from '@asfi/maf';
import { Box } from '@mui/material';
import { ReactChild } from 'react';

import { styles } from './detailList.styles';

interface IListItem {
  label: ReactChild;
}

interface IDetailListProps {
  list: IListItem[];
}

const DetailList: React.FC<IDetailListProps> = ({ list }) => (
  <Box component="ul" sx={styles.list}>
    {list.map(({ label }, index) => (
      <Box component="li" key={index} sx={styles.listItem}>
        <MAFTypography variant="body1">{label}</MAFTypography>
      </Box>
    ))}
  </Box>
);

export default DetailList;
