import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  direction: PropTypes.oneOf(['asc', 'desc']),
  isUplift: PropTypes.bool,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.func,
      fallbackName: PropTypes.string,
      isHidden: PropTypes.bool,
      isUnsortable: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
        .isRequired,
      name: PropTypes.string.isRequired,
      renderFunc: PropTypes.func,
      shouldStopPropagation: PropTypes.bool,
      sortField: PropTypes.string,
      sortTitle: PropTypes.node,
      subName: PropTypes.string,
    }),
  ).isRequired,
  onOrder: PropTypes.func,
  orderBy: PropTypes.string,
  shouldAdjustHeaderPosition: PropTypes.bool,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
} as any;
