const Sad: React.FC<{}> = () => (
  <svg height="48px" width="48px" color="#003755" viewBox="-2 -2 24 24">
    <path
      id="a"
      d="M6 9a1 1 0 100-2 1 1 0 000 2zm8.316 6.192a7.215 7.215 0 00-.37-.196 11.92 11.92 0 00-1-.445c-.995-.39-1.986-.629-2.895-.641h-.074c-.927 0-1.935.239-2.945.64-.356.141-.687.293-.983.445a6.9 6.9 0 00-.361.195.455.455 0 11-.467-.78c.08-.047.22-.125.414-.225.32-.164.677-.327 1.062-.48 1.107-.44 2.221-.705 3.28-.705h.086c1.035.015 2.126.277 3.216.704.389.153.75.316 1.076.48.197.099.34.177.42.224a.455.455 0 11-.46.784zM14 9a1 1 0 100-2 1 1 0 000 2zm-4 10c-4.963 0-9-4.037-9-9s4.037-9 9-9 9 4.037 9 9-4.037 9-9 9m0-19C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0"
    ></path>
  </svg>
);

export default Sad;
