import { getConsentLevel } from '../components/MAFCookiesConsent/MAFCookiesConsent.util';

const CONSENT_LEVEL_STATISTICAL = 2;

export const isSelectedConsentLevelStatistical = (): boolean => {
  const currentLevel = getConsentLevel();
  return Boolean(currentLevel && currentLevel >= CONSENT_LEVEL_STATISTICAL);
};

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    s: S;
  }
}

// S comes from AppMeasurement.js
// eslint-disable-next-line @typescript-eslint/naming-convention
interface S {
  contextData: ITrackingParams;
  t: () => void;
}

interface ITrackingParams {
  [key: string]: string;
}

export interface IMAFAdobeAnalyticsParams {
  visitorApiSrc?: string;
  appMeasurementSrc?: string;
}

export const track = (data: ITrackingParams, defaultPayload: ITrackingParams) => {
  if (!isSelectedConsentLevelStatistical()) {
    return;
  }

  if (typeof window.s === 'undefined') {
    setTimeout(() => {
      track(data, defaultPayload);
    }, 1000);
    return;
  }

  const payload = { ...defaultPayload, ...data };

  window.s.contextData = {};
  Object.entries(payload).forEach(([key, val]) => {
    window.s.contextData[key] = val;
  });

  window.s.t();
};

export const initializeAdobeAnalytics = ({
  visitorApiSrc = '/adobe/VisitorAPI.js',
  appMeasurementSrc = '/adobe/AppMeasurement.js',
}: IMAFAdobeAnalyticsParams): void => {
  const visitorAPI = document.createElement('script');
  visitorAPI.src = visitorApiSrc;
  visitorAPI.async = false;
  document.head.appendChild(visitorAPI);

  const AppMeasurement = document.createElement('script');
  AppMeasurement.src = appMeasurementSrc;
  AppMeasurement.async = false;
  document.head.appendChild(AppMeasurement);
};
