const Download: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="15px"
    height="22px"
    viewBox="0 0 15 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Desktop-HD"
        transform="translate(-1228.000000, -1340.000000)"
        fill="#003755"
        fillRule="nonzero"
      >
        <g id="download_darkblue-(1)-copy" transform="translate(1228.000000, 1340.000000)">
          <path
            d="M14.3661501,20.8941799 L0.544284358,20.8941799 C0.242750823,20.8941799 0,21.1376542 0,21.4376494 C0,21.7376446 0.242750823,21.9811189 0.544284358,21.9811189 L14.3661501,21.9811189 C14.666595,21.9811189 14.9104344,21.7376446 14.9104344,21.4376494 C14.9104344,21.1376542 14.666595,20.8941799 14.3661501,20.8941799 Z M13.5535449,9.75346574 L7.96047881,15.3403322 L7.96047881,0.543469503 C7.96047881,0.243474337 7.71663941,0 7.41619445,0 C7.11466091,0 6.87191009,0.243474337 6.87191009,0.543469503 L6.87191009,15.3392453 L1.27884403,9.75346574 C1.1721643,9.64694572 1.0328275,9.59477265 0.893490707,9.59477265 C0.754153911,9.59477265 0.614817116,9.64694572 0.50922595,9.75346574 C0.295866482,9.96541885 0.295866482,10.3099785 0.50922595,10.5219316 L7.02866399,17.0326963 C7.12663517,17.1326947 7.26379483,17.1957371 7.41619445,17.1957371 C7.56641693,17.1957371 7.70248802,17.1348685 7.80154777,17.0359571 L14.3231629,10.5219316 C14.5354338,10.3099785 14.5354338,9.96541885 14.3231629,9.75346574 C14.110892,9.54151264 13.7658158,9.54151264 13.5535449,9.75346574 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Download;
