const BurgerMenu: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-navigation/Burger-menu-small/Darkblue">
        <g id="Icon" transform="translate(6.000000, 7.000000)">
          <path
            d="M0,0.5 C0,0.223857625 0.229000568,0 0.500347316,0 L11.4996527,0 C11.7759869,0 12,0.231933594 12,0.5 C12,0.776142375 11.7709994,1 11.4996527,1 L0.500347316,1 C0.224013124,1 0,0.768066406 0,0.5 Z M0,5.5 C0,5.22385763 0.229000568,5 0.500347316,5 L11.4996527,5 C11.7759869,5 12,5.23193359 12,5.5 C12,5.77614237 11.7709994,6 11.4996527,6 L0.500347316,6 C0.224013124,6 0,5.76806641 0,5.5 Z M0,10.5 C0,10.2238576 0.229000568,10 0.500347316,10 L11.4996527,10 C11.7759869,10 12,10.2319336 12,10.5 C12,10.7761424 11.7709994,11 11.4996527,11 L0.500347316,11 C0.224013124,11 0,10.7680664 0,10.5 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default BurgerMenu;
