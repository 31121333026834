"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeInsuranceMatrix = exports.patchInsuranceMatrixResponse = exports.patchInsuranceMatrixRequest = exports.initialState = exports.getInsuranceNamesResponse = exports.getInsuranceNamesRequest = exports.getInsuranceMatrixResponse = exports.getInsuranceMatrixRequest = exports.deleteInsuranceMatrixResponse = exports.deleteInsuranceMatrixRequest = exports.default = exports.createInsuranceMatrixResponse = exports.createInsuranceMatrixRequest = exports.clearInsuranceStateRequest = exports.addInsuranceMatrix = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
var _utils = require("../../utils");
var _insuranceConstants = require("./insuranceConstants");
const getInsuranceNamesRequest = exports.getInsuranceNamesRequest = (0, _reduxActions.createAction)('INSURANCE_GET_NAMES_REQUEST');
const getInsuranceNamesResponse = exports.getInsuranceNamesResponse = (0, _reduxActions.createAction)('INSURANCE_GET_NAMES_RESPONSE');
const getInsuranceMatrixRequest = exports.getInsuranceMatrixRequest = (0, _reduxActions.createAction)('INSURANCE_GET_MATRIX_REQUEST');
const getInsuranceMatrixResponse = exports.getInsuranceMatrixResponse = (0, _reduxActions.createAction)('INSURANCE_GET_MATRIX_RESPONSE');
const createInsuranceMatrixRequest = exports.createInsuranceMatrixRequest = (0, _reduxActions.createAction)('INSURANCE_CREATE_MATRIX_REQUEST');
const createInsuranceMatrixResponse = exports.createInsuranceMatrixResponse = (0, _reduxActions.createAction)('INSURANCE_CREATE_MATRIX_RESPONSE');
const patchInsuranceMatrixRequest = exports.patchInsuranceMatrixRequest = (0, _reduxActions.createAction)('INSURANCE_PATCH_MATRIX_REQUEST');
const patchInsuranceMatrixResponse = exports.patchInsuranceMatrixResponse = (0, _reduxActions.createAction)('INSURANCE_PATCH_MATRIX_RESPONSE');
const deleteInsuranceMatrixRequest = exports.deleteInsuranceMatrixRequest = (0, _reduxActions.createAction)('INSURANCE_DELETE_MATRIX_REQUEST');
const deleteInsuranceMatrixResponse = exports.deleteInsuranceMatrixResponse = (0, _reduxActions.createAction)('INSURANCE_DELETE_MATRIX_RESPONSE');
const addInsuranceMatrix = exports.addInsuranceMatrix = (0, _reduxActions.createAction)('INSURANCE_ADD_MATRIX');
const removeInsuranceMatrix = exports.removeInsuranceMatrix = (0, _reduxActions.createAction)('INSURANCE_REMOVE_MATRIX');
const clearInsuranceStateRequest = exports.clearInsuranceStateRequest = (0, _reduxActions.createAction)('INSURANCE_CLEAR_INSURANCE_STATE_REQUEST');
const initialState = exports.initialState = (0, _immutable.fromJS)({
  names: [],
  isLoadingNames: false,
  currentMatrix: null,
  isLoadingMatrix: false,
  isNewMatrix: false
});
var _default = exports.default = (0, _reduxActions.handleActions)({
  [getInsuranceNamesRequest](state) {
    return state.set('isLoadingNames', true);
  },
  [getInsuranceNamesResponse](state, _ref) {
    let {
      payload,
      error
    } = _ref;
    return error ? state.set('names', (0, _immutable.fromJS)([])).set('isLoadingNames', false) : state.set('names', (0, _immutable.fromJS)(payload)).set('isLoadingNames', false);
  },
  [(0, _reduxActions.combineActions)(getInsuranceMatrixRequest, createInsuranceMatrixRequest, patchInsuranceMatrixRequest, deleteInsuranceMatrixRequest)](state) {
    return state.set('isLoadingMatrix', true);
  },
  [(0, _reduxActions.combineActions)(getInsuranceMatrixResponse, createInsuranceMatrixResponse, patchInsuranceMatrixResponse)](state, _ref2) {
    let {
      payload,
      error
    } = _ref2;
    return error ? state.set('currentMatrix', (0, _immutable.fromJS)(null)).set('isLoadingMatrix', false) : state.set('currentMatrix', (0, _immutable.fromJS)(payload)).set('isLoadingMatrix', false).set('isNewMatrix', false);
  },
  [(0, _reduxActions.combineActions)(deleteInsuranceMatrixResponse, removeInsuranceMatrix)](state, _ref3) {
    let {
      error
    } = _ref3;
    return error ? state.set('isLoadingMatrix', false) : state.set('isLoadingMatrix', false).set('currentMatrix', (0, _immutable.fromJS)(null));
  },
  [addInsuranceMatrix](state, _ref4) {
    let {
      payload: {
        name
      }
    } = _ref4;
    return state.set('isNewMatrix', true).set('currentMatrix', (0, _immutable.fromJS)({
      name,
      rates: _utils.matrixUtils.createRates({
        name,
        mileages: _insuranceConstants.MILEAGES,
        periods: _insuranceConstants.PERIODS
      })
    }));
  },
  [clearInsuranceStateRequest]() {
    return initialState;
  }
}, initialState);