import { createSelector } from 'reselect';

import { selectAsset } from '../asset/assetSelectors';
import {
  selectSelectedEquipment,
  selectEditableRetrofitFromForm,
} from '../asset/equipmentSelectors';
import {
  selectIsCasePrivate,
  selectActiveOLterms,
  selectCase,
} from '../caseDetail/caseDetailSelectors';
import {
  selectSelectedTerms,
  selectVendorTermsPROL,
  selectVendorTermsCMOL,
} from '../vendor/vendorTermsSelectors';

import { requestMappers } from './quoteMapper';

const selectQuoteState = (state) => state.quote;

const selectUseDownPaymentPercentage = () =>
  createSelector(selectQuoteState, (state) => state.get('useDownPaymentPercentage'));

export const selectQuoteDataOL = () =>
  createSelector(selectQuoteState, (state) => {
    const quoteData = state.get('quoteData').toJS();
    const winterTiresPrice = Math.trunc(quoteData.winterTiresPrice);
    return {
      finalPresentValue: quoteData.finalPresentValue,
      carPrice: quoteData.carPrice,
      commission: Math.trunc(quoteData.commission),
      quote: quoteData.quote,
      insurance: quoteData.insurance,
      discount: Math.trunc(quoteData.discount),
      serviceFee: Math.trunc(quoteData.serviceFee),
      winterTiresPrice: Number.isNaN(winterTiresPrice) ? 0 : winterTiresPrice,
      service: quoteData.service,
      winterTires: quoteData.winterTires,
      overageCost: quoteData.overageCost,
      downPaymentValue: quoteData.downPaymentValue,
      downPaymentPercentage: quoteData.downPaymentPercentage,
      monthlyBenefitValue: quoteData.monthlyBenefitValue,
      monthlyInsuranceFee: quoteData.monthlyInsuranceFee,
    };
  });

export const selectRecalculateRequest = ({
  commission,
  discount,
  quote,
  downPaymentValue,
  downPaymentPercentage,
} = {}) =>
  createSelector(
    selectIsCasePrivate(),
    selectAsset(),
    selectSelectedTerms(),
    selectActiveOLterms(),
    selectSelectedEquipment(),
    selectEditableRetrofitFromForm(),
    selectQuoteDataOL(),
    selectUseDownPaymentPercentage(),
    selectCase(),
    selectVendorTermsPROL(),
    selectVendorTermsCMOL(),
    (
      isCasePrivate,
      asset,
      selectedTerms,
      terms,
      selectedEquipment,
      retrofitEquipment,
      quoteData,
      useDownPaymentPercentage,
      { asset: caseAsset = {} },
      vendorTermsPROL,
      vendorTermsCMOL,
    ) => {
      const priceDeduction = { commission, discount, quote };
      const downPayment = { value: downPaymentValue, percentage: downPaymentPercentage };
      const mapper = isCasePrivate
        ? requestMappers.mapRecalculateQuotePROL
        : requestMappers.mapRecalculateQuoteCMOL;
      return mapper({
        asset,
        selectedTerms,
        terms,
        selectedEquipment,
        retrofitEquipment,
        quoteData,
        priceDeduction,
        downPayment,
        useDownPaymentPercentage,
        vinNumber: caseAsset.vinNumber,
        registrationNumber: caseAsset.registrationNumber,
        allValidTerms: isCasePrivate ? vendorTermsPROL : vendorTermsCMOL,
      });
    },
  );

export const selectQuoteData = () =>
  createSelector(selectQuoteState, (state) => state.get('quoteData').toJS());

export const selectDownPayment = () =>
  createSelector(selectQuoteData(), (quoteData) => quoteData.downPaymentValue);

export const selectIsUpdatingPrice = () =>
  createSelector(selectQuoteState, (state) => state.get('updatingPrice'));

export const selectInterestRateCMFLValues = () =>
  createSelector(selectQuoteState, (state) =>
    state.getIn(['additionalValuesCMFL', 'interestRate']).toJS(),
  );

export const selectResidualValuesCMFLValues = () =>
  createSelector(selectQuoteState, (state) =>
    state.getIn(['additionalValuesCMFL', 'residualValue']).toJS(),
  );

export const selectQuoteError = () =>
  createSelector(selectQuoteState, (state) => state.get('error'));
