import { ISxStyles } from '../../../theming/theme.type';

export const styles: ISxStyles = {
  rowWrapper: {
    wordBreak: 'break-all',
    '@supports (word-break: break-word)': {
      wordBreak: 'break-word',
    },
  },
  subtitle: ({ spacing }) => ({
    '& .MuiTypography-root': {
      fontStyle: 'italic',
    },
    '&:not(:nth-of-type(2))': {
      marginTop: spacing(4),
    },
  }),
  spacing: ({ spacing }) => ({
    marginTop: spacing(1),
  }),
  detailsDivider: ({ spacing, colors }) => ({
    margin: spacing(1, 0),
    color: colors.danske.greyC60,
    width: '100%',
  }),
  subtitleText: {
    paddingLeft: 'calc(100% / 12)',
    textAlign: 'right',
  },
};
