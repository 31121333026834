"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertToPositiveOrZero = exports.convertToFixed = void 0;
const convertToPositiveOrZero = value => value > 0 ? Number(value) : 0;
exports.convertToPositiveOrZero = convertToPositiveOrZero;
const convertToFixed = function (value) {
  let fixed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  let defaultValue = arguments.length > 2 ? arguments[2] : undefined;
  const parsedValue = Number.parseFloat(value);
  return !Number.isNaN(parsedValue) ? parsedValue.toFixed(fixed) : defaultValue || NaN;
};
exports.convertToFixed = convertToFixed;