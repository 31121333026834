const AgreementActivated: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="38px"
    height="26px"
    viewBox="0 0 38 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="07-Case-detail-all-statuses-assets"
        transform="translate(-1026.000000, -668.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="agreement_activated" transform="translate(1028.000000, 670.000000)">
          <g id="Group-31">
            <path
              d="M35.27575,6.41666667 L35.27575,19.25 C35.27575,21.4757409 33.4211951,23.27575 31.1666667,23.27575 L6.61111111,23.27575 C4.47507744,23.27575 2.69807816,21.6599803 2.51718567,19.5972176 C0.408008848,19.4383606 -1.27575,17.7041088 -1.27575,15.5833333 L-1.27575,2.75 C-1.27575,0.524259145 0.578804877,-1.27575 2.83333333,-1.27575 L27.3888889,-1.27575 C29.5249226,-1.27575 31.3019218,0.340019693 31.4828143,2.40278241 C33.5919912,2.56163942 35.27575,4.29589116 35.27575,6.41666667 Z M31.1666667,4.94241667 L28.9464722,4.94241667 L28.9464722,2.75 C28.9464722,1.95074085 28.2510271,1.27575 27.3888889,1.27575 L2.83333333,1.27575 C1.97119512,1.27575 1.27575,1.95074085 1.27575,2.75 L1.27575,15.5833333 C1.27575,16.3825925 1.97119512,17.0575833 2.83333333,17.0575833 L5.05352778,17.0575833 L5.05352778,19.25 C5.05352778,20.0492591 5.7489729,20.72425 6.61111111,20.72425 L31.1666667,20.72425 C32.0288049,20.72425 32.72425,20.0492591 32.72425,19.25 L32.72425,6.41666667 C32.72425,5.61740752 32.0288049,4.94241667 31.1666667,4.94241667 Z"
              id="Stroke-1"
            ></path>
            <path
              d="M5.27575,6.7 L5.27575,19.3 C5.27575,20.0685084 5.95881417,20.72425 6.8125,20.72425 L31.1875,20.72425 C32.0411858,20.72425 32.72425,20.0685084 32.72425,19.3 L32.72425,6.7 C32.72425,5.93149161 32.0411858,5.27575 31.1875,5.27575 L6.8125,5.27575 C5.95881417,5.27575 5.27575,5.93149161 5.27575,6.7 Z M2.72425,6.7 C2.72425,4.49850839 4.57243583,2.72425 6.8125,2.72425 L31.1875,2.72425 C33.4275642,2.72425 35.27575,4.49850839 35.27575,6.7 L35.27575,19.3 C35.27575,21.5014916 33.4275642,23.27575 31.1875,23.27575 L6.8125,23.27575 C4.57243583,23.27575 2.72425,21.5014916 2.72425,19.3 L2.72425,6.7 Z"
              id="Stroke-3"
            ></path>
            <path
              d="M23.3010782,16.2280925 L28.4792035,8.3804144 C28.8213912,7.86181382 29.5191979,7.71880291 30.0377985,8.06099059 C30.5563991,8.40317826 30.69941,9.10098502 30.3572223,9.6195856 L23.5554892,19.9279133 L19.1546369,14.9159295 C18.7446845,14.4490484 18.7908344,13.7382344 19.2577155,13.328282 C19.7245966,12.9183295 20.4354106,12.9644794 20.8453631,13.4313605 L23.3010782,16.2280925 Z"
              id="Path-8-Copy"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AgreementActivated;
