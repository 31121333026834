"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectNotificationsError = exports.selectNotificationWithRedirect = exports.selectAllNotifications = void 0;
var _reselect = require("reselect");
const selectNotificationsState = state => state.notifications;
const selectAllNotifications = () => (0, _reselect.createSelector)(selectNotificationsState, state => state.get('notifications').toJS());
exports.selectAllNotifications = selectAllNotifications;
const selectNotificationWithRedirect = () => (0, _reselect.createSelector)(selectNotificationsState, state => {
  const notificationWithRedirect = state.get('notificationWithRedirect');
  return notificationWithRedirect && notificationWithRedirect.toJS();
});
exports.selectNotificationWithRedirect = selectNotificationWithRedirect;
const selectNotificationsError = () => (0, _reselect.createSelector)(selectNotificationsState, state => state.get('error'));
exports.selectNotificationsError = selectNotificationsError;