import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  number: ({ typography, fontSizes, colors, spacing }) => ({
    fontFamily: typography.main,
    fontSize: fontSizes.tiny,
    color: colors.solid.white,
    backgroundColor: colors.solid.cadedBlue,
    height: spacing(3),
    width: spacing(3),
    minWidth: spacing(3),
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    '&:hover': {
      backgroundColor: colors.solid.cadedBlue,
    },
  }),
  large: ({ fontSizes }) => ({
    height: 42,
    width: 42,
    fontSize: fontSizes.small,
  }),
};
