"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectUserContactDetails = exports.selectThemeSettings = exports.selectIsUserDetailsLoading = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _reselect = require("reselect");
var _objectUtils = require("../../utils/object-utils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const selectUsersState = state => state.userProfile;
const selectUserContactDetails = () => (0, _reselect.createSelector)(selectUsersState, state => {
  const user = state.get('user').toJS();
  return _objectSpread(_objectSpread({}, user), {}, {
    alternativeEmailAddress: user.alternativeEmailAddress || null
  });
});
exports.selectUserContactDetails = selectUserContactDetails;
const selectIsUserDetailsLoading = () => (0, _reselect.createSelector)(selectUsersState, state => state.get('isLoadingUser'));
exports.selectIsUserDetailsLoading = selectIsUserDetailsLoading;
const selectThemeSettings = () => (0, _reselect.createSelector)(selectUsersState, state => {
  const data = state.get('themeSettings').toJS();
  return (0, _objectUtils.filterNullOrUndefined)({
    obj: data
  });
});
exports.selectThemeSettings = selectThemeSettings;