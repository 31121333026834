import { requestHandler } from '@northstar/core-ui/utils';

const { vendorCaseDomain } = requestHandler;

export const updateCase = ({ resourceId, importerId, requestBody }) =>
  vendorCaseDomain.UPDATE_DETAILS.call({
    urlParams: { resourceId, importerId },
    body: requestBody,
  });

export const updateCaseImporter = ({ resourceId, importerId }) =>
  vendorCaseDomain.UPDATE_IMPORTER.call({
    urlParams: { resourceId, importerId },
  });

export const createCase = ({ importerId, requestBody = {} }) =>
  vendorCaseDomain.CREATE.call({
    urlParams: { importerId },
    body: requestBody,
  });

export const sendPdf = ({ resourceId, importerId, requestBody }) =>
  vendorCaseDomain.SEND_DOCUMENT.call({
    urlParams: { resourceId, importerId },
    body: requestBody,
  });

export const swithCaseFlow = ({ resourceId, importerId, requestBody }) =>
  vendorCaseDomain.SWITCH_FLOW.call({
    urlParams: { resourceId, importerId },
    body: requestBody,
  });

export const setAssetDelivered = ({ resourceId, importerId }) =>
  vendorCaseDomain.SET_ASSET_DELIVERED.call({
    urlParams: { resourceId, importerId },
  });

export const setReferenceNumberPRHP = ({ resourceId, importerId, requestBody }) =>
  vendorCaseDomain.SET_REFERENCE_NUMBER_PRHP.call({
    urlParams: { resourceId, importerId },
    body: requestBody,
  });

export const setReferenceNumberPROL = ({ resourceId, importerId, requestBody }) =>
  vendorCaseDomain.SET_REFERENCE_NUMBER_PROL.call({
    urlParams: { resourceId, importerId },
    body: requestBody,
  });

export const createDraftScenario = ({ resourceId, importerId }) =>
  vendorCaseDomain.CREATE_DRAFT_SCENARIO.call({
    urlParams: { resourceId, importerId },
  });

export const proceedCustomerDataGathering = ({ resourceId }) =>
  vendorCaseDomain.PROCEED_FROM_CUSTOMER_DATA_GATHERING_STEP_DEMO.call({
    urlParams: { resourceId },
  });
