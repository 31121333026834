"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = caseSaga;
exports.getCasePublicComments = exports.getCaseInternalComments = void 0;
exports.postCaseInternalComment = postCaseInternalComment;
exports.postCasePublicComment = postCasePublicComment;
var _reduxForm = require("redux-form");
var _effects = require("redux-saga/effects");
var _reduxSagaUtils = require("../../utils/redux-saga-utils");
var commentsAPI = _interopRequireWildcard(require("./commentsApi"));
var _commentsConstants = require("./commentsConstants");
var _commentsMapper = require("./commentsMapper");
var _commentsReducer = require("./commentsReducer");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const getCaseInternalComments = exports.getCaseInternalComments = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: commentsAPI.getInternalComments,
  successActions: [_commentsReducer.getInternalCommentsResponse],
  responseMapper: _commentsMapper.responseMappers.mapComments,
  errorActions: [_commentsReducer.getInternalCommentsResponse]
});
const getCasePublicComments = exports.getCasePublicComments = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: commentsAPI.getPublicComments,
  successActions: [_commentsReducer.getPublicCommentsResponse],
  responseMapper: _commentsMapper.responseMappers.mapComments,
  errorActions: [_commentsReducer.getPublicCommentsResponse]
});
function postCaseInternalComment(_ref) {
  let {
    payload
  } = _ref;
  return function* () {
    try {
      const request = _commentsMapper.requestMappers.mapCreateComment(payload);
      const response = yield (0, _effects.call)(commentsAPI.postInternalComment, request);
      yield (0, _effects.put)((0, _reduxForm.reset)(_commentsConstants.INTERNAL_COMMENT_FORM));
      yield (0, _effects.put)((0, _commentsReducer.postInternalCommentsResponse)(_commentsMapper.responseMappers.mapComment(response)));
    } catch (e) {
      yield (0, _effects.put)((0, _commentsReducer.postInternalCommentsResponse)(e));
    }
  }();
}
function postCasePublicComment(_ref2) {
  let {
    payload
  } = _ref2;
  return function* () {
    try {
      const request = _commentsMapper.requestMappers.mapCreateComment(payload);
      const response = yield (0, _effects.call)(commentsAPI.postPublicComment, request);
      yield (0, _effects.put)((0, _reduxForm.reset)(_commentsConstants.PUBLIC_COMMENT_FORM));
      yield (0, _effects.put)((0, _commentsReducer.postPublicCommentsResponse)(_commentsMapper.responseMappers.mapComment(response)));
    } catch (e) {
      yield (0, _effects.put)((0, _commentsReducer.postPublicCommentsResponse)(e));
    }
  }();
}
function* caseSaga() {
  yield (0, _effects.takeLatest)(_commentsReducer.getInternalCommentsRequest, getCaseInternalComments);
  yield (0, _effects.takeLatest)(_commentsReducer.getPublicCommentsRequest, getCasePublicComments);
  yield (0, _effects.takeLatest)(_commentsReducer.postInternalCommentRequest, postCaseInternalComment);
  yield (0, _effects.takeLatest)(_commentsReducer.postPublicCommentRequest, postCasePublicComment);
}