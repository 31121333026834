"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.getCaseForQuoteResponse = exports.getCaseForQuoteRequest = exports.default = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
const getCaseForQuoteRequest = exports.getCaseForQuoteRequest = (0, _reduxActions.createAction)('CASE_GET_CASE_FOR_QUOTE_REQUEST');
const getCaseForQuoteResponse = exports.getCaseForQuoteResponse = (0, _reduxActions.createAction)('CASE_GET_CASE_FOR_QUOTE_RESPONSE');
const initialState = exports.initialState = (0, _immutable.fromJS)({
  caseForQuote: {},
  loadingCaseForQuote: false,
  error: ''
});
var _default = exports.default = (0, _reduxActions.handleActions)({
  [getCaseForQuoteRequest](state) {
    return state.set('loadingCaseForQuote', true).set('error', '');
  },
  [getCaseForQuoteResponse](state, _ref) {
    let {
      payload,
      error
    } = _ref;
    return !error ? state.set('caseForQuote', (0, _immutable.fromJS)(payload)).set('loadingCaseForQuote', false) : state.set('loadingCaseForQuote', false).set('error', 'quote.error');
  }
}, initialState);