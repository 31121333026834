const ProofOfDeliverySigned: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="37px"
    height="27px"
    viewBox="0 0 37 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="Screens"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <g
        id="07-Case-detail-all-statuses-assets"
        transform="translate(-491.000000, -171.000000)"
        stroke="#FFFFFF"
        strokeWidth="2.25"
      >
        <g id="Group-19" transform="translate(492.000000, 173.000000)">
          <path d="M0.449442987,13.125 L11.2360747,13.125" id="Line-Copy-21"></path>
          <path d="M4.88677614,8.5 L12.55,8.5" id="Line-Copy-22"></path>
          <path
            d="M11.1592103,2.54145682e-16 L32.6804051,0 C33.5088322,-2.35955288e-15 34.1804051,0.671572875 34.1804051,1.5 L34.1804051,21.5 C34.1804051,22.3284271 33.5088322,23 32.6804051,23 L7,23"
            id="Rectangle-15-Copy-7"
          ></path>
          <path
            d="M17,18.1874684 C17.7748528,16.4923606 18.9418224,16.0685837 20.5009087,16.9161376 C22.8395383,18.1874684 23.9694537,17.7636914 24.9867998,17.3399145 C26.0041459,16.9161376 29.6879799,13.8078147 27.8460629,11.5817546 C26.0041459,9.35569443 22.7136176,13.940469 29.5380477,18.1874684"
            id="Path-10-Copy-2"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default ProofOfDeliverySigned;
