"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SERVICE_TYPE_WINTER_TIRES = exports.SERVICE_TYPE_SERVICE = exports.SERVICE_TYPE_INSURANCE = exports.ASSET_STATE_USED = exports.ASSET_STATE_NEW = exports.ASSET_FORM_PRIVATE_LEASE_SWITCH = exports.ASSET_FORM_COMMERCIAL_OPERATIONAL_LEASE_SWITCH = void 0;
const ASSET_STATE_NEW = exports.ASSET_STATE_NEW = 'NEW';
const ASSET_STATE_USED = exports.ASSET_STATE_USED = 'USED';
const SERVICE_TYPE_WINTER_TIRES = exports.SERVICE_TYPE_WINTER_TIRES = 'winter_tires';
const SERVICE_TYPE_INSURANCE = exports.SERVICE_TYPE_INSURANCE = 'insurance';
const SERVICE_TYPE_SERVICE = exports.SERVICE_TYPE_SERVICE = 'service';
const ASSET_FORM_PRIVATE_LEASE_SWITCH = exports.ASSET_FORM_PRIVATE_LEASE_SWITCH = 'private_lease_switch';
const ASSET_FORM_COMMERCIAL_OPERATIONAL_LEASE_SWITCH = exports.ASSET_FORM_COMMERCIAL_OPERATIONAL_LEASE_SWITCH = 'commercial_operational_lease_switch';