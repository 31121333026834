const ProofOfDeliveryWaiting: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="56px"
    height="27px"
    viewBox="0 0 36 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="07-Case-detail-all-statuses-assets"
        transform="translate(-482.000000, -293.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="proof_of_delivery_waiting_for_signing" transform="translate(482.000000, 293.000000)">
          <path
            d="M1.44944299,17.25 C0.828122643,17.25 0.324442987,16.7463203 0.324442987,16.125 C0.324442987,15.5036797 0.828122643,15 1.44944299,15 L12.2360747,15 C12.857395,15 13.3610747,15.5036797 13.3610747,16.125 C13.3610747,16.7463203 12.857395,17.25 12.2360747,17.25 L1.44944299,17.25 Z"
            id="Line-Copy-23"
          ></path>
          <path
            d="M5.88677614,12.625 C5.26545579,12.625 4.76177614,12.1213203 4.76177614,11.5 C4.76177614,10.8786797 5.26545579,10.375 5.88677614,10.375 L13.55,10.375 C14.1713203,10.375 14.675,10.8786797 14.675,11.5 C14.675,12.1213203 14.1713203,12.625 13.55,12.625 L5.88677614,12.625 Z"
            id="Line-Copy-24"
          ></path>
          <path
            d="M20.8277355,8 C20.8277355,7.37867966 21.3314151,6.875 21.9527355,6.875 C22.5740558,6.875 23.0777355,7.37867966 23.0777355,8 L23.0777355,15.0001425 C23.0777355,15.6214629 22.5740558,16.1251425 21.9527355,16.1251425 C21.3314151,16.1251425 20.8277355,15.6214629 20.8277355,15.0001425 L20.8277355,8 Z"
            id="Line-4-Copy-5"
          ></path>
          <path
            d="M26.1240377,16.5639434 C26.6410075,16.9085899 26.7807031,17.6070679 26.4360566,18.1240377 C26.0914101,18.6410075 25.3929321,18.7807031 24.8759623,18.4360566 L21.8759623,16.4360566 C21.3589925,16.0914101 21.2192969,15.3929321 21.5639434,14.8759623 C21.9085899,14.3589925 22.6070679,14.2192969 23.1240377,14.5639434 L26.1240377,16.5639434 Z"
            id="Line-4-Copy-6"
          ></path>
          <path
            d="M11.1592103,3.125 C10.53789,3.125 10.0342103,2.62132034 10.0342103,2 C10.0342103,1.37867966 10.53789,0.875 11.1592103,0.875 L32.6804051,0.875 C34.1301525,0.875 35.3054051,2.05025253 35.3054051,3.5 L35.3054051,23.5 C35.3054051,24.9497475 34.1301525,26.125 32.6804051,26.125 L7,26.125 C6.37867966,26.125 5.875,25.6213203 5.875,25 C5.875,24.3786797 6.37867966,23.875 7,23.875 L32.6804051,23.875 C32.8875119,23.875 33.0554051,23.7071068 33.0554051,23.5 L33.0554051,3.5 C33.0554051,3.29289322 32.8875119,3.125 32.6804051,3.125 L11.1592103,3.125 Z"
            id="Rectangle-15-Copy-7"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default ProofOfDeliveryWaiting;
