import { ISxStyles } from '../../../theming/theme.type';

interface IProps {
  bubblePosition?: number;
}

export const useStyles = ({ bubblePosition }: IProps): ISxStyles => ({
  bubble: ({ colors, typography: { main } }) => ({
    marginLeft: '-3px',
    zIndex: 1,
    color: colors.danske.white,
    fontFamily: main,
    fontWeight: 600,
    left: `${bubblePosition}px`,
    transition: '0.4s ease-in-out',
    position: 'absolute',
  }),
  bubbleWithoutAnimation: {
    transition: 'none',
  },
  icon: ({ colors }) => ({
    backgroundColor: colors.danske.primary100,
    padding: 1,
    borderRadius: '50%',
  }),
});
