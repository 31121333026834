"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLocale = exports.LONG_POLL_VERSION_DELAY = exports.LONG_POLL_DISABLE_AFTER = exports.DEFAULT_TIMESTAMP_FORMAT = exports.DEFAULT_LOCALE = exports.DEFAULT_DATE_FORMAT = exports.DEFAULT_COUNTRY = void 0;
var _numeral = _interopRequireDefault(require("numeral"));
var _dayjsUtils = require("./utils/dayjs-utils");
const DEFAULT_LOCALE = exports.DEFAULT_LOCALE = 'se';
const DEFAULT_DATE_FORMAT = exports.DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_TIMESTAMP_FORMAT = exports.DEFAULT_TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm';
const DEFAULT_COUNTRY = exports.DEFAULT_COUNTRY = 'SE';
const setLocale = () => {
  (0, _dayjsUtils.setInitialDayJSLocale)({
    defaultLocale: DEFAULT_LOCALE
  });
  const localeConfig = {
    delimiters: {
      thousands: '.',
      decimal: ','
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
    },
    ordinal: number => number,
    currency: {
      symbol: '€'
    }
  };
  _numeral.default.register('locale', DEFAULT_LOCALE, localeConfig);
  _numeral.default.locale(DEFAULT_LOCALE);
};
exports.setLocale = setLocale;
const LONG_POLL_VERSION_DELAY = exports.LONG_POLL_VERSION_DELAY = 60000;
const LONG_POLL_DISABLE_AFTER = exports.LONG_POLL_DISABLE_AFTER = 1000 * 60 * 10;