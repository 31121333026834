import { ISxStyles } from '@asfi/maf';

export const styles: ISxStyles = {
  lineContainer: {
    display: 'flex',
    justifyContent: 'center',
    '&:nth-last-of-type(2) div': {
      borderBottom: 'none',
    },
  },
  line: ({ colors: { solid } }) => ({
    borderBottom: `1px solid ${solid.gainsboro}`,
    width: '80%',
  }),
  label: {
    margin: '25px 0 10px',
  },
  text: {
    marginBottom: 3,
  },
};
