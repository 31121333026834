import { fromJS } from 'immutable';
import { createAction, handleActions, combineActions } from 'redux-actions';

export const getAssetsPrivateRequest = createAction('ASSETS_GET_ASSETS_REQUEST');
export const getAssetsPrivateResponse = createAction('ASSETS_GET_ASSETS_RESPONSE');
export const getAssetsCommercialRequest = createAction('ASSETS_GET_ASSETS_COMMERCIAL_REQUEST');
export const getAssetsCommercialResponse = createAction('ASSETS_GET_ASSETS_COMMERCIAL_RESPONSE');
export const getAssetsDynamicRequest = createAction('ASSETS_GET_ASSETS_DYNAMIC_REQUEST');

export const initialState = fromJS({
  assets: [],
  gettingAssets: false,
});

export default handleActions(
  {
    [combineActions(getAssetsPrivateRequest, getAssetsCommercialRequest)](state) {
      return state.set('gettingAssets', true);
    },
    [combineActions(getAssetsPrivateResponse, getAssetsCommercialResponse)](
      state,
      { payload, error },
    ) {
      return !error
        ? state.set('assets', fromJS(payload)).set('gettingAssets', false)
        : state.set('gettingAssets', false);
    },
  },
  initialState,
);
