import { ObjectUtils } from '@northstar/core';
import {
  app,
  asset as assetModule,
  caseDetail,
  EQUIPMENT_FORM_ADDITIONAL,
  EQUIPMENT_FORM_COLORS,
  EQUIPMENT_FORM_RETROFIT,
} from '@northstar/core-ui/modules';
import { reduxSagaUtils, errorUtils } from '@northstar/core-ui/utils';
import { callWithAttachers } from '@northstar/core-ui/utils/redux-saga-utils';
import dayjs from 'dayjs';
import { getFormValues, change } from 'redux-form';
import { call, put, takeLatest, select, take, all } from 'redux-saga/effects';

import { createAttacherForTermsToUse } from 'modules/vendor/vendorTermsAttachers';
import { selectAsset } from 'modules/asset/assetSelectors';
import { changeFramePreviousId } from 'modules/asset/frameReducer';
import { selectFrame } from 'modules/asset/frameSelectors';
import { prefillNewCarsFlowEq } from 'modules/quote/quoteSaga';

import { changeAssetPreviousLicencePlate } from '../asset/assetReducer';
import { getAsset } from '../asset/assetSaga';
import { resetSelectedEquipment, changeSelectedEquipment } from '../asset/equipmentReducer';
import { prefillEquipmentCategory } from '../asset/equipmentSaga';
import { EQUIPMENT_CATEGORY_OPTIONAL } from '../asset/equipmentConstants';
import { selectEquipment } from '../asset/equipmentSelectors';
import { responseMappers as hirePurchaseResponseMappers } from '../hirePurchase/hirePurchaseMapper';
import { postHirePurchaseQuoteResponse } from '../hirePurchase/hirePurchaseReducer';
import { CMFL_FORM } from '../quote/quoteConstants';
import {
  recalculatePriceResponse,
  setQuoteData,
  calculateQuoteRequestCMFL,
} from '../quote/quoteReducer';
import { selectRecalculateRequest } from '../quote/quoteSelectors';

// Case detail module
import * as caseAPI from './caseAssetApi';
import { formMappers } from './caseAssetMapper';
import {
  updateCaseWithAssetRequest,
  updateCaseWithAssetResponse,
  updateHirePurchaseCalculationsRequest,
  updateHirePurchaseCalculationsResponse,
  updateAssetPriceCMFLRequest,
  updateAssetPriceCMFLResponse,
  prefillCMFLValues,
  resetIsFormEditedState,
} from './caseAssetReducer';
import { selectCalculationRequestPRHP, selectCalculationRequestCMFL } from './caseAssetSelectors';
import { updateImporter, updateCaseGeneralInformation } from './caseDetailSaga';
import {
  selectActiveOLterms,
  selectCase,
  selectIsCasePrivate,
  selectCaseAsset,
} from './caseDetailSelectors';
import { attachDraftScenarioId } from './caseAttachers';

// Asset module
// hire purchase module
const { equipmentMappers } = assetModule;
const { formMappers: equipmentFormMappers } = equipmentMappers;

function validateResourceId({ resourceId }) {
  if (!resourceId) {
    throw new Error('errors.case_not_found');
  }
}

// second argument comes purely as DI approach for testing

export function* updateCaseWithAsset({ payload }, updateAssetPriceSaga = updateAssetPriceOL) {
  try {
    validateResourceId(payload);
    const {
      resourceId,
      id,
      assetVersionId,
      vendorTermsId,
      isAssetCommercial,
      initializeDeliveryDate,
      redirect,
    } = payload;
    yield put(resetSelectedEquipment());
    yield call(getAsset, {
      payload: {
        assetId: id,
        assetVersionId,
        isAssetCommercial,
        configuration: true,
      },
    });

    const { colors } = yield select(selectEquipment());
    if (colors.length === 1) {
      const [color] = colors;
      yield put(
        changeSelectedEquipment({
          category: EQUIPMENT_CATEGORY_OPTIONAL,
          key: color.id,
          value: color,
        }),
      );
    }

    const activeOLTerms = yield select(selectActiveOLterms());

    const assetTerms =
      activeOLTerms.find((term) => term.vendorTermsId === vendorTermsId) ||
      activeOLTerms.find((term) => term.isDefault);

    const requestBody = yield select(
      selectRecalculateRequest({
        downPaymentPercentage: assetTerms?.defaultDownPaymentPercentage,
      }),
    );

    yield call(updateImporter);
    yield call(updateAssetPriceSaga, { requestBody });

    if (initializeDeliveryDate) {
      yield call(updateCaseGeneralInformation, { payload: { deliveryDate: dayjs() } });
    }
    if (typeof redirect === 'function') {
      yield payload.redirect({ resourceId });
    }
    yield put(updateCaseWithAssetResponse());
  } catch (e) {
    yield put(updateCaseWithAssetResponse(e));
    yield put(app.displayError('errors.could_not_get_case'));
  }
}

export function* updateAssetPriceOL({ requestBody }) {
  const { resourceId, ...currentCase } = yield select(selectCase());
  const isCasePrivate = yield select(selectIsCasePrivate());
  const apiCall = isCasePrivate ? caseAPI.updateAssetPricePROL : caseAPI.updateAssetCMOL;
  const response = yield callWithAttachers({
    endpoint: apiCall,
    payload: {
      resourceId,
      requestBody,
    },
    attachers: [createAttacherForTermsToUse(), attachDraftScenarioId],
  });
  const { detailsPL, detailsCMOL } = caseDetail.responseMappers.mapAssetDetails({
    ...currentCase,
    asset: response,
  });
  const details = detailsPL || detailsCMOL;
  yield put(recalculatePriceResponse(details.quoteDetails));
}

// TODO: write tests
function* updateHirePurchaseCalculations({ payload: formValues }) {
  try {
    const request = yield select(selectCalculationRequestPRHP(formValues));
    const response = yield callWithAttachers({
      endpoint: caseAPI.updateAssetPricePRHP,
      payload: request,
      attachers: [createAttacherForTermsToUse(), attachDraftScenarioId],
    });
    yield put(
      postHirePurchaseQuoteResponse(
        hirePurchaseResponseMappers.mapQuote(response.asset_details_response_sehp.quote_details),
      ),
    );
    const { mileage } = response.asset_details_response_sehp;
    yield put(
      updateHirePurchaseCalculationsResponse({
        mileage: ObjectUtils.isNullOrUndefined(mileage) ? mileage : mileage.toString(),
        state: response.state,
      }),
    );
  } catch (e) {
    yield put(postHirePurchaseQuoteResponse(e));
    yield put(updateHirePurchaseCalculationsResponse(e));
    yield put(app.displayError(e));
  }
}

// TODO: write tests
function* updateAssetPriceCMFL({ payload: { resourceId, redirect, mileage } }) {
  try {
    const formValues = yield select(getFormValues(CMFL_FORM));
    const assetInformation = yield select(
      selectCalculationRequestCMFL({ formValues, useDownPaymentPercentage: undefined }),
    );
    const requestBody = { ...assetInformation, mileage };
    const response = yield callWithAttachers({
      endpoint: caseAPI.updateAssetCMFL,
      payload: {
        resourceId,
        requestBody,
      },
      attachers: [createAttacherForTermsToUse()],
    });
    yield put(
      setQuoteData({
        detailsCMFL: {
          quoteDetails: {
            ...caseDetail.responseMappers.mapQuoteCMFL(
              response.asset_details_response_secomfl.quote_details,
            ),
            leasePeriod: response.lease_period,
          },
        },
        vendorTermsId: response.vendor_terms_id,
      }),
    );
    yield put(updateAssetPriceCMFLResponse({ mileage }));

    const currentFrame = yield select(selectFrame());
    const currentAsset = yield select(selectAsset());
    yield put(changeAssetPreviousLicencePlate({ licencePlate: currentAsset?.licencePlate }));
    yield put(changeFramePreviousId({ id: currentFrame?.id }));

    if (typeof redirect === 'function') {
      yield call(redirect);
    }
  } catch (e) {
    const { reason, message } = errorUtils.mapResponseExceptionsToError({ response: e }) || {};
    yield put(app.displayError(reason || message));
    yield put(updateAssetPriceCMFLResponse(e));
  }
}

// TODO: write tests
function* prefillCMFLFormValues({ payload }) {
  try {
    if (payload.resourceId) {
      // Calling case again to prefill asset
      yield put(caseDetail.getCaseRequest({ resourceId: payload.resourceId }));
      yield take(caseDetail.getCaseResponse().type);
    }
    const {
      asset: {
        leasePeriod,
        vendorTermsId,
        detailsCMFL: { quoteDetails },
      },
    } = yield select(selectCase());

    if (payload.resourceId) {
      const { frameId } = yield select(selectCaseAsset());
      if (frameId) yield call(prefillNewCarsFlowEq, { payload: { frameId } });
    }

    const { additional, colors, retrofit } = yield select(selectEquipment());

    const mappedDetails = formMappers.mapQuoteToCMFLForm({ ...quoteDetails, leasePeriod });
    yield all(
      Object.keys(mappedDetails).map((key) => put(change(CMFL_FORM, key, mappedDetails[key]))),
    );
    yield prefillEquipmentCategory({
      equipment: equipmentFormMappers.mapEquipmentToFormValues(additional),
      category: EQUIPMENT_FORM_ADDITIONAL,
    });
    yield prefillEquipmentCategory({
      equipment: equipmentFormMappers.mapEquipmentToFormValues(colors),
      category: EQUIPMENT_FORM_COLORS,
    });
    yield prefillEquipmentCategory({
      equipment: equipmentFormMappers.mapRetrofitToFormValues({
        retrofit,
        isWithVAT: false,
      }),
      category: EQUIPMENT_FORM_RETROFIT,
    });
    yield put(
      setQuoteData({
        detailsCMFL: {
          quoteDetails,
        },
        vendorTermsId,
      }),
    );
    yield take(calculateQuoteRequestCMFL().type);
    yield put(resetIsFormEditedState());
  } catch (e) {
    yield put(app.displayError('errors.could_not_retrieve_quote'));
  }
}

export default function* caseAssetSaga() {
  yield takeLatest(updateCaseWithAssetRequest, updateCaseWithAsset);
  yield reduxSagaUtils.takeLatestAndWaitForPreviousToFinish(
    updateHirePurchaseCalculationsRequest,
    updateHirePurchaseCalculations,
  );
  yield reduxSagaUtils.takeLatestAndWaitForPreviousToFinish(
    updateAssetPriceCMFLRequest,
    updateAssetPriceCMFL,
  );
  yield takeLatest(prefillCMFLValues, prefillCMFLFormValues);
}
