import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  index: PropTypes.number.isRequired,
  isLongTitle: PropTypes.bool,
  isNotLast: PropTypes.bool.isRequired,
  isStrongVariant: PropTypes.bool,
  isSubtitle: PropTypes.bool,
  name: PropTypes.node,
  value: PropTypes.node,
} as any;
