import { ObjectUtils } from '@northstar/core/utils';

export const calculateMaximumAvailableDuration = ({
  carAgeMonths,
  maxCarAgeMonths,
  maxDurationMonths,
}) => {
  const suggestedValue = maxCarAgeMonths - carAgeMonths;
  const safeSuggestedValue = suggestedValue > 0 ? suggestedValue : 0;
  return !ObjectUtils.isNullOrUndefined(maxDurationMonths) && safeSuggestedValue > maxDurationMonths
    ? maxDurationMonths
    : safeSuggestedValue;
};

export const removeParamsFromPath = ({ path, tab }) => {
  const PARAM_REGEX = /\/:(\w+)\??/g;
  const editedPath = tab ? path.replace(':tab', tab) : path;
  return editedPath.replace(PARAM_REGEX, '');
};
