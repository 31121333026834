import { FC, CSSProperties, ReactChild, ElementType } from 'react';
import { Box, Button, SxProps, Theme } from '@mui/material';

import MAFTypography from '../../MAFTypography/MAFTypography';
import ArrowNext from '../../Icons/ArrowNext';
import MAFDisplayNumber from '../../MAFDisplayNumber/MAFDisplayNumber';
import { handleCustomSx } from '../../../utils';
import MAFIcon from '../../MAFIcon/MAFIcon';

import { useStyles } from './Step.styles';
import { propTypes } from './Step.propTypes';

interface IStepProps {
  sx?: SxProps<Theme>;
  classNameStepInfo?: SxProps<Theme>;
  style?: CSSProperties;
  step: number;
  title: ReactChild;
  lastAvailableStep: number;
  lastStep?: number;
  onStepClick: (step: number) => void;
  onClose?: () => void;
  type?: 'submit' | 'reset' | 'button';
  isDisabled?: boolean;
  customIcon?: ElementType;
}

const Step: FC<IStepProps> = ({
  sx,
  classNameStepInfo = {},
  style,
  step,
  title,
  lastAvailableStep,
  lastStep,
  onStepClick,
  onClose,
  type,
  isDisabled = false,
  customIcon,
}) => {
  const styles = useStyles({ step, lastAvailableStep, lastStep, isDisabled });
  const currentStepDisplayNumber = step + 1;
  const isStepDisabled = step > lastAvailableStep || isDisabled;
  const handleOnStepClick = () => {
    onStepClick(step);
    onClose && onClose();
  };
  return (
    <Button
      onClick={handleOnStepClick}
      sx={[styles.step, isStepDisabled && styles.stepDisabled, ...handleCustomSx(sx)]}
      tabIndex={currentStepDisplayNumber}
      style={style}
      type={type || 'submit'}
      disabled={isStepDisabled}
    >
      {customIcon && !isStepDisabled ? (
        <MAFIcon Icon={customIcon} sx={styles.icon} />
      ) : (
        <MAFDisplayNumber number={currentStepDisplayNumber} />
      )}

      <Box sx={[styles.stepInfo, ...handleCustomSx(classNameStepInfo)]}>
        <MAFTypography variant="body1" sx={styles.title}>
          {title}
        </MAFTypography>
        <Box sx={styles.iconWrapper}>
          <ArrowNext />
        </Box>
      </Box>
    </Button>
  );
};

Step.propTypes = propTypes;

export default Step;
