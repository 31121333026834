"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  links: _ref => {
    let {
      colors
    } = _ref;
    return {
      '& > :first-child': {
        borderTop: "1px solid ".concat(colors.custom.sidebar.borderColor),
        borderBottom: "1px solid ".concat(colors.custom.sidebar.borderColor)
      },
      '& > *:not(:first-child)': {
        borderBottom: "1px solid ".concat(colors.custom.sidebar.borderColor)
      }
    };
  },
  navigation: {
    padding: '35px 0'
  }
};