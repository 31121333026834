import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  activeStep: PropTypes.number.isRequired,
  buttonsClass: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  buttonsComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  buttonText: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
    .isRequired,
  children: PropTypes.node,
  /**
   * A flag that determines if we display the intro.
   * By default it will reenter intro state on screen resize
   */
  isAnimationFinished: PropTypes.bool,
  onInitialClick: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      buttonType: PropTypes.oneOf(['button', 'submit']),
      content: PropTypes.node,
      isDisabled: PropTypes.bool,
      title: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
        .isRequired,
    }),
  ).isRequired,
  subTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]).isRequired,
} as any;
