import { FC } from 'react';
import { NumberFormatValues, SourceInfo, SyntheticInputEvent } from 'react-number-format';

import MAFNumberFieldMasked, {
  IMAFNumberFieldMaskedProps,
} from '../../MAFNumberFieldMasked/MAFNumberFieldMasked';

import { styles } from './MAFCalendarInput.styles';
import { propTypes } from './MAFCalendarInput.propTypes';

export interface IMAFCalendarInputProps extends IMAFNumberFieldMaskedProps {
  onChange?: (event: SyntheticInputEvent) => void;
}

const DATE_REGEX = /\d{6,8}/;

const MAFCalendarInput: FC<IMAFCalendarInputProps> = ({
  InputProps,
  mask,
  maskedFormat,
  onChange,
  value,
  placeholder,
  inputRef,
  onBlur,
  onFocus,
  ...rest
}) => {
  const handleOnChange = (formattedValues: NumberFormatValues, sourceInfo: SourceInfo): void => {
    const event = sourceInfo.event;
    const cleanValue = formattedValues.value;
    if (!event || !onChange) return;
    if (DATE_REGEX.test(cleanValue)) {
      onChange(event);
    } else {
      event.preventDefault();
    }
  };

  return (
    <MAFNumberFieldMasked
      icon={InputProps?.endAdornment}
      adornmentClass={styles.adornment}
      onBlur={onBlur}
      onFocus={onFocus}
      inputRef={inputRef}
      maskedFormat={maskedFormat}
      mask={mask}
      value={value}
      onValueChange={handleOnChange}
      placeholder={placeholder}
      {...rest}
    />
  );
};

MAFCalendarInput.propTypes = propTypes;

export default MAFCalendarInput;
