import { useState, useRef, FC, ReactChild, useLayoutEffect } from 'react';
import { Box, SxProps, Theme } from '@mui/material';

import MAFTypography from '../MAFTypography/MAFTypography';
import MAFStepperDesktop, { IMAFStepperDesktopProps } from '../MAFStepperDesktop/MAFStepperDesktop';
import MAFDesktopStep from '../MAFStepperDesktop/MAFDesktopStep/MAFDesktopStep';
import MAFButton from '../MAFButton/MAFButton';
import { handleCustomSx } from '../../utils';

import { styles } from './MAFStepperIntroDesktop.styles';
import { propTypes } from './MAFStepperIntroDesktop.propTypes';

export interface IMAFStepperIntroDesktopProps extends IMAFStepperDesktopProps {
  title: ReactChild;
  subTitle: ReactChild;
  /**
   * A flag that determines if we display the intro.
   * By default it will reenter intro state on screen resize
   */
  isAnimationFinished?: boolean;
  buttonText: ReactChild;
  onInitialClick: (step: number) => void;
  buttonsComponent?: ReactChild;
  buttonsClass?: SxProps<Theme>;
}

const MAFStepperIntroDesktop: FC<IMAFStepperIntroDesktopProps> = ({
  steps,
  title,
  subTitle,
  activeStep,
  isAnimationFinished: isAnimationFinishedInitial = false,
  buttonText,
  onInitialClick,
  buttonsComponent,
  buttonsClass = {},
  ...rest
}) => {
  const refsStepper = useRef<HTMLDivElement[]>([]);
  const refsStepsList = useRef<HTMLDivElement[]>([]);

  const [isAnimating, setIsAnimating] = useState(false);
  const [isAnimationFinished, setIsAnimationFinished] = useState(isAnimationFinishedInitial);
  const [isStepperActive, setIsStepperActive] = useState(isAnimationFinishedInitial);

  const [coordinatesStepper, setCoordinatesStepper] = useState<DOMRect[]>([]);
  const [coordinatesStepsList, setCoordinatesStepsList] = useState<DOMRect[]>([]);

  const updateCoordinatesStepsList = () =>
    setCoordinatesStepsList(refsStepsList.current.map((item) => item.getBoundingClientRect()));

  const updateCoordinatesStepper = () =>
    setCoordinatesStepper(refsStepper.current.map((item) => item.getBoundingClientRect()));

  // We want to apply recalculation before drawing
  useLayoutEffect(() => {
    updateCoordinatesStepsList();
    window.addEventListener('resize', updateCoordinatesStepsList);
    window.addEventListener('scroll', updateCoordinatesStepsList);
    return () => {
      window.removeEventListener('resize', updateCoordinatesStepsList);
      window.removeEventListener('scroll', updateCoordinatesStepsList);
    };
  }, [refsStepsList?.current]);

  useLayoutEffect(() => {
    updateCoordinatesStepper();
    window.addEventListener('resize', updateCoordinatesStepper);
    window.addEventListener('scroll', updateCoordinatesStepper);
    return () => {
      window.removeEventListener('resize', updateCoordinatesStepper);
      window.removeEventListener('scroll', updateCoordinatesStepper);
    };
  }, [refsStepper?.current]);

  const handleClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      onInitialClick(0);
      setIsAnimationFinished(true);
    }, 750);
    setTimeout(() => setIsStepperActive(true), 850);
  };

  const calculateStepCoordinates = (index: number) => {
    if (!isAnimating && !coordinatesStepsList[index]) return {};
    const topBase = isAnimating ? coordinatesStepper[index].top : coordinatesStepsList[index].top;
    const leftBase = isAnimating
      ? coordinatesStepper[index].left + 3
      : coordinatesStepsList[index].left;

    const adjustmentForActiveStep = !index ? -12 : 0;

    const topAdjustment = isAnimating ? 0 + adjustmentForActiveStep : 0 + window.scrollY;
    const leftAdjustment = isAnimating ? 0 + adjustmentForActiveStep : -125;

    return {
      top: topBase + topAdjustment,
      left: leftBase + leftAdjustment,
    };
  };

  const calculateTextCoordinates = (index: number) => {
    if (!isAnimating && !coordinatesStepsList[index]) return {};
    const topBase = isAnimating ? coordinatesStepper[index].top : coordinatesStepsList[index].top;
    const leftBase = isAnimating
      ? coordinatesStepper[index].left
      : coordinatesStepsList[index].left;

    const topAdjustment = isAnimating ? 36 : 13 + window.scrollY;
    const leftAdjustment = isAnimating ? 8 : -60;

    return {
      top: topBase + topAdjustment,
      left: leftBase + leftAdjustment,
    };
  };

  return (
    <>
      <MAFStepperDesktop
        parentRefs={refsStepper}
        isIntroAnimating={isAnimating && !isStepperActive}
        steps={steps}
        sx={[styles.hiddenStepper, isAnimationFinished && styles.visibleStepper]}
        activeStep={activeStep}
        {...rest}
      />
      {isAnimationFinished ? (
        steps[activeStep].content
      ) : (
        <>
          <Box sx={styles.container}>
            <Box sx={[isAnimating && styles.animateContainerText]}>
              <MAFTypography variant="h1-italic" isWithContainer>
                {title}
              </MAFTypography>
              <MAFTypography variant="body1" sx={styles.subText} isWithContainer>
                {subTitle}
              </MAFTypography>
            </Box>
            <Box>
              {steps.map((step, index) => (
                <Box key={index}>
                  <Box
                    ref={(ref: HTMLDivElement) => {
                      if (ref) refsStepsList.current[index] = ref;
                    }}
                    sx={styles.stepInfoPlaceholder}
                  >
                    &nbsp;
                  </Box>
                  <MAFDesktopStep
                    tabIndex={-1}
                    sx={[
                      styles.transition,
                      isAnimating && Boolean(index) && styles.stepInactive,
                      ((isAnimating && Boolean(coordinatesStepper)) ||
                        Boolean(coordinatesStepsList)) &&
                        calculateStepCoordinates(index),
                    ]}
                    activeStep={!isAnimating || index === 0 ? index : undefined}
                  />
                  <MAFTypography
                    variant="label1"
                    sx={[
                      styles.stepTitle,
                      styles.transition,
                      isAnimating && styles.animateStepTitle,
                      isAnimating && Boolean(index) && styles.stepTitleInactive,
                      ((isAnimating && Boolean(coordinatesStepper)) ||
                        Boolean(coordinatesStepsList)) &&
                        calculateTextCoordinates(index),
                    ]}
                  >
                    {step.title}
                  </MAFTypography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box sx={[styles.buttons, ...handleCustomSx(buttonsClass)]}>
            {buttonsComponent}
            <MAFButton color="primaryPR" onClick={handleClick}>
              {buttonText}
            </MAFButton>
          </Box>
        </>
      )}
    </>
  );
};

MAFStepperIntroDesktop.propTypes = propTypes;

export default MAFStepperIntroDesktop;
