import { keyframes } from '@mui/styled-engine';

import { ISxStyles } from '../../theming/theme.type';

const slideDown = keyframes`
  0% {
    margin: 10px 8px -80px 8px;
    opacity: 0;
  }
  100% {
    margin: 8px 8px 0px 8px;
    opacity: 1;
  }
`;

const visibleStyles = {
  visibility: 'visible',
  opacity: 1,
  margin: '8px 8px 0px 8px',
  animation: `${slideDown} 0.15s`,
  zIndex: 1,
};

export const styles: ISxStyles = {
  helperText: ({ colors }) => ({
    '&.MuiFormHelperText-root': {
      lineHeight: '1.6',
      visibility: 'visible',
      opacity: 0,
      margin: '10px 8px -80px 8px',
      padding: 2,
      width: 'calc(100% - 48px)',
      borderRadius: '2px',
      position: 'relative',
      ...colors.custom.inputFull.helper,
      '&:before': {
        ...colors.custom.inputFull.helper,
        boxShadow: 'none',
        float: 'left',
        position: 'absolute',
        display: 'block',
        content: '""',
        transform: 'rotate(45deg)',
        top: -5,
        width: 10,
        height: 10,
      },
    },
    '&.MuiFormHelperText-root.Mui-error': {
      ...colors.custom.inputFull.error,
      boxShadow: 'none',
      '&:before': {
        ...colors.custom.inputFull.error,
        boxShadow: 'none',
      },
    },
  }),
  helperTextVisible: {
    '&.MuiFormHelperText-root.Mui-focused': visibleStyles,
  },
  helperTextVisibleError: {
    '&.MuiFormHelperText-root': visibleStyles,
  },
  helperTextFocused: ({ colors }) => ({
    '&.MuiFormHelperText-root': {
      ...colors.custom.inputFull.helper,
    },
  }),
  helperTextRight: {
    '&.MuiFormHelperText-root': {
      '&:before': {
        float: 'right',
      },
    },
  },
};
