import { FC, ReactNode } from 'react';
import { TableSortLabel, TableCell, TableCellProps, Box, SxProps, Theme } from '@mui/material';

import { TMAFTableDirection } from '../../MAFTable.types';
import { ArrowDescending } from '../../../Icons';
import { handleCustomSx } from '../../../../utils';
import { MAFTooltip } from '../../../MAFTooltip';

import { styles } from './MAFCell.styles';
import { propTypes } from './MAFSortableCell.propTypes';

interface IMAFSortableCellProps extends TableCellProps {
  children: ReactNode;
  onOrder?: () => void;
  name: string;
  orderBy?: string;
  direction?: TMAFTableDirection;
  isUnsortable?: boolean;
  sortField?: string;
  isUplift?: boolean;
  sx?: SxProps<Theme>;
  shouldAdjustHeaderPosition?: boolean;
  sortTitle?: ReactNode;
}

const MAFSortableCell: FC<IMAFSortableCellProps> = ({
  children,
  onOrder,
  name,
  orderBy,
  direction,
  isUnsortable,
  sortField,
  isUplift = false,
  sx,
  shouldAdjustHeaderPosition = false,
  sortTitle,
  ...rest
}) => {
  const tableSortLabel = (
    <TableSortLabel
      active={orderBy === name || orderBy === sortField}
      direction={direction}
      sx={[isUplift && styles.headTypeBodyUplift, styles.icon]}
      IconComponent={ArrowDescending}
      onClick={onOrder}
    >
      {children}
    </TableSortLabel>
  );

  return (
    <TableCell
      sx={[
        shouldAdjustHeaderPosition && styles.adjusted,
        styles.root,
        styles.cellTypeBody,
        styles.headTypeBody,
        ...handleCustomSx(sx),
      ]}
      {...rest}
    >
      {!isUnsortable ? (
        sortTitle ? (
          <MAFTooltip title={sortTitle} enterDelay={300}>
            {tableSortLabel}
          </MAFTooltip>
        ) : (
          tableSortLabel
        )
      ) : (
        children
      )}

      {!isUplift && <Box sx={styles.underline} />}
    </TableCell>
  );
};

MAFSortableCell.propTypes = propTypes;

export default MAFSortableCell;
