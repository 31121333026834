//  CMFL
export const CMFL_FORM = 'commercialFinancialLeaseForm';
export const CMFL_FORM_CAR_PRICE = 'carPrice';
export const CMFL_FORM_INTEREST_RATE = 'interestRate';
export const CMFL_FORM_CLIMATE_BONUS = 'climateBonusValue';
export const CMFL_FORM_RESIDUAL_VALUE = 'residualValue';
export const CMFL_FORM_DOWN_PAYMENT_VALUE = 'downPaymentValue';
export const CMFL_FORM_DOWN_PAYMENT_PERCENTAGE = 'downPaymentPercentage';
export const CMFL_FORM_VENDOR_TERMS_ID = 'vendorTermsId';
export const CMFL_FORM_DURATION = 'duration';
export const DURATION_STEP_SIZE = 12;

export const CMFL_FORM_CAR_PRICE_MAPPED_FIELD = 'carPriceExVat';
export const CMFL_FORM_INTEREST_RATE_MAPPED_FIELD = 'totalYearlyInterestRate';
export const CMFL_FORM_CLIMATE_BONUS_MAPPED_FIELD = 'climateBonusValueExVat';
export const CMFL_FORM_RESIDUAL_VALUE_MAPPED_FIELD = 'carRvReferenceExVat';
export const CMFL_FORM_PAYMENT_VALUE_MAPPED_FIELD = 'downPaymentValueExVat';
export const CMFL_FORM_DURATION_MAPPED_FIELD = 'durationMonths';

//  CMOL
export const CMOL_DOWN_PAYMENT_FORM = 'downPaymentForm';
export const CMOL_FORM_DOWN_PAYMENT_PERCENTAGE = CMFL_FORM_DOWN_PAYMENT_PERCENTAGE;
export const CMOL_FORM_DOWN_PAYMENT_VALUE = CMFL_FORM_DOWN_PAYMENT_VALUE;
export const CMOL_FORM_DOWN_PAYMENT_MAPPED_FIELD = CMFL_FORM_PAYMENT_VALUE_MAPPED_FIELD;

export const CMOL_FORM_DESIRED_QUOTE_MAPPED_FIELD = 'desiredQuoteExVat';
export const CMOL_FORM_DISCOUNT_MAPPED_FIELD = 'discountExVat';

// PROL
export const PROL_FORM_DESIRED_QUOTE_MAPPED_FIELD = 'desiredQuoteInVat';
export const PROL_FORM_DISCOUNT_MAPPED_FIELD = 'discountInVat';

//  CMOL, PROL
export const CMOL_PROL_PRICE_DEDUCTION_FORM = 'priceDeductionForm ';
export const CMOL_PROL_FORM_DESIRED_QUOTE = 'quote';
export const CMOL_PROL_FORM_DISCOUNT = 'discount';
