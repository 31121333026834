import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  icon: ({ colors }) => ({
    '& g': colors.custom.icon,
  }),
  tabIndex: ({ colors }) => ({
    '&:focus-visible': {
      border: `0.5px solid ${colors.danske.action}`,
      borderRadius: '4px',
      outline: '-webkit-focus-ring-color auto 1px',
    },
  }),
};
