"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  keyContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    cursor: 'pointer'
  }
};