import { ObjectUtils } from '@northstar/core';
import { SERVICE_TYPE_WINTER_TIRES, SERVICE_TYPE_INSURANCE } from '@northstar/core-ui/modules';
import { objectUtils } from '@northstar/core-ui/utils';

import {
  CMFL_FORM_CAR_PRICE,
  CMFL_FORM_INTEREST_RATE,
  CMFL_FORM_CLIMATE_BONUS,
  CMFL_FORM_RESIDUAL_VALUE,
  CMFL_FORM_DOWN_PAYMENT_VALUE,
  CMFL_FORM_DURATION,
  CMFL_FORM_CAR_PRICE_MAPPED_FIELD,
  CMFL_FORM_INTEREST_RATE_MAPPED_FIELD,
  CMFL_FORM_CLIMATE_BONUS_MAPPED_FIELD,
  CMFL_FORM_RESIDUAL_VALUE_MAPPED_FIELD,
  CMFL_FORM_PAYMENT_VALUE_MAPPED_FIELD,
  CMFL_FORM_DURATION_MAPPED_FIELD,
  CMOL_FORM_DOWN_PAYMENT_MAPPED_FIELD,
  CMOL_FORM_DOWN_PAYMENT_VALUE,
  CMOL_FORM_DESIRED_QUOTE_MAPPED_FIELD,
  CMOL_FORM_DISCOUNT_MAPPED_FIELD,
  PROL_FORM_DESIRED_QUOTE_MAPPED_FIELD,
  PROL_FORM_DISCOUNT_MAPPED_FIELD,
  CMOL_PROL_FORM_DISCOUNT,
  CMOL_PROL_FORM_DESIRED_QUOTE,
} from './quoteConstants';

export const mapRecalculateRequestOL = ({
  asset,
  selectedTerms,
  selectedEquipment,
  retrofitEquipment,
  quoteData,
  priceDeduction,
  vinNumber,
  registrationNumber,
  allValidTerms,
}) => {
  const { mileage, period } = selectedTerms;
  const { commission, discount } = priceDeduction;
  const [vendorTermsId] = asset.vendorTermsIds || [];
  return {
    asset_id: asset.id,
    vendor_terms_id: allValidTerms.find((terms) => terms.vendorTermsId === vendorTermsId)
      ? vendorTermsId
      : undefined,
    option_list: [
      ...Object.keys(selectedEquipment.optional)
        .map((key) => selectedEquipment.optional[key].id)
        .filter((id) => !ObjectUtils.isNullOrEmpty(id)),
    ],
    lease_period: period,
    yearly_mileage: mileage,
    commission: commission >= 0 ? commission : quoteData.commission,
    discount: discount >= 0 ? discount : quoteData.discount,
    optional_retrofit_equipment_packages: retrofitEquipment,
    winter_tires: Boolean(
      selectedEquipment.services.find(({ id }) => id === SERVICE_TYPE_WINTER_TIRES),
    ),
    insurance: Boolean(selectedEquipment.services.find(({ id }) => id === SERVICE_TYPE_INSURANCE)),
    service: true,
    vin_number: vinNumber,
    registration_number: registrationNumber,
  };
};

const mapRecalculateRequestPROL = ({
  asset,
  selectedTerms,
  terms,
  selectedEquipment,
  retrofitEquipment,
  quoteData,
  priceDeduction,
  vinNumber,
  registrationNumber,
  allValidTerms,
}) => {
  const genericOLRequest = mapRecalculateRequestOL({
    asset: {
      ...asset,
      vendorTermsIds: asset.privateVendorTermsIds,
    },
    selectedTerms,
    terms,
    selectedEquipment,
    retrofitEquipment,
    quoteData,
    priceDeduction,
    vinNumber,
    registrationNumber,
    allValidTerms,
  });
  const { quote } = priceDeduction;
  return {
    ...genericOLRequest,
    desired_quote: quote,
    discount: !quote ? genericOLRequest.discount : null,
  };
};

const mapRecalculateRequestCMOL = ({
  asset,
  selectedTerms,
  terms,
  selectedEquipment,
  retrofitEquipment,
  quoteData,
  priceDeduction,
  vinNumber,
  registrationNumber,
  downPayment,
  useDownPaymentPercentage,
  allValidTerms,
}) => {
  const currentDownPaymentValue = !ObjectUtils.isNullOrUndefined(downPayment.value)
    ? downPayment.value
    : quoteData.downPaymentValue;
  const downPaymentValue = !useDownPaymentPercentage ? currentDownPaymentValue : null;

  const currentDownPaymentPercentage = !ObjectUtils.isNullOrUndefined(downPayment.percentage)
    ? downPayment.percentage
    : quoteData.downPaymentPercentage;

  const downPaymentPercentage = !ObjectUtils.isNullOrUndefined(currentDownPaymentPercentage)
    ? currentDownPaymentPercentage
    : terms.defaultDownPaymentPercentage;

  const genericOLRequest = mapRecalculateRequestOL({
    asset: {
      ...asset,
      vendorTermsIds: asset.commercialVendorTermsIds,
    },
    selectedTerms,
    terms,
    selectedEquipment,
    retrofitEquipment,
    quoteData,
    priceDeduction,
    vinNumber,
    registrationNumber,
    allValidTerms,
  });
  const { quote } = priceDeduction;
  return {
    ...genericOLRequest,
    desired_quote: quote,
    discount: !quote ? genericOLRequest.discount : null,
    down_payment_value: !ObjectUtils.isNullOrUndefined(downPaymentValue) ? downPaymentValue : null,
    down_payment_percentage: ObjectUtils.isNullOrUndefined(downPaymentValue)
      ? downPaymentPercentage
      : null,
  };
};

const errorToUIFieldValues = [
  {
    errorField: CMFL_FORM_CAR_PRICE_MAPPED_FIELD,
    uiField: CMFL_FORM_CAR_PRICE,
  },
  {
    errorField: CMFL_FORM_INTEREST_RATE_MAPPED_FIELD,
    uiField: CMFL_FORM_INTEREST_RATE,
  },
  {
    errorField: CMFL_FORM_CLIMATE_BONUS_MAPPED_FIELD,
    uiField: CMFL_FORM_CLIMATE_BONUS,
  },
  {
    errorField: CMFL_FORM_RESIDUAL_VALUE_MAPPED_FIELD,
    uiField: CMFL_FORM_RESIDUAL_VALUE,
  },
  {
    errorField: CMFL_FORM_PAYMENT_VALUE_MAPPED_FIELD,
    uiField: CMFL_FORM_DOWN_PAYMENT_VALUE,
  },
  {
    errorField: CMFL_FORM_DURATION_MAPPED_FIELD,
    uiField: CMFL_FORM_DURATION,
  },
  {
    errorField: CMOL_FORM_DOWN_PAYMENT_MAPPED_FIELD,
    uiField: CMOL_FORM_DOWN_PAYMENT_VALUE,
  },
  {
    errorField: CMOL_FORM_DESIRED_QUOTE_MAPPED_FIELD,
    uiField: CMOL_PROL_FORM_DESIRED_QUOTE,
  },
  {
    errorField: CMOL_FORM_DISCOUNT_MAPPED_FIELD,
    uiField: CMOL_PROL_FORM_DISCOUNT,
  },
  {
    errorField: PROL_FORM_DESIRED_QUOTE_MAPPED_FIELD,
    uiField: CMOL_PROL_FORM_DESIRED_QUOTE,
  },
  {
    errorField: PROL_FORM_DISCOUNT_MAPPED_FIELD,
    uiField: CMOL_PROL_FORM_DISCOUNT,
  },
];

const mapInterestValuesCMFL = (values) => objectUtils.objectToCamelCase({ obj: values });

export const requestMappers = {
  mapRecalculateQuotePROL: mapRecalculateRequestPROL,
  mapRecalculateQuoteCMOL: mapRecalculateRequestCMOL,
};

export const responseMappers = {
  mapInterestValuesCMFL,
  errorToUIFieldValues,
};
