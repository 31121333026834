import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  containerParent: ({ colors, spacing }) => ({
    '&::after': {
      display: 'block',
      content: '""',
      height: '1px',
      width: '100%',
      background: colors.custom.stepper.underline,
      marginTop: spacing(1),
    },
  }),
  stepperContainer: {
    width: '100%',
    backgroundColor: 'inherit',
    zIndex: 0,
    padding: 3,
  },
  alternativeLabel: {
    '&.MuiStepConnector-alternativeLabel': {
      zIndex: 0,
      left: '-50%',
      right: '50%',
      top: '50%',
    },
  },
  activeLine: ({ colors }) => ({
    '&.MuiStepConnector-root.Mui-active, .MuiStepConnector-root.Mui-completed': {
      '& .MuiStepConnector-line': {
        borderColor: colors.custom.stepper.active,
      },
    },
  }),
  line: ({ colors }) => ({
    '& .MuiStepConnector-line': {
      borderColor: colors.custom.stepper.inactive,
    },
  }),
  lineAnimation: {
    '& .MuiStepConnector-line': {
      visibility: 'visible',
    },
  },
  button: {
    '&.MuiStepButton-root': {
      cursor: 'default',
      '&:disabled': {
        pointerEvents: 'auto',
      },
    },
  },
  labelRoot: {
    '&.MuiStepLabel-root': {
      marginTop: '14px',
    },
  },
  label: ({ colors, typography, fontSizes }) => ({
    '& .MuiStepLabel-label': {
      '&.Mui-active': {
        color: colors.custom.stepper.active,
      },
      color: colors.custom.stepper.active,
      maxHeight: 20,
      overflowWrap: 'anywhere',
      fontFamily: typography.main,
      fontSize: fontSizes.extraSmall,
    },
  }),
  labelContainer: {
    '& .MuiStepLabel-labelContainer': {
      visibility: 'hidden',
    },
  },
  step: ({ colors }) => ({
    height: 18,
    width: 18,
    backgroundColor: colors.custom.stepper.inactive,
    borderRadius: '50%',
    zIndex: 1,
    marginTop: 3,
    outline: 'none',
    cursor: 'pointer',
    '&:focus': colors.custom.stepper.activeStep,
  }),
  activeStep: ({ colors }) => ({
    backgroundColor: colors.custom.stepper.active,
    '&:hover': colors.custom.stepper.activeStep,
  }),
};
