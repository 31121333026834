"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledEngine = require("@mui/styled-engine");
var _templateObject;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const shake = (0, _styledEngine.keyframes)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  10%, 90% {\n    transform: translate3d(-1px, 0, 0);\n  }\n  20%, 80% {\n    transform: translate3d(2px, 0, 0);\n  }\n  30%, 50%, 70% {\n    transform: translate3d(-4px, 0, 0);\n  }\n  40%, 60% {\n    transform: translate3d(4px, 0, 0);\n  }\n"])));
const styles = exports.styles = {
  container: _ref => {
    let {
      colors,
      breakpoints
    } = _ref;
    return _objectSpread(_objectSpread({
      margin: 'auto'
    }, colors.custom.loginBlock), {}, {
      borderRadius: '6px',
      maxWidth: 400,
      padding: '66px 55px 0px',
      [breakpoints.only('xs')]: {
        margin: '86px auto 20px',
        padding: '66px 42px 0px',
        maxWidth: 'calc(100vw - 50px)',
        minWidth: 300
      }
    });
  },
  form: {
    maxWidth: '100%'
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: 5,
    '& > svg': {
      width: 200,
      '@media not all and (min-resolution:.001dpcm)': {
        height: 'intrinsic'
      }
    },
    '& > img': {
      width: 200
    }
  },
  languageToggleContainer: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  error: _ref2 => {
    let {
      typography: {
        main
      },
      fontSizes: {
        extraSmall
      },
      colors
    } = _ref2;
    return {
      animation: "".concat(shake, " 0.82s cubic-bezier(.36,.07,.19,.97) both"),
      fontFamily: main,
      fontSize: extraSmall,
      color: colors.solid.white,
      background: colors.solid.redBackground,
      marginBottom: 3,
      padding: 2,
      display: 'block',
      textAlign: 'center',
      borderRadius: '10px'
    };
  },
  forgotPassword: {
    marginTop: 16
  },
  footer: _ref3 => {
    let {
      typography: {
        main
      },
      fontSizes: {
        tiny
      },
      colors
    } = _ref3;
    return {
      textAlign: 'center',
      fontFamily: main,
      fontWeight: 500,
      padding: '40px 0',
      color: colors.custom.fontPrimary,
      maxWidth: 300,
      fontSize: "".concat(tiny, " !important"),
      margin: 'auto'
    };
  },
  title: {
    fontWeight: 'bold'
  },
  link: _ref4 => {
    let {
      colors
    } = _ref4;
    return {
      color: colors.custom.fontPrimary,
      textDecoration: 'none'
    };
  }
};