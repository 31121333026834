import { FC, ReactNode, ReactChild, ReactElement } from 'react';
import {
  RadioGroup,
  FormControl,
  FormLabel,
  FormHelperTextProps,
  RadioGroupProps,
  Box,
  SxProps,
  Theme,
} from '@mui/material';

import MAFHelperText from '../MAFHelperText/MAFHelperText';
import MAFTooltipLabel, { IMAFTooltipLabelProps } from '../MAFTooltipLabel/MAFTooltipLabel';
import { handleCustomSx } from '../../utils';

import { useStyles } from './MAFRadioSelect.styles';
import MAFRadioSelectButton from './MAFRadioSelectButton/MAFRadioSelectButton';
import { propTypes } from './MAFRadioSelect.propTypes';

export type TMAFRadioSelectValue = {
  label: ReactChild;
  value: ReactChild;
  isDisabled?: boolean;
};

export interface IMAFRadioSelectProps extends RadioGroupProps {
  id?: string;
  name: string;
  values: TMAFRadioSelectValue[];
  isError?: boolean;
  isVertical?: boolean;
  isFullWidth?: boolean;
  label?: ReactNode;
  helperText?: ReactChild;
  tooltipText?: ReactNode;
  formHelperTextProps?: FormHelperTextProps;
  value?: ReactChild;
  containerClass?: SxProps<Theme>;
  inputComponent?: ReactElement;
  isFocused?: boolean;
  tooltipProps?: Omit<IMAFTooltipLabelProps, 'tooltipText' | 'label'>;
}

const MAFRadioSelect: FC<IMAFRadioSelectProps> = ({
  name,
  label,
  values,
  tooltipText,
  isVertical = false,
  isFullWidth = false,
  helperText,
  formHelperTextProps,
  value,
  defaultValue,
  containerClass = {},
  inputComponent,
  isError = false,
  isFocused,
  tooltipProps,
  ...rest
}) => {
  const currentValue = !value && defaultValue ? defaultValue : value;
  const isWithValue = Boolean(currentValue);

  const styles = useStyles({ isWithValue });
  const radioGroupRoot = [
    styles.row,
    isError && isVertical && styles.rowError,
    isError && !isVertical && styles.rowErrorHorizontal,
  ];

  return (
    <FormControl
      sx={[styles.container, ...handleCustomSx(containerClass)]}
      component="fieldset"
      focused={isFocused}
    >
      {label && (
        <FormLabel sx={styles.labelRoot} component="legend">
          <MAFTooltipLabel tooltipText={tooltipText} label={label} {...tooltipProps} />
        </FormLabel>
      )}

      <RadioGroup
        aria-label={name}
        name={name}
        row={!isVertical}
        sx={radioGroupRoot}
        value={currentValue}
        {...rest}
      >
        {values.map((props, index) => (
          <MAFRadioSelectButton
            key={index}
            sx={[styles.rowElement, isVertical && styles.vertical, isFullWidth && styles.fullWidth]}
            isError={isError}
            {...props}
          />
        ))}
      </RadioGroup>
      {helperText && (
        <MAFHelperText formHelperTextProps={formHelperTextProps} isError={isError}>
          {helperText}
        </MAFHelperText>
      )}

      {inputComponent && inputComponent.props.renderOnValue === value && (
        <Box sx={styles.input}>{inputComponent}</Box>
      )}
    </FormControl>
  );
};

MAFRadioSelect.propTypes = propTypes;

export default MAFRadioSelect;
