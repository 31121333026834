import { ISxStyles } from '../../theming/theme.type';

interface IProps {
  isStrongVariant?: boolean;
}

export const useStyles = ({ isStrongVariant }: IProps): ISxStyles => ({
  topBar: ({ colors }) => ({
    height: 5,
    borderRadius: 2,
    background: colors.solid.cadedBlue,
    zIndex: 1,
    position: 'relative',
    ...colors.custom.summaryEdit.topBar,
  }),
  slideContainer: {
    overflow: 'hidden',
  },
  wrapper: ({ spacing, colors, breakpoints }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.danske.white,
    borderRadius: `0 0 ${spacing(1)} ${spacing(1)}`,
    padding: spacing(0, 2),
    ...colors.custom.summaryEdit.wrapper,
    margin: `0px ${spacing(8)} ${spacing(2)}`,
    [breakpoints.only('xs')]: {
      margin: `0px ${spacing(1)} ${spacing(2)}`,
    },
  }),
  container: ({ spacing, colors }) => ({
    padding: spacing(2, 0),
    borderTop: `1px solid ${isStrongVariant ? colors.danske.primary100 : colors.danske.greyC60}`,
  }),
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '2px 2px 0 0',
  },
  button: ({ colors, fontSizes }) => ({
    color: colors.danske.action,
    fontSize: fontSizes.small,
    marginRight: '-10px',
  }),
  value: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
