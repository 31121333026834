import { caseDetail, documents } from '@northstar/core-ui/modules';
import { fromJS } from 'immutable';
import { createAction, handleActions, combineActions } from 'redux-actions';
/**
 * Actions
 */
export const createCaseRequest = createAction('CASE_DETAIL_CREATE_CASE_REQUEST');
export const createCaseResponse = createAction('CASE_DETAIL_CREATE_CASE_RESPONSE');
export const clearCaseState = createAction('CASE_DETAIL_CLEAR_STATE');
export const updateGeneralInformationRequest = createAction(
  'CASE_DETAIL_UPDATE_GENERAL_INFORMATION_REQUEST',
);
export const updateGeneralInformationResponse = createAction(
  'CASE_DETAIL_UPDATE_GENERAL_INFORMATION_RESPONSE',
);
export const updateCompanyInformationRequest = createAction(
  'CASE_DETAIL_UPDATE_COMPANY_INFORMATION_REQUEST',
);
export const updateCompanyInformationResponse = createAction(
  'CASE_DETAIL_UPDATE_COMPANY_INFORMATION_RESPONSE',
);
export const sendPdfRequest = createAction('CASE_DETAIL_SEND_PDF_REQUEST');
export const sendPdfResponse = createAction('CASE_DETAIL_SEND_PDF_RESPONSE');
export const changeCaseFlowRequest = createAction('CASE_DETAIL_SWITCH_CASE_FLOW_REQUEST');
export const changeCaseFlowResponse = createAction('CASE_DETAIL_SWITCH_CASE_FLOW_RESPONSE');
export const setAssetDeliveredRequest = createAction('CASE_DETAIL_SET_ASSET_DELIVERED_REQUEST');
export const setAssetDeliveredResponse = createAction('CASE_DETAIL_SET_ASSET_DELIVERED_RESPONSE');
export const resetGeneralCarInformationInCase = createAction(
  'CASE_DETAIL_RESET_GENERAL_CAR_INFORMATION_IN_CASE',
);
export const createDraftScenarioRequest = createAction('CASE_DETAIL_CREATE_DRAFT_SCENARIO_REQUEST');
export const createDraftScenarioResponse = createAction(
  'CASE_DETAIL_CREATE_DRAFT_SCENARIO_RESPONSE',
);
export const proceedFromCustomerDataGatheringStepRequest = createAction(
  'CASE_DETAIL_PROCEED_FROM_CUSTOMER_DATA_GATHERING_STEP_REQUEST',
);
export const proceedFromCustomerDataGatheringStepResponse = createAction(
  'CASE_DETAIL_PROCEED_FROM_CUSTOMER_DATA_GATHERING_STEP_RESPONSE',
);

export const restartCaseSagas = createAction('CASE_DETAIL_RESTART_CASE_SAGAS');

/**
 * Initial state
 */
export const initialState = fromJS({
  details: {
    applicant: {
      applicantDetails: {
        contactPersonDetails: {},
      },
    },
  },
  creatingCase: false,
  gettingCase: false,
  updatingCase: false,
  updatingStage: false,
  sendingPdf: false,
  proceedingCustomerDataGathering: false,
});

/**
 * Actions handlers
 */
export default handleActions(
  {
    // Getting existing case
    [caseDetail.getCaseRequest](state) {
      return state.set('gettingCase', true);
    },
    [caseDetail.getCaseResponse](state, { payload, error }) {
      return !error
        ? state.set('gettingCase', false).set('details', fromJS(payload))
        : state.set('gettingCase', false);
    },
    // Creating case
    [createCaseRequest](state) {
      return state.set('creatingCase', true);
    },
    [createCaseResponse](state, { payload, error }) {
      return !error
        ? state.set('details', fromJS(payload)).set('creatingCase', false)
        : state.set('creatingCase', false);
    },
    [combineActions(
      updateGeneralInformationRequest,
      updateCompanyInformationRequest,
      setAssetDeliveredRequest,
      createDraftScenarioRequest,
    )](state) {
      return state.set('updatingCase', true);
    },
    [combineActions(
      updateGeneralInformationResponse,
      updateCompanyInformationResponse,
      setAssetDeliveredResponse,
      createDraftScenarioResponse,
    )](state) {
      return state.set('updatingCase', false);
    },
    [combineActions(sendPdfRequest, documents.sendQuoteDocumentRequest)](state) {
      return state.set('sendingPdf', true);
    },
    [combineActions(sendPdfResponse, documents.sendQuoteDocumentResponse)](
      state,
      { payload, error },
    ) {
      return !error
        ? state.set('sendingPdf', false)
        : state.set('sendingPdf', false).set('error', payload.message);
    },
    [changeCaseFlowResponse](state, { payload, error }) {
      return !error ? state.setIn(['details', 'productSubType'], payload.productSubType) : state;
    },
    [resetGeneralCarInformationInCase](state) {
      return state
        .setIn(['details', 'asset', 'registrationNumber'], null)
        .setIn(['details', 'asset', 'vinNumber'], null);
    },
    [clearCaseState]() {
      return initialState;
    },
    [proceedFromCustomerDataGatheringStepRequest](state) {
      return state.set('proceedingCustomerDataGathering', true);
    },
    [proceedFromCustomerDataGatheringStepResponse](state, { payload, error }) {
      return !error
        ? state.set('details', fromJS(payload)).set('proceedingCustomerDataGathering', false)
        : state.set('proceedingCustomerDataGathering', false);
    },
  },
  initialState,
);
