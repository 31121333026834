import { ReactElement, FC } from 'react';

import MAFButton, { IMAFButtonProps } from '../MAFButton/MAFButton';
import MAFDialog, { IMAFDialogProps } from '../MAFDialog/MAFDialog';

import { propTypes } from './MAFAlertDialog.propTypes';

export interface IMAFAlertDialogProps extends IMAFDialogProps {
  closeButtonText?: ReactElement | string;
  onClose?: () => void;
}

const MAFAlertDialog: FC<IMAFAlertDialogProps> = ({
  title,
  message,
  isLoading,
  closeButtonText,
  onClose,
  ...rest
}) => (
  <MAFDialog {...rest} isLoading={isLoading} onClose={onClose} title={title} message={message}>
    <MAFButton
      onClick={onClose as IMAFButtonProps['onClick']}
      color="primaryPR"
      isRounded
      disabled={isLoading}
    >
      {closeButtonText}
    </MAFButton>
  </MAFDialog>
);

MAFAlertDialog.propTypes = propTypes;

export default MAFAlertDialog;
