"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = supportSaga;
exports.readNotifications = exports.getAllNotifications = void 0;
exports.tryToRedirect = tryToRedirect;
var _effects = require("redux-saga/effects");
var _appReducer = require("../app/appReducer");
var casesApi = _interopRequireWildcard(require("../cases/casesApi"));
var _appSelectors = require("../app/appSelectors");
var _reduxSagaUtils = require("../../utils/redux-saga-utils");
var notificationsApi = _interopRequireWildcard(require("./notificationsApi"));
var _notificationsConstants = require("./notificationsConstants");
var _notificationsReducer = require("./notificationsReducer");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const getAllNotifications = exports.getAllNotifications = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: notificationsApi.getAllNotifications,
  successActions: [_notificationsReducer.getAllNotificationsResponse],
  errorActions: [_notificationsReducer.getAllNotificationsResponse]
});
function* getAllNotificationsPoll() {
  while (true) {
    const isLongPollingAllowed = yield (0, _effects.select)((0, _appSelectors.selectIsLongPollingAllowed)());
    if (isLongPollingAllowed) yield (0, _effects.put)((0, _notificationsReducer.getAllNotificationsRequest)());
    yield (0, _effects.delay)(_notificationsConstants.GET_NOTIFICATIONS_TIMEOUT);
  }
}
const readNotifications = exports.readNotifications = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: notificationsApi.readNotifications,
  successActions: [_notificationsReducer.readNotificationsResponse, _notificationsReducer.getAllNotificationsRequest],
  errorActions: [_notificationsReducer.readNotificationsResponse, _appReducer.displayError]
});
function tryToRedirect(_ref) {
  let {
    payload
  } = _ref;
  return function* () {
    try {
      const {
        resourceId
      } = payload;
      const isCaseForCurrentIdentity = yield (0, _effects.call)(casesApi.checkCaseIdentity, {
        resourceId
      });
      if (isCaseForCurrentIdentity.otherIdentity) {
        const identityId = isCaseForCurrentIdentity.otherIdentity.id;
        yield (0, _effects.put)((0, _notificationsReducer.setNotificationWithRedirect)({
          resourceId,
          identityId
        }));
      } else {
        yield (0, _effects.put)((0, _notificationsReducer.redirectFromNotification)({
          resourceId
        }));
      }
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
    }
  }();
}
function* supportSaga() {
  yield (0, _effects.takeLatest)(_notificationsReducer.getAllNotificationsPollRequest, getAllNotificationsPoll);
  yield (0, _effects.takeLatest)(_notificationsReducer.getAllNotificationsRequest, getAllNotifications);
  yield (0, _effects.takeLatest)(_notificationsReducer.readNotificationsRequest, readNotifications);
  yield (0, _effects.takeLatest)(_notificationsReducer.tryToRedirectRequest, tryToRedirect);
}