/* eslint-disable camelcase */
import { requestHandler } from '@northstar/core-ui/utils';
const { assetDomain, productDomain } = requestHandler;

export const calculateQuotePROL = ({ importerId, requestBody }) =>
  assetDomain.CALCULATE_PR_OL_QUOTE.call({
    urlParams: { importerId },
    body: requestBody,
  });

export const calculateQuoteCMOL = ({ importerId, requestBody }) =>
  assetDomain.CALCULATE_CM_OL_QUOTE.call({
    urlParams: { importerId },
    body: requestBody,
  });

export const calculateQuoteCMFL = ({ requestBody, importerId }) =>
  assetDomain.CALCULATE_CM_FL_QUOTE.call({
    urlParams: { importerId },
    body: requestBody,
  });

export const getResidualValuesCMFL = ({ requestBody, importerId }) =>
  assetDomain.GET_RV_RANGE_CM_FL.call({
    urlParams: { importerId },
    body: requestBody,
  });

export const getInitialValuesCMFL = ({ importerId, vendorTermsId }) =>
  productDomain.GET_INIT_VALUES_CMFL.call({ urlParams: { importerId, vendorTermsId } });
