"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  container: {
    height: '100%',
    minHeight: 'calc(100vh - 114px)'
  },
  tableContainer: {
    marginTop: '10px',
    overflow: 'auto',
    paddingBottom: '6px'
  },
  headerCell: _ref => {
    let {
      colors
    } = _ref;
    return {
      '&.MuiTableCell-root': {
        background: colors.custom.background.background
      },
      '&.MuiTableCell-head': {
        color: colors.solid.cadedGray,
        fontWeight: 500
      }
    };
  },
  empty: _ref2 => {
    let {
      typography: {
        baseFont
      },
      fontSizes: {
        small
      },
      colors
    } = _ref2;
    return _objectSpread(_objectSpread({}, baseFont), {}, {
      fontSize: small,
      padding: '35px 20px',
      color: colors.custom.contentSecondary.color,
      margin: '92px auto 50px',
      boxShadow: "0px 1px 1px ".concat(colors.solid.lightBlueGray)
    });
  },
  emailNotVerified: _ref3 => {
    let {
      fontSizes: {
        tiny
      },
      colors
    } = _ref3;
    return {
      fontSize: tiny,
      color: colors.solid.red,
      marginLeft: 1
    };
  },
  roles: {
    width: 600,
    height: 0
  },
  toolbars: {
    display: 'flex',
    height: 120
  },
  row: {
    '&.MuiTableRow-root': {
      boxShadow: '0 4px 2px -2px rgba(102, 102, 102, 0.2)',
      '& > td': {
        padding: '12px'
      }
    }
  },
  tooltip: {
    maxWidth: 500
  }
};