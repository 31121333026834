"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initiateErrorThrower = void 0;
const initiateErrorThrower = () => {
  /* eslint-disable no-console */
  const warn = console.error;
  console.error = function thrower() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    if (process.env.NODE_ENV !== 'production') {
      const message = args[0].replace && args[0].replace('%s%s', '').replace('%s', args[1]) + args[2];
      if (/(Invalid prop|Failed prop type)/.test(message)) {
        console.log(message);
        throw new Error(message);
      }
    }
    // eslint-disable-next-line prefer-rest-params
    warn.apply(console, arguments);
  };
  /* eslint-enable no-console */
};
exports.initiateErrorThrower = initiateErrorThrower;