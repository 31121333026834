import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  closeButtonText: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
    .isRequired,
  isOpen: PropTypes.bool.isRequired,
  isSide: PropTypes.bool.isRequired,
  isWithAppBar: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  topOffset: PropTypes.number.isRequired,
} as any;
