import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  buttonText: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
    .isRequired,
  children: PropTypes.node,
  dialogTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
    .isRequired,
  initialDialogPage: PropTypes.oneOf(['FEEDBACK', 'THANK_YOU']),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSend: PropTypes.func.isRequired,
  textAreaPlaceholder: PropTypes.string,
  thankYouText: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
    .isRequired,
  thankYouTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
    .isRequired,
} as any;
