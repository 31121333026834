import { vendorConfig } from '@northstar/core-ui';
import { requestHandler } from '@northstar/core-ui/utils';
const { productDomain } = requestHandler;

export const getProductTermsPRHP = ({ countryCode = vendorConfig.DEFAULT_COUNTRY, importerId }) =>
  productDomain.GET_PRODUCT_TERMS_HP.call({
    urlParams: { countryCode, importerId },
  });

export const getProductTermsCMFL = ({ countryCode = vendorConfig.DEFAULT_COUNTRY, importerId }) =>
  productDomain.GET_PRODUCT_TERMS_CM_FL.call({
    urlParams: { countryCode, importerId },
  });
