import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  input: {
    marginTop: '13px',
  },
  field: ({ fieldSizes }) => ({
    maxWidth: `${fieldSizes.maxWidth}px !important`,
  }),
};
