import { ISxStyles } from '@asfi/maf';

export const styles: ISxStyles = {
  container: ({ colors: { solid } }) => ({
    backgroundColor: solid.white,
    border: `1px solid ${solid.gainsboro}`,
  }),
  footerContainer: {
    '@media print': {
      display: 'none',
    },
  },
  footer: ({ colors: { solid } }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: solid.white,
    alignItems: 'center',
    padding: 3,
  }),
  lineDashed: ({ colors: { solid } }) => ({
    borderTop: `1px dashed ${solid.gainsboro}`,
  }),
};
