const Filter: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="19px"
    height="20px"
    viewBox="0 0 19 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs></defs>
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-HD" transform="translate(-1230.000000, -501.000000)">
        <g id="Group" transform="translate(1227.000000, 499.000000)">
          <path
            d="M20.6302244,2 C21.0720522,2 21.4302244,2.3581722 21.4302244,2.8 C21.4302244,2.99837917 21.3565176,3.1896826 21.2234108,3.33677737 L13.8907817,11.4399795 L13.8907817,21.2 C13.8907817,21.6418278 13.5326095,22 13.0907817,22 L11.7753532,22 C11.3335254,22 10.9753532,21.6418278 10.9753532,21.2 L10.9753532,11.4399795 L3.64272412,3.33677737 C3.34627016,3.00916959 3.37152533,2.5032676 3.69913311,2.20681364 C3.84622788,2.07370683 4.0375313,2 4.23591048,2 L20.6302244,2 Z M19.9256388,3.05263158 L4.9404961,3.05263158 L11.9471627,10.9753832 L11.9471627,20.9473684 L12.9189722,20.9473684 L12.9189722,10.9753832 L19.9256388,3.05263158 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Filter;
