import { AuthUtils } from '@northstar/core';
import { VENDOR_DASHBOARD_PATH } from '@northstar/core-ui/screens/vendorDashboard/route';

const paths = {
  ASSETS_PR_OL: {
    path: '/case/:resourceId/private/operational-lease/assets',
    exact: true,
    buildPath: ({ resourceId }) => `/case/${resourceId}/private/operational-lease/assets`,
    rules: [
      AuthUtils.allowVendorSalesPerson,
      AuthUtils.allowVendorAdministratorForStandaloneAndDealerships,
    ],
  },
  ASSETS_CM_OL: {
    path: '/case/:resourceId/commercial/operational-lease/assets',
    exact: true,
    buildPath: ({ resourceId }) => `/case/${resourceId}/commercial/operational-lease/assets`,
    rules: [
      AuthUtils.allowVendorSalesPerson,
      AuthUtils.allowVendorAdministratorForStandaloneAndDealerships,
    ],
  },
  CREATE_PASSWORD: {
    path: '/register',
    exact: true,
    rules: [AuthUtils.allowAny],
  },
  VENDOR_CREATE_USER: {
    path: '/vendor/assets/create-user',
    exact: true,
    rules: [AuthUtils.allowVendorAdministrator],
  },
  VENDOR_CREATE_ASSET: {
    path: '/vendor/assets/create',
    exact: true,
    rules: [AuthUtils.allowVendorAdministrator],
  },
  VENDOR_DASHBOARD: {
    path: '/vendor/:tab/:assetId?/:assetVersionId?',
    exact: true,
    buildPath: ({ tab, assetId, assetVersionId }) =>
      `/vendor/${tab}${assetId ? `/${assetId}` : ''}${assetVersionId ? `/${assetVersionId}` : ''}`,
    buildSearch: VENDOR_DASHBOARD_PATH.buildSearch,
    rules: [AuthUtils.allowVendorAdministrator],
    tabs: {
      terms: VENDOR_DASHBOARD_PATH.tabs.terms,
      assets: VENDOR_DASHBOARD_PATH.tabs.assets,
      serviceRates: VENDOR_DASHBOARD_PATH.tabs.serviceRates,
      insuranceRates: VENDOR_DASHBOARD_PATH.tabs.insuranceRates,
      winterTiresRates: VENDOR_DASHBOARD_PATH.tabs.winterTiresRates,
      deletedAssets: VENDOR_DASHBOARD_PATH.tabs.deletedAssets,
      users: VENDOR_DASHBOARD_PATH.tabs.users,
    },
  },
  CASE: {
    path: '/case/:resourceId/details',
    exact: true,
    buildPath: ({ resourceId }) => `/case/${resourceId}/details`,
    rules: [
      AuthUtils.allowVendorSalesPerson,
      AuthUtils.allowVendorAdministratorForStandaloneAndDealerships,
    ],
  },
  BASE: {
    path: '/',
    exact: true,
  },
  VENDOR_AUTH: {
    path: '/login',
    exact: true,
  },
  ASHE_BACK: {
    path: '/login/oauth2/code/ashe',
    exact: true,
  },
  ONBOARD_SELF: {
    path: '/onboard-self',
    exact: true,
  },
  LANDING: {
    path: '/home',
    rules: [
      AuthUtils.allowVendorSalesPerson,
      AuthUtils.allowVendorAdministratorForStandaloneAndDealerships,
    ],
  },
  CASES: {
    path: '/case-overview',
    buildPath: () => `/cases-overview`,
    rules: [
      AuthUtils.allowVendorSalesPerson,
      AuthUtils.allowVendorAdministratorForStandaloneAndDealerships,
    ],
  },
  CAR_CONFIGURATION_PR_OL: {
    path: '/case/:resourceId/:draftScenarioId?/private/operational-lease/configuration',
    exact: true,
    buildPath: ({ resourceId, draftScenarioId }) =>
      `/case/${resourceId}${
        draftScenarioId ? `/${draftScenarioId}` : ''
      }/private/operational-lease/configuration`,
    rules: [
      AuthUtils.allowVendorSalesPerson,
      AuthUtils.allowVendorAdministratorForStandaloneAndDealerships,
    ],
  },
  CAR_CONFIGURATION_PR_HP: {
    path: '/case/:resourceId/:draftScenarioId?/private/hire-purchase/configuration',
    exact: true,
    buildPath: ({ resourceId, draftScenarioId }) =>
      `/case/${resourceId}${
        draftScenarioId ? `/${draftScenarioId}` : ''
      }/private/hire-purchase/configuration`,
    rules: [
      AuthUtils.allowVendorSalesPerson,
      AuthUtils.allowVendorAdministratorForStandaloneAndDealerships,
    ],
  },
  CAR_CONFIGURATION_CM_FL: {
    path: '/case/:resourceId/commercial/financial-leasing/configuration',
    exact: true,
    buildPath: ({ resourceId }) => `/case/${resourceId}/commercial/financial-leasing/configuration`,
    rules: [
      AuthUtils.allowVendorSalesPerson,
      AuthUtils.allowVendorAdministratorForStandaloneAndDealerships,
    ],
  },
  CAR_CONFIGURATION_CM_OL: {
    path: '/case/:resourceId/commercial/operational-lease/configuration',
    exact: true,
    buildPath: ({ resourceId }) => `/case/${resourceId}/commercial/operational-lease/configuration`,
    rules: [
      AuthUtils.allowVendorSalesPerson,
      AuthUtils.allowVendorAdministratorForStandaloneAndDealerships,
    ],
  },
  CREDIT_PR_OL: {
    path: '/case/:resourceId/:draftScenarioId?/private/operational-lease/credit',
    exact: true,
    buildPath: ({ resourceId, draftScenarioId }) =>
      `/case/${resourceId}${
        draftScenarioId ? `/${draftScenarioId}` : ''
      }/private/operational-lease/credit`,
    rules: [
      AuthUtils.allowVendorSalesPerson,
      AuthUtils.allowVendorAdministratorForStandaloneAndDealerships,
    ],
  },
  CREDIT_PR_HP: {
    path: '/case/:resourceId/:draftScenarioId?/private/hire-purchase/credit',
    exact: true,
    buildPath: ({ resourceId, draftScenarioId }) =>
      `/case/${resourceId}${
        draftScenarioId ? `/${draftScenarioId}` : ''
      }/private/hire-purchase/credit`,
    rules: [
      AuthUtils.allowVendorSalesPerson,
      AuthUtils.allowVendorAdministratorForStandaloneAndDealerships,
    ],
  },
  CREDIT_CM_OL: {
    path: '/case/:resourceId/commercial/operational-lease/credit',
    exact: true,
    buildPath: ({ resourceId, legalEntity }) =>
      `/case/${resourceId}/commercial/operational-lease/credit${
        legalEntity ? `?legalEntity=${legalEntity}` : ''
      }`,
    rules: [
      AuthUtils.allowVendorSalesPerson,
      AuthUtils.allowVendorAdministratorForStandaloneAndDealerships,
    ],
  },
  CREDIT_CM_FL: {
    path: '/case/:resourceId/commercial/financial-lease/credit',
    exact: true,
    buildPath: ({ resourceId, legalEntity }) =>
      `/case/${resourceId}/commercial/financial-lease/credit${
        legalEntity ? `?legalEntity=${legalEntity}` : ''
      }`,
    rules: [
      AuthUtils.allowVendorSalesPerson,
      AuthUtils.allowVendorAdministratorForStandaloneAndDealerships,
    ],
  },
  ADVISORY: {
    path: '/advisory/:tab',
    exact: true,
    buildPath: ({ tab }) => `/advisory/${tab}`,
    rules: [
      AuthUtils.allowVendorSalesPerson,
      AuthUtils.allowVendorAdministratorForStandaloneAndDealerships,
    ],
    tabs: {
      privateLeasingVsHirePurchase: 'private-leasing-vs-hire-purchase',
      salesOverview: 'sales-overview',
      termsAndConditions: 'terms-and-conditions',
    },
  },
  CAR_SELECTION_PR_HP: {
    path: '/case/:resourceId/car-selection',
    exact: true,
    buildPath: ({ resourceId }) => `/case/${resourceId}/car-selection`,
    rules: [
      AuthUtils.allowVendorSalesPerson,
      AuthUtils.allowVendorAdministratorForStandaloneAndDealerships,
    ],
    tabs: {
      manualCarSelection: 'manual-car-selection',
      searchByReg: 'car-search-by-reg-nr',
    },
  },
  KYC: {
    path: '/:tokenId/kyc/:organisationNumber?',
    exact: true,
    buildPath: ({ organisationNumber, tokenId }) =>
      `${tokenId}/kyc${organisationNumber ? `/${organisationNumber}` : ''}`,
    rules: [AuthUtils.allowAny],
  },
  QUOTE_DOCUMENT: {
    path: '/quote/:hashId',
    exact: true,
    buildPath: ({ hashId }) => `/quote/${hashId}`,
    rules: [AuthUtils.allowAny],
  },
  FORBIDDEN: {
    path: '/forbidden',
    exact: true,
    // rules: [
    //   AuthUtils.allowNonAnonymous,
    // ],
  },
  NOT_FOUND: {
    path: '*',
    strictPath: '/not-found',
    exact: true,
    rules: [AuthUtils.allowNonAnonymous],
  },
  MAINTENANCE: {
    path: '*',
    exact: true,
    rules: [AuthUtils.allowAny],
  },
  SERVICE_UNAVAILABLE: {
    path: '*',
    exact: true,
    rules: [AuthUtils.allowAny],
  },
  LOADING_OVERLAY: {
    path: '/loading',
    exact: true,
    rules: [AuthUtils.allowAny],
  },
};

export default paths;
