import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  container: {
    margin: '15px 0',
  },
  options: {
    display: 'flex',
    justifyContent: 'center',
  },
  label: ({ colors, typography: { baseFont }, fontSizes: { small } }) => ({
    ...baseFont,
    fontSize: small,
    marginBottom: '20px',
    color: colors.custom.fontPrimary,
  }),
  button: ({ breakpoints, fontSizes: { extraSmall } }) => ({
    '&.MuiButton-root': {
      margin: '0 5px',
      maxWidth: 200,
      fontSize: extraSmall,
      [breakpoints.only('xs')]: {
        padding: 0,
        minWidth: 125,
      },
    },
  }),
};
