/* eslint-disable  */
import { NSLoadingOverlay, NSLoadingOverlayDanskeBank } from '@northstar/core-ui/components';
import { AuthProvider } from '@northstar/core-ui/providers';
import { ConnectedRouter as Router } from 'connected-react-router';
import { lazy, Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ErrorBoundary from '@northstar/core-ui/screens/ErrorBoundary';
import { selectCookieConsent } from '@northstar/core-ui/modules/app/appSelectors';
import { track } from '@asfi/maf';
import { matchPath } from 'react-router';

import Paths from 'paths';
import { removeParamsFromPath } from 'utils/domain-utils';
import { defaultAdobeAnalyticsPayload } from 'utils/constants';

import { history } from './store';

/**
 * Available pages
 */
// Authprovider HOC
// The loading component for the suspense fallback
// Lazy loaded components for code splitting
const VendorDashoard = lazy(() => import('screens/VendorDashboard'));
const Onboard = lazy(() => import('screens/Onboard'));
const CaseOverview = lazy(() => import('screens/CaseOverview'));
const CaseDetailSwitch = lazy(() => import('screens/CaseDetailSwitch'));
const AssetListPROL = lazy(() => import('screens/AssetListPROL'));
const AssetListCMOL = lazy(() => import('screens/AssetListCMOL'));
const CarConfigurationPROL = lazy(() => import('screens/CarConfigurationPROL'));
const CarConfigurationCMOL = lazy(() => import('screens/CarConfigurationCMOL'));
const CarConfigurationCMFL = lazy(() => import('screens/CarConfigurationCMFL'));
const CarConfigurationPRHP = lazy(() => import('screens/CarConfigurationPRHP'));
const Advisory = lazy(() => import('screens/Advisory'));
const Landing = lazy(() => import('screens/Landing'));
const CreditPROL = lazy(() => import('screens/CreditPROL'));
const CreditPRHP = lazy(() => import('screens/CreditPRHP'));
const CreditCMOL = lazy(() => import('screens/CreditCMOL'));
const CreditCMFL = lazy(() => import('screens/CreditCMFL'));
const CarSelection = lazy(() => import('screens/CarSelection'));
const Kyc = lazy(() => import('screens/KycForm'));
const Login = lazy(() => import('screens/Login'));
const OnboardSelf = lazy(() => import('screens/OnboardSelf'));
const PageNotFound = lazy(() =>
  import('@northstar/core-ui/screens').then((module) => ({ default: module.PageNotFound })),
);
const Maintenance = lazy(() =>
  import('@northstar/core-ui/screens').then((module) => ({ default: module.Maintenance })),
);
const Forbidden = lazy(() =>
  import('@northstar/core-ui/screens').then((module) => ({ default: module.Forbidden })),
);
const ServiceUnavailable = lazy(() =>
  import('@northstar/core-ui/screens').then((module) => ({ default: module.ServiceUnavailable })),
);
const QuoteDocument = lazy(() =>
  import('@northstar/core-ui/screens').then((module) => ({ default: module.QuoteDocument })),
);

const LoadingOverlay = lazy(() =>
  import('@northstar/core-ui/components').then((module) => ({ default: module.NSLoadingOverlay })),
);

export const RouteComponents = {
  ASSETS_PR_OL: {
    component: AssetListPROL,
    exact: true,
    redirect: ({ resourceId }) => history.push(Paths.ASSETS_PR_OL.buildPath({ resourceId })),
    ...Paths.ASSETS_PR_OL,
  },
  ASSETS_CM_OL: {
    component: AssetListCMOL,
    exact: true,
    redirect: ({ resourceId }) => history.push(Paths.ASSETS_CM_OL.buildPath({ resourceId })),
    ...Paths.ASSETS_CM_OL,
  },
  CREATE_PASSWORD: {
    component: Onboard,
    exact: true,
    redirect: () => history.push(Paths.CREATE_PASSWORD.path),
    ...Paths.CREATE_PASSWORD,
  },
  VENDOR_DASHBOARD: {
    component: VendorDashoard,
    exact: true,
    redirect: ({ vendorId, tab, assetId, assetVersionId, isCommercial }) =>
      history.push({
        pathname: Paths.VENDOR_DASHBOARD.buildPath({
          vendorId,
          tab,
          assetId,
          assetVersionId,
        }),
        search: Paths.VENDOR_DASHBOARD.buildSearch({ isCommercial }),
      }),
    ...Paths.VENDOR_DASHBOARD,
  },
  CASE_DETAIL: {
    component: CaseDetailSwitch,
    exact: true,
    redirect: ({ resourceId }) => history.push(Paths.CASE.buildPath({ resourceId })),
    ...Paths.CASE,
  },
  BASE: {
    exact: true,
    component: Login,
    path: '/',
  },
  VENDOR_AUTH: {
    exact: true,
    component: Login,
    redirect: () => history.push(Paths.VENDOR_AUTH.path),
    ...Paths.VENDOR_AUTH,
  },
  ASHE_BACK: {
    exact: true,
    component: Login,
    redirect: () => history.push(Paths.ASHE_BACK.path),
    ...Paths.ASHE_BACK,
  },
  ONBOARD_SELF: {
    exact: true,
    component: OnboardSelf,
    redirect: () => history.push(Paths.ONBOARD_SELF.path),
    ...Paths.ONBOARD_SELF,
  },
  LANDING: {
    component: Landing,
    redirect: () => history.push(Paths.LANDING.path),
    ...Paths.LANDING,
  },
  CASES: {
    component: CaseOverview,
    redirect: () => history.push(Paths.CASES.path),
    ...Paths.CASES,
  },
  CAR_CONFIGURATION_PR_OL: {
    exact: true,
    component: CarConfigurationPROL,
    redirect: ({ resourceId, draftScenarioId }) =>
      history.push(Paths.CAR_CONFIGURATION_PR_OL.buildPath({ resourceId, draftScenarioId })),
    ...Paths.CAR_CONFIGURATION_PR_OL,
  },
  CAR_CONFIGURATION_PR_HP: {
    exact: true,
    component: CarConfigurationPRHP,
    redirect: ({ resourceId, draftScenarioId, queryParams }) =>
      history.push({
        pathname: Paths.CAR_CONFIGURATION_PR_HP.buildPath({ resourceId, draftScenarioId }),
        search: queryParams,
      }),
    ...Paths.CAR_CONFIGURATION_PR_HP,
  },
  CAR_CONFIGURATION_CM_FL: {
    exact: true,
    component: CarConfigurationCMFL,
    redirect: ({ resourceId, queryParams }) =>
      history.push({
        pathname: Paths.CAR_CONFIGURATION_CM_FL.buildPath({ resourceId }),
        search: queryParams,
      }),
    ...Paths.CAR_CONFIGURATION_CM_FL,
  },
  CAR_CONFIGURATION_CM_OL: {
    exact: true,
    component: CarConfigurationCMOL,
    redirect: ({ resourceId }) =>
      history.push(Paths.CAR_CONFIGURATION_CM_OL.buildPath({ resourceId })),
    ...Paths.CAR_CONFIGURATION_CM_OL,
  },
  CREDIT_PR_OL: {
    exact: true,
    component: CreditPROL,
    redirect: ({ resourceId, draftScenarioId }) =>
      history.push(Paths.CREDIT_PR_OL.buildPath({ resourceId, draftScenarioId })),
    ...Paths.CREDIT_PR_OL,
  },
  CREDIT_PR_HP: {
    exact: true,
    component: CreditPRHP,
    redirect: ({ resourceId, draftScenarioId }) =>
      history.push(Paths.CREDIT_PR_HP.buildPath({ resourceId, draftScenarioId })),
    ...Paths.CREDIT_PR_HP,
  },
  CREDIT_CM_OL: {
    exact: true,
    component: CreditCMOL,
    redirect: ({ resourceId, legalEntity }) =>
      history.push(Paths.CREDIT_CM_OL.buildPath({ resourceId, legalEntity })),
    ...Paths.CREDIT_CM_OL,
  },
  CREDIT_CM_FL: {
    exact: true,
    component: CreditCMFL,
    redirect: ({ resourceId, legalEntity }) =>
      history.push(Paths.CREDIT_CM_FL.buildPath({ resourceId, legalEntity })),
    ...Paths.CREDIT_CM_FL,
  },
  ADVISORY: {
    exact: true,
    component: Advisory,
    redirect: ({ tab }) => history.push(Paths.ADVISORY.buildPath({ tab })),
    ...Paths.ADVISORY,
  },
  CAR_SELECTION_PR_HP: {
    exact: true,
    component: CarSelection,
    redirect: ({ resourceId }) => history.push(Paths.CAR_SELECTION_PR_HP.buildPath({ resourceId })),
    ...Paths.CAR_SELECTION_PR_HP,
  },
  KYC: {
    exact: true,
    component: Kyc,
    redirect: ({ orgnizationNumber, tokenId }) =>
      history.push(Paths.KYC.buildPath({ orgnizationNumber, tokenId })),
    ...Paths.KYC,
  },
  QUOTE_DOCUMENT: {
    exact: true,
    component: QuoteDocument,
    redirect: ({ hashId }) => history.push(Paths.QUOTE_DOCUMENT.buildPath({ hashId })),
    ...Paths.QUOTE_DOCUMENT,
  },
  LOADING_OVERLAY: {
    component: LoadingOverlay,
    exact: true,
    redirect: () => history.push(Paths.LOADING_OVERLAY.path),
    ...Paths.LOADING_OVERLAY,
  },
  FORBIDDEN: {
    component: Forbidden,
    redirect: () => history.push(Paths.FORBIDDEN.path),
    ...Paths.FORBIDDEN,
  },
  NOT_FOUND: {
    component: PageNotFound,
    redirect: () => history.push(Paths.NOT_FOUND.strictPath),
    ...Paths.NOT_FOUND,
  },
  MAINTENANCE: {
    component: Maintenance,
    ...Paths.MAINTENANCE,
  },
  SERVICE_UNAVAILABLE: {
    component: ServiceUnavailable,
    ...Paths.SERVICE_UNAVAILABLE,
  },
};

// eslint-disable-next-line no-undef,no-unneeded-ternary
const isMaintenanceMode = typeof MAINTENANCE_MODE !== 'undefined' ? MAINTENANCE_MODE : false;

const ComponentToRender = ({ component: Component, ...props }) => {
  const {
    match: {
      params: { tab },
      path,
    },
  } = props;
  const cookieConsent = useSelector(selectCookieConsent());
  useEffect(() => {
    const pageName = removeParamsFromPath({ path, tab });
    track({ 'page.name': pageName }, defaultAdobeAnalyticsPayload);
  }, [tab, cookieConsent]);
  return <Component {...props} />;
};

const ComponentsToRender = ({ isServiceUnavailable }) => {
  if (isMaintenanceMode) {
    return <Route {...RouteComponents.MAINTENANCE} />;
  } else if (isServiceUnavailable) {
    return <Route {...RouteComponents.SERVICE_UNAVAILABLE} />;
  }
  return (
    <Route
      render={({ location }) => (
        <AuthProvider location={location} paths={Paths}>
          <Switch>
            {Object.keys(RouteComponents).map((key) => {
              const { component, path, ...rest } = RouteComponents[key];
              return (
                <Route
                  key={key}
                  render={(props) => <ComponentToRender component={component} {...props} />}
                  path={path}
                  {...rest}
                />
              );
            })}
          </Switch>
        </AuthProvider>
      )}
    />
  );
};

const routes = (props) => {
  const matchQuoteDocumentPath = matchPath(history.location.pathname, Paths.QUOTE_DOCUMENT);
  return (
    <Router history={history} {...history}>
      <ErrorBoundary>
        <Suspense
          fallback={matchQuoteDocumentPath ? <NSLoadingOverlayDanskeBank /> : <NSLoadingOverlay />}
        >
          <ComponentsToRender {...props} />
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
};

export default routes;
