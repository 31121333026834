"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.responseMappers = exports.formMappers = exports.flatEquipmentBoolean = exports.flatEquipment = exports.divideIntoCategories = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _numeral = _interopRequireDefault(require("numeral"));
var _assetConstants = require("../assetConstants");
var _equipmentConstants = require("../equipmentConstants");
var _vendorDashboardConstants = require("../vendorDashboard/vendorDashboardConstants");
var objectUtils = _interopRequireWildcard(require("../../utils/object-utils"));
var stringUtils = _interopRequireWildcard(require("../../utils/string-utils"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const parseFormKeyToId = key => key.replace(/[^0-9]/g, '');
const categories = {
  retrofit: _equipmentConstants.EQUIPMENT_CATEGORY_RETROFIT,
  colors: _equipmentConstants.EQUIPMENT_CATEGORY_COLOUR,
  additional: _equipmentConstants.EQUIPMENT_CATEGORY_ADDITIONAL,
  preconfigured: _equipmentConstants.EQUIPMENT_CATEGORY_PRECONFIGURED,
  other: 'OTHER'
};
const divideIntoCategories = eq => Object.entries(eq).reduce((prev, _ref) => {
  let [key, value] = _ref;
  const category = Object.keys(categories).find(cat => key.includes(cat));
  if (!category) return _objectSpread(_objectSpread({}, prev), {}, {
    [categories.other]: _objectSpread(_objectSpread({}, prev[categories.other]), {}, {
      [key]: value
    })
  });
  const categoryValue = categories[category];
  const prevCategoryValue = prev[categoryValue] || {};
  return _objectSpread(_objectSpread({}, prev), {}, {
    [categoryValue]: _objectSpread(_objectSpread({}, prevCategoryValue), {}, {
      [parseFormKeyToId(key)]: value
    })
  });
}, {});
exports.divideIntoCategories = divideIntoCategories;
const mapEquipmentFormToRequest = eq => {
  // Mapping logic is:
  // equipment not from category list (like winterTiresLevel, privatePrice...) -> mappedEq (as a new category)
  // all colours -> mappedEq.optional_equipment
  // all additional and not preconfigured -> mappedEq.optional_equipment, other preconfigured additional -> mappedEq.preconfigured_equipment
  // retrofit is ignored / skipped in this mapper

  const mappedEq = {
    optional_equipment: [],
    preconfigured_equipment: []
  };
  const divided = divideIntoCategories(eq);
  Object.entries(divided).forEach(_ref2 => {
    let [key, value] = _ref2;
    if (key.includes(categories.other)) {
      Object.entries(value).forEach(_ref3 => {
        let [id, val] = _ref3;
        mappedEq[id] = val;
      });
    } else if (value || value === 0) {
      if (key.includes(_equipmentConstants.EQUIPMENT_CATEGORY_COLOUR)) {
        Object.entries(value).forEach(_ref4 => {
          let [id, val] = _ref4;
          if (val || val === 0) {
            mappedEq.optional_equipment.push({
              id: Number(id),
              price_ex_vat: (0, _numeral.default)(val).value()
            });
          }
        });
      } else if (key.includes(_equipmentConstants.EQUIPMENT_CATEGORY_ADDITIONAL)) {
        Object.entries(value).forEach(_ref5 => {
          var _divided$EQUIPMENT_CA;
          let [id, val] = _ref5;
          const isPreconfigured = Boolean((_divided$EQUIPMENT_CA = divided[_equipmentConstants.EQUIPMENT_CATEGORY_PRECONFIGURED]) === null || _divided$EQUIPMENT_CA === void 0 ? void 0 : _divided$EQUIPMENT_CA[id]);
          if (!isPreconfigured && (val || val === 0)) {
            mappedEq.optional_equipment.push({
              id: Number(id),
              price_ex_vat: (0, _numeral.default)(val).value()
            });
          } else if (isPreconfigured && (val || val === 0)) {
            mappedEq.preconfigured_equipment.push({
              id: Number(id),
              price_ex_vat: (0, _numeral.default)(val).value()
            });
          }
        });
      }
    }
  });
  return mappedEq;
};
const flatEquipment = _ref6 => {
  let {
    prefix,
    equipment,
    enabled,
    fullyEditable
  } = _ref6;
  if (!equipment || !Array.isArray(equipment)) return {};
  const reducer = (prev, _ref7) => {
    let {
      id,
      equipmentPrice: price,
      name
    } = _ref7;
    const value = fullyEditable ? {
      price,
      name
    } : price;
    return _objectSpread(_objectSpread({}, prev), {}, {
      ["".concat(prefix, "-").concat(id)]: value,
      enabled
    });
  };
  return equipment.reduce(reducer, {});
};
exports.flatEquipment = flatEquipment;
const flatEquipmentBoolean = _ref8 => {
  let {
    prefix,
    equipment,
    enabled
  } = _ref8;
  if (!equipment || !Array.isArray(equipment)) return {};
  const reducer = (prev, _ref9) => {
    let {
      id
    } = _ref9;
    return _objectSpread(_objectSpread({}, prev), {}, {
      ["".concat(prefix, "-").concat(id)]: true,
      enabled
    });
  };
  return equipment.reduce(reducer, {});
};
exports.flatEquipmentBoolean = flatEquipmentBoolean;
const mapAssetAndEquipmenToEquipmentForm = _ref10 => {
  let {
    asset,
    equipment: {
      colors,
      additional,
      preconfigured,
      retrofit
    },
    enabled = true
  } = _ref10;
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
    privatePrice: asset.privatePrice,
    commercialPrice: asset.commercialPrice,
    [_assetConstants.ASSET_FORM_PRIVATE_LEASE_SWITCH]: Boolean(asset.privatePrice),
    [_assetConstants.ASSET_FORM_COMMERCIAL_OPERATIONAL_LEASE_SWITCH]: Boolean(asset.commercialPrice),
    serviceLevel: asset.serviceLevel,
    insurance: asset.insurance || stringUtils.EMPTY_VALUE,
    privateVendorTermId: asset.privateVendorTermsIds[0],
    commercialVendorTermId: asset.commercialVendorTermsIds[0],
    winterTiresLevel: asset.winterTires || stringUtils.EMPTY_VALUE,
    climateBonusValue: asset.climateBonusValue
  }, flatEquipment({
    prefix: _equipmentConstants.EQUIPMENT_FORM_COLORS,
    equipment: colors,
    enabled
  })), flatEquipment({
    prefix: _equipmentConstants.EQUIPMENT_FORM_ADDITIONAL,
    equipment: additional,
    enabled
  })), flatEquipment({
    prefix: _equipmentConstants.EQUIPMENT_FORM_ADDITIONAL,
    equipment: preconfigured,
    enabled
  })), flatEquipmentBoolean({
    prefix: _equipmentConstants.EQUIPMENT_FORM_PRECONFIGURED,
    equipment: preconfigured,
    enabled
  })), flatEquipment({
    prefix: _equipmentConstants.EQUIPMENT_FORM_RETROFIT,
    equipment: retrofit,
    enabled,
    fullyEditable: true
  }));
};
const mapAssetToStore = (assetObj, isAssetPrivate) => {
  var _asset$privatePrice, _asset$commercialPric, _asset$climateBonusVa, _asset$commission, _asset$quote, _asset$quote2, _asset$residualValue, _asset$defaultDownPay;
  const asset = objectUtils.objectToCamelCase({
    obj: assetObj,
    override: {
      asset_state: 'state',
      private_price: 'privatePrice',
      commercial_price: 'commercialPrice',
      climate_bonus_value: 'climateBonusValue',
      commission: 'commission',
      residual_value: 'residualValue',
      default_down_payment: 'defaultDownPayment'
    }
  });
  return _objectSpread(_objectSpread({}, asset), {}, {
    privatePrice: (_asset$privatePrice = asset.privatePrice) === null || _asset$privatePrice === void 0 ? void 0 : _asset$privatePrice.exVat,
    commercialPrice: (_asset$commercialPric = asset.commercialPrice) === null || _asset$commercialPric === void 0 ? void 0 : _asset$commercialPric.exVat,
    climateBonusValue: (_asset$climateBonusVa = asset.climateBonusValue) === null || _asset$climateBonusVa === void 0 ? void 0 : _asset$climateBonusVa.exVat,
    commission: (_asset$commission = asset.commission) === null || _asset$commission === void 0 ? void 0 : _asset$commission.exVat,
    quote: isAssetPrivate ? (_asset$quote = asset.quote) === null || _asset$quote === void 0 ? void 0 : _asset$quote.inVat : (_asset$quote2 = asset.quote) === null || _asset$quote2 === void 0 ? void 0 : _asset$quote2.exVat,
    residualValue: (_asset$residualValue = asset.residualValue) === null || _asset$residualValue === void 0 ? void 0 : _asset$residualValue.exVat,
    defaultDownPayment: (_asset$defaultDownPay = asset.defaultDownPayment) === null || _asset$defaultDownPay === void 0 ? void 0 : _asset$defaultDownPay.exVat
  });
};
const mapAssetCarInfoToStore = info => objectUtils.objectToCamelCase({
  obj: info,
  override: {
    car_name: 'popName',
    drive_type: 'drivingWheel',
    car_status: 'state'
  }
});
const mapAssetFieldErrorNames = _ref11 => {
  let {
    fieldErrors
  } = _ref11;
  const nameMap = {
    'assetPrice.privatePriceExVat': _vendorDashboardConstants.ASSET_FIELD_PRIVATE_PRICE,
    'assetPrice.commercialPriceExVat': _vendorDashboardConstants.ASSET_FIELD_COMMERCIAL_PRICE,
    'assetPrice.climateBonusValueExVat': _vendorDashboardConstants.ASSET_FIELD_CLIMATE_BONUS
  };
  return fieldErrors.map(error => _objectSpread(_objectSpread({}, error), {}, {
    fieldName: nameMap[error.fieldName] || error.fieldName
  }));
};
const responseMappers = exports.responseMappers = {
  mapAsset: mapAssetToStore,
  mapAssetCarInfo: mapAssetCarInfoToStore,
  mapFieldErrorNames: mapAssetFieldErrorNames
};
const formMappers = exports.formMappers = {
  mapEquipmentToRequest: mapEquipmentFormToRequest,
  mapAssetAndEquipment: mapAssetAndEquipmenToEquipmentForm
};
var _default = exports.default = {
  responseMappers,
  formMappers
};