import { FC, ReactChild, ReactNode } from 'react';
import { Box } from '@mui/material';

import MAFTypography from '../MAFTypography/MAFTypography';
import MAFTooltip from '../MAFTooltip/MAFTooltip';

import { styles } from './MAFHighLightItem.styles';
import { propTypes } from './MAFHighLightItem.propTypes';

const PRIMARY = 'primary';

export type TMAFHighlightItem = {
  label?: ReactNode;
  text: ReactChild;
};

export interface IMAFHighLightItemProps {
  item: TMAFHighlightItem;
  variant?: 'primary' | 'secondary';
  isNumber?: boolean;
  isWithEllipsis?: boolean;
}

const MAFHighLightItem: FC<IMAFHighLightItemProps> = ({
  item,
  variant,
  isNumber = false,
  isWithEllipsis = false,
}) => {
  const isNotPrimaryVariant = variant !== PRIMARY;

  const titleModifier = [styles.title, isNotPrimaryVariant && styles.titleSecondary];

  const valueModifier = [
    styles.value,
    isWithEllipsis && styles.withEllipsis,
    isNotPrimaryVariant && styles.valueSecondary,
    isNumber && styles.valueNumber,
  ];

  const textField = <Box sx={valueModifier}>{item.text}</Box>;

  return (
    <Box sx={styles.wrapper}>
      <MAFTypography variant="body2" sx={titleModifier}>
        {item.label}
      </MAFTypography>
      {isWithEllipsis ? (
        <MAFTooltip title={item.text} placement="bottom-start">
          {textField}
        </MAFTooltip>
      ) : (
        textField
      )}
    </Box>
  );
};

MAFHighLightItem.propTypes = propTypes;

export default MAFHighLightItem;
