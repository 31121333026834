const AgreementExpired: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="38px"
    height="26px"
    viewBox="0 0 38 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="07-Case-detail-all-statuses-assets"
        transform="translate(-117.000000, -545.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="agreement_expired" transform="translate(119.000000, 547.000000)">
          <g id="Group-31">
            <path
              d="M35.27575,6.41666667 L35.27575,19.25 C35.27575,21.4757409 33.4211951,23.27575 31.1666667,23.27575 L6.61111111,23.27575 C4.47507744,23.27575 2.69807816,21.6599803 2.51718567,19.5972176 C0.408008848,19.4383606 -1.27575,17.7041088 -1.27575,15.5833333 L-1.27575,2.75 C-1.27575,0.524259145 0.578804877,-1.27575 2.83333333,-1.27575 L27.3888889,-1.27575 C29.5249226,-1.27575 31.3019218,0.340019693 31.4828143,2.40278241 C33.5919912,2.56163942 35.27575,4.29589116 35.27575,6.41666667 Z M31.1666667,4.94241667 L28.9464722,4.94241667 L28.9464722,2.75 C28.9464722,1.95074085 28.2510271,1.27575 27.3888889,1.27575 L2.83333333,1.27575 C1.97119512,1.27575 1.27575,1.95074085 1.27575,2.75 L1.27575,15.5833333 C1.27575,16.3825925 1.97119512,17.0575833 2.83333333,17.0575833 L5.05352778,17.0575833 L5.05352778,19.25 C5.05352778,20.0492591 5.7489729,20.72425 6.61111111,20.72425 L31.1666667,20.72425 C32.0288049,20.72425 32.72425,20.0492591 32.72425,19.25 L32.72425,6.41666667 C32.72425,5.61740752 32.0288049,4.94241667 31.1666667,4.94241667 Z"
              id="Stroke-1"
            ></path>
            <path
              d="M5.27575,6.7 L5.27575,19.3 C5.27575,20.0685084 5.95881417,20.72425 6.8125,20.72425 L31.1875,20.72425 C32.0411858,20.72425 32.72425,20.0685084 32.72425,19.3 L32.72425,6.7 C32.72425,5.93149161 32.0411858,5.27575 31.1875,5.27575 L6.8125,5.27575 C5.95881417,5.27575 5.27575,5.93149161 5.27575,6.7 Z M2.72425,6.7 C2.72425,4.49850839 4.57243583,2.72425 6.8125,2.72425 L31.1875,2.72425 C33.4275642,2.72425 35.27575,4.49850839 35.27575,6.7 L35.27575,19.3 C35.27575,21.5014916 33.4275642,23.27575 31.1875,23.27575 L6.8125,23.27575 C4.57243583,23.27575 2.72425,21.5014916 2.72425,19.3 L2.72425,6.7 Z"
              id="Stroke-3"
            ></path>
            <path
              d="M28.6160061,16.5390301 C29.0428646,16.8768784 29.1665787,17.4718895 28.9097655,17.9518861 L26.4599451,22.5307225 C26.1668352,23.0785597 25.4851128,23.2850568 24.9372757,22.9919469 C24.3894386,22.6988369 24.1829414,22.0171146 24.4760514,21.4692775 L26.482218,17.719652 L22.844679,14.8406273 C22.3817569,14.4742356 22.2808041,13.8123311 22.6134565,13.3245994 L26.6773859,7.36610553 C27.0274762,6.8528066 27.7273914,6.72049983 28.2406903,7.07059008 C28.7539892,7.42068033 28.886296,8.12059554 28.5362058,8.63389447 L25.0627183,13.7266882 L28.6160061,16.5390301 Z"
              id="Path-11"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AgreementExpired;
