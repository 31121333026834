import { FC, ReactChild } from 'react';
import { InputAdornment, Box } from '@mui/material';

import MAFTextField, { IMAFTextFieldProps } from '../MAFTextField/MAFTextField';

import { getFormattingProps } from './utils/formatting.utils';
import { styles } from './MAFNumberField.styles';
import { propTypes } from './MAFNumberField.propTypes';

export interface IMAFNumberFieldProps extends IMAFTextFieldProps {
  isMoney?: boolean;
  isPercentage?: boolean;
  custom?: ReactChild;
  refCallback?: () => void;
  shouldAllowCents?: boolean;
  isWithoutDecimals?: boolean;
  value?: number | string;
}

const MAFNumberField: FC<IMAFNumberFieldProps> = ({
  textAlign = 'right',
  isMoney,
  isPercentage,
  custom,
  refCallback,
  placeholder = '0',
  value,
  onChange,
  onBlur,
  shouldAllowCents,
  isWithoutDecimals = false,
  ...rest
}) => {
  const adornmentClasses = [styles.adornment, Boolean(rest.disabled) && styles.adornmentDisabled];

  const {
    adornment = '',
    overridedValue = value,
    overridedOnChange = onChange,
    overrideOnBlur = onBlur,
  } = getFormattingProps({
    value,
    onChange,
    onBlur,
    isMoney,
    isPercentage,
    custom,
    shouldAllowCents,
    isWithoutDecimals,
  });

  const endAdornment = (
    <InputAdornment position="end">
      <Box sx={adornmentClasses}>{adornment}</Box>
    </InputAdornment>
  );

  return (
    <MAFTextField
      additionalInputProps={{
        endAdornment,
      }}
      {...rest}
      inputProps={{
        ref: refCallback,
        inputMode: 'numeric',
      }}
      placeholder={placeholder.toString()}
      value={overridedValue}
      onChange={overridedOnChange}
      onBlur={overrideOnBlur}
      textAlign={textAlign}
    />
  );
};

MAFNumberField.propTypes = propTypes;

export default MAFNumberField;
