import { ISxStyles } from '@asfi/maf';

export const styles: ISxStyles = {
  link: ({ colors }) => ({
    color: colors.solid.blue,
  }),
  icon: ({ colors }) => ({
    width: 16,
    height: 16,
    margin: '0 0 -3px 6px',
    '& path': {
      fill: colors.solid.blue,
    },
  }),
  button: {
    marginBottom: 5,
    '&:hover': {
      textDecoration: 'none',
    },
    '@media print': {
      display: 'none',
    },
  },
  vendorBox: ({ colors, breakpoints }) => ({
    height: 165,
    backgroundColor: colors.solid.cadedBlue,
    marginTop: 6,
    color: colors.solid.white,
    display: 'flex',
    flexDirection: 'row',
    [breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
    '@media print': {
      display: 'none',
    },
  }),
  vendorBoxTitle: ({ colors }) => ({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.solid.white,
  }),
  vendorContacts: ({ breakpoints }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    [breakpoints.only('xs')]: {
      flex: 2,
    },
    '& > div:first-child': {
      marginBottom: 2,
    },
  }),
  vendorContactsTitle: ({ colors }) => ({
    color: colors.solid.white,
  }),
  phone: ({ colors, fontSizes: { medium, extraMedium }, typography: { main }, breakpoints }) => ({
    fontFamily: main,
    fontWeight: 600,
    color: colors.solid.white,
    textDecoration: 'none',
    fontSize: extraMedium,
    [breakpoints.only('xs')]: {
      fontSize: medium,
    },
  }),
  pageBreak: {
    pageBreakAfter: 'always',
  },
};
