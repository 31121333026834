import { select } from 'redux-saga/effects';
import { DomainUtils } from '@northstar/core';
import { USE_CURRENT_VENDOR_TERMS } from '@northstar/core-ui/modules/auth/authConstants';
import { selectAvailableProcessVariances } from '@northstar/core-ui/modules/processVariances/processVariancesSelectors';

import { selectCase } from 'modules/caseDetail/caseDetailSelectors';

import { selectActiveTerms } from './vendorTermsSelectors';

const getImporterId = ({ activeTerms, availableTerms }) => {
  if (!activeTerms) {
    if (!availableTerms.length) {
      throw new Error('Incorrect usage of attacher');
    }
    const [firstAvailableTerms] = availableTerms;
    return firstAvailableTerms.id === USE_CURRENT_VENDOR_TERMS ? null : firstAvailableTerms.id;
  }
  return activeTerms === USE_CURRENT_VENDOR_TERMS ? null : activeTerms;
};

function* getProductTypeToUse(productTypeParam) {
  if (productTypeParam) return productTypeParam;
  const { productType, productSubType } = yield select(selectCase());
  return DomainUtils.getFinancialType({ productType, productSubType });
}

export const createAttacherForTermsToUse = (productType) =>
  function* attacherTermsToUse(payload = {}) {
    const productTypeToUse = yield getProductTypeToUse(productType);
    const activeTerms = yield select(selectActiveTerms(productTypeToUse));
    const availableTerms = yield select(selectAvailableProcessVariances(productTypeToUse));
    return {
      ...payload,
      importerId: getImporterId({ activeTerms, availableTerms }),
    };
  };
