"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.changeLanguage = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
var _i18n = require("../../i18n");
var _localStorageUtils = require("../../utils/localStorage-utils");
/**
 * Actions
 */
const changeLanguage = exports.changeLanguage = (0, _reduxActions.createAction)('LANGUAGE_PROVIDER_CHANGE_LANGUAGE');

/**
 * Initial state
 */
const initialState = (0, _immutable.fromJS)({
  locale: (0, _localStorageUtils.getValueByKey)(_localStorageUtils.KEYS.LOCALE) ? (0, _localStorageUtils.getValueByKey)(_localStorageUtils.KEYS.LOCALE) : _i18n.DEFAULT_LOCALE
});

/**
 * Actions handlers
 */
var _default = exports.default = (0, _reduxActions.handleActions)({
  [changeLanguage](state, _ref) {
    let {
      payload
    } = _ref;
    return state.set('locale', payload.locale);
  }
}, initialState);