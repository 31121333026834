import { ReactElement, FC } from 'react';
import { Box } from '@mui/material';

import MAFButton, { TMAFButtonColor, IMAFButtonProps } from '../MAFButton/MAFButton';

import { styles } from './MAFButtonSelect.styles';
import { propTypes } from './MAFButtonSelect.propTypes';

export interface IMAFButtonSelectOptions extends IMAFButtonProps {
  label?: ReactElement | string;
  value?: string;
}

export interface IMAFButtonSelectProps {
  options?: IMAFButtonSelectOptions[];
  label?: ReactElement | string;
  value?: string;
  onChange: (val?: IMAFButtonSelectOptions['value']) => void;
  color?: TMAFButtonColor;
}

const MAFButtonSelect: FC<IMAFButtonSelectProps> = ({
  options = [],
  label,
  value,
  onChange = () => {},
  color = 'primaryPR',
}) => (
  <Box sx={styles.container}>
    <Box sx={styles.label}>{label}</Box>
    <Box sx={styles.options} ns-target="button-select">
      {options.map(({ label: text, value: val, ...rest }) => (
        <MAFButton
          key={val}
          onClick={() => onChange(val)}
          isRounded={false}
          color={val === value ? color : 'transparent-gray'}
          sx={styles.button}
          {...rest}
        >
          {text}
        </MAFButton>
      ))}
    </Box>
  </Box>
);

MAFButtonSelect.propTypes = propTypes;

export default MAFButtonSelect;
