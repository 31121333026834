"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setFiltersChanged = exports.setEmployees = exports.setDefaultEmployeeAndLoadFirstPageRequest = exports.setCaseDeletionStatusResponse = exports.setCaseDeletionStatusRequest = exports.setCaseDeletionStatus = exports.searchCriteriaChange = exports.restoreMultipleAndGetCasesRequest = exports.restoreAndGetCasesResponse = exports.restoreAndGetCaseResponse = exports.restoreAndGetCaseRequest = exports.initialState = exports.getEmployeesResponse = exports.getEmployeesRequest = exports.getCasesResponse = exports.getCasesRequest = exports.getCasesCountResponse = exports.getCasesCountRequest = exports.filterClear = exports.filterChange = exports.exportCasesResponse = exports.exportCasesRequest = exports.deleteMultipleAndGetCasesRequest = exports.deleteAndGetCasesResponse = exports.deleteAndGetCaseResponse = exports.deleteAndGetCaseRequest = exports.default = exports.changeSortingResponse = exports.changeSortingRequest = exports.changeSearchCriteriaRequest = exports.changeFilteringResponse = exports.changeFilteringRequest = void 0;
var _immutable = require("immutable");
var _dayjs = _interopRequireDefault(require("dayjs"));
var _reduxActions = require("redux-actions");
var _casesConstants = require("./casesConstants");
/**
 * Actions
 */
const getCasesCountRequest = exports.getCasesCountRequest = (0, _reduxActions.createAction)('CASES_GET_CASES_COUNT_REQUEST');
const getCasesCountResponse = exports.getCasesCountResponse = (0, _reduxActions.createAction)('CASES_GET_CASES_COUNT_RESPONSE');
const getCasesRequest = exports.getCasesRequest = (0, _reduxActions.createAction)('CASES_GET_CASES_REQUEST');
const getCasesResponse = exports.getCasesResponse = (0, _reduxActions.createAction)('CASES_GET_CASES_RESPONSE');
const exportCasesRequest = exports.exportCasesRequest = (0, _reduxActions.createAction)('CASES_EXPORT_CASES_REQUEST');
const exportCasesResponse = exports.exportCasesResponse = (0, _reduxActions.createAction)('CASES_EXPORT_CASES_RESPONSE');
const changeSortingRequest = exports.changeSortingRequest = (0, _reduxActions.createAction)('CASES_CHANGE_SORTING_REQUEST');
const changeSortingResponse = exports.changeSortingResponse = (0, _reduxActions.createAction)('CASES_CHANGE_SORTING_RESPONSE');
const changeFilteringRequest = exports.changeFilteringRequest = (0, _reduxActions.createAction)('CASES_CHANGE_FILTERING_REQUEST');
const changeFilteringResponse = exports.changeFilteringResponse = (0, _reduxActions.createAction)('CASES_CHANGE_FILTERING_RESPONSE');
const filterChange = exports.filterChange = (0, _reduxActions.createAction)('CASES_FILTER_CHANGE');
const setFiltersChanged = exports.setFiltersChanged = (0, _reduxActions.createAction)('CASES_SET_FILTERS_CHANGED');
const filterClear = exports.filterClear = (0, _reduxActions.createAction)('CASES_FILTER_CLEAR');
const getEmployeesRequest = exports.getEmployeesRequest = (0, _reduxActions.createAction)('CASES_GET_EMPLOYEES_REQUEST');
const getEmployeesResponse = exports.getEmployeesResponse = (0, _reduxActions.createAction)('CASES_GET_EMPLOYEES_RESPONSE');
const setDefaultEmployeeAndLoadFirstPageRequest = exports.setDefaultEmployeeAndLoadFirstPageRequest = (0, _reduxActions.createAction)('CASES_SET_DEFAULT_EMPLOYEE_AND_LOAD_FIRST_CASES_PAGE_REQUEST');
const changeSearchCriteriaRequest = exports.changeSearchCriteriaRequest = (0, _reduxActions.createAction)('CASES_CHANGE_SEARCH_CRITERIA_REQUEST');
const searchCriteriaChange = exports.searchCriteriaChange = (0, _reduxActions.createAction)('CASES_CHANGE_SEARCH_CRITERIA');
const setEmployees = exports.setEmployees = (0, _reduxActions.createAction)('CASES_SET_EMPLOYEES');
const setCaseDeletionStatus = exports.setCaseDeletionStatus = (0, _reduxActions.createAction)('CASES_SET_CASE_DELETION_STATUS');
const setCaseDeletionStatusRequest = exports.setCaseDeletionStatusRequest = (0, _reduxActions.createAction)('CASES_SET_CASE_DELETION_STATUS_REQUEST');
const setCaseDeletionStatusResponse = exports.setCaseDeletionStatusResponse = (0, _reduxActions.createAction)('CASES_SET_CASE_DELETION_STATUS_RESPONSE');
const deleteAndGetCasesResponse = exports.deleteAndGetCasesResponse = (0, _reduxActions.createAction)('CASES_DELETE_AND_GET_CASES_RESPONSE');
const deleteMultipleAndGetCasesRequest = exports.deleteMultipleAndGetCasesRequest = (0, _reduxActions.createAction)('CASES_DELETE_MULTIPLE_AND_GET_CASES_REQUEST');
const restoreAndGetCasesResponse = exports.restoreAndGetCasesResponse = (0, _reduxActions.createAction)('CASES_RESTORE_AND_GET_CASES_RESPONSE');
const restoreMultipleAndGetCasesRequest = exports.restoreMultipleAndGetCasesRequest = (0, _reduxActions.createAction)('CASES_RESTORE_MULTIPLE_AND_GET_CASES_REQUEST');
const deleteAndGetCaseRequest = exports.deleteAndGetCaseRequest = (0, _reduxActions.createAction)('CASES_DELETE_AND_GET_CASE_REQUEST');
const deleteAndGetCaseResponse = exports.deleteAndGetCaseResponse = (0, _reduxActions.createAction)('CASES_DELETE_AND_GET_CASE_RESPONSE');
const restoreAndGetCaseRequest = exports.restoreAndGetCaseRequest = (0, _reduxActions.createAction)('CASES_RESTORE_AND_GET_CASE_REQUEST');
const restoreAndGetCaseResponse = exports.restoreAndGetCaseResponse = (0, _reduxActions.createAction)('CASES_RESTORE_AND_GET_CASE_RESPONSE');

/**
 * Initial state
 */
const initialFilterState = {
  [_casesConstants.FILTER_FIELD_DATE_OPTION_SELECTED]: _casesConstants.DATE_OPTION_ANY_TIME,
  [_casesConstants.FILTER_FIELD_DATE_FROM_SELECTED]: null,
  [_casesConstants.FILTER_FIELD_DATE_TO_SELECTED]: null,
  [_casesConstants.FILTER_FIELD_EMPLOYEE_SELECTED]: [],
  [_casesConstants.FILTER_FIELD_STATUS_SELECTED]: [],
  [_casesConstants.FILTER_FIELD_EMPLOYEE]: [],
  [_casesConstants.FILTER_FIELD_STATUSES]: [],
  [_casesConstants.FILTER_FIELD_PRODUCT_TYPE_SELECTED]: [],
  [_casesConstants.FILTER_FIELD_PRODUCT_SUB_TYPE_SELECTED]: [],
  [_casesConstants.FILTER_FIELD_IMPORTER_NAME]: ''
};
const initialState = exports.initialState = (0, _immutable.fromJS)({
  cases: [],
  casesCount: 0,
  sorting: {
    orderBy: 'id',
    direction: 'desc'
  },
  pagination: {
    page: 1,
    totalPages: 1,
    totalElements: 0,
    size: 40
  },
  employees: [],
  caseDeletionStatus: _casesConstants.CASES_ONLY_NON_DELETED,
  filters: initialFilterState,
  filtersChanged: false,
  searchCriteria: null,
  gettingCases: false,
  gettingCasesCount: false,
  deletingCase: false,
  restoringCase: false,
  changingCaseDeletionStatus: false,
  exportingCases: false,
  reloadingCases: false
});

/**
 * Actions handlers
 */
var _default = exports.default = (0, _reduxActions.handleActions)({
  // Cases count
  [getCasesCountRequest](state) {
    return state.set('gettingCasesCount', true);
  },
  [getCasesCountResponse](state, _ref) {
    let {
      payload,
      error
    } = _ref;
    return !error ? state.set('casesCount', payload.casesCount).set('gettingCasesCount', false) : state.set('gettingCasesCount', false);
  },
  // Cases
  [(0, _reduxActions.combineActions)(getCasesRequest, changeFilteringRequest)](state) {
    return state.set('gettingCases', true);
  },
  [getCasesResponse](state, _ref2) {
    let {
      payload,
      error
    } = _ref2;
    const {
      cases,
      pagination
    } = payload;
    return !error ? state.set('cases', (0, _immutable.fromJS)(cases)).set('pagination', (0, _immutable.fromJS)(pagination)).set('gettingCases', false).set('reloadingCases', false) : state.set('gettingCases', false).set('reloadingCases', false);
  },
  [changeFilteringResponse](state) {
    return state.set('gettingCases', false);
  },
  [(0, _reduxActions.combineActions)(changeSortingResponse, filterChange)](state) {
    return state.setIn(['pagination', 'page'], 1);
  },
  [changeSortingResponse](state, _ref3) {
    let {
      payload
    } = _ref3;
    return state.set('sorting', (0, _immutable.fromJS)(payload)).set('cases', initialState.get('cases')).set('gettingCases', true);
  },
  [filterChange](state, _ref4) {
    let {
      payload
    } = _ref4;
    const {
      field,
      value
    } = payload;
    if (field === _casesConstants.FILTER_FIELD_DATE_OPTION_SELECTED) {
      if (value === _casesConstants.DATE_OPTION_PAST_WEEK) {
        return state.setIn(['filters', _casesConstants.FILTER_FIELD_DATE_FROM_SELECTED], (0, _dayjs.default)().startOf('week').toDate()).setIn(['filters', _casesConstants.FILTER_FIELD_DATE_TO_SELECTED], (0, _dayjs.default)().endOf('week').toDate()).setIn(['filters', _casesConstants.FILTER_FIELD_DATE_OPTION_SELECTED], value);
      } else if (value === _casesConstants.DATE_OPTION_PAST_MONTH) {
        return state.setIn(['filters', _casesConstants.FILTER_FIELD_DATE_FROM_SELECTED], (0, _dayjs.default)().startOf('month').toDate()).setIn(['filters', _casesConstants.FILTER_FIELD_DATE_TO_SELECTED], (0, _dayjs.default)().endOf('month').toDate()).setIn(['filters', _casesConstants.FILTER_FIELD_DATE_OPTION_SELECTED], value);
      } else if (value === _casesConstants.DATE_OPTION_ANY_TIME) {
        return state.setIn(['filters', _casesConstants.FILTER_FIELD_DATE_FROM_SELECTED], initialFilterState.date_from_selected).setIn(['filters', _casesConstants.FILTER_FIELD_DATE_TO_SELECTED], initialFilterState.date_to_selected).setIn(['filters', _casesConstants.FILTER_FIELD_DATE_OPTION_SELECTED], value);
      } else if (value === _casesConstants.DATE_OPTION_CUSTOM) {
        return state.setIn(['filters', _casesConstants.FILTER_FIELD_DATE_FROM_SELECTED], (0, _dayjs.default)().startOf('day').toDate()).setIn(['filters', _casesConstants.FILTER_FIELD_DATE_TO_SELECTED], (0, _dayjs.default)().endOf('day').toDate()).setIn(['filters', _casesConstants.FILTER_FIELD_DATE_OPTION_SELECTED], value);
      }
    } else if (field === _casesConstants.FILTER_FIELD_DATE_FROM_SELECTED) {
      const currentToSelected = state.getIn(['filters', _casesConstants.FILTER_FIELD_DATE_TO_SELECTED]);
      if (currentToSelected < value) {
        return state.setIn(['filters', _casesConstants.FILTER_FIELD_DATE_FROM_SELECTED], (0, _dayjs.default)(value).startOf('day').toDate()).setIn(['filters', _casesConstants.FILTER_FIELD_DATE_TO_SELECTED], (0, _dayjs.default)(value).endOf('day').toDate());
      }
    } else if (field === _casesConstants.FILTER_FIELD_DATE_TO_SELECTED) {
      const currentFromSelected = state.getIn(['filters', _casesConstants.FILTER_FIELD_DATE_FROM_SELECTED]);
      if (currentFromSelected > value) {
        return state.setIn(['filters', _casesConstants.FILTER_FIELD_DATE_FROM_SELECTED], (0, _dayjs.default)(value).startOf('day').toDate()).setIn(['filters', _casesConstants.FILTER_FIELD_DATE_TO_SELECTED], (0, _dayjs.default)(value).endOf('day').toDate());
      }
    } else if (field === _casesConstants.FILTER_FIELD_IMPORTER_NAME && !value.length) {
      return state.setIn(['filters', _casesConstants.FILTER_FIELD_IMPORTER_NAME], '');
    }
    return state.setIn(['filters', field], value);
  },
  [filterClear](state) {
    return state.set('filters', (0, _immutable.fromJS)(initialFilterState));
  },
  [setFiltersChanged](state) {
    return state.set('filtersChanged', true);
  },
  [searchCriteriaChange](state, _ref5) {
    let {
      payload
    } = _ref5;
    return state.set('searchCriteria', payload);
  },
  [(0, _reduxActions.combineActions)(getEmployeesResponse, setEmployees)](state, _ref6) {
    let {
      payload,
      error
    } = _ref6;
    return error ? state : state.set('employees', (0, _immutable.fromJS)(payload));
  },
  [setCaseDeletionStatus](state, _ref7) {
    let {
      payload
    } = _ref7;
    return state.set('caseDeletionStatus', payload.caseDeletionStatus);
  },
  [setCaseDeletionStatusRequest](state) {
    return state.set('changingCaseDeletionStatus', true);
  },
  [setCaseDeletionStatusResponse](state) {
    return state.set('changingCaseDeletionStatus', false);
  },
  [(0, _reduxActions.combineActions)(deleteAndGetCaseRequest)](state) {
    return state.set('deletingCase', true);
  },
  [(0, _reduxActions.combineActions)(deleteAndGetCaseResponse)](state) {
    return state.set('deletingCase', false);
  },
  [(0, _reduxActions.combineActions)(restoreAndGetCaseRequest)](state) {
    return state.set('restoringCase', true);
  },
  [(0, _reduxActions.combineActions)(restoreAndGetCaseResponse)](state) {
    return state.set('restoringCase', false);
  },
  [exportCasesRequest](state) {
    return state.set('exportingCases', true);
  },
  [exportCasesResponse](state) {
    return state.set('exportingCases', false);
  },
  [(0, _reduxActions.combineActions)(setCaseDeletionStatusRequest, searchCriteriaChange, changeFilteringRequest, deleteMultipleAndGetCasesRequest, restoreMultipleAndGetCasesRequest)](state) {
    return state.set('reloadingCases', true);
  }
}, initialState);