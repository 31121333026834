"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: {
    '& > div:nth-last-child(2)': {
      marginBottom: 4
    }
  },
  header: _ref => {
    let {
      typography,
      fontSizes: {
        small
      },
      colors
    } = _ref;
    return {
      fontFamily: typography.main,
      fontSize: small,
      letterSpacing: '0.82px',
      textAlign: 'left',
      color: colors.white
    };
  }
};