"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = assets;
exports.deleteAssets = deleteAssets;
exports.getAssetList = getAssetList;
exports.getAssetListCommercial = getAssetListCommercial;
exports.hardDeleteAssets = hardDeleteAssets;
exports.restoreAssets = restoreAssets;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _core = require("@northstar/core");
var _effects = require("redux-saga/effects");
var _assetListMappers = require("../assetList/assetListMappers");
var _appReducer = require("../app/appReducer");
var assetListApi = _interopRequireWildcard(require("../assetList/assetListApi"));
var _reduxSagaUtils = require("../../utils/redux-saga-utils");
var _vendorDashboardAssetListReducer = require("./vendorDashboardAssetListReducer");
var _vendorDashboardAssetListSelectors = require("./vendorDashboardAssetListSelectors");
var _vendorDashboardAssetSaga = require("./vendorDashboardAssetSaga");
var _dashboardSagaUtils = require("./vendorDashboardUtils/dashboard-saga-utils");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function getAssetListDynamic(_ref) {
  let {
    payload
  } = _ref;
  return function* () {
    try {
      const productType = payload.productType || (yield (0, _effects.select)((0, _vendorDashboardAssetListSelectors.selectSelectedProductType)()));
      const actionToDispatch = productType === _core.PROCESS_VARIANCES.PRIVATE_OL ? _vendorDashboardAssetListReducer.getAssetsRequest : _vendorDashboardAssetListReducer.getAssetsCommercialRequest;
      const vehicleType = yield (0, _effects.select)((0, _vendorDashboardAssetListSelectors.selectSelectedVehicleType)());
      const values = _objectSpread(_objectSpread({}, payload), {}, {
        vehicleType
      });
      yield (0, _effects.put)(actionToDispatch(values));
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)('errors.could_not_switch_customer_type'));
    }
  }();
}
function getAssetList(_ref2) {
  let {
    payload
  } = _ref2;
  return function* () {
    try {
      const response = yield (0, _reduxSagaUtils.callWithAttachers)({
        endpoint: assetListApi.getAssetListPrivate,
        payload,
        attachers: [_dashboardSagaUtils.attachRouteVendorId]
      });
      yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.getAssetsResponse)(_assetListMappers.responseMappers.mapAssets(response, true)));
    } catch (e) {
      yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.getAssetsResponse)(e));
      yield (0, _effects.put)((0, _appReducer.displayError)('errors.could_not_get_asset_list'));
    }
  }();
}
function getAssetListCommercial(_ref3) {
  let {
    payload
  } = _ref3;
  return function* () {
    try {
      const {
        active,
        status,
        vehicleType
      } = payload;
      const response = yield (0, _reduxSagaUtils.callWithAttachers)({
        endpoint: assetListApi.getAssetListCommercial,
        payload: {
          active,
          status,
          vehicleType
        },
        attachers: [_dashboardSagaUtils.attachRouteVendorId]
      });
      yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.getAssetsCommercialResponse)(_assetListMappers.responseMappers.mapAssets(response)));
      yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.changeSelectedVehicleType)({
        vehicleType
      }));
    } catch (e) {
      yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.getAssetsCommercialResponse)(e));
      yield (0, _effects.put)((0, _appReducer.displayError)('errors.could_not_get_asset_list'));
    }
  }();
}
function* restoreAssets() {
  try {
    const selected = yield (0, _effects.select)((0, _vendorDashboardAssetListSelectors.selectSelectedAssets)());
    yield (0, _effects.all)(selected.map(assetId => (0, _effects.call)(_vendorDashboardAssetSaga.restoreAsset, {
      payload: {
        assetId
      }
    })));
    yield (0, _reduxSagaUtils.callWithAttachers)({
      endpoint: getAssetListDynamic,
      payload: {
        payload: {
          active: 'false'
        }
      },
      attachers: [_dashboardSagaUtils.attachRouteVendorId]
    });
    yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.restoreAssetsResponse)());
    const message = selected.length === 1 ? {
      id: 'vendor.activate_asset_success'
    } : {
      id: 'vendor.activate_assets_success',
      values: {
        value: selected.length
      }
    };
    yield (0, _effects.put)((0, _appReducer.displaySnackbar)(message));
  } catch (e) {
    yield (0, _effects.put)((0, _appReducer.displayError)(e));
    yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.restoreAssetsResponse)(e));
  }
}
function* deleteAssets() {
  try {
    const selected = yield (0, _effects.select)((0, _vendorDashboardAssetListSelectors.selectSelectedAssets)());
    yield (0, _effects.all)(selected.map(assetId => (0, _effects.call)(_vendorDashboardAssetSaga.deleteAsset, {
      payload: {
        assetId
      }
    })));
    yield (0, _reduxSagaUtils.callWithAttachers)({
      endpoint: getAssetListDynamic,
      payload: {
        payload: {
          active: true
        }
      },
      attachers: [_dashboardSagaUtils.attachRouteVendorId]
    });
    yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.deleteAssetsResponse)());
    const message = selected.length === 1 ? {
      id: 'vendor.deactivate_asset_success'
    } : {
      id: 'vendor.deactivate_assets_success',
      values: {
        value: selected.length
      }
    };
    yield (0, _effects.put)((0, _appReducer.displaySnackbar)(message));
  } catch (e) {
    yield (0, _effects.put)((0, _appReducer.displayError)(e));
    yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.deleteAssetsResponse)(e));
  }
}
function* hardDeleteAssets() {
  try {
    const selected = yield (0, _effects.select)((0, _vendorDashboardAssetListSelectors.selectSelectedAssets)());
    yield (0, _effects.all)(selected.map(assetId => (0, _effects.call)(_vendorDashboardAssetSaga.hardDeleteAsset, {
      payload: {
        assetId
      }
    })));
    yield (0, _reduxSagaUtils.callWithAttachers)({
      endpoint: getAssetListDynamic,
      payload: {
        payload: {
          active: 'false'
        }
      },
      attachers: [_dashboardSagaUtils.attachRouteVendorId]
    });
    yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.hardDeleteAssetsResponse)());
    const message = selected.length === 1 ? {
      id: 'vendor.delete_asset_success'
    } : {
      id: 'vendor.delete_assets_success',
      values: {
        value: selected.length
      }
    };
    yield (0, _effects.put)((0, _appReducer.displaySnackbar)(message));
  } catch (e) {
    yield (0, _effects.put)((0, _appReducer.displayError)(e));
    yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.hardDeleteAssetsResponse)(e));
  }
}
function* assets() {
  yield (0, _effects.takeLatest)(_vendorDashboardAssetListReducer.getAssetsRequest, getAssetList);
  yield (0, _effects.takeLatest)(_vendorDashboardAssetListReducer.getAssetsCommercialRequest, getAssetListCommercial);
  yield (0, _effects.takeLatest)(_vendorDashboardAssetListReducer.getAssetsDynamicRequest, getAssetListDynamic);
  yield (0, _effects.takeLatest)(_vendorDashboardAssetListReducer.restoreAssetsRequest, restoreAssets);
  yield (0, _effects.takeLatest)(_vendorDashboardAssetListReducer.deleteAssetsRequest, deleteAssets);
  yield (0, _effects.takeLatest)(_vendorDashboardAssetListReducer.hardDeleteAssetsRequest, hardDeleteAssets);
}