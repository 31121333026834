import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  activeStep: PropTypes.number.isRequired,
  children: PropTypes.node,
  customIcon: PropTypes.elementType,
  isIntroAnimating: PropTypes.bool,
  lastAvailableStep: PropTypes.number,
  onStepClick: PropTypes.func,
  parentRefs: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
  shouldShowAllTitles: PropTypes.bool,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      buttonType: PropTypes.oneOf(['button', 'submit']),
      content: PropTypes.node,
      isDisabled: PropTypes.bool,
      title: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
        .isRequired,
    }),
  ).isRequired,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
} as any;
