import { MAFTypography } from '@asfi/maf';
import { ReactChild } from 'react';
import { FormattedMessage } from 'react-intl';
import { IAgreementDetails, TFormatTextFunc } from 'types';

import Details from '../Details/Details';

import { styles } from './agreementDetails.styles';

export interface IAgreementDetailsProps {
  agreementDetails: IAgreementDetails[];
  conditionsTitle: ReactChild;
  conditionsItemsTitle?: ReactChild;
  conditionsItems?: string[];
  conditions: string[];
  formatText: TFormatTextFunc;
}

const AgreementDetails: React.FC<IAgreementDetailsProps> = ({
  agreementDetails,
  conditionsTitle,
  conditionsItemsTitle,
  conditionsItems,
  conditions,
  formatText,
}) => (
  <>
    <Details details={agreementDetails} isWithLine formatText={formatText} />
    <div>
      <MAFTypography variant="sub2-semi-bold" isWithContainer sx={styles.title}>
        {conditionsTitle}
      </MAFTypography>
      {conditionsItemsTitle && (
        <MAFTypography variant="body1" isWithContainer sx={styles.paragraph}>
          {conditionsItemsTitle}
        </MAFTypography>
      )}
      {conditionsItems && (
        <ul>
          {conditionsItems.map((item, index) => (
            <li key={index}>
              <MAFTypography variant="body1" isWithContainer sx={styles.paragraph}>
                <FormattedMessage id={item} />
              </MAFTypography>
            </li>
          ))}
        </ul>
      )}
      {conditions.map((condition, index) => (
        <MAFTypography variant="body1" isWithContainer key={index} sx={styles.paragraph}>
          <FormattedMessage id={condition} />
        </MAFTypography>
      ))}
    </div>
  </>
);

export default AgreementDetails;
