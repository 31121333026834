"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var constants = _interopRequireWildcard(require("../../../../utils/constants"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = exports.styles = {
  body: _ref => {
    let {
      colors
    } = _ref;
    return {
      body: {
        margin: 0,
        overflow: 'auto',
        backgroundColor: colors.custom.background.background
      }
    };
  },
  containerWithFooter: {
    height: '100vh'
  },
  directionColumn: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    '@media not all and (min-resolution:.001dpcm)': {
      flexWrap: 'wrap',
      flexDirection: 'initial'
    }
  },
  imageBackground: _ref2 => {
    let {
      breakpoints,
      backgrounds
    } = _ref2;
    return {
      backgroundSize: 'cover',
      backgroundImage: "url(".concat(backgrounds.path).concat(constants.PUBLIC_BACKGROUND_IMAGE_URL, ")"),
      [breakpoints.only('xs')]: {
        backgroundImage: "url(".concat(backgrounds.path).concat(constants.PUBLIC_BACKGROUNG_IMAGE_MOBILE_URL, ")"),
        height: 400
      },
      width: '100%',
      position: 'absolute',
      zIndex: '-1',
      height: backgrounds.innerBackgroundSize,
      minHeight: 'auto'
    };
  },
  fullImageBackground: {
    alignItems: 'center',
    minHeight: '100vh',
    height: 'auto'
  },
  alert: _ref3 => {
    let {
      colors
    } = _ref3;
    return {
      width: '100%',
      color: colors.custom.fontPrimary,
      background: 'red',
      padding: '10px 20px',
      textAlign: 'center'
    };
  },
  separator: {
    flex: 0.9
  },
  loginBtn: {
    marginLeft: 55,
    marginRight: -55
  },
  userBlock: {
    marginLeft: 55,
    marginRight: 5
  },
  userBlockDisplayName: {
    fontWeight: 'bold !important'
  },
  blured: {
    filter: 'blur(4px)'
  },
  footer: _ref4 => {
    let {
      typography: {
        main
      },
      fontSizes: {
        extraSmall
      },
      colors
    } = _ref4;
    return {
      color: colors.custom.fontPrimary,
      fontSize: extraSmall,
      fontFamily: main,
      lineHeight: '20px',
      letterSpacing: '0.68px',
      textAlign: 'center'
    };
  },
  logo: {
    width: 225,
    height: 44
  },
  overFlowHidden: {
    overflow: 'hidden',
    position: 'fixed'
  },
  dark: {
    alignItems: 'inherit'
  }
};