import { MAFTypography } from '@asfi/maf';
import { Box } from '@mui/material';
import { ReactChild } from 'react';

import { styles } from './quoteDetail.styles';

export interface IQuoteDetailProps {
  label: ReactChild;
  isWithBoldLabel?: boolean;
  value: ReactChild;
  helperText?: ReactChild;
}

const QuoteDetail: React.FC<IQuoteDetailProps> = ({
  label,
  isWithBoldLabel,
  value,
  helperText,
}) => (
  <>
    {isWithBoldLabel ? (
      <MAFTypography variant="label1" isWithContainer sx={styles.label}>
        {label}
      </MAFTypography>
    ) : (
      <MAFTypography variant="body1" isWithContainer sx={styles.label}>
        {label}
      </MAFTypography>
    )}
    <MAFTypography variant="sub1-semi-bold" isWithContainer sx={styles.text}>
      {value}
    </MAFTypography>
    <MAFTypography variant="body1" isWithContainer sx={styles.text}>
      {helperText}
    </MAFTypography>
    <Box sx={styles.lineContainer}>
      <Box sx={styles.line} />
    </Box>
  </>
);

export default QuoteDetail;
