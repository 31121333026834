"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeSorting = changeSorting;
exports.default = casesSaga;
exports.deleteCaseSaga = deleteCaseSaga;
exports.getCases = getCases;
exports.getCasesCount = getCasesCount;
exports.restoreCaseSaga = restoreCaseSaga;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _effects = require("redux-saga/effects");
var _appReducer = require("../app/appReducer");
var _authSelectors = require("../auth/authSelectors");
var _languageSelectors = require("../language/languageSelectors");
var _excelUtils = require("../../utils/excel-utils");
var casesAPI = _interopRequireWildcard(require("./casesApi"));
var _casesConstants = require("./casesConstants");
var _casesMapper = require("./casesMapper");
var _casesReducer = require("./casesReducer");
var _casesSelectors = require("./casesSelectors");
const _excluded = ["clear", "field", "value", "filtersChanged"],
  _excluded2 = ["value", "hasDelay"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// Auth module

function* getCasesCount() {
  try {
    const caseCount = yield (0, _effects.call)(casesAPI.getCasesCount);
    yield (0, _effects.put)((0, _casesReducer.getCasesCountResponse)(_casesMapper.responseMappers.mapCaseCount(caseCount)));
  } catch (e) {
    yield (0, _effects.put)((0, _casesReducer.getCasesCountResponse)(e));
    yield (0, _effects.put)((0, _appReducer.displayError)('errors.could_not_get_case_count'));
  }
}
function getCases() {
  let {
    payload: {
      page: reqPage
    } = {}
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function* () {
    try {
      const request = yield (0, _effects.select)((0, _casesSelectors.selectCasesRequest)());
      if (reqPage) {
        request.page = reqPage;
      }
      const {
        case_overview_in_page: content,
        page_metadata: {
          page_number: page,
          page_size: size,
          total_elements: totalElements,
          total_pages: totalPages
        }
      } = yield (0, _effects.call)(casesAPI.getCases, request);
      const response = {
        cases: _casesMapper.responseMappers.mapCases(content),
        pagination: {
          page,
          size,
          totalPages,
          totalElements
        }
      };
      if (reqPage && reqPage !== 1) {
        const caseList = yield (0, _effects.select)((0, _casesSelectors.selectCases)());
        response.cases = caseList.concat(response.cases);
      }
      yield (0, _effects.put)((0, _casesReducer.getCasesResponse)(response));
    } catch (e) {
      yield (0, _effects.put)((0, _casesReducer.getCasesResponse)(e));
      yield (0, _effects.put)((0, _appReducer.displayError)('errors.could_not_get_cases'));
    }
  }();
}
function exportCases() {
  let {
    payload: {
      size,
      columns
    }
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function* () {
    try {
      const request = yield (0, _effects.select)((0, _casesSelectors.selectCasesRequest)());
      const {
        case_overview_in_page: response
      } = yield (0, _effects.call)(casesAPI.getCases, _objectSpread(_objectSpread({}, request), {}, {
        page: 1,
        size
      }));
      const locale = yield (0, _effects.select)((0, _languageSelectors.selectLocale)());
      const cases = _casesMapper.responseMappers.mapCasesForExport({
        response,
        columns,
        locale
      });
      (0, _excelUtils.jsonToExcelSheet)({
        data: cases,
        options: {
          fileName: 'case-overview.xlsx',
          sheetName: 'Case overview'
        }
      });
      yield (0, _effects.put)((0, _casesReducer.exportCasesResponse)());
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)('errors.could_not_export_cases'));
      yield (0, _effects.put)((0, _casesReducer.exportCasesResponse)(e));
    }
  }();
}
function changeSorting(_ref) {
  let {
    payload
  } = _ref;
  return function* () {
    try {
      let {
        orderBy,
        direction
      } = yield (0, _effects.select)((0, _casesSelectors.selectCasesSorting)());
      if (payload === orderBy) {
        direction = direction === 'asc' ? 'desc' : 'asc';
      } else {
        orderBy = payload;
        direction = 'desc';
      }
      yield (0, _effects.put)((0, _casesReducer.changeSortingResponse)({
        orderBy,
        direction
      }));
      yield (0, _effects.call)(getCases);
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)('errors.could_not_sort_cases'));
    }
  }();
}
function changeFiltering(_ref2) {
  let {
    payload
  } = _ref2;
  return function* () {
    try {
      const {
          clear,
          field,
          value,
          filtersChanged
        } = payload,
        rest = (0, _objectWithoutProperties2.default)(payload, _excluded);
      if (clear) {
        yield (0, _effects.put)((0, _casesReducer.filterClear)());
      } else {
        yield (0, _effects.put)((0, _casesReducer.filterChange)({
          field,
          value
        }));
        if (filtersChanged) {
          yield (0, _effects.put)((0, _casesReducer.setFiltersChanged)());
        }
      }
      yield (0, _effects.call)(getCases, rest);
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)('errors.could_not_filter_cases'));
      yield (0, _effects.put)((0, _casesReducer.changeFilteringResponse)());
    }
  }();
}
function* getEmployees() {
  try {
    const response = yield (0, _effects.call)(casesAPI.getEmployees);
    yield (0, _effects.put)((0, _casesReducer.getEmployeesResponse)(response));
  } catch (e) {
    yield (0, _effects.put)((0, _casesReducer.getEmployeesResponse)(e));
  }
}
function setDefaultEmployeeAndLoadFirstPage(_ref3) {
  let {
    payload = {}
  } = _ref3;
  return function* () {
    const {
      caseDeletionStatus
    } = payload;
    if (caseDeletionStatus) {
      yield (0, _effects.put)((0, _casesReducer.setCaseDeletionStatus)({
        caseDeletionStatus
      }));
    }
    const username = yield (0, _effects.select)((0, _authSelectors.selectUsername)());
    const employeeList = [username];
    yield (0, _effects.put)((0, _casesReducer.filterChange)({
      field: _casesConstants.FILTER_FIELD_EMPLOYEE_SELECTED,
      value: employeeList
    }));
    const employees = yield (0, _effects.select)((0, _casesSelectors.selectEmployees)());
    if (!employees.length) {
      yield (0, _effects.put)((0, _casesReducer.setEmployees)(employeeList));
    }
    yield (0, _effects.put)((0, _casesReducer.getCasesRequest)({
      page: 1
    }));
  }();
}
function changeSearchCriteria(_ref4) {
  let {
    payload
  } = _ref4;
  return function* () {
    try {
      const {
          value,
          hasDelay
        } = payload,
        rest = (0, _objectWithoutProperties2.default)(payload, _excluded2);
      if (hasDelay) {
        yield (0, _effects.delay)(1000);
      }
      yield (0, _effects.put)((0, _casesReducer.searchCriteriaChange)(value));
      yield (0, _effects.put)((0, _casesReducer.getCasesRequest)(rest));
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)('errors.could_not_filter_cases'));
      yield (0, _effects.put)((0, _casesReducer.changeFilteringResponse)());
    }
  }();
}
function deleteCaseSaga(_ref5) {
  let {
    payload
  } = _ref5;
  return function* () {
    const {
      resourceId
    } = payload;
    yield (0, _effects.call)(casesAPI.deleteCase, {
      resourceId
    });
  }();
}
function restoreCaseSaga(_ref6) {
  let {
    payload
  } = _ref6;
  return function* () {
    const {
      resourceId
    } = payload;
    yield (0, _effects.call)(casesAPI.restoreCase, {
      resourceId
    });
  }();
}
function deleteMultipleAndGetCases(_ref7) {
  let {
    payload
  } = _ref7;
  return function* () {
    try {
      const {
        resourceIds
      } = payload;
      yield (0, _effects.all)(resourceIds.map(resourceId => (0, _effects.call)(deleteCaseSaga, {
        payload: {
          resourceId
        }
      })));
      yield (0, _effects.call)(getCases, {
        payload: {
          page: 1
        }
      });
      yield (0, _effects.put)((0, _casesReducer.deleteAndGetCasesResponse)());
      const snackbar = resourceIds.length === 1 ? {
        id: 'cases.delete_success'
      } : {
        id: 'cases.delete_multiple_success',
        values: {
          count: resourceIds.length
        }
      };
      yield (0, _effects.put)((0, _appReducer.displaySnackbar)(snackbar));
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)('errors.could_not_delete_case'));
      yield (0, _effects.put)((0, _casesReducer.deleteAndGetCasesResponse)(e));
    }
  }();
}
function restoreMultipleAndGetCases(_ref8) {
  let {
    payload
  } = _ref8;
  return function* () {
    try {
      const {
        resourceIds
      } = payload;
      yield (0, _effects.all)(resourceIds.map(resourceId => (0, _effects.call)(restoreCaseSaga, {
        payload: {
          resourceId
        }
      })));
      yield (0, _effects.call)(getCases, {
        payload: {
          page: 1
        }
      });
      yield (0, _effects.put)((0, _casesReducer.restoreAndGetCasesResponse)());
      const snackbar = resourceIds.length === 1 ? {
        id: 'cases.restore_success'
      } : {
        id: 'cases.restore_multiple_success',
        values: {
          count: resourceIds.length
        }
      };
      yield (0, _effects.put)((0, _appReducer.displaySnackbar)(snackbar));
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)('errors.could_not_restore_case'));
      yield (0, _effects.put)((0, _casesReducer.restoreAndGetCasesResponse)(e));
    }
  }();
}
function changeCaseDeletionStatus(_ref9) {
  let {
    payload
  } = _ref9;
  return function* () {
    const {
      caseDeletionStatus
    } = payload;
    yield (0, _effects.put)((0, _casesReducer.setCaseDeletionStatus)({
      caseDeletionStatus
    }));
    yield (0, _effects.call)(getCases, {
      payload: {
        page: 1
      }
    });
  }();
}
function* casesSaga() {
  yield (0, _effects.takeLatest)(_casesReducer.getCasesCountRequest, getCasesCount);
  yield (0, _effects.takeLatest)(_casesReducer.getCasesRequest, getCases);
  yield (0, _effects.takeLatest)(_casesReducer.exportCasesRequest, exportCases);
  yield (0, _effects.takeLatest)(_casesReducer.changeSortingRequest, changeSorting);
  yield (0, _effects.takeLatest)(_casesReducer.changeFilteringRequest, changeFiltering);
  yield (0, _effects.takeLatest)(_casesReducer.getEmployeesRequest, getEmployees);
  yield (0, _effects.takeLatest)(_casesReducer.setDefaultEmployeeAndLoadFirstPageRequest, setDefaultEmployeeAndLoadFirstPage);
  yield (0, _effects.takeLatest)(_casesReducer.changeSearchCriteriaRequest, changeSearchCriteria);
  yield (0, _effects.takeLatest)(_casesReducer.setCaseDeletionStatusRequest, changeCaseDeletionStatus);
  yield (0, _effects.takeLatest)(_casesReducer.deleteMultipleAndGetCasesRequest, deleteMultipleAndGetCases);
  yield (0, _effects.takeLatest)(_casesReducer.restoreMultipleAndGetCasesRequest, restoreMultipleAndGetCases);
}