import { FormControl, Input, InputLabel, Select } from '@mui/material';
import { useState, FC, ReactChild, ReactNode } from 'react';

import { styles } from './MAFMultiSelect.styles';
import { propTypes } from './MAFMultiSelect.propTypes';

export interface IMAFMultiSelectProps {
  name: string;
  tag: ReactChild;
  children: ReactNode;
  onChange: () => void;
}

const MAFMultiSelect: FC<IMAFMultiSelectProps> = ({ name, tag, children, onChange, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <FormControl sx={styles.container}>
      <InputLabel sx={styles.label} htmlFor={name}>
        {tag}
      </InputLabel>
      <Select
        name={name}
        input={<Input id={name} />}
        {...rest}
        open={isOpen}
        onOpen={toggleOpen}
        onClose={toggleOpen}
        onChange={onChange}
        sx={[styles.menuField, styles.icon]}
        MenuProps={{ sx: styles.menuContainer }}
      >
        {children}
      </Select>
    </FormControl>
  );
};

MAFMultiSelect.propTypes = propTypes;

export default MAFMultiSelect;
