import { keyframes } from '@mui/styled-engine';

import { ISxStyles } from '../../theming/theme.type';

interface IProps {
  isError?: boolean;
  isDisabled?: boolean;
}

const fillAnimation = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

export const useStyles = ({ isError, isDisabled }: IProps): ISxStyles => ({
  root: ({ colors }) => ({
    '&.MuiRadio-root': {
      '&:hover': {
        backgroundColor: 'transparent',
        '& > span > div': {
          boxShadow: `${
            isError ? colors.custom.inputFull.error.background : colors.custom.toggleElements.color
          } 0px 0px 4px 0px`,
        },
      },
    },
  }),
  colorSecondary: {
    '& .MuiRadio-colorPrimary.Mui-checked, .MuiRadio-colorSecondary.Mui-checked': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  icon: ({ colors }) => ({
    background: colors.custom.toggleElements.background,
    borderRadius: '50px',
    minWidth: 20,
    width: 20,
    height: 20,
    opacity: isDisabled ? 0.5 : undefined,
    marginTop: '2px',
    border: `1px solid ${
      isError ? colors.custom.inputFull.error.background : colors.custom.toggleElements.border
    }`,
  }),
  checkedIcon: ({ colors }) => ({
    background: colors.custom.toggleElements.background,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50px',
    minWidth: 18,
    width: 18,
    height: 18,
    opacity: isDisabled ? 0.5 : undefined,
    marginTop: '2px',
    border: `2px solid ${colors.custom.toggleElements.color}`,
  }),
  checkedIconFill: ({ colors }) => ({
    width: 12,
    height: 12,
    background: colors.custom.toggleElements.color,
    borderRadius: '50%',
    opacity: isDisabled ? 0.5 : undefined,
    animation: `${fillAnimation} .25s`,
  }),
  labelDisabled: {
    opacity: 0.5,
  },
  rootLabel: {
    '&.MuiFormControlLabel-root': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
});
