import { TMAFTextFieldOnChangeEvent } from '../../MAFTextField';
import MAFTextFieldWithIcon, { IMAFTextFieldWithIconProps } from '../MAFTextFieldWithIcon';
import { Message } from '../../Icons';

import { propTypes } from './MAFEmailField.propTypes';

const MAFEmailField: React.FC<IMAFTextFieldWithIconProps> = ({ onChange, ...rest }) => {
  const handleOnChange = (e: TMAFTextFieldOnChangeEvent) => {
    e.target.value = e.target.value.replace(/\s/g, '');
    onChange && onChange(e);
  };
  return (
    <MAFTextFieldWithIcon
      icon={<Message />}
      iconPosition="start"
      onChange={handleOnChange}
      {...rest}
    />
  );
};

MAFEmailField.propTypes = propTypes;

export default MAFEmailField;
