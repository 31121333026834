"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.responseMappers = exports.requestMappers = exports.default = void 0;
var _objectUtils = require("../../utils/object-utils");
const mapComments = function () {
  let arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return arr.map(mapComment);
};
const mapComment = comment => (0, _objectUtils.objectToCamelCase)({
  obj: comment
});
const mapCreateCommentToRequest = _ref => {
  let {
    resourceId,
    username,
    role,
    text,
    employeeEmail,
    caseId
  } = _ref;
  return {
    requestBody: {
      resourceId,
      key: resourceId,
      text,
      created_by: username,
      role,
      employeeEmail,
      caseId
    }
  };
};
const requestMappers = exports.requestMappers = {
  mapCreateComment: mapCreateCommentToRequest
};
const responseMappers = exports.responseMappers = {
  mapComments,
  mapComment
};
var _default = exports.default = {
  requestMappers,
  responseMappers
};