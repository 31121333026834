const SadFilled: React.FC<{}> = () => (
  <svg height="48px" width="48px" color="#003755" viewBox="-2 -2 24 24">
    <path
      d="M10,0 C15.523,0 20,4.477 20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 Z M10.0634806,13.0005952 L9.97724898,13 C8.9183276,13 7.80424352,13.2649691 6.69671349,13.7049298 C6.31216735,13.8576888 5.95548973,14.0213106 5.63499357,14.1852536 C5.44075801,14.2846106 5.30008062,14.3630158 5.22147571,14.4099801 C5.00597508,14.5387358 4.9356544,14.8178106 5.06441006,15.0333112 C5.19316573,15.2488119 5.47224059,15.3191326 5.68774121,15.1903769 C5.74990009,15.1532387 5.87329699,15.0844646 6.04899291,14.9945911 C6.34504922,14.8431498 6.67587472,14.6913873 7.032329,14.5497877 C8.04194307,14.1487236 9.04955291,13.9090779 9.97724898,13.9090779 L9.97724898,13.9090779 L10.0509797,13.9095871 C10.9603624,13.9220934 11.9508372,14.1601329 12.9468442,14.5505478 C13.3085928,14.692346 13.6450423,14.844331 13.9466984,14.9960133 C14.1258193,15.086081 14.2519115,15.1550574 14.3156836,15.1924051 C14.5323044,15.3192672 14.8107522,15.2465034 14.9376143,15.0298826 C15.0644764,14.8132619 14.9917127,14.534814 14.7750919,14.4079519 C14.6952567,14.3611971 14.5522981,14.2829942 14.3550894,14.1838313 C14.0295294,14.0201294 13.6678121,13.8567301 13.2786075,13.7041697 C12.1892584,13.2771666 11.0976311,13.0148173 10.0634806,13.0005952 L10.0634806,13.0005952 Z M6,7 C5.44771525,7 5,7.44771525 5,8 C5,8.55228475 5.44771525,9 6,9 C6.55228475,9 7,8.55228475 7,8 C7,7.44771525 6.55228475,7 6,7 Z M14,7 C13.4477153,7 13,7.44771525 13,8 C13,8.55228475 13.4477153,9 14,9 C14.5522847,9 15,8.55228475 15,8 C15,7.44771525 14.5522847,7 14,7 Z"
      id="Combined-Shape"
    ></path>
  </svg>
);

export default SadFilled;
