import { app, caseDetail, EQUIPMENT_CATEGORY_RETROFIT } from '@northstar/core-ui/modules';
import { objectUtils, errorUtils, uiErrorUtils } from '@northstar/core-ui/utils';
import { getFormValues, startSubmit, stopSubmit, isInvalid } from 'redux-form';
import { delay, put, call, takeLatest, select, fork, cancel } from 'redux-saga/effects';
import { callWithAttachers, createApiSaga } from '@northstar/core-ui/utils/redux-saga-utils';
import { PROCESS_VARIANCES } from '@northstar/core';

import { createAttacherForTermsToUse } from 'modules/vendor/vendorTermsAttachers';
import { selectAsset } from 'modules/asset/assetSelectors';
import { selectVendorTermsCMFL } from 'modules/vendor/vendorTermsSelectors';

import { changeAssetPreviousId } from '../asset/assetReducer';
import {
  changeSelectedEquipment,
  recalculatePriceEquipmentChange,
} from '../asset/equipmentReducer';
import { enableEquipments, loadEquipment } from '../asset/equipmentSaga';
import { selectSelectedEquipmentWithServices } from '../asset/equipmentSelectors';
import { changeFrameProperty } from '../asset/frameReducer';
import { updateAssetPriceOL } from '../caseDetail/caseAssetSaga';
import { selectCalculationRequestCMFL } from '../caseDetail/caseAssetSelectors';
import { selectIsCasePrivate } from '../caseDetail/caseDetailSelectors';

import * as quoteApi from './quoteApi';
import { CMFL_FORM } from './quoteConstants';
import { responseMappers } from './quoteMapper';
import {
  recalculatePriceRequest,
  recalculatePriceResponse,
  updatePriceDeductionRequest,
  prefillNewCarsFlowEquipmentAndRedirect,
  saveQuoteCalculationsRequestOL,
  saveQuoteCalculationsResponseOL,
  calculateQuoteRequestOL,
  calculateQuoteRequestCMFL,
  calculateQuoteResponseCMFL,
  getInitialValuesCMFLRequest,
  getInitialValuesCMFLResponse,
  getResidualValuesCMFLRequest,
  getResidualValuesCMFLResponse,
} from './quoteReducer';
import { selectRecalculateRequest } from './quoteSelectors';

export function* saveCalculations({ payload } = {}) {
  try {
    yield put(recalculatePriceRequest());
    const requestBody = yield select(selectRecalculateRequest());
    yield call(updateAssetPriceOL, { requestBody });
    yield put(saveQuoteCalculationsResponseOL());
    const { redirect } = payload;
    if (typeof redirect === 'function') {
      yield call(redirect);
    }
    const currentAsset = yield select(selectAsset());
    yield put(changeAssetPreviousId({ id: currentAsset?.id }));
  } catch (e) {
    yield put(app.displayError(e));
    yield put(saveQuoteCalculationsResponseOL(e));
  }
}

export function* recalculatePrice({ payload = {} } = {}) {
  yield put(recalculatePriceRequest(payload));
  const requestBody = yield select(selectRecalculateRequest());
  yield call(recalculatePriceGeneric, { requestBody });
}

export function* recalculatePriceGeneric({ requestBody, formName }) {
  try {
    if (formName) {
      yield put(startSubmit(formName));
    }
    const isCasePrivate = yield select(selectIsCasePrivate());
    yield delay(1000);

    const { apiCall, responseMapper } = isCasePrivate
      ? {
          apiCall: quoteApi.calculateQuotePROL,
          responseMapper: caseDetail.responseMappers.mapQuoteDetailsPROL,
        }
      : {
          apiCall: quoteApi.calculateQuoteCMOL,
          responseMapper: caseDetail.responseMappers.mapQuoteDetailsCMOL,
        };
    const response = yield callWithAttachers({
      endpoint: apiCall,
      payload: { requestBody },
      attachers: [createAttacherForTermsToUse()],
    });
    yield put(
      recalculatePriceResponse(responseMapper(objectUtils.objectToCamelCase({ obj: response }))),
    );
  } catch (e) {
    yield put(recalculatePriceResponse(e));
    yield call(displayQuoteErrors, {
      fieldLevelError: errorUtils.mapResponseExceptionsToError({
        response: e,
        customExceptionMapper: uiErrorUtils.constructCustomErrorsToUIFieldsMapper(
          responseMappers.errorToUIFieldValues,
        ),
      }),
      formName,
    });
  }
}

export function* displayQuoteErrors({ fieldLevelError, formName }) {
  const { fieldErrors } = fieldLevelError;
  if (fieldErrors.length === 0) {
    const { message, reason } = fieldLevelError;
    yield put(stopSubmit(formName));
    yield put(app.displayError(reason || message));
  } else {
    yield put(
      stopSubmit(formName, {
        _error: fieldErrors,
      }),
    );
  }
}

let recalculatePriceGenericTask = null;
export function* recalculatePriceDeduction({ payload: { name, value, formName, invalid } }) {
  if (invalid) {
    if (recalculatePriceGenericTask) {
      yield cancel(recalculatePriceGenericTask);
    }
    yield put(recalculatePriceResponse(new Error('Form is invalid')));
    return;
  }
  yield put(recalculatePriceRequest());
  const requestBody = yield select(
    selectRecalculateRequest({
      [name]: value,
      commission: 0,
    }),
  );
  recalculatePriceGenericTask = yield fork(recalculatePriceGeneric, { requestBody, formName });
}

const mapEquipment = ({ optional }) =>
  optional
    .filter(({ category }) => category !== EQUIPMENT_CATEGORY_RETROFIT)
    .reduce(
      (prev, { id, equipmentPrice, category }) => ({
        ...prev,
        [id]: {
          id,
          category,
          equipmentPrice,
        },
      }),
      {},
    );

export function* prefillNewCarsFlowEq({ payload: { frameId, redirect } }) {
  yield put(changeFrameProperty({ property: 'id', value: frameId }));
  yield call(loadEquipment, { payload: { withoutRetrofit: true, frameId } });
  const equipment = yield select(selectSelectedEquipmentWithServices());
  yield call(enableEquipments, { payload: mapEquipment(equipment) });
  if (typeof redirect === 'function') yield call(redirect);
}

export function* calculateQuoteCMFL({ payload: { useDownPaymentPercentage, ignoreValidation } }) {
  try {
    yield put(startSubmit(CMFL_FORM));
    yield delay(1000);
    const isInvalidForm = yield select(isInvalid(CMFL_FORM));
    const formValues = yield select(getFormValues(CMFL_FORM));
    const allValidTerms = yield select(selectVendorTermsCMFL());
    if (
      (isInvalidForm && !ignoreValidation) ||
      !allValidTerms.some(({ vendorTermsId }) => vendorTermsId === formValues.vendorTermsId)
    ) {
      yield put(calculateQuoteResponseCMFL(new Error()));
      return;
    }
    const requestBody = yield select(
      selectCalculationRequestCMFL({ formValues, useDownPaymentPercentage }),
    );
    yield call(getResidualValuesCMFL, { payload: { withDelay: false, requestBody } });
    const response = yield callWithAttachers({
      endpoint: quoteApi.calculateQuoteCMFL,
      payload: {
        requestBody,
      },
      attachers: [createAttacherForTermsToUse()],
    });
    yield put(calculateQuoteResponseCMFL(caseDetail.responseMappers.mapQuoteCMFL(response)));
  } catch (e) {
    yield put(calculateQuoteResponseCMFL(e));
    yield call(displayQuoteErrors, {
      fieldLevelError: errorUtils.mapResponseExceptionsToError({
        response: e,
        customExceptionMapper: uiErrorUtils.constructCustomErrorsToUIFieldsMapper(
          responseMappers.errorToUIFieldValues,
        ),
      }),
      formName: CMFL_FORM,
    });
  }
}

export const getInitialValuesCMFL = createApiSaga({
  endpoint: quoteApi.getInitialValuesCMFL,
  attachers: [createAttacherForTermsToUse(PROCESS_VARIANCES.COMMERCIAL_FL)],
  responseMapper: responseMappers.mapInterestValuesCMFL,
  successActions: [getInitialValuesCMFLResponse],
  errorActions: [getInitialValuesCMFLResponse],
});

export function* getResidualValuesCMFL({
  payload: { withDelay = true, requestBody = null, callbackCalculation } = {},
}) {
  try {
    if (withDelay) {
      yield delay(1000);
    }
    const formValues = yield select(getFormValues(CMFL_FORM));
    // If no request body is provided, select formValues and create one
    const createRequestBody = !requestBody
      ? yield select(selectCalculationRequestCMFL({ formValues, useDownPaymentPercentage: true }))
      : requestBody;
    const rvValues = yield callWithAttachers({
      endpoint: quoteApi.getResidualValuesCMFL,
      payload: {
        requestBody: createRequestBody,
      },
      attachers: [createAttacherForTermsToUse(PROCESS_VARIANCES.COMMERCIAL_FL)],
    });
    yield put(getResidualValuesCMFLResponse(rvValues));

    if (typeof callbackCalculation === 'function') {
      yield put(calculateQuoteRequestCMFL({ ignoreValidation: true }));
      callbackCalculation();
    }
  } catch (e) {
    yield put(getResidualValuesCMFLResponse(e));
  }
}

export default function* quote() {
  yield takeLatest(changeSelectedEquipment, recalculatePrice);
  yield takeLatest(recalculatePriceEquipmentChange, recalculatePrice);
  yield takeLatest(calculateQuoteRequestOL, recalculatePrice);
  yield takeLatest(updatePriceDeductionRequest, recalculatePriceDeduction);
  yield takeLatest(prefillNewCarsFlowEquipmentAndRedirect, prefillNewCarsFlowEq);
  yield takeLatest(saveQuoteCalculationsRequestOL, saveCalculations);
  yield takeLatest(calculateQuoteRequestCMFL, calculateQuoteCMFL);
  yield takeLatest(getInitialValuesCMFLRequest, getInitialValuesCMFL);
  yield takeLatest(getResidualValuesCMFLRequest, getResidualValuesCMFL);
}
