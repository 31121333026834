import {
  caseDetail,
  EQUIPMENT_CATEGORY_COLOUR,
  EQUIPMENT_FORM_RETROFIT,
} from '@northstar/core-ui/modules';
import {
  isExteriorColour,
  isInteriorColour,
} from '@northstar/core-ui/modules/asset/equipmentUtils';
import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import shortId from 'shortid';

/**
 * Actions
 */
export const getEquipmentRequest = createAction('ASSET_GET_FRAME_EQUIPMENT_REQUEST');
export const getEquipmentResponse = createAction('ASSET_GET_FRAME_EQUIPMENT_RESPONSE');
export const createRetro = createAction('ASSET_CREATE_RETRO_EQUIPMENT');
export const removeRetro = createAction('ASSET_REMOVE_RETRO_EQUIPMENT');
export const enableEquipment = createAction('ASSET_ENABLE_EQUIPMENT');
export const enableEquipmentByIdWithPrice = createAction('ASSET_ENABLE_EQUIPMENT_BY_ID_WITH_PRICE');
export const changeSelectedEquipment = createAction('ASSET_EQUIPMENT_CHANGE');
export const resetSelectedEquipment = createAction('ASSET_RESET_SELECTED_EQUIPMENT');
export const clearEquipmentState = createAction('ASSET_EQUIPMENT_CLEAR_STATE');
export const enableEquipmentsByIds = createAction('ASSET_ENABLE_EQUIPMENTS_BY_IDS');
export const recalculatePriceEquipmentChange = createAction(
  'ASSET_RECALCULATE_PRICE_EQUIPMENT_CHANGE',
);
export const prefillEditableRetrofit = createAction('ASSET_PREFILL_EDITABLE_RETROFIT');

/**
 * Initial state
 */

const initialSelectedState = {
  optional: [],
  services: [],
};

const equipmentInitialState = {
  standard: [],
  additional: [],
  preconfigured: [],
  retrofit: [],
  colors: [],
};

export const initialState = fromJS({
  equipment: equipmentInitialState,
  selected: initialSelectedState,
  gettingEquipment: false,
});

/**
 * Actions handlers
 */
export default handleActions(
  {
    [createRetro](state) {
      return state.updateIn(['equipment', 'retrofit'], (list) =>
        list.push({
          id: shortId.generate(),
          name: '',
          price: '',
          dynamic: true,
        }),
      );
    },
    [removeRetro](state, { payload }) {
      return state.updateIn(['equipment', 'retrofit'], (list) =>
        fromJS(list.toJS().filter((item) => item.id !== payload.id)),
      );
    },
    [getEquipmentRequest](state) {
      return state.set('gettingEquipment', true);
    },
    [enableEquipment](state, { payload }) {
      return state.setIn(
        ['equipment', payload.category, payload.index, 'enabled'],
        payload.enabled,
      );
    },
    [enableEquipmentByIdWithPrice](state, { payload }) {
      const { category, id, equipmentPrice = 0 } = payload;
      const equipment = state.get('equipment').toJS();
      equipment[category].forEach((eq, i) => {
        if (eq.id === id) {
          equipment[category][i].enabled = true;
          equipment[category][i].equipmentPrice = equipmentPrice;
        }
      });
      return state.set('equipment', fromJS(equipment));
    },
    [getEquipmentResponse](state, { payload, error }) {
      return !error
        ? state.set('equipment', fromJS(payload)).set('gettingEquipment', false)
        : state.set('gettingEquipment', false);
    },
    [caseDetail.prefillEquipmentFromCase](state, { payload }) {
      const { selected, retrofit } = payload;
      const equipment = state.get('equipment').toJS();
      const isInCarConfigPage = JSON.stringify(equipmentInitialState) !== JSON.stringify(equipment);

      const filteredOptional = isInCarConfigPage
        ? selected.optional.filter(
            (eq) =>
              equipment.colors.some((colour) => colour.id === eq.id) ||
              equipment.additional.some((additional) => additional.id === eq.id) ||
              equipment.retrofit.some((retro) => retro.id === eq.id),
          )
        : selected.optional;

      return state
        .set('selected', fromJS({ ...selected, optional: filteredOptional }))
        .updateIn(['equipment', EQUIPMENT_FORM_RETROFIT], (retrofitBase) => {
          const list = retrofitBase.toJS();
          return fromJS(
            list.filter((item) => item.equipmentPrice && !item.dynamic).concat(retrofit),
          );
        });
    },
    [changeSelectedEquipment](state, { payload }) {
      const { category, key, value } = payload;
      return state.updateIn(['selected', category], (arr) => {
        if (value === null) {
          return arr.filter((item) => item && item.get('id') !== key);
        }

        if (isExteriorColour(value)) {
          return arr
            .filter(
              (item) =>
                item &&
                (item.get('category') !== EQUIPMENT_CATEGORY_COLOUR ||
                  isInteriorColour(item.toJS())),
            )
            .push(fromJS(value));
        }

        return arr.push(fromJS(value));
      });
    },
    [resetSelectedEquipment](state) {
      return state.set('selected', fromJS(initialSelectedState));
    },
    [clearEquipmentState]() {
      return initialState;
    },
  },
  initialState,
);
