import { Box, GlobalStyles, Theme } from '@mui/material';
import { ReactChild, Ref } from 'react';

import { styles } from './layout.styles';

interface ILayoutProps {
  header: ReactChild;
  body: ReactChild;
  footer: ReactChild;
  quoteRef: Ref<any>;
}

const Layout: React.FC<ILayoutProps> = ({ header, body, footer, quoteRef }) => (
  <>
    <GlobalStyles
      styles={({ colors: { custom } }: Theme) => ({
        body: {
          margin: 0,
          ...custom.quoteDocument,
        },
      })}
    />
    <Box sx={styles.container}>
      {header}
      <Box ref={quoteRef} sx={styles.bodyContainer}>
        <Box sx={styles.body}>{body}</Box>
      </Box>
      {footer}
    </Box>
  </>
);

export default Layout;
