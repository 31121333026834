"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  root: {
    flexGrow: 1
  },
  highlightsWrapper: {
    padding: '15px 0'
  }
};