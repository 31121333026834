"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectVehicleType = exports.selectIsAssetExistsInAssetCatalogueOpen = exports.selectAsset = exports.isSubmittingAsset = exports.isGettingAsset = void 0;
var _reselect = require("reselect");
var _vendorDashboardFrameSelectors = require("./vendorDashboardFrameSelectors");
const selectAssetState = state => state.vendorDashboard.asset;
const selectAsset = () => (0, _reselect.createSelector)(selectAssetState, state => state.get('asset').toJS());
exports.selectAsset = selectAsset;
const isSubmittingAsset = () => (0, _reselect.createSelector)(selectAssetState, state => state.get('submittingAsset'));
exports.isSubmittingAsset = isSubmittingAsset;
const isGettingAsset = () => (0, _reselect.createSelector)(selectAssetState, state => state.get('gettingAsset'));
exports.isGettingAsset = isGettingAsset;
const selectIsAssetExistsInAssetCatalogueOpen = () => (0, _reselect.createSelector)(selectAssetState, state => state.get('isAssetExistsInAssetCatalogueOpen'));
exports.selectIsAssetExistsInAssetCatalogueOpen = selectIsAssetExistsInAssetCatalogueOpen;
const selectVehicleType = () => (0, _reselect.createSelector)(selectAsset(), (0, _vendorDashboardFrameSelectors.selectFrame)(), (asset, frame) => frame.vehicleType || asset.vehicleType);
exports.selectVehicleType = selectVehicleType;