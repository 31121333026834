import { IMAFColors, ISxStyles } from '../../../theming/theme.type';

interface IProps {
  step: number;
  lastAvailableStep: number;
  lastStep?: number;
  isDisabled?: boolean;
}

export const useStyles = ({ step, lastAvailableStep, lastStep }: IProps): ISxStyles => {
  const getBorderBottomForStepInfo = (colors: IMAFColors) => {
    if (step === lastAvailableStep || step === lastStep) return 'none';
    return `1px solid ${colors.alpha(colors.solid.black, 0.1)}`;
  };
  return {
    step: ({ colors, spacing }) => ({
      display: 'flex',
      width: '100%',
      boxSizing: 'border-box',
      alignItems: 'center',
      padding: spacing(0, 2),
      height: 48,
      backgroundColor:
        step > lastAvailableStep ? colors.alpha(colors.solid.lightGray, 0.2) : colors.solid.white,
      cursor: !lastAvailableStep || step <= lastAvailableStep ? 'pointer' : 'initial',
      border: 'none',
      borderBottom: getBorderBottomForStepInfo(colors),
      textTransform: 'none',
      '&:hover': {
        backgroundColor:
          step <= lastAvailableStep
            ? colors.custom.stepper.inactive
            : colors.alpha(colors.solid.lightGray, 0.2),
      },
      '&:focus': {
        outline: 'none',
      },
    }),
    stepDisabled: {
      opacity: 0.5,
    },
    title: {
      marginLeft: 2,
    },
    stepInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 20,
      width: 20,
    },
    icon: ({ colors, fontSizes }) => ({
      fontSize: fontSizes.extraSmall,
      color: colors.danske.white,
      backgroundColor: colors.danske.primary100,
      padding: 0.5,
      borderRadius: '50%',
    }),
  };
};
