"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = caseSaga;
exports.getCaseForQuote = getCaseForQuote;
var _effects = require("redux-saga/effects");
var objectUtils = _interopRequireWildcard(require("../../utils/object-utils"));
var caseForQuoteAPI = _interopRequireWildcard(require("./caseForQuoteApi"));
var _caseForQuoteReducer = require("./caseForQuoteReducer");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function getCaseForQuote(_ref) {
  let {
    payload
  } = _ref;
  return function* () {
    try {
      const {
        hashId
      } = payload;
      const response = yield (0, _effects.call)(caseForQuoteAPI.getCaseForQuote, {
        hashId
      });
      const currentCase = objectUtils.objectToCamelCase({
        obj: response
      });
      yield (0, _effects.put)((0, _caseForQuoteReducer.getCaseForQuoteResponse)(currentCase));
    } catch (e) {
      yield (0, _effects.put)((0, _caseForQuoteReducer.getCaseForQuoteResponse)(e));
    }
  }();
}
function* caseSaga() {
  yield (0, _effects.takeLatest)(_caseForQuoteReducer.getCaseForQuoteRequest, getCaseForQuote);
}