"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.restoreCase = exports.getEmployees = exports.getCasesCount = exports.getCases = exports.deleteCase = exports.checkCaseIdentity = void 0;
var _core = require("@northstar/core");
var _requestHandler = _interopRequireDefault(require("../../utils/request-handler"));
const {
  userDomain,
  caseDomain,
  vendorCaseDomain
} = _requestHandler.default;
const getCases = body => {
  const correctRoute = _core.DomainUtils.getByExternalOrInternal(vendorCaseDomain.GET_OVERVIEW, caseDomain.GET_OVERVIEW);
  return correctRoute.call({
    body
  });
};
exports.getCases = getCases;
const getCasesCount = () => vendorCaseDomain.GET_COUNT.call();
exports.getCasesCount = getCasesCount;
const getEmployees = () => userDomain.GET_EMPLOYEES.call();
exports.getEmployees = getEmployees;
const deleteCase = _ref => {
  let {
    resourceId
  } = _ref;
  const correctRoute = _core.DomainUtils.getByExternalOrInternal(vendorCaseDomain.DELETE, caseDomain.DELETE);
  return correctRoute.call({
    urlParams: {
      resourceId
    }
  });
};
exports.deleteCase = deleteCase;
const restoreCase = _ref2 => {
  let {
    resourceId
  } = _ref2;
  const correctRoute = _core.DomainUtils.getByExternalOrInternal(vendorCaseDomain.RESTORE, caseDomain.RESTORE);
  return correctRoute.call({
    urlParams: {
      resourceId
    }
  });
};
exports.restoreCase = restoreCase;
const checkCaseIdentity = _ref3 => {
  let {
    resourceId
  } = _ref3;
  return vendorCaseDomain.CHECK_CASE_IDENTITY.call({
    urlParams: {
      resourceId
    }
  });
};
exports.checkCaseIdentity = checkCaseIdentity;