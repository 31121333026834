import { FC, ReactNode, MouseEvent, memo } from 'react';
import { TableCell, Box, SxProps, Theme } from '@mui/material';

import { handleCustomSx } from '../../../../utils';
import MAFCellIndicator from '../MAFCellIndicator/MAFCellIndicator';

import { styles } from './MAFCell.styles';
import { propTypes } from './MAFCell.propTypes';

interface IMAFCellProps {
  children: ReactNode;
  isHead?: boolean;
  isCompact?: boolean;
  isAligned?: boolean;
  sx?: SxProps<Theme>;
  color?: string;
  isUplift?: boolean;
  shouldStopPropagation?: boolean;
  handleItemClick?: () => void;
}

const MAFCell: FC<IMAFCellProps> = ({
  children,
  isHead,
  isCompact = false,
  isAligned = false,
  sx,
  color,
  isUplift = false,
  shouldStopPropagation,
  handleItemClick,
}) => {
  const rootClasses = [
    styles.root,
    isAligned && styles.aligned,
    Boolean(color) && styles.indicatorCell,
    ...handleCustomSx(sx),
  ];

  const underlineClasses = [styles.underline, isCompact && styles.compact];

  const contentClasses = [styles.content, Boolean(color) && styles.indicatorContent];

  const handleItemClickWithoutPropagation = (e: MouseEvent) => {
    e.stopPropagation();
    handleItemClick && handleItemClick();
  };

  return (
    <TableCell
      sx={[
        ...rootClasses,
        !isUplift ? styles.cellTypeBody : styles.cellTypeBodyUplift,
        !isUplift ? styles.headTypeBody : styles.headTypeBodyUplift,
      ]}
      onClick={shouldStopPropagation ? handleItemClickWithoutPropagation : handleItemClick}
    >
      {color && <MAFCellIndicator color={color} />}
      <Box sx={contentClasses}>{children}</Box>
      {isHead && <Box sx={underlineClasses} />}
    </TableCell>
  );
};

MAFCell.propTypes = propTypes;

// casting MAFCell type because React.memo does not infer it by default.
export default memo(MAFCell) as typeof MAFCell;
