const HappyFilled: React.FC<{}> = () => (
  <svg height="48px" width="48px" color="#003755" viewBox="-2 -2 24 24">
    <path
      d="M10,0 C15.523,0 20,4.477 20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 Z M14.3156836,13.0623857 C14.2519115,13.0997333 14.1258193,13.1687097 13.9466984,13.2587774 C13.6450423,13.4104597 13.3085928,13.5624447 12.9468442,13.704243 C11.9508372,14.0946578 10.9603624,14.3326973 10.0509797,14.3452036 L9.97724898,14.3457128 L9.97724898,14.3457128 C9.04955291,14.3457128 8.04194307,14.1060671 7.032329,13.705003 C6.67587472,13.5634034 6.34504922,13.4116409 6.04899291,13.2601996 C5.87329699,13.1703261 5.74990009,13.101552 5.68774121,13.0644138 C5.47224059,12.9356582 5.19316573,13.0059788 5.06441006,13.2214795 C4.9356544,13.4369801 5.00597508,13.716055 5.22147571,13.8448106 C5.30008062,13.8917749 5.44075801,13.9701801 5.63499357,14.0695372 C5.95548973,14.2334801 6.31216735,14.397102 6.69671349,14.5498609 C7.80424352,14.9898216 8.9183276,15.2547907 9.97724898,15.2547907 L10.0634806,15.2541956 L10.0634806,15.2541956 C11.0976311,15.2399734 12.1892584,14.9776241 13.2786075,14.550621 C13.6678121,14.3980606 14.0295294,14.2346613 14.3550894,14.0709594 C14.5522981,13.9717965 14.6952567,13.8935936 14.7750919,13.8468388 C14.9917127,13.7199767 15.0644764,13.4415288 14.9376143,13.2249081 C14.8107522,13.0082873 14.5323044,12.9355236 14.3156836,13.0623857 Z M14,7 C13.4477153,7 13,7.44771525 13,8 C13,8.55228475 13.4477153,9 14,9 C14.5522847,9 15,8.55228475 15,8 C15,7.44771525 14.5522847,7 14,7 Z M6,7 C5.44771525,7 5,7.44771525 5,8 C5,8.55228475 5.44771525,9 6,9 C6.55228475,9 7,8.55228475 7,8 C7,7.44771525 6.55228475,7 6,7 Z"
      id="Combined-Shape"
    ></path>
  </svg>
);

export default HappyFilled;
