import { PROCESS_VARIANCES } from '@northstar/core';
import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

import { DEFAULT_MIN_MILEAGE, DEFAULT_MAX_PERIOD } from './vendorTermsConstants';

export const getVendorTermsPROLRequest = createAction('VENDOR_GET_VENDOR_TERMS_PRIVATE_OL_REQUEST');
export const getVendorTermsPROLResponse = createAction(
  'VENDOR_GET_VENDOR_TERMS_PRIVATE_OL_RESPONSE',
);
export const getVendorTermsPRHPRequest = createAction('VENDOR_GET_VENDOR_TERMS_PRIVATE_HP_REQUEST');
export const getVendorTermsPRHPResponse = createAction(
  'VENDOR_GET_VENDOR_TERMS_PRIVATE_HP_RESPONSE',
);
export const getVendorTermsCMOLRequest = createAction(
  'VENDOR_GET_VENDOR_TERMS_COMMERCIAL_OL_REQUEST',
);
export const getVendorTermsCMOLResponse = createAction(
  'VENDOR_GET_VENDOR_TERMS_COMMERCIAL_OL_RESPONSE',
);
export const getVendorTermsCMFLRequest = createAction(
  'VENDOR_GET_VENDOR_TERMS_FINANCIAL_OL_REQUEST',
);
export const getVendorTermsCMFLResponse = createAction(
  'VENDOR_GET_VENDOR_TERMS_FINANCIAL_OL_RESPONSE',
);
export const updateSelectedLeasingTerm = createAction('VENDOR_LEASING_TERMS_UPDATE_SELECTED');
export const clearSelectedTerms = createAction('VENDOR_LEASING_CLEAR_STATE');
export const getVendorRequest = createAction('VENDOR_GET_VENDOR_REQUEST');
export const getVendorResponse = createAction('VENDOR_GET_VENDOR_RESPONSE');
export const setGettingTerms = createAction('VENDOR_SET_GETTING_TERMS');

export const setActiveTermsRequest = createAction('VENDOR_SET_ACTIVE_TERMS_REQUEST');
export const setActiveTermsResponse = createAction('VENDOR_SET_ACTIVE_TERMS_RESPONSE');
export const clearActiveTermsRequest = createAction('VENDOR_CLEAR_ACTIVE_TERMS_REQUEST');

export const defaultSelectedTerms = {
  mileage: DEFAULT_MIN_MILEAGE,
  period: DEFAULT_MAX_PERIOD,
};

export const initialState = fromJS({
  selected: defaultSelectedTerms,
  [PROCESS_VARIANCES.PRIVATE_OL]: [],
  [PROCESS_VARIANCES.PRIVATE_HP]: [],
  [PROCESS_VARIANCES.COMMERCIAL_OL]: [],
  [PROCESS_VARIANCES.COMMERCIAL_FL]: [],
  vendor: {
    brands: [],
    orderCompanies: [],
  },
  gettingTermsCMOL: false,
  gettingTermsCMFL: false,
  updatingTerms: false,
  gettingVendor: false,
  gettingServiceRates: false,
  activeTerms: {
    [PROCESS_VARIANCES.PRIVATE_OL]: null,
    [PROCESS_VARIANCES.PRIVATE_HP]: null,
    [PROCESS_VARIANCES.COMMERCIAL_OL]: null,
    [PROCESS_VARIANCES.COMMERCIAL_FL]: null,
  },
});

/**
 * Actions handlers
 */
export default handleActions(
  {
    [getVendorTermsPROLResponse](state, { payload, error }) {
      return error
        ? state.set(PROCESS_VARIANCES.PRIVATE_OL, [])
        : state.set(PROCESS_VARIANCES.PRIVATE_OL, fromJS(payload));
    },
    [updateSelectedLeasingTerm](state, { payload }) {
      return state.setIn(['selected', payload.key], payload.value);
    },
    [clearSelectedTerms](state, { payload = {} } = {}) {
      const { partialCleanup = false } = payload;
      return state.set(
        'selected',
        partialCleanup ? state.get('selected') : fromJS(defaultSelectedTerms),
      );
    },
    [getVendorRequest](state) {
      return state.set('gettingVendor', true);
    },
    [getVendorResponse](state, { payload, error }) {
      return error
        ? state.set('gettingVendor', false)
        : state.set('gettingVendor', false).set('vendor', fromJS(payload));
    },
    [getVendorTermsPRHPResponse](state, { payload, error }) {
      return error
        ? state.set(PROCESS_VARIANCES.PRIVATE_HP, [])
        : state.set(PROCESS_VARIANCES.PRIVATE_HP, fromJS(payload));
    },
    [getVendorTermsCMOLRequest](state) {
      return state.set('gettingTermsCMOL', true);
    },
    [getVendorTermsCMOLResponse](state, { payload, error }) {
      return error
        ? state.set(PROCESS_VARIANCES.COMMERCIAL_OL, []).set('gettingTermsCMOL', false)
        : state
            .set(PROCESS_VARIANCES.COMMERCIAL_OL, fromJS(payload))
            .set('gettingTermsCMOL', false);
    },
    [getVendorTermsCMFLRequest](state) {
      return state.set('gettingTermsCMFL', true);
    },
    [getVendorTermsCMFLResponse](state, { payload, error }) {
      return error
        ? state.set(PROCESS_VARIANCES.COMMERCIAL_FL, []).set('gettingTermsCMFL', false)
        : state
            .set(PROCESS_VARIANCES.COMMERCIAL_FL, fromJS(payload))
            .set('gettingTermsCMFL', false);
    },
    [setGettingTerms](state, { payload }) {
      return state.set('gettingTermsCMOL', payload).set('gettingTermsCMFL', payload);
    },
    [clearActiveTermsRequest](state) {
      return state.set('activeTerms', initialState.get('activeTerms'));
    },
    [setActiveTermsResponse](state, { payload }) {
      return state
        .setIn(['activeTerms', payload.financialType], payload.value)
        .set('selected', fromJS(defaultSelectedTerms));
    },
  },
  initialState,
);
