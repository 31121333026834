import { FC, useEffect } from 'react';

import {
  isSelectedConsentLevelStatistical,
  initializeAdobeAnalytics,
  IMAFAdobeAnalyticsParams,
} from '../track';

interface IMAFTrackingProps extends IMAFAdobeAnalyticsParams {
  /**
   * *Typescript TIP*: If you want to specify your cookie consent
   * levels, cast the value.
   */
  cookieConsent: Nullable<number>;
}

const MAFTracking: FC<IMAFTrackingProps> = ({
  cookieConsent,
  appMeasurementSrc,
  visitorApiSrc,
}) => {
  useEffect(() => {
    if (isSelectedConsentLevelStatistical()) {
      initializeAdobeAnalytics({
        appMeasurementSrc,
        visitorApiSrc,
      });
    }
  }, [cookieConsent]);
  return null;
};

export default MAFTracking;
