import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  Icon: PropTypes.oneOfType([
    PropTypes.oneOf([
      'animate',
      'animateMotion',
      'animateTransform',
      'defs',
      'desc',
      'foreignObject',
      'g',
      'metadata',
      'mpath',
      'symbol',
      'svg',
      'switch',
      'view',
    ]),
    PropTypes.func,
  ]),
  onClick: PropTypes.func,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
} as any;
