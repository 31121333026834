import { useState } from 'react';

import { TMAFTextFieldOnChangeEvent, TMAFTextFieldOnFocusEvent } from '../../MAFTextField';
import MAFTextFieldWithIcon, { IMAFTextFieldWithIconProps } from '../MAFTextFieldWithIcon';
import { Phone } from '../../Icons';

import { propTypes } from './MAFPhoneField.propTypes';

export interface IMAFPhoneFieldProps extends IMAFTextFieldWithIconProps {
  phonePrefix: string;
}

const MAFPhoneField: React.FC<IMAFPhoneFieldProps> = ({
  value,
  onChange,
  phonePrefix,
  customHelperText,
  ...rest
}) => {
  const [fieldValue, setFieldValue] = useState(value);

  const handleOnFocus = (e: TMAFTextFieldOnFocusEvent) => {
    if (!e.target.value) {
      setFieldValue(phonePrefix);
      e.target.setSelectionRange(1, 1);
    }
  };

  const handleOnChange = (e: TMAFTextFieldOnChangeEvent) => {
    setFieldValue(e.target.value);
    onChange && onChange(e);
  };

  return (
    <MAFTextFieldWithIcon
      icon={<Phone />}
      iconPosition="start"
      {...rest}
      placeholder={phonePrefix}
      value={fieldValue || value}
      onFocus={handleOnFocus}
      onChange={handleOnChange}
      customHelperText={customHelperText}
    />
  );
};

MAFPhoneField.propTypes = propTypes;

export default MAFPhoneField;
