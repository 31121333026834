"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AssetCatalogue", {
  enumerable: true,
  get: function () {
    return _AssetCatalogue.default;
  }
});
Object.defineProperty(exports, "DeletedAssets", {
  enumerable: true,
  get: function () {
    return _DeletedAssets.default;
  }
});
Object.defineProperty(exports, "FrameForm", {
  enumerable: true,
  get: function () {
    return _FrameForm.default;
  }
});
Object.defineProperty(exports, "InsuranceRates", {
  enumerable: true,
  get: function () {
    return _InsuranceRates.default;
  }
});
Object.defineProperty(exports, "ServiceRates", {
  enumerable: true,
  get: function () {
    return _ServiceRates.default;
  }
});
Object.defineProperty(exports, "SideBar", {
  enumerable: true,
  get: function () {
    return _SideBar.default;
  }
});
Object.defineProperty(exports, "UserAdministration", {
  enumerable: true,
  get: function () {
    return _UserAdministration.default;
  }
});
Object.defineProperty(exports, "VendorTerms", {
  enumerable: true,
  get: function () {
    return _VendorTerms.default;
  }
});
Object.defineProperty(exports, "WinterTiresRates", {
  enumerable: true,
  get: function () {
    return _WinterTiresRates.default;
  }
});
var _SideBar = _interopRequireDefault(require("./SideBar/SideBar"));
var _AssetCatalogue = _interopRequireDefault(require("./AssetCatalogue/AssetCatalogue"));
var _FrameForm = _interopRequireDefault(require("./AssetCatalogue/assetCatalogue/FrameForm/FrameForm"));
var _UserAdministration = _interopRequireDefault(require("./UserAdministration/UserAdministration"));
var _ServiceRates = _interopRequireDefault(require("./ServiceRates/ServiceRates"));
var _InsuranceRates = _interopRequireDefault(require("./InsuranceRates/InsuranceRates"));
var _WinterTiresRates = _interopRequireDefault(require("./WinterTiresRates/WinterTiresRates"));
var _VendorTerms = _interopRequireDefault(require("./VendorTerms/VendorTerms"));
var _DeletedAssets = _interopRequireDefault(require("./DeletedAssets/DeletedAssets"));