"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: {
    paddingTop: '35px',
    width: '100%'
  },
  highlightsWrapper: {
    padding: '15px 0'
  },
  text: _ref => {
    let {
      colors
    } = _ref;
    return {
      color: colors.custom.fontPrimary,
      lineHeight: '19px',
      fontWeight: 500
    };
  }
};