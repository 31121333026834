import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

import { styles } from './MAFCellIndicator.styles';
import { propTypes } from './MAFCellIndicator.propTypes';

interface IMAFCellIndicatorProps {
  color?: string;
  children?: ReactNode;
}

const MAFCellIndicator: FC<IMAFCellIndicatorProps> = ({ color, children }) => {
  const cellColors = [
    color === 'primaryCM' && styles.primaryCM,
    color === 'primaryPR' && styles.primaryPR,
    Boolean(color) && styles.indicator,
  ];

  return <Box sx={cellColors}>{children}</Box>;
};

MAFCellIndicator.propTypes = propTypes;

export default MAFCellIndicator;
