"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setCurrentVendorVendorId = exports.initialVendorDashboardAppState = exports.default = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
/**
 * Actions
 */
const setCurrentVendorVendorId = exports.setCurrentVendorVendorId = (0, _reduxActions.createAction)('VENDOR_DASHBOARD_SET_CURRENT_VENDOR_ID');

/**
 * Initial state
 */
const initialVendorDashboardAppState = exports.initialVendorDashboardAppState = (0, _immutable.fromJS)({
  currentDashboardVendorId: null
});

/**
 * Actions handlers
 */
var _default = exports.default = (0, _reduxActions.handleActions)({
  // Cases count
  [setCurrentVendorVendorId](state, _ref) {
    let {
      payload
    } = _ref;
    return state.set('currentDashboardVendorId', Number(payload));
  }
}, initialVendorDashboardAppState);