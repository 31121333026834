"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NOTIFICATIONS_API_BASE = exports.SELF_API_BASE = exports.AUTHENTICATED_USER_API_BASE = exports.CREDIT_API_BASE = exports.EMAIL_API_BASE = exports.ASSET_API_BASE = exports.AUTH_API_BASE = exports.KYC_API_BASE = exports.VENDOR_CASE_API_BASE = exports.CASE_HANDLER_API_BASE = exports.HTTP_METHODS = void 0;
var tslib_1 = require("tslib");
var ObjectUtils_1 = require("../ObjectUtils");
var Route_1 = require("./Route");
exports.HTTP_METHODS = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete',
    HTTP_UNSUPPORTED: 'HTTP_UNSUPPORTED',
};
var baseApiEnv = process.env.API_ENV ? process.env.API_ENV + "_" : '';
exports.CASE_HANDLER_API_BASE = process.env[baseApiEnv + "CASE_HANDLER_API_BASE"];
exports.VENDOR_CASE_API_BASE = process.env[baseApiEnv + "VENDOR_CASE_API_BASE"];
exports.KYC_API_BASE = process.env[baseApiEnv + "KYC_API_BASE"];
exports.AUTH_API_BASE = process.env[baseApiEnv + "AUTH_API_BASE"];
exports.ASSET_API_BASE = process.env[baseApiEnv + "ASSET_API_BASE"];
exports.EMAIL_API_BASE = process.env[baseApiEnv + "EMAIL_API_BASE"];
exports.CREDIT_API_BASE = process.env[baseApiEnv + "CREDIT_API_BASE"];
exports.AUTHENTICATED_USER_API_BASE = process.env[baseApiEnv + "AUTHENTICATED_USER_API_BASE"];
exports.SELF_API_BASE = process.env.SELF_API_BASE;
exports.NOTIFICATIONS_API_BASE = process.env.NOTIFICATIONS_API_BASE;
var unsupportedEndpoint = function () {
    throw new Error('Cannot call this endpoint');
};
var RouteConstants = (function () {
    function RouteConstants() {
        this.domains = {
            authDomainConstants: {
                LOGIN: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return '/api/login'; },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                LOGOUT: new Route_1.default({
                    rabbitExchange: 'LOGOUT_INTERNAL',
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/logout'; },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                CHANGE_PASSWORD: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/changepassword'; },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                CHECK_SESSION: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return '/auth-session'; },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                GET_CUSTOMER_ID: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function () { return exports.AUTHENTICATED_USER_API_BASE + "/default-ashe/connect/userinfo"; },
                }),
                ASHE_LOGIN_OAUTH: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () {
                        return process.env.REACT_APP_BANK_ID_REDIRECT_URL || '/api/oauth2/authorization/ashe';
                    },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                ASHE_LOGOUT: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return '/api/v1/auth/perform_logout'; },
                    buildUrlExternal: unsupportedEndpoint,
                }),
            },
            userDomainConstants: {
                GET_THEME: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return "/api/theme"; },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                AD_LOGIN: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/ad/login'; },
                    buildUrlExternal: function () { return exports.AUTH_API_BASE + "/basic-user/ad"; },
                }),
                AD_LOOKUP: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/basic-user/" + params.username + "/ad";
                    },
                }),
                FORGOT_PASSWORD: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/api/users/forgot-password'; },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/basic-user/" + params.username + "/forgot-password";
                    },
                }),
                FORGOT_PASSWORD_VALIDATE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/api/users/forgot-password/validate'; },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/basic-user/" + params.username + "/forgot-password/validate";
                    },
                }),
                CREATE_VENDOR: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function () { return exports.AUTH_API_BASE + "/vendors"; },
                }),
                GET_VENDOR: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/vendors/" + params.vendorId;
                    },
                }),
                GET_VENDOR_LIST: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function () { return exports.AUTH_API_BASE + "/vendors"; },
                }),
                VERIFY_USER: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/api/users/verify'; },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/basic-user/" + params.username + "/verify" + ObjectUtils_1.ObjectUtils.serialize({
                            confirmation_token: params.confirmation_token,
                        });
                    },
                }),
                _VERIFY_USER_DONE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.HTTP_UNSUPPORTED,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: unsupportedEndpoint,
                }),
                _CREATE_APPSEC_USER: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.HTTP_UNSUPPORTED,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: unsupportedEndpoint,
                }),
                _CREATE_APPSEC_USER_DONE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.HTTP_UNSUPPORTED,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: unsupportedEndpoint,
                }),
                GET_EMPLOYEES: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return '/api/employees'; },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                GET_USERS_TO_REAUTHORIZE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return '/api/users-to-reauthorize'; },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                GET_USERS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/users" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/basic-user" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                CREATE_USER: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/api/users'; },
                    buildUrlExternal: function () { return exports.AUTH_API_BASE + "/basic-user"; },
                }),
                GET_USER: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/users/single-user" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/basic-user/" + params.username;
                    },
                }),
                GET_USER_BY_CUSTOMER_ID: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/basic-user/by-customer-id/" + params.customerId;
                    },
                }),
                UPDATE_USER: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/users/single-user" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/basic-user/" + params.username;
                    },
                }),
                GET_CURRENT_USER: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return '/api/current-users'; },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                EDIT_USER: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function (params) {
                        return "/api/users/single-user" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/basic-user/" + params.username;
                    },
                }),
                _GET_ROLE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/roles/" + params.base_type + "/" + params.sub_type;
                    },
                }),
                REGENERATE_CONFIRMATION_TOKEN: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/users/single-user/regenerate-token" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/basic-user/" + params.username + "/regenerate-token";
                    },
                }),
                UPDATE_LAST_VISIT: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/basic-user/" + params.username + "/update-last-visit";
                    },
                }),
                GET_ALL_IDENTITIES: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/identities" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                REAUTHORIZE_IDENTITIES: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/api/identities/reauthorize'; },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/identities/reauthorize" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                ADD_IDENTITY: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/api/user/identities'; },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/basic-user/" + params.username + "/identities";
                    },
                }),
                UPDATE_IDENTITY: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function () { return '/api/user/identities'; },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/basic-user/" + params.username + "/identities";
                    },
                }),
                UPDATE_IDENTITIES: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function () { return '/api/identities'; },
                    buildUrlExternal: function () { return exports.AUTH_API_BASE + "/identities"; },
                }),
                DELETE_IDENTITY: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.DELETE,
                    buildUrl: function (params) {
                        return "/api/user/identities" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/basic-user/" + params.username + "/identities/" + params.identityId;
                    },
                }),
                DELETE_IDENTITIES: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.DELETE,
                    buildUrl: function (params) {
                        return "/api/identities" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/identities" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
            },
            featureDomainConstants: {
                GET_FEATURES_VENDOR: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendors/features" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/vendors/" + params.vendorId;
                    },
                }),
                UPDATE_FEATURE_VENDOR: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function (params) {
                        return "/api/vendors/features" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/vendors/" + params.vendorId + "/features";
                    },
                }),
            },
            vendorCaseDomainConstants: {
                GET_SINGLE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/cases/single-case" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (_a) {
                        var resourceId = _a.resourceId, params = tslib_1.__rest(_a, ["resourceId"]);
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + resourceId + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                SEND_QUOTE_DOCUMENT: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/send-quote-document" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                RESTORE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/restore" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + params.resourceId + "/restoreCase";
                    },
                }),
                CANCEL_CASE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/internal/" + params.resourceId + "/cancel-case";
                    },
                }),
                CHECK_CASE_IDENTITY: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/cases/checkCaseIdentity" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                CREATE_QUOTE_DOCUMENT_LINK: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/quote-document-link" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                GET_DOCUMENT: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/documents/" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + params.resourceId + "/" + params.documentType + "/documents";
                    },
                }),
                GET_OVERVIEW: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/api/cases/overview'; },
                    buildUrlExternal: function () { return exports.VENDOR_CASE_API_BASE + "/cases/overview/pages"; },
                }),
                CASE_GET_PUBLIC_NOTES: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/notes/public" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/notes/" + params.type + "/" + params.key;
                    },
                }),
                GET_SINGLE_UNAUTHORIZED: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/cases/single-case-unauthorized" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (_a) {
                        var resourceId = _a.resourceId, params = tslib_1.__rest(_a, ["resourceId"]);
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + resourceId + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                DELETE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.DELETE,
                    buildUrl: function (params) {
                        return "/api/cases/delete" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + params.resourceId;
                    },
                }),
                CREATE_DRAFT_SCENARIO: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/draft" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + params.resourceId + "/create-draft-scenario";
                    },
                }),
                GET_COUNT: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return '/api/cases/count'; },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + params.vendor_id + "/count";
                    },
                }),
                CREATE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function () { return exports.VENDOR_CASE_API_BASE + "/cases"; },
                }),
                UPDATE_APPLICANTS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/single-case/applicant" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + params.resourceId + "/applicant";
                    },
                }),
                UPDATE_APPLICANTS_CM: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/single-case/commercial-operational-lease/sme/applicant" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/SE/commercial/sme/" + params.resourceId + "/applicant";
                    },
                }),
                UPDATE_LEGAL_ENTITY_CM: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/single-case/commercial-operational-lease/legal-entity" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/SE/commercial/" + params.resourceId + "/applicant/legal-entity";
                    },
                }),
                UPDATE_ASSET_PR_OL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/single-case/private-operational-lease/asset" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/SE/private/operational-leasing/" + params.resourceId + "/asset";
                    },
                }),
                UPDATE_ASSET_PR_HP: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/single-case/private-hire-purchase/asset" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/SE/private/high-purchase/" + params.resourceId + "/asset";
                    },
                }),
                UPDATE_ASSET_CM_OL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/single-case/commercial-operational-lease/asset" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/SE/commercial/operational/" + params.resourceId + "/asset";
                    },
                }),
                UPDATE_ASSET_CM_FL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/single-case/commercial-financial-lease/asset" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/SE/commercial/financial/" + params.resourceId + "/asset";
                    },
                }),
                UPDATE_DETAILS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function (params) {
                        return "/api/cases/single-case/case-details" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + params.resourceId + "/case-details" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                UPDATE_IMPORTER: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/single-case/importer" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + params.resourceId + "/partner" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                INITIATE_CREDIT: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/single-case/credit-decision" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (_a) {
                        var resourceId = _a.resourceId, params = tslib_1.__rest(_a, ["resourceId"]);
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + resourceId + "/credit-decision" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                INITIATE_SIGNING: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/single-case/signing-case" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + params.resourceId + "/signing-case";
                    },
                }),
                SWITCH_FLOW: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/single-case/switch-flow" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + params.resourceId + "/switch-flow";
                    },
                }),
                SEND_DOCUMENT: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/documents/send-as-email" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + params.resourceId + "/documents/send-as-email";
                    },
                }),
                SEND_AFFORDABILITY_QUOTE_DOCUMENT: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/documents/send-affordability-quote" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + params.resourceId + "/documents/send-affordability-quote";
                    },
                }),
                SET_ASSET_DELIVERED: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/set-asset-delivered" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/SE/private/high-purchase/" + params.resourceId + "/set-asset-delivered";
                    },
                }),
                SET_REFERENCE_NUMBER_PRHP: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/private-high-purchase/invoice-reference-number" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/SE/private/high-purchase/" + params.resourceId + "/invoice-reference-number";
                    },
                }),
                SET_REFERENCE_NUMBER_PROL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/private-operational-lease/invoice-reference-number" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/SE/private/operational-leasing/" + params.resourceId + "/invoice-reference-number";
                    },
                }),
                CASE_CREATE_PUBLIC_NOTE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return "/api/notes/public"; },
                    buildUrlExternal: function () { return exports.VENDOR_CASE_API_BASE + "/notes"; },
                }),
                CREDIT_GET_STATUS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/cases/credit-application" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + params.resourceId + "/credit-application/" + params.creditApplicationId;
                    },
                }),
                GET_CUSTOMER_FULL_NAME: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/cases/customer/full-name" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/customer/" + params.externalCustomerId + "/customer-contacts" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                FINANCIER_ADD_GUARANTOR: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/vendor-case/add-guarantors" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/internal/" + params.resourceId + "/add-guarantors";
                    },
                }),
                INTERNAL_REMOVE_GUARANTORS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.DELETE,
                    buildUrl: function (params) {
                        return "/api/vendor-case/remove-guarantors" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/internal/" + params.resourceId + "/remove-guarantors";
                    },
                }),
                SUBMIT_KYC_DEMO: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/vendor-case/kyc-demo" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/SE/commercial/" + params.resourceId + "/kyc-demo";
                    },
                }),
                GET_NOTES: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendor-case/notes" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/notes/" + params.key;
                    },
                }),
                PROCEED_FROM_CUSTOMER_DATA_GATHERING_STEP_DEMO: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/vendor-case/proceed-customer-data-gathering-demo" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.VENDOR_CASE_API_BASE + "/cases/" + params.resourceId + "/proceed-customer-data-gathering-demo";
                    },
                }),
            },
            caseDomainConstants: {
                GET_OVERVIEW: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/api/internal/cases/overview'; },
                    buildUrlExternal: function () { return exports.CASE_HANDLER_API_BASE + "/cases/overview/pages"; },
                }),
                CREDIT_GET_STATUS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/internal/cases/credit-application" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CASE_HANDLER_API_BASE + "/cases/" + params.resourceId + "/credit-application/" + params.creditApplicationId;
                    },
                }),
                GET_SINGLE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/internal/cases/single-case" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (_a) {
                        var resourceId = _a.resourceId, params = tslib_1.__rest(_a, ["resourceId"]);
                        return exports.CASE_HANDLER_API_BASE + "/cases/" + resourceId + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                DELETE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.DELETE,
                    buildUrl: function (params) {
                        return "/api/internal/cases/delete" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CASE_HANDLER_API_BASE + "/cases/" + params.resourceId;
                    },
                }),
                RESTORE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/internal/cases/restore" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CASE_HANDLER_API_BASE + "/cases/" + params.resourceId + "/restoreCase";
                    },
                }),
                CANCEL_CASE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/internal/cases/cancel" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CASE_HANDLER_API_BASE + "/cases/" + params.resourceId + "/cancel-credit-application";
                    },
                }),
                GET_DOCUMENT: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/internal/documents/" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CASE_HANDLER_API_BASE + "/cases/" + params.resourceId + "/" + params.documentType + "/documents";
                    },
                }),
                CREATE_QUOTE_DOCUMENT_LINK: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/internal/quote-document-link" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                FINANCIER_VERIFY: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/financier-verification" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CASE_HANDLER_API_BASE + "/cases/" + params.resourceId + "/financier-verification";
                    },
                }),
                FINANCIER_ADD_GUARANTOR: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/financier-add-guarantor" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CASE_HANDLER_API_BASE + "/cases/SE/commercial/" + params.resourceId + "/guarantors";
                    },
                }),
                FINANCIER_RETRIGGER_SIGNING_CASE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/retrigger-signing-case" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CASE_HANDLER_API_BASE + "/" + params.resourceId + "/retrigger-signing-case";
                    },
                }),
                CASE_GET_ALL_SCENARIOS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/cases/scenarios" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CASE_HANDLER_API_BASE + "/cases/" + params.resourceId + "/scenarios";
                    },
                }),
                CASE_GET_INTERNAL_NOTES: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/notes/internal" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CASE_HANDLER_API_BASE + "/notes/" + params.type + "/" + params.key;
                    },
                }),
                CASE_GET_PUBLIC_NOTES: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/internal/notes/public" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CASE_HANDLER_API_BASE + "/notes/" + params.type + "/" + params.key;
                    },
                }),
                CASE_CREATE_PUBLIC_NOTE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return "/api/internal/notes/public"; },
                    buildUrlExternal: function () { return exports.CASE_HANDLER_API_BASE + "/notes"; },
                }),
                CASE_CREATE_INTERNAL_NOTE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return "/api/notes/internal"; },
                    buildUrlExternal: function () { return exports.CASE_HANDLER_API_BASE + "/notes"; },
                }),
                CREDIT_ACTIVATE_DELIVERY: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/cases/processed-contracts" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CASE_HANDLER_API_BASE + "/processed-contracts/" + params.resourceId;
                    },
                }),
            },
            creditDomainConstants: {
                CREDIT_GET_REPORT: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/credit/credit-application/client-report" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CREDIT_API_BASE + "/rams/full-report/" + params.creditApplicationId;
                    },
                }),
                CREDIT_GET_DECISION: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/credit/credit-application/decision" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CREDIT_API_BASE + "/credit-application/" + params.creditApplicationId + "/decision";
                    },
                }),
                CREDIT_GET_DETAILS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/credit/credit-application/details" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CREDIT_API_BASE + "/credit-application/" + params.creditApplicationId + "/details";
                    },
                }),
                CREDIT_SET_MANUAL_CREDIT_DECISION: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/credit/credit-application/set-manual-credit-decision" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CREDIT_API_BASE + "/credit-application/" + params.creditApplicationId + "/" + params.creditDecision;
                    },
                }),
                CREDIT_GET_RETRY_AML: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/credit/credit-application/retry/aml" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CREDIT_API_BASE + "/credit-services/" + params.creditApplicationId + "/" + params.customerType + "/AML_SCORING";
                    },
                }),
                CREDIT_GET_RETRY_EXCLUSION_MARKS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/credit/credit-application/retry/exclusion-marks" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CREDIT_API_BASE + "/credit-services/" + params.creditApplicationId + "/" + params.customerType + "/EXCLUSION_MARKS";
                    },
                }),
                CREDIT_GET_RETRY_RISK_EVENTS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/credit/credit-application/retry/risk-events" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CREDIT_API_BASE + "/credit-services/" + params.creditApplicationId + "/" + params.customerType + "/RISK_EVENTS";
                    },
                }),
                CREDIT_GET_RETRY_CUSTOMER_CLASSIFICATION: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/credit/credit-application/retry/customer-classification" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.CREDIT_API_BASE + "/credit-services/" + params.creditApplicationId + "/" + params.customerType + "/CUSTOMER_CLASSIFICATION";
                    },
                }),
            },
            kycDomainConstants: {
                CREATE_KYC_LINK: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return "/api/kyc-link"; },
                    buildUrlExternal: function (params) {
                        return exports.KYC_API_BASE + "/kyc/cases/" + params.organisationNumber + "/link/send";
                    },
                }),
                UPDATE_KYC: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return "/api/kyc"; },
                    buildUrlExternal: function (params) {
                        return exports.KYC_API_BASE + "/kyc/cases/" + params.organisationNumber + "/" + params.tokenId;
                    },
                }),
                GET_KYC_STATUS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/kyc-status" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.KYC_API_BASE + "/kyc/cases/" + params.organisationNumber + "/status";
                    },
                }),
                GET_KYC_EXPIRATION: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/kyc-expiration" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.KYC_API_BASE + "/kyc/cases/" + params.organisationNumber + "/check-kyc-expiration";
                    },
                }),
                VALIDATE_KYC_INFORMATION: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/kyc-validation" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.KYC_API_BASE + "/kyc/cases/" + params.organisationNumber + "/link/validate/" + params.tokenId;
                    },
                }),
            },
            vendorDomainConstants: {
                GET_VENDOR_LIST: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendor-list" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/vendors"; },
                }),
                CREATE_VENDOR: new Route_1.default({
                    rabbitExchange: 'CREATE_VENDOR',
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/api/vendors'; },
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/vendors"; },
                }),
                DELETE_VENDOR: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.DELETE,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId;
                    },
                }),
                GET_VENDOR: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId;
                    },
                }),
                GET_VENDOR_ORDER_COMPANIES: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendors/vendor-order-companies" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                UPDATE_VENDOR: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/api/vendors/single-vendor'; },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId;
                    },
                }),
                UPDATE_VENDOR_INTERNAL_INFORMATION: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function () { return '/api/vendors/single-vendor-internal'; },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/vendors/" + params.vendorId;
                    },
                }),
                UPDATE_VENDOR_PROCESS_CONFIGURATION: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function () { return '/api/vendors/single-vendor-process-variances'; },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/vendors/" + params.vendorId + "/process-configuration";
                    },
                }),
                GET_ALL_VENDOR_TERMS_PR_HP: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor/terms/hire-purchase-all" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/hire_purchase" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                GET_VENDOR_TERMS_HP: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor/terms/hire-purchase" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/hire_purchase/default";
                    },
                }),
                UPDATE_VENDOR_TERMS_HP_ADMIN: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor/terms/admin/hire-purchase" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/hire_purchase/" + params.vendorTermsId;
                    },
                }),
                CREATE_VENDOR_TERMS_HP_ADMIN: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor/terms/admin/hire-purchase" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/hire_purchase";
                    },
                }),
                GET_VENDOR_TERMS_PL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor/terms/private-lease" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/private_leasing/default";
                    },
                }),
                GET_ALL_VENDOR_TERMS_PR_OL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor/terms/private-lease-all" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/private_leasing" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                UPDATE_VENDOR_TERMS_PL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor/terms/private-lease" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/private_leasing/" + params.vendorTermsId;
                    },
                }),
                CREATE_VENDOR_TERMS_PL_ADMIN: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor/terms/admin/private-lease" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/private_leasing";
                    },
                }),
                GET_ALL_VENDOR_TERMS_CM_OL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor/terms/commercial-operational-leasing-all" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/commercial_operational_leasing" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                GET_VENDOR_TERMS_CM_OL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor/terms/commercial-operational-lease" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/commercial_operational_leasing/default";
                    },
                }),
                CREATE_VENDOR_TERMS_CM_OL_ADMIN: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor/terms/admin/commercial-operational-lease" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/commercial_operational_leasing";
                    },
                }),
                UPDATE_VENDOR_TERMS_CM_OL_ADMIN: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor/terms/admin/commercial-operational-lease" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/commercial_operational_leasing/" + params.vendorTermsId;
                    },
                }),
                GET_ALL_VENDOR_TERMS_CM_FL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor/terms/commercial-financial-leasing-all" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/commercial_financial_leasing" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                GET_VENDOR_TERMS_CM_FL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor/terms/commercial-financial-lease" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/commercial_financial_leasing/default";
                    },
                }),
                CREATE_VENDOR_TERMS_CM_FL_ADMIN: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor/terms/admin/commercial-financial-lease" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/commercial_financial_leasing";
                    },
                }),
                UPDATE_VENDOR_TERMS_CM_FL_ADMIN: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function (params) {
                        return "/api/vendors/single-vendor/terms/admin/commercial-financial-lease" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/commercial_financial_leasing/" + params.vendorTermsId;
                    },
                }),
                GET_VENDOR_SERVICE_NAMES: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendors/service-names" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/services";
                    },
                }),
                GET_VENDOR_SERVICE_RATES_MATRIX: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendors/services" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/services/" + params.matrixName;
                    },
                }),
                CREATE_VENDOR_SERVICE_RATES_MATRIX: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/vendors/services" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/services";
                    },
                }),
                UPDATE_VENDOR_SERVICE_RATES_MATRIX: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PUT,
                    buildUrl: function (params) {
                        return "/api/vendors/services" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/services";
                    },
                }),
                DELETE_VENDOR_SERVICE_RATES_MATRIX: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.DELETE,
                    buildUrl: function (params) {
                        return "/api/vendors/services" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/" + params.vendorId + "/services/" + params.matrixName;
                    },
                }),
                GET_THEME: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendors/theme" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                UPDATE_THEME: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/api/vendors/update-theme'; },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                GET_ALL_PROCESS_CONFIGURATIONS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return '/api/vendors/configuration'; },
                    buildUrlExternal: function () { return exports.AUTH_API_BASE + "/configuration"; },
                }),
                GET_SYSTEMS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return '/api/systems'; },
                    buildUrlExternal: function () { return exports.AUTH_API_BASE + "/systems"; },
                }),
            },
            frameDomainConstants: {
                GET_FILTERS: new Route_1.default({
                    internalHttpMethod: exports.HTTP_METHODS.POST,
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/frames/filters" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/frames/filters" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                FIND_FRAME: new Route_1.default({
                    internalHttpMethod: exports.HTTP_METHODS.POST,
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/frames" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/frames" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                BULK_UPDATE_FRAMES: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/frames/rv/bulk-update"; },
                }),
                PRESCORE_FRAMES: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/frames/prescore-frames" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                GET_EQUIPMENT: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/equipment" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/equipment" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                CREATE_EQUIPMENT: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PUT,
                    buildUrl: function () { return '/api/equipment'; },
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/equipment"; },
                }),
                GET_FRAME: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/frame" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/frames/" + params.frameId;
                    },
                }),
                GET_FRAME_VAN: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/lcv/frame" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/lcv/frames/" + params.frameId;
                    },
                }),
                GET_FILTERS_VAN: new Route_1.default({
                    internalHttpMethod: exports.HTTP_METHODS.POST,
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/lcv/frames/filters" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/lcv/frames/filters" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                FIND_FRAME_VAN: new Route_1.default({
                    internalHttpMethod: exports.HTTP_METHODS.POST,
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/lcv/frames" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/lcv/frames" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                BULK_UPDATE_FRAMES_VAN: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/lcv/frames/rv/bulk-update"; },
                }),
                PRESCORE_FRAMES_VAN: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/lcv/frames/prescore-frames" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                GET_EQUIPMENT_VAN: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/lcv/equipment" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/lcv/equipment" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                CREATE_EQUIPMENT_VAN: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PUT,
                    buildUrl: function () { return '/api/lcv/equipment'; },
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/lcv/equipment"; },
                }),
            },
            productDomainConstants: {
                GET_PRODUCT_TERMS_PL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/terms/private-leasing" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/product/private_leasing/" + params.countryCode;
                    },
                }),
                UPDATE_PRODUCT_TERMS_PL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function (params) {
                        return "/api/terms/private-leasing" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/product/private_leasing/" + params.countryCode;
                    },
                }),
                GET_PRODUCT_TERMS_CM_OL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/terms/commercial_operational_leasing" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/product/commercial_operational_leasing/" + params.countryCode;
                    },
                }),
                UPDATE_PRODUCT_TERMS_CM_OL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function (params) {
                        return "/api/terms/commercial_operational_leasing" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/product/commercial_operational_leasing/" + params.countryCode;
                    },
                }),
                GET_INIT_VALUES_CMFL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/quote/commercial-financial-leasing/init-values" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/quote/commercial_financial_leasing/init_values" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                GET_PRODUCT_TERMS_CM_FL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/terms/commercial_financial_leasing" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/product/commercial_financial_leasing/" + params.countryCode;
                    },
                }),
                UPDATE_PRODUCT_TERMS_CM_FL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function (params) {
                        return "/api/terms/commercial_financial_leasing" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/product/commercial_financial_leasing/" + params.countryCode;
                    },
                }),
                GET_INIT_VALUES_HP: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/quote/hire-purchase/init-values" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/quote/hire_purchase/init_values" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                GET_PRODUCT_TERMS_HP: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/terms/hire-purchase" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/product/hire_purchase/" + params.countryCode;
                    },
                }),
                UPDATE_PRODUCT_TERMS_HP: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function (params) {
                        return "/api/terms/hire-purchase" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/product/hire_purchase/" + params.countryCode;
                    },
                }),
            },
            assetDomainConstants: {
                GET_BRANDS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return '/api/assets/brands'; },
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/brands"; },
                }),
                GET_BRAND: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/assets/brand" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/brands/" + params.brand_id;
                    },
                }),
                GET_MODELS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/models" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                GET_FILTERS_PRIVATE: new Route_1.default({
                    internalHttpMethod: exports.HTTP_METHODS.POST,
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/assets/private/filters" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/assets/private/filters" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                GET_FILTERS_COMMERCIAL: new Route_1.default({
                    internalHttpMethod: exports.HTTP_METHODS.POST,
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/assets/commercial/filters" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/assets/commercial/filters" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                GET_LIST_VENDOR_PRIVATE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/assets/vendor/private" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/assets/private" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                GET_LIST_ADMIN_PRIVATE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/assets/admin/private" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/assets/private" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                GET_LIST_VENDOR_COMMERCIAL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/assets/vendor/commercial" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/assets/commercial" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                GET_LIST_ADMIN_COMMERCIAL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/assets/admin/commercial" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/assets/commercial" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                GET_LIST_FINANCIER_PRIVATE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/assets/financier/private" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/financier/private" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                GET_LIST_FINANCIER_COMMERCIAL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/assets/financier/commercial" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/financier/commercial" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                GET_LIST_COUNT_FINANCIER: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return "/api/assets/financier/count"; },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                CREATE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/assets" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/assets"; },
                }),
                GET_ASSET_PRIVATE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/assets/private/single-asset" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/assets/" + params.assetId + "/private";
                    },
                }),
                GET_ASSET_COMMERCIAL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/assets/commercial/single-asset" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/assets/" + params.assetId + "/commercial";
                    },
                }),
                GET_ASSET_FINANCIER_PRIVATE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/assets/private/single-asset/financier" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/financier/" + params.assetId + "/private" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                GET_ASSET_FINANCIER_COMMERCIAL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/assets/commercial/single-asset/financier" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/financier/" + params.assetId + "/commercial" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                GET_ASSET_CAR_INFO: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/assets/single-asset/car-info" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/car/info/plate/" + params.plateNumber + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                UPDATE_ASSET_PATCH: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function (params) {
                        return "/api/assets/single-asset" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/assets/" + params.assetId;
                    },
                }),
                DELETE_HARD_ASSET: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.DELETE,
                    buildUrl: function (params) {
                        return "/api/assets/single-asset" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/assets/" + params.assetId;
                    },
                }),
                CALCULATE_PR_OL_QUOTE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/quotes/private-leasing" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/quote/private_leasing"; },
                }),
                SEEK_DISCOUNT_PR_OL_QUOTE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/quote/private_leasing/discount-seek"; },
                }),
                CALCULATE_PR_HP_QUOTE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/quotes/hire-purchase" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/quote/hire_purchase"; },
                }),
                CALCULATE_CM_OL_QUOTE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/quotes/commercial-operational-leasing" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/quote/commercial_operational_leasing"; },
                }),
                SEEK_DISCOUNT_CM_OL_QUOTE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function () {
                        return exports.ASSET_API_BASE + "/quote/commercial_operational_leasing/discount-seek";
                    },
                }),
                CALCULATE_CM_FL_QUOTE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/quotes/commercial-financial-leasing" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/quote/commercial_financial_leasing"; },
                }),
                GET_RV_RANGE_CM_FL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/commercial-financial-leasing/residual-value-range" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/quote/commercial_financial_leasing"; },
                }),
                GET_RV_MATRICES_NAMES: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return "/api/assets/rv-matrices-names"; },
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/residual_value_coefficients"; },
                }),
                GET_RV_MATRIX: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/assets/rv-matrix" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/residual_value_coefficients/" + params.matrixName;
                    },
                }),
                CREATE_RV_MATRIX: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/api/assets/rv-matrix'; },
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/residual_value_coefficients"; },
                }),
                UPDATE_RV_MATRIX: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PUT,
                    buildUrl: function () { return '/api/assets/rv-matrix'; },
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/residual_value_coefficients"; },
                }),
                DELETE_RV_MATRIX: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.DELETE,
                    buildUrl: function (params) {
                        return "/api/assets/rv-matrix" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/residual_value_coefficients/" + params.matrixName;
                    },
                }),
                EVALUATOR_UPDATE_PRICING: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/assets/single-asset/pricing" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/financier/" + params.assetId;
                    },
                }),
                EVALUATOR_APPROVE_ASSET: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/assets/single-asset/approve" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/financier/" + params.assetId + "/approve";
                    },
                }),
                EVALUATOR_DECLINE_ASSET: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/assets/single-asset/decline" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/financier/" + params.assetId + "/decline";
                    },
                }),
                CHECK_ASSET_IDENTITY: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/assets/check-asset-identity" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: unsupportedEndpoint,
                }),
            },
            supportDomainConstants: {
                GET_SYSTEM_MESSAGE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return "/api/support/admin/message"; },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/system-message/" + params.messageType;
                    },
                }),
                GET_SYSTEM_MESSAGE_ACTIVE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return "/api/support/message"; },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                UPDATE_SYSTEM_MESSAGE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return "/api/support/admin/message"; },
                    buildUrlExternal: function (params) {
                        return exports.AUTH_API_BASE + "/system-message/" + params.messageType;
                    },
                }),
            },
            notificationsDomainConstants: {
                GET_ALL_NOTIFICATIONS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return '/api/notifications'; },
                    buildUrlExternal: function (params) {
                        return exports.NOTIFICATIONS_API_BASE + "/notifications" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                READ_NOTIFICATIONS: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/api/notifications'; },
                    buildUrlExternal: unsupportedEndpoint,
                }),
            },
            emailDomainConstants: {
                SEND_EMAIL: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: unsupportedEndpoint,
                    buildUrlExternal: function () { return exports.EMAIL_API_BASE + "/email"; },
                }),
            },
            featureToggleDomainConstants: {
                GET_ALL_FEATURE_TOGGLES: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function () { return '/api/feature-toggles'; },
                    buildUrlExternal: unsupportedEndpoint,
                }),
                UPDATE_FEATURE_TOGGLE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function () { return '/api/set-one-feature'; },
                    buildUrlExternal: unsupportedEndpoint,
                }),
            },
            insuranceDomainConstants: {
                INSURANCE_GET_NAMES: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendors/insurances" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/insurances" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
                INSURANCE_CREATE_NEW: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.POST,
                    buildUrl: function (params) {
                        return "/api/vendors/insurances" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function () { return exports.ASSET_API_BASE + "/vendors/insurances"; },
                }),
                INSURANCE_GET_ONE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.GET,
                    buildUrl: function (params) {
                        return "/api/vendors/insurances/single" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/insurances/" + params.matrixName;
                    },
                }),
                INSURANCE_DELETE_ONE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.DELETE,
                    buildUrl: function (params) {
                        return "/api/vendors/insurances/single" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/insurances/" + params.matrixName;
                    },
                }),
                INSURANCE_PATCH_ONE: new Route_1.default({
                    httpMethod: exports.HTTP_METHODS.PATCH,
                    buildUrl: function (params) {
                        return "/api/vendors/insurances/single" + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                    buildUrlExternal: function (params) {
                        return exports.ASSET_API_BASE + "/vendors/insurances/" + params.matrixName + ObjectUtils_1.ObjectUtils.serialize(params);
                    },
                }),
            },
        };
    }
    RouteConstants.prototype.bindApiAgent = function (apiAgent) {
        Object.values(this.domains).forEach(function (domain) {
            return Object.values(domain).forEach(function (route) { return route.bindApiAgent(apiAgent); });
        });
    };
    return RouteConstants;
}());
exports.default = RouteConstants;
