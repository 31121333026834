"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapResponseExceptionsToError = exports.mapGeneralExceptionsToReasoningError = exports.mapExceptionsToFieldErrors = exports.groupErrorsByProperty = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _StringUtils = require("@northstar/core/utils/StringUtils");
var _stringUtils = require("./string-utils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const mapExceptionsToFieldErrors = exceptions => exceptions.filter(_ref => {
  let {
    field_name: fieldName
  } = _ref;
  return fieldName;
}).map(_ref2 => {
  let {
    field_name: fieldName,
    message
  } = _ref2;
  return {
    fieldName: (0, _StringUtils.snakeToCamelCase)(fieldName),
    message: (0, _stringUtils.capitalizeFirstLetter)(message)
  };
});

// If exception does not have field_name, that is considored as general exception
exports.mapExceptionsToFieldErrors = mapExceptionsToFieldErrors;
const mapGeneralExceptionsToReasoningError = exceptions => exceptions.filter(_ref3 => {
  let {
    field_name: fieldName
  } = _ref3;
  return !fieldName;
}).map(_ref4 => {
  let {
    message
  } = _ref4;
  return message;
}).join('\n');
exports.mapGeneralExceptionsToReasoningError = mapGeneralExceptionsToReasoningError;
const mapResponseExceptionsToError = _ref5 => {
  let {
    response,
    formName,
    formSectionName,
    customExceptionMapper
  } = _ref5;
  if (response.httpResponse) {
    const {
      exceptions = [],
      reason
    } = response.httpResponse;
    const fieldErrors = customExceptionMapper ? customExceptionMapper(exceptions) : mapExceptionsToFieldErrors(exceptions);
    const error = new Error(reason);
    error.fieldErrors = fieldErrors;
    error.reason = mapGeneralExceptionsToReasoningError(exceptions);
    error.formName = formName;
    error.formSectionName = formSectionName;
    return error;
  }
  return undefined;
};
exports.mapResponseExceptionsToError = mapResponseExceptionsToError;
const groupErrorsByProperty = errors => errors.reduce((prev, _ref6) => {
  let {
    fieldName,
    message
  } = _ref6;
  const [property, name] = fieldName.split('.');
  const prevPropertyValue = prev[property] || [];
  return _objectSpread(_objectSpread({}, prev), {}, {
    [property]: [...prevPropertyValue, {
      fieldName: name,
      message
    }]
  });
}, {});
exports.groupErrorsByProperty = groupErrorsByProperty;