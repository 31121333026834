"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  options: {
    display: 'flex',
    justifyContent: 'center'
  },
  btn: _ref => {
    let {
      typography: {
        main
      }
    } = _ref;
    return {
      '&.MuiButton-root': {
        justifyContent: 'flex-start',
        textAlign: 'left',
        height: 'auto',
        padding: '20px 24px',
        margin: '5px 0px',
        fontFamily: main
      }
    };
  },
  btnRoundTop: {
    '&.MuiButton-root': {
      borderRadius: '4px 4px 0px 0px'
    }
  },
  textField: {
    '&.MuiTextField-root': {
      width: '100%',
      margin: '-5px 0px 15px 0px'
    }
  },
  input: _ref2 => {
    let {
      colors
    } = _ref2;
    return {
      '&.MuiInput-root': _objectSpread({
        height: 56
      }, colors.custom.choosable)
    };
  }
};