import { Box, Typography, useTheme } from '@mui/material';

import { IMAFTypographyContainerProps, IMAFTypographyProps } from './MAFTypography.types';

const Container: React.FC<IMAFTypographyContainerProps> = ({
  isWithContainer,
  containerProps,
  children,
}) => (isWithContainer ? <Box {...containerProps}>{children}</Box> : <>{children}</>);

const MAFTypography: React.FC<IMAFTypographyProps> = ({
  isWithContainer,
  children,
  containerProps,
  fontSize,
  fontFamily,
  ref,
  component,
  ...rest
}) => {
  const theme = useTheme();

  const fontSizeOrUndefined = fontSize ? theme?.fontSizes[fontSize] : undefined;

  return (
    <Container isWithContainer={isWithContainer} containerProps={containerProps}>
      {component ? (
        <Typography component={component} {...rest} fontSize={fontSizeOrUndefined}>
          {children}
        </Typography>
      ) : (
        <Typography ref={ref} {...rest} fontSize={fontSizeOrUndefined}>
          {children}
        </Typography>
      )}
    </Container>
  );
};

export default MAFTypography;
