import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  root: {
    '&.MuiAutocomplete-root': {
      marginBottom: '20px',
    },
  },
  icon: ({ colors }) => ({
    '& .MuiAutocomplete-popupIndicator, .MuiAutocomplete-popupIndicatorOpen': {
      color: colors.custom.fontPrimary,
      transform: 'none',
      marginRight: 1,
    },
  }),
  multiValueLabel: ({ colors, typography: { baseFont }, fontSizes: { small } }) => ({
    color: colors.alpha(colors.custom.fontPrimary, 0.4),
    ...baseFont,
    textAlign: 'left',
    fontSize: small,
  }),
  paper: ({ colors }) => ({
    '&.MuiAutocomplete-paper': {
      ...colors.custom.inputFull.input,
      borderRadius: 0,
      marginTop: '12px',
      zIndex: 1,
      overflow: 'visible',
      '&:before': {
        left: '85%',
        position: 'relative',
        display: 'block',
        content: '""',
        transform: 'rotate(45deg)',
        top: '10px',
        marginTop: '-17px',
        width: '8px',
        height: '8px',
        border: colors.custom.inputFull.input.border,
        borderBottom: `8px solid ${colors.custom.inputFull.input.background}`,
        borderRightColor: 'transparent',
        backgroundColor: colors.custom.inputFull.input.background,
      },
    },
  }),
  noOptions: ({ colors, typography: { main }, fontSizes: { small } }) => ({
    '& .MuiAutocomplete-noOptions': {
      color: colors.custom.fontPrimary,
      fontFamily: main,
      fontSize: small,
      letterSpacing: '0.82px',
      padding: '12px 16px',
    },
  }),
  option: ({ colors, typography: { main }, fontSizes: { small }, spacing }) => ({
    '& .MuiAutocomplete-option': {
      color: colors.custom.fontPrimary,
      fontFamily: main,
      fontSize: small,
      letterSpacing: '0.82px',
      minHeight: `${spacing(6)} !important`,
      zIndex: 1,
      position: 'relative',
      '&[aria-selected=true]': colors.custom.dropDown.selected,
    },
  }),
  listBox: ({ colors }) => ({
    '& .MuiAutocomplete-listbox': {
      overflow: 'auto',
      maxHeight: '200px',
      padding: 0,
      margin: 0,
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        backgroundColor: colors.custom.contentAlternative.main.color,
      },
    },
  }),
  input: {
    '& .MuiAutocomplete-input': {
      marginTop: '-10px',
    },
  },
  textField: {
    '&.MuiInput-root': {
      minHeight: '48px',
      height: 'auto',
      paddingBottom: '1px',
    },
  },
  arrowDownIcon: {
    width: '16px',
    height: '16px',
    padding: '5px',
  },
  clearIndicator: ({ colors }) => ({
    '& .MuiAutocomplete-clearIndicator': {
      color: colors.custom.dropDown.selected.color,
      '& svg': {
        visibility: 'visible',
      },
    },
  }),
};
