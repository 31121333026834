import { SxProps, Theme } from '@mui/material';

import MAFButton from '../../../MAFButton/MAFButton';
import MAFDoubleButtons from '../../../MAFDoubleButtons/MAFDoubleButtons';
import {
  IMAFCookiesConsentButtonText,
  TMAFCookiesConsentHandleAcceptParams,
} from '../../MAFCookiesConsent.types';
import { handleCustomSx } from '../../../../utils';

import { styles } from './ExpandedButtons.styles';
import { propTypes } from './ExpandedButtons.propTypes';

interface IExpandedButtonsProps {
  isStrict?: boolean;
  level?: number;
  buttonText: IMAFCookiesConsentButtonText;
  handleAccept: (params: TMAFCookiesConsentHandleAcceptParams) => void;
  buttonClass?: SxProps<Theme>;
  highestMandatoryLevel: number;
  highestLevel: number;
}

const ExpandedButtons: React.FC<IExpandedButtonsProps> = ({
  isStrict,
  level,
  buttonText,
  handleAccept,
  buttonClass,
  highestMandatoryLevel,
  highestLevel,
}) => {
  const handleAcceptNone = () => handleAccept({ shouldAcceptAll: false });

  return isStrict ? (
    <MAFDoubleButtons
      sx={styles.doubleButtonContainer}
      secondaryButtonProps={{
        color: 'secondary',
        onClick: handleAcceptNone,
        sx: [styles.buttonSecondary, ...handleCustomSx(buttonClass)],
      }}
      secondaryButtonText={
        level === highestMandatoryLevel ? buttonText.agreeMandatory : buttonText.agreeSome
      }
      primaryButtonProps={{
        color: 'primaryPR',
        onClick: () => handleAccept({ shouldAcceptAll: true }),
        sx: buttonClass,
      }}
      primaryButtonText={buttonText.agreeAll}
    />
  ) : (
    <MAFButton color="primaryPR" sx={buttonClass} onClick={handleAcceptNone}>
      {level === highestLevel ? buttonText.agreeAll : buttonText.agreeSome}
    </MAFButton>
  );
};

ExpandedButtons.propTypes = propTypes;

export default ExpandedButtons;
