import { select } from 'redux-saga/effects';

import { selectCase } from './caseDetailSelectors';

export function* attachDraftScenarioId(payload = {}) {
  const { draftScenarioId } = yield select(selectCase());
  return {
    ...payload,
    draftScenarioId,
  };
}
