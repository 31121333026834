import { Box, SxProps, Theme } from '@mui/material';

import { handleCustomSx } from '../../utils';

import { styles } from './MAFText.styles';
import { propTypes } from './MAFText.propTypes';

export type TMAFTextFontFamily = 'main' | 'title';

export type TMAFTextFontSize =
  | 'tiny'
  | 'extraSmall'
  | 'small'
  | 'medium'
  | 'extraMedium'
  | 'large'
  | 'extraLarge'
  | 'huge'
  | 'giant';

export type TMAFTextFontWeigth = 600 | '600' | 'bold' | 'normal' | 400;

export type TMAFTextFontStyle = 'italic';

export type TMAFTextColor = 'primary';

export type TMAFTextTextAlign = 'left' | 'right' | 'center' | 'justify';

export interface IMAFTextProps {
  fontFamily?: TMAFTextFontFamily;
  fontSize?: TMAFTextFontSize;
  fontWeight?: TMAFTextFontWeigth;
  fontStyle?: TMAFTextFontStyle;
  color?: TMAFTextColor;
  textAlign?: TMAFTextTextAlign;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  isWithContainer?: boolean;
}

interface IContainer {
  isWithContainer?: boolean;
  children?: React.ReactNode;
  sx: SxProps<Theme>;
}

const Container: React.FC<IContainer> = ({ isWithContainer, children, sx, ...props }) =>
  isWithContainer ? (
    <Box sx={sx} {...props}>
      {children}
    </Box>
  ) : (
    <Box component="span" {...props} sx={[styles.span, ...handleCustomSx(sx)]}>
      {children}
    </Box>
  );

const MAFText: React.FC<IMAFTextProps> = ({
  sx,
  fontFamily,
  fontSize,
  fontWeight,
  fontStyle,
  color,
  textAlign,
  children,
  isWithContainer = false,
  ...rest
}) => {
  const rootStyles = [
    styles.default,
    fontFamily === 'main' && styles.fontMain,
    fontFamily === 'title' && styles.fontTitle,
    fontSize === 'tiny' && styles.tiny,
    fontSize === 'extraSmall' && styles.extraSmall,
    fontSize === 'small' && styles.small,
    fontSize === 'medium' && styles.medium,
    fontSize === 'extraMedium' && styles.extraMedium,
    fontSize === 'large' && styles.large,
    fontSize === 'extraLarge' && styles.extraLarge,
    fontSize === 'huge' && styles.huge,
    fontSize === 'giant' && styles.giant,
    (fontWeight === '600' || fontWeight === 600 || fontWeight === 'bold') && styles.bold,
    fontStyle === 'italic' && styles.italic,
    color === 'primary' && styles.primary,
    textAlign === 'left' && styles.leftAlignment,
    textAlign === 'right' && styles.rightAlignment,
    textAlign === 'center' && styles.centerAlignment,
    textAlign === 'justify' && styles.justifyAlignment,
    ...handleCustomSx(sx),
  ];

  return (
    <Container isWithContainer={isWithContainer} sx={rootStyles} {...rest}>
      {children}
    </Container>
  );
};

MAFText.propTypes = propTypes;

export default MAFText;
