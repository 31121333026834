import { FC, ReactNode, MouseEvent } from 'react';
import { TableCell, Box, SxProps, Theme } from '@mui/material';

import { handleCustomSx } from '../../../../utils';
import DeprecatedMAFCellIndicator from '../DeprecatedMAFCellIndicator/DeprecatedMAFCellIndicator';

import { styles } from './DeprecatedMAFCell.styles';
import { propTypes } from './DeprecatedMAFCell.propTypes';

interface IMAFCellProps {
  children: ReactNode;
  isHead?: boolean;
  isCompact?: boolean;
  isAligned?: boolean;
  sx?: SxProps<Theme>;
  color?: string;
  isUplift?: boolean;
  shouldStopPropagation?: boolean;
}

const DeprecatedMAFCell: FC<IMAFCellProps> = ({
  children,
  isHead,
  isCompact = false,
  isAligned = false,
  sx,
  color,
  isUplift = false,
  shouldStopPropagation,
}) => {
  const rootClasses = [
    styles.root,
    isAligned && styles.aligned,
    Boolean(color) && styles.indicatorCell,
    ...handleCustomSx(sx),
  ];

  const underlineClasses = [styles.underline, isCompact && styles.compact];

  const contentClasses = [styles.content, Boolean(color) && styles.indicatorContent];

  const propagationStop = (e: MouseEvent) => e.stopPropagation();

  return (
    <TableCell
      sx={[
        ...rootClasses,
        !isUplift ? styles.cellTypeBody : styles.cellTypeBodyUplift,
        !isUplift ? styles.headTypeBody : styles.headTypeBodyUplift,
      ]}
      onClick={shouldStopPropagation ? propagationStop : undefined}
    >
      {color && <DeprecatedMAFCellIndicator color={color} />}
      <Box sx={contentClasses}>{children}</Box>
      {isHead && <Box sx={underlineClasses} />}
    </TableCell>
  );
};

DeprecatedMAFCell.propTypes = propTypes;

export default DeprecatedMAFCell;
