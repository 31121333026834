import { useEffect, useRef, MouseEventHandler, MouseEvent } from 'react';
import { useTheme, useMediaQuery, SxProps, Theme } from '@mui/material';
import numeral from 'numeral';

import { TSxProp } from '../theming/theme.type';

export const isScrolledToBottom = (): boolean => {
  const scrollTop =
    (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
  const scrollHeight =
    (document.documentElement && document.documentElement.scrollHeight) ||
    document.body.scrollHeight;
  const clientHeight = document.documentElement.clientHeight || window.innerHeight;
  return Math.ceil(scrollTop + clientHeight) >= scrollHeight;
};

export const isScrolledToElementBottom = (element?: Nullable<Element>): boolean => {
  if (element) {
    const scrollTop = element.scrollTop;
    const scrollHeight = element.scrollHeight;
    const clientHeight = element.clientHeight;
    return Math.ceil(scrollTop + clientHeight) >= scrollHeight;
  }
  return false;
};

export const useScreenSize = (): {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
} => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'), { noSsr: true }) && !isMobile;
  const isDesktop = !isMobile && !isTablet;
  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};

export const useThrottle = (callback: MouseEventHandler<HTMLButtonElement>, delay: number) => {
  const latestCallback = useRef<MouseEventHandler<HTMLButtonElement>>();
  const latestTimeout = useRef<ReturnType<typeof setTimeout>>();
  const isReady = useRef(true);

  useEffect(() => {
    latestCallback.current = callback;
  }, [callback]);

  return (props: MouseEvent<HTMLButtonElement>) => {
    if (isReady.current) {
      if (delay === 0) {
        if (latestCallback && latestCallback.current) latestCallback.current(props);
      } else {
        if (latestCallback && latestCallback.current) latestCallback.current(props);
        isReady.current = false;
        clearTimeout(latestTimeout.current as unknown as number);
        latestTimeout.current = setTimeout(() => {
          isReady.current = true;
        }, delay);
      }
    }
  };
};

export const convertToNumberWithoutDots = (value: string): number | string => {
  const valueWithoutDots = value.replace ? value.replace(/\./g, '') : value;
  return Number.isNaN(Number(valueWithoutDots)) || valueWithoutDots === ''
    ? value
    : Number(valueWithoutDots);
};

export const formatValueToMoneyFormat = (value: string | number): string | number => {
  if (!value && value !== 0) return '';

  return Number.isNaN(Number(value)) ? value : numeral(value).format('0,0');
};

export const formatSafeValueToMoney = (
  value: number | string,
  defaultValue?: number,
): number | string => {
  if (defaultValue !== undefined && !value && value !== 0) {
    return Number(defaultValue);
  }
  const passedValue = value > Number.MAX_SAFE_INTEGER ? Number.MAX_SAFE_INTEGER : value;
  return formatValueToMoneyFormat(passedValue);
};

/**
 * Checks if value is undefined or null
 *
 * @param value any value
 *
 * @returns boolean - that represents whether `value` is undefined/null
 */
const isNullOrUndefined = (value: any): boolean => typeof value === 'undefined' || value === null;

/**
 * Check if a value is undefined, null or empty
 *
 * @param value any value
 *
 * @returns boolean - that represents whether the `value` is undefined/null/empty
 */
const isNullOrEmpty = (value: any): boolean =>
  typeof value === 'number'
    ? isNullOrUndefined(value)
    : isNullOrUndefined(value) || Object.keys(value).length === 0;

export const ObjectUtils = {
  isNullOrEmpty,
  isNullOrUndefined,
};

export const handleCustomSx = (style?: SxProps<Theme> | SxProps<Theme>[]): TSxProp[] => {
  if (!style) return [];
  return Array.isArray(style) ? (style as TSxProp[]) : [style as TSxProp];
};
