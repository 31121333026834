import { caseDetail, LEGAL_ENTITY_SOLE_TRADER } from '@northstar/core-ui/modules';
import { fromJS } from 'immutable';
import { handleActions, createAction, combineActions } from 'redux-actions';

export const clearCreditState = createAction('CREDIT_CLEAR_STATE');
export const clearPrefillDebtors = createAction('CLEAR_PREFILL_DEBTORS');
export const updateApplicants = createAction('CREDIT_UPDATE_APPLICANTS');
export const submitApplicantionRequest = createAction('CREDIT_SUBMIT_APPLICANT_REQUEST');

export const nextStep = createAction('CREDIT_NEXT_STEP');
export const getCustomerFullNameRequest = createAction('CREDIT_GET_CUSTOMER_FULL_NAME_REQUEST');
export const getCustomerFullNameResponse = createAction('CREDIT_GET_CUSTOMER_FULL_NAME_RESPONSE');
export const resetApplicantFullName = createAction('CREDIT_RESET_APPLICANT_FULL_NAME');
export const setLegalEntity = createAction('CREDIT_LEGAL_ENTITY');
export const setIsWithCoApplicant = createAction('CREDIT_SET_IS_WITH_CO_APPLICANT');
export const setResourceWaitingForDecision = createAction(
  'CREDIT_SET_RESOURCE_WAITING_FOR_DECISION',
);
export const checkApplicationStatusPollRequest = createAction(
  'CREDIT_CHECK_APPLICATION_POLL_REQUEST',
);
export const checkIfCreditDecisionExpiredRequest = createAction(
  'CREDIT_CHECK_IF_CREDIT_DECISION_EXPIRED_REQUEST',
);
export const checkIfCreditDecisionExpiredResponse = createAction(
  'CREDIT_CHECK_IF_CREDIT_DECISION_EXPIRED_RESPONSE',
);

const initialDebtor = {
  contactPerson: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  company: {
    name: '',
    companyNumber: '',
  },
  legalEntity: '',
  externalCustomerId: '',
  nationalities: [],
  downpaymentSource: '',
  maritalStatus: '',
  affordabilityDataResponse: null,
  soleTraderDetails: null,
};

const initialPrefillDebtor = {
  contactPerson: {
    firstName: '',
    lastName: '',
  },
  companyAddress: '',
  externalCustomerId: '',
};

const INITIAL_STEP = 0;

export const initialState = fromJS({
  debtor: initialDebtor,
  coDebtor: initialDebtor,
  prefillDebtor: initialPrefillDebtor,
  prefillCoDebtor: initialPrefillDebtor,
  error: null,
  step: INITIAL_STEP,
  sendingToDsp: false,
  sendingDecision: false,
  successfullDecision: false,
  fetchingCustomerInformation: false,
  currentLegalEntity: LEGAL_ENTITY_SOLE_TRADER,
  isWithCoApplicant: false,
  resourceWaitingForDecision: null,
  checkingIfCreditDecisionExpired: false,
  isCreditDecisionExpired: false,
  isUpdatingApplicants: false,
});

export const updateApplicantState = ({ currentDebtorState, data }) => {
  const {
    contactPerson,
    legalEntity,
    externalCustomerId,
    company,
    nationalities,
    companyAddress,
    downpaymentSource,
    soleTraderDetails,
  } = data;
  return currentDebtorState
    .set('contactPerson', contactPerson)
    .set('legalEntity', legalEntity)
    .set('externalCustomerId', externalCustomerId)
    .set('company', company)
    .set('nationalities', nationalities)
    .set('companyAddress', companyAddress)
    .set('downpaymentSource', downpaymentSource)
    .set('soleTraderDetails', soleTraderDetails);
};

export default handleActions(
  {
    [nextStep](state) {
      return state.update('step', (currentStep) => currentStep + 1);
    },
    [submitApplicantionRequest](state) {
      return state.set('error', null);
    },
    [combineActions(updateApplicants, caseDetail.updateApplicantsFromCase)](state, { payload }) {
      const { applicant, coApplicant } = payload;
      return state
        .update('debtor', (currentDebtorState) =>
          updateApplicantState({ currentDebtorState, data: applicant }),
        )
        .update('coDebtor', (currentDebtorState) =>
          updateApplicantState({ currentDebtorState, data: coApplicant }),
        );
    },
    [clearCreditState]() {
      return initialState;
    },
    [clearPrefillDebtors](state) {
      return state
        .set('prefillDebtor', fromJS(initialPrefillDebtor))
        .set('prefillCoDebtor', fromJS(initialPrefillDebtor));
    },
    [getCustomerFullNameRequest](state) {
      return state.set('fetchingCustomerInformation', true);
    },
    [getCustomerFullNameResponse](state, { error, payload = {} }) {
      const { isCoDebtor, applicant } = payload;

      const newState = state.set('fetchingCustomerInformation', false);
      const stateToUpdate = isCoDebtor ? 'prefillCoDebtor' : 'prefillDebtor';
      return !error
        ? newState.update(stateToUpdate, (currentDebtorState) =>
            updateApplicantState({ currentDebtorState, data: applicant }),
          )
        : newState;
    },
    [resetApplicantFullName](state, { payload = {} }) {
      const { isCoDebtor, externalCustomerId } = payload;
      const stateToUpdate = isCoDebtor ? 'prefillCoDebtor' : 'prefillDebtor';
      return state.set(
        stateToUpdate,
        fromJS({
          ...initialPrefillDebtor,
          externalCustomerId,
        }),
      );
    },
    [setLegalEntity](state, { payload }) {
      return state.set('currentLegalEntity', payload);
    },
    [setIsWithCoApplicant](state, { payload }) {
      return state.set('isWithCoApplicant', payload);
    },
    [setResourceWaitingForDecision](state, { payload }) {
      return state.set('resourceWaitingForDecision', payload);
    },
    [checkIfCreditDecisionExpiredRequest](state) {
      return state.set('checkingIfCreditDecisionExpired', true);
    },
    [checkIfCreditDecisionExpiredResponse](state, { payload, error }) {
      return !error
        ? state
            .set('checkingIfCreditDecisionExpired', false)
            .set('isCreditDecisionExpired', payload)
        : state.set('checkingIfCreditDecisionExpired', false).set('isCreditDecisionExpired', false);
    },
  },
  initialState,
);
