import { ListItem, ListItemProps } from '@mui/material';

import { IMAFLinkProps, MAFLink } from 'src/components/MAFLink';
import { MAFTypography } from 'src/components/MAFTypography';
import { handleCustomSx, useScreenSize } from 'src/utils';

import { styles } from './MAFMenuItem.styles';
import { propTypes } from './MAFMenuItem.propTypes';

export interface IMAFMenuItemProps {
  sectionTitle?: React.ReactNode;
  linkProps?: IMAFLinkProps;
  listItemProps?: ListItemProps;
  index: number;
  custom?: React.ReactNode;
}

const MAFMenuItem: React.FC<IMAFMenuItemProps> = ({
  sectionTitle,
  linkProps,
  listItemProps,
  custom,
  index,
}) => {
  const { isMobile } = useScreenSize();

  return (
    <>
      {sectionTitle && (
        <ListItem sx={[(index > 0 || isMobile) && styles.sectionTitle]}>
          <MAFTypography variant="label1" fontSize="small">
            {sectionTitle}
          </MAFTypography>
        </ListItem>
      )}
      {linkProps && (
        <ListItem {...listItemProps} sx={[styles.item, ...handleCustomSx(listItemProps?.sx)]}>
          <MAFLink
            {...linkProps}
            sx={[styles.link, ...handleCustomSx(linkProps?.sx)]}
            typographyProps={{
              ...(linkProps.typographyProps ? linkProps.typographyProps : {}),
              sx: [styles.linkColor, ...handleCustomSx(linkProps.typographyProps?.sx)],
            }}
          />
        </ListItem>
      )}
      {custom}
    </>
  );
};

MAFMenuItem.propTypes = propTypes;

export default MAFMenuItem;
