import { ISxStyles } from '@asfi/maf';

export const styles: ISxStyles = {
  menuContainer: ({ colors: { solid } }) => ({
    backgroundColor: solid.white,
    border: 'none',
    '& .MuiMenu-paper': {
      backgroundColor: solid.white,
      border: 'none',
    },
  }),
  menuItem: {
    '&:hover button': {
      background: 'transparent',
    },
  },
  languageButton: {
    width: 'auto',
    display: 'inline-flex',
    padding: 0,
  },
  languageItem: ({ colors: { solid }, breakpoints, fontSizes: { extraSmall } }) => ({
    color: solid.cadedBlue,
    [breakpoints.only('xs')]: {
      fontSize: extraSmall,
    },
  }),
  icon: ({ colors: { solid } }) => ({
    '& g': {
      fill: solid.cadedBlue,
    },
  }),
};
