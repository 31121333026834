import dayjs from 'dayjs';
import { DateTimeUtils, ObjectUtils } from '@northstar/core';
import * as constants from '@northstar/core-ui/utils/constants';
import * as reactUtils from '@northstar/core-ui/utils/react-utils';
import * as numberUtils from '@northstar/core-ui/utils/number-utils';
import * as stringUtils from '@northstar/core-ui/utils/string-utils';

import {
  HP_FORM_CAR_PRICE,
  HP_FORM_DOWN_PAYMENT,
  HP_FORM_TRADE_IN_DEBT,
  HP_FORM_DURATION,
  HP_FORM_FUTURE_PERCENTAGE,
  HP_FORM_VAT_TOGGLE,
  HP_FORM_INTEREST_RATE,
} from './hirePurchaseConstants';

export const calculateDurationPeriodsCount = ({ maxDurationMonths, minDurationMonths, stepSize }) =>
  maxDurationMonths > minDurationMonths && stepSize
    ? Math.floor((maxDurationMonths - minDurationMonths) / stepSize + 1)
    : 0;

export const formDurationMarks = ({ periodsCount, stepSize, minDurationMonths }) =>
  periodsCount && stepSize
    ? new Array(periodsCount)
        .fill()
        .map((_, index) => (index + (minDurationMonths ? 1 : 0)) * stepSize)
    : [];

export const formatToPercentage = (value) => {
  if (value === undefined || value === null || Number.isNaN(Number(value))) return value;
  const commaValue = value.toString();
  const dotIndex = commaValue.indexOf('.');
  const condition = dotIndex !== -1 && commaValue.substring(dotIndex).length > 3;
  if (Number.isNaN(value)) return 0;
  else if (condition) {
    return Number(value).toFixed(2).toString().replace('.', ',');
  }
  return value.toString().replace('.', ',');
};

export const calculateTotalEquipmentPrice = ({ equipmentFormValues = {} }) =>
  Object.values(equipmentFormValues).reduce((prev, curr) => {
    if (typeof curr === 'object') {
      return prev + curr.price;
    }
    return prev + curr;
  }, 0);

export const calculateMinimumDownPayment = ({
  carPrice,
  minimumPercentage,
  equipmentPrice = 0,
  tradeinCarValue,
  tradeinCarDebt,
  tradeIn,
}) =>
  Math.ceil(
    (carPrice + equipmentPrice) * (minimumPercentage / 100) -
      numberUtils.convertToPositiveOrZero(tradeIn || tradeinCarValue - tradeinCarDebt),
  );

export const calculateCarAge = ({ asset, currentCase, formValues, endDate = new Date() }) => {
  const { duration, carRegistrationDate } = formValues || {};
  const isAssetWithRegistrationDate = !ObjectUtils.isNullOrEmpty(asset) && asset.registrationDate;
  const startDate = isAssetWithRegistrationDate
    ? reactUtils.parseDefaultDateToDayjs(asset.registrationDate)
    : carRegistrationDate || reactUtils.parseDefaultDateToDayjs(currentCase.deliveryDate);
  const monthsBetween = DateTimeUtils.getDifferenceBetweenDatesInMonths({
    startDate: dayjs(startDate).format(constants.DATE_REQUEST_FORMAT),
    endDate,
  });
  return stringUtils.formatToYearsAndMonths(monthsBetween + Number(duration));
};

export const getHirePurchaseFormInitialValues = ({ vatIncluded } = {}) => ({
  [HP_FORM_CAR_PRICE]: 0,
  [HP_FORM_FUTURE_PERCENTAGE]: 0,
  [HP_FORM_DOWN_PAYMENT]: 0,
  [HP_FORM_DURATION]: 84,
  [HP_FORM_TRADE_IN_DEBT]: 0,
  [HP_FORM_INTEREST_RATE]: 0,
  // jato flow has the option hidden
  [HP_FORM_VAT_TOGGLE]: vatIncluded ? 'true' : null,
});
