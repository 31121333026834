import { CSSProperties } from '@mui/styles';

import { ISxStyles } from '../../theming/theme.type';

interface IProps {
  isBold?: boolean;
}

interface IArrowTransitionProps {
  offsetX: number;
}

const arrowTransition = ({ offsetX }: IArrowTransitionProps): CSSProperties => ({
  transform: `translateX(${offsetX}px)`,
  transition: 'transform 0.1s linear',
  WebkitTransition: 'transform 0.1s linear',
  MozTransition: 'transform 0.1s linear',
  msTransition: 'transform 0.1s linear',
});

export const useStyles = ({ isBold }: IProps): ISxStyles => ({
  container: ({ colors }) => ({
    display: 'inline-flex',
    '&:not(:last-child)': {
      '& > span:last-child': {
        ...arrowTransition({ offsetX: 0 }),
        '& *': {
          fill: colors.danske.action,
        },
      },
    },
    '&:hover': {
      cursor: 'pointer',
      '& > p:first-child': {
        color: isBold ? colors.danske.action : colors.danske.primary100,
      },
    },
  }),
  singleLevelArrow: ({ colors }) => ({
    '& :last-child': {
      fill: colors.danske.action,
      transition: 'transform 0.1s linear',
      WebkitTransition: 'transform 0.1s linear',
      MozTransition: 'transform 0.1s linear',
      msTransition: 'transform 0.1s linear',
    },
    '&:hover': {
      '& > span:last-child': {
        ...arrowTransition({ offsetX: 4 }),
        '& :last-child': {
          fill: colors.danske.primary100,
        },
      },
    },
  }),
  multiLevelArrow: ({ colors }) => ({
    '&:hover': {
      '&:not(:last-child)': {
        '& > span:last-child': {
          ...arrowTransition({ offsetX: 4 }),
          '& :last-child': {
            fill: colors.danske.primary100,
          },
        },
      },
    },
  }),
  labelHelp: {
    marginRight: '-2px',
  },
  labelHref: ({ colors }) => ({
    marginRight: '-2px',
    color: colors.danske.action,
  }),
  arrowContainer: {
    '& > .MuiSvgIcon-root': {
      height: 15,
      transform: 'translateY(1px)',
    },
    marginRight: 1,
  },
  active: ({ colors }) => ({
    color: colors.danske.action,
  }),
});
