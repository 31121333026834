import dayjs from 'dayjs';
import { ObjectUtils } from '@northstar/core';
import { constants, objectUtils } from '@northstar/core-ui/utils';

const mapGeneralInformationFormToRequest = ({
  applicant: { firstName, lastName, phone, email } = {},
  coApplicant,
  deliveryDate,
  draftScenarioId,
  vinNumber,
  registrationNumber,
  hasAppliedForCredit,
}) => {
  const deliveryDateFormatted = deliveryDate
    ? dayjs(deliveryDate).format(constants.DEFAULT_ISO_FORMAT)
    : undefined;

  return objectUtils.filterNullOrUndefined({
    obj: {
      case_data: {
        delivery_date: deliveryDateFormatted,
        draft_scenario_id: draftScenarioId,
      },
      asset: {
        registration_number: registrationNumber || null,
        vin_number: vinNumber || null,
      },
      applicant: hasAppliedForCredit
        ? {
            phone,
            email,
          }
        : {
            first_name: firstName,
            last_name: lastName,
            phone,
            email,
          },
      co_applicant: ObjectUtils.isNullOrEmpty(coApplicant)
        ? undefined
        : { phone: coApplicant.phone, email: coApplicant.email },
    },
    recurse: true,
  });
};

export const requestMappers = {
  mapGeneralInformationForm: mapGeneralInformationFormToRequest,
};
