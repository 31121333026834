import { requestHandler } from '@northstar/core-ui/utils';
const { assetDomain } = requestHandler;

export const getAssetsPrivate = ({ importerId }) =>
  assetDomain.GET_LIST_VENDOR_PRIVATE.call({
    urlParams: { importerId },
  });

export const getAssetsCommercial = ({ importerId }) =>
  assetDomain.GET_LIST_VENDOR_COMMERCIAL.call({
    urlParams: { importerId },
  });
