import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  isNumber: PropTypes.bool,
  isWithEllipsis: PropTypes.bool,
  item: PropTypes.shape({
    label: PropTypes.node,
    text: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary']),
} as any;
