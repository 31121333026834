import { ISxStyles } from '../../theming/theme.type';

interface IProps {
  isLabelInside?: boolean;
  isAutoMinWidth?: boolean;
}

export const useStyles = ({ isLabelInside, isAutoMinWidth }: IProps): ISxStyles => {
  const iconBase = {
    width: 16,
    right: 18,
    top: isLabelInside ? 10 : 18,
    transition: 'transform 0.5s ease 0s',
  };
  return {
    select: ({ typography, colors }) => ({
      '& .MuiSelect-select, .MuiNativeSelect-select': {
        ...colors.custom.inputFull.input,
        padding: isLabelInside ? '28px 54px 8px 12px !important' : '11px 48px 11px 18px !important',
        marginTop: isLabelInside ? -2 : undefined,
        height: 22,
        minWidth: isAutoMinWidth ? 'auto' : '204px !important',
        lineHeight: '24px',
        fontFamily: typography.main,
        borderRadius: '2px',
        '&:hover:not($disabled)': {
          ...colors.custom.inputFull.hover,
        },
        '&[aria-expanded="true"]': {
          ...colors.custom.inputFull.hover,
        },
        '&:focus': {
          backgroundColor: colors.custom.inputFull.input.background,
          ...colors.custom.inputFull.hover,
        },
      },
    }),
    border: ({ colors }) => ({
      '& .MuiSelect-select, .MuiNativeSelect-select': {
        ...colors.custom.inputFull.hover,
      },
    }),
    icon: ({ colors }) => ({
      '& .MuiSelect-icon, .MuiNativeSelect-icon': {
        ...iconBase,
        top: isLabelInside ? 10 : 18,
        '& g': {
          fill: colors.custom.inputFull.input.color,
        },
      },
    }),
    iconDisabled: ({ colors }) => ({
      '& .MuiSelect-icon, .MuiNativeSelect-icon': {
        ...iconBase,
        top: isLabelInside ? 10 : 18,
        '& g': {
          fill: colors.custom.inputFull.disabled.color,
        },
      },
    }),
    selected: ({ colors }) => ({
      '&.MuiMenuItem-root.Mui-selected': {
        backgroundColor: `${colors.custom.select.selected.background} !important`,
        color: `${colors.custom.select.selected.color} !important`,
      },
    }),
    disabled: ({ colors }) => ({
      '& .MuiSelect-select.Mui-disabled, .MuiNativeSelect-select.Mui-disabled': {
        '-webkitTextFillColor': `${colors.custom.inputFull.disabled.color} !important`,
        color: `${colors.custom.inputFull.disabled.color} !important`,
        backgroundColor: `${colors.custom.inputFull.disabled.background} !important`,
        cursor: 'not-allowed !important',
      },
    }),
    error: ({ colors }) => ({
      '& .MuiSelect-select, .MuiNativeSelect-select': {
        borderColor: `${colors.custom.inputFull.error.background} !important`,
      },
    }),
    item: {
      '&.MuiMenuItem-root': {
        padding: '12px 18px',
      },
    },
    paper: ({ colors }) => ({
      '& .MuiMenu-paper': {
        ...colors.custom.inputFull.input,
        maxHeight: 200,
        marginTop: '6px',
        zIndex: 1,
        overflow: 'visible',
        borderStyle: 'none',
        '&:before': {
          left: '85%',
          position: 'relative',
          display: 'block',
          content: '""',
          transform: 'rotate(45deg)',
          top: 7,
          marginTop: '-10px',
          width: 10,
          height: 10,
          backgroundColor: colors.custom.inputFull.input.background,
        },
      },
    }),
    list: ({ colors }) => ({
      '& .MuiMenu-list': {
        padding: '0px',
        width: 'inherit',
        overflowX: 'hidden',
        overflowY: 'auto',
        maxHeight: 200,
        '&::-webkit-scrollbar': {
          width: 4,
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '4px',
          backgroundColor: colors.custom.contentAlternative.main.color,
        },
      },
    }),
  };
};
