import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  hiddenStepper: {
    visibility: 'hidden',
    marginBottom: '-50px',
  },
  visibleStepper: {
    visibility: 'visible',
    marginBottom: 0,
  },
  container: {
    textAlign: 'center',
    overflow: 'visible',
  },
  animateContainerText: {
    transform: 'translateY(-100px)',
    transition: 'all 0.5s',
    opacity: 0,
  },
  subText: ({ colors }) => ({
    ...colors.custom.stepper.intro.subText,
    margin: '20px 0px 30px',
  }),
  stepInfoPlaceholder: ({ spacing }) => ({
    minHeight: spacing(7),
    width: '1px',
    margin: 'auto',
  }),
  stepInactive: ({ colors }) => ({
    width: 18,
    height: 18,
    outline: 'none',
    backgroundColor: colors.custom.stepper.inactive,
  }),
  transition: {
    transition: 'all 0.75s',
  },
  stepTitle: {
    position: 'absolute',
    textAlign: 'left',
    width: '1px',
    whiteSpace: 'nowrap',
  },
  stepTitleInactive: {
    opacity: 0,
  },
  animateStepTitle: ({ fontSizes: { extraSmall } }) => ({
    fontSize: extraSmall,
    fontWeight: 500,
    display: 'grid',
    justifyContent: 'center',
  }),
  buttons: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'center',
  },
};
