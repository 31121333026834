import { Box } from '@mui/material';
import { handleAccessibilityEvent, handleCustomSx } from 'src/utils';
import { IMAFFontSizes } from 'src/theming/theme.type';

import ArrowNext from '../Icons/ArrowNext';
import MAFIcon from '../MAFIcon/MAFIcon';
import MAFTypography from '../MAFTypography/MAFTypography';

import { IMAFLinkProps } from './MAFLink.types';
import { useStyles } from './MAFLink.styles';
import { propTypes } from './MAFLink.propTypes';

const MAFLink: React.FC<IMAFLinkProps> = ({
  levels,
  isWithArrow = false,
  isBold = false,
  isLinkActive = false,
  typographyProps,
  sx,
  arrowStyles,
}) => {
  const styles = useStyles({ isBold });
  const variant = isBold ? 'label1' : 'help1';
  const hoverClassName = levels.length > 1 ? styles.multiLevelArrow : styles.singleLevelArrow;
  const isSingleLevel = levels.length === 1;

  return (
    <Box component="nav" sx={sx}>
      {levels.map(({ label, onClick, isHref = false }, index) => {
        const isLastElement = index + 1 >= levels.length;
        return (
          <Box
            sx={[styles.container, isWithArrow && hoverClassName]}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              onClick && handleAccessibilityEvent(e, onClick)
            }
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
              onClick && handleAccessibilityEvent(e, onClick)
            }
            tabIndex={0}
            component="div"
            role="button"
          >
            <MAFTypography
              sx={[
                !isHref && styles.labelHelp,
                isHref && styles.labelHref,
                isLinkActive && styles.active,
              ]}
              variant={variant}
              // typescript throws error if you cast it as keyof IMAFFontSizes | undefined
              fontSize={
                (typographyProps?.variant ? undefined : ('small' as keyof IMAFFontSizes)) as any
              }
              fontWeight={isBold ? 'bold' : undefined}
              {...typographyProps}
            >
              {label}
            </MAFTypography>
            {(!isLastElement || isSingleLevel) && isWithArrow && (
              <Box component="span" sx={[styles.arrowContainer, ...handleCustomSx(arrowStyles)]}>
                <MAFIcon Icon={ArrowNext} />
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

MAFLink.propTypes = propTypes;

export default MAFLink;
