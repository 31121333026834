"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showOverflowScroll = exports.setTranslationModeRequest = exports.setLongPollingData = exports.setForceRefresh = exports.setCookieConsent = exports.redirectAndClose = exports.logError = exports.initialState = exports.hideSnackbar = exports.hideOverflowScroll = exports.expandMenu = exports.displaySnackbar = exports.displayError = exports.default = exports.clearAppState = exports.changeReduxFormStatesKeeping = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
/**
 * Actions
 */
const displayError = exports.displayError = (0, _reduxActions.createAction)('APP_DISPLAY_ERROR');
const displaySnackbar = exports.displaySnackbar = (0, _reduxActions.createAction)('APP_DISPLAY_SNACKBAR');
const hideSnackbar = exports.hideSnackbar = (0, _reduxActions.createAction)('APP_HIDE_SNACKBAR');
const clearAppState = exports.clearAppState = (0, _reduxActions.createAction)('APP_CLEAR_APP_STATE');
const redirectAndClose = exports.redirectAndClose = (0, _reduxActions.createAction)('APP_REDIRECT_AND_CLOSE');
const expandMenu = exports.expandMenu = (0, _reduxActions.createAction)('APP_EXPAND_MENU');
const hideOverflowScroll = exports.hideOverflowScroll = (0, _reduxActions.createAction)('APP_HIDE_OVERFLOW_SCROLL');
const showOverflowScroll = exports.showOverflowScroll = (0, _reduxActions.createAction)('APP_SHOW_OVERFLOW_SCROLL');
const changeReduxFormStatesKeeping = exports.changeReduxFormStatesKeeping = (0, _reduxActions.createAction)('APP_TOGGLE_REDUX_FORM_STATES_KEEPING');
const setForceRefresh = exports.setForceRefresh = (0, _reduxActions.createAction)('APP_SET_FORCE_REFRESH');
const setTranslationModeRequest = exports.setTranslationModeRequest = (0, _reduxActions.createAction)('APP_SET_TRANSLATION_MODE_REQUEST');
const logError = exports.logError = (0, _reduxActions.createAction)('APP_LOG_ERROR');
const setCookieConsent = exports.setCookieConsent = (0, _reduxActions.createAction)('APP_SET_COOKIE_CONSENT');
const setLongPollingData = exports.setLongPollingData = (0, _reduxActions.createAction)('APP_SET_LONG_POLLING_DATA');

/**
 * Initial state
 */
const initialState = exports.initialState = (0, _immutable.fromJS)({
  error: null,
  action: {},
  expandedMenu: null,
  overflowHidden: null,
  keepReduxState: false,
  forceRefresh: false,
  translationMode: false,
  snackbar: null,
  showSnackbar: false,
  cookieConsent: null,
  longPolling: {
    lastActionTimestamp: null,
    isAllowed: true
  }
});

/**
 * Actions handlers
 */
var _default = exports.default = (0, _reduxActions.handleActions)({
  [displayError](state, _ref) {
    let {
      payload
    } = _ref;
    if (payload && payload.httpResponse) {
      return state.set('error', (0, _immutable.fromJS)(payload.httpResponse.reason));
    }
    if (typeof payload === 'object') {
      const {
        error,
        action
      } = payload;
      return state.set('error', (0, _immutable.fromJS)(error)).set('action', (0, _immutable.fromJS)(action));
    }
    return state.set('error', (0, _immutable.fromJS)(payload));
  },
  [displaySnackbar](state, _ref2) {
    let {
      payload
    } = _ref2;
    return state.set('snackbar', payload).set('showSnackbar', true);
  },
  [hideSnackbar](state) {
    return state.set('showSnackbar', false);
  },
  [clearAppState](state, _ref3) {
    let {
      payload = {}
    } = _ref3;
    const {
      keepExpanded
    } = payload;
    const newState = initialState.set('forceRefresh', state.get('forceRefresh'));
    return keepExpanded ? newState.set('expandedMenu', state.get('expandedMenu')) : newState;
  },
  [expandMenu](state, _ref4) {
    let {
      payload
    } = _ref4;
    return state.set('expandedMenu', payload);
  },
  [hideOverflowScroll](state) {
    return state.set('overflowHidden', true);
  },
  [showOverflowScroll](state) {
    return state.set('overflowHidden', false);
  },
  [changeReduxFormStatesKeeping](state, _ref5) {
    let {
      payload
    } = _ref5;
    return state.set('keepReduxState', payload);
  },
  [setForceRefresh](state, _ref6) {
    let {
      payload
    } = _ref6;
    return state.set('forceRefresh', payload);
  },
  [setTranslationModeRequest](state, _ref7) {
    let {
      payload
    } = _ref7;
    return state.set('translationMode', payload);
  },
  [setCookieConsent](state, _ref8) {
    let {
      payload
    } = _ref8;
    return state.set('cookieConsent', payload);
  },
  [setLongPollingData](state, _ref9) {
    let {
      payload
    } = _ref9;
    return state.set('longPolling', (0, _immutable.fromJS)(payload));
  }
}, initialState);