import { vendorConfig } from '@northstar/core-ui';

import { POSITION_OWNER, POSITION_SIGNEE } from 'screens/kyc/kyc-constants';

import {
  transformUserSelections,
  mapCountries,
  mapPosition,
  mapLineOfIndustry,
  mapGeneralPersonInformation,
  mapCountryInfo,
} from './kyc-utils';

const mapKycToRequest = (applicant) => {
  const {
    prefillPeople,
    leasingContractSignees,
    shareholders,
    citizenships,
    proofOfDeliverySignees,
    physicalOwners,
    pep,
    industry,
    legalEntities,
    customerTypes,
    salesChannels,
    suppliers,
  } = applicant;

  return {
    businessSuppliers: transformUserSelections(suppliers),
    companyChannels: transformUserSelections(salesChannels),
    companyCustomerTypes: transformUserSelections(customerTypes),
    companyOwnershipShares: legalEntities
      ? mapLegalEntities({ legalEntities: legalEntities.added })
      : null,
    lineOfIndustry: mapLineOfIndustry(Object.keys(industry)[0]),
    ownersCitizenship: mapOwnersCitizenship({ prefillPeople, citizenshipsValues: citizenships }),
    pep: Object.keys(pep)[0],
    personOwnershipShares: mapPersonalOwnershipShares({ physicalOwners }),
    proofOfDeliverySignees: mapProofOfDeliverySignees({
      proofOfDeliverySignees,
      citizenshipsValues: citizenships,
    }),
    signatoryDetails: mapSignatories({
      addedSignatories: leasingContractSignees.added,
      citizenshipsValues: citizenships,
    }),
    signeeDetails: mapSignee({
      selectedSignees: shareholders.selected,
      citizenshipsValues: citizenships,
    }),
  };
};

const mapLegalEntities = ({ legalEntities }) =>
  Object.keys(legalEntities).map((id) => ({
    companyCustomerId: legalEntities[id].organisationNumber,
    companyName: legalEntities[id].companyName,
    ownershipPercentage: legalEntities[id].ownershipShare,
  }));

const mapOwnersCitizenship = ({ prefillPeople, citizenshipsValues }) => {
  const seCitizenship = citizenshipsValues.true ? citizenshipsValues.true.value : false;
  return {
    owners: !seCitizenship
      ? Object.keys(citizenshipsValues.other).map((id) => ({
          ...mapGeneralPersonInformation(prefillPeople[id]),
          ...mapCountryInfo({ id, citizenshipsValues }),
          position: prefillPeople[id].position
            ? mapPosition(prefillPeople[id].position)
            : mapPosition(POSITION_OWNER),
        }))
      : null,
    seCitizenship,
  };
};

const mapPersonalOwnershipShares = ({ physicalOwners }) =>
  physicalOwners
    ? [
        ...mapPersonalOwnershipAdded({ physicalOwners: physicalOwners.added || [] }),
        ...mapPersonalOwnershipSelected({ physicalOwners: physicalOwners.selected || [] }),
      ]
    : null;

const mapPersonalOwnershipSelected = ({ physicalOwners }) =>
  Object.keys(physicalOwners).map((id) => ({
    ...mapGeneralPersonInformation(physicalOwners[id].optionValue),
    ownershipPercentage: physicalOwners[id].value,
  }));

const mapPersonalOwnershipAdded = ({ physicalOwners }) =>
  Object.keys(physicalOwners).map((id) => ({
    ...mapGeneralPersonInformation(physicalOwners[id]),
    ownershipPercentage: physicalOwners[id].ownershipShare,
  }));

const mapProofOfDeliverySignees = ({ proofOfDeliverySignees, citizenshipsValues }) =>
  proofOfDeliverySignees.selected
    ? mapProofOfDeliverySigneesSelected({
        proofOfDeliverySignees: proofOfDeliverySignees.selected,
        citizenshipsValues,
      })
    : mapProofOfDeliverySigneesAdded({ proofOfDeliverySignees: proofOfDeliverySignees.added });

const mapProofOfDeliverySigneesSelected = ({ proofOfDeliverySignees, citizenshipsValues }) =>
  Object.keys(proofOfDeliverySignees).map((id) => ({
    ...mapGeneralPersonInformation(proofOfDeliverySignees[id].optionValue),
    citizenships:
      citizenshipsValues.other && citizenshipsValues.other[id]
        ? mapCountries(citizenshipsValues.other[id].citizenship)
        : [vendorConfig.DEFAULT_COUNTRY],
  }));

const mapProofOfDeliverySigneesAdded = ({ proofOfDeliverySignees }) =>
  Object.keys(proofOfDeliverySignees).map((id) => ({
    ...mapGeneralPersonInformation(proofOfDeliverySignees[id]),
    citizenships: mapCountries(proofOfDeliverySignees[id].citizenship),
  }));

const mapSignatories = ({ addedSignatories, citizenshipsValues }) =>
  Object.keys(addedSignatories).map((id) => ({
    ...mapGeneralPersonInformation(addedSignatories[id]),
    ...mapCountryInfo({ id, citizenshipsValues }),
  }));

const mapSignee = ({ selectedSignees, citizenshipsValues }) =>
  Object.keys(selectedSignees).map((id) => {
    const { externalCustomerId, ...restBasic } = mapGeneralPersonInformation(
      selectedSignees[id].optionValue,
    );
    const { citizenships, residence } = mapCountryInfo({ id, citizenshipsValues });
    return {
      externalId: externalCustomerId,
      ...restBasic,
      signeeCitizenships: citizenships,
      residence,
      position: mapPosition(POSITION_SIGNEE),
    };
  })[0];

export const requestMappers = {
  mapKyc: mapKycToRequest,
};
