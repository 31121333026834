import { ISxStyles } from '../../theming/theme.type';

interface IProps {
  background?: string;
}

export const useStyles = ({ background }: IProps): ISxStyles => ({
  absolute: ({ colors }) => ({
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1301,
    textAlign: 'center',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: background ? `${background}` : `${colors.danske.white}`,
  }),
});
