import { ReactNode } from 'react';
import { Divider, Grid } from '@mui/material';

import MAFTypography from '../../MAFTypography/MAFTypography';

import { styles } from './ValueRow.styles';
import { propTypes } from './ValueRow.propTypes';

interface IValueRow {
  isStrongVariant?: boolean;
  index: number;
  isNotLast: boolean;
  name: ReactNode;
  value?: ReactNode;
  isSubtitle?: boolean;
  isLongTitle?: boolean;
}

const ValueRow = ({
  name,
  value,
  isSubtitle,
  isLongTitle,
  isStrongVariant,
  index,
  isNotLast,
}: IValueRow) => (
  <Grid
    sx={[
      styles.rowWrapper,
      Boolean(isSubtitle) && styles.subtitle,
      !isStrongVariant && index > 0 && styles.spacing,
    ]}
    container
    key={index}
  >
    <>
      <Grid item xs={isLongTitle ? 12 : 6}>
        <MAFTypography variant="body1">{name}</MAFTypography>
      </Grid>
      <Grid
        item
        xs={isLongTitle ? 12 : 6}
        sx={[!isLongTitle ? styles.subtitleText : styles.spacing]}
      >
        <MAFTypography variant="body1">{value}</MAFTypography>
      </Grid>
    </>
    {isStrongVariant && isNotLast && <Divider sx={styles.detailsDivider} />}
  </Grid>
);

ValueRow.propTypes = propTypes;

export default ValueRow;
