"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: _ref => {
    let {
      typography: {
        main
      },
      colors
    } = _ref;
    return {
      fontFamily: main,
      color: colors.custom.fontPrimary,
      textAlign: 'left',
      padding: '40px 50px 20px',
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap'
    };
  }
};