"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCase = void 0;
var _core = require("@northstar/core");
var _requestHandler = _interopRequireDefault(require("../../utils/request-handler"));
const {
  caseDomain,
  vendorCaseDomain
} = _requestHandler.default;
const getCase = _ref => {
  let {
    resourceId,
    draftScenarioId
  } = _ref;
  const correctRoute = _core.DomainUtils.getByExternalOrInternal(vendorCaseDomain.GET_SINGLE, caseDomain.GET_SINGLE);
  return correctRoute.call({
    urlParams: {
      resourceId,
      draftScenarioId
    }
  });
};
exports.getCase = getCase;