"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.submitAssetResponse = exports.submitAssetRequest = exports.restoreAssetWithEditingRequest = exports.prefillEquipmentFormRequest = exports.openAssetFormRequest = exports.openAssetExistsInAssetCatalogueDialog = exports.initialState = exports.hardDeleteAssetResponse = exports.hardDeleteAssetRequest = exports.getAssetResponse = exports.getAssetRequest = exports.getAssetAndRedirectVendorRequest = exports.editAssetResponse = exports.editAssetRequest = exports.deleteAssetResponse = exports.deleteAssetRequest = exports.default = exports.closeAssetForm = exports.closeAssetExistsInAssetCatalogueDialog = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
const prefillEquipmentFormRequest = exports.prefillEquipmentFormRequest = (0, _reduxActions.createAction)('DASHBOARD_PREFILL_EQUIPMENT_FORM_REQUEST');
const getAssetRequest = exports.getAssetRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_ASSET_REQUEST');
const getAssetResponse = exports.getAssetResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_ASSET_RESPONSE');
const submitAssetRequest = exports.submitAssetRequest = (0, _reduxActions.createAction)('DASHBOARD_SUBMIT_ASSET_REQUEST');
const submitAssetResponse = exports.submitAssetResponse = (0, _reduxActions.createAction)('DASHBOARD_SUBMIT_ASSET_RESPONSE');
const editAssetRequest = exports.editAssetRequest = (0, _reduxActions.createAction)('DASHBOARD_EDIT_ASSET_REQUEST');
const editAssetResponse = exports.editAssetResponse = (0, _reduxActions.createAction)('DASHBOARD_EDIT_ASSET_RESPONSE');
const deleteAssetRequest = exports.deleteAssetRequest = (0, _reduxActions.createAction)('DASHBOARD_DELETE_ASSET_REQUEST');
const deleteAssetResponse = exports.deleteAssetResponse = (0, _reduxActions.createAction)('DASHBOARD_DELETE_ASSET_RESPONSE');
const hardDeleteAssetRequest = exports.hardDeleteAssetRequest = (0, _reduxActions.createAction)('DASHBOARD_HARD_DELETE_ASSET_REQUEST');
const hardDeleteAssetResponse = exports.hardDeleteAssetResponse = (0, _reduxActions.createAction)('DASHBOARD_HARD_DELETE_ASSET_RESPONSE');
const getAssetAndRedirectVendorRequest = exports.getAssetAndRedirectVendorRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_ASSET_AND_REDIRECT_REQUEST');
const openAssetFormRequest = exports.openAssetFormRequest = (0, _reduxActions.createAction)('DASHBOARD_OPEN_ASSET_FORM_REQUEST');
const closeAssetForm = exports.closeAssetForm = (0, _reduxActions.createAction)('DASHBOARD_CLOSE_ASSET_FORM');
const closeAssetExistsInAssetCatalogueDialog = exports.closeAssetExistsInAssetCatalogueDialog = (0, _reduxActions.createAction)('DASHBOARD_CLOSE_EXISTS_IN_ASSET_CATALOGUE_DIALOG');
const restoreAssetWithEditingRequest = exports.restoreAssetWithEditingRequest = (0, _reduxActions.createAction)('DASHBOARD_RESTORE_ASSET_WITH_EDITTING_REQUEST');
const openAssetExistsInAssetCatalogueDialog = exports.openAssetExistsInAssetCatalogueDialog = (0, _reduxActions.createAction)('DASHBOARD_ASSET_OPEN_EXISTS_IN_ASSET_CATALOGUE_DIALOG');
/**
 * Initial state
 */
const initialState = exports.initialState = (0, _immutable.fromJS)({
  asset: {},
  gettingAsset: false,
  submittingAsset: false,
  isAssetExistsInAssetCatalogueOpen: false
});

/**
 * Actions handlers
 */
var _default = exports.default = (0, _reduxActions.handleActions)({
  [(0, _reduxActions.combineActions)(submitAssetRequest, editAssetRequest)](state) {
    return state.set('submittingAsset', true);
  },
  [(0, _reduxActions.combineActions)(submitAssetResponse, editAssetResponse)](state, _ref) {
    let {
      payload,
      error
    } = _ref;
    return !error ? state.set('asset', (0, _immutable.fromJS)(payload)).set('submittingAsset', false) : state.set('submittingAsset', false);
  },
  [closeAssetExistsInAssetCatalogueDialog](state) {
    return state.set('isAssetExistsInAssetCatalogueOpen', false);
  },
  [getAssetRequest](state) {
    return state.set('gettingAsset', true);
  },
  [getAssetResponse](state, _ref2) {
    let {
      payload,
      error
    } = _ref2;
    return !error ? state.set('asset', (0, _immutable.fromJS)(payload)).set('gettingAsset', false).set('submittingAsset', false) : state.set('gettingAsset', false);
  },
  [hardDeleteAssetRequest](state) {
    return state.set('submittingAsset', true);
  },
  [hardDeleteAssetResponse](state) {
    return state.set('submittingAsset', false);
  },
  [openAssetExistsInAssetCatalogueDialog](state, _ref3) {
    let {
      payload
    } = _ref3;
    return state.set('isAssetExistsInAssetCatalogueOpen', payload).set('submittingAsset', false);
  }
}, initialState);