import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  /**
   * Dialog children, usually the included sub-components.
   */
  children: PropTypes.node,
  closeButtonText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isLoading: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
} as any;
