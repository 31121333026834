"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCaseForQuote = void 0;
var _requestHandler = _interopRequireDefault(require("../../utils/request-handler"));
const {
  vendorCaseDomain
} = _requestHandler.default;
const getCaseForQuote = _ref => {
  let {
    hashId
  } = _ref;
  return vendorCaseDomain.GET_SINGLE_UNAUTHORIZED.call({
    urlParams: {
      hashId
    }
  });
};
exports.getCaseForQuote = getCaseForQuote;