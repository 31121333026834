"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  container: _ref => {
    let {
      typography: {
        baseFont
      },
      colors
    } = _ref;
    return _objectSpread(_objectSpread({}, baseFont), {}, {
      color: colors.custom.fontPrimary,
      textAlign: 'left',
      padding: '20px 50px',
      display: 'flex',
      flexWrap: 'wrap',
      fontWeight: 'normal',
      alignItems: 'center',
      borderTop: "1px solid ".concat(colors.alpha(colors.custom.content.background, 0.28))
    });
  },
  title: _ref2 => {
    let {
      typography: {
        title
      }
    } = _ref2;
    return {
      textAlign: 'left',
      padding: '20px 0',
      width: '100%',
      fontFamily: title,
      fontWeight: 500,
      fontSize: 20,
      '&span:first-child': {
        marginRight: '5px'
      }
    };
  },
  helperText: _ref3 => {
    let {
      fontSizes: {
        extraSmall
      }
    } = _ref3;
    return {
      display: 'block',
      marginTop: '5px',
      fontSize: extraSmall,
      fontStyle: 'italic'
    };
  }
};