const DOCUMENT_BASE = window.DOCUMENT_BASE;
const FILE_DOCUMENT_BASE =
  typeof DOCUMENT_BASE === 'undefined'
    ? 'https://external-ssl-edge-nlns-test.apps.az2-osn10.eva.danskenet.com/documents'
    : DOCUMENT_BASE;

export const TERMS_AND_CONDITIONS_PROL = `${FILE_DOCUMENT_BASE}/Allmänna villkor Privatleasing februaru 2020.pdf`;
export const TERMS_AND_CONDITIONS_PRHP = `${FILE_DOCUMENT_BASE}/Allmänna villkor Avbetalnings konsument.pdf`;
export const TERMS_AND_CONDITIONS_CMOL = `${FILE_DOCUMENT_BASE}/Allmänna villkor Opertional leasing Fordon.pdf`;
export const TERMS_AND_CONDITIONS_CMFL = `${FILE_DOCUMENT_BASE}/Finansiell Leasing Fordon T&C.pdf`;

export const defaultAdobeAnalyticsPayload = {
  'page.server': 'northstar.danskebank.se',
  'page.channel': 'Privat',
  'page.platform': 'NorthStar',
  'page.country': 'SE',
};
