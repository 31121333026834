import { PROCESS_VARIANCES } from '@northstar/core';
import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

/**
 * Actions
 */
export const getImportersTermsResponse = createAction('IMPORTERS_GET_TERMS_RESPONSE');
/**
 *
 * Initial state
 */
export const initialState = fromJS({
  terms: {
    [PROCESS_VARIANCES.PRIVATE_OL]: {},
    [PROCESS_VARIANCES.PRIVATE_HP]: {},
    [PROCESS_VARIANCES.COMMERCIAL_OL]: {},
    [PROCESS_VARIANCES.COMMERCIAL_FL]: {},
  },
});

/**
 * Actions handlers
 */
export default handleActions(
  {
    [getImportersTermsResponse](state, { payload }) {
      const { financialType, importerId, terms } = payload;
      return state.setIn(['terms', financialType, importerId], fromJS(terms));
    },
  },
  initialState,
);
