import { FC, ReactChild, ReactNode } from 'react';
import { Box, Button, Slide, Fade } from '@mui/material';

import MAFTypography from '../../MAFTypography/MAFTypography';

import { useStyles } from './StepsContainer.styles';
import { propTypes } from './StepsContainer.propTypes';

interface IStepsContainerProps {
  isSide: boolean;
  closeButtonText: ReactChild;
  children: ReactNode;
  onClose: () => void;
  isOpen: boolean;
  isWithAppBar?: boolean;
  topOffset: number;
}

const StepsContainer: FC<IStepsContainerProps> = ({
  isSide,
  closeButtonText,
  children,
  onClose,
  isOpen,
  isWithAppBar = false,
  topOffset,
}) => {
  const styles = useStyles({ isSide, topOffset });

  const containerClasses = [styles.mobileProgressStepsContainer, isWithAppBar && styles.withAppBar];

  return (
    <Fade in={isOpen} timeout={250}>
      <Box sx={styles.mobileProgressStepsParentContainer}>
        <Slide in={isOpen} direction={isSide ? 'right' : 'down'} timeout={250}>
          <Box sx={containerClasses}>
            <Box>
              <Box sx={styles.mobileProgressStepsWrapper}>
                {children}
                <Button sx={styles.closeButton} type="button" onClick={onClose}>
                  <MAFTypography variant="body1">{closeButtonText}</MAFTypography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Slide>
      </Box>
    </Fade>
  );
};

StepsContainer.propTypes = propTypes;

export default StepsContainer;
