import { useState, createRef, FC, ReactChild, useLayoutEffect } from 'react';
import { Box, SxProps, Theme } from '@mui/material';

import MAFStepperMobile, { IMAFStepperMobileProps } from '../MAFStepperMobile/MAFStepperMobile';
import MAFButton from '../MAFButton/MAFButton';
import MAFTypography from '../MAFTypography/MAFTypography';
import Step from '../MAFStepperMobile/Step/Step';
import { handleCustomSx } from '../../utils';

import { styles } from './MAFStepperIntroMobile.styles';
import { propTypes } from './MAFStepperIntroMobile.propTypes';

const BASE_TRANSITION_DELAY = 0.15;
const DEFAULT_ANIMATION_LENGTH_MS = 1250;

export interface IMAFStepperIntroMobileProps extends IMAFStepperMobileProps {
  title: ReactChild;
  subTitle: ReactChild;
  activeStep: number;
  /**
   * A flag that determines if we display the intro.
   * By default it will reenter intro state on screen resize
   */
  isAnimationFinished?: boolean;
  buttonText: ReactChild;
  onInitialClick: (step: number) => void;
  containerClass?: SxProps<Theme>;
  buttonsClass?: SxProps<Theme>;
  buttonsComponent?: ReactChild;
}

const MAFStepperIntroMobile: FC<IMAFStepperIntroMobileProps> = ({
  steps,
  title,
  subTitle,
  activeStep,
  isAnimationFinished: isAnimationFinishedInitial,
  buttonText,
  onInitialClick,
  containerClass = {},
  buttonsClass = {},
  buttonsComponent,
  ...rest
}) => {
  const container = createRef<HTMLDivElement>();

  const [isAnimating, setIsAnimating] = useState(false);
  const [isAnimationFinished, setIsAnimationFinished] = useState(isAnimationFinishedInitial);
  const [containerTopPosition, setContainerTopPosition] = useState<Nullable<number>>(null);

  useLayoutEffect(() => {
    setContainerTopPosition(
      container.current && container.current.getBoundingClientRect().y + window.scrollY,
    );
  }, [container.current]);

  const handleClick = () => {
    setIsAnimating(true);
    const allStepsFinishedAnimatingTime = steps.length * BASE_TRANSITION_DELAY * 100;
    setTimeout(() => {
      onInitialClick(0);
      setIsAnimationFinished(true);
    }, DEFAULT_ANIMATION_LENGTH_MS + allStepsFinishedAnimatingTime);
  };

  return isAnimationFinished ? (
    <>
      <MAFStepperMobile steps={steps} {...rest} />
      {steps[activeStep].content}
    </>
  ) : (
    <Box sx={[styles.container, ...handleCustomSx(containerClass)]}>
      <Box sx={[isAnimating && styles.transitionText]}>
        <MAFTypography variant="h1-italic" isWithContainer>
          {title}
        </MAFTypography>
        <MAFTypography variant="body1" sx={styles.subText} isWithContainer>
          {subTitle}
        </MAFTypography>
      </Box>
      <div ref={container}>
        {steps.map((item, index) => (
          <Step
            step={index}
            title={item.title}
            lastAvailableStep={0}
            key={index}
            sx={[
              styles.stepContainer,
              isAnimating && styles.transition,
              isAnimating && !index && styles.transitionFirstStep,
              isAnimating && {
                transform: `translateY(-${
                  containerTopPosition && containerTopPosition - 10 + index * 48
                }px)`,
                transitionDelay: `${index * BASE_TRANSITION_DELAY}s`,
              },
            ]}
            classNameStepInfo={[isAnimating && styles.transition]}
            onStepClick={index === 0 ? handleClick : () => {}}
            type="button"
          />
        ))}
      </div>
      <Box sx={[styles.buttons, ...handleCustomSx(buttonsClass)]}>
        {buttonsComponent}
        <MAFButton color="primaryPR" onClick={handleClick}>
          {buttonText}
        </MAFButton>
      </Box>
    </Box>
  );
};

MAFStepperIntroMobile.propTypes = propTypes;

export default MAFStepperIntroMobile;
