const Delete: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-action/Trash/Darkblue">
        <g id="Icon" transform="translate(2.000000, 2.000000)">
          <path
            d="M6.4687,16 C6.7447,16 6.9687,15.776 6.9687,15.5 L6.9687,7.5 C6.9687,7.224 6.7447,7 6.4687,7 C6.1927,7 5.9687,7.224 5.9687,7.5 L5.9687,15.5 C5.9687,15.776 6.1927,16 6.4687,16 M13.5,16 C13.776,16 14,15.776 14,15.5 L14,7.5 C14,7.224 13.776,7 13.5,7 C13.224,7 13,7.224 13,7.5 L13,15.5 C13,15.776 13.224,16 13.5,16 M9.9844,16 C10.2604,16 10.4844,15.776 10.4844,15.5 L10.4844,7.5 C10.4844,7.224 10.2604,7 9.9844,7 C9.7084,7 9.4844,7.224 9.4844,7.5 L9.4844,15.5 C9.4844,15.776 9.7084,16 9.9844,16 M17,17.5 C17,18.327 16.327,19 15.5,19 L4.5,19 C3.673,19 3,18.327 3,17.5 L3,3.969 L17,3.969 L17,17.5 Z M19,1.969 L12.443,1.969 L12.443,1 C12.443,0.448 11.995,0 11.443,0 L8.443,0 C7.89,0 7.443,0.448 7.443,1 L7.443,1.969 L1,1.969 C0.448,1.969 0,2.417 0,2.969 C0,3.521 0.448,3.969 1,3.969 L2,3.969 L2,17.5 C2,18.879 3.122,20 4.5,20 L15.5,20 C16.879,20 18,18.879 18,17.5 L18,3.969 L19,3.969 C19.553,3.969 20,3.521 20,2.969 C20,2.417 19.553,1.969 19,1.969 L19,1.969 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Delete;
