import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  cancelButtonProps: PropTypes.object,
  cancelButtonText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  /**
   * Dialog children, usually the included sub-components.
   */
  children: PropTypes.node,
  confirmButtonText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  isLoading: PropTypes.bool,
  isVerticalButtons: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  okButtonProps: PropTypes.object,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
} as any;
