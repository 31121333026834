import {
  app,
  auth,
  language,
  support,
  userProfile,
  cases,
  userManagement,
  documents,
  comments,
  caseForQuote,
  notifications,
  vendorDashboard,
  processVariances,
  frameForm,
  insurance,
} from '@northstar/core-ui/modules';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import productTermsReducer from 'modules/productTerms/productTermsReducer';
import assetReducer from 'modules/asset/assetReducer';
import equipmentReducer from 'modules/asset/equipmentReducer';
import frameReducer from 'modules/asset/frameReducer';
import assetsReducer from 'modules/assets/assetsReducer';
import carListReducer from 'modules/carList/carListReducer';
import caseAssetReducer from 'modules/caseDetail/caseAssetReducer';
import caseDetailReducer from 'modules/caseDetail/caseDetailReducer';
import creditReducer from 'modules/credit/creditReducer';
import hirePurchaseReducer from 'modules/hirePurchase/hirePurchaseReducer';
import kycReducer from 'modules/kyc/kycReducer';
import quoteReducer from 'modules/quote/quoteReducer';
import vendorTermsReducer from 'modules/vendor/vendorTermsReducer';
import importersReducer from 'modules/vendor/importers/importersReducer';

const containerReducer = {
  app: app.appReducer,
  auth: auth.authReducer,
  assets: assetsReducer,
  asset: combineReducers({
    asset: assetReducer,
    frame: frameReducer,
    equipment: equipmentReducer,
  }),
  credit: creditReducer,
  processVariances: processVariances.processVariancesReducer,
  frameForm: frameForm.frameFormReducer,
  hirePurchase: hirePurchaseReducer,
  carList: carListReducer,
  vendor: combineReducers({
    vendor: vendorTermsReducer,
    importers: importersReducer,
  }),
  documents: documents.documentsReducer,
  users: userManagement.userManagementReducer,
  cases: cases.casesReducer,
  comments: comments.commentsReducer,
  caseDetail: caseDetailReducer,
  caseAsset: caseAssetReducer,
  support: support.supportReducer,
  language: language.languageReducer,
  userProfile: userProfile.userProfileReducer,
  quote: quoteReducer,
  kyc: kycReducer,
  caseForQuote: caseForQuote.caseForQuoteReducer,
  notifications: notifications.notificationsReducer,
  productTerms: productTermsReducer,
  vendorDashboard: combineReducers({
    terms: vendorDashboard.vendorDashboardTermsReducer,
    assetList: vendorDashboard.vendorDashboardAssetListReducer,
    asset: vendorDashboard.vendorDashboardAssetReducer,
    frame: vendorDashboard.vendorDashboardFrameReducer,
    equipment: vendorDashboard.vendorDashboardEquipmentReducer,
    vendorDashboardApp: vendorDashboard.vendorDashboardAppReducer,
  }),
  insurance: insurance.insuranceReducer,
};

const createGlobalReducer = (history) =>
  combineReducers({
    ...containerReducer,
    form: reduxFormReducer,
    router: connectRouter(history),
  });

export default createGlobalReducer;
