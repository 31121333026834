"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectSearchCriteria = exports.selectOrderBy = exports.selectIsRestoringCase = exports.selectIsReloadingCases = exports.selectIsFiltersChanged = exports.selectIsExportingCases = exports.selectIsDeletingCase = exports.selectIsCaseCountLoading = exports.selectFilterProps = exports.selectEmployees = exports.selectDirection = exports.selectCasesVendor = exports.selectCasesSorting = exports.selectCasesRequest = exports.selectCasesPagination = exports.selectCasesLoading = exports.selectCasesFilter = exports.selectCasesDFSE = exports.selectCasesCount = exports.selectCases = exports.selectCaseDeletionStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _reselect = require("reselect");
var _languageSelectors = require("../language/languageSelectors");
var _caseStatuses = require("../../utils/case-statuses");
var _stringUtils = require("../../utils/string-utils");
var _arrayUtils = require("../../utils/array-utils");
var _casesConstants = require("./casesConstants");
var _casesMapper = require("./casesMapper");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const selectCasesState = state => state.cases;
const selectCasesCount = () => (0, _reselect.createSelector)(selectCasesState, state => state.get('casesCount'));
exports.selectCasesCount = selectCasesCount;
const selectIsCaseCountLoading = () => (0, _reselect.createSelector)(selectCasesState, state => state.get('gettingCasesCount'));
exports.selectIsCaseCountLoading = selectIsCaseCountLoading;
const selectCasesPagination = () => (0, _reselect.createSelector)(selectCasesState, state => state.get('pagination').toJS());
exports.selectCasesPagination = selectCasesPagination;
const selectCasesSorting = () => (0, _reselect.createSelector)(selectCasesState, state => state.get('sorting').toJS());
exports.selectCasesSorting = selectCasesSorting;
const selectCasesFilter = () => (0, _reselect.createSelector)(selectCasesState, state => state.get('filters').toJS());
exports.selectCasesFilter = selectCasesFilter;
const selectCases = () => (0, _reselect.createSelector)(selectCasesState, state => state.get('cases').toJS());
exports.selectCases = selectCases;
const selectCasesVendor = () => (0, _reselect.createSelector)(selectCases(), cases => cases.map(currentCase => {
  const brandAndModel = "".concat(currentCase.brand || '', " ").concat(currentCase.model || '');
  const applicantName = (0, _stringUtils.renderFullName)(currentCase);
  return _objectSpread(_objectSpread({}, currentCase), {}, {
    brandAndModel,
    applicantName
  });
}));
exports.selectCasesVendor = selectCasesVendor;
const selectCasesDFSE = () => (0, _reselect.createSelector)(selectCases(), cases => cases.map(currentCase => {
  const applicantName = (0, _stringUtils.renderFullName)(currentCase);
  return _objectSpread(_objectSpread({}, currentCase), {}, {
    applicantName
  });
}));
exports.selectCasesDFSE = selectCasesDFSE;
const selectCasesLoading = () => (0, _reselect.createSelector)(selectCasesState, state => state.get('gettingCases'));
exports.selectCasesLoading = selectCasesLoading;
const selectEmployees = () => (0, _reselect.createSelector)(selectCasesState, state => state.get('employees').toJS());
exports.selectEmployees = selectEmployees;
const selectOrderBy = () => (0, _reselect.createSelector)(selectCasesSorting(), sorting => sorting.orderBy);
exports.selectOrderBy = selectOrderBy;
const selectDirection = () => (0, _reselect.createSelector)(selectCasesSorting(), sorting => sorting.direction);
exports.selectDirection = selectDirection;
const selectCasesRequest = () => (0, _reselect.createSelector)(selectCasesPagination(), selectCasesSorting(), selectCasesFilter(), selectSearchCriteria(), selectCaseDeletionStatus(), (pagination, sorting, filter, searchCriteria, caseDeletionStatus) => _casesMapper.requestMappers.mapCases({
  pagination,
  sorting,
  filter,
  searchCriteria,
  caseDeletionStatus
}));
exports.selectCasesRequest = selectCasesRequest;
const selectFilterProps = () => (0, _reselect.createSelector)(selectCasesFilter(), selectStatuses(), selectEmployees(), (filters, statuses, employees) => {
  const {
    [_casesConstants.FILTER_FIELD_DATE_OPTION_SELECTED]: dateOption,
    [_casesConstants.FILTER_FIELD_DATE_FROM_SELECTED]: dateFromSelected,
    [_casesConstants.FILTER_FIELD_DATE_TO_SELECTED]: dateToSelected,
    [_casesConstants.FILTER_FIELD_EMPLOYEE_SELECTED]: selectedEmployee,
    [_casesConstants.FILTER_FIELD_STATUS_SELECTED]: selectedStatus,
    [_casesConstants.FILTER_FIELD_PRODUCT_TYPE_SELECTED]: productTypes,
    [_casesConstants.FILTER_FIELD_PRODUCT_SUB_TYPE_SELECTED]: productSubTypes,
    [_casesConstants.FILTER_FIELD_IMPORTER_NAME]: importerName
  } = filters;
  return {
    selectedEmployee,
    selectedStatus,
    selectedDateFrom: dateFromSelected,
    selectedDateTo: dateToSelected,
    option: dateOption,
    employees: employees.sort((0, _arrayUtils.createAlphabeticalSorter)()),
    productTypes,
    productSubTypes,
    filteredDisplayStatuses: statuses,
    importerName
  };
});
exports.selectFilterProps = selectFilterProps;
const selectStatuses = () => (0, _reselect.createSelector)((0, _languageSelectors.selectLocale)(), locale => _caseStatuses.DISPLAY_STATUSES.filter(_ref => {
  let {
    name
  } = _ref;
  return _caseStatuses.IGNORED_CASE_STAGES.indexOf(name) === -1;
}).map(status => ({
  label: status.text,
  value: status.name
})).filter((status, index, arr) => arr.findIndex(item => item.label.props.id === status.label.props.id) === index).sort((a, b) => (0, _arrayUtils.createAlphabeticalSorter)({
  isTranslations: true,
  locale
})(a.label.props.id, b.label.props.id)));
const selectSearchCriteria = () => (0, _reselect.createSelector)(selectCasesState, state => state.get('searchCriteria'));
exports.selectSearchCriteria = selectSearchCriteria;
const selectCaseDeletionStatus = () => (0, _reselect.createSelector)(selectCasesState, state => state.get('caseDeletionStatus'));
exports.selectCaseDeletionStatus = selectCaseDeletionStatus;
const selectIsDeletingCase = () => (0, _reselect.createSelector)(selectCasesState, state => state.get('deletingCase'));
exports.selectIsDeletingCase = selectIsDeletingCase;
const selectIsRestoringCase = () => (0, _reselect.createSelector)(selectCasesState, state => state.get('restoringCase'));
exports.selectIsRestoringCase = selectIsRestoringCase;
const selectIsFiltersChanged = () => (0, _reselect.createSelector)(selectCasesState, state => state.get('filtersChanged'));
exports.selectIsFiltersChanged = selectIsFiltersChanged;
const selectIsExportingCases = () => (0, _reselect.createSelector)(selectCasesState, state => state.get('exportingCases'));
exports.selectIsExportingCases = selectIsExportingCases;
const selectIsReloadingCases = () => (0, _reselect.createSelector)(selectCasesState, state => state.get('reloadingCases'));
exports.selectIsReloadingCases = selectIsReloadingCases;