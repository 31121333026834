"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSystemMessageResponse = exports.updateSystemMessageRequest = exports.refreshSystemMessageRequest = exports.initialState = exports.getSystemMessageResponse = exports.getSystemMessageRequest = exports.getActiveSystemMessageResponse = exports.getActiveSystemMessageRequest = exports.default = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
const getActiveSystemMessageRequest = exports.getActiveSystemMessageRequest = (0, _reduxActions.createAction)('SUPPORT_GET_ACTIVE_SYSTEM_MESSAGE_REQUEST');
const getActiveSystemMessageResponse = exports.getActiveSystemMessageResponse = (0, _reduxActions.createAction)('SUPPORT_GET_ACTIVE_SYSTEM_MESSAGE_RESPONSE');
const getSystemMessageRequest = exports.getSystemMessageRequest = (0, _reduxActions.createAction)('SUPPORT_GET_SYSTEM_MESSAGE_REQUEST');
const getSystemMessageResponse = exports.getSystemMessageResponse = (0, _reduxActions.createAction)('SUPPORT_GET_SYSTEM_MESSAGE_RESPONSE');
const updateSystemMessageRequest = exports.updateSystemMessageRequest = (0, _reduxActions.createAction)('SUPPORT_UPDATE_SYSTEM_MESSAGE_REQUEST');
const updateSystemMessageResponse = exports.updateSystemMessageResponse = (0, _reduxActions.createAction)('SUPPORT_UPDATE_SYSTEM_MESSAGE_RESPONSE');
const refreshSystemMessageRequest = exports.refreshSystemMessageRequest = (0, _reduxActions.createAction)('SUPPORT_UPDATE_SYSTEM_MESSAGE_VIEW_REQUEST');
const initialSystemMessageState = {
  message: '',
  active: false
};
const initialActiveSystemMessage = {
  message: '',
  display: false
};
const initialState = exports.initialState = (0, _immutable.fromJS)({
  isGettingActiveSystemMessage: false,
  activeSystemMessage: initialActiveSystemMessage,
  systemMessage: initialSystemMessageState,
  isLoadingSystemMessage: false
});
var _default = exports.default = (0, _reduxActions.handleActions)({
  [getActiveSystemMessageResponse](state, _ref) {
    let {
      payload,
      error
    } = _ref;
    return !error ? state.set('activeSystemMessage', (0, _immutable.fromJS)(payload)).set('isGettingActiveSystemMessage', false) : state.set('activeSystemMessage', (0, _immutable.fromJS)(initialActiveSystemMessage)).set('isGettingActiveSystemMessage', false);
  },
  [(0, _reduxActions.combineActions)(getSystemMessageRequest, updateSystemMessageRequest)](state) {
    return state.set('isLoadingSystemMessage', true);
  },
  [(0, _reduxActions.combineActions)(getSystemMessageResponse, updateSystemMessageResponse)](state, _ref2) {
    let {
      payload,
      error
    } = _ref2;
    return !error ? state.set('systemMessage', (0, _immutable.fromJS)(payload)).set('isLoadingSystemMessage', false) : state.set('systemMessage', (0, _immutable.fromJS)(initialSystemMessageState)).set('isLoadingSystemMessage', false);
  }
}, initialState);