import { Slider, SliderProps, Box, SxProps, Theme } from '@mui/material';
import { FC, ReactChild } from 'react';

import MAFHelperText from '../MAFHelperText/MAFHelperText';
import { handleCustomSx } from '../../utils';

import { styles } from './MAFSlider.styles';
import { propTypes } from './MAFSlider.propTypes';

export type TMAFSliderValue = number | number[];

export interface IMAFSliderProps
  extends Omit<SliderProps, 'title' | 'onChange' | 'onChangeCommitted'> {
  title?: ReactChild;
  titleValue?: ReactChild;
  output?: ReactChild;
  isError?: boolean;
  helperText?: ReactChild;
  additionalTextBellow?: ReactChild;
  trackColor?: string;
  sx?: SxProps<Theme>;
  onChange: (value: TMAFSliderValue) => void;
  onChangeCommitted: (value?: TMAFSliderValue) => void;
}

const MAFSlider: FC<IMAFSliderProps> = ({
  title,
  titleValue,
  output,
  isError = false,
  helperText,
  additionalTextBellow,
  trackColor,
  sx,
  marks,
  onChange,
  onChangeCommitted = () => ({}),
  disabled: isDisabled,
  value,
  ...rest
}) => {
  const trackClasses = [
    !isDisabled && styles.track,
    !isDisabled && trackColor === 'alternative' && styles.trackAlternative,
    isError && styles.trackError,
  ];

  const handleOnChange = (_: Event, changeValue: TMAFSliderValue) => onChange(changeValue);
  const handleOnChangeCommited = (_: Event, changeValue: TMAFSliderValue) =>
    onChangeCommitted(changeValue);

  const valueAsNumber = Number(value);
  return (
    <Box sx={[styles.container, ...handleCustomSx(sx)]} ns-target={rest['ns-target']}>
      {title && (
        <Box sx={styles.titleContainer}>
          <Box sx={styles.title}>{title}</Box>
          <Box>{titleValue}</Box>
        </Box>
      )}

      {output}
      <Slider
        sx={[
          styles.root,
          marks !== undefined && styles.rootWithMarks,
          styles.thumb,
          styles.rail,
          ...trackClasses,
          styles.mark,
          styles.markLabel,
          styles.active,
        ]}
        onChange={handleOnChange}
        onChangeCommitted={handleOnChangeCommited}
        disabled={isDisabled}
        {...rest}
        marks={marks || true}
        value={Number.isNaN(valueAsNumber) ? value : valueAsNumber}
        getAriaLabel={() => 'slider'}
      />
      {helperText && <MAFHelperText isError={isError}>{helperText}</MAFHelperText>}
      {additionalTextBellow && <Box ns-target="slider-additional-text">{additionalTextBellow}</Box>}
    </Box>
  );
};

MAFSlider.propTypes = propTypes;

export default MAFSlider;
