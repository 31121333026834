import { ISxStyles } from '../../../../theming/theme.type';

export const styles: ISxStyles = {
  checkboxContent: ({ spacing, breakpoints }) => ({
    margin: spacing(5, 0, -2),
    [breakpoints.only('xs')]: {
      margin: spacing(1, 0),
    },
  }),
  checkboxText: {
    lineHeight: '24px',
    marginLeft: 1,
    marginTop: '10px',
  },
  singleCheckbox: {
    marginTop: 1,
  },
};
