"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FEATURE_TYPES = void 0;
var Constants_1 = require("./Constants");
var ObjectUtils_1 = require("./ObjectUtils");
var getFinancialType = function (_a) {
    var productType = _a.productType, productSubType = _a.productSubType;
    return productType + "_" + productSubType;
};
exports.FEATURE_TYPES = {
    PR_OL: getFinancialType({
        productType: Constants_1.PRODUCT_TYPE_PRIVATE,
        productSubType: Constants_1.PRODUCT_SUB_TYPE_OPERATIONAL_LEASE,
    }),
    PR_HP: getFinancialType({
        productType: Constants_1.PRODUCT_TYPE_PRIVATE,
        productSubType: Constants_1.PRODUCT_SUB_TYPE_HIRE_PURCHASE,
    }),
    CM_OL: getFinancialType({
        productType: Constants_1.PRODUCT_TYPE_COMMERCIAL,
        productSubType: Constants_1.PRODUCT_SUB_TYPE_OPERATIONAL_LEASE,
    }),
    CM_FL: getFinancialType({
        productType: Constants_1.PRODUCT_TYPE_COMMERCIAL,
        productSubType: Constants_1.PRODUCT_SUB_TYPE_FINANCIAL_LEASE,
    }),
};
var isSingleVarianceEnabled = function (processVariance, configuration) { return configuration[processVariance] === Constants_1.PROCESS_VARIANCES_VALUES.PROCESS_VARIANCE_ENABLED; };
var isProcessVarianceEnabled = function (processVariance, processVarianceMap, partnerProcessVarianceMap) {
    if (processVarianceMap === void 0) { processVarianceMap = {}; }
    if (partnerProcessVarianceMap === void 0) { partnerProcessVarianceMap = {}; }
    var isPartnerPresent = !ObjectUtils_1.ObjectUtils.isNullOrEmpty(partnerProcessVarianceMap);
    var isProcessVarianceEnabledForPartner = isSingleVarianceEnabled(processVariance, partnerProcessVarianceMap);
    var isProcessVarianceEnabledForVendor = isSingleVarianceEnabled(processVariance, processVarianceMap);
    switch (processVariance) {
        case Constants_1.PROCESS_VARIANCES.USE_INSURANCE:
            if (isPartnerPresent) {
                return isProcessVarianceEnabledForPartner;
            }
            return isProcessVarianceEnabledForVendor;
        case Constants_1.PROCESS_VARIANCES.PRIVATE_OL:
        case Constants_1.PROCESS_VARIANCES.PRIVATE_HP:
        case Constants_1.PROCESS_VARIANCES.COMMERCIAL_OL:
        case Constants_1.PROCESS_VARIANCES.COMMERCIAL_FL:
            return isProcessVarianceEnabledForVendor || isProcessVarianceEnabledForPartner;
        default:
            return false;
    }
};
var getByExternalOrInternal = function (returnIfExternal, returnIfInternal) {
    if (process.env.REACT_APP_CURR_PROJECT === Constants_1.EXTERNAL_PROJECT_NAME)
        return returnIfExternal;
    if (process.env.REACT_APP_CURR_PROJECT === Constants_1.INTERNAL_PROJECT_NAME)
        return returnIfInternal;
    return returnIfExternal;
};
exports.default = {
    getFinancialType: getFinancialType,
    isProcessVarianceEnabled: isProcessVarianceEnabled,
    getByExternalOrInternal: getByExternalOrInternal,
};
