import { caseDetail } from '@northstar/core-ui/modules';
import { fromJS } from 'immutable';
import { createAction, handleActions, combineActions } from 'redux-actions';

import { getAssetResponse } from '../asset/assetReducer';

/**
 * Actions
 */
export const recalculatePriceRequest = createAction('QUOTE_RECALCULATE_PRICE_REQUEST');
export const recalculatePriceResponse = createAction('QUOTE_RECALCULATE_PRICE_RESPONSE');
export const updatePriceDeductionRequest = createAction(
  'QUOTE_UPDATE_COMMISSION_AND_DISCOUNT_REQUEST',
);
export const setUseDownPaymentPercentage = createAction('QUOTE_SET_USE_DOWN_PAYMENT_PERCENTAGE');
export const setQuoteData = createAction('QUOTE_SET_QUOTE_DATA');
export const clearDiscount = createAction('QUOTE_CLEAR_DISCOUNT');
export const clearQuoteState = createAction('QUOTE_CLEAR_STATE');

export const prefillNewCarsFlowEquipmentAndRedirect = createAction(
  'QUOTE_PREFILL_NEW_CARS_FLOW_EQUIPMENT',
);
export const saveQuoteCalculationsRequestOL = createAction(
  'QUOTE_SAVE_QUOTE_CALCULATIONS_REQUEST_OL',
);
export const saveQuoteCalculationsResponseOL = createAction(
  'QUOTE_SAVE_QUOTE_CALCULATIONS_RESPONSE_OL',
);
export const calculateQuoteRequestOL = createAction('QUOTE_CALCULATE_OL_REQUEST');
export const calculateQuoteRequestCMFL = createAction('QUOTE_CALCULATE_QUOTE_CMFL_REQUEST');
export const calculateQuoteResponseCMFL = createAction('QUOTE_CALCULATE_QUOTE_CMFL_RESPONSE');
export const getInitialValuesCMFLRequest = createAction('QUOTE_GET_INITIAL_VALUES_CMFL_REQUEST');
export const getInitialValuesCMFLResponse = createAction('QUOTE_GET_INITIAL_VALUES_CMFL_RESPONSE');
export const getResidualValuesCMFLRequest = createAction('QUOTE_GET_RESIDUAL_VALUES_CMFL_REQUEST');
export const getResidualValuesCMFLResponse = createAction(
  'QUOTE_GET_RESIDUAL_VALUES_CMFL_RESPONSE',
);

const initialInterestRateCMFL = {
  defaultTotalYearlyInterestRate: 0,
  minTotalYearlyInterestRate: null,
  maxTotalYearlyInterestRate: null,
};

const initialResidualValuesCMFL = {
  minRvValue: null,
  maxRvValue: null,
};

/**
 * Initial state
 */
export const initialState = fromJS({
  quoteData: {
    commission: 0,
    discount: 0,
  },
  additionalValuesCMFL: {
    interestRate: initialInterestRateCMFL,
    residualValue: initialResidualValuesCMFL,
    gettingInterestRateValues: false,
  },
  error: null,
  updatingPrice: false,
  useDownPaymentPercentage: true,
});

/**
 * Actions handlers
 */
export default handleActions(
  {
    [combineActions(recalculatePriceRequest, calculateQuoteRequestCMFL)](state) {
      return state.set('updatingPrice', true);
    },
    [combineActions(recalculatePriceResponse, calculateQuoteResponseCMFL)](
      state,
      { payload = {}, error },
    ) {
      return !error
        ? state.set('updatingPrice', false).set('quoteData', fromJS(payload)).set('error', null)
        : state.set('updatingPrice', false).set('error', payload.toString());
    },
    [combineActions(setQuoteData, caseDetail.setQuoteFromCase)](state, { payload }) {
      const { detailsPL, detailsHP, detailsCMOL, detailsCMFL, vendorTermsId } = payload;
      const details = detailsPL || detailsHP || detailsCMOL || detailsCMFL;
      return state.set('quoteData', fromJS({ ...details.quoteDetails, vendorTermsId }));
    },
    [clearDiscount](state) {
      return state.setIn(['quoteData', 'discount'], 0);
    },
    [getAssetResponse](state, { payload, error }) {
      return !error ? state.setIn(['quoteData', 'commision'], payload.commission) : state;
    },
    [setUseDownPaymentPercentage](state, { payload }) {
      return state.set('useDownPaymentPercentage', payload.useDownPaymentPercentage);
    },
    [getInitialValuesCMFLResponse](state, { payload, error }) {
      return !error
        ? state.setIn(['additionalValuesCMFL', 'interestRate'], fromJS(payload))
        : state.setIn(['additionalValuesCMFL', 'interestRate'], fromJS(initialInterestRateCMFL));
    },
    [getResidualValuesCMFLResponse](state, { payload, error }) {
      return !error
        ? state.setIn(['additionalValuesCMFL', 'residualValue'], fromJS(payload)).set('error', null)
        : state
            .setIn(['additionalValuesCMFL', 'residualValue', fromJS(initialResidualValuesCMFL)])
            .set('error', payload.toString());
    },
    [clearQuoteState]() {
      return initialState;
    },
  },
  initialState,
);
