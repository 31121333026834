import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  height: PropTypes.number,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  onRedirect: PropTypes.func.isRequired,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  width: PropTypes.number,
} as any;
