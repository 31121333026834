import { Box, IconButton } from '@mui/material';
import { ArrowBack, ArrowNext } from '@asfi/maf';
import { ReactChild } from 'react';

import HeaderMenu, { IHeaderMenuProps } from './HeaderMenu/HeaderMenu';
import { styles } from './header.styles';

interface IHeaderProps extends IHeaderMenuProps {
  isWithBack?: boolean;
  isFirstPage?: boolean;
  isWithNext?: boolean;
  handleBack: () => void;
  handleNext: () => void;
  middleComponent: ReactChild;
}

const Header: React.FC<IHeaderProps> = ({
  isWithBack,
  handleBack,
  isWithNext,
  handleNext,
  middleComponent,
  isFirstPage,
  ...rest
}) => (
  <Box sx={styles.container}>
    <Box sx={styles.leftContainer}>
      {isWithBack && (
        <IconButton onClick={handleBack} size="large">
          <Box component={ArrowBack} sx={styles.icon} />
        </IconButton>
      )}
    </Box>
    <Box sx={styles.centerContainer}>{middleComponent}</Box>
    <Box sx={styles.rightContainer}>
      {isFirstPage && <HeaderMenu {...rest} />}
      {isWithNext && (
        <IconButton onClick={handleNext} size="large">
          <Box component={ArrowNext} sx={styles.icon} />
        </IconButton>
      )}
    </Box>
  </Box>
);

export default Header;
