import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  formatText: PropTypes.func,
  highlightedItems: PropTypes.arrayOf(
    PropTypes.shape({
      formatRules: PropTypes.any,
      isNumber: PropTypes.bool,
      isWithEllipsis: PropTypes.bool,
      item: PropTypes.shape({
        label: PropTypes.node,
        text: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
          .isRequired,
      }),
      label: PropTypes.node,
      text: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]).isRequired,
      variant: PropTypes.oneOf(['primary', 'secondary']),
    }),
  ).isRequired,
} as any;
