const CloseOutlinedFilled: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="linearGradient-1">
        <stop stopColor="#F5F5F5" offset="0%"></stop>
        <stop stopColor="#FFFFFF" offset="50.1953125%"></stop>
        <stop stopColor="#FFFFFF" offset="100%"></stop>
      </linearGradient>
      <path
        d="M4.6443859,4.00005696 L7.86648629,0.777956568 C8.04465646,0.599786401 8.04465646,0.311797793 7.86648629,0.133627625 C7.6887718,-0.0445425418 7.40032752,-0.0445425418 7.22215735,0.133627625 L4.00005696,3.35572802 L0.777956568,0.133627625 C0.599786401,-0.0445425418 0.311342115,-0.0445425418 0.133627625,0.133627625 C-0.0445425418,0.311797793 -0.0445425418,0.599786401 0.133627625,0.777956568 L3.35572802,4.00005696 L0.133627625,7.22215735 C-0.0445425418,7.40032752 -0.0445425418,7.68831613 0.133627625,7.86648629 C0.22248487,7.95534354 0.339138483,8 0.455792097,8 C0.571990032,8 0.688643645,7.95534354 0.777956568,7.86648629 L4.00005696,4.6443859 L7.22215735,7.86648629 C7.31147027,7.95534354 7.42766821,8 7.54432182,8 C7.66097544,8 7.77762905,7.95534354 7.86648629,7.86648629 C8.04465646,7.68831613 8.04465646,7.40032752 7.86648629,7.22215735 L4.6443859,4.00005696 Z"
        id="closeOutlinedFilled"
      ></path>
    </defs>
    <g id="PLHP-done" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1440-/-Car-config-PL" transform="translate(-941.000000, -1570.000000)">
        <g id="Sidebar-Copy" transform="translate(917.000000, 104.000000)">
          <g id="Group-5" transform="translate(0.000000, 710.000000)">
            <g id="Tag/-Button-/-Default" transform="translate(24.000000, 756.000000)">
              <path
                d="M10,0 C4.486,0 0,4.486 0,10 C0,15.514 4.486,20 10,20 C15.514,20 20,15.514 20,10 C20,4.486 15.514,0 10,0"
                id="Base"
                fill="url(#linearGradient-1)"
              ></path>
              <g id="Icons/Close/Xsmall" transform="translate(6.000000, 6.000000)">
                <mask id="mask-3" fill="white">
                  <use href="#closeOutlinedFilled"></use>
                </mask>
                <use id="Fill-1" fill="#003755" href="#closeOutlinedFilled"></use>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CloseOutlinedFilled;
