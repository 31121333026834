"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCurrentUser = exports.getTheme = exports.getCurrentUser = void 0;
var _requestHandler = _interopRequireDefault(require("../../utils/request-handler"));
const {
  userDomain
} = _requestHandler.default;
const getCurrentUser = () => userDomain.GET_CURRENT_USER.call();
exports.getCurrentUser = getCurrentUser;
const updateCurrentUser = body => userDomain.EDIT_USER.call({
  body
});
exports.updateCurrentUser = updateCurrentUser;
const getTheme = () => userDomain.GET_THEME.call();
exports.getTheme = getTheme;