"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.jsonToExcelSheet = void 0;
var _xlsx = require("xlsx");
const jsonToExcelSheet = _ref => {
  let {
    data,
    options: {
      fileName,
      sheetName
    }
  } = _ref;
  const sheet = _xlsx.utils.json_to_sheet(data);
  const workbook = _xlsx.utils.book_new();
  _xlsx.utils.book_append_sheet(workbook, sheet, sheetName);
  (0, _xlsx.writeFile)(workbook, fileName);
};
exports.jsonToExcelSheet = jsonToExcelSheet;