"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.responseMappers = exports.requestMappers = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _utils = require("../../utils");
var _insuranceConstants = require("./insuranceConstants");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const mapMatrixToRequest = _ref => {
  let {
    name,
    rates,
    vendorIds,
    companyInformation
  } = _ref;
  return {
    matrixName: name,
    requestBody: _utils.objectUtils.objectToSnakeCase({
      obj: _objectSpread(_objectSpread({}, companyInformation), {}, {
        insuranceMatrices: _utils.matrixUtils.mapSingleMatrixToRequest({
          formValues: rates,
          name,
          rateFieldName: 'insurance_rate_ex_vat'
        }),
        vendorIds: vendorIds.vendors
      })
    })
  };
};
const mapMatrixFromResponse = _ref2 => {
  let {
    name,
    insurance_matrices: insuranceMatrices,
    insurance_company_firma_nr: insuranceCompanyFirmaNr,
    insurance_operational_code: insuranceOperationalCode,
    vendor_ids: vendorIds
  } = _ref2;
  return {
    name,
    insuranceCompanyFirmaNr,
    insuranceOperationalCode,
    vendorIds,
    rates: _utils.matrixUtils.mapSingleMatrixToStore({
      name,
      rates: insuranceMatrices,
      mileages: _insuranceConstants.MILEAGES,
      periods: _insuranceConstants.PERIODS
    })
  };
};
const requestMappers = exports.requestMappers = {
  mapMatrixToRequest
};
const responseMappers = exports.responseMappers = {
  mapMatrixFromResponse
};
var _default = exports.default = {
  requestMappers,
  responseMappers
};