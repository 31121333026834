import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  containerClass: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  /**
   * If `true`, the picker and text field are disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the label is displayed in an error state.
   * @default false
   */
  error: PropTypes.bool,
  /**
   * The helper text content.
   */
  helperText: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  /**
   * Format string.
   */
  inputFormat: PropTypes.string,
  /**
   * The label content.
   */
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  mask: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  maskedFormat: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  /**
   * Callback fired when the value (the selected date) changes @DateIOType.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * The short hint displayed in the `input` before the user enters a value.
   */
  placeholder: PropTypes.string,
  textFieldClass: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  /**
   * The value of the picker.
   */
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.shape({
      add: PropTypes.func.isRequired,
      clone: PropTypes.func.isRequired,
      day: PropTypes.func.isRequired,
      daysInMonth: PropTypes.func.isRequired,
      date: PropTypes.func.isRequired,
      diff: PropTypes.func.isRequired,
      endOf: PropTypes.func.isRequired,
      format: PropTypes.func.isRequired,
      get: PropTypes.func.isRequired,
      hour: PropTypes.func.isRequired,
      year: PropTypes.func.isRequired,
      isAfter: PropTypes.func.isRequired,
      isBefore: PropTypes.func.isRequired,
      isSame: PropTypes.func.isRequired,
      isValid: PropTypes.func.isRequired,
      locale: PropTypes.func.isRequired,
      millisecond: PropTypes.func.isRequired,
      minute: PropTypes.func.isRequired,
      month: PropTypes.func.isRequired,
      second: PropTypes.func.isRequired,
      set: PropTypes.func.isRequired,
      startOf: PropTypes.func.isRequired,
      subtract: PropTypes.func.isRequired,
      toDate: PropTypes.func.isRequired,
      toISOString: PropTypes.func.isRequired,
      toJSON: PropTypes.func.isRequired,
      toString: PropTypes.func.isRequired,
      unix: PropTypes.func.isRequired,
      utcOffset: PropTypes.func.isRequired,
      valueOf: PropTypes.func.isRequired,
    }),
    PropTypes.string,
  ]),
} as any;
