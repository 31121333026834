import { MAFTypography, useScreenSize } from '@asfi/maf';
import { Box } from '@mui/material';

import { ISpecifications } from 'types';

import { styles } from './specificationBox.styles';

interface ISpecificationBoxProps {
  specifications: ISpecifications[];
}

const SpecificationBox: React.FC<ISpecificationBoxProps> = ({ specifications }) => {
  const { isMobile, isTablet, isDesktop } = useScreenSize();
  const columnStyles = [
    (isTablet || isDesktop) && styles.tripleColumns,
    isMobile && styles.doubleColumns,
    styles.column,
  ];

  return (
    <Box sx={styles.container}>
      {specifications
        .filter(({ shouldRender = true }) => shouldRender)
        .map(({ label, value }, index) => (
          <Box sx={columnStyles} key={index}>
            <MAFTypography variant="body1" isWithContainer>
              {label}
            </MAFTypography>
            <MAFTypography variant="sub1-semi-bold" sx={styles.value}>
              {value}
            </MAFTypography>
          </Box>
        ))}
    </Box>
  );
};

export default SpecificationBox;
