import { CASE_STATUS } from '@northstar/core';

export const GENERAL_INFORMATION_FORM = 'generalInformationForm';
export const SOLE_TRADER_INFORMATION_FORM = 'soleTraderInformationForm';
export const SME_INFORMATION_FORM = 'SMEInformationForm';
export const COMPANY_INFORMATION_FORM = 'companyInformationForm';

export const VENDOR_DELETE_ALLOW_STATUSES = [
  CASE_STATUS.IN_PROGRESS,
  CASE_STATUS.CREDIT_AWAITING_AUTOMATIC_DECISION,
  CASE_STATUS.CREDIT_AWAITING_MANUAL_DECISION,
  CASE_STATUS.CREDIT_REJECTED,
  CASE_STATUS.CREDIT_AUTO_REJECTED,
  CASE_STATUS.CREDIT_APPROVED,
  CASE_STATUS.CREDIT_CONDITIONALLY_APPROVED,
  CASE_STATUS.CREDIT_AGREEMENT_CANCELLED,
  CASE_STATUS.CREDIT_AGREEMENT_EXPIRED,
  CASE_STATUS.AGREEMENT_ACTIVATED,
];
