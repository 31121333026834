"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.replaceElementInArray = exports.groupBy = exports.createAlphabeticalSorter = exports.chunck = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _core = require("@northstar/core");
var _translationsUtils = require("./translations-utils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * Split array into chuncks with provided size
 * @param {Array} arr
 * @param {Inter} size
 */
const chunck = (arr, size) => {
  if (arr.length === 0) return [];
  const total = arr.length / size;
  const chuncks = [];
  let start = 0;
  for (let i = 1; i <= size; i += 1) {
    chuncks.push(arr.slice(start, total * i));
    start += total;
  }
  return chuncks;
};
/**
 * Group array values by provided key or keys
 * @param {Array} groupArray Array of elements
 * @param {*} prop group by key or array of prop
 */
exports.chunck = chunck;
const groupBy = (items, prop) => items.reduce((groups, item) => {
  const groupedValue = Array.isArray(prop) ? prop.map(singleProp => item[singleProp]).join(',') : item[prop];
  const prevValue = groups[groupedValue] || [];
  return _objectSpread(_objectSpread({}, groups), {}, {
    [groupedValue]: [...prevValue, item]
  });
}, {});
exports.groupBy = groupBy;
const replaceElementInArray = _ref => {
  let {
    array,
    rule,
    replacer
  } = _ref;
  return array.map(value => rule(value) ? replacer(value) : value);
};
exports.replaceElementInArray = replaceElementInArray;
const ORDER_ASC = 'ASC';
const convertToString = value => !_core.ObjectUtils.isNullOrUndefined(value) && value.toString ? value.toString() : '';
const getAcomparedToB = _ref2 => {
  let {
    a,
    b,
    isTranslations,
    locale
  } = _ref2;
  return isTranslations ? (0, _translationsUtils.getTranslationByKey)({
    key: a,
    locale
  }).localeCompare((0, _translationsUtils.getTranslationByKey)({
    key: b,
    locale
  })) : convertToString(a).localeCompare(convertToString(b));
};
const createAlphabeticalSorter = function () {
  let {
    property,
    order = ORDER_ASC,
    isTranslations,
    locale
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (a, b) => {
    const aComparedToB = getAcomparedToB({
      a: property ? a[property] : a,
      b: property ? b[property] : b,
      isTranslations,
      locale
    });
    return order === ORDER_ASC ? aComparedToB : -aComparedToB;
  };
};
exports.createAlphabeticalSorter = createAlphabeticalSorter;