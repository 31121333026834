import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  customLabelClass: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  formControlProps: PropTypes.object,
  formHelperTextProps: PropTypes.object,
  helperText: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  isError: PropTypes.bool,
  isLabelInside: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  labelProps: PropTypes.object,
  tooltipText: PropTypes.node,
} as any;
