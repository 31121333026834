"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPowertrainContainsHybrid = void 0;
const getPowertrainContainsHybrid = powertrainVal => {
  if (typeof powertrainVal !== 'string') return false;
  return powertrainVal === null || powertrainVal === void 0 ? void 0 : powertrainVal.toLowerCase().includes('hybrid');
};
exports.getPowertrainContainsHybrid = getPowertrainContainsHybrid;