import { Chip, ChipProps, Box, SxProps, Theme } from '@mui/material';

import CloseOutlined from '../../Icons/CloseOutlined';
import MAFIcon from '../../MAFIcon/MAFIcon';
import { handleCustomSx } from '../../../utils';

import { styles } from './MAFChip.styles';
import { propTypes } from './MAFChip.propTypes';

export interface IMAFChipProps extends Omit<ChipProps, 'color' | 'children'> {
  color?: string;
  isCompact?: boolean;
  customChipClass?: SxProps<Theme>;
  onBlur?: () => void;
}

const MAFChip: React.FC<IMAFChipProps> = ({
  color = 'primaryPR',
  label,
  isCompact = false,
  customChipClass,
  onDelete,
  disabled: isDisabled,
  onBlur,
}) => {
  const handleOnDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    onDelete && onDelete(e);
    onBlur && onBlur();
  };
  return (
    <Box sx={[styles.chipContainer, ...handleCustomSx(customChipClass)]}>
      <Chip
        sx={[
          color === 'primaryPR' && styles.primaryPR,
          color === 'primaryCM' && styles.primaryCM,
          color === 'blue' && styles.blue,
          styles.root,
          isCompact && styles.compact,
          styles.disabled,
        ]}
        tabIndex={-1}
        label={label}
        deleteIcon={
          <MAFIcon
            Icon={CloseOutlined}
            sx={[
              styles.icon,
              color === 'primaryPR' && styles.iconPrimaryPR,
              color === 'primaryCM' && styles.iconPrimaryCM,
            ]}
            iconButtonClass={styles.iconButton}
            onClick={handleOnDelete}
            isDisabled={isDisabled}
          />
        }
        onDelete={handleOnDelete}
        disabled={isDisabled}
      />
    </Box>
  );
};

MAFChip.propTypes = propTypes;

export default MAFChip;
