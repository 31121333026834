const Link: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="PLHP-done" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1440-/-Car-config-PL" transform="translate(-1039.000000, -724.000000)" fill="#003755">
        <g id="Sidebar-Copy" transform="translate(917.000000, 104.000000)">
          <g id="Button-/-Small-/-White-/-Centered" transform="translate(24.000000, 610.000000)">
            <g id="Group-13" transform="translate(29.000000, 8.000000)">
              <g id="Group" transform="translate(67.000000, 0.000000)">
                <g id="Icons/LinkToRealtor/20px" transform="translate(2.000000, 2.000000)">
                  <path
                    d="M0.8,16 C0.3581722,16 -7.23047817e-16,15.6418278 0,15.2 L0,3.2 C-4.99461232e-17,2.79215895 0.305188147,2.45559842 0.6996497,2.40623314 L0.8,2.4 L7.6,2.4 C7.8209139,2.4 8,2.5790861 8,2.8 C8,3.0209139 7.8209139,3.2 7.6,3.2 L0.799,3.199 L0.799,15.2 L12.8,15.2 L12.8,8.4 C12.8,8.1790861 12.9790861,8 13.2,8 C13.4209139,8 13.6,8.1790861 13.6,8.4 L13.6,15.2 C13.6,15.607841 13.2948119,15.9444016 12.9003503,15.9937669 L12.8,16 L0.8,16 Z M15.2,2.77555756e-16 C15.607841,2.39124959e-16 15.9444016,0.305188147 15.9937669,0.6996497 L16,0.8 L16,6 C16,6.2209139 15.8209139,6.4 15.6,6.4 C15.3790861,6.4 15.2,6.2209139 15.2,6 L15.2,1.365 L8.82010101,7.74558441 C8.6638913,7.90179413 8.4106253,7.90179413 8.25441559,7.74558441 C8.09820587,7.5893747 8.09820587,7.3361087 8.25441559,7.17989899 L14.633,0.8 L10,0.8 C9.7790861,0.8 9.6,0.6209139 9.6,0.4 C9.6,0.1790861 9.7790861,-8.60422844e-16 10,-6.66133815e-16 L15.2,2.77555756e-16 Z"
                    id="Combined-Shape"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Link;
