import { Menu, MenuItem } from '@mui/material';
import { ReactChild, ReactInstance, useState } from 'react';
import { MAFIcon, KebabMenu, useScreenSize } from '@asfi/maf';

import PrintQuote from '../PrintQuote/PrintQuote';

import { styles } from './headerMenu.styles';

export interface IHeaderMenuProps {
  quoteRef?: ReactInstance;
  handlePrint: () => void;
  languageComponent: ReactChild;
}

const HeaderMenu: React.FC<IHeaderMenuProps> = ({ quoteRef, handlePrint, languageComponent }) => {
  const { isDesktop } = useScreenSize();

  const [menu, setMenu] = useState<(EventTarget & HTMLElement) | null>(null);
  const handleMenu = (e: React.MouseEvent<HTMLElement>) => setMenu(menu ? null : e.currentTarget);

  return isDesktop ? (
    <>
      {languageComponent}
      <PrintQuote quoteRef={quoteRef} handlePrint={handlePrint} isOnlyIcon />
    </>
  ) : (
    <>
      <MAFIcon Icon={KebabMenu} sx={styles.icon} onClick={handleMenu} />
      <Menu
        anchorEl={menu}
        keepMounted
        open={Boolean(menu)}
        onClose={handleMenu}
        sx={styles.menuContainer}
      >
        <MenuItem sx={styles.menuItem}>{languageComponent}</MenuItem>
        <MenuItem>
          <PrintQuote quoteRef={quoteRef} handlePrint={handlePrint} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderMenu;
