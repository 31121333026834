"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createInsuranceMatrix = void 0;
exports.default = insuranceSaga;
exports.getInsuranceMatrix = exports.deleteInsuranceMatrix = void 0;
exports.getInsuranceNames = getInsuranceNames;
exports.patchInsuranceMatrix = void 0;
var _effects = require("redux-saga/effects");
var _appReducer = require("../app/appReducer");
var _dashboardSagaUtils = require("../vendorDashboard/vendorDashboardUtils/dashboard-saga-utils");
var _reduxSagaUtils = require("../../utils/redux-saga-utils");
var insuranceApi = _interopRequireWildcard(require("./insuranceApi"));
var _insuranceReducer = require("./insuranceReducer");
var _insuranceMappers = require("./insuranceMappers");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function getInsuranceNames() {
  let {
    payload = {}
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function* () {
    try {
      const {
        withoutVendorId
      } = payload;
      const response = withoutVendorId ? yield (0, _effects.call)(insuranceApi.getInsuranceNames, payload) : yield (0, _reduxSagaUtils.callWithAttachers)({
        endpoint: insuranceApi.getInsuranceNames,
        payload,
        attachers: [_dashboardSagaUtils.attachRouteVendorId]
      });
      yield (0, _effects.put)((0, _insuranceReducer.getInsuranceNamesResponse)(response));
    } catch (e) {
      yield (0, _effects.put)((0, _insuranceReducer.getInsuranceNamesResponse)(e));
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
    }
  }();
}
const getInsuranceMatrix = exports.getInsuranceMatrix = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: insuranceApi.getInsuranceMatrix,
  requestMapper: _ref => {
    let {
      name
    } = _ref;
    return {
      matrixName: name
    };
  },
  responseMapper: _insuranceMappers.responseMappers.mapMatrixFromResponse,
  successActions: [_insuranceReducer.getInsuranceMatrixResponse],
  errorActions: [_insuranceReducer.getInsuranceMatrixResponse, _appReducer.displayError]
});
const createInsuranceMatrix = exports.createInsuranceMatrix = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: insuranceApi.createInsuranceMatrix,
  requestMapper: _insuranceMappers.requestMappers.mapMatrixToRequest,
  responseMapper: _insuranceMappers.responseMappers.mapMatrixFromResponse,
  successActions: [() => (0, _insuranceReducer.getInsuranceNamesRequest)({
    withoutVendorId: true
  }), _insuranceReducer.createInsuranceMatrixResponse],
  errorActions: [_insuranceReducer.createInsuranceMatrixResponse, _appReducer.displayError]
});
const patchInsuranceMatrix = exports.patchInsuranceMatrix = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: insuranceApi.patchInsuranceMatrix,
  requestMapper: _insuranceMappers.requestMappers.mapMatrixToRequest,
  responseMapper: _insuranceMappers.responseMappers.mapMatrixFromResponse,
  successActions: [_insuranceReducer.patchInsuranceMatrixResponse],
  errorActions: [_insuranceReducer.patchInsuranceMatrixResponse, _appReducer.displayError]
});
const deleteInsuranceMatrix = exports.deleteInsuranceMatrix = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: insuranceApi.deleteInsuranceMatrix,
  requestMapper: _ref2 => {
    let {
      name
    } = _ref2;
    return {
      matrixName: name
    };
  },
  successActions: [() => (0, _insuranceReducer.getInsuranceNamesRequest)({
    withoutVendorId: true
  }), _insuranceReducer.deleteInsuranceMatrixResponse],
  errorActions: [_insuranceReducer.deleteInsuranceMatrixResponse, _appReducer.displayError]
});
function* insuranceSaga() {
  yield (0, _effects.takeLatest)(_insuranceReducer.getInsuranceNamesRequest, getInsuranceNames);
  yield (0, _effects.takeLatest)(_insuranceReducer.getInsuranceMatrixRequest, getInsuranceMatrix);
  yield (0, _effects.takeLatest)(_insuranceReducer.createInsuranceMatrixRequest, createInsuranceMatrix);
  yield (0, _effects.takeLatest)(_insuranceReducer.patchInsuranceMatrixRequest, patchInsuranceMatrix);
  yield (0, _effects.takeLatest)(_insuranceReducer.deleteInsuranceMatrixRequest, deleteInsuranceMatrix);
}