"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: {
    paddingRight: '20px',
    marginBottom: '20px',
    width: '150px'
  },
  title: _ref => {
    let {
      colors
    } = _ref;
    return {
      color: colors.alpha(colors.custom.fontPrimary, 0.7)
    };
  }
};