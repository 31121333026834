import { ReactChild, FC } from 'react';
import { Box, SxProps, Theme } from '@mui/material';

import { Add } from '../../Icons';
import MAFButton from '../../MAFButton/MAFButton';
import MAFIcon from '../../MAFIcon/MAFIcon';
import MAFHelperText from '../../MAFHelperText/MAFHelperText';
import { handleCustomSx } from '../../../utils';

import { styles } from './AddCard.styles';
import { propTypes } from './AddCard.propTypes';

interface IAddCardProps {
  onAdd: () => void;
  title: ReactChild;
  sx?: SxProps<Theme>;
  isError?: boolean;
  helperText?: ReactChild;
}

const AddCard: FC<IAddCardProps> = ({ onAdd, title, sx, isError, helperText }) => (
  <>
    <MAFButton
      sx={[styles.button, ...handleCustomSx(sx)]}
      startIcon={<MAFIcon Icon={Add} sx={styles.icon} />}
      onClick={onAdd}
      size="wide"
    >
      <Box component="span" sx={styles.closedText}>
        {title}
      </Box>
    </MAFButton>
    {helperText && <MAFHelperText isError={isError}>{helperText}</MAFHelperText>}
  </>
);

AddCard.propTypes = propTypes;

export default AddCard;
