import { ReactNode, FC } from 'react';
import {
  Theme,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  AccordionProps,
  Box,
  SxProps,
} from '@mui/material';

import MAFIcon from '../MAFIcon/MAFIcon';
import MAFHighLightItems from '../MAFHighlightItems/MAFHighlightItems';
import { TMAFHighlightItem } from '../MAFHighlightItem/MAFHighLightItem';
import ArrowDown from '../Icons/ArrowDown';
import { handleCustomSx } from '../../utils';

import { styles } from './MAFAccordion.styles';
import { propTypes } from './MAFAccordion.propTypes';

export interface IMAFAccordionProps extends Omit<AccordionProps, 'children' | 'title'> {
  title?: ReactNode;
  children?: ReactNode;
  isSection?: boolean;
  highlightedItems?: TMAFHighlightItem[];
  highlightCustomTemplate?: ReactNode;
  shouldUnmountOnExit?: boolean;
  sx?: SxProps<Theme>;
  expandedPanelSummaryClass?: SxProps<Theme>;
  accordionDetailsSx?: SxProps<Theme>;
}

const MAFAccordion: FC<IMAFAccordionProps> = ({
  title,
  children,
  isSection = false,
  disabled: isDisabled = false,
  highlightedItems,
  highlightCustomTemplate,
  shouldUnmountOnExit = false,
  onChange = () => {},
  defaultExpanded: isDefaultExpanded = false,
  expanded: isExpanded,
  sx,
  expandedPanelSummaryClass,
  accordionDetailsSx,
}) =>
  isSection ? (
    <AccordionSummary
      sx={[styles.isExpandableRoot, styles.summaryPanelContent, ...handleCustomSx(sx)]}
    >
      {title}
    </AccordionSummary>
  ) : (
    <Accordion
      sx={[styles.panelRoot, styles.panelExpanded, ...handleCustomSx(sx)]}
      TransitionProps={{ timeout: 500, unmountOnExit: shouldUnmountOnExit }}
      onChange={onChange}
      defaultExpanded={isDefaultExpanded}
      expanded={isExpanded}
    >
      <AccordionSummary
        sx={[
          styles.summaryPanelRoot,
          styles.summaryPanelContent,
          ...handleCustomSx(expandedPanelSummaryClass),
        ]}
        disabled={isDisabled}
        expandIcon={
          isSection ? undefined : <MAFIcon sx={styles.summaryPanelExpandIcon} Icon={ArrowDown} />
        }
        screenshot-id="accordion-summary"
      >
        <Box sx={styles.summaryPanelChild}>
          {title}
          {highlightCustomTemplate}
          {highlightedItems && <MAFHighLightItems highlightedItems={highlightedItems} />}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={[styles.detailsPanelRoot, ...handleCustomSx(accordionDetailsSx)]}>
        {children}
      </AccordionDetails>
    </Accordion>
  );

MAFAccordion.propTypes = propTypes;

export default MAFAccordion;
