"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.winterTiresRates = exports.users = exports.terms = exports.serviceRates = exports.insuranceRates = exports.deletedAssets = exports.assets = exports.VENDOR_DASHBOARD_PATH = void 0;
const terms = exports.terms = 'terms';
const assets = exports.assets = 'assets';
const serviceRates = exports.serviceRates = 'service-rates';
const users = exports.users = 'users';
const insuranceRates = exports.insuranceRates = 'insurance-rates';
const winterTiresRates = exports.winterTiresRates = 'winter-tires-rates';
const deletedAssets = exports.deletedAssets = 'deleted-assets';
const VENDOR_DASHBOARD_PATH = exports.VENDOR_DASHBOARD_PATH = {
  buildSearch: _ref => {
    let {
      isCommercial
    } = _ref;
    return typeof isCommercial === 'boolean' ? new URLSearchParams({
      isCommercial: Number(isCommercial)
    }).toString() : undefined;
  },
  tabs: {
    terms,
    assets,
    serviceRates,
    users,
    insuranceRates,
    winterTiresRates,
    deletedAssets
  }
};