import { VEHICLE_TYPE_CAR, VEHICLE_TYPE_VAN } from '@northstar/core';
import { app, asset, FRAME_FORM } from '@northstar/core-ui/modules';
import * as frameApi from '@northstar/core-ui/modules/asset/frameApi';
import { getFormValues } from 'redux-form';
import { call, put, takeLatest, select } from 'redux-saga/effects';

import { updateImporter } from '../caseDetail/caseDetailSaga';
import { resetGeneralCarInformationInCase } from '../caseDetail/caseDetailReducer';

import { getEquipmentRequest } from './equipmentReducer';
import { submitFrameRequest, submitFrameResponse, getFrameResponse } from './frameReducer';

const { responseMappers, formMappers } = asset.frameMappers;

export function* submitFrame({ payload }) {
  try {
    const formValues = yield select(getFormValues(FRAME_FORM));
    const requestBody = formMappers.mapFrameFormToRequest(formValues);
    const apiCall =
      formValues.vehicleType.toLowerCase() === VEHICLE_TYPE_VAN.toLowerCase()
        ? asset.findFrameVan
        : asset.findFrame;
    const response = yield call(apiCall, requestBody);
    if (Array.isArray(response) && response.length) {
      yield put(submitFrameResponse(responseMappers.mapFrame(response[0])));
      yield put(getEquipmentRequest());
      if (typeof payload.redirect === 'function') {
        yield call(payload.redirect, {
          frameId: response[0].id,
          vehicleType: response[0].vehicle_type,
        });
      }
      yield call(updateImporter);
      yield put(resetGeneralCarInformationInCase());
    } else {
      throw new Error('errors.could_not_create_frame');
    }
  } catch (e) {
    if (e.httpResponse) {
      yield put(app.displayError(e));
    } else {
      yield put(app.displayError(e.message));
    }
    yield put(submitFrameResponse(e));
  }
}

export function* getFrame({ payload }) {
  const { frameId, vehicleType } = payload;
  const apiToCall = vehicleType === VEHICLE_TYPE_CAR ? frameApi.getFrame : frameApi.getFrameVan;
  const response = yield call(apiToCall, { frameId });
  yield put(getFrameResponse(responseMappers.mapFrame(response)));
}

export default function* frameSaga() {
  yield takeLatest(submitFrameRequest, submitFrame);
}
