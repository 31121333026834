/* eslint-disable camelcase */
import { requestHandler } from '@northstar/core-ui/utils';

const { vendorCaseDomain } = requestHandler;

export const updateAssetPricePROL = ({ resourceId, importerId, requestBody, draftScenarioId }) =>
  vendorCaseDomain.UPDATE_ASSET_PR_OL.call({
    urlParams: { resourceId, importerId },
    body: { ...requestBody, draft_scenario_id: draftScenarioId },
  });

export const updateAssetPricePRHP = ({ resourceId, importerId, requestBody, draftScenarioId }) =>
  vendorCaseDomain.UPDATE_ASSET_PR_HP.call({
    urlParams: { resourceId, importerId },
    body: { ...requestBody, draft_scenario_id: draftScenarioId },
  });

export const updateAssetCMOL = ({ resourceId, importerId, requestBody }) =>
  vendorCaseDomain.UPDATE_ASSET_CM_OL.call({
    urlParams: { resourceId, importerId },
    body: requestBody,
  });

export const updateAssetCMFL = ({ resourceId, importerId, requestBody }) =>
  vendorCaseDomain.UPDATE_ASSET_CM_FL.call({
    urlParams: { resourceId, importerId },
    body: requestBody,
  });
