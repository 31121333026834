const CreditApproved: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 18 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="07-Case-overview" transform="translate(-1207.000000, -414.000000)">
        <g id="case-credit-approved" transform="translate(1208.000000, 415.000000)">
          <rect id="Rectangle-15" x="0" y="0" width="16" height="18" rx="1"></rect>
          <rect
            id="Rectangle-15"
            stroke="#fff"
            x="-0.5"
            y="-0.5"
            width="17"
            height="19"
            rx="1"
          ></rect>
          <polyline
            id="Path-8"
            stroke="#fff"
            strokeWidth="1.5"
            strokeLinecap="round"
            points="5 11.4490967 7.28552246 14.052002 11.2788086 8"
          ></polyline>
          <path
            d="M1.33333333,3.5 L14.6666667,3.5"
            id="Line-5"
            stroke="#fff"
            strokeWidth="1.5"
            strokeLinecap="round"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default CreditApproved;
