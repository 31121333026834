import { arrayUtils, objectUtils, matrixUtils } from '@northstar/core-ui/utils';

const mapTermsPROLtoStore = (terms) => {
  const { winterTiresRates, yearlyMileages, leasePeriods, ...camelCasedTerms } =
    objectUtils.objectToCamelCase({
      obj: terms,
      override: {
        winter_tires_rate_ex_vat: 'rate',
      },
    });
  return {
    ...camelCasedTerms,
    winterTiresRates: matrixUtils.transformRatesToTables(winterTiresRates),
    yearlyMileages: yearlyMileages.sort(),
    leasePeriods: leasePeriods.sort(),
  };
};
const mapTermsPRHPtoStore = (terms) => objectUtils.objectToCamelCase({ obj: terms });
const mapTermsCMOLtoStore = (terms) => {
  const { yearlyMileages, leasePeriods, ...camelCasedTerms } = objectUtils.objectToCamelCase({
    obj: terms,
  });
  return {
    ...camelCasedTerms,
    yearlyMileages: yearlyMileages.sort(),
    leasePeriods: leasePeriods.sort(),
  };
};
const mapTermsCMFLtoStore = (terms) => {
  const { leasePeriods, ...camelCasedTerms } = objectUtils.objectToCamelCase({ obj: terms });
  return {
    ...camelCasedTerms,
    leasePeriods: leasePeriods.sort(),
  };
};

const mapVendorToStore = ({ process_variances: processVariances, ...vendor }) => {
  const { orderCompanies, ...camelCasedVendor } = objectUtils.objectToCamelCase({ obj: vendor });
  return {
    ...camelCasedVendor,
    processVariances,
    orderCompanies: orderCompanies.sort(arrayUtils.createAlphabeticalSorter({ property: 'name' })),
  };
};

export const responseMappers = {
  mapVendor: mapVendorToStore,
  mapTermsPROL: mapTermsPROLtoStore,
  mapTermsPRHP: mapTermsPRHPtoStore,
  mapTermsCMOL: mapTermsCMOLtoStore,
  mapTermsCMFL: mapTermsCMFLtoStore,
};
