import { alpha } from '@mui/material/styles';

export const fieldSizes = {
  minWidth: 272,
  maxWidth: 420,
};

export const mainColors = {
  gradients: {
    orange: 'linear-gradient(141.66deg, #ffe17f 0%, #fbb273 100%)',
    green: 'linear-gradient(142.57deg, #09b89d 0%, #6dbace 100%)',
    red: 'linear-gradient(143.64deg, #E91E63 0%, #F44336 100%)',
    black: 'linear-gradient(rgba(0,0,0, 0) 90%, rgba(0,0,0, 1) 100%)',
  },
  solid: {
    cadedDarkGray: '#2d3334',
    darkBlueGray: '#545c5f',
    darkGray: '#3E4548',
    solidGray: '#5e6769',
    redGray: '#808080',
    cadedGray: '#3A4344',
    gray: '#848B8D',
    lightRedGray: '#bdbdbd',
    lightBlueGray: '#A6ADB0',
    lightGray: '#bec2c3',
    lighterGray: '#c5c8cb',
    whiteGray: '#EFF0F1',
    gainsboro: '#dcdcdc',

    darkBlue: '#253e6a',
    blue: '#009fda',
    lightBlue: '#6dbace',
    cadedBlue: '#003755',
    whiteBlue: '#E0EEF5',

    red: '#e65a6d',
    lightRed: '#f44336',
    redBackground: '#b33434',

    purple: '#74489d',
    green: '#09b89d',
    orange: '#cb7e3c',
    white: '#ffffff',
    black: '#000000',
  },
  danske: {
    white: '#FFFFFF',
    background: '#F9FAFA',
    action: '#009FDA',
    shadow: '#3A4344',
    primary100: '#003755',
    primary70: '#406980',
    primary50: '#7F9BAA',
    primary30: '#BFCDD4',
    primary10: '#E5EBEE',
    primary6: '#D7E9F1',
    primary1: '#F7FAFC',
    info100: '#6DBACE',
    info80: '#8BC5D7',
    info60: '#A8D2E0',
    info40: '#C3E0EB',
    info20: '#E0EEF4',
    info10: '#EFF6F9',
    info1: '#F8FBFC',
    alert100: '#E65A6D',
    alert80: '#EB7C83',
    alert60: '#EF9A9C',
    alert40: '#F4BAB8',
    alert20: '#F9DAD8',
    alert10: '#FCECEA',
    caution100: '#FFE17F',
    caution80: '#FFE698',
    caution60: '#FFECB0',
    caution40: '#FFF3CA',
    caution20: '#FFF9E2',
    caution10: '#FFFBF0',
    success100: '#09B89D',
    success80: '#5EC3AE',
    success60: '#8AD0BF',
    success40: '#B2DFD3',
    success20: '#D6EDE7',
    success10: '#EAF6F3',
    alt100: '#74489D',
    alt80: '#8865AC',
    alt60: '#9F84BD',
    alt40: '#BAA7D1',
    alt20: '#D9D0E7',
    alt10: '#EBE6F2',
    attention100: '#FBB273',
    attention80: '#FCC18B',
    attention60: '#FDCFA5',
    attention40: '#FEDDBF',
    attention20: '#FEEEDC',
    attention10: '#FEF6ED',
    greyA100: '#7B8185',
    greyA80: '#93989C',
    greyA60: '#ABAFB2',
    greyA40: '#C5C8CB',
    greyA20: '#E0E2E3',
    greyA10: '#EFF0F1',
    greyB100: '#E7E3DD',
    greyB80: '#EBE8E4',
    greyB60: '#F0EEEA',
    greyB40: '#F6F4F2',
    greyB20: '#FAFAF8',
    greyB10: '#FCFBFB',
    greyC100: '#C7CECB',
    greyC80: '#D2D7D5',
    greyC60: '#DBE0DE',
    greyC40: '#E8EBEA',
    greyC20: '#F3F5F4',
    greyC10: '#F9FAFA',
  },
  alpha: (color: string, opacity: number) => alpha(color, opacity),
};
