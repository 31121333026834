/**
 * Object.values polyfill
 */
if (!Object.values) {
  const reduce = Function.bind.call(Function.call, Array.prototype.reduce);
  const concat = Function.bind.call(Function.call, Array.prototype.concat);
  const keys = Reflect.ownKeys;
  const isEnumerable = Function.bind.call(Function.call, Object.prototype.propertyIsEnumerable);

  Object.values = function values(O) {
    return reduce(
      keys(O),
      (v, k) => concat(v, typeof k === 'string' && isEnumerable(O, k) ? [O[k]] : []),
      [],
    );
  };
}

/* eslint-disable */
// read more: https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
// from: https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode === null) {
          return;
        }
        this.parentNode.removeChild(this);
      },
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

/**
 * window.location.origin polyfill
 */
if (!window.location.origin) {
  window.location.origin =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '');
}
/* eslint-enable */

if (!Array.flat) {
  /* eslint-disable-next-line no-extend-native, func-names */
  Array.prototype.flat = function () {
    return this.reduce((acc, val) => acc.concat(val), []);
  };
}
