import { Box, SxProps, Theme } from '@mui/material';
import { useState } from 'react';
import { MAFProgressiveImage, useScreenSize, FILE_IMAGE_BASE } from '@asfi/maf';

import { styles } from './assetImage.styles';

interface IAssetImageProps {
  sx?: SxProps<Theme>;
  asset?: IAsset;
  setIsAssetImageLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  isAssetImageLoaded: boolean;
  placeholderImage: string;
}

interface IAsset {
  frame?: {
    framePicFile: string;
  };
}

const AssetImage: React.FC<IAssetImageProps> = ({
  sx,
  asset,
  placeholderImage,
  setIsAssetImageLoaded,
  isAssetImageLoaded,
}) => {
  const { isMobile } = useScreenSize();
  const assetImage = asset?.frame?.framePicFile;
  const [shouldShowImage, setShouldShowImage] = useState(isAssetImageLoaded);
  const imageWidth = !isMobile ? 400 : 200;
  const iconWidth = !isMobile ? 200 : 120;

  const onError = () => setShouldShowImage(false);
  return (
    <Box sx={styles.container}>
      <MAFProgressiveImage
        src={`${FILE_IMAGE_BASE}/${assetImage}`}
        placeholder={placeholderImage}
        width={shouldShowImage ? imageWidth : iconWidth}
        onError={onError}
        onLoad={() => {
          setIsAssetImageLoaded(true);
          setShouldShowImage(true);
        }}
        sx={sx}
      />
    </Box>
  );
};

export default AssetImage;
