import { push } from 'connected-react-router';
import dayjs from 'dayjs';
import { app, caseDetail, LEGAL_ENTITY_SMALL_MEDIUM_ENTERPRISE } from '@northstar/core-ui/modules';
import { errorUtils } from '@northstar/core-ui/utils';
import {
  callWithAttachers,
  takeLatestAndWaitForPreviousToFinish,
} from '@northstar/core-ui/utils/redux-saga-utils';
import { startSubmit, stopSubmit } from 'redux-form';
import { delay, call, put, takeLatest, select, take } from 'redux-saga/effects';
import { CREDIT_APPLICATION_STATUS } from '@northstar/core';

// Credit module
import Paths from 'paths';
import { createAttacherForTermsToUse } from 'modules/vendor/vendorTermsAttachers';

import * as caseAPI from '../caseDetail/caseDetailApi';
import { selectCase, selectIsCasePrivate } from '../caseDetail/caseDetailSelectors';
import { attachDraftScenarioId } from '../caseDetail/caseAttachers';
import { requestMappers as caseDetailRequestMappers } from '../caseDetail/caseDetailMapper';

import * as creditApi from './creditApi';
import { APPLICANT_FORM } from './creditConstants';
import { requestMappers, responseMappers } from './creditMapper';
import {
  nextStep,
  submitApplicantionRequest,
  updateApplicants as updateApplicantsAction,
  getCustomerFullNameRequest,
  getCustomerFullNameResponse,
  resetApplicantFullName,
  setResourceWaitingForDecision,
  checkApplicationStatusPollRequest,
  checkIfCreditDecisionExpiredRequest,
  checkIfCreditDecisionExpiredResponse,
} from './creditReducer';
import { selectError, selectIsWithCoApplicant } from './creditSelectors';

export function* updateCaseDetails({ resourceId, debtor, orderCompany, useDefaultAddress }) {
  const { draftScenarioId, creditApplicationStatus } = yield select(selectCase());
  const { mainApplicant, firstName, lastName, email } = debtor;
  const isWithCoApplicant = yield select(selectIsWithCoApplicant());
  const coApplicant = !isWithCoApplicant ? { co_applicant: null } : {};

  yield callWithAttachers({
    endpoint: caseAPI.updateCase,
    payload: {
      resourceId,
      requestBody: {
        ...caseDetailRequestMappers.mapGeneralInformationForm({
          draftScenarioId,
          applicant: {
            firstName: mainApplicant?.firstName || firstName,
            lastName: mainApplicant?.lastName || lastName,
            email: mainApplicant?.email || email,
          },
          coApplicant: debtor.coApplicant,
          hasAppliedForCredit: Boolean(creditApplicationStatus),
        }),
        ...coApplicant,
        orderCompany,
        useDefaultAddress,
      },
    },
    attachers: [createAttacherForTermsToUse()],
  });
}

export function* updateApplicantIndividual({ resourceId, requestBody }) {
  const applicantResponse = yield callWithAttachers({
    endpoint: creditApi.updateApplicants,
    payload: {
      resourceId,
      requestBody,
    },
    attachers: [createAttacherForTermsToUse(), attachDraftScenarioId],
  });
  yield put(updateApplicantsAction(responseMappers.mapApplicants(applicantResponse)));
}

export function* updateApplicantsInCase({ resourceId, debtor }) {
  const isCasePrivate = yield select(selectIsCasePrivate());
  if (isCasePrivate) {
    yield call(updateApplicantIndividual, {
      resourceId,
      requestBody: {
        ...requestMappers.mapApplicantPrivate(debtor),
        isWithAffordabilityQuoteDocumentUrl: true,
      },
    });
  } else {
    const { legalEntity } = debtor;
    yield callWithAttachers({
      endpoint: creditApi.updateLegalEntityCM,
      payload: {
        resourceId,
        requestBody: {
          legal_entity: legalEntity,
        },
      },
      attachers: [createAttacherForTermsToUse()],
    });
    if (legalEntity === LEGAL_ENTITY_SMALL_MEDIUM_ENTERPRISE) {
      yield callWithAttachers({
        endpoint: creditApi.updateApplicantsCM,
        payload: {
          resourceId,
          requestBody: requestMappers.mapApplicantSME(debtor),
        },
        attachers: [createAttacherForTermsToUse()],
      });
    } else {
      yield call(updateApplicantIndividual, {
        resourceId,
        requestBody: requestMappers.mapApplicantST(debtor),
      });
    }
  }
}

export function* submitApplicants({ payload }) {
  const formName = payload.formName || APPLICANT_FORM;
  try {
    yield put(startSubmit(formName));
    const { resourceId, debtor, orderCompany, useDefaultAddress } = payload;
    if (orderCompany || useDefaultAddress) {
      yield call(updateCaseDetails, { resourceId, debtor, orderCompany, useDefaultAddress });
    }
    yield call(updateApplicantsInCase, { resourceId, debtor });

    // By this point applicants must be ok to proceed.
    yield put(nextStep());

    // Proceed to credit decision
    yield callWithAttachers({
      endpoint: creditApi.initiateCredit,
      payload: { resourceId },
      attachers: [createAttacherForTermsToUse(), attachDraftScenarioId],
    });
    yield put(setResourceWaitingForDecision(resourceId));
    yield put(
      caseDetail.getCaseRequest({
        resourceId,
        skip: true,
        withCreditPollCheck: true,
        withAffordabilityFormUrlPoll: true,
      }),
    );
    yield take(caseDetail.getCaseResponse().type);
  } catch (e) {
    const fieldLevelError = errorUtils.mapResponseExceptionsToError({ response: e });
    if (fieldLevelError) {
      const { fieldErrors } = fieldLevelError;
      if (fieldErrors.length === 0) {
        yield put(caseDetail.getCaseRequest({ resourceId: payload.resourceId, skip: true }));
        yield take(caseDetail.getCaseResponse().type);
        yield put(
          app.displayError({
            error: 'errors.could_not_apply_for_credit_decision',
            action: push(Paths.CASE.buildPath({ resourceId: payload.resourceId })),
          }),
        );
      } else {
        yield put(stopSubmit(formName, { _error: errorUtils.groupErrorsByProperty(fieldErrors) }));
      }
    } else {
      yield put(
        app.displayError({
          error: 'errors.could_not_apply_for_credit_decision',
          action: push(Paths.CASE.buildPath({ resourceId: payload.resourceId })),
        }),
      );
    }
  }
}

export function* checkApplicationStatusPoll({ payload: { resourceId } }) {
  while (true) {
    const { creditApplicationStatus } = yield call(caseDetail.getCaseGenerator, {
      payload: { resourceId, skip: true },
    });
    const error = yield select(selectError());

    const breakStatuses = [
      CREDIT_APPLICATION_STATUS.SUBMITTED_FOR_MANUAL,
      CREDIT_APPLICATION_STATUS.MANUAL_APPROVAL,
      CREDIT_APPLICATION_STATUS.AUTO_APPROVAL,
      CREDIT_APPLICATION_STATUS.AUTO_DECLINE,
      CREDIT_APPLICATION_STATUS.STRICT_DECLINE,
      CREDIT_APPLICATION_STATUS.ERROR,
    ];

    if ((error && error !== '') || breakStatuses.indexOf(creditApplicationStatus) !== -1) {
      yield put(setResourceWaitingForDecision(null));
      yield put(
        caseDetail.getCaseRequest({ resourceId, skip: true, withContractProcessedPollCheck: true }),
      );
      break;
    }
    yield delay(4000);
  }
}

export function* getCustomerFullName({ payload }) {
  const { externalCustomerId, isCoDebtor } = payload;
  try {
    yield put(resetApplicantFullName({ externalCustomerId, isCoDebtor }));
    const response = yield call(creditApi.getCustomerFullName, {
      externalCustomerId,
    });
    const applicant = {
      externalCustomerId,
      ...response,
    };
    yield put(
      getCustomerFullNameResponse({
        isCoDebtor,
        applicant: responseMappers.mapToApplicantFromFullnameAndSSN(applicant),
      }),
    );
  } catch (e) {
    yield put(app.displayError(e));
    yield put(
      resetApplicantFullName({
        isCoDebtor,
        externalCustomerId,
      }),
    );
    yield put(getCustomerFullNameResponse(e));
  }
}

export function* checkIfCreditDecisionExpired() {
  try {
    const { creditApplicationStatusUpdatedAt } = yield select(selectCase());

    if (!creditApplicationStatusUpdatedAt) {
      throw new Error('Credit decision timestamp does not exist!');
    }
    const isExpired =
      dayjs(new Date()).diff(new Date(creditApplicationStatusUpdatedAt), 'months') >= 6;
    yield put(checkIfCreditDecisionExpiredResponse(isExpired));
  } catch (e) {
    yield put(checkIfCreditDecisionExpiredResponse(e));
  }
}

export default function* creditSaga() {
  yield takeLatest(submitApplicantionRequest, submitApplicants);
  yield takeLatestAndWaitForPreviousToFinish(getCustomerFullNameRequest, getCustomerFullName);
  yield takeLatest(checkApplicationStatusPollRequest, checkApplicationStatusPoll);
  yield takeLatest(checkIfCreditDecisionExpiredRequest, checkIfCreditDecisionExpired);
}
