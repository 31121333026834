"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectVendorProcessVariances = exports.selectImportersProcessVariances = exports.selectAvailableProcessVariances = exports.selectAllAvailableProcessVariances = void 0;
var _reselect = require("reselect");
const selectProcessVariancesState = state => state.processVariances;
const selectVendorProcessVariances = () => (0, _reselect.createSelector)(selectProcessVariancesState, state => state.get('vendorProcessVariances').toJS());
exports.selectVendorProcessVariances = selectVendorProcessVariances;
const selectImportersProcessVariances = () => (0, _reselect.createSelector)(selectProcessVariancesState, state => state.get('importersProcessVariances').toJS());
exports.selectImportersProcessVariances = selectImportersProcessVariances;
const selectAvailableProcessVariances = financialType => (0, _reselect.createSelector)(selectAllAvailableProcessVariances(), processVariancesWithImporters => processVariancesWithImporters[financialType] || []);
exports.selectAvailableProcessVariances = selectAvailableProcessVariances;
const selectAllAvailableProcessVariances = () => (0, _reselect.createSelector)(selectProcessVariancesState, state => state.get('processVariancesWithImporters').toJS());
exports.selectAllAvailableProcessVariances = selectAllAvailableProcessVariances;