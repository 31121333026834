import { FC, CSSProperties, ElementType } from 'react';
import { SxProps, Theme } from '@mui/material';
import { MAFIcon } from 'src/components/MAFIcon';

import MAFDisplayNumber from '../../MAFDisplayNumber/MAFDisplayNumber';
import { handleCustomSx } from '../../../utils';

import { useStyles } from './MAFDesktopStep.styles';
import { propTypes } from './MAFDesktopStep.propTypes';

export interface IMAFDesktopStepProps {
  sx?: SxProps<Theme>;
  bubblePosition?: number;
  activeStep?: number;
  shouldDisableAnimation?: boolean;
  style?: CSSProperties;
  buttonType?: 'submit' | 'button';
  tabIndex?: number;
  customIcon?: ElementType;
}

const MAFDesktopStep: FC<IMAFDesktopStepProps> = ({
  sx,
  bubblePosition,
  activeStep,
  shouldDisableAnimation = false,
  style = {},
  buttonType,
  tabIndex,
  customIcon,
}) => {
  const styles = useStyles({ bubblePosition });
  const number = activeStep || activeStep === 0 ? activeStep + 1 : undefined;

  return (
    <>
      {customIcon ? (
        <MAFIcon
          Icon={customIcon}
          sx={[
            styles.bubble,
            styles.icon,
            shouldDisableAnimation && styles.bubbleWithoutAnimation,
            ...handleCustomSx(sx),
          ]}
        />
      ) : (
        <MAFDisplayNumber
          number={number}
          sx={[
            styles.bubble,
            shouldDisableAnimation && styles.bubbleWithoutAnimation,
            ...handleCustomSx(sx),
          ]}
          size="large"
          style={style}
          buttonType={buttonType}
          tabIndex={tabIndex}
        />
      )}
    </>
  );
};

MAFDesktopStep.propTypes = propTypes;

export default MAFDesktopStep;
