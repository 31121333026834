import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  buttonContainer: {
    alignItems: 'center',
    textAlign: 'left',
  },
  text: {
    lineHeight: '14px',
    letterSpacing: 0,
  },
  card: ({ colors, spacing }) => ({
    '&.MuiButton-root': {
      padding: 2,
      minHeight: spacing(7),
      borderRadius: 0,
      '& .MuiPopover-root': {
        position: 'absolute !important',
        cursor: 'default',
      },
      ...colors.custom.cardList.card,
    },
  }),
  container: {
    maxWidth: 484,
    position: 'relative',
    overflow: 'visible',
  },
  error: ({ colors }) => ({
    border: `1px solid ${colors.solid.red}`,
  }),
  select: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0,
    cursor: 'pointer',
  },
  helperText: ({ spacing }) => ({
    maxWidth: `calc(484px - ${spacing(6)})`,
  }),
  cardSelected: ({ spacing }) => ({
    '&.MuiButton-root': {
      padding: `13px ${spacing(2)}`,
    },
  }),
  icon: ({ colors }) => ({
    height: 20,
    width: 20,
    marginRight: 1,
    '& *': {
      fill: colors.custom.cardList.icon.fill,
    },
  }),
  iconRotated: {
    transform: 'rotate(45deg) translateZ(0px)',
    transition: 'transform 0.25s',
  },
  rootPaper: {
    '&.MuiMenu-root': {
      position: 'absolute',
    },
  },
  paper: ({ colors }) => ({
    '& .MuiMenu-paper': {
      ...colors.custom.inputFull.input,
      position: 'relative',
      maxHeight: 250,
      borderStyle: 'none',
      top: '67px !important',
      left: '0px !important',
      maxWidth: '100%',
      overflow: 'visible',
      '&:before': {
        left: '15%',
        position: 'absolute',
        display: 'block',
        content: '""',
        transform: 'rotate(45deg)',
        top: 5,
        marginTop: '-10px',
        width: 10,
        height: 10,
        backgroundColor: colors.custom.inputFull.input.background,
      },
    },
  }),
  list: ({ colors }) => ({
    '& .MuiMenu-list': {
      padding: '0px !important',
      width: 'inherit !important',
      overflowX: 'hidden',
      overflowY: 'auto',
      maxHeight: 242,
      '&::-webkit-scrollbar': {
        width: 4,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        backgroundColor: colors.custom.contentAlternative.main.color,
      },
    },
  }),
  menuItem: {
    padding: '18px',
  },
  menuItemOdd: ({ colors }) => colors.custom.cardSelect.dropDown.alternative,
  selected: ({ colors }) => ({
    display: 'block',
    lineHeight: '14px',
    ...colors.custom.cardSelect.selected,
  }),
  title: {
    lineHeight: '14px',
  },
  initStateTitle: ({ colors }) => ({
    ...colors.custom.cardList.title,
  }),
};
