import {
  Stepper,
  Step,
  StepButton,
  StepLabel,
  StepConnector,
  Box,
  Theme,
  SxProps,
} from '@mui/material';
import { useState, useRef, useEffect, FC, ReactChild, MutableRefObject, ReactNode } from 'react';

import { handleCustomSx } from '../../utils';

import MAFDesktopStep from './MAFDesktopStep/MAFDesktopStep';
import { styles } from './MAFStepperDesktop.styles';
import { propTypes } from './MAFStepperDesktop.propTypes';

export interface IMAFStepperStep {
  title: ReactChild;
  content?: ReactNode;
  buttonType?: 'submit' | 'button';
  isDisabled?: boolean;
}

export interface IMAFStepperDesktopProps {
  sx?: SxProps<Theme>;
  isIntroAnimating?: boolean;
  parentRefs?: MutableRefObject<HTMLDivElement[]>;
  shouldShowAllTitles?: boolean;
  activeStep: number;
  steps: IMAFStepperStep[];
  lastAvailableStep?: number;
  onStepClick?: (step: number) => void;
  customIcon?: React.ElementType;
}

const calculateBubblePosition = (ref: HTMLDivElement) =>
  ref?.getBoundingClientRect().x - ref?.getBoundingClientRect().width / 2;

const MAFStepperDesktop: FC<IMAFStepperDesktopProps> = ({
  sx,
  isIntroAnimating = false,
  parentRefs,
  shouldShowAllTitles,
  activeStep,
  steps,
  lastAvailableStep = steps.length - 1,
  onStepClick = () => {},
  customIcon,
}) => {
  const refs = parentRefs || useRef([]);

  const [bubblePosition, setBubblePosition] = useState(0);
  const [hoveredStep, setHoveredStep] = useState(-1);

  useEffect(() => {
    setBubblePosition(calculateBubblePosition(refs?.current?.[activeStep]));
  }, [activeStep]);

  useEffect(() => {
    const updateBubblePosition = () =>
      setBubblePosition(calculateBubblePosition(refs?.current?.[activeStep]));
    window.addEventListener('resize', updateBubblePosition);
    return () => window.removeEventListener('resize', updateBubblePosition);
  }, [activeStep]);

  useEffect(() => {
    setBubblePosition(calculateBubblePosition(refs?.current?.[activeStep]));
  }, [refs?.current]);

  return (
    <Box sx={[styles.containerParent, ...handleCustomSx(sx)]}>
      <Box sx={styles.container}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          nonLinear
          connector={
            <StepConnector
              sx={[
                styles.alternativeLabel,
                styles.activeLine,
                styles.line,
                isIntroAnimating && styles.lineAnimation,
              ]}
            />
          }
          sx={styles.stepperContainer}
        >
          {steps.map(({ title, buttonType, isDisabled }, step) => {
            const isActive = step <= lastAvailableStep;
            const isAnyStepHovered = hoveredStep > -1;
            const isCurrentStepHovered = step === hoveredStep;
            const isCurrentStepActive = step === activeStep;
            const isStepLabelHidden =
              (!isCurrentStepHovered && !isCurrentStepActive) ||
              (isCurrentStepActive && isAnyStepHovered);
            const onClickHandler = () => {
              onStepClick(step);
            };
            return (
              <Step key={step} active={isActive} disabled={!isActive || isDisabled}>
                <StepButton
                  sx={styles.button}
                  type={buttonType || 'button'}
                  onClick={onClickHandler}
                  tabIndex={-1}
                >
                  <StepLabel
                    sx={[
                      styles.labelRoot,
                      styles.label,
                      (shouldShowAllTitles ? false : isStepLabelHidden) && styles.labelContainer,
                    ]}
                    StepIconComponent={() => (
                      <Box
                        sx={[styles.step, isActive && !isDisabled && styles.activeStep]}
                        ref={(ref: HTMLDivElement) => {
                          if (ref) {
                            refs.current[step] = ref;
                          }
                        }}
                        onMouseEnter={() => setHoveredStep(step)}
                        onMouseLeave={() => setHoveredStep(-1)}
                        role="button"
                        tabIndex={isCurrentStepActive || !isActive || isDisabled ? -1 : 0}
                        aria-label="Bubble"
                      />
                    )}
                  >
                    {title}
                  </StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        {Boolean(bubblePosition) && (
          <MAFDesktopStep
            bubblePosition={bubblePosition}
            activeStep={activeStep}
            shouldDisableAnimation={isIntroAnimating}
            buttonType={steps[bubblePosition]?.buttonType || 'submit'}
            customIcon={customIcon}
          />
        )}
      </Box>
    </Box>
  );
};

MAFStepperDesktop.propTypes = propTypes;

export default MAFStepperDesktop;
