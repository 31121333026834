"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.responseMappers = exports.requestMappers = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var arrayUtils = _interopRequireWildcard(require("../../utils/array-utils"));
var matrixUtils = _interopRequireWildcard(require("../../utils/matrix-utils"));
var objectUtils = _interopRequireWildcard(require("../../utils/object-utils"));
const _excluded = ["winterTiresRates", "yearlyMileages", "leasePeriods"],
  _excluded2 = ["winter_tires_rates", "winter_tires_names"],
  _excluded3 = ["yearlyMileages", "leasePeriods"],
  _excluded4 = ["leasePeriods"],
  _excluded5 = ["process_variances"],
  _excluded6 = ["orderCompanies"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const mapTermsPROLtoStore = terms => {
  const _objectUtils$objectTo = objectUtils.objectToCamelCase({
      obj: terms,
      override: {
        winter_tires_rate_ex_vat: 'rate'
      }
    }),
    {
      winterTiresRates,
      yearlyMileages,
      leasePeriods
    } = _objectUtils$objectTo,
    camelCasedTerms = (0, _objectWithoutProperties2.default)(_objectUtils$objectTo, _excluded);
  const rates = matrixUtils.transformRatesToTables(winterTiresRates);
  return _objectSpread(_objectSpread({}, camelCasedTerms), {}, {
    winterTiresRates: rates.map((matrix, index) => _objectSpread(_objectSpread({}, matrix), {}, {
      id: index
    })),
    yearlyMileages: yearlyMileages.sort(),
    leasePeriods: leasePeriods.sort()
  });
};
const mapTermsPROLtoRequest = terms => {
  const _objectUtils$objectTo2 = objectUtils.objectToSnakeCase({
      obj: terms,
      override: {
        rate: 'winter_tires_rate_ex_vat'
      }
    }),
    {
      winter_tires_rates: winterTiresRates,
      winter_tires_names: winterTiresNames
    } = _objectUtils$objectTo2,
    snakeCasedTerms = (0, _objectWithoutProperties2.default)(_objectUtils$objectTo2, _excluded2);
  return _objectSpread(_objectSpread({}, snakeCasedTerms), {}, {
    winter_tires_rates: matrixUtils.flattenTables(winterTiresRates)
  });
};
const mapTermsPRHPtoStore = terms => objectUtils.objectToCamelCase({
  obj: terms
});
const mapTermsPRHPtoRequest = terms => objectUtils.objectToSnakeCase({
  obj: terms
});
const mapTermsCMOLtoStore = terms => {
  const _objectUtils$objectTo3 = objectUtils.objectToCamelCase({
      obj: terms
    }),
    {
      yearlyMileages,
      leasePeriods
    } = _objectUtils$objectTo3,
    camelCasedTerms = (0, _objectWithoutProperties2.default)(_objectUtils$objectTo3, _excluded3);
  return _objectSpread(_objectSpread({}, camelCasedTerms), {}, {
    yearlyMileages: yearlyMileages.sort(),
    leasePeriods: leasePeriods.sort()
  });
};
const mapTermsCMOLtoRequest = terms => objectUtils.objectToSnakeCase({
  obj: terms
});
const mapTermsCMFLtoStore = terms => {
  const _objectUtils$objectTo4 = objectUtils.objectToCamelCase({
      obj: terms
    }),
    {
      leasePeriods
    } = _objectUtils$objectTo4,
    camelCasedTerms = (0, _objectWithoutProperties2.default)(_objectUtils$objectTo4, _excluded4);
  return _objectSpread(_objectSpread({}, camelCasedTerms), {}, {
    leasePeriods: leasePeriods.sort()
  });
};
const mapTermsCMFLtoRequest = objectUtils.objectToSnakeCase;
const mapVendorToStore = _ref => {
  let {
      process_variances: processVariances
    } = _ref,
    vendor = (0, _objectWithoutProperties2.default)(_ref, _excluded5);
  const _objectUtils$objectTo5 = objectUtils.objectToCamelCase({
      obj: vendor
    }),
    {
      orderCompanies
    } = _objectUtils$objectTo5,
    camelCasedVendor = (0, _objectWithoutProperties2.default)(_objectUtils$objectTo5, _excluded6);
  return _objectSpread(_objectSpread({}, camelCasedVendor), {}, {
    orderCompanies: orderCompanies.sort(arrayUtils.createAlphabeticalSorter({
      property: 'name'
    })),
    processVariances
  });
};
const mapAllVendorTermsToStore = _ref2 => {
  let {
    validity,
    terms,
    mapper
  } = _ref2;
  return {
    validity,
    terms: terms.map(mapper)
  };
};
const requestMappers = exports.requestMappers = {
  mapTermsPROL: mapTermsPROLtoRequest,
  mapTermsPRHP: mapTermsPRHPtoRequest,
  mapTermsCMOL: mapTermsCMOLtoRequest,
  mapTermsCMFL: mapTermsCMFLtoRequest
};
const responseMappers = exports.responseMappers = {
  mapVendor: mapVendorToStore,
  mapTermsPROL: mapTermsPROLtoStore,
  mapTermsPRHP: mapTermsPRHPtoStore,
  mapTermsCMOL: mapTermsCMOLtoStore,
  mapTermsCMFL: mapTermsCMFLtoStore,
  mapAllVendorTerms: mapAllVendorTermsToStore
};
var _default = exports.default = {
  requestMappers,
  responseMappers
};