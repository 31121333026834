"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCurrentUserResponse = exports.updateCurrentUserRequest = exports.initialState = exports.getCurrentUserThemeResponse = exports.getCurrentUserThemeRequest = exports.getCurrentUserResponse = exports.getCurrentUserRequest = exports.default = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
var _localStorageUtils = require("../../utils/localStorage-utils");
var _userProfileConstants = require("./userProfileConstants");
/**
 * Actions
 */
const getCurrentUserRequest = exports.getCurrentUserRequest = (0, _reduxActions.createAction)('USER_PROFILE_GET_CURRENT_USER_REQUEST');
const getCurrentUserResponse = exports.getCurrentUserResponse = (0, _reduxActions.createAction)('USER_PROFILE_GET_CURRENT_USER_RESPONSE');
const updateCurrentUserRequest = exports.updateCurrentUserRequest = (0, _reduxActions.createAction)('USER_PROFILE_UPDATE_CURRENT_USER_REQUEST');
const updateCurrentUserResponse = exports.updateCurrentUserResponse = (0, _reduxActions.createAction)('USER_PROFILE_UPDATE_CURRENT_USER_RESPONSE');
const getCurrentUserThemeRequest = exports.getCurrentUserThemeRequest = (0, _reduxActions.createAction)('USER_PROFILE_GET_CURRENT_THEME_REQUEST');
const getCurrentUserThemeResponse = exports.getCurrentUserThemeResponse = (0, _reduxActions.createAction)('USER_PROFILE_GET_CURRENT_THEME_RESPONSE');
/**
 * Initial state
 */
const {
  baseTheme,
  backgrounds,
  customColors,
  customLogo
} = (0, _localStorageUtils.getValueByKey)(_localStorageUtils.KEYS.THEME_SETTINGS) || {};
const initialState = exports.initialState = (0, _immutable.fromJS)({
  user: {
    email: '',
    phone: '',
    firstName: '',
    alternativeEmailAddress: null,
    lastName: '',
    gdprConsent: null
  },
  isLoadingUser: false,
  themeSettings: {
    baseTheme: baseTheme || null,
    customColors: customColors || null,
    backgrounds: backgrounds || null,
    customLogo: customLogo || null
  },
  themeError: false,
  isLoadingTheme: false
});

/**
 * Actions handlers
 */
var _default = exports.default = (0, _reduxActions.handleActions)({
  [(0, _reduxActions.combineActions)(getCurrentUserRequest, updateCurrentUserRequest)](state) {
    return state.set('isLoadingUser', true);
  },
  [(0, _reduxActions.combineActions)(getCurrentUserResponse, updateCurrentUserResponse)](state, _ref) {
    let {
      payload,
      error
    } = _ref;
    return !error ? state.set('user', (0, _immutable.fromJS)(payload)).set('isLoadingUser', false) : state.set('isLoadingUser', false);
  },
  [getCurrentUserThemeRequest](state) {
    return state.set('isLoadingTheme', true);
  },
  [getCurrentUserThemeResponse](state, _ref2) {
    let {
      payload,
      error
    } = _ref2;
    return !error ? state.set('themeSettings', (0, _immutable.fromJS)(payload)).set('isLoadingTheme', false) : state.set('themeError', (0, _immutable.fromJS)(error)).set('themeSettings', (0, _immutable.fromJS)(_userProfileConstants.defaultThemeSettings)).set('isLoadingTheme', false);
  }
}, initialState);