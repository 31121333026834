import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

/**
 * Actions
 */
export const postHirePurchaseQuoteRequest = createAction(
  'HIRE_PURCHASE_POST_HIRE_PURCHASE_QUOTE_REQUEST',
);
export const postHirePurchaseQuoteResponse = createAction(
  'HIRE_PURCHASE_POST_HIRE_PURCHASE_QUOTE_RESPONSE',
);
export const startCalculatingState = createAction('HIRE_PURCHASE_START_CALCULATING_STATE');
export const stopCalculatingState = createAction('HIRE_PURCHASE_STOP_CALCULATING_STATE');
export const resetCalculationState = createAction('HIRE_PURCHASE_RESET_CALCULATION_STATE');
export const getInitValuesHPRequest = createAction('HIRE_PURCHASE_GET_INITIAL_VALUES_REQUEST');
export const getInitValuesHPResponse = createAction('HIRE_PURCHASE_GET_INITIAL_VALUES_RESPONSE');
export const prefillHPValues = createAction('HIRE_PURCHASE_PREFILL_FORM_VALUES');
export const saveCalculationsRequest = createAction('HIRE_PURCHASE_SAVE_CALCULATIONS');

/**
 * Initial state
 */
export const initialState = fromJS({
  calculationData: {},
  initialValues: {},
  calculating: false,
});

/**
 * Actions handlers
 */
export default handleActions(
  {
    [startCalculatingState](state) {
      return state.set('calculating', true);
    },
    [stopCalculatingState](state) {
      return state.set('calculating', false);
    },
    [postHirePurchaseQuoteResponse](state, { payload, error }) {
      return error
        ? state.set('calculating', false)
        : state.set('calculationData', fromJS(payload)).set('calculating', false);
    },
    [resetCalculationState](state, { payload = {} }) {
      const { maxResidualPercentage = 0 } = payload;
      return state
        .set('calculationData', fromJS({}))
        .setIn(['calculationData', 'maxResidualPercentage'], maxResidualPercentage);
    },
    [getInitValuesHPResponse](state, { payload, error }) {
      return error ? state : state.set('initialValues', fromJS(payload));
    },
  },
  initialState,
);
