import { ISxStyles } from '../../../theming/theme.type';

export const styles: ISxStyles = {
  button: ({ spacing, colors }) => ({
    '&.MuiButton-root': {
      padding: 2,
      minHeight: spacing(7),
      justifyContent: 'flex-start',
      borderRadius: 0,
      ...colors.custom.cardList.card,
    },
  }),
  icon: ({ colors }) => ({
    '& g': { fill: `${colors.custom.cardList.icon.fill} !important` },
  }),
  iconActive: {
    transform: 'rotate(45deg) translateZ(0px)',
  },
  closedText: ({ fontSizes, typography, colors }) => ({
    fontSize: fontSizes.small,
    fontFamily: typography.main,
    transition: 'color 300ms ease 0s',
    ...colors.custom.cardList.title,
  }),
};
