import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  root: {
    '&.MuiSlider-root': {
      height: '2px',
    },
  },
  container: ({ typography: { baseFont }, colors }) => ({
    color: colors.custom.fontPrimary,
    ...baseFont,
    letterSpacing: '0.68px',
    lineHeight: '19px',
    fontWeight: 400,
    textAlign: 'center',
  }),
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 600,
  },
  rootWithMarks: {
    '&.MuiSlider-root': {
      marginTop: '50px',
    },
  },
  rail: ({ colors }) => ({
    '& .MuiSlider-rail': {
      color: colors.solid.black,
    },
  }),
  track: ({ colors }) => ({
    '& .MuiSlider-track': {
      ...colors.custom.slider.track,
      border: 'none',
    },
  }),
  trackAlternative: ({ colors }) => ({
    '& .MuiSlider-track': colors.custom.slider.trackAlternative,
  }),
  trackError: ({ colors }) => ({
    '& .MuiSlider-track': {
      background: colors.solid.lightRed,
    },
  }),
  thumb: ({ colors }) => ({
    '& .MuiSlider-thumb': {
      height: '24px !important',
      width: '24px !important',
      ...colors.custom.slider.thumb,
      boxShadow: `0 1px 4px 0 ${colors.alpha(colors.solid.black, 0.3)}`,
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: `0 1px 4px 0 ${colors.alpha(colors.solid.black, 0.3)}`,
      },
    },
  }),
  mark: {
    '& .MuiSlider-mark': {
      display: 'none',
    },
  },
  markLabel: ({ typography: { main }, fontSizes: { extraSmall }, colors }) => ({
    '& .MuiSlider-markLabel': {
      color: colors.alpha(colors.custom.fontPrimary, 0.4),
      fontFamily: main,
      fontSize: extraSmall,
      fontWeight: 600,
      lineHeight: '19px',
      top: -27,
    },
  }),
  active: ({ colors }) => ({
    '.MuiSlider-markLabelActive': {
      color: colors.custom.fontPrimary,
    },
  }),
};
