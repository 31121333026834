"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectFrame = exports.selectFormStep = exports.isFrameSubmitting = exports.isFormOpened = void 0;
var _reselect = require("reselect");
const selectFrameState = state => state.vendorDashboard.frame;
const selectFrame = () => (0, _reselect.createSelector)(selectFrameState, state => state.get('frame').toJS());
exports.selectFrame = selectFrame;
const selectFormStep = () => (0, _reselect.createSelector)(selectFrameState, state => state.get('frameFormStep'));
exports.selectFormStep = selectFormStep;
const isFormOpened = () => (0, _reselect.createSelector)(selectFrameState, state => state.get('formOpened'));
exports.isFormOpened = isFormOpened;
const isFrameSubmitting = () => (0, _reselect.createSelector)(selectFrameState, state => state.get('submitingFrame'));
exports.isFrameSubmitting = isFrameSubmitting;