"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  signInBtn: {
    marginTop: '50px !important',
    margin: '0 auto 30px auto'
  }
};