"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  iconUpdate: _ref => {
    let {
      colors
    } = _ref;
    return {
      display: 'block',
      marginLeft: '10px',
      '& svg': {
        width: 16,
        height: 16
      },
      '& g': {
        fill: colors.custom.badge.base.backgroundColor
      }
    };
  }
};