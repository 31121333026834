"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EQUIPMENT_SUB_CATEGORY_INTERIOR_COLOUR = exports.EQUIPMENT_FORM_RETROFIT = exports.EQUIPMENT_FORM_PRECONFIGURED = exports.EQUIPMENT_FORM_COLORS = exports.EQUIPMENT_FORM_ADDITIONAL = exports.EQUIPMENT_FORM = exports.EQUIPMENT_CATEGORY_STANDARD = exports.EQUIPMENT_CATEGORY_RETROFIT = exports.EQUIPMENT_CATEGORY_PRECONFIGURED = exports.EQUIPMENT_CATEGORY_PACKAGE = exports.EQUIPMENT_CATEGORY_COLOUR = exports.EQUIPMENT_CATEGORY_ADDITIONAL = exports.CAR_CONFIGURATION_EQUIPMENT_FORM = void 0;
const EQUIPMENT_CATEGORY_STANDARD = exports.EQUIPMENT_CATEGORY_STANDARD = 'STANDARD';
const EQUIPMENT_CATEGORY_ADDITIONAL = exports.EQUIPMENT_CATEGORY_ADDITIONAL = 'ADDITIONAL';
const EQUIPMENT_CATEGORY_PACKAGE = exports.EQUIPMENT_CATEGORY_PACKAGE = 'PACKAGE';
const EQUIPMENT_CATEGORY_COLOUR = exports.EQUIPMENT_CATEGORY_COLOUR = 'COLOUR';
const EQUIPMENT_CATEGORY_RETROFIT = exports.EQUIPMENT_CATEGORY_RETROFIT = 'RETROFIT';
const EQUIPMENT_CATEGORY_PRECONFIGURED = exports.EQUIPMENT_CATEGORY_PRECONFIGURED = 'PRECONFIGURED';
const EQUIPMENT_SUB_CATEGORY_INTERIOR_COLOUR = exports.EQUIPMENT_SUB_CATEGORY_INTERIOR_COLOUR = 'INTERIOR_COLOUR';
const EQUIPMENT_FORM_COLORS = exports.EQUIPMENT_FORM_COLORS = 'colors';
const EQUIPMENT_FORM_ADDITIONAL = exports.EQUIPMENT_FORM_ADDITIONAL = 'additional';
const EQUIPMENT_FORM_PRECONFIGURED = exports.EQUIPMENT_FORM_PRECONFIGURED = 'preconfigured';
const EQUIPMENT_FORM_RETROFIT = exports.EQUIPMENT_FORM_RETROFIT = 'retrofit';
const CAR_CONFIGURATION_EQUIPMENT_FORM = exports.CAR_CONFIGURATION_EQUIPMENT_FORM = 'retrofitEquipmentForm';
const EQUIPMENT_FORM = exports.EQUIPMENT_FORM = 'equipmentForm';