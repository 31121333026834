import { FC } from 'react';
import { SxProps, TableHead, Theme } from '@mui/material';

import {
  IDeprecatedMAFTableField,
  TDeprecatedMAFTableDirection,
} from '../DeprecatedMAFTable.types';

import DeprecatedMAFSortableCell from './DeprecatedMAFCell/DeprecatedMAFSortableCell';
import DeprecatedMAFTableRow from './DeprecatedMAFTableRow/DeprecatedMAFTableRow';
import { propTypes } from './DeprecatedMAFTableHeader.propTypes';

interface IMAFTableHeaderProps {
  onOrder?: (value: string) => void;
  orderBy?: string;
  direction?: TDeprecatedMAFTableDirection;
  labels: IDeprecatedMAFTableField[];
  isUplift?: boolean;
  sx?: SxProps<Theme>;
  shouldAdjustHeaderPosition?: boolean;
}

const DeprecatedMAFTableHeader: FC<IMAFTableHeaderProps> = ({
  onOrder,
  orderBy,
  direction,
  labels,
  isUplift,
  sx,
  shouldAdjustHeaderPosition,
}) => (
  <TableHead>
    <DeprecatedMAFTableRow isHead isUplift={isUplift}>
      {labels.map(({ name, label, sortField, isUnsortable, sortTitle }) => (
        <DeprecatedMAFSortableCell
          key={name}
          direction={direction}
          name={name}
          onOrder={onOrder ? () => onOrder(sortField || name) : undefined}
          orderBy={orderBy}
          sortField={sortField}
          isUnsortable={isUnsortable}
          sx={sx}
          isUplift={isUplift}
          shouldAdjustHeaderPosition={shouldAdjustHeaderPosition}
          sortTitle={sortTitle}
        >
          {label}
        </DeprecatedMAFSortableCell>
      ))}
    </DeprecatedMAFTableRow>
  </TableHead>
);

DeprecatedMAFTableHeader.propTypes = propTypes;

export default DeprecatedMAFTableHeader;
