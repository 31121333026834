"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.callWithAttachers = callWithAttachers;
exports.takeLatestAndWaitForPreviousToFinish = exports.createApiSaga = void 0;
var _effects = require("redux-saga/effects");
var _appReducer = require("../modules/app/appReducer");
const isTaskRunning = task => new Promise(resolve => {
  const myInterval = setInterval(() => {
    if (!task.isRunning()) {
      clearInterval(myInterval);
      resolve();
    }
  }, 5);
});

/**
 *
 * @param {*} patternOrChannel
 * @param {*} saga
 * @param  {...any} args
 *
 * The idea: effect accepts new actions, but do not starts the saga until the previous saga
 * with the same action has finished.
 */
/* eslint-disable-next-line func-names */
const takeLatestAndWaitForPreviousToFinish = function (patternOrChannel, saga) {
  for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    args[_key - 2] = arguments[_key];
  }
  return (
    // eslint-disable-next-line func-names
    (0, _effects.fork)(function* () {
      let lastTask;
      while (true) {
        let action;
        if (lastTask) {
          do {
            const [newAction] = yield (0, _effects.race)([(0, _effects.take)(patternOrChannel), (0, _effects.call)(isTaskRunning, lastTask)]);
            if (newAction) {
              action = newAction;
            }
          } while (lastTask.isRunning());
          lastTask = null;
        } else {
          action = yield (0, _effects.take)(patternOrChannel);
        }
        if (action) {
          lastTask = yield (0, _effects.fork)(saga, ...args.concat(action));
          action = null;
        }
      }
    })
  );
};
exports.takeLatestAndWaitForPreviousToFinish = takeLatestAndWaitForPreviousToFinish;
const createApiSaga = _ref => {
  let {
    endpoint,
    requestMapper = value => value,
    successActions,
    responseMapper = value => value,
    errorActions,
    attachers,
    snackbarId
  } = _ref;
  return function apiSaga() {
    let {
      payload
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return function* () {
      try {
        const mappedPayload = requestMapper(payload);
        const response = yield attachers ? callWithAttachers({
          endpoint,
          payload: mappedPayload,
          attachers
        }) : (0, _effects.call)(endpoint, mappedPayload);
        yield (0, _effects.all)(successActions.map(action => (0, _effects.put)(action(responseMapper(response)))));
        if (snackbarId) {
          yield (0, _effects.put)((0, _appReducer.displaySnackbar)({
            id: snackbarId
          }));
        }
      } catch (e) {
        yield (0, _effects.all)(errorActions.map(action => (0, _effects.put)(action(e))));
      }
    }();
  };
};
exports.createApiSaga = createApiSaga;
function combineAttachers(_ref2) {
  let {
    payload,
    attachers
  } = _ref2;
  return function* () {
    let modifiedPayload = payload;
    for (let i = 0; i < attachers.length; i += 1) {
      modifiedPayload = yield attachers[i](modifiedPayload);
    }
    return modifiedPayload;
  }();
}
function callWithAttachers(_ref3) {
  let {
    endpoint,
    payload = {},
    attachers = []
  } = _ref3;
  return function* () {
    const finalPayload = yield combineAttachers({
      payload,
      attachers
    });
    return yield (0, _effects.call)(endpoint, finalPayload);
  }();
}