import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  addLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]).isRequired,
  children: PropTypes.node,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.element.isRequired,
      title: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  helperText: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  isError: PropTypes.bool,
  maxCards: PropTypes.number,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
} as any;
