/* eslint-disable no-console */
import { PROCESS_VARIANCES } from '@northstar/core';
import { createSelector } from 'reselect';
import { selectAvailableProcessVariances } from '@northstar/core-ui/modules/processVariances/processVariancesSelectors';
import { USE_CURRENT_VENDOR_TERMS } from '@northstar/core-ui/modules/auth/authConstants';

import { selectImportersTerms } from './importers/importersSelector';

const selectVendorTermsState = (state) => state.vendor.vendor;

const getTerms = ({ activeTerms, availableTerms, vendorTerms, importersTerms }) => {
  if (!activeTerms) {
    if (!availableTerms.length) return null;
    const [firstAvailableTerms] = availableTerms;
    return firstAvailableTerms.id === USE_CURRENT_VENDOR_TERMS
      ? vendorTerms
      : importersTerms[firstAvailableTerms.id];
  } else {
    if (activeTerms === USE_CURRENT_VENDOR_TERMS) return vendorTerms;
    return importersTerms[activeTerms];
  }
};

const selectMainVendorTerms = (financialType) =>
  createSelector(selectVendorTermsState, (state) => state.get(financialType).toJS());

const selectTerms = (financialType) =>
  createSelector(
    selectActiveTerms(financialType),
    selectMainVendorTerms(financialType),
    selectImportersTerms(financialType),
    selectAvailableProcessVariances(financialType),
    (activeTerms, vendorTerms, importersTerms, availableTerms) =>
      getTerms({ activeTerms, vendorTerms, importersTerms, availableTerms }) || [],
  );

export const selectVendorTermsPROL = () => selectTerms(PROCESS_VARIANCES.PRIVATE_OL);
export const selectVendorTermsPRHP = () => selectTerms(PROCESS_VARIANCES.PRIVATE_HP);
export const selectVendorTermsCMOL = () => selectTerms(PROCESS_VARIANCES.COMMERCIAL_OL);
export const selectVendorTermsCMFL = () => selectTerms(PROCESS_VARIANCES.COMMERCIAL_FL);

export const selectCommissionTerms = () =>
  createSelector(selectVendorTermsPROL(), (state) => state.commission);

export const selectMaxDiscountPercentage = () =>
  createSelector(selectVendorTermsPROL(), (state) => state.defaultMaxDiscountPercentage);

export const selectVendor = () =>
  createSelector(selectVendorTermsState, (state) => state.get('vendor').toJS());

export const selectIsGettingVendor = () =>
  createSelector(selectVendorTermsState, (state) => state.get('gettingVendor'));

export const selectIsGettingTermsCMFL = () =>
  createSelector(selectVendorTermsState, (state) => state.get('gettingTermsCMFL'));

export const selectSelectedTerms = () =>
  createSelector(selectVendorTermsState, (state) => state.get('selected').toJS());

export const selectActiveTerms = (financialType) =>
  createSelector(selectVendorTermsState, (state) => state.get('activeTerms').get(financialType));
