const ArrowDescending: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20px"
    height="21px"
    viewBox="0 0 20 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Desktop-HD"
        transform="translate(-1229.000000, -544.000000)"
        fill="#003755"
        fillRule="nonzero"
      >
        <g id="sort-descending_darkblue" transform="translate(1229.000000, 544.000000)">
          <path
            d="M5.5225,3.0894 C5.2465,3.0894 5.0225,2.8654 5.0225,2.5894 C5.0225,2.3134 5.2465,2.0894 5.5225,2.0894 L10.5245,2.0904 C10.8005,2.0904 11.0245,2.3134 11.0245,2.5904 C11.0245,2.8664 10.8005,3.0904 10.5245,3.0904 L5.5225,3.0894 Z M2.5041,6.0382 C2.2281,6.0382 2.0041,5.8142 2.0041,5.5382 C2.0041,5.2622 2.2281,5.0382 2.5041,5.0382 L10.5241,5.0382 C10.8011,5.0382 11.0241,5.2622 11.0241,5.5382 C11.0241,5.8142 10.8011,6.0382 10.5241,6.0382 L2.5041,6.0382 Z M11.0242,8.6928 C11.0242,8.9688 10.8002,9.1928 10.5242,9.1928 L0.5002,9.1928 C0.2232,9.1928 0.000199999995,8.9688 0.000199999995,8.6928 C0.000199999995,8.4168 0.2232,8.1928 0.5002,8.1928 L10.5242,8.1928 C10.8002,8.1928 11.0242,8.4168 11.0242,8.6928 Z M19.8535,13.2567 C20.0485,13.4517 20.0485,13.7687 19.8535,13.9637 L13.8625,19.9567 C13.7715,20.0477 13.6465,20.1037 13.5085,20.1037 C13.3685,20.1037 13.2425,20.0457 13.1525,19.9537 L7.1635,13.9637 C6.9675,13.7687 6.9675,13.4517 7.1635,13.2567 C7.2605,13.1587 7.3885,13.1107 7.5165,13.1107 C7.6445,13.1107 7.7725,13.1587 7.8705,13.2567 L13.0085,18.3957 L13.0085,0.4997 C13.0085,0.2237 13.2315,-0.0003 13.5085,-0.0003 C13.7845,-0.0003 14.0085,0.2237 14.0085,0.4997 L14.0085,18.3967 L19.1465,13.2567 C19.3415,13.0617 19.6585,13.0617 19.8535,13.2567 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowDescending;
