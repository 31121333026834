const AgreementWaitingForSigning: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="39px"
    height="36px"
    viewBox="0 0 39 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="07-Case-detail-all-statuses-assets"
        transform="translate(-94.000000, -413.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="agreement_waititng_for_signing" transform="translate(97.000000, 416.000000)">
          <path
            d="M4,18.0007324 L4,28.5 C4,29.3284271 4.67157288,30 5.5,30 L31.5,30 C32.3284271,30 33,29.3284271 33,28.5 L33,14.5 C33,13.6715729 32.3284271,13 31.5,13 L19.5421977,13 C19.8396462,12.0529588 20,11.0452057 20,10 C20,4.4771525 15.5228475,0 10,0 C4.4771525,0 0,4.4771525 0,10 C0,13.2716357 1.5711066,16.1763197 4,18.0007324 Z M-2.25,10 C-2.25,3.23451181 3.23451181,-2.25 10,-2.25 C16.7654882,-2.25 22.25,3.23451181 22.25,10 C22.25,10.2510007 22.2424204,10.501087 22.2273448,10.75 L31.5,10.75 C33.5710678,10.75 35.25,12.4289322 35.25,14.5 L35.25,28.5 C35.25,30.5710678 33.5710678,32.25 31.5,32.25 L5.5,32.25 C3.42893219,32.25 1.75,30.5710678 1.75,28.5 L1.75,19.0558418 C-0.767397698,16.7630162 -2.25,13.5030131 -2.25,10 Z M19.5421977,10.75 L19.5421977,13 L21.6888087,13.6742115 C21.9855083,12.7295547 22.1668258,11.749232 22.2273448,10.75 L19.5421977,10.75 Z"
            id="Combined-Shape"
          ></path>
          <path
            d="M8.375,4.5625 C8.375,3.94117966 8.87867966,3.4375 9.5,3.4375 C10.1213203,3.4375 10.625,3.94117966 10.625,4.5625 L10.625,12.4375 C10.625,13.0588203 10.1213203,13.5625 9.5,13.5625 C8.87867966,13.5625 8.375,13.0588203 8.375,12.4375 L8.375,4.5625 Z"
            id="Line-4"
          ></path>
          <path
            d="M13.1240377,13.5639434 C13.6410075,13.9085899 13.7807031,14.6070679 13.4360566,15.1240377 C13.0914101,15.6410075 12.3929321,15.7807031 11.8759623,15.4360566 L8.87596228,13.4360566 C8.3589925,13.0914101 8.2192969,12.3929321 8.56394342,11.8759623 C8.90858994,11.3589925 9.60706795,11.2192969 10.1240377,11.5639434 L13.1240377,13.5639434 Z"
            id="Line-4-Copy"
          ></path>
          <path
            d="M31.1324567,25.2323228 C31.659969,25.5606058 31.8214762,26.254365 31.4931932,26.7818773 C31.1649102,27.3093896 30.471151,27.4708969 29.9436387,27.1426139 C29.1290343,26.6356669 28.4185449,26.101846 27.8143867,25.5521013 C27.3623524,25.9003907 26.8873966,26.1834698 26.4193901,26.3784185 C24.5964573,27.1377637 23.2321451,27.1377637 20.9635964,25.9045305 C19.9895374,25.3750104 19.5324763,25.5409889 19.023171,26.6551713 C18.7648658,27.2202531 18.0973788,27.4689446 17.532297,27.2106394 C16.9672153,26.9523342 16.7185238,26.2848472 16.976829,25.7197654 C18.0172293,23.4437323 19.8941074,22.762157 22.038221,23.9277447 C23.6673882,24.8133961 24.3251041,24.8133961 25.5542095,24.3014105 C25.7270092,24.2294305 25.9915907,24.0510267 26.285416,23.8102489 C25.4932546,22.6268763 25.1676982,21.4236703 25.3284806,20.2981247 C25.663076,17.9558131 28.1448618,16.9696021 29.7128214,18.8645694 C30.9814228,20.3977454 30.512063,22.3636214 29.376739,23.9396504 C29.8604124,24.3693991 30.4439281,24.8038357 31.1324567,25.2323228 Z M27.9793043,20.2989397 C27.7550353,20.0278981 27.6329948,20.0763945 27.5558697,20.6163036 C27.4947282,21.0443204 27.5897052,21.5367843 27.8536589,22.0672422 C28.3065253,21.3165292 28.3402498,20.7351627 27.9793043,20.2989397 Z"
            id="Path-10"
          ></path>
          <path
            d="M10,20 C15.5228475,20 20,15.5228475 20,10 C20,4.4771525 15.5228475,0 10,0 C4.4771525,0 0,4.4771525 0,10 C0,15.5228475 4.4771525,20 10,20 Z M10,22.25 C3.23451181,22.25 -2.25,16.7654882 -2.25,10 C-2.25,3.23451181 3.23451181,-2.25 10,-2.25 C16.7654882,-2.25 22.25,3.23451181 22.25,10 C22.25,16.7654882 16.7654882,22.25 10,22.25 Z"
            id="Oval-5-Copy-2"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default AgreementWaitingForSigning;
