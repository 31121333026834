import { Box } from '@mui/material';

import { IAgreementDetails, TFormatTextFunc } from 'types';

import Detail from './Detail/Detail';
import { styles } from './details.styles';

interface IDetailsProps {
  details: IAgreementDetails[];
  isWithLine?: boolean;
  formatText: TFormatTextFunc;
}

const Details = ({ details, isWithLine, formatText }: IDetailsProps) => (
  <Box sx={styles.container}>
    {details
      .filter(({ render = true }: any) => render)
      .map((detail: any, index: number) => (
        <Detail key={index} {...detail} isWithLine={isWithLine} formatText={formatText} />
      ))}
  </Box>
);

export default Details;
