const Update: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-action/Update/Darkblue">
        <g id="Icon" transform="translate(1.000000, 2.000000)">
          <path
            d="M19.9192729,8.74487686 L20.9228545,7.74129524 C21.1181166,7.5460331 21.4346991,7.5460331 21.6299613,7.74129524 C21.8252234,7.93655739 21.8252234,8.25313988 21.6299613,8.44840202 L19.4430745,10.6352887 L17.2561878,8.44840202 C17.0609257,8.25313988 17.0609257,7.93655739 17.2561878,7.74129524 C17.45145,7.5460331 17.7680325,7.5460331 17.9632946,7.74129524 L18.8933827,8.67138331 C18.1380507,4.4222988 14.677966,2.03137655 10.9691691,1.9957093 C7.70013827,1.96427125 4.88541126,3.91280364 3.4672413,7.22186687 C3.35846349,7.47568176 3.06452403,7.59325755 2.81070914,7.48447974 C2.55689424,7.37570193 2.43931846,7.08176246 2.54809627,6.82794757 C4.1211569,3.15747278 7.29501234,0.960328939 10.9787856,0.995755537 C15.2084716,1.03643214 19.1701638,3.82766155 19.9192729,8.74487686 Z M2.09192247,11.3030098 L1.07462273,12.3203096 C0.879360581,12.5155717 0.562778091,12.5155717 0.367515945,12.3203096 C0.172253799,12.1250474 0.172253799,11.8084649 0.367515945,11.6132028 L2.55440267,9.42631607 L4.74128939,11.6132028 C4.93655154,11.8084649 4.93655154,12.1250474 4.74128939,12.3203096 C4.54602725,12.5155717 4.22944476,12.5155717 4.03418261,12.3203096 L3.12464731,11.4107743 C3.90076419,15.6240793 7.34639258,17.9936912 11.0384997,18.0291979 C14.3075305,18.060636 17.1222575,16.1121036 18.5404275,12.8030404 C18.6492053,12.5492255 18.9431448,12.4316497 19.1969596,12.5404275 C19.4507745,12.6492053 19.5683503,12.9431448 19.4595725,13.1969596 C17.8865119,16.8674344 14.7126564,19.0645783 11.0288832,19.0291517 C6.80579046,18.9885385 2.84985667,16.205941 2.09192247,11.3030098 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Update;
