const Columns: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16px"
    height="18px"
    viewBox="0 0 16 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Desktop-HD"
        transform="translate(-1231.000000, -456.000000)"
        fill="#003755"
        fillRule="nonzero"
      >
        <g
          id="burger-menu_darkblue"
          transform="translate(1239.000000, 465.000000) rotate(90.000000) translate(-1239.000000, -465.000000) translate(1230.000000, 457.000000)"
        >
          <path
            d="M0,1 C0,0.44771525 0.444946289,0 0.993397683,0 L17.0066023,0 C17.5552407,0 18,0.443864822 18,1 C18,1.55228475 17.5550537,2 17.0066023,2 L0.993397683,2 C0.444759292,2 0,1.55613518 0,1 Z M0,8 C0,7.44771525 0.444946289,7 0.993397683,7 L17.0066023,7 C17.5552407,7 18,7.44386482 18,8 C18,8.55228475 17.5550537,9 17.0066023,9 L0.993397683,9 C0.444759292,9 0,8.55613518 0,8 Z M0,15 C0,14.4477153 0.444946289,14 0.993397683,14 L17.0066023,14 C17.5552407,14 18,14.4438648 18,15 C18,15.5522847 17.5550537,16 17.0066023,16 L0.993397683,16 C0.444759292,16 0,15.5561352 0,15 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Columns;
