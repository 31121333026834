const ArrowBack: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-navigation/Back/Darkblue" fill="#003755">
        <g id="Icon" transform="translate(7.000000, 2.000000)">
          <path
            d="M9.3364,19.9863 C9.0674,19.9863 8.8004,19.8793 8.6044,19.6673 L0.2674,10.6863 C-0.0886,10.3033 -0.0886,9.7113 0.2664,9.3273 L8.6034,0.3213 C8.9764,-0.0847 9.6104,-0.1097 10.0164,0.2663 C10.4214,0.6413 10.4454,1.2743 10.0694,1.6793 L2.3634,10.0053 L10.0684,18.3053 C10.4444,18.7113 10.4214,19.3433 10.0174,19.7183 C9.8244,19.8973 9.5804,19.9863 9.3364,19.9863"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowBack;
