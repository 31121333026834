const CreditRejected: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 18 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="07-Case-overview" transform="translate(-1207.000000, -641.000000)" stroke="#fff">
        <g id="case-credit-rejected" transform="translate(1208.000000, 642.000000)">
          <rect id="Rectangle-15" x="-0.5" y="-0.5" width="17" height="19" rx="1"></rect>
          <path d="M5,8 L11,14" id="Line-4-Copy-2" strokeWidth="1.5" strokeLinecap="round"></path>
          <path
            d="M7.94974747,6.57474747 L7.94974747,15.3247475"
            id="Line-4-Copy-2"
            strokeWidth="1.5"
            strokeLinecap="round"
            transform="translate(7.949747, 10.949747) scale(-1, 1) rotate(-45.000000) translate(-7.949747, -10.949747) "
          ></path>
          <path
            d="M1.33333333,3.5 L14.6666667,3.5"
            id="Line-5"
            strokeWidth="1.5"
            strokeLinecap="round"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default CreditRejected;
