import { ReactChild, FC } from 'react';
import { Radio, Box, SxProps, Theme } from '@mui/material';

import { useStyles } from './MAFRadioSelectButton.styles';
import { propTypes } from './MAFRadioSelectButton.propTypes';

export interface IMAFRadioButtonProps {
  value: ReactChild;
  isDisabled?: boolean;
  label?: ReactChild;
  isError?: boolean;
  sx?: SxProps<Theme>;
}

const MAFRadioSelectButton: FC<IMAFRadioButtonProps> = ({
  value,
  isDisabled = false,
  label,
  isError,
  ...rest
}) => {
  const styles = useStyles({ isError });
  return (
    <Radio
      disabled={isDisabled}
      color="default"
      icon={<Box sx={[styles.icon, isDisabled && styles.disabled]}>{label || value}</Box>}
      checkedIcon={<Box sx={styles.iconChecked}>{label || value}</Box>}
      value={value}
      {...rest}
    />
  );
};

MAFRadioSelectButton.propTypes = propTypes;

export default MAFRadioSelectButton;
