"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _constants = require("./constants");
const styles = exports.styles = {
  status: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  [_constants.COLOR_GREEN]: _ref => {
    let {
      colors
    } = _ref;
    return {
      color: colors.solid.green
    };
  },
  [_constants.COLOR_BLUE]: _ref2 => {
    let {
      colors
    } = _ref2;
    return {
      color: colors.solid.lightBlue
    };
  },
  [_constants.COLOR_ORANGE]: _ref3 => {
    let {
      colors
    } = _ref3;
    return {
      color: colors.solid.orange
    };
  },
  [_constants.COLOR_RED]: _ref4 => {
    let {
      colors
    } = _ref4;
    return {
      color: colors.solid.red
    };
  },
  [_constants.COLOR_GRAY]: _ref5 => {
    let {
      colors
    } = _ref5;
    return {
      color: colors.solid.gray
    };
  },
  [_constants.COLOR_YELLOW]: _ref6 => {
    let {
      colors
    } = _ref6;
    return {
      color: colors.danske.caution100
    };
  },
  circle: _ref7 => {
    let {
      breakpoints
    } = _ref7;
    return {
      display: 'inline-block',
      height: 12,
      minWidth: 12,
      borderRadius: '50%',
      marginRight: '14px',
      [breakpoints.only('xs')]: {
        marginRight: '5px'
      }
    };
  },
  ["circle_".concat(_constants.COLOR_GREEN)]: _ref8 => {
    let {
      colors
    } = _ref8;
    return {
      background: colors.solid.green
    };
  },
  ["circle_".concat(_constants.COLOR_BLUE)]: _ref9 => {
    let {
      colors
    } = _ref9;
    return {
      background: colors.solid.lightBlue
    };
  },
  ["circle_".concat(_constants.COLOR_ORANGE)]: _ref10 => {
    let {
      colors
    } = _ref10;
    return {
      background: colors.solid.orange
    };
  },
  ["circle_".concat(_constants.COLOR_RED)]: _ref11 => {
    let {
      colors
    } = _ref11;
    return {
      background: colors.solid.red
    };
  },
  ["circle_".concat(_constants.COLOR_GRAY)]: _ref12 => {
    let {
      colors
    } = _ref12;
    return {
      background: colors.solid.gray
    };
  },
  ["circle_".concat(_constants.COLOR_YELLOW)]: _ref13 => {
    let {
      colors
    } = _ref13;
    return {
      background: colors.danske.caution100
    };
  }
};