"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const useStyles = _ref => {
  let {
    topAdjustment,
    widthAdjustments
  } = _ref;
  return {
    tableTop: _ref2 => {
      let {
        typography: {
          main
        },
        breakpoints
      } = _ref2;
      return {
        fontFamily: main,
        width: '100%',
        padding: '28px 0px',
        minHeight: 50,
        [breakpoints.only('xs')]: {
          marginLeft: '12px',
          width: 'calc(100% - 12px)'
        }
      };
    },
    paper: _ref3 => {
      let {
        colors
      } = _ref3;
      return _objectSpread(_objectSpread({
        display: 'flex'
      }, colors.custom.contentAlternative.highlight), {}, {
        flexGrow: 1,
        padding: '0px 11px'
      });
    },
    container: _ref4 => {
      let {
        breakpoints
      } = _ref4;
      return {
        [breakpoints.only('xs')]: {
          flexDirection: 'column-reverse'
        }
      };
    },
    info: {
      alignSelf: 'center',
      flexGrow: 1
    },
    actions: {
      cursor: 'pointer'
    },
    actionsAdjusted: _ref5 => {
      let {
        breakpoints
      } = _ref5;
      return {
        cursor: 'pointer',
        [breakpoints.only('xs')]: {
          marginBottom: '10px',
          width: '100%',
          flexWrap: 'nowrap'
        }
      };
    },
    checkedTop: _ref6 => {
      let {
        colors,
        breakpoints
      } = _ref6;
      return _objectSpread(_objectSpread({}, colors.custom.contentAlternative.main), {}, {
        padding: '28px 28px 28px 0px',
        position: 'fixed',
        // used for detecting IE browser
        '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
          padding: 0,
          position: 'static'
        },
        top: topAdjustment,
        zIndex: 1,
        width: "calc(100% - ".concat(widthAdjustments || 145, "px)"),
        [breakpoints.down('lg')]: {
          top: topAdjustment,
          width: "calc(100% - ".concat(widthAdjustments || 88, "px)")
        },
        [breakpoints.only('xs')]: {
          padding: '28px 12px',
          top: topAdjustment,
          width: "calc(100% - ".concat(widthAdjustments || 44, "px)"),
          marginLeft: '-12px'
        }
      });
    }
  };
};
exports.useStyles = useStyles;