import { ObjectUtils, CASE_STATUS, DOCUMENT_STATUS } from '@northstar/core';
import { FILE_IMAGE_BASE } from '@asfi/maf';
import { vendorConfig } from '@northstar/core-ui';
import {
  PRODUCT_TYPE_PRIVATE,
  PRODUCT_TYPE_COMMERCIAL,
  PRODUCT_SUB_TYPE_OPERATIONAL_LEASE,
  PRODUCT_SUB_TYPE_HIRE_PURCHASE,
  PRODUCT_SUB_TYPE_FINANCIAL_LEASE,
} from '@northstar/core-ui/modules';
import { caseStatuses, reduxUtils } from '@northstar/core-ui/utils';
import dayjs from 'dayjs';
import { isInvalid } from 'redux-form';
import { createSelector } from 'reselect';

import { selectAsset } from '../asset/assetSelectors';
import { selectFrame } from '../asset/frameSelectors';
import { selectIsResourceWaitingForDecision } from '../credit/creditSelectors';
import { selectVendorTermsPROL, selectVendorTermsCMOL } from '../vendor/vendorTermsSelectors';

import {
  GENERAL_INFORMATION_FORM,
  COMPANY_INFORMATION_FORM,
  SOLE_TRADER_INFORMATION_FORM,
  SME_INFORMATION_FORM,
} from './caseDetailConstants';

const selectCaseState = (state) => state.caseDetail;

export const selectCaseAsset = () =>
  createSelector(
    selectAsset(),
    selectFrame(),
    ({ state, id, brand, model, popName, name, vehicleType, frameId, framePicFile }, frame) => ({
      ...frame,
      state,
      id,
      brand: frame.brand.name || brand,
      model: frame.model.name || model,
      popName: name || popName,
      vehicleType,
      frameId,
      framePic: frame.framePic || `${FILE_IMAGE_BASE}/${framePicFile}`,
    }),
  );

export const selectIsGettingCase = () =>
  createSelector(selectCaseState, (state) => state.get('gettingCase'));

export const selectIsUpdatingCase = () =>
  createSelector(selectCaseState, (state) => state.get('updatingCase'));

export const selectIsCaseCreating = () =>
  createSelector(selectCaseState, (state) => state.get('creatingCase'));

export const selectIsSendingPdf = () =>
  createSelector(selectCaseState, (state) => state.get('sendingPdf'));

export const selectIsProceedingCustomerDataGathering = () =>
  createSelector(selectCaseState, (state) => state.get('proceedingCustomerDataGathering'));

const dateOrNull = (value) =>
  dayjs.isDayjs(value) && value.isValid() ? value.format(vendorConfig.DEFAULT_DATE_FORMAT) : null;

export const selectCase = () =>
  createSelector(selectCaseState, (state) => {
    const details = state.get('details').toJS();
    if (Object.keys(details).length === 0) {
      return details;
    }

    return {
      ...details,
      deliveryDate: dateOrNull(details.deliveryDate),
      firstPaymentDate: dateOrNull(details.firstPaymentDate),
      createdAt: dateOrNull(details.createdAt),
      updatedAt: dateOrNull(details.updatedAt),
      deletedAt: dateOrNull(details.deletedAt),
    };
  });

export const selectQuoteDetailsHP = () =>
  createSelector(selectCase(), ({ asset }) =>
    asset && asset.detailsHP ? asset.detailsHP.quoteDetails : undefined,
  );

export const selectIsCasePrivate = () =>
  createSelector(selectCase(), ({ productType }) => productType === PRODUCT_TYPE_PRIVATE);

export const selectIsCaseCommercial = () =>
  createSelector(selectCase(), ({ productType }) => productType === PRODUCT_TYPE_COMMERCIAL);

export const selectIsCaseOperationalLease = () =>
  createSelector(
    selectCase(),
    ({ productSubType }) => productSubType === PRODUCT_SUB_TYPE_OPERATIONAL_LEASE,
  );

export const selectIsCasePrivateOperationalLease = () =>
  createSelector(
    selectIsCasePrivate(),
    selectCase(),
    (isPrivate, { productSubType }) =>
      isPrivate && productSubType === PRODUCT_SUB_TYPE_OPERATIONAL_LEASE,
  );

export const selectIsCasePrivateHirePurchase = () =>
  createSelector(
    selectIsCasePrivate(),
    selectCase(),
    (isPrivate, { productSubType }) =>
      isPrivate && productSubType === PRODUCT_SUB_TYPE_HIRE_PURCHASE,
  );

export const selectIsCasePrivateHirePurchaseOld = () =>
  createSelector(
    selectIsCasePrivateHirePurchase(),
    selectCase(),
    (isCasePRHP, { newHpFlow }) => isCasePRHP && !newHpFlow,
  );

export const selectIsCaseCommercialOperationalLease = () =>
  createSelector(
    selectIsCaseCommercial(),
    selectCase(),
    (isCommercial, { productSubType }) =>
      isCommercial && productSubType === PRODUCT_SUB_TYPE_OPERATIONAL_LEASE,
  );

export const selectIsCaseCommercialFinancialLease = () =>
  createSelector(
    selectIsCaseCommercial(),
    selectCase(),
    (isCommercial, { productSubType }) =>
      isCommercial && productSubType === PRODUCT_SUB_TYPE_FINANCIAL_LEASE,
  );

export const selectIsCaseWithoutType = () =>
  createSelector(
    selectCase(),
    ({ productType, productSubType }) => !productType || !productSubType,
  );

export const selectHasContactInformation = () =>
  createSelector(selectCaseContactPerson(), (caseContactPerson) =>
    Boolean((caseContactPerson.firstName || caseContactPerson.lastName) && caseContactPerson.email),
  );

export const selectActiveOLterms = () =>
  createSelector(
    selectIsCasePrivate(),
    selectVendorTermsPROL(),
    selectVendorTermsCMOL(),
    (isPrivate, termsPROL, termsCMOL) => (isPrivate ? termsPROL : termsCMOL),
  );

export const selectCaseContactPerson = () =>
  createSelector(
    selectCase(),
    (currentCase) => currentCase.applicant.applicantDetails.contactPersonDetails,
  );

export const selectCaseCoApplicant = () =>
  createSelector(selectCase(), (currentCase) => {
    const { applicant } = currentCase;
    const { coApplicantDetails } = applicant || {};
    const { contactPersonDetails: coApplicantContacts = {} } = coApplicantDetails || {};
    return coApplicantContacts;
  });

export const selectIsWithCoApplicant = () =>
  createSelector(selectCaseCoApplicant(), (coApplicantContacts) =>
    Boolean(coApplicantContacts.firstName),
  );

export const selectIsWaitingForAffordabilityFormUrl = () =>
  createSelector(selectCase(), selectIsWithCoApplicant(), (currentCase, isWithCoApplicant) => {
    if (caseStatuses.getStatusForCase(currentCase).name !== CASE_STATUS.CUSTOMER_DATA_GATHERING)
      return false;
    if (!isWithCoApplicant) return !currentCase.applicant.applicantDetails?.affordabilityFormUrl;
    return (
      !currentCase.applicant?.applicantDetails?.affordabilityFormUrl ||
      !currentCase.applicant?.coApplicantDetails?.affordabilityFormUrl
    );
  });

export const selectIsCaseWithAsset = () =>
  createSelector(selectCase(), (currentCase) => !ObjectUtils.isNullOrEmpty(currentCase.asset));

export const selectIsBeforeApplyingForCredit = () =>
  createSelector(
    selectCase(),
    (currentCase) => caseStatuses.getStatusForCase(currentCase).name === CASE_STATUS.IN_PROGRESS,
  );

const STATUSES_AFTER_APPLYING_FOR_CREDIT_BEFORE_AGREEMENT_SIGNING = [
  CASE_STATUS.CREDIT_AWAITING_AUTOMATIC_DECISION,
  CASE_STATUS.CREDIT_AWAITING_MANUAL_DECISION,
  CASE_STATUS.CREDIT_CONDITIONALLY_APPROVED,
  CASE_STATUS.CREDIT_AUTO_REJECTED,
  CASE_STATUS.CREDIT_APPROVED,
];

const STATUSES_UNTIL_PREPARING_PROOF_OF_DELIVERY = [
  CASE_STATUS.IN_PROGRESS,
  ...STATUSES_AFTER_APPLYING_FOR_CREDIT_BEFORE_AGREEMENT_SIGNING,
  CASE_STATUS.CREDIT_AGREEMENT_AWAITING_SIGNING,
  CASE_STATUS.PREPARING_PROOF_OF_DELIVERY,
  CASE_STATUS.CREDIT_AGREEMENT_SIGNED_PR_HP,
  CASE_STATUS.AWAITING_ASSET,
];
export const selectIsUntilSendForDigitalSignOrCarDeliveryConfirm = () =>
  createSelector(selectCase(), (currentCase) =>
    STATUSES_UNTIL_PREPARING_PROOF_OF_DELIVERY.includes(
      caseStatuses.getStatusForCase(currentCase).name,
    ),
  );

export const selectIsDigitalSignOrConfirmCarDelivery = () =>
  createSelector(
    selectCase(),
    selectIsCasePrivateHirePurchaseOld(),
    (currentCase, isCasePRHPOld) => {
      const caseStatus = caseStatuses.getStatusForCase(currentCase).name;
      const condition = caseStatus === CASE_STATUS.AWAITING_ASSET;
      const conditionPRHP = caseStatus === CASE_STATUS.CREDIT_APPROVED;
      return condition || (isCasePRHPOld && conditionPRHP && currentCase.contractProcessed);
    },
  );

export const selectIsAfterApplyingForCreditAndBeforeAgreementSentForSigning = () =>
  createSelector(selectCase(), (currentCase) =>
    STATUSES_AFTER_APPLYING_FOR_CREDIT_BEFORE_AGREEMENT_SIGNING.includes(
      caseStatuses.getStatusForCase(currentCase).name,
    ),
  );

export const selectIsBeforeAgreementIsSigned = () =>
  createSelector(
    selectCase(),
    (currentCase) => currentCase.signingAgreementStatus !== DOCUMENT_STATUS.SIGNED,
  );

const STATUSES_FOR_REAPPLY = [
  CASE_STATUS.CANCELLED,
  CASE_STATUS.CREDIT_REJECTED,
  CASE_STATUS.CREDIT_APPROVED,
  CASE_STATUS.CREDIT_AGREEMENT_AWAITING_SIGNING,
  CASE_STATUS.CREDIT_AGREEMENT_CANCELLED,
  CASE_STATUS.CREDIT_AGREEMENT_EXPIRED,
];

export const selectCanReapplyForCredit = () =>
  createSelector(selectCase(), (currentCase) =>
    STATUSES_FOR_REAPPLY.includes(caseStatuses.getStatusForCase(currentCase).name),
  );

const STATUSES_AFTER_AGREEMENT_SIGNED = [
  CASE_STATUS.PREPARING_PROOF_OF_DELIVERY,
  CASE_STATUS.AWAITING_ASSET,
  CASE_STATUS.DELIVERY_AGREEMENT_AWAITING_SIGNING,
  CASE_STATUS.AWAITING_REFERENCE_NUMBER,
  CASE_STATUS.FINANCIER_AWAITING_ASSET_DELIVERED,
];

export const selectIsAfterAgreementSigned = () =>
  createSelector(selectCase(), (currentCase) =>
    STATUSES_AFTER_AGREEMENT_SIGNED.includes(caseStatuses.getStatusForCase(currentCase).name),
  );

export const selectIsPodSigned = () =>
  createSelector(
    selectCase(),
    (currentCase) => currentCase.proofOfDeliveryPaperStatus === DOCUMENT_STATUS.SIGNED,
  );

export const selectIsAgreementActivated = () =>
  createSelector(
    selectCase(),
    (currentCase) =>
      caseStatuses.getStatusForCase(currentCase).name === CASE_STATUS.AGREEMENT_ACTIVATED,
  );

export const selectAreAnyFormsDirty = () =>
  createSelector(
    reduxUtils.isDirtyForm(GENERAL_INFORMATION_FORM),
    reduxUtils.isDirtyForm(COMPANY_INFORMATION_FORM),
    reduxUtils.isDirtyForm(SOLE_TRADER_INFORMATION_FORM),
    reduxUtils.isDirtyForm(SME_INFORMATION_FORM),
    (
      isDirtyGeneralForm,
      isDirtyCompanyInformationForm,
      isDirtySoleTraderForm,
      isDirtySMEInformationForm,
    ) =>
      isDirtyGeneralForm ||
      isDirtyCompanyInformationForm ||
      isDirtySoleTraderForm ||
      isDirtySMEInformationForm,
  );

export const selectAreAnyInformationFormsInvalid = () =>
  createSelector(
    isInvalid(GENERAL_INFORMATION_FORM),
    isInvalid(COMPANY_INFORMATION_FORM),
    isInvalid(SOLE_TRADER_INFORMATION_FORM),
    isInvalid(SME_INFORMATION_FORM),
    (
      isInvalidGeneralForm,
      isInvalidCompanyInformationForm,
      isInvalidSoleTraderForm,
      isInvalidSMEInformationForm,
    ) =>
      isInvalidGeneralForm ||
      isInvalidCompanyInformationForm ||
      isInvalidSoleTraderForm ||
      isInvalidSMEInformationForm,
  );

export const selectIsCurrentResourceWaitingForDecision = () =>
  createSelector(
    selectIsResourceWaitingForDecision(),
    selectCase(),
    (resourceId, currentCase) => resourceId === currentCase.resourceId,
  );

export const selectGuarantors = () =>
  createSelector(selectCase(), ({ guarantorsResponse }) => guarantorsResponse);

export const selectCaseAssetRegistrationNumber = () =>
  createSelector(selectCase(), ({ asset }) => asset?.registrationNumber);

export const selectIsReapplyingForCredit = () =>
  createSelector(selectCase(), ({ draftScenarioId }) => Boolean(draftScenarioId));

export const selectIsFinancierCaseExists = () =>
  createSelector(selectCase(), ({ displayId }) => displayId?.toString().includes('-'));
