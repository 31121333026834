"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  root: {
    '& .MuiDialog-paper': {
      maxWidth: 1360,
      width: 'auto',
      overflowX: 'hidden'
    }
  },
  contentRoot: {
    '&.MuiDialogContent-root': {
      paddingLeft: 0,
      paddingRight: 0,
      position: 'relative'
    }
  },
  retroBtn: {
    marginLeft: '45px'
  },
  emptyLabel: _ref => {
    let {
      fontSizes: {
        extraSmall
      }
    } = _ref;
    return {
      fontSize: extraSmall,
      fontWeight: 'normal',
      margin: '10px 0'
    };
  },
  title: _ref2 => {
    let {
      typography: {
        main
      },
      fontSizes: {
        small
      }
    } = _ref2;
    return {
      fontFamily: main,
      fontSize: small,
      fontWeight: 600,
      letterSpacing: '0.45px',
      lineHeight: '28px',
      paddingLeft: '50px',
      textAlign: 'left',
      marginTop: '-30px'
    };
  },
  actionRoot: _ref3 => {
    let {
      colors
    } = _ref3;
    return {
      padding: '30px 0',
      margin: '0',
      boxShadow: "0px -3px 17px 0px ".concat(colors.alpha(colors.custom.fontPrimary, 0.5)),
      flexDirection: 'row'
    };
  },
  confirmActionRoot: {
    margin: '4px 40px 40px 40px'
  },
  actionAction: {
    width: '100%',
    textAlign: 'center'
  },
  form: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap'
  },
  button: {
    marginBottom: '20px'
  },
  cancelButton: {
    display: 'block',
    margin: 'auto'
  },
  sharedButton: {
    margin: '0 15px',
    marginBottom: '20px'
  },
  instructions: _ref4 => {
    let {
      typography: {
        baseFont
      },
      colors
    } = _ref4;
    return _objectSpread(_objectSpread({}, baseFont), {}, {
      color: colors.alpha(colors.custom.fontPrimary, 0.8),
      padding: '0 50px'
    });
  },
  rtl: {
    direction: 'rtl'
  },
  buttonsContainer: {
    marginBottom: '-30px'
  },
  backIcon: _ref5 => {
    let {
      colors
    } = _ref5;
    return {
      '& g': {
        fill: colors.solid.blue
      }
    };
  },
  backBtn: () => ({
    padding: '5px 5px 0',
    top: 20,
    position: 'absolute',
    right: 60,
    cursor: 'pointer'
  }),
  climateBonus: {
    justifyContent: 'flex-end',
    width: 272,
    marginTop: '5px'
  }
};