"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  inputs: {
    display: 'flex',
    textAlign: 'left'
  },
  field: {
    width: '90%'
  },
  header: {
    display: 'flex',
    padding: '20px 20px 0 0'
  },
  icon: {
    '& g': _ref => {
      let {
        colors
      } = _ref;
      return {
        fill: colors.solid.red
      };
    }
  }
};