import { ObjectUtils, DEMO_TOKEN_ID } from '@northstar/core';
import { app } from '@northstar/core-ui/modules';
import { localStorageUtils, reduxSagaUtils, errorUtils } from '@northstar/core-ui/utils';
import { call, put, takeLatest, select } from 'redux-saga/effects';

import { selectCase } from '../caseDetail/caseDetailSelectors';

import * as kycApi from './kycApi';
import { requestMappers } from './kycMapper';
import {
  createKycLinkRequest,
  createKycLinkResponse,
  updateKycRequest,
  updateKycResponse,
  getKycStatusRequest,
  getKycStatusResponse,
  getKycExpirationRequest,
  getKycExpirationResponse,
  validateKycInformationRequest,
} from './kycReducer';

const isDemoVendor = (tokenId) => tokenId === DEMO_TOKEN_ID;

export function* updateKyc({ payload }) {
  try {
    const { organisationNumber, tokenId, values } = payload;
    const mappedValues = requestMappers.mapKyc(values);
    const apiToCall = isDemoVendor(tokenId) ? kycApi.demoKycForm : kycApi.updateKyc;
    yield call(apiToCall, {
      requestBody: {
        organisationNumber,
        tokenId,
        caseResourceId: localStorageUtils.getValueByKey(localStorageUtils.KEYS.CASE_RESOURCE_ID),
        ...mappedValues,
      },
    });
    yield put(updateKycResponse());
    localStorageUtils.setValueByKey(localStorageUtils.KEYS.KYC_FILLED, new Date());
  } catch (e) {
    const { reason, message, fieldErrors } =
      errorUtils.mapResponseExceptionsToError({ response: e }) || {};
    const errorToDisplay = !ObjectUtils.isNullOrEmpty(fieldErrors) || reason ? reason : message;
    yield put(app.displayError(errorToDisplay));
    yield put(updateKycResponse(e));
  }
}

export const getKycStatus = reduxSagaUtils.createApiSaga({
  endpoint: kycApi.getKycStatus,
  responseMapper: ({ status }) => status,
  successActions: [getKycStatusResponse],
  errorActions: [app.displayError, getKycStatusResponse],
});

export const getKycExpiration = reduxSagaUtils.createApiSaga({
  endpoint: kycApi.getKycExpiration,
  successActions: [getKycExpirationResponse],
  responseMapper: ({ expired }) => expired,
  errorActions: [app.displayError, getKycExpirationResponse],
});

export function* createKycLink({ payload }) {
  try {
    const { organisationNumber, values, redirect } = payload;
    const { link } = yield call(kycApi.createKycLink, {
      requestBody: {
        organisationNumber,
        ...values,
      },
    });
    if (typeof redirect === 'function') {
      const { resourceId } = yield select(selectCase());
      localStorageUtils.setValueByKey(localStorageUtils.KEYS.CASE_RESOURCE_ID, resourceId);
      redirect({ path: link });
    }
    yield put(createKycLinkResponse(values));
  } catch (e) {
    yield put(createKycLinkResponse(e));
  }
}

export function* validateKycInformation({ payload }) {
  try {
    const { organisationNumber, tokenId, redirect } = payload;
    const apiToCall = isDemoVendor(tokenId) ? kycApi.demoKycForm : kycApi.validateKycInformation;
    yield call(apiToCall, { organisationNumber, tokenId });
    if (typeof redirect === 'function') {
      redirect();
    }
  } catch (e) {
    yield put(app.displayError(e));
  }
}

export default function* kycSaga() {
  yield takeLatest(updateKycRequest, updateKyc);
  yield takeLatest(getKycStatusRequest, getKycStatus);
  yield takeLatest(getKycExpirationRequest, getKycExpiration);
  yield takeLatest(createKycLinkRequest, createKycLink);
  yield takeLatest(validateKycInformationRequest, validateKycInformation);
}
