"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectTranslationMode = exports.selectSnackbar = exports.selectShowSnackbar = exports.selectShouldForceRefresh = exports.selectOverflowHidden = exports.selectLongPollingLastActionTimestamp = exports.selectKeepingReduxState = exports.selectIsLongPollingAllowed = exports.selectExpandedMenu = exports.selectError = exports.selectCookieConsent = exports.selectAction = exports.appState = void 0;
var _reselect = require("reselect");
const selectAppState = state => state.app;
const appState = () => (0, _reselect.createSelector)(selectAppState, state => state.toJS());
exports.appState = appState;
const selectError = () => (0, _reselect.createSelector)(selectAppState, state => state.get('error'));
exports.selectError = selectError;
const selectSnackbar = () => (0, _reselect.createSelector)(selectAppState, state => state.get('snackbar'));
exports.selectSnackbar = selectSnackbar;
const selectShowSnackbar = () => (0, _reselect.createSelector)(selectAppState, state => state.get('showSnackbar'));
exports.selectShowSnackbar = selectShowSnackbar;
const selectAction = () => (0, _reselect.createSelector)(selectAppState, state => state.get('action').toJS());
exports.selectAction = selectAction;
const selectExpandedMenu = () => (0, _reselect.createSelector)(selectAppState, state => state.get('expandedMenu'));
exports.selectExpandedMenu = selectExpandedMenu;
const selectOverflowHidden = () => (0, _reselect.createSelector)(selectAppState, state => state.get('overflowHidden'));
exports.selectOverflowHidden = selectOverflowHidden;
const selectKeepingReduxState = () => (0, _reselect.createSelector)(selectAppState, state => state.get('keepReduxState'));
exports.selectKeepingReduxState = selectKeepingReduxState;
const selectShouldForceRefresh = () => (0, _reselect.createSelector)(selectAppState, state => state.get('forceRefresh'));
exports.selectShouldForceRefresh = selectShouldForceRefresh;
const selectTranslationMode = () => (0, _reselect.createSelector)(selectAppState, state => state.get('translationMode'));
exports.selectTranslationMode = selectTranslationMode;
const selectCookieConsent = () => (0, _reselect.createSelector)(selectAppState, state => state.get('cookieConsent'));
exports.selectCookieConsent = selectCookieConsent;
const selectLongPollingLastActionTimestamp = () => (0, _reselect.createSelector)(selectAppState, state => state.get('longPolling').get('lastActionTimestamp'));
exports.selectLongPollingLastActionTimestamp = selectLongPollingLastActionTimestamp;
const selectIsLongPollingAllowed = () => (0, _reselect.createSelector)(selectAppState, state => state.get('longPolling').get('isAllowed'));
exports.selectIsLongPollingAllowed = selectIsLongPollingAllowed;