import { ISxStyles } from '../../theming/theme.type';

interface IProps {
  isError?: boolean;
  isDisabled?: boolean;
}

export const useStyles = ({ isError, isDisabled }: IProps): ISxStyles => ({
  root: ({ colors }) => ({
    '&.MuiCheckbox-root, .MuiFormControlLabel-root': {
      '&:hover': {
        backgroundColor: 'transparent',
        '& > span > div': {
          boxShadow: `${
            isError ? colors.custom.inputFull.error.background : colors.custom.toggleElements.color
          } 0px 0px 4px 0px`,
        },
        '& > span > svg': {
          boxShadow: `${
            isError ? colors.custom.inputFull.error.background : colors.custom.toggleElements.color
          } 0px 0px 4px 0px`,
        },
      },
    },
  }),
  colorSecondary: {
    '& .MuiCheckbox-colorPrimary.Mui-checked, .MuiCheckbox-colorSecondary.Mui-checked': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  disabledLabel: ({ colors }) => ({
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: `${colors.alpha(colors.custom.inputFull.label.color, 0.5)} !important`,
    },
  }),
  rootLabel: {
    marginRight: 0,
    marginLeft: 0,
    display: 'flex',
    alignItems: 'start',
    '& > span:nth-child(2)': {
      marginTop: 1,
    },
  },
  icon: ({ colors }) => ({
    backgroundColor: colors.custom.toggleElements.background,
    color: 'transparent',
    border: `1px solid ${
      isError ? colors.custom.inputFull.error.background : colors.custom.toggleElements.border
    }`,
    borderRadius: '2px',
    minWidth: 22,
    minHeight: 22,
    opacity: isDisabled ? 0.5 : undefined,
  }),
  checkedIcon: ({ colors }) => ({
    color: colors.custom.toggleElements.secondary,
    background: colors.custom.toggleElements.color,
    opacity: isDisabled ? 0.5 : undefined,
    borderRadius: '2px',
  }),
  error: ({ breakpoints }) => ({
    marginTop: '-9px',
    maxWidth: 208,
    [breakpoints.down('sm')]: {
      maxWidth: 'unset',
    },
  }),
});
