"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = frameSaga;
exports.initializeFrameOptionsAndForm = initializeFrameOptionsAndForm;
exports.loadFrameOptions = loadFrameOptions;
exports.prefillVendorAssetFormSingleValues = prefillVendorAssetFormSingleValues;
exports.resetSelectedFrameOptions = resetSelectedFrameOptions;
exports.revertSelectedFrameOptions = revertSelectedFrameOptions;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _core = require("@northstar/core");
var _reduxForm = require("redux-form");
var _effects = require("redux-saga/effects");
var _frameConstants = require("../frameConstants");
var _appReducer = require("../app/appReducer");
var frameApi = _interopRequireWildcard(require("../asset/frameApi"));
var _frameMappers = require("../asset/frameMappers");
var _frameFormReducer = require("./frameFormReducer");
var _frameFormSelectors = require("./frameFormSelectors");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
let codeTrigeredChanges = 0;
function prefillVendorAssetFormSingleValues(_ref) {
  let {
    requestForm,
    response,
    shouldCountCodeTrigeredChanges,
    isWithVehicleType
  } = _ref;
  return function* () {
    const {
      brands: availableBrands,
      models,
      trimLevels
    } = yield (0, _effects.select)((0, _frameFormSelectors.selectAvailableOptions)());
    const vendorBrands = yield (0, _effects.select)((0, _frameFormSelectors.selectVendorBrands)());
    const brands = vendorBrands.filter(brand => availableBrands.includes(brand));
    const mappedResponse = _frameMappers.responseMappers.mapFrameOptions(_objectSpread(_objectSpread({}, response), {}, {
      vehicle_type: isWithVehicleType ? [_core.VEHICLE_TYPE_CAR, _core.VEHICLE_TYPE_VAN] : response.vehicle_type,
      brand_names: brands,
      model_names: models.length !== 0 ? models : response.model_names,
      trim_levels: trimLevels.length !== 0 ? trimLevels : response.trim_levels
    }));
    yield (0, _effects.put)((0, _frameFormReducer.getFrameOptionsResponse)(mappedResponse));
    const singleValues = Object.keys(mappedResponse).filter(key => mappedResponse[key].length === 1 && !requestForm[key]);
    const isAllPrimaryFieldsSelected = [response.vehicle_type, response.brand_names, response.model_names, response.trim_levels].every(arr => arr.length === 1);
    if (isAllPrimaryFieldsSelected) {
      yield (0, _effects.put)((0, _frameFormReducer.saveToHistory)(requestForm));
    }

    // We do not want to retrieve assets after each change triggered by the following lines for changes amount
    const isSingleBrand = singleValues.length === 1 && singleValues[0] === _frameConstants.BRAND_NAMES;
    if (shouldCountCodeTrigeredChanges && !isSingleBrand) {
      codeTrigeredChanges = singleValues.length;
    }

    // Set form values if it's already on a single value from response
    yield (0, _effects.all)(singleValues.map(key => (0, _effects.put)((0, _reduxForm.change)(_frameConstants.FRAME_FORM, key, mappedResponse[key][0].value))));
  }();
}
const CLEAR_FIELDS = {
  vehicleType: [_frameConstants.BRAND_NAMES, _frameConstants.MODEL_NAMES, _frameConstants.TRIM_LEVELS],
  brandNames: [_frameConstants.MODEL_NAMES, _frameConstants.TRIM_LEVELS],
  modelNames: [_frameConstants.TRIM_LEVELS],
  trimLevels: []
};
function resetValuesIfMatchesHierarchy(_ref2) {
  let {
    name,
    previousOptions,
    requestForm
  } = _ref2;
  return function* () {
    const isFieldChanged = previousOptions[name] && requestForm[name] !== previousOptions[name];
    const fieldsToClear = [...CLEAR_FIELDS[name], ..._frameConstants.SECONDARY_FIELDS];
    const isFieldsToClearNotEmpty = fieldsToClear.some(field => requestForm[field]);
    if (isFieldChanged) {
      yield (0, _effects.put)((0, _frameFormReducer.clearHistory)());
      yield (0, _effects.all)(CLEAR_FIELDS[name].map(field => (0, _effects.put)((0, _frameFormReducer.setAvailableOptionsForField)({
        field,
        values: []
      }))));
      yield (0, _effects.put)((0, _reduxForm.clearFields)(_frameConstants.FRAME_FORM, true, true, ...fieldsToClear));
    }
    return isFieldChanged && isFieldsToClearNotEmpty;
  }();
}
function loadFrameOptions(_ref3) {
  let {
    payload = {}
  } = _ref3;
  return function* () {
    try {
      if (codeTrigeredChanges) {
        codeTrigeredChanges -= 1;
        // We do need to if new redux form values effects enabling state
        yield (0, _effects.put)((0, _frameFormReducer.getFrameOptionsResponse)());
        return;
      }
      const {
        isWithVehicleType
      } = payload;
      const requestForm = (yield (0, _effects.select)((0, _reduxForm.getFormValues)(_frameConstants.FRAME_FORM))) || {};
      const {
        vehicleType,
        brandNames,
        modelNames,
        trimLevels
      } = requestForm;
      const requestFormEmpty = Object.keys(requestForm).length === 0;
      if (!requestFormEmpty) {
        const previousOptions = yield (0, _effects.select)((0, _frameFormSelectors.selectPreviousOptions)());
        const isFieldsChanged = yield (0, _effects.all)(Object.keys(CLEAR_FIELDS).map(field => (0, _effects.call)(resetValuesIfMatchesHierarchy, {
          name: field,
          previousOptions,
          requestForm
        })));
        yield (0, _effects.put)((0, _frameFormReducer.setPreviousOptions)(requestForm));
        if (isFieldsChanged.some(field => field)) {
          return;
        }
      }
      const requestBody = _frameMappers.formMappers.mapFrameFormToRequest(requestForm);
      const apiCall = requestBody.vehicle_type === _core.VEHICLE_TYPE_VAN ? frameApi.getFrameOptionsVan : frameApi.getFrameOptions;
      const response = yield (0, _effects.call)(apiCall, requestBody);
      if (vehicleType && !brandNames) {
        yield (0, _effects.put)((0, _frameFormReducer.setAvailableOptionsForField)({
          field: _frameConstants.BRAND_NAMES,
          values: response.brand_names
        }));
      }
      if (brandNames && !modelNames) {
        yield (0, _effects.put)((0, _frameFormReducer.setAvailableOptionsForField)({
          field: _frameConstants.MODEL_NAMES,
          values: response.model_names
        }));
      }
      if ((brandNames || modelNames) && !trimLevels) {
        yield (0, _effects.put)((0, _frameFormReducer.setAvailableOptionsForField)({
          field: _frameConstants.TRIM_LEVELS,
          values: response.trim_levels
        }));
      }
      yield (0, _effects.call)(prefillVendorAssetFormSingleValues, {
        response,
        requestForm,
        shouldCountCodeTrigeredChanges: !requestFormEmpty,
        isWithVehicleType
      });
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
    }
  }();
}
function* initializeFrameOptionsAndForm() {
  try {
    const response = yield (0, _effects.call)(frameApi.getFrameOptions, {});
    yield (0, _effects.call)(prefillVendorAssetFormSingleValues, {
      response,
      requestForm: {},
      shouldCountCodeTrigeredChanges: false
    });
  } catch (e) {
    yield (0, _effects.put)((0, _appReducer.displayError)(e));
  }
}
function revertSelectedFrameOptions(_ref4) {
  let {
    payload = {}
  } = _ref4;
  return function* () {
    const {
      latestChange = false,
      isWithVehicleType
    } = payload;
    const history = yield (0, _effects.select)((0, _frameFormSelectors.selectStateHistory)());
    const requestForm = yield (0, _effects.select)((0, _reduxForm.getFormValues)(_frameConstants.FRAME_FORM));
    const elementIndex = history.length - (latestChange ? 1 : 2);
    const {
      form
    } = history[elementIndex] || {};
    const formKeys = Object.keys(form || {});
    yield (0, _effects.put)((0, _frameFormReducer.loadFromHistory)());
    if (requestForm) {
      yield (0, _effects.put)((0, _reduxForm.clearFields)(_frameConstants.FRAME_FORM, true, true, ...Object.keys(requestForm)));
    }
    if (formKeys.length > 0) {
      yield (0, _effects.all)(formKeys.map(key => (0, _effects.put)((0, _reduxForm.change)(_frameConstants.FRAME_FORM, key, form[key]))));
    }
    if (!requestForm) {
      yield (0, _effects.put)((0, _frameFormReducer.getFrameOptionsRequest)({
        isWithVehicleType
      }));
    }
  }();
}
function* resetSelectedFrameOptions() {
  yield (0, _effects.put)((0, _reduxForm.clearFields)(_frameConstants.FRAME_FORM, true, true, ..._frameConstants.SECONDARY_FIELDS));
  yield (0, _effects.put)((0, _frameFormReducer.clearHistory)());
}
function* frameSaga() {
  yield (0, _effects.takeLatest)(_frameFormReducer.getFrameOptionsRequest, loadFrameOptions);
  yield (0, _effects.takeLatest)(_frameFormReducer.getInitialFrameOptionsRequest, initializeFrameOptionsAndForm);
  yield (0, _effects.takeLatest)(_frameFormReducer.revertFields, revertSelectedFrameOptions);
  yield (0, _effects.takeLatest)(_frameFormReducer.resetFields, resetSelectedFrameOptions);
}