import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  isLoggedIn: PropTypes.bool,
  languageSelectProps: PropTypes.object,
  leftComponent: PropTypes.node,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      arrowStyles: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
      ]),
      isBold: PropTypes.bool,
      isLinkActive: PropTypes.bool,
      isWithArrow: PropTypes.bool,
      levels: PropTypes.arrayOf(
        PropTypes.shape({
          isHref: PropTypes.bool,
          label: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
            .isRequired,
          onClick: PropTypes.func,
        }),
      ).isRequired,
      sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
      ]),
      typographyProps: PropTypes.shape({
        children: PropTypes.node,
        color: PropTypes.oneOf(['primary']),
        fontFamily: PropTypes.oneOf(['main', 'title']),
        fontSize: PropTypes.oneOf([
          'extraLarge',
          'extraMedium',
          'extraSmall',
          'giant',
          'huge',
          'large',
          'medium',
          'small',
          'tiny',
        ]),
        fontStyle: PropTypes.oneOf(['italic']),
        fontWeight: PropTypes.oneOf(['600', 'bold', 'normal', 400, 600]),
        isWithContainer: PropTypes.bool,
        isWithFontSmoothing: PropTypes.bool,
        style: PropTypes.object,
        sx: PropTypes.oneOfType([
          PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
          ),
          PropTypes.func,
          PropTypes.object,
        ]),
        textAlign: PropTypes.oneOf(['center', 'justify', 'left', 'right']),
        variant: PropTypes.oneOf([
          'body1',
          'body2',
          'body3',
          'h1-italic',
          'h1-regular',
          'h1-semi-bold',
          'h2-italic',
          'h2-regular',
          'h2-semi-bold',
          'help1',
          'help2',
          'label1',
          'label2',
          'label3',
          'sub1-italic',
          'sub1-regular',
          'sub1-semi-bold',
          'sub2-italic',
          'sub2-regular',
          'sub2-semi-bold',
        ]),
      }),
    }),
  ),
  loginButton: PropTypes.node,
  Logo: PropTypes.oneOfType([
    PropTypes.oneOf([
      'a',
      'abbr',
      'address',
      'animate',
      'animateMotion',
      'animateTransform',
      'area',
      'article',
      'aside',
      'audio',
      'b',
      'base',
      'bdi',
      'bdo',
      'big',
      'blockquote',
      'body',
      'br',
      'button',
      'canvas',
      'caption',
      'circle',
      'cite',
      'clipPath',
      'code',
      'col',
      'colgroup',
      'data',
      'datalist',
      'dd',
      'defs',
      'del',
      'desc',
      'details',
      'dfn',
      'dialog',
      'div',
      'dl',
      'dt',
      'ellipse',
      'em',
      'embed',
      'feBlend',
      'feColorMatrix',
      'feComponentTransfer',
      'feComposite',
      'feConvolveMatrix',
      'feDiffuseLighting',
      'feDisplacementMap',
      'feDistantLight',
      'feDropShadow',
      'feFlood',
      'feFuncA',
      'feFuncB',
      'feFuncG',
      'feFuncR',
      'feGaussianBlur',
      'feImage',
      'feMerge',
      'feMergeNode',
      'feMorphology',
      'feOffset',
      'fePointLight',
      'feSpecularLighting',
      'feSpotLight',
      'feTile',
      'feTurbulence',
      'fieldset',
      'figcaption',
      'figure',
      'filter',
      'footer',
      'foreignObject',
      'form',
      'g',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'head',
      'header',
      'hgroup',
      'hr',
      'html',
      'i',
      'iframe',
      'image',
      'img',
      'input',
      'ins',
      'kbd',
      'keygen',
      'label',
      'legend',
      'li',
      'line',
      'linearGradient',
      'link',
      'main',
      'map',
      'mark',
      'marker',
      'mask',
      'menu',
      'menuitem',
      'meta',
      'metadata',
      'meter',
      'mpath',
      'nav',
      'noindex',
      'noscript',
      'object',
      'ol',
      'optgroup',
      'option',
      'output',
      'p',
      'param',
      'path',
      'pattern',
      'picture',
      'polygon',
      'polyline',
      'pre',
      'progress',
      'q',
      'radialGradient',
      'rect',
      'rp',
      'rt',
      'ruby',
      's',
      'samp',
      'script',
      'section',
      'select',
      'symbol',
      'slot',
      'small',
      'source',
      'span',
      'style',
      'stop',
      'strong',
      'sub',
      'summary',
      'sup',
      'svg',
      'switch',
      'table',
      'tbody',
      'td',
      'template',
      'text',
      'textarea',
      'textPath',
      'tfoot',
      'th',
      'thead',
      'time',
      'title',
      'tr',
      'track',
      'tspan',
      'u',
      'ul',
      'use',
      'var',
      'video',
      'view',
      'wbr',
      'webview',
    ]),
    PropTypes.func,
  ]),
  topLinks: PropTypes.arrayOf(
    PropTypes.shape({
      componentType: PropTypes.oneOf(['MAFLink', 'MAFSelect']).isRequired,
      props: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.shape({
          arrowStyles: PropTypes.oneOfType([
            PropTypes.arrayOf(
              PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
            ),
            PropTypes.func,
            PropTypes.object,
          ]),
          isBold: PropTypes.bool,
          isWithArrow: PropTypes.bool,
          levels: PropTypes.arrayOf(
            PropTypes.shape({
              isHref: PropTypes.bool,
              label: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
                .isRequired,
              onClick: PropTypes.func,
            }),
          ).isRequired,
          sx: PropTypes.oneOfType([
            PropTypes.arrayOf(
              PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
            ),
            PropTypes.func,
            PropTypes.object,
          ]),
          typographyProps: PropTypes.shape({
            children: PropTypes.node,
            color: PropTypes.oneOf(['primary']),
            fontFamily: PropTypes.oneOf(['main', 'title']),
            fontSize: PropTypes.oneOf([
              'extraLarge',
              'extraMedium',
              'extraSmall',
              'giant',
              'huge',
              'large',
              'medium',
              'small',
              'tiny',
            ]),
            fontStyle: PropTypes.oneOf(['italic']),
            fontWeight: PropTypes.oneOf(['600', 'bold', 'normal', 400, 600]),
            isWithContainer: PropTypes.bool,
            isWithFontSmoothing: PropTypes.bool,
            style: PropTypes.object,
            sx: PropTypes.oneOfType([
              PropTypes.arrayOf(
                PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
              ),
              PropTypes.func,
              PropTypes.object,
            ]),
            textAlign: PropTypes.oneOf(['center', 'justify', 'left', 'right']),
            variant: PropTypes.oneOf([
              'body1',
              'body2',
              'body3',
              'h1-italic',
              'h1-regular',
              'h1-semi-bold',
              'h2-italic',
              'h2-regular',
              'h2-semi-bold',
              'help1',
              'help2',
              'label1',
              'label2',
              'label3',
              'sub1-italic',
              'sub1-regular',
              'sub1-semi-bold',
              'sub2-italic',
              'sub2-regular',
              'sub2-semi-bold',
            ]),
          }),
        }),
      ]).isRequired,
    }),
  ),
  topText: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
} as any;
