const Undo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20px"
    height="10px"
    viewBox="0 0 20 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Desktop-HD"
        transform="translate(-1228.000000, -829.000000)"
        fill="#003755"
        fillRule="nonzero"
      >
        <g
          id="arrow-illustrative-down-left_darkblue"
          transform="translate(1228.000000, 829.000000)"
        >
          <path
            d="M19.4239591,0.246211021 C12.6113466,0.246211021 6.17501887,2.7396783 1.15324313,7.29690494 L1.15324313,0.57604088 C1.15324313,0.257824749 0.894257012,0 0.57604088,0 C0.258986122,0 0,0.257824749 0,0.57604088 L0,8.64177458 C0,8.64758144 0.00464549097,8.65454968 0.00464549097,8.66151791 C0.00696823646,8.72887753 0.0185819639,8.79739853 0.0441321642,8.86127403 C0.103362174,9.00180013 0.214853957,9.11445328 0.356541432,9.17252192 C0.427385169,9.20155624 0.501713025,9.21781546 0.57604088,9.21781546 L8.64177458,9.21781546 C8.95999071,9.21781546 9.21781546,8.95882934 9.21781546,8.64177458 C9.21781546,8.32355845 8.95999071,8.06457232 8.64177458,8.06457232 L2.06724348,8.06457232 C6.86022879,3.77213867 12.9562743,1.39829278 19.4239591,1.39829278 C19.7421753,1.39829278 20,1.14046803 20,0.822251902 C20,0.50403577 19.7421753,0.246211021 19.4239591,0.246211021"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Undo;
