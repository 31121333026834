import { ReactNode, FC } from 'react';
import { Slide, Box } from '@mui/material';

import MAFButton from '../MAFButton/MAFButton';
import MAFTypography from '../MAFTypography/MAFTypography';

import { useStyles } from './MAFSummaryEdit.styles';
import { propTypes } from './MAFSummaryEdit.propTypes';
import ValueRow from './ValueRow/ValueRow';

export type TMAFSummaryValue = {
  name: ReactNode;
  value?: ReactNode;
  isSubtitle?: boolean;
  isLongTitle?: boolean;
};

export type TMAFSummaryDetail = {
  title: ReactNode;
  values: TMAFSummaryValue[];
};

export interface IMAFSummaryEdit {
  header?: ReactNode;
  additionalDetails?: TMAFSummaryDetail[];
  summaryDetails: TMAFSummaryDetail[];
  changeCurrentStep?: (index: number) => void;
  editLabel?: ReactNode;
  isStrongVariant?: boolean;
}

const MAFSummaryEdit: FC<IMAFSummaryEdit> = ({
  header,
  additionalDetails,
  summaryDetails,
  changeCurrentStep,
  editLabel,
  isStrongVariant,
}) => {
  const styles = useStyles({ isStrongVariant });

  return (
    <>
      <Box sx={styles.topBar} />
      <Box sx={styles.slideContainer}>
        <Slide direction="down" in mountOnEnter unmountOnExit timeout={{ enter: 1000 }}>
          <Box sx={styles.wrapper}>
            {header}
            {additionalDetails?.map(({ title, values }, index) => (
              <Box sx={styles.container} key={index}>
                <Box sx={styles.title}>
                  <MAFTypography variant="label1">{title}</MAFTypography>
                </Box>
                {values.map((item, valueIndex) => (
                  <ValueRow
                    {...item}
                    index={valueIndex}
                    isNotLast={Boolean(values[valueIndex + 1])}
                    isStrongVariant={isStrongVariant}
                  />
                ))}
              </Box>
            ))}

            {summaryDetails.map(({ title, values }, index) => (
              <Box sx={styles.container} key={index}>
                <Box sx={styles.title}>
                  <MAFTypography variant="label1">{title}</MAFTypography>
                  {editLabel && changeCurrentStep && (
                    <MAFButton isLink onClick={() => changeCurrentStep(index)}>
                      <Box component="span" sx={styles.button}>
                        {editLabel}
                      </Box>
                    </MAFButton>
                  )}
                </Box>
                {values.map((item, valueIndex) => (
                  <ValueRow
                    {...item}
                    index={valueIndex}
                    isNotLast={Boolean(values[valueIndex + 1])}
                    isStrongVariant={isStrongVariant}
                  />
                ))}
              </Box>
            ))}
          </Box>
        </Slide>
      </Box>
    </>
  );
};

MAFSummaryEdit.propTypes = propTypes;

export default MAFSummaryEdit;
