const VerySadFilled: React.FC<{}> = () => (
  <svg height="48px" width="48px" color="#003755" viewBox="-2 -2 24 24">
    <path
      d="M10,0 C15.523,0 20,4.477 20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 Z M10,11.7354464 C7.47092825,11.7354464 5.76677262,12.7838466 4.97513632,14.8548019 C4.8941442,15.0666808 5.00024893,15.3040997 5.21212787,15.3850918 C5.42400681,15.466084 5.66142572,15.3599792 5.74241784,15.1481003 C6.40605299,13.4120017 7.79604597,12.556875 10,12.556875 C12.2039815,12.556875 13.5942484,13.4120344 14.2583521,15.1481867 C14.3393919,15.3600474 14.5768347,15.4660986 14.7886954,15.3850588 C15.0005561,15.304019 15.1066074,15.0665762 15.0255676,14.8547155 C14.2334173,12.7838139 12.5290443,11.7354464 10,11.7354464 Z M6,7 C5.44771525,7 5,7.44771525 5,8 C5,8.55228475 5.44771525,9 6,9 C6.55228475,9 7,8.55228475 7,8 C7,7.44771525 6.55228475,7 6,7 Z M14,7 C13.4477153,7 13,7.44771525 13,8 C13,8.55228475 13.4477153,9 14,9 C14.5522847,9 15,8.55228475 15,8 C15,7.44771525 14.5522847,7 14,7 Z"
      id="Combined-Shape"
    ></path>
  </svg>
);

export default VerySadFilled;
