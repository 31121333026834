"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: {
    padding: '0 30px'
  },
  field: {
    marginTop: '10px'
  },
  btn: {
    '&.MuiButton-root': {
      marginTop: '16px'
    }
  },
  icon: _ref => {
    let {
      colors
    } = _ref;
    return {
      color: colors.custom.fontPrimary
    };
  }
};