import { ISxStyles } from '../../../theming/theme.type';

export const styles: ISxStyles = {
  root: {
    '&.MuiChip-root': {
      margin: '0px 2px',
      alignSelf: 'center',
    },
  },
  chipContainer: {
    height: 45,
    marginTop: '-12px',
    display: 'flex',
  },
  compact: {
    '&.MuiChip-root': {
      marginTop: 0,
      height: 28,
    },
  },
  primaryPR: ({ colors }) => ({
    ...colors.custom.primaryPR.chip,
  }),
  primaryCM: ({ colors }) => ({
    ...colors.custom.primaryCM.chip,
  }),
  blue: ({ typography: { main }, colors }) => ({
    fontFamily: main,
    color: colors.solid.white,
    background: colors.solid.blue,
  }),
  disabled: ({ typography: { main }, colors }) => ({
    '&.MuiChip-root.Mui-disabled': {
      fontFamily: main,
      ...colors.custom.chipsAlternative,
    },
  }),
  icon: {
    margin: '-12px -5px -12px -13px !important',
  },
  iconPrimaryPR: ({ colors }) => ({
    '& g': {
      fill: colors.custom.primaryPR.chip.color,
    },
  }),
  iconPrimaryCM: ({ colors }) => ({
    '& g': {
      fill: colors.custom.primaryCM.chip.color,
    },
  }),
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};
