"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: {
    marginTop: '25px'
  },
  title: _ref => {
    let {
      fontSizes: {
        small
      },
      spacing
    } = _ref;
    return {
      fontSize: small,
      fontWeight: 500,
      margin: spacing(2, 0)
    };
  },
  productOptions: _ref2 => {
    let {
      colors,
      spacing
    } = _ref2;
    return {
      padding: spacing(4, 1),
      backgroundColor: colors.custom.equipmentBox.background
    };
  }
};