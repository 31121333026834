"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = users;
var _core = require("@northstar/core");
var _effects = require("redux-saga/effects");
var _appReducer = require("../app/appReducer");
var _authReducer = require("../auth/authReducer");
var _authSelectors = require("../auth/authSelectors");
var usersApi = _interopRequireWildcard(require("./userManagementApi"));
var _userManagementConstants = require("./userManagementConstants");
var _userManagementMappers = require("./userManagementMappers");
var _userManagementReducer = require("./userManagementReducer");
var _userManagementSelectors = require("./userManagementSelectors");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function createUser(_ref) {
  let {
    payload
  } = _ref;
  return function* () {
    try {
      yield (0, _effects.call)(usersApi.createUser, _userManagementMappers.requestMappers.mapCreateUser(payload));
      yield (0, _effects.put)((0, _userManagementReducer.createUserResponse)());
      yield (0, _effects.put)((0, _userManagementReducer.getUserListRequest)());
      yield (0, _effects.put)((0, _userManagementReducer.changeFormVisibility)({
        form: 'createUserFormOpen',
        visibility: false
      }));
      yield (0, _effects.put)((0, _appReducer.displaySnackbar)({
        id: 'vendor.create_user_success'
      }));
    } catch (e) {
      if (e.httpResponse) {
        yield (0, _effects.put)((0, _appReducer.displayError)(e));
      } else {
        yield (0, _effects.put)((0, _appReducer.displayError)(e.message));
      }
      yield (0, _effects.put)((0, _userManagementReducer.createUserResponse)(e));
    }
  }();
}
function updateUser(_ref2) {
  let {
    payload
  } = _ref2;
  return function* () {
    try {
      yield (0, _effects.call)(usersApi.updateUser, {
        username: payload.username,
        requestBody: {
          status: payload.status === _userManagementConstants.USER_STATUS_ACTIVE ? _userManagementConstants.USER_STATUS_INACTIVE : _userManagementConstants.USER_STATUS_ACTIVE
        }
      });
      yield (0, _effects.put)((0, _userManagementReducer.updateUserResponse)());
      yield (0, _effects.put)((0, _userManagementReducer.getUserListRequest)());
    } catch (e) {
      if (e.httpResponse) {
        yield (0, _effects.put)((0, _appReducer.displayError)(e));
      } else {
        yield (0, _effects.put)((0, _appReducer.displayError)(e.message));
      }
    }
  }();
}
function* getUserList() {
  try {
    const request = yield (0, _effects.select)((0, _userManagementSelectors.selectUsersRequest)());
    const response = yield (0, _effects.call)(usersApi.getUsers, request);
    yield (0, _effects.put)((0, _userManagementReducer.getUserListResponse)(_userManagementMappers.responseMappers.mapUsers(response)));
  } catch (e) {
    if (e.httpResponse) {
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
    } else {
      yield (0, _effects.put)((0, _appReducer.displayError)(e.message));
    }
  }
}
function* getNeedToReauthorize() {
  try {
    const currentRole = yield (0, _effects.select)((0, _authSelectors.selectRole)());
    if (currentRole === _core.USER_ROLE_ONBOARDER || currentRole === _core.USER_ROLE_VENDOR_ADMIN) {
      const response = yield (0, _effects.call)(usersApi.getUsersToReauthorize);
      yield (0, _effects.put)((0, _userManagementReducer.changeNeedToReauthorize)(response));
    }
  } catch (e) {
    yield (0, _effects.put)((0, _userManagementReducer.changeNeedToReauthorize)(false));
  }
}
function regenerateConfirmationToken(_ref3) {
  let {
    payload
  } = _ref3;
  return function* () {
    try {
      const {
        username
      } = payload;
      yield (0, _effects.call)(usersApi.regenerateConfirmationToken, {
        username
      });
      yield (0, _effects.put)((0, _userManagementReducer.regenerateConfirmationTokenResponse)());
      yield (0, _effects.put)((0, _userManagementReducer.getUserListRequest)());
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
      yield (0, _effects.put)((0, _userManagementReducer.regenerateConfirmationTokenResponse)(e));
    }
  }();
}
function createIdentity(_ref4) {
  let {
    payload
  } = _ref4;
  return function* () {
    try {
      const {
        values,
        isOnboarder
      } = payload;
      yield (0, _effects.call)(usersApi.addUserIdentity, _userManagementMappers.requestMappers.mapCreateIdentity({
        values,
        isOnboarder
      }));
      yield (0, _effects.put)((0, _userManagementReducer.createIdentityResponse)());
      yield (0, _effects.put)((0, _userManagementReducer.getUserListRequest)());
      yield (0, _effects.put)((0, _appReducer.displaySnackbar)({
        id: 'vendor.create_role_success'
      }));
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
      yield (0, _effects.put)((0, _userManagementReducer.createIdentityResponse)(e));
    }
  }();
}
function reauthorizeIdentities(_ref5) {
  let {
    payload
  } = _ref5;
  return function* () {
    try {
      const {
        identities
      } = payload;
      const currentRole = yield (0, _effects.select)((0, _authSelectors.selectRole)());
      const identitiesToReauthorize = _userManagementMappers.requestMappers.mapIdentitiesToReauthorize({
        currentRole,
        identities
      });
      yield (0, _effects.call)(usersApi.reauthorizeIdentities, {
        identities: identitiesToReauthorize
      });
      yield (0, _effects.put)((0, _userManagementReducer.reauthorizeIdentitiesResponse)());
      yield (0, _effects.call)(getNeedToReauthorize);
      yield (0, _effects.put)((0, _userManagementReducer.getUserListRequest)());
      const message = identitiesToReauthorize.length === 1 ? {
        id: 'vendor.reauthorize_role_success'
      } : {
        id: 'vendor.reauthorize_roles_success',
        values: {
          value: identitiesToReauthorize.length
        }
      };
      yield (0, _effects.put)((0, _appReducer.displaySnackbar)(message));
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
      yield (0, _effects.put)((0, _userManagementReducer.reauthorizeIdentitiesResponse)(e));
    }
  }();
}
function activateIdentities(_ref6) {
  let {
    payload
  } = _ref6;
  return function* () {
    try {
      const {
        identities
      } = payload;
      yield (0, _effects.call)(usersApi.updateUserIdentities, _userManagementMappers.requestMappers.mapUpdateIdentities({
        identities,
        status: _userManagementConstants.IDENTITY_STATUS_ACTIVE
      }));
      yield (0, _effects.put)((0, _userManagementReducer.activateIdentitiesResponse)());
      yield (0, _effects.put)((0, _userManagementReducer.getUserListRequest)());
      const message = identities.length === 1 ? {
        id: 'vendor.activate_role_success'
      } : {
        id: 'vendor.activate_roles_success',
        values: {
          value: identities.length
        }
      };
      yield (0, _effects.put)((0, _appReducer.displaySnackbar)(message));
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
      yield (0, _effects.put)((0, _userManagementReducer.activateIdentitiesResponse)(e));
    }
  }();
}
function deactivateIdentities(_ref7) {
  let {
    payload
  } = _ref7;
  return function* () {
    try {
      const {
        identities
      } = payload;
      yield (0, _effects.call)(usersApi.updateUserIdentities, _userManagementMappers.requestMappers.mapUpdateIdentities({
        identities,
        status: _userManagementConstants.IDENTITY_STATUS_INACTIVE
      }));
      yield (0, _effects.put)((0, _userManagementReducer.deactivateIdentitiesResponse)());
      yield (0, _effects.put)((0, _userManagementReducer.getUserListRequest)());
      const message = identities.length === 1 ? {
        id: 'vendor.deactivate_role_success'
      } : {
        id: 'vendor.deactivate_roles_success',
        values: {
          value: identities.length
        }
      };
      yield (0, _effects.put)((0, _appReducer.displaySnackbar)(message));
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
      yield (0, _effects.put)((0, _userManagementReducer.deactivateIdentitiesResponse)(e));
    }
  }();
}
function deleteIdentities(_ref8) {
  let {
    payload
  } = _ref8;
  return function* () {
    try {
      const {
        identities
      } = payload;
      yield (0, _effects.call)(usersApi.deleteIdentities, {
        identities: _userManagementMappers.requestMappers.mapIdentities(identities)
      });
      yield (0, _effects.put)((0, _userManagementReducer.deleteIdentitiesResponse)());
      yield (0, _effects.put)((0, _userManagementReducer.getUserListRequest)());
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
      yield (0, _effects.put)((0, _userManagementReducer.deleteIdentitiesResponse)(e));
    }
  }();
}
function* users() {
  yield (0, _effects.takeLatest)(_userManagementReducer.updateUserRequest, updateUser);
  yield (0, _effects.takeLatest)(_userManagementReducer.getUserListRequest, getUserList);
  yield (0, _effects.takeLatest)(_userManagementReducer.changeRelatedImporterRequest, getUserList);
  yield (0, _effects.takeLatest)(_userManagementReducer.createUserRequest, createUser);
  yield (0, _effects.takeLatest)(_userManagementReducer.regenerateConfirmationTokenRequest, regenerateConfirmationToken);
  yield (0, _effects.takeLatest)(_userManagementReducer.createIdentityRequest, createIdentity);
  yield (0, _effects.takeLatest)(_userManagementReducer.reauthorizeIdentitiesRequest, reauthorizeIdentities);
  yield (0, _effects.takeLatest)(_userManagementReducer.activateIdentitiesRequest, activateIdentities);
  yield (0, _effects.takeLatest)(_userManagementReducer.deactivateIdentitiesRequest, deactivateIdentities);
  yield (0, _effects.takeLatest)(_userManagementReducer.deleteIdentitiesRequest, deleteIdentities);
  yield (0, _effects.takeLatest)(_authReducer.loginResponse, getNeedToReauthorize);
  yield (0, _effects.takeLatest)(_authReducer.loginInternalResponse, getNeedToReauthorize);
  yield (0, _effects.takeLatest)(_authReducer.sessionResponse, getNeedToReauthorize);
}