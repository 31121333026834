import { createSelector } from 'reselect';

import { selectSelectedTerms } from '../vendor/vendorTermsSelectors';

export const carListSelect = (state) => state.carList;

export const selectListDisplay = () =>
  createSelector(carListSelect, (carState) => carState.get('display'));

export const selectCarFetchState = () =>
  createSelector(carListSelect, (carState) => ({
    fetching: carState.get('fetching_cars'),
    carsLoaded: carState.get('cars_loaded'),
  }));

export const makeSelectRequest = () =>
  createSelector(
    carListSelect,
    makeSelectAppliedFiltersMapped(),
    makeSelectLeasing(),
    selectSelectedTerms(),
    (carListState, appliedFilters, leasing, { period, mileage }) => ({
      ...appliedFilters,
      limit: [0, carListState.get('limit')],
      lease_period: period,
      yearly_mileage: mileage,
      query: '',
      price_min: leasing.min_price,
      price_max: leasing.max_price,
    }),
  );

export const makeSelectFilters = () =>
  createSelector(carListSelect, (carListState) => carListState.get('filters').toJS());

export const makeSelectLeasing = () =>
  createSelector(carListSelect, (carListState) => carListState.get('leasing').toJS());

export const selectError = () =>
  createSelector(carListSelect, (carListState) => carListState.get('error'));

export const selectCars = () =>
  createSelector(carListSelect, (carListState) => carListState.get('cars').toJS());

export const makeSelectAppliedFilters = () =>
  createSelector(carListSelect, (carListState) => carListState.get('applied_filters').toJS());

export const makeSelectAppliedFiltersMapped = () =>
  createSelector(makeSelectAppliedFilters(), (filters) => {
    const mappedFilters = {};
    Object.keys(filters).forEach((key) => {
      if (Array.isArray(filters[key])) {
        mappedFilters[key] = filters[key].map((filter) => filter.value);
      } else {
        mappedFilters[key] = filters[key].value;
      }
    });

    return mappedFilters;
  });

export const makeSelectRangeEnabled = () =>
  createSelector(carListSelect, (carListState) => carListState.get('range'));

export const makeSelectLastFilter = () =>
  createSelector(carListSelect, (carListState) => carListState.get('lastFilter'));

export const selectTermsAndCarsLoading = () =>
  createSelector(carListSelect, (state) => state.get('fetchingTerms'));
