"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.responseMappers = exports.requestMappers = exports.formMappers = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _core = require("@northstar/core");
var _objectUtils = require("../../utils/object-utils");
const _excluded = ["identities"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const identitiesSorter = (itemA, itemB) => itemA.status.localeCompare(itemB.status) || itemA.role.baseType.localeCompare(itemB.role.baseType);
const mapUser = _ref => {
  let {
      identities
    } = _ref,
    user = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return _objectSpread(_objectSpread({}, user), {}, {
    identities: identities.sort(identitiesSorter)
  });
};
const usersSorter = (itemA, itemB) => itemA.status.localeCompare(itemB.status) || itemA.username.localeCompare(itemB.username);
const mapUsersResponse = _ref2 => {
  let {
    users
  } = _ref2;
  const camelCasedUsers = (0, _objectUtils.objectToCamelCase)({
    obj: users
  });
  const sortedUsers = camelCasedUsers.sort(usersSorter);
  return sortedUsers.map(mapUser);
};
const mapIdentities = identities => identities.map(_ref3 => {
  let {
    id
  } = _ref3;
  return id;
});
const mapIdentitiesToReauthorize = _ref4 => {
  let {
    currentRole,
    identities
  } = _ref4;
  return mapIdentities(identities.filter(_ref5 => {
    let {
      role: {
        baseType: roleBaseType
      }
    } = _ref5;
    if (currentRole === _core.USER_ROLE_VENDOR_ADMIN) {
      return roleBaseType === _core.USER_ROLE_VENDOR_EMPLOYEE;
    } else if (currentRole === _core.USER_ROLE_ONBOARDER) {
      return roleBaseType === _core.USER_ROLE_VENDOR_ADMIN;
    }
    return false;
  }));
};
const mapCreateIdentityRequest = _ref6 => {
  let {
    values: {
      role,
      username,
      vendorId
    },
    isOnboarder
  } = _ref6;
  return {
    base_type: role === null || role === void 0 ? void 0 : role.value,
    username: username === null || username === void 0 ? void 0 : username.value,
    vendorId: isOnboarder ? vendorId === null || vendorId === void 0 ? void 0 : vendorId.value : null
  };
};
const mapUpdateIdentities = _ref7 => {
  let {
    identities,
    status
  } = _ref7;
  return {
    identities: mapIdentities(identities),
    identity_status: status
  };
};
const mapCreateUser = _ref8 => {
  let {
    username,
    phone,
    role,
    vendorId,
    firstName,
    lastName,
    alternativeEmailAddress
  } = _ref8;
  return {
    username,
    phone,
    base_type: role === null || role === void 0 ? void 0 : role.value,
    vendorId: vendorId === null || vendorId === void 0 ? void 0 : vendorId.value,
    first_name: firstName,
    last_name: lastName,
    alternative_email_address: alternativeEmailAddress
  };
};
const requestMappers = exports.requestMappers = {
  mapIdentities,
  mapUpdateIdentities,
  mapIdentitiesToReauthorize,
  mapCreateIdentity: mapCreateIdentityRequest,
  mapCreateUser
};
const responseMappers = exports.responseMappers = {
  mapUsers: mapUsersResponse
};
const formMappers = exports.formMappers = {};
var _default = exports.default = {
  requestMappers,
  responseMappers,
  formMappers
};