"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.submitEquipmentVan = exports.submitEquipment = exports.getFrameEquipmentVan = exports.getFrameEquipment = void 0;
var _requestHandler = _interopRequireDefault(require("../../utils/request-handler"));
const {
  frameDomain
} = _requestHandler.default;
const getFrameEquipment = _ref => {
  let {
    frameId,
    frameVersionId
  } = _ref;
  return frameDomain.GET_EQUIPMENT.call({
    urlParams: {
      frame_id: frameId,
      frame_version_id: frameVersionId
    }
  });
};
exports.getFrameEquipment = getFrameEquipment;
const submitEquipment = body => frameDomain.CREATE_EQUIPMENT.call({
  body
});
exports.submitEquipment = submitEquipment;
const getFrameEquipmentVan = _ref2 => {
  let {
    frameId,
    frameVersionId
  } = _ref2;
  return frameDomain.GET_EQUIPMENT_VAN.call({
    urlParams: {
      frame_id: frameId,
      frame_version_id: frameVersionId
    }
  });
};
exports.getFrameEquipmentVan = getFrameEquipmentVan;
const submitEquipmentVan = body => frameDomain.CREATE_EQUIPMENT_VAN.call({
  body
});
exports.submitEquipmentVan = submitEquipmentVan;