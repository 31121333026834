"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDocumentsByType = void 0;
var _documentsConstants = require("./documentsConstants");
const getDocumentsByType = _ref => {
  let {
    type,
    pdfs
  } = _ref;
  const {
    invoicePdf,
    signingAgreement,
    proofOfDeliveryPaper,
    signingAgreementAppendix,
    guarantorAgreement
  } = pdfs;
  const documentsMap = {
    [_documentsConstants.DOCUMENT_TYPE_VENDOR_INVOICE]: invoicePdf,
    [_documentsConstants.DOCUMENT_TYPE_SIGNING_AGREEMENT]: signingAgreement,
    [_documentsConstants.DOCUMENT_TYPE_PROOF_OF_DELIVERY]: proofOfDeliveryPaper,
    [_documentsConstants.DOCUMENT_TYPE_GUARANTOR_AGREEMENT]: guarantorAgreement
  };
  return {
    document: documentsMap[type],
    appendixDocument: signingAgreementAppendix
  };
};
exports.getDocumentsByType = getDocumentsByType;