import { ISxStyles } from '../../../theming/theme.type';

export const styles: ISxStyles = {
  overlay: ({ zIndex }) => ({
    position: 'fixed',
    zIndex: zIndex.mobileStepper,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }),
};
