"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const getBorder = colors => "1px solid ".concat(colors.solid.gainsboro);
const styles = exports.styles = {
  label: _ref => {
    let {
      typography,
      colors,
      fontSizes
    } = _ref;
    return {
      fontFamily: typography.main,
      textAlign: 'center',
      border: getBorder(colors),
      fontSize: fontSizes.small
    };
  },
  blank: _ref2 => {
    let {
      colors
    } = _ref2;
    return {
      borderRight: getBorder(colors),
      borderBottom: getBorder(colors)
    };
  },
  header: _ref3 => {
    let {
      typography,
      colors,
      fontSizes
    } = _ref3;
    return {
      fontFamily: typography.main,
      height: 48,
      textAlign: 'center',
      color: colors.alpha(colors.solid.black, 0.87),
      fontSize: fontSizes.extraSmall,
      fontWeight: 'bold',
      borderBottom: '1px'
    };
  },
  row: _ref4 => {
    let {
      colors
    } = _ref4;
    return {
      '&.MuiTableRow-root': {
        borderLeft: getBorder(colors),
        borderRight: getBorder(colors),
        '&:last-child': {
          borderBottom: getBorder(colors)
        }
      }
    };
  },
  borderless: {
    '&.MuiTableCell-root': {
      border: 'none'
    }
  }
};