import { PROCESS_VARIANCES, ObjectUtils, VALIDITY_VALID } from '@northstar/core';
import { app, vendor } from '@northstar/core-ui/modules';
import { put, takeLatest, select, all } from 'redux-saga/effects';
import { callWithAttachers } from '@northstar/core-ui/utils/redux-saga-utils';
import { localStorageUtils } from '@northstar/core-ui/utils';
import { selectCurrentIdentity } from '@northstar/core-ui/modules/auth/authSelectors';
import { setActiveTerms as authSetActiveTerms } from '@northstar/core-ui/modules/auth/authReducer';
import { setVendorBrands } from '@northstar/core-ui/modules/frameForm/frameFormReducer';
import { USE_CURRENT_VENDOR_TERMS } from '@northstar/core-ui/modules/auth/authConstants';
import { selectAllAvailableProcessVariances } from '@northstar/core-ui/modules/processVariances/processVariancesSelectors';

import { createAttacherForTermsToUse } from 'modules/vendor/vendorTermsAttachers';

import { responseMappers } from './vendorTermsMapper';
import {
  getVendorTermsPROLRequest,
  getVendorTermsPROLResponse,
  getVendorRequest,
  getVendorResponse,
  getVendorTermsPRHPRequest,
  getVendorTermsPRHPResponse,
  getVendorTermsCMOLRequest,
  getVendorTermsCMOLResponse,
  getVendorTermsCMFLRequest,
  getVendorTermsCMFLResponse,
  setGettingTerms,
  setActiveTermsRequest,
  setActiveTermsResponse,
  clearActiveTermsRequest,
} from './vendorTermsReducer';
import {
  selectVendorTermsPROL,
  selectVendorTermsPRHP,
  selectVendorTermsCMOL,
  selectVendorTermsCMFL,
  selectActiveTerms,
} from './vendorTermsSelectors';
import { getImportersTermsResponse } from './importers/importersReducer';

const { vendorApi } = vendor;

const createVendorTermsSaga = ({
  financialType,
  selectCurrentTerms,
  apiToCall,
  vendorResponse,
  reponseMapper,
}) =>
  function* getVendorTerms() {
    try {
      const currentTerms = yield select(selectCurrentTerms());
      if (!ObjectUtils.isNullOrEmpty(currentTerms)) {
        yield put(setGettingTerms(false));
        return;
      }

      const { terms } = yield callWithAttachers({
        endpoint: apiToCall,
        payload: {
          validity: VALIDITY_VALID,
        },
        attachers: [createAttacherForTermsToUse(financialType)],
      });

      const activeTerms = yield select(selectActiveTerms(financialType));
      if (activeTerms && activeTerms !== USE_CURRENT_VENDOR_TERMS) {
        yield put(
          getImportersTermsResponse({
            financialType,
            importerId: activeTerms,
            terms: terms.map((term) => reponseMapper(term)),
          }),
        );
        yield put(setGettingTerms(false));
      } else {
        yield put(vendorResponse(terms.map((term) => reponseMapper(term))));
      }
    } catch (e) {
      yield put(vendorResponse(e));
      yield put(app.displayError('errors.failed_to_get_leasing_terms'));
    }
  };

export const getVendorTermsPROL = createVendorTermsSaga({
  financialType: PROCESS_VARIANCES.PRIVATE_OL,
  selectCurrentTerms: selectVendorTermsPROL,
  apiToCall: vendorApi.getAllVendorTermsPROL,
  vendorResponse: getVendorTermsPROLResponse,
  reponseMapper: responseMappers.mapTermsPROL,
});

export const getVendorTermsPRHP = createVendorTermsSaga({
  financialType: PROCESS_VARIANCES.PRIVATE_HP,
  selectCurrentTerms: selectVendorTermsPRHP,
  apiToCall: vendorApi.getAllVendorTermsPRHP,
  vendorResponse: getVendorTermsPRHPResponse,
  reponseMapper: responseMappers.mapTermsPRHP,
});

export const getVendorTermsCMOL = createVendorTermsSaga({
  financialType: PROCESS_VARIANCES.COMMERCIAL_OL,
  selectCurrentTerms: selectVendorTermsCMOL,
  apiToCall: vendorApi.getAllVendorTermsCMOL,
  vendorResponse: getVendorTermsCMOLResponse,
  reponseMapper: responseMappers.mapTermsCMOL,
});

export const getVendorTermsCMFL = createVendorTermsSaga({
  financialType: PROCESS_VARIANCES.COMMERCIAL_FL,
  selectCurrentTerms: selectVendorTermsCMFL,
  apiToCall: vendorApi.getAllVendorTermsCMFL,
  vendorResponse: getVendorTermsCMFLResponse,
  reponseMapper: responseMappers.mapTermsCMFL,
});

function* getVendor({ payload = {} } = {}) {
  try {
    const { shouldTryToUseImporter = false } = payload;
    const attachers = shouldTryToUseImporter ? [createAttacherForTermsToUse()] : [];
    const response = yield callWithAttachers({
      endpoint: vendorApi.getVendor,
      attachers,
    });
    yield put(setVendorBrands(response.brands));
    yield put(getVendorResponse(responseMappers.mapVendor(response)));
  } catch (e) {
    yield put(app.displayError(e));
    yield put(getVendorResponse(e));
  }
}

export function* setInitialActiveTerms() {
  yield put(clearActiveTermsRequest());
  const { id } = yield select(selectCurrentIdentity());
  const existingTerms = localStorageUtils.getValueByKey(localStorageUtils.KEYS.DEFAULT_TERMS) || {};
  const currentIdentityTerms = existingTerms[id];
  if (currentIdentityTerms) {
    const allAvailableFeatures = yield select(selectAllAvailableProcessVariances());
    yield all(
      Object.keys(currentIdentityTerms).map(
        (financialType) =>
          allAvailableFeatures[financialType].find(
            (item) => item.id === currentIdentityTerms[financialType],
          ) &&
          put(
            setActiveTermsResponse({ financialType, value: currentIdentityTerms[financialType] }),
          ),
      ),
    );
  }
}

function* setActiveTerms({ payload }) {
  const { financialType, value } = payload;
  const { id } = yield select(selectCurrentIdentity());
  const existingTerms = localStorageUtils.getValueByKey(localStorageUtils.KEYS.DEFAULT_TERMS) || {};
  localStorageUtils.setValueByKey(localStorageUtils.KEYS.DEFAULT_TERMS, {
    ...existingTerms,
    [id]: {
      ...existingTerms[id],
      [financialType]: value,
    },
  });
  yield put(setActiveTermsResponse(payload));
}

export default function* vendorSaga() {
  yield takeLatest(getVendorTermsPROLRequest, getVendorTermsPROL);
  yield takeLatest(getVendorTermsPRHPRequest, getVendorTermsPRHP);
  yield takeLatest(getVendorTermsCMOLRequest, getVendorTermsCMOL);
  yield takeLatest(getVendorTermsCMFLRequest, getVendorTermsCMFL);
  yield takeLatest(getVendorRequest, getVendor);
  yield takeLatest(authSetActiveTerms, setInitialActiveTerms);
  yield takeLatest(setActiveTermsRequest, setActiveTerms);
}
