import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  detailsPanelRoot: ({ typography, fontSizes, colors }) => ({
    '&.MuiAccordionDetails-root': {
      borderTop: `2px solid ${colors.custom.accordion.color}`,
      padding: '16px 0px',
      fontFamily: typography.main,
      fontSize: fontSizes.small,
      lineHeight: '24px',
      color: colors.custom.accordion.color,
    },
  }),
  panelRoot: ({ colors }) => ({
    '&.MuiAccordion-root': {
      background: colors.custom.accordion.background,
      boxShadow: 'none',
      '&.Mui-expanded': {
        margin: '0',
      },
      borderBottom: `1px solid ${colors.custom.accordion.bottomColor}`,
      borderRadius: 0,
    },
  }),
  panelExpanded: ({ colors }) => ({
    '&.MuiAccordion-root.Mui-expanded': {
      borderBottom: `1px solid ${colors.custom.accordion.color}`,
    },
  }),
  summaryPanelContent: ({ typography, fontSizes, colors }) => ({
    '& .MuiAccordionSummary-content': {
      fontSize: fontSizes.small,
      lineHeight: '24px',
      fontFamily: typography.main,
      color: colors.custom.accordion.color,
      margin: '16px 0px !important',
      flexDirection: 'column',
    },
  }),
  summaryPanelRoot: ({ colors }) => ({
    '&.MuiAccordionSummary-root': {
      padding: '0',
      minHeight: 'unset !important',
      margin: '0',
      '&:focus-visible': {
        backgroundColor: colors.custom.accordion.focusBackground,
      },
    },
  }),
  summaryPanelExpandIcon: ({ colors }) => ({
    transitionDuration: '500ms',
    marginRight: '0',
    width: '16px',
    fill: colors.custom.accordion.color,
    padding: 2,
  }),
  isExpandableRoot: ({ colors }) => ({
    '&.MuiAccordionSummary-root': {
      background: colors.custom.accordion.background,
      boxShadow: 'none',
      borderBottom: `1px solid ${colors.custom.accordion.bottomColor}`,
      padding: '0',
      pointerEvents: 'none',
      userSelect: 'text',
      '&:focus-visible': {
        backgroundColor: colors.custom.accordion.focusBackground,
      },
    },
  }),
  summaryPanelChild: {
    padding: '0',
  },
};
