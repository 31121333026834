"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  root: () => ({
    '& .MuiDialog-paper': {
      maxWidth: 1360,
      width: 'auto'
    }
  }),
  title: _ref => {
    let {
      typography: {
        main
      },
      fontSizes: {
        small
      }
    } = _ref;
    return {
      fontFamily: main,
      fontSize: small,
      fontWeight: 600,
      letterSpacing: '0.45px',
      lineHeight: '28px',
      textAlign: 'left'
    };
  },
  contentRoot: () => ({
    '& .MuiDialogContent-root': {
      padding: '32px 24px !important',
      textAlign: 'left'
    }
  }),
  container: {
    maxWidth: 900,
    padding: '0 10px',
    margin: 'auto'
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative'
  },
  loading: {
    position: 'absolute',
    zIndex: 1,
    alignSelf: 'center'
  },
  label: {
    textAlign: 'center',
    marginTop: '44px',
    marginBottom: 3
  }
};