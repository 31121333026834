import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  color: PropTypes.oneOf(['primary']),
  fontFamily: PropTypes.oneOf(['main', 'title']),
  fontSize: PropTypes.oneOf([
    'extraLarge',
    'extraMedium',
    'extraSmall',
    'giant',
    'huge',
    'large',
    'medium',
    'small',
    'tiny',
  ]),
  fontStyle: PropTypes.oneOf(['italic']),
  fontWeight: PropTypes.oneOf(['600', 'bold', 'normal', 400, 600]),
  isWithContainer: PropTypes.bool,
  style: PropTypes.object,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  textAlign: PropTypes.oneOf(['center', 'justify', 'left', 'right']),
} as any;
