"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleRememberUsername = exports.stopSagas = exports.setRedirectPath = exports.setIdentityForSwitching = exports.setActiveTerms = exports.sessionUnauthorizedResponse = exports.sessionUnauthenticatedResponse = exports.sessionResponse = exports.sessionRequest = exports.selfOnboardResponse = exports.selfOnboardRequest = exports.selectIdentityResponse = exports.selectIdentityRequest = exports.sealerResponse = exports.runSagas = exports.resetAppState = exports.redirectWithIdentitySwitch = exports.redirectToPath = exports.redirectToLogin = exports.redirectToHome = exports.redirectToForbidden = exports.onboardUserResponse = exports.onboardUserRequest = exports.logoutResponse = exports.logoutRequest = exports.loginResponse = exports.loginRequest = exports.loginInternalResponse = exports.loginInternalRequest = exports.initialState = exports.default = exports.clearErrors = exports.clearAuthState = exports.checkForIdentitySwitchResponse = exports.checkForIdentitySwitchRequest = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
var _authUtils = require("./authUtils");
/**
 * Actions
 */
const loginRequest = exports.loginRequest = (0, _reduxActions.createAction)('AUTH_LOGIN_REQUEST');
const loginResponse = exports.loginResponse = (0, _reduxActions.createAction)('AUTH_LOGIN_RESPONSE');
const onboardUserRequest = exports.onboardUserRequest = (0, _reduxActions.createAction)('AUTH_ONBOARD_USER_REQUEST');
const onboardUserResponse = exports.onboardUserResponse = (0, _reduxActions.createAction)('AUTH_ONBOARD_USER_RESPONSE');
const loginInternalRequest = exports.loginInternalRequest = (0, _reduxActions.createAction)('AUTH_LOGIN_INTERNAL_REQUEST');
const loginInternalResponse = exports.loginInternalResponse = (0, _reduxActions.createAction)('AUTH_LOGIN_INTERNAL_RESPONSE');
const selectIdentityRequest = exports.selectIdentityRequest = (0, _reduxActions.createAction)('SELECT_IDENTITY_REQUEST');
const selectIdentityResponse = exports.selectIdentityResponse = (0, _reduxActions.createAction)('SELECT_IDENTITY_RESPONSE');
const sessionRequest = exports.sessionRequest = (0, _reduxActions.createAction)('AUTH_SESSION_REQUEST');
const sessionResponse = exports.sessionResponse = (0, _reduxActions.createAction)('AUTH_SESSION_RESPONSE');
const sessionUnauthorizedResponse = exports.sessionUnauthorizedResponse = (0, _reduxActions.createAction)('AUTH_SESSION_UNAUTHORIZED_RESPONSE');
const sessionUnauthenticatedResponse = exports.sessionUnauthenticatedResponse = (0, _reduxActions.createAction)('AUTH_SESSION_UNAUTHENTICATED_RESPONSE');
const logoutRequest = exports.logoutRequest = (0, _reduxActions.createAction)('AUTH_LOGOUT_REQUEST');
const logoutResponse = exports.logoutResponse = (0, _reduxActions.createAction)('AUTH_LOGOUT_RESPONSE');
const sealerResponse = exports.sealerResponse = (0, _reduxActions.createAction)('AUTH_SEALER_RESPONSE');
const toggleRememberUsername = exports.toggleRememberUsername = (0, _reduxActions.createAction)('AUTH_TOGGLE_REMEMBER_USERNAME');
const clearErrors = exports.clearErrors = (0, _reduxActions.createAction)('AUTH_CLEAR_ERRORS');
const redirectToHome = exports.redirectToHome = (0, _reduxActions.createAction)('AUTH_REDIRECT_TO_HOME');
const redirectToPath = exports.redirectToPath = (0, _reduxActions.createAction)('AUTH_REDIRECT_TO_PATH');
const clearAuthState = exports.clearAuthState = (0, _reduxActions.createAction)('AUTH_CLEAR_AUTH_STATE');
const resetAppState = exports.resetAppState = (0, _reduxActions.createAction)('AUTH_RESET_APP_STATE');
const runSagas = exports.runSagas = (0, _reduxActions.createAction)('AUTH_RUN_SAGAS');
const stopSagas = exports.stopSagas = (0, _reduxActions.createAction)('AUTH_STOP_SAGAS');
const redirectToForbidden = exports.redirectToForbidden = (0, _reduxActions.createAction)('AUTH_REDIRECT_TO_FORBIDDEN');
const redirectToLogin = exports.redirectToLogin = (0, _reduxActions.createAction)('AUTH_REDIRECT_TO_LOGIN');
const setRedirectPath = exports.setRedirectPath = (0, _reduxActions.createAction)('AUTH_SET_REDIRECT_PATH');
const setActiveTerms = exports.setActiveTerms = (0, _reduxActions.createAction)('AUTH_SET_ACTIVE_TERMS');
const selfOnboardRequest = exports.selfOnboardRequest = (0, _reduxActions.createAction)('AUTH_SELF_ONBOARD_REQUEST');
const selfOnboardResponse = exports.selfOnboardResponse = (0, _reduxActions.createAction)('AUTH_SELF_ONBOARD_RESPONSE');
const checkForIdentitySwitchRequest = exports.checkForIdentitySwitchRequest = (0, _reduxActions.createAction)('AUTH_CHECK_FOR_IDENTITY_SWITCH_REQUEST');
const checkForIdentitySwitchResponse = exports.checkForIdentitySwitchResponse = (0, _reduxActions.createAction)('AUTH_CHECK_FOR_IDENTITY_SWITCH_RESPONSE');
const setIdentityForSwitching = exports.setIdentityForSwitching = (0, _reduxActions.createAction)('AUTH_SET_IDENTITY_FOR_SWITCHING');
const redirectWithIdentitySwitch = exports.redirectWithIdentitySwitch = (0, _reduxActions.createAction)('AUTH_REDIRECT_WITH_IDENTITY_SWITCH');

/**
 * Initial state
 */
const initialState = exports.initialState = (0, _immutable.fromJS)({
  authenticating: false,
  authenticated: false,
  error: '',
  user: {
    username: '',
    identities: [],
    currentIdentity: {
      role: {},
      vendor: {}
    },
    isInternal: false
  },
  auth: {
    LogonPackage: '',
    AuthenticationSecret: '',
    UserID: ''
  },
  isChangePasswordOpen: false,
  isChangingPassword: false,
  rememberUsername: Boolean((0, _authUtils.getRememberedUsername)()),
  isSelfOnboarding: false,
  redirectPath: false,
  checkingForNeedOfIdentitySwitch: false,
  identityForSwitching: false
});
/**
 * Actions handlers
 */
var _default = exports.default = (0, _reduxActions.handleActions)({
  [sealerResponse](state, _ref) {
    let {
      payload
    } = _ref;
    return state.set('auth', (0, _immutable.fromJS)({
      LogonPackage: payload.LogonPackage,
      AuthenticationSecret: payload.AuthenticationSecret,
      UserID: payload.UserID
    }));
  },
  [(0, _reduxActions.combineActions)(loginRequest, loginInternalRequest, sessionRequest, selectIdentityRequest)](state) {
    return state.set('authenticating', true);
  },
  [(0, _reduxActions.combineActions)(loginResponse, loginInternalResponse, sessionResponse, selectIdentityResponse)](state, _ref2) {
    let {
      payload,
      error
    } = _ref2;
    return !error ? state.set('authenticating', false).set('authenticated', true).set('user', (0, _immutable.fromJS)(payload)) : state.set('authenticating', false).set('authenticated', false).set('error', payload.message);
  },
  [setRedirectPath](state, _ref3) {
    let {
      payload
    } = _ref3;
    return state.set('redirectPath', (0, _immutable.fromJS)(payload));
  },
  [setIdentityForSwitching](state, _ref4) {
    let {
      payload
    } = _ref4;
    return state.set('identityForSwitching', (0, _immutable.fromJS)(payload));
  },
  [sessionUnauthenticatedResponse](state, _ref5) {
    let {
      payload,
      error
    } = _ref5;
    return !error ? state.set('authenticating', false).set('authenticated', false).set('user', initialState.get('user')).set('auth', initialState.get('auth')) : state.set('authenticating', false).set('error', payload.message);
  },
  [sessionUnauthorizedResponse](state, _ref6) {
    let {
      payload,
      error
    } = _ref6;
    return !error ? state.set('authenticating', false) : state.set('authenticating', false).set('error', payload.message);
  },
  [logoutResponse](state, _ref7) {
    let {
      payload,
      error
    } = _ref7;
    return !error ? state.set('user', (0, _immutable.fromJS)({})).set('authenticated', false) : state.set('authenticated', false).set('error', payload.message);
  },
  [toggleRememberUsername](state, _ref8) {
    let {
      payload
    } = _ref8;
    return state.set('rememberUsername', payload);
  },
  [clearErrors](state) {
    return state.set('error', '');
  },
  [clearAuthState]() {
    return initialState.set('rememberUsername', Boolean((0, _authUtils.getRememberedUsername)()));
  },
  [selfOnboardRequest](state) {
    return state.set('isSelfOnboarding', true);
  },
  [selfOnboardResponse](state) {
    return state.set('isSelfOnboarding', false);
  },
  [checkForIdentitySwitchRequest](state) {
    return state.set('checkingForNeedOfIdentitySwitch', true);
  },
  [checkForIdentitySwitchResponse](state) {
    return state.set('checkingForNeedOfIdentitySwitch', false);
  },
  [onboardUserResponse](state, _ref9) {
    let {
      payload,
      error
    } = _ref9;
    return error ? state.set('error', payload.message) : state;
  }
}, initialState);