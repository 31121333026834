"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformRatesToTables = exports.prefillRateMatrixUndefinedsToZeros = exports.mapSingleMatrixToStore = exports.mapSingleMatrixToRequest = exports.mapRateMatrixFromForm = exports.getRatesTableInitialValues = exports.getInitialValuesFromRates = exports.flattenTables = exports.extendMatrixIfNeeded = exports.createRow = exports.createRates = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _core = require("@northstar/core");
var _arrayUtils = require("./array-utils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const mapSingleMatrixToRequest = _ref => {
  let {
    formValues,
    name,
    rateFieldName
  } = _ref;
  const flatMatrix = mapRateMatrixFromForm({
    formValues,
    name
  }).flat();
  return flatMatrix.map(row => mapStoreToRequestRow({
    row,
    rateFieldName
  }));
};
exports.mapSingleMatrixToRequest = mapSingleMatrixToRequest;
const mapStoreToRequestRow = _ref2 => {
  let {
    rateFieldName,
    row: {
      name,
      leasePeriod,
      rate,
      yearlyMileage
    }
  } = _ref2;
  return {
    name,
    lease_period: leasePeriod,
    [rateFieldName]: rate,
    yearly_mileage: yearlyMileage
  };
};
const prefillRateMatrixUndefinedsToZeros = rvMatrix => Object.keys(rvMatrix).reduce((acc, rowKey) => {
  const cells = Object.keys(rvMatrix[rowKey]).reduce((cellsAcc, cellKey) => {
    if (rvMatrix[rowKey][cellKey] === undefined) {
      return _objectSpread(_objectSpread({}, cellsAcc), {}, {
        [cellKey]: 0
      });
    } else return _objectSpread(_objectSpread({}, cellsAcc), {}, {
      [cellKey]: Number(rvMatrix[rowKey][cellKey])
    });
  }, {});
  return _objectSpread(_objectSpread({}, acc), {}, {
    [rowKey]: cells
  });
}, {});
exports.prefillRateMatrixUndefinedsToZeros = prefillRateMatrixUndefinedsToZeros;
const mapRateMatrixFromForm = _ref3 => {
  let {
    formValues,
    name
  } = _ref3;
  return Object.keys(formValues).map(rowKey => Object.keys(formValues[rowKey]).map(cellKey => ({
    name,
    leasePeriod: Number(cellKey.replace('cell_', '')),
    yearlyMileage: Number(rowKey.replace('row_', '')),
    rate: formValues[rowKey][cellKey]
  })).filter(_ref4 => {
    let {
      rate
    } = _ref4;
    return typeof rate === 'number';
  }));
};
exports.mapRateMatrixFromForm = mapRateMatrixFromForm;
const mapSingleMatrixToStore = _ref5 => {
  let {
    name,
    rates,
    mileages,
    periods
  } = _ref5;
  const matrix = mapRatesValues({
    rates: rates.map(mapMatrixRow)
  });
  return extendMatrixIfNeeded({
    name,
    matrix,
    mileages,
    periods
  });
};
exports.mapSingleMatrixToStore = mapSingleMatrixToStore;
const mapMatrixRow = _ref6 => {
  let {
    name,
    lease_period: leasePeriod,
    service_rate_ex_vat: rate,
    insurance_rate: {
      ex_vat: insuranceExVat
    } = {},
    coefficient,
    yearly_mileage: yearlyMileage
  } = _ref6;
  return {
    name,
    leasePeriod,
    rate: rate || coefficient || insuranceExVat,
    yearlyMileage
  };
};
const mapRatesValues = _ref7 => {
  let {
    rates
  } = _ref7;
  return sortByAscendingPeriods(Object.values((0, _arrayUtils.groupBy)(sortByAcsendingMileage(rates), 'yearlyMileage')));
};
const getServiceLevels = rates => [...new Set(rates.map(value => value.name))];
const sortByAcsendingMileage = rates => rates.sort((a, b) => a.yearlyMileage - b.yearlyMileage);
const getRatesByServiceName = (name, rates) => rates.filter(x => x.name === name);
const sortByAscendingPeriods = rows => rows.map(row => row.sort((a, b) => a.leasePeriod - b.leasePeriod));
const flattenTables = rates => {
  const flatRows = [].concat(...rates.reduce((prev, curr) => [...prev, curr.rates], []));
  return [].concat(...flatRows);
};
exports.flattenTables = flattenTables;
const transformRatesToTables = rates => {
  const levels = getServiceLevels(rates);
  const ratesByLevel = levels.map(level => ({
    levelName: level,
    rates: mapRatesValues({
      rates: getRatesByServiceName(level, rates)
    })
  })).sort((0, _arrayUtils.createAlphabeticalSorter)({
    property: 'levelName'
  }));
  return ratesByLevel;
};
exports.transformRatesToTables = transformRatesToTables;
const getInitialValuesFromRates = _ref8 => {
  let {
    rates
  } = _ref8;
  if (rates.length === 0) return {};
  return rates.reduce((prev, values) => {
    const {
      yearlyMileage
    } = values[0];
    const rowValues = values.reduce((prevVal, _ref9) => {
      let {
        rate,
        leasePeriod
      } = _ref9;
      return _objectSpread(_objectSpread({}, prevVal), {}, {
        ["cell_".concat(leasePeriod)]: rate
      });
    }, {});
    return _objectSpread(_objectSpread({}, prev), {}, {
      ["row_".concat(yearlyMileage)]: rowValues
    });
  }, []);
};
exports.getInitialValuesFromRates = getInitialValuesFromRates;
const getRatesTableInitialValues = _ref10 => {
  let {
    rates = [],
    selected
  } = _ref10;
  if (rates.length === 0 || _core.ObjectUtils.isNullOrEmpty(selected)) {
    return {};
  }
  const matrix = rates.find(item => item.id === selected);
  return matrix ? getInitialValuesFromRates({
    rates: matrix.rates
  }) : {};
};

/**
 *
 * @param {String} name - name of the matrix
 * @param {Array} matrix - matrix to be updated
 * @param {Array} mileages - extended mileages to be set in
 * @param {Array} periods - extended periods to be set in
 */
exports.getRatesTableInitialValues = getRatesTableInitialValues;
const extendMatrixIfNeeded = _ref11 => {
  let {
    name,
    matrix,
    mileages,
    periods
  } = _ref11;
  const oldPeriods = matrix[0].map(_ref12 => {
    let {
      leasePeriod
    } = _ref12;
    return leasePeriod;
  });
  const oldMileages = matrix.map(row => row[0].yearlyMileage);
  const matrixWithExtendedMileages = extendMileagesIfNeeded({
    name,
    oldPeriods,
    oldMileages,
    matrix,
    mileages
  });
  const matrixWithExtendedPeriods = extendPeriodsIfNeeded({
    name,
    oldPeriods,
    matrix: matrixWithExtendedMileages,
    periods
  });
  return matrixWithExtendedPeriods;
};

/**
 *
 * @param {String} name - name of the matrix
 * @param {Array} currentPeriods - old matrix periods
 * @param {Array} oldMileages - old matrix mileages
 * @param {Array} matrix - matrix to be updated
 * @param {Array} mileages - extended mileages to be set in
 */
exports.extendMatrixIfNeeded = extendMatrixIfNeeded;
const extendMileagesIfNeeded = _ref13 => {
  let {
    name,
    oldPeriods,
    oldMileages,
    matrix,
    mileages
  } = _ref13;
  const mileagesDiff = findDiffBetweenArrays({
    array1: mileages,
    array2: oldMileages
  });
  return mileagesDiff.length ? [...matrix, ...mileagesDiff.map(mileage => createRow({
    name,
    periods: oldPeriods,
    mileage
  }))] : matrix;
};

/**
 *
 * @param {String} name - name of the matrix
 * @param {Array} oldPeriods - old matrix periods
 * @param {Array} matrix - matrix to be updated
 * @param {Array} periods - extended periods to be set in
 */
const extendPeriodsIfNeeded = _ref14 => {
  let {
    name,
    oldPeriods,
    matrix,
    periods
  } = _ref14;
  const periodsDiff = findDiffBetweenArrays({
    array1: periods,
    array2: oldPeriods
  });
  return periodsDiff.length ? matrix.map(row => [...row, ...periodsDiff.map(period => createCell({
    period,
    mileage: row[0].yearlyMileage,
    name
  }))]) : matrix;
};
const findDiffBetweenArrays = _ref15 => {
  let {
    array1,
    array2
  } = _ref15;
  return array1.filter(element => !array2.includes(element));
};
const createCell = _ref16 => {
  let {
    period,
    mileage,
    name
  } = _ref16;
  return {
    name,
    leasePeriod: period,
    rate: '',
    yearlyMileage: mileage
  };
};
const createRow = _ref17 => {
  let {
    name,
    periods,
    mileage
  } = _ref17;
  return periods.map(period => createCell({
    period,
    mileage,
    name
  }));
};
exports.createRow = createRow;
const createRates = _ref18 => {
  let {
    name,
    mileages,
    periods
  } = _ref18;
  return mileages.map(mileage => createRow({
    name,
    periods,
    mileage
  }));
};
exports.createRates = createRates;