"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  container: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  fieldContainer: {
    '.MuiTextField-root': {
      marginRight: '10px'
    }
  },
  iconButton: _ref => {
    let {
      colors
    } = _ref;
    return _objectSpread(_objectSpread({}, colors.custom.chatMessage.own), {}, {
      height: 36,
      width: 36,
      '&:hover': _objectSpread(_objectSpread({}, colors.custom.chatMessage.own), {}, {
        boxShadow: 'rgba(102, 102, 102, 0.2) 0px 1px 2px 0px, rgba(102, 102, 102, 0.2) 0px 2px 4px 0px'
      }),
      '&:disabled': _objectSpread(_objectSpread({}, colors.custom.disabled), {}, {
        opacity: 0.7
      }),
      marginLeft: '12px',
      marginBottom: '6px'
    });
  },
  svgIcon: _ref2 => {
    let {
      colors
    } = _ref2;
    return {
      width: 20,
      height: 20,
      '& g': {
        fill: colors.solid.white
      }
    };
  }
};