const ProofOfDeliveryRejected: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="38px"
    height="27px"
    viewBox="0 0 38 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="Screens"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <g
        id="07-Case-detail-all-statuses-assets"
        transform="translate(-489.000000, -424.000000)"
        stroke="#FFFFFF"
        strokeWidth="2.25"
      >
        <g id="Group-17" transform="translate(490.000000, 426.000000)">
          <path d="M0.449442987,13.125 L11.2360747,13.125" id="Line-Copy-27"></path>
          <path d="M4.88677614,8.5 L12.55,8.5" id="Line-Copy-28"></path>
          <path
            d="M12.1592103,2.54145682e-16 L33.6804051,0 C34.5088322,-2.35955288e-15 35.1804051,0.671572875 35.1804051,1.5 L35.1804051,21.5 C35.1804051,22.3284271 34.5088322,23 33.6804051,23 L8,23"
            id="Rectangle-15-Copy-8"
          ></path>
          <path d="M20.5,7 L29.5,16" id="Line-4-Copy-2"></path>
          <path
            d="M24.9246212,4.8621212 L24.9246212,17.9871212"
            id="Line-4-Copy-2"
            transform="translate(24.924621, 11.424621) scale(-1, 1) rotate(-45.000000) translate(-24.924621, -11.424621) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default ProofOfDeliveryRejected;
