"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = asset;
exports.deleteAsset = deleteAsset;
exports.formSubmitAsset = formSubmitAsset;
exports.getAsset = getAsset;
exports.getAssetAndRedirectVendor = getAssetAndRedirectVendor;
exports.getAssetEditRequest = getAssetEditRequest;
exports.hardDeleteAsset = hardDeleteAsset;
exports.restoreAsset = restoreAsset;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _core = require("@northstar/core");
var _numeral = _interopRequireDefault(require("numeral"));
var _reduxForm = require("redux-form");
var _effects = require("redux-saga/effects");
var _maf = require("@asfi/maf");
var _equipmentMappers = require("../asset/equipmentMappers");
var _appReducer = require("../app/appReducer");
var assetModule = _interopRequireWildcard(require("../asset"));
var _equipmentConstants = require("../equipmentConstants");
var _vendorDashboardConstants = require("./vendorDashboardConstants");
var errorUtils = _interopRequireWildcard(require("../../utils/error-utils"));
var _reduxSagaUtils = require("../../utils/redux-saga-utils");
var stringUtils = _interopRequireWildcard(require("../../utils/string-utils"));
var _route = require("../../screens/vendorDashboard/route");
var _vendorDashboardAppSelectors = require("./vendorDashboardAppSelectors");
var _vendorDashboardAssetListReducer = require("./vendorDashboardAssetListReducer");
var _vendorDashboardAssetReducer = require("./vendorDashboardAssetReducer");
var _vendorDashboardAssetSelectors = require("./vendorDashboardAssetSelectors");
var _vendorDashboardEquipmentReducer = require("./vendorDashboardEquipmentReducer");
var _vendorDashboardEquipmentSelectors = require("./vendorDashboardEquipmentSelectors");
var _vendorDashboardFrameReducer = require("./vendorDashboardFrameReducer");
var _vendorDashboardFrameSelectors = require("./vendorDashboardFrameSelectors");
var _dashboardSagaUtils = require("./vendorDashboardUtils/dashboard-saga-utils");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } // Frame Module
// Equipment module
const {
  frameMappers,
  assetMappers
} = assetModule;
const {
  responseMappers: frameResponseMappers
} = frameMappers;
const {
  responseMappers,
  formMappers
} = assetMappers;
function* openFormAndHideOverflow() {
  yield (0, _effects.put)((0, _appReducer.hideOverflowScroll)());
  yield (0, _effects.put)((0, _vendorDashboardFrameReducer.openForm)());
}
function* closeFormAndShowOverflow() {
  yield (0, _effects.put)((0, _appReducer.showOverflowScroll)());
  yield (0, _effects.put)((0, _vendorDashboardFrameReducer.closeForm)());
}
function deleteAsset(_ref) {
  let {
    payload
  } = _ref;
  return function* () {
    try {
      const {
        assetId,
        reload
      } = payload;
      yield (0, _reduxSagaUtils.callWithAttachers)({
        endpoint: assetModule.deleteAsset,
        payload: {
          assetId,
          active: false
        },
        attachers: [_dashboardSagaUtils.attachRouteVendorId, _dashboardSagaUtils.attachIsCommercial]
      });
      if (reload) {
        yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.getAssetsDynamicRequest)({
          active: true
        }));
      }
      yield (0, _effects.put)((0, _appReducer.displaySnackbar)({
        id: 'vendor.deactivate_asset_success'
      }));
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
    }
  }();
}
function restoreAsset(_ref2) {
  let {
    payload
  } = _ref2;
  return function* () {
    try {
      const {
        assetId,
        reload
      } = payload;
      yield (0, _reduxSagaUtils.callWithAttachers)({
        endpoint: assetModule.editPatchAsset,
        payload: {
          assetId,
          active: true
        },
        attachers: [_dashboardSagaUtils.attachRouteVendorId, _dashboardSagaUtils.attachIsCommercial]
      });
      if (reload) {
        yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.getAssetsDynamicRequest)({
          active: 'false'
        }));
      }
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
    }
  }();
}
const extractRetrofitValues = formValues => Object.keys(formValues).map(key => key.includes(_equipmentConstants.EQUIPMENT_FORM_RETROFIT) ? formValues[key] : undefined).filter(Boolean);
function mapSingleRetrofitEquipment(_ref3) {
  let {
    name,
    price,
    vehicleType
  } = _ref3;
  return function* () {
    const apiToCall = vehicleType === _core.VEHICLE_TYPE_VAN ? assetModule.submitEquipmentVan : assetModule.submitEquipment;
    const equipment = yield (0, _effects.call)(apiToCall, {
      name: {
        local: name,
        english: name
      },
      category: _equipmentConstants.EQUIPMENT_CATEGORY_RETROFIT
    });
    return yield _objectSpread(_objectSpread({}, equipment), {}, {
      price_ex_vat: (0, _numeral.default)(price).value()
    });
  }();
}
function getAssetEditRequest() {
  let {
    vehicleType
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function* () {
    const formValues = yield (0, _effects.select)((0, _reduxForm.getFormValues)(_equipmentConstants.EQUIPMENT_FORM));
    const equipmentForm = formMappers.mapEquipmentToRequest(formValues);
    const equipmentInState = yield (0, _effects.select)((0, _vendorDashboardEquipmentSelectors.selectEquipment)());
    const mappedEq = yield (0, _effects.all)(extractRetrofitValues(formValues).map(equipment => (0, _effects.call)(mapSingleRetrofitEquipment, _objectSpread(_objectSpread({}, equipment), {}, {
      vehicleType
    }))));
    const privatePrice = equipmentForm[_vendorDashboardConstants.ASSET_FIELD_PRIVATE_PRICE] && (0, _numeral.default)(equipmentForm[_vendorDashboardConstants.ASSET_FIELD_PRIVATE_PRICE]).value();
    const commercialPrice = equipmentForm[_vendorDashboardConstants.ASSET_FIELD_COMMERCIAL_PRICE] && (0, _numeral.default)(equipmentForm[_vendorDashboardConstants.ASSET_FIELD_COMMERCIAL_PRICE]).value();
    return {
      included_equipment: equipmentInState.standard.map(_ref4 => {
        let {
          id
        } = _ref4;
        return id;
      }),
      private_price_ex_vat: privatePrice,
      commercial_price_ex_vat: commercialPrice,
      service_level: equipmentForm.serviceLevel,
      insurance: equipmentForm.insurance === stringUtils.EMPTY_VALUE ? null : equipmentForm.insurance,
      winter_tires: equipmentForm.winterTiresLevel === stringUtils.EMPTY_VALUE ? null : equipmentForm.winterTiresLevel,
      climate_bonus_value_ex_vat: equipmentForm[_vendorDashboardConstants.ASSET_FIELD_CLIMATE_BONUS] && (0, _numeral.default)(equipmentForm[_vendorDashboardConstants.ASSET_FIELD_CLIMATE_BONUS]).value(),
      optional_equipment: [...equipmentForm.optional_equipment, ...mappedEq],
      preconfigured_equipment: equipmentForm.preconfigured_equipment,
      private_vendor_terms_ids: privatePrice && equipmentForm.privateVendorTermId ? [equipmentForm.privateVendorTermId] : undefined,
      commercial_vendor_terms_ids: commercialPrice && equipmentForm.commercialVendorTermId ? [equipmentForm.commercialVendorTermId] : undefined
    };
  }();
}
function mapAssetError(_ref5) {
  let {
    error,
    responseAction
  } = _ref5;
  return function* () {
    const fieldLevelError = errorUtils.mapResponseExceptionsToError({
      response: error
    });
    if (fieldLevelError) {
      const {
        fieldErrors,
        reason
      } = fieldLevelError;
      yield (0, _effects.put)((0, _reduxForm.stopSubmit)(_equipmentConstants.EQUIPMENT_FORM, {
        _error: responseMappers.mapFieldErrorNames({
          fieldErrors
        })
      }));
      yield (0, _effects.put)((0, _appReducer.displaySnackbar)({
        text: reason || error.message,
        type: _maf.SNACKBAR_TYPE_ERROR
      }));
    } else {
      yield (0, _effects.put)((0, _appReducer.displaySnackbar)({
        text: error.message,
        type: _maf.SNACKBAR_TYPE_ERROR
      }));
    }
    yield (0, _effects.put)(responseAction(error));
  }();
}
function restoreAssetWithEditting(_ref6) {
  let {
    payload = {}
  } = _ref6;
  return function* () {
    try {
      const {
        redirect
      } = payload;
      const requestBody = yield (0, _effects.call)(getAssetEditRequest);
      const {
        id: assetId
      } = yield (0, _effects.select)((0, _vendorDashboardAssetSelectors.selectAsset)());
      const response = yield (0, _reduxSagaUtils.callWithAttachers)({
        endpoint: assetModule.editPatchAsset,
        payload: _objectSpread({
          assetId,
          active: true
        }, requestBody),
        attachers: [_dashboardSagaUtils.attachRouteVendorId, _dashboardSagaUtils.attachIsCommercial]
      });
      yield (0, _effects.put)((0, _vendorDashboardAssetReducer.editAssetResponse)(responseMappers.mapAsset(response)));
      yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.getAssetsDynamicRequest)({
        active: 'false'
      }));
      yield (0, _effects.put)((0, _appReducer.showOverflowScroll)());
      redirect();
      yield (0, _effects.put)((0, _appReducer.displaySnackbar)({
        id: 'vendor.activate_asset_success'
      }));
    } catch (e) {
      yield (0, _effects.call)(mapAssetError, {
        error: e,
        responseAction: _vendorDashboardAssetReducer.editAssetResponse
      });
    }
  }();
}
function* formEditAsset() {
  try {
    const requestBody = yield (0, _effects.call)(getAssetEditRequest);
    const {
      id: assetId
    } = yield (0, _effects.select)((0, _vendorDashboardAssetSelectors.selectAsset)());
    const response = yield (0, _reduxSagaUtils.callWithAttachers)({
      endpoint: assetModule.editPatchAsset,
      payload: _objectSpread({
        assetId
      }, requestBody),
      attachers: [_dashboardSagaUtils.attachRouteVendorId, _dashboardSagaUtils.attachIsCommercial]
    });
    yield (0, _effects.put)((0, _vendorDashboardAssetReducer.editAssetResponse)(responseMappers.mapAsset(response)));
    yield (0, _effects.put)((0, _vendorDashboardAssetReducer.closeAssetForm)());
    yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.getAssetsDynamicRequest)({
      active: true
    }));
    yield (0, _effects.put)((0, _appReducer.displaySnackbar)({
      id: 'vendor.edit_asset_success'
    }));
  } catch (e) {
    yield (0, _effects.call)(mapAssetError, {
      error: e,
      responseAction: _vendorDashboardAssetReducer.editAssetResponse
    });
  }
}
function* formSubmitAsset() {
  try {
    const {
      id,
      vehicleType
    } = yield (0, _effects.select)((0, _vendorDashboardFrameSelectors.selectFrame)());
    const editableBody = yield (0, _effects.call)(getAssetEditRequest, {
      vehicleType
    });
    const response = yield (0, _reduxSagaUtils.callWithAttachers)({
      endpoint: assetModule.submitAsset,
      payload: _objectSpread({
        frame_id: id,
        vehicle_type: vehicleType
      }, editableBody),
      attachers: [_dashboardSagaUtils.attachRouteVendorId]
    });
    yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.getAssetsDynamicRequest)({
      active: true
    }));
    yield (0, _effects.put)((0, _appReducer.displaySnackbar)({
      id: response.status === _core.ASSET_STATUS_APPROVED ? 'vendor.create_asset_approved' : 'vendor.create_asset_success'
    }));
    yield (0, _effects.put)((0, _vendorDashboardAssetReducer.submitAssetResponse)(responseMappers.mapAsset(response)));
    yield (0, _effects.put)((0, _vendorDashboardAssetReducer.closeAssetForm)());
  } catch (e) {
    const {
      status,
      reason
    } = e.httpResponse || {};
    if (status.toString() === _core.STATUS_CONFLICT) {
      const splittedReason = reason.split(' ');
      const assetId = splittedReason[splittedReason.length - 1];
      yield (0, _effects.put)((0, _vendorDashboardAssetReducer.openAssetExistsInAssetCatalogueDialog)(assetId));
    } else {
      yield (0, _effects.call)(mapAssetError, {
        error: e,
        responseAction: _vendorDashboardAssetReducer.submitAssetResponse
      });
    }
  }
}
function getAsset(_ref7) {
  let {
    payload
  } = _ref7;
  return function* () {
    try {
      const {
        assetId,
        assetVersionId,
        withFrame,
        redirect
      } = payload;
      const isAssetCommercial = yield (0, _effects.select)((0, _vendorDashboardAppSelectors.selectIsHandlingCommercialAsset)());
      const apiToCall = isAssetCommercial ? assetModule.getAssetCommercial : assetModule.getAsset;
      const response = yield (0, _reduxSagaUtils.callWithAttachers)({
        endpoint: apiToCall,
        payload: {
          assetId,
          assetVersionId
        },
        attachers: [_dashboardSagaUtils.attachRouteVendorId]
      });
      yield (0, _effects.put)((0, _vendorDashboardFrameReducer.submitFrameResponse)(frameResponseMappers.mapFrame(response.frame)));
      yield (0, _effects.put)((0, _vendorDashboardEquipmentReducer.getEquipmentResponse)(_equipmentMappers.responseMappers.mapAssetEquipment({
        equipment: response.optional_equipment,
        preconfiguredEquipment: response.preconfigured_equipment,
        currentEquipment: {
          standard: response.included_equipment
        },
        withoutStandard: true
      })));
      if (withFrame) {
        yield (0, _effects.put)((0, _vendorDashboardEquipmentReducer.getEquipmentRequest)({
          withoutRetrofit: true,
          withoutStandard: true
        }));
      }
      yield (0, _effects.put)((0, _vendorDashboardAssetReducer.getAssetResponse)(responseMappers.mapAsset(response, !isAssetCommercial)));
      if (typeof redirect === 'function') {
        yield (0, _effects.call)(redirect);
      }
    } catch (e) {
      yield (0, _effects.put)((0, _vendorDashboardAssetReducer.getAssetResponse)(e));
      if (payload && typeof payload.onFailure === 'function') {
        payload.onFailure();
      }
      yield (0, _effects.put)((0, _appReducer.displayError)('errors.could_not_get_asset'));
    }
  }();
}
function hardDeleteAsset(_ref8) {
  let {
    payload
  } = _ref8;
  return function* () {
    try {
      const {
        assetId,
        reload
      } = payload;
      yield (0, _reduxSagaUtils.callWithAttachers)({
        endpoint: assetModule.hardDeleteAsset,
        payload: {
          assetId
        },
        attachers: [_dashboardSagaUtils.attachRouteVendorId, _dashboardSagaUtils.attachIsCommercial]
      });
      yield (0, _effects.put)((0, _vendorDashboardAssetReducer.hardDeleteAssetResponse)());
      if (reload) {
        yield (0, _effects.put)((0, _vendorDashboardAssetListReducer.getAssetsDynamicRequest)({
          active: 'false'
        }));
      }
    } catch (e) {
      yield (0, _effects.put)((0, _vendorDashboardAssetReducer.hardDeleteAssetResponse)(e));
    }
  }();
}
function* prefillEquipmentForm() {
  try {
    yield (0, _effects.all)([(0, _effects.take)((0, _vendorDashboardAssetReducer.getAssetResponse)().type), (0, _effects.take)((0, _vendorDashboardEquipmentReducer.getEquipmentResponse)().type)]);
    const assetData = yield (0, _effects.select)((0, _vendorDashboardAssetSelectors.selectAsset)());
    const equipment = yield (0, _effects.select)((0, _vendorDashboardEquipmentSelectors.selectEquipment)());
    const mappedObject = formMappers.mapAssetAndEquipment({
      asset: assetData,
      equipment
    });
    yield (0, _effects.all)(Object.keys(mappedObject).map(key => (0, _effects.put)((0, _reduxForm.change)(_equipmentConstants.EQUIPMENT_FORM, key, mappedObject[key]))));
    yield (0, _effects.take)((0, _vendorDashboardEquipmentReducer.getEquipmentResponse)().type);
    const {
      optionalEquipment,
      preconfiguredEquipment
    } = assetData;
    yield (0, _effects.put)((0, _vendorDashboardEquipmentReducer.enableEquipmentsByIds)([...optionalEquipment, ...preconfiguredEquipment]));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}
function getAssetAndRedirectVendor(_ref9) {
  let {
    payload = {}
  } = _ref9;
  return function* () {
    try {
      const {
        assetId,
        redirect
      } = payload;
      const allSettled = effects => (0, _effects.all)(effects.map(_ref10 => {
        let {
          effect,
          isAssetCommercial
        } = _ref10;
        return (0, _effects.call)(function* settle() {
          try {
            return {
              result: yield effect,
              isAssetCommercial
            };
          } catch (error) {
            const {
              status
            } = error.httpResponse || {};
            if ((status === null || status === void 0 ? void 0 : status.toString()) !== _core.STATUS_NOT_FOUND) {
              yield (0, _effects.put)((0, _appReducer.displayError)(error));
            }
            return {
              error
            };
          }
        });
      }));
      const assetResponses = yield allSettled([true, false].map(isAssetCommercial => ({
        effect: (0, _reduxSagaUtils.callWithAttachers)({
          endpoint: isAssetCommercial ? assetModule.getAssetCommercial : assetModule.getAsset,
          payload: {
            assetId
          },
          attachers: [_dashboardSagaUtils.attachRouteVendorId]
        }),
        isAssetCommercial
      })));
      const [{
        result: assetSuccessResponse,
        isAssetCommercial
      }] = assetResponses.filter(response => !response.error);
      const constructPathObj = tab => ({
        tab,
        assetId,
        isCommercial: isAssetCommercial
      });
      if (assetSuccessResponse.active) {
        yield (0, _effects.call)(redirect, constructPathObj(_route.VENDOR_DASHBOARD_PATH.tabs.assets));
      } else {
        yield (0, _effects.call)(redirect, constructPathObj(_route.VENDOR_DASHBOARD_PATH.tabs.deletedAssets));
      }
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
    }
  }();
}
function* asset() {
  yield (0, _effects.takeLatest)(_vendorDashboardAssetReducer.submitAssetRequest, formSubmitAsset);
  yield (0, _effects.takeLatest)(_vendorDashboardAssetReducer.editAssetRequest, formEditAsset);
  yield (0, _effects.takeLatest)(_vendorDashboardAssetReducer.getAssetRequest, getAsset);
  yield (0, _effects.takeLatest)(_vendorDashboardAssetReducer.deleteAssetRequest, deleteAsset);
  yield (0, _effects.takeLatest)(_vendorDashboardAssetReducer.restoreAssetWithEditingRequest, restoreAssetWithEditting);
  yield (0, _effects.takeLatest)(_vendorDashboardAssetReducer.hardDeleteAssetRequest, hardDeleteAsset);
  yield (0, _effects.takeLatest)(_vendorDashboardAssetReducer.prefillEquipmentFormRequest, prefillEquipmentForm);
  yield (0, _effects.takeLatest)(_vendorDashboardAssetReducer.closeAssetForm, closeFormAndShowOverflow);
  yield (0, _effects.takeLatest)(_vendorDashboardAssetReducer.openAssetFormRequest, openFormAndHideOverflow);
  yield (0, _effects.takeLatest)(_vendorDashboardAssetReducer.getAssetAndRedirectVendorRequest, getAssetAndRedirectVendor);
}