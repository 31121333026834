"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const getBorder = _ref => {
  let {
    colors
  } = _ref;
  return "1px solid ".concat(colors.custom.sidebar.borderColor);
};
const styles = exports.styles = {
  row: _ref2 => {
    let {
      colors
    } = _ref2;
    return {
      '&:hover': _objectSpread(_objectSpread({}, colors.custom.sidebar.active), {}, {
        cursor: 'pointer'
      }),
      width: '100%',
      '&:nth-child(2)': {
        borderTop: getBorder({
          colors
        })
      },
      borderBottom: getBorder({
        colors
      }),
      '& > div': {
        display: 'flex',
        alignItems: 'center'
      }
    };
  },
  icon: _ref3 => {
    let {
      colors
    } = _ref3;
    return {
      padding: '15px',
      '& path': {
        fill: colors.custom.fontPrimary
      }
    };
  },
  rightContainer: _ref4 => {
    let {
      colors
    } = _ref4;
    return {
      color: colors.solid.gray,
      justifyContent: 'flex-end'
    };
  },
  nextIcon: _ref5 => {
    let {
      colors
    } = _ref5;
    return {
      marginLeft: '10px',
      '& path': {
        fill: colors.custom.fontPrimary
      }
    };
  }
};