import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  span: {
    // break \n into a newline
    whiteSpace: 'pre-line',
  },
  default: ({ fontSizes, typography }) => ({
    fontFamily: typography.main,
    fontSize: fontSizes.small,
    color: 'inherit',
  }),
  fontMain: ({ typography }) => ({
    fontFamily: typography.main,
  }),
  fontTitle: ({ typography }) => ({
    fontFamily: typography.title,
  }),
  tiny: ({ fontSizes }) => ({
    fontSize: fontSizes.tiny,
  }),
  extraSmall: ({ fontSizes }) => ({
    fontSize: fontSizes.extraSmall,
  }),
  small: ({ fontSizes }) => ({
    fontSize: fontSizes.small,
  }),
  medium: ({ fontSizes }) => ({
    fontSize: fontSizes.medium,
  }),
  extraMedium: ({ fontSizes }) => ({
    fontSize: fontSizes.extraMedium,
  }),
  large: ({ fontSizes }) => ({
    fontSize: fontSizes.large,
  }),
  extraLarge: ({ fontSizes }) => ({
    fontSize: fontSizes.extraLarge,
  }),
  huge: ({ fontSizes }) => ({
    fontSize: fontSizes.huge,
  }),
  giant: ({ fontSizes }) => ({
    fontSize: fontSizes.giant,
  }),
  bold: {
    fontWeight: 600,
  },
  italic: {
    fontStyle: 'italic',
  },
  primary: ({ colors: { custom } }) => ({
    color: custom.fontPrimary,
  }),
  leftAlignment: {
    textAlign: 'left',
  },
  rightAlignment: {
    textAlign: 'right',
  },
  centerAlignment: {
    textAlign: 'center',
  },
  justifyAlignment: {
    textAlign: 'justify',
  },
};
