import { ISxStyles } from '../../../../theming/theme.type';

export const styles: ISxStyles = {
  primaryCM: ({ colors }) => ({
    ...colors.custom.primaryCM.solid,
  }),
  primaryPR: ({ colors }) => ({
    ...colors.custom.primaryPR.solid,
  }),
  indicator: {
    height: '74%',
    width: 4,
  },
};
