const ArrowNext: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-navigation/Next/Darkblue" fill="#003755">
        <g id="Icon" transform="translate(6.000000, 2.000000)">
          <path
            d="M0.9999,19.9864 C0.7569,19.9864 0.5129,19.8984 0.3209,19.7194 C-0.0841,19.3444 -0.1091,18.7124 0.2659,18.3064 L7.9729,9.9814 L0.2669,1.6804 C-0.1081,1.2764 -0.0851,0.6434 0.3199,0.2674 C0.7239,-0.1086 1.3549,-0.0856 1.7329,0.3204 L10.0689,9.3004 C10.4249,9.6834 10.4249,10.2764 10.0699,10.6594 L1.7339,19.6664 C1.5369,19.8794 1.2689,19.9864 0.9999,19.9864"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowNext;
