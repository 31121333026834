import { ReactChild, FC } from 'react';
import { Box, SxProps, Theme } from '@mui/material';

import Loader from '../Icons/Loader';
import MAFTypography from '../MAFTypography/MAFTypography';
import MAFIcon from '../MAFIcon/MAFIcon';
import { handleCustomSx } from '../../utils';

import { useStyles } from './MAFSpinner.styles';
import { propTypes } from './MAFSpinner.propTypes';

export type TMAFSpinnerColor = 'grey' | 'white' | 'blue';
export type TMAFSpinnerVariant = 'responsive' | 'large' | 'small';

export interface IMAFSpinnerProps {
  containerClass?: SxProps<Theme>;
  iconClass?: SxProps<Theme>;
  title?: ReactChild;
  color?: TMAFSpinnerColor;
  variant?: TMAFSpinnerVariant;
  isTextAboveSpinner?: boolean;
  isWithContainer?: boolean;
}

const MAFSpinner: FC<IMAFSpinnerProps> = ({
  containerClass,
  iconClass,
  title,
  color = 'grey',
  variant = 'responsive',
  isTextAboveSpinner = true,
  isWithContainer = true,
  ...rest
}) => {
  const styles = useStyles({ variant });
  const containerClasses = [
    isWithContainer && styles.spinnerContainer,
    ...handleCustomSx(containerClass),
  ];

  const iconClasses = [
    styles.icon,
    color === 'grey' && styles.grey,
    color === 'white' && styles.white,
    ...handleCustomSx(iconClass),
  ];

  return (
    <Box sx={containerClasses} {...rest}>
      {isTextAboveSpinner && title && (
        <MAFTypography variant="body1" sx={styles.titleAbove} isWithContainer>
          {title}
        </MAFTypography>
      )}

      <MAFIcon Icon={Loader} sx={iconClasses} />
      {!isTextAboveSpinner && title && (
        <MAFTypography variant="body1" sx={styles.titleBelow} isWithContainer>
          {title}
        </MAFTypography>
      )}
    </Box>
  );
};

MAFSpinner.propTypes = propTypes;

export default MAFSpinner;
