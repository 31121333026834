import { VEHICLE_TYPE_CAR } from '@northstar/core';
import { FILTER_VEHICLE_TYPES } from '@northstar/core-ui/modules/frameConstants';
import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

import { AVAILABLE_FILTERS } from './carListConstants';

export const clearCarListState = createAction('CAR_LIST_CLEAR_STATE');
export const fetchCarsRequest = createAction('CAR_LIST_FETCH_REQUEST');
export const fetchCarsResponse = createAction('CAR_LIST_FETCH_RESPONSE');
export const applyFilters = createAction('CAR_LIST_APPLY_FILTERS');
export const updateLeaseTerms = createAction('CAR_LIST_UPDATE_LEASE_TERMS');
export const changeListDisplay = createAction('CAR_LIST_CHANGE_LIST_DISPLAY');
export const applyQueryFiltersRequest = createAction('CAR_LIST_APPLY_QUERY_FILTERS_REQUEST');
export const createQueryFiltersRequest = createAction('CAR_LIST_CREATE_QUERY_FILTERS_REQUEST');

export const getTermsAndCarsRequest = createAction('CAR_LIST_GET_TERMS_AND_CARS_REQUEST');
export const getTermsAndCarsResponse = createAction('CAR_LIST_GET_TERMS_AND_CARS_RESPONSE');

export const initialState = fromJS({
  filters: AVAILABLE_FILTERS,
  applied_filters: {
    ...AVAILABLE_FILTERS,
    vehicle_types: { label: VEHICLE_TYPE_CAR, value: VEHICLE_TYPE_CAR },
  },
  lastFilter: '',

  leasing: {
    min_price: 0,
    max_price: 18000,
    max_quote: 18000,
  },
  cars: [],
  limit: 10,
  total: 0,
  display: 'grid',

  fetching_cars: false,
  cars_loaded: false,
  error: null,
  fetchingTerms: false,
});

/**
 * Reducer
 */
export default handleActions(
  {
    [clearCarListState](state, { payload = {} }) {
      const stateBase = initialState
        .set('filters', state.get('filters'))
        .set('applied_filters', state.get('applied_filters'))
        .set('leasing', state.get('leasing'));

      if (payload.fullReset) {
        return initialState;
      }
      return stateBase;
    },
    [updateLeaseTerms](state, { payload }) {
      return state.setIn(['leasing', payload.key], payload.value);
    },
    [fetchCarsRequest](state) {
      return state.set('cars_loaded', false).set('fetching_cars', true);
    },
    [changeListDisplay](state, { payload }) {
      return state.set('display', payload);
    },
    [fetchCarsResponse](state, { payload, error }) {
      const { cars, filters } = payload;
      return !error
        ? state
            .set('cars', fromJS(cars))
            .set('total', cars.length)
            .set('filters', fromJS(filters))
            .set('fetching_cars', false)
            .set('cars_loaded', true)
        : state
            .set('fetching_cars', false)
            .set('cars_loaded', false)
            .set('error', payload.toString());
    },
    [applyFilters](state, { payload }) {
      if (payload.filter === FILTER_VEHICLE_TYPES) {
        return state
          .set(
            'applied_filters',
            fromJS({
              ...AVAILABLE_FILTERS,
              [FILTER_VEHICLE_TYPES]: payload.value,
            }),
          )
          .set('lastFilter', payload.filter);
      }
      return state
        .setIn(['applied_filters', payload.filter], fromJS(payload.value))
        .set('lastFilter', payload.filter);
    },
    [getTermsAndCarsRequest](state) {
      return state.set('fetchingTerms', true).set('fetching_cars', true).set('cars_loaded', false);
    },
    [getTermsAndCarsResponse](state) {
      return state.set('fetchingTerms', false);
    },
  },
  initialState,
);
