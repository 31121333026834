import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  adornment: {
    marginBottom: '1px',
  },
  adornmentDisabled: ({ colors }) => ({
    color: colors.alpha(colors.solid.black, 0.18),
  }),
};
