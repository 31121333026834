import { ISxStyles } from '../../theming/theme.type';

export const styles: ISxStyles = {
  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  radioButtonsContainer: {
    '&.MuiFormGroup-root': {
      flexDirection: 'row',
      fontSize: '12px',
    },
  },
};
