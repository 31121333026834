import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  action: PropTypes.node,
  authoHideDurationMs: PropTypes.number,
  children: PropTypes.node,
  horizontal: PropTypes.oneOf(['center', 'left', 'right']),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]).isRequired,
  type: PropTypes.oneOf(['ERROR', 'SUCCESS']),
} as any;
