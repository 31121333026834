"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authenticate = authenticate;
exports.authenticateInternal = authenticateInternal;
exports.changeServiceCode = exports.badCredentials = void 0;
exports.checkOtherIdentities = checkOtherIdentities;
exports.checkSession = checkSession;
exports.clearReduxState = clearReduxState;
exports.default = authentication;
exports.handleErrors = handleErrors;
exports.handleUnauthenticated = handleUnauthenticated;
exports.handleUnauthorized = handleUnauthorized;
exports.performLogonService = void 0;
exports.selectIdentity = selectIdentity;
exports.setChangeServiceCodeRules = exports.setAuthSingletonFromLocalStorage = void 0;
exports.setSecrets = setSecrets;
var _core = require("@northstar/core");
var _effects = require("redux-saga/effects");
var _Constants = require("@northstar/core/utils/Constants");
var _appReducer = require("../app/appReducer");
var _userProfileReducer = require("../userProfile/userProfileReducer");
var _processVariancesReducer = require("../processVariances/processVariancesReducer");
var _authSingleton = _interopRequireDefault(require("../../utils/auth-singleton"));
var _localStorageUtils = require("../../utils/localStorage-utils");
var _reduxSagaUtils = require("../../utils/redux-saga-utils");
var Api = _interopRequireWildcard(require("./authApi"));
var _authMapper = require("./authMapper");
var Actions = _interopRequireWildcard(require("./authReducer"));
var Selectors = _interopRequireWildcard(require("./authSelectors"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const badCredentials = exports.badCredentials = 'auth.provided_crediantials_are_not_correct_please_try_again';
function handleErrors(_ref) {
  let {
    payload,
    error
  } = _ref;
  return function* () {
    try {
      if (error) {
        const {
          httpResponse
        } = payload;
        if (httpResponse) {
          const {
            status
          } = httpResponse;
          switch (status.toString()) {
            case _core.STATUS_UNAUTHORIZED:
              yield (0, _effects.call)(handleUnauthenticated, 'errors.unauthenticated_or_session_expired');
              yield (0, _effects.put)(Actions.stopSagas());
              break;
            case _core.STATUS_FORBIDDEN:
              yield (0, _effects.call)(handleUnauthorized);
              yield (0, _effects.put)(Actions.stopSagas());
              break;
            default:
              break;
          }
        }
      }
    } catch (e) {
      // dont do shit
    } finally {
      if (error && payload !== null && payload !== void 0 && payload.httpResponse) {
        const status = payload.httpResponse.status.toString();
        if (status === _core.STATUS_UNAUTHORIZED || status === _core.STATUS_FORBIDDEN) {
          yield (0, _effects.put)(Actions.runSagas());
        }
      }
    }
  }();
}
const performLogonService = _ref2 => {
  let {
    username,
    password
  } = _ref2;
  return new Promise((resolve, reject) => {
    if (!window.performLogonServiceCode_v2) {
      reject(new Error('The authentication server refused your connection. Please try again later.'));
      return;
    }
    window.performLogonServiceCode_v2(username, password,
    // Successful login response
    response => {
      resolve({
        LogonPackage: response.LogonPackage,
        UserID: response.UserID
      });
    },
    // Fail login response
    error => {
      reject(error);
    });
  });
};
exports.performLogonService = performLogonService;
function selectIdentity(_ref3) {
  let {
    payload
  } = _ref3;
  return function* () {
    try {
      const user = yield (0, _effects.select)(Selectors.selectUser());
      const identities = user.identities.length ? user.identities : payload.identities;
      const newIdentity = identities.find(identity => identity.id === payload.id);
      if (!newIdentity) {
        yield (0, _effects.call)(handleUnauthorized);
        return;
      }

      // Change identity id only when user really left
      window.addEventListener('unload', () => {
        setSecrets(_authSingleton.default.getAuthSecret(), payload.id);
      });
      const redirectPath = yield (0, _effects.select)(Selectors.selectRedirectPath());
      if (redirectPath) {
        yield (0, _effects.put)(Actions.redirectToPath({
          redirectPath,
          refresh: true
        }));
      } else {
        yield (0, _effects.put)(Actions.redirectToHome({
          role: newIdentity.role.base_type,
          vendor: newIdentity.vendor,
          refresh: true
        }));
      }

      // only matters when user decides to stay
      yield (0, _effects.put)(Actions.selectIdentityResponse(user));
    } catch (e) {
      yield (0, _effects.put)(Actions.selectIdentityResponse(e));
    }
  }();
}
function authenticateInternal(_ref4) {
  let {
    payload
  } = _ref4;
  return function* () {
    clearLocalSession();
    const {
      username,
      password
    } = payload;
    try {
      const rememberUsername = yield (0, _effects.select)(Selectors.selectRememberUsername());
      if (rememberUsername) {
        (0, _localStorageUtils.setValueByKey)(_localStorageUtils.KEYS.USERNAME, username);
      } else {
        (0, _localStorageUtils.setValueByKey)(_localStorageUtils.KEYS.USERNAME, '');
        yield (0, _effects.put)(Actions.toggleRememberUsername(false));
      }
      if (!username || !password) {
        throw new Error(badCredentials);
      }
      const loginResponse = yield (0, _effects.call)(Api.loginInternal, {
        username,
        password
      });

      // TODO: use issuedAt / expiresAt ?
      const {
        username: receivedUsername,
        AuthenticationSecret,
        identities,
        isInternal
      } = _authMapper.responseMappers.mapLoginResponse(loginResponse);
      let identityId = (0, _localStorageUtils.getValueByKey)(_localStorageUtils.KEYS.IDENTITY_ID);
      if (!identities.some(_ref5 => {
        let {
          id
        } = _ref5;
        return id === identityId;
      })) {
        [{
          id: identityId
        }] = identities;
      }
      setSecrets(AuthenticationSecret, identityId);
      const currentIdentity = identities.find(_ref6 => {
        let {
          id
        } = _ref6;
        return id === identityId;
      });
      yield (0, _effects.call)(clearReduxState);
      const redirectPath = yield (0, _effects.select)(Selectors.selectRedirectPath());
      if (redirectPath) {
        yield (0, _effects.put)(Actions.redirectToPath({
          redirectPath
        }));
        yield (0, _effects.put)(Actions.setRedirectPath(false));
      } else {
        yield (0, _effects.put)(Actions.redirectToHome({
          role: currentIdentity.role.base_type
        }));
      }
      const {
        process_configuration: {
          process_variances: processVariances
        }
      } = currentIdentity.vendor;
      yield (0, _effects.put)((0, _processVariancesReducer.setProcessVariances)({
        vendorProcessVariances: processVariances
      }));
      yield (0, _effects.put)(Actions.loginInternalResponse({
        username: receivedUsername,
        currentIdentity,
        identities,
        isInternal
      }));
    } catch (e) {
      if (e.httpResponse) {
        yield (0, _effects.put)(Actions.loginInternalResponse(new Error(e.httpResponse.reason)));
        return;
      }
      yield (0, _effects.put)(Actions.loginInternalResponse(e));
    }
  }();
}
function onboardUser(_ref7) {
  let {
    payload
  } = _ref7;
  return function* () {
    try {
      const {
        redirectUrl,
        params
      } = payload;
      yield (0, _effects.call)(Api.finishHandshake, {
        redirectUrl
      });
      yield (0, _effects.call)(Api.verifyUser, params);
      yield (0, _effects.call)(login);
      yield (0, _effects.put)(Actions.onboardUserResponse());
    } catch (e) {
      yield (0, _effects.put)(Actions.onboardUserResponse(e));
    }
  }();
}
function login() {
  let {
    withRedirect = true
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function* () {
    try {
      const loginResponse = yield (0, _effects.call)(Api.login);
      const {
        username: receivedUsername,
        identities,
        isInternal
      } = _authMapper.responseMappers.mapLoginResponse(loginResponse);
      let identityId = (0, _localStorageUtils.getValueByKey)(_localStorageUtils.KEYS.IDENTITY_ID);
      if (!identities.some(_ref8 => {
        let {
          id
        } = _ref8;
        return id === identityId;
      })) {
        [{
          id: identityId
        }] = identities;
      }
      setSecrets(null, identityId);
      const currentIdentity = identities.find(_ref9 => {
        let {
          id
        } = _ref9;
        return id === identityId;
      });
      yield (0, _effects.call)(clearReduxState);
      yield (0, _effects.put)((0, _userProfileReducer.getCurrentUserRequest)());
      if (withRedirect) {
        const redirectPath = yield (0, _effects.select)(Selectors.selectRedirectPath());
        if (redirectPath) {
          yield (0, _effects.put)(Actions.redirectToPath({
            redirectPath
          }));
          yield (0, _effects.put)(Actions.setRedirectPath(false));
        } else {
          yield (0, _effects.put)(Actions.redirectToHome({
            role: currentIdentity.role.base_type,
            vendor: currentIdentity.vendor
          }));
        }
      }
      yield (0, _effects.put)((0, _userProfileReducer.getCurrentUserThemeRequest)());
      yield (0, _effects.put)((0, _processVariancesReducer.setProcessVariances)(_authMapper.responseMappers.mapProcessVariances(currentIdentity.vendor)));
      yield (0, _effects.put)(Actions.loginResponse({
        username: receivedUsername,
        currentIdentity,
        identities,
        isInternal
      }));
      yield (0, _effects.put)(Actions.setActiveTerms());
    } catch (e) {
      yield (0, _effects.put)(Actions.loginResponse(e));
    }
  }();
}
function authenticate(_ref10) {
  let {
    payload
  } = _ref10;
  return function* () {
    clearLocalSession();
    try {
      const {
        redirectUrl
      } = payload;
      yield (0, _effects.call)(Api.finishHandshake, {
        redirectUrl
      });
      yield (0, _effects.call)(login);
    } catch (e) {
      if (e.httpResponse) {
        yield (0, _effects.put)(Actions.loginResponse(new Error(e.httpResponse.errorCode || e.httpResponse.reason)));
        return;
      }
      yield (0, _effects.put)(Actions.loginResponse(e));
    }
  }();
}
const changeServiceCode = _ref11 => {
  let {
    userId,
    oldPassword,
    newPassword
  } = _ref11;
  return new Promise((resolve, reject) => window.changeServiceCode(userId, oldPassword, newPassword, securityPackage => {
    resolve(securityPackage);
  }, error => {
    reject(error);
  }));
};
exports.changeServiceCode = changeServiceCode;
const setChangeServiceCodeRules = _ref12 => {
  let {
    username
  } = _ref12;
  return new Promise((resolve, reject) => window.setChangeServiceCodeRules(username, '', data => {
    if (data.httpResponse) {
      reject(data);
    }
    resolve(data);
  }, error => {
    reject(error);
  }));
};
exports.setChangeServiceCodeRules = setChangeServiceCodeRules;
function checkSession(_ref13) {
  let {
    payload
  } = _ref13;
  return function* () {
    try {
      const isAuthenticated = yield (0, _effects.select)(Selectors.selectIsAuthenticated());

      // if for some reason, authentication details were not retrieved by app this will ensure that they are.
      if (process.env.REACT_APP_CURR_PROJECT === _Constants.EXTERNAL_PROJECT_NAME) {
        yield (0, _effects.call)(login, {
          withRedirect: false
        });
        yield (0, _effects.delay)(1000);
        return;
      }
      const authSecret = (0, _localStorageUtils.getValueByKey)(_localStorageUtils.KEYS.AUTH_SECRET);
      let identityId = (0, _localStorageUtils.getValueByKey)(_localStorageUtils.KEYS.IDENTITY_ID);
      // If he's not authorized and has no cookies - he hasn't logged in
      if (!authSecret || !identityId) {
        yield (0, _effects.call)(handleUnauthenticated, 'errors.unauthenticated');
        yield (0, _effects.delay)(1000);
        return;
      }

      // Set secret so the session call has the correct Authorization header
      setSecrets(authSecret, identityId);
      let apiResponse;
      try {
        // If this pases - session is still valid
        apiResponse = yield (0, _effects.call)(Api.session);
      } catch (e) {
        setSecrets(null, null);
        yield (0, _effects.put)(Actions.sessionResponse(e));
        return;
      }
      const {
        username,
        identities,
        isInternal
      } = _authMapper.responseMappers.mapLoginResponse(apiResponse);
      if (!identities.some(_ref14 => {
        let {
          id
        } = _ref14;
        return id === identityId;
      })) {
        [{
          id: identityId
        }] = identities;
        setSecrets(authSecret, identityId);
      }
      const currentIdentity = identities.find(_ref15 => {
        let {
          id
        } = _ref15;
        return id === identityId;
      });

      // Validate authorization
      const isValid = payload.validationRules.find(rule => rule({
        roleBaseType: currentIdentity.role.base_type,
        roleSubType: _core.USER_ROLE_SUB_TYPE_DEFAULT,
        vendorType: currentIdentity.vendor.type
      }));
      if (!isValid) {
        if (isInternal) {
          const hasValidOtherIdentity = yield (0, _effects.call)(checkOtherIdentities, {
            validationRules: payload.validationRules,
            identities
          });
          if (hasValidOtherIdentity) return;
        }
        yield (0, _effects.call)(handleUnauthorized);
      }
      if (isAuthenticated) {
        yield (0, _effects.call)(handleUnauthorized);
        return;
      }
      const user = {
        username,
        currentIdentity,
        identities,
        isInternal
      };
      const processVariances = _authMapper.responseMappers.mapProcessVariances(currentIdentity.vendor);
      // If there are no rules to validate against, authentication is enough
      if (!payload || !payload.validationRules) {
        yield (0, _effects.put)((0, _processVariancesReducer.setProcessVariances)(processVariances));
        yield (0, _effects.put)(Actions.sessionResponse(user));
        yield (0, _effects.put)(Actions.setActiveTerms());
        return;
      }
      if (!isInternal) {
        yield (0, _effects.put)((0, _userProfileReducer.getCurrentUserRequest)());
        yield (0, _effects.put)((0, _userProfileReducer.getCurrentUserThemeRequest)());
      }
      yield (0, _effects.put)((0, _processVariancesReducer.setProcessVariances)(processVariances));
      yield (0, _effects.put)(Actions.sessionResponse(user));
      yield (0, _effects.put)(Actions.setActiveTerms());
    } catch (e) {
      yield (0, _effects.call)(handleUnauthenticated, e);
    }
  }();
}
function checkOtherIdentities(_ref16) {
  let {
    validationRules,
    identities
  } = _ref16;
  return function* () {
    const validOtherIdentity = identities.find(identity => validationRules.find(rule => rule({
      roleBaseType: identity.role.base_type,
      roleSubType: _core.USER_ROLE_SUB_TYPE_DEFAULT
    })));
    if (!validOtherIdentity) {
      return false;
    } else {
      yield (0, _effects.put)(Actions.setRedirectPath({
        path: window.location.pathname
      }));
      yield (0, _effects.put)(Actions.selectIdentityRequest({
        id: validOtherIdentity.id,
        identities
      }));
      return true;
    }
  }();
}
function* handleUnauthenticated(error) {
  yield (0, _effects.call)(clearStateAndRedirect);
  let errorMessage;
  if (error && error.httpResponse) {
    errorMessage = error.httpResponse.reason;
  } else if (error || error === '') {
    errorMessage = error;
  } else {
    errorMessage = 'errors.credit_decision_request_failed';
  }
  yield (0, _effects.put)(Actions.setRedirectPath({
    path: window.location.pathname
  }));
  yield (0, _effects.put)(Actions.sessionUnauthenticatedResponse(new Error(errorMessage)));
}
function* handleUnauthorized() {
  yield (0, _effects.put)(Actions.redirectToForbidden());
  yield (0, _effects.put)(Actions.sessionUnauthorizedResponse());
}
function* clearStateAndRedirect() {
  clearLocalSession();
  yield (0, _effects.call)(clearReduxState, {
    fullAuthClear: true
  });
  yield (0, _effects.put)(Actions.redirectToLogin());
}
function setSecrets(authSecret, identityId) {
  _authSingleton.default.setSecrets(authSecret, identityId);
  (0, _localStorageUtils.setValueByKey)(_localStorageUtils.KEYS.AUTH_SECRET, authSecret);
  (0, _localStorageUtils.setValueByKey)(_localStorageUtils.KEYS.IDENTITY_ID, identityId);
}
const setAuthSingletonFromLocalStorage = () => {
  _authSingleton.default.setSecrets((0, _localStorageUtils.getValueByKey)(_localStorageUtils.KEYS.AUTH_SECRET), (0, _localStorageUtils.getValueByKey)(_localStorageUtils.KEYS.IDENTITY_ID));
};
exports.setAuthSingletonFromLocalStorage = setAuthSingletonFromLocalStorage;
function clearLocalSession() {
  _authSingleton.default.clearSecrets();
  (0, _localStorageUtils.removeValueByKey)(_localStorageUtils.KEYS.AUTH_SECRET);
  (0, _localStorageUtils.removeValueByKey)(_localStorageUtils.KEYS.IDENTITY_ID);
}
function clearReduxState() {
  let {
    fullAuthClear = false
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function* () {
    if (fullAuthClear) {
      yield (0, _effects.put)(Actions.clearAuthState());
    }
    yield (0, _effects.put)((0, _appReducer.clearAppState)());
    yield (0, _effects.put)(Actions.clearErrors());
    yield (0, _effects.put)(Actions.resetAppState());
  }();
}
function* logout() {
  try {
    yield (0, _effects.call)(clearStateAndRedirect);
    yield (0, _effects.call)(Api.logout);
  } catch (error) {
    yield (0, _effects.put)(Actions.sessionResponse(error));
  }
}
function selfOnboard(_ref17) {
  let {
    payload
  } = _ref17;
  return function* () {
    try {
      const {
        username
      } = payload;
      yield (0, _effects.call)(Api.selfOnboard, {
        username
      });
      yield (0, _effects.put)(Actions.selfOnboardResponse());
    } catch (e) {
      yield (0, _effects.put)(Actions.selfOnboardResponse(e));
    }
  }();
}
function* authentication() {
  yield (0, _effects.all)([(0, _effects.takeEvery)('*', handleErrors), (0, _effects.takeLatest)(Actions.selectIdentityRequest, selectIdentity), (0, _effects.takeLatest)(Actions.loginInternalRequest, authenticateInternal), (0, _effects.takeLatest)(Actions.loginRequest, authenticate), (0, _reduxSagaUtils.takeLatestAndWaitForPreviousToFinish)(Actions.sessionRequest, checkSession), (0, _effects.takeLatest)(Actions.logoutRequest, logout), (0, _effects.takeLatest)(Actions.onboardUserRequest, onboardUser), (0, _effects.takeLatest)(Actions.selfOnboardRequest, selfOnboard)]);
}