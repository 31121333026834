"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTranslationByKey = void 0;
var _i18n = require("../i18n");
const getTranslationByKey = _ref => {
  let {
    key,
    locale
  } = _ref;
  return _i18n.translationMessages[locale][key];
};
exports.getTranslationByKey = getTranslationByKey;