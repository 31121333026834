const Search: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Desktop-HD"
        transform="translate(-1230.000000, -316.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g id="search_darkblue" transform="translate(1230.000000, 316.000000)">
          <path
            d="M1,7.75 C1,4.028 4.028,1 7.75,1 C11.472,1 14.5,4.028 14.5,7.75 C14.5,11.472 11.472,14.5 7.75,14.5 C4.028,14.5 1,11.472 1,7.75 M19.847,17.307 L14.334,11.819 C15.069,10.635 15.5,9.243 15.5,7.75 C15.5,3.477 12.024,0 7.75,0 C3.477,0 0,3.477 0,7.75 C0,12.023 3.477,15.5 7.75,15.5 C9.23,15.5 10.609,15.076 11.787,14.353 L17.294,19.836 C17.489,20.029 17.803,20.03 17.998,19.838 L19.845,18.017 C20.043,17.822 20.044,17.503 19.847,17.307"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Search;
