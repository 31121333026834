const VeryHappy: React.FC<{}> = () => (
  <svg height="48px" width="48px" color="#003755" viewBox="-2 -2 24 24">
    <path
      d="M10,0 C15.523,0 20,4.477 20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 Z M10,1 C5.037,1 1,5.037 1,10 C1,14.963 5.037,19 10,19 C14.963,19 19,14.963 19,10 C19,5.037 14.963,1 10,1 Z M14.7870612,11.7592494 C14.9989219,11.8402892 15.1049732,12.077732 15.0239333,12.2895927 C14.2317831,14.3604943 12.52741,15.4088618 9.99836578,15.4088618 C7.46929403,15.4088618 5.7651384,14.3604616 4.9735021,12.2895064 C4.89250997,12.0776274 4.9986147,11.8402085 5.21049364,11.7592164 C5.42237259,11.6782243 5.65979149,11.784329 5.74078362,11.9962079 C6.40441877,13.7323065 7.79441175,14.5874332 9.99836578,14.5874332 C12.2023473,14.5874332 13.5926141,13.7322738 14.2567179,11.9961216 C14.3377577,11.7842609 14.5752005,11.6782096 14.7870612,11.7592494 Z M6,7 C6.55228475,7 7,7.44771525 7,8 C7,8.55228475 6.55228475,9 6,9 C5.44771525,9 5,8.55228475 5,8 C5,7.44771525 5.44771525,7 6,7 Z M14,7 C14.5522847,7 15,7.44771525 15,8 C15,8.55228475 14.5522847,9 14,9 C13.4477153,9 13,8.55228475 13,8 C13,7.44771525 13.4477153,7 14,7 Z"
      id="Combined-Shape"
    ></path>
  </svg>
);

export default VeryHappy;
