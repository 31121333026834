import { Box } from '@mui/material';
import { ReactChild } from 'react';

import { styles } from './quoteDetails.styles';
import QuoteDetail, { IQuoteDetailProps } from './QuoteDetail/QuoteDetail';

export interface IQuoteDetailsProps {
  details: IQuoteDetailProps[];
  footer: ReactChild;
}

const QuoteDetails: React.FC<IQuoteDetailsProps> = ({ details, footer }) => (
  <Box sx={styles.container}>
    {details.map((detail, index) => (
      <QuoteDetail key={index} {...detail} />
    ))}
    {footer && (
      <Box sx={styles.footerContainer}>
        <Box sx={styles.lineDashed} />
        <Box sx={styles.footer}>{footer}</Box>
      </Box>
    )}
  </Box>
);

export default QuoteDetails;
