const Back: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-communication/Arrow-illustrative-left/Darkblue" fill="#003755">
        <g
          id="Icon"
          transform="translate(11.972571, 12.040068) rotate(90.000000) translate(-11.972571, -12.040068) translate(5.472571, 2.040068)"
        >
          <path
            d="M12.8266786,13.2532079 C12.5967746,13.029089 12.2227338,13.0279278 11.9904474,13.2532079 L7.09099031,18.032863 L7.09099031,0.575973988 C7.09099031,0.258956047 6.82534992,0 6.49895769,0 C6.17375667,0 5.90811628,0.258956047 5.90811628,0.575973988 L5.90930749,18.032863 L1.0086592,13.2532079 C0.77756397,13.0279278 0.40352324,13.0279278 0.172428012,13.2532079 C-0.0574760039,13.4784881 -0.0574760039,13.8431168 0.172428012,14.0683969 L6.0808421,19.829298 C6.13563787,19.882715 6.20115456,19.9256808 6.27262731,19.9547117 C6.34410006,19.9837427 6.42152888,20 6.5001489,20 C6.57876893,20 6.65619774,19.9837427 6.72767049,19.9547117 C6.79795203,19.926842 6.85989508,19.8838762 6.91349964,19.833943 C6.91469086,19.8327817 6.91707328,19.8327817 6.91826449,19.8304593 L12.8266786,14.0683969 C13.0577738,13.8431168 13.0577738,13.4784881 12.8266786,13.2532079"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Back;
