import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  component: PropTypes.node,
  forwardedRef: PropTypes.shape({
    current: (props, propName) => {
      if (props[propName] == null) {
        return null;
      } else if (typeof props[propName] !== 'object' || props[propName].nodeType !== 1) {
        return new Error(`Expected prop '${propName}'  to be of type Element`);
      }
      return null;
    },
  }),
  isLoading: PropTypes.bool,
  loadPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  shouldUseRef: PropTypes.bool,
  totalPages: PropTypes.number,
  upcomingPage: PropTypes.number,
} as any;
