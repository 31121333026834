import { Theme } from '@mui/material/styles';

import { ISxStyles } from '../../theming/theme.type';

const buttonBaseHeight = 54;

const getButtonBase = ({
  typography,
  colors,
  fontSizes,
}: Pick<Theme, 'typography' | 'colors' | 'fontSizes'>) => ({
  ...typography.baseFont,
  ...colors.custom.buttons.base,
  height: buttonBaseHeight,
  textTransform: 'none',
  minWidth: 144,
  padding: '0px 32px',
  fontSize: fontSizes.small,
  opacity: 'unset',
});

export const styles: ISxStyles = {
  default: ({ colors }) => ({
    '&.MuiButton-root': {
      color: colors.custom.fontPrimary,
      height: 34,
      width: '100%',
      justifyContent: 'center',
      textTransform: 'none',
    },
  }),
  primaryPRHighlight: ({ typography, colors, fontSizes }) => ({
    '&.MuiButton-root': {
      ...getButtonBase({ typography, colors, fontSizes }),
      ...colors.custom.primaryPR.highlight,
    },
  }),
  primaryCMHighlight: ({ typography, colors, fontSizes }) => ({
    '&.MuiButton-root': {
      ...getButtonBase({ typography, colors, fontSizes }),
      ...colors.custom.primaryCM.highlight,
    },
  }),
  primaryPR: ({ typography, colors, fontSizes }) => ({
    '&.MuiButton-root': {
      ...getButtonBase({ typography, colors, fontSizes }),
      ...colors.custom.primaryPR.action,
    },
  }),
  primaryCM: ({ typography, colors, fontSizes }) => ({
    '&.MuiButton-root': {
      ...getButtonBase({ typography, colors, fontSizes }),
      ...colors.custom.primaryCM.action,
    },
  }),
  secondary: ({ typography, colors, fontSizes }) => ({
    '&.MuiButton-root': {
      ...getButtonBase({ typography, colors, fontSizes }),
      ...colors.custom.buttons.secondary,
    },
  }),
  blue: ({ typography, colors, fontSizes }) => ({
    '&.MuiButton-root': {
      ...getButtonBase({ typography, colors, fontSizes }),
      color: colors.solid.white,
      backgroundColor: colors.solid.blue,
      '&:hover': {
        backgroundColor: colors.solid.blue,
      },
    },
  }),
  darkGray: ({ typography, colors, fontSizes }) => ({
    '&.MuiButton-root': {
      ...getButtonBase({ typography, colors, fontSizes }),
      color: colors.solid.white,
      backgroundColor: colors.solid.darkGray,
      '&:hover': {
        backgroundColor: colors.solid.darkGray,
      },
    },
  }),
  transparentDarkGray: ({ typography, colors, fontSizes }) => ({
    '&.MuiButton-root': {
      ...getButtonBase({ typography, colors, fontSizes }),
      color: colors.solid.white,
      backgroundColor: colors.solid.darkGray,
      opacity: 0.5,
      '&:hover': {
        backgroundColor: colors.solid.darkGray,
      },
    },
  }),
  green: ({ typography, colors, fontSizes }) => ({
    '&.MuiButton-root': {
      ...getButtonBase({ typography, colors, fontSizes }),
      backgroundColor: colors.solid.cadedGray,
      color: colors.solid.white,
      background: colors.gradients.green,
    },
  }),
  red: ({ typography, colors, fontSizes }) => ({
    '&.MuiButton-root': {
      ...getButtonBase({ typography, colors, fontSizes }),
      backgroundColor: colors.solid.cadedGray,
      color: colors.solid.white,
      background: colors.gradients.red,
    },
  }),
  gray: ({ typography, colors, fontSizes }) => ({
    '&.MuiButton-root': {
      ...getButtonBase({ typography, colors, fontSizes }),
      ...colors.custom.buttons.gray,
    },
  }),
  transparentGray: ({ typography, colors, fontSizes }) => ({
    '&.MuiButton-root': {
      ...getButtonBase({ typography, colors, fontSizes }),
      ...colors.custom.buttons.transparentGray,
    },
  }),
  blank: ({ typography, colors, fontSizes }) => ({
    '&.MuiButton-root': {
      ...getButtonBase({ typography, colors, fontSizes }),
      ...colors.custom.buttons.blank,
      boxShadow: 'none',
      background: 'none',
    },
  }),
  disabled: ({ colors }) => ({
    '&.MuiButton-root.Mui-disabled': {
      ...colors.custom.disabled,
      border: 'none',
    },
  }),
  wide: {
    '&.MuiButton-root': {
      width: '100%',
    },
  },
  tiny: ({ spacing, fontSizes: { tiny } }) => ({
    '&.MuiButton-root': {
      padding: `${spacing(0.5)} ${spacing(1.5)}`,
      height: 'auto',
      fontSize: tiny,
      minWidth: spacing(8),
    },
  }),
  small: ({ typography }) => ({
    '&.MuiButton-root': {
      ...typography.baseFont,
      height: 36,
    },
  }),
  rounded: {
    '&.MuiButton-root': {
      borderRadius: `${buttonBaseHeight / 2}px`,
    },
  },
  alignLeft: {
    '&.MuiButton-root': {
      justifyContent: 'flex-start',
    },
  },
  link: ({ typography: { main }, colors }) => ({
    '&.MuiButton-root': {
      width: 'auto',
      color: colors.custom.fontPrimary,
      fontFamily: main,
      padding: '5px 10px',
      minWidth: 'auto',
      textTransform: 'none',
      '&:hover': {
        background: 'inherit',
      },
    },
  }),
  iconSmall: {
    '& .MuiButton-startIcon, .MuiButton-endIcon': {
      '& g': {
        fill: 'currentColor',
      },
      width: 16,
    },
  },
  icon: {
    '& .MuiButton-startIcon, .MuiButton-endIcon': {
      '& g': {
        fill: 'currentColor',
      },
      width: 20,
    },
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  containerWide: {
    display: 'block',
    width: '100%',
  },
  sticky: ({ colors }) => ({
    position: 'sticky',
    bottom: -2,
    background: colors.custom.buttons.sticky.background,
  }),
  buttonAccessibility: ({ colors }) => ({
    '&:focus-visible': {
      border: `2px ${colors.solid.black} solid !important`,
    },
  }),
};
