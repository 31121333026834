"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ContactDetails", {
  enumerable: true,
  get: function () {
    return _ContactDetails.default;
  }
});
Object.defineProperty(exports, "IdentityToggle", {
  enumerable: true,
  get: function () {
    return _IdentityToggle.default;
  }
});
var _IdentityToggle = _interopRequireDefault(require("./IdentityToggle/IdentityToggle"));
var _ContactDetails = _interopRequireDefault(require("./ContactDetails/ContactDetails"));