"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectLocale = exports.selectLanguageSate = void 0;
var _reselect = require("reselect");
const selectLanguageSate = state => state.language;
exports.selectLanguageSate = selectLanguageSate;
const selectLocale = () => (0, _reselect.createSelector)(selectLanguageSate, languageState => languageState.get('locale'));
exports.selectLocale = selectLocale;