"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  root: {
    '& .MuiDialog-paper': {
      maxWidth: 1000,
      width: 'auto',
      overflowX: 'hidden',
      minWidth: 500
    }
  },
  actionRoot: _ref => {
    let {
      colors
    } = _ref;
    return {
      padding: '30px 0',
      margin: 0,
      borderTop: "1px solid ".concat(colors.alpha(colors.custom.content.background, 0.28)),
      flexDirection: 'column'
    };
  },
  actionAction: {
    width: '100%',
    textAlign: 'center'
  },
  title: _ref2 => {
    let {
      typography: {
        main
      },
      fontSizes: {
        small
      }
    } = _ref2;
    return {
      fontFamily: main,
      fontSize: small,
      fontWeight: 600,
      letterSpacing: '0.45px',
      lineHeight: '28px',
      paddingLeft: '50px',
      textAlign: 'left',
      marginTop: '-30px'
    };
  },
  contentRoot: {
    '&.MuiDialogContent-root': {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  button: {
    marginBottom: '20px',
    width: 500
  },
  sharedButton: {
    margin: '0 15px',
    marginBottom: '20px'
  },
  spinner: {
    paddingTop: '50px'
  },
  emptyLabel: {
    margin: '15px 0'
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '30px 0px -30px'
  },
  helperText: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontStyle: 'italic',
    marginTop: 2
  },
  section: _ref3 => {
    let {
      colors
    } = _ref3;
    return {
      padding: 4,
      backgroundColor: colors.custom.equipmentBox.background
    };
  }
};