"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "VendorHeader", {
  enumerable: true,
  get: function () {
    return _VendorHeader.default;
  }
});
Object.defineProperty(exports, "VendorSubSection", {
  enumerable: true,
  get: function () {
    return _VendorSubSection.default;
  }
});
var _VendorHeader = _interopRequireDefault(require("./VendorHeader/VendorHeader"));
var _VendorSubSection = _interopRequireDefault(require("./VendorSubSection/VendorSubSection"));