"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reduxErrorMiddleware = exports.isPristineFormImmutable = exports.isPristineForm = exports.isDirtyFormImmutable = exports.isDirtyForm = void 0;
var _reduxForm = require("redux-form");
var _immutable = require("redux-form/immutable");
var _appReducer = require("../modules/app/appReducer");
// This is needed to make sure no additional parameters are passed to redux-form isDirty/isPristine functions, because all of them will be treated as fields and this is not desired functionality
const isDirtyForm = formName => state => (0, _reduxForm.isDirty)(formName)(state);
exports.isDirtyForm = isDirtyForm;
const isPristineForm = formName => state => (0, _reduxForm.isPristine)(formName)(state);
exports.isPristineForm = isPristineForm;
const isDirtyFormImmutable = formName => state => (0, _immutable.isDirty)(formName)(state);
exports.isDirtyFormImmutable = isDirtyFormImmutable;
const isPristineFormImmutable = formName => state => (0, _immutable.isPristine)(formName)(state);
exports.isPristineFormImmutable = isPristineFormImmutable;
const reduxErrorMiddleware = _ref => {
  let {
    dispatch
  } = _ref;
  return next => action => {
    if (action.error && action.error.stack && !action.payload.httpResponse) {
      const {
        payload: error
      } = action;
      dispatch((0, _appReducer.logError)({
        error: {
          stack: error.stack,
          message: error.message
        }
      }));
    }
    next(action);
  };
};
exports.reduxErrorMiddleware = reduxErrorMiddleware;