"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRatesTableInitialValues = exports.getPeriodsAndMileages = exports.getInitialValuesFromRates = exports.extendMatrixIfNeeded = exports.createRates = exports.createMatrix = exports.createDropdownOptions = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _core = require("@northstar/core");
var _arrayUtils = require("./array-utils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const getInitialValuesFromRates = _ref => {
  let {
    rates
  } = _ref;
  if (rates.length === 0) return {};
  return rates.reduce((prev, values) => {
    const {
      yearlyMileage
    } = values[0];
    const rowValues = values.reduce((prevVal, _ref2) => {
      let {
        rate,
        leasePeriod
      } = _ref2;
      return _objectSpread(_objectSpread({}, prevVal), {}, {
        ["cell_".concat(leasePeriod)]: rate
      });
    }, {});
    return _objectSpread(_objectSpread({}, prev), {}, {
      ["row_".concat(yearlyMileage)]: rowValues
    });
  }, []);
};
exports.getInitialValuesFromRates = getInitialValuesFromRates;
const getRatesTableInitialValues = _ref3 => {
  let {
    rates,
    selected
  } = _ref3;
  if (rates.length === 0 || _core.ObjectUtils.isNullOrEmpty(selected)) {
    return {};
  }
  const matrix = rates.find(item => item.id === selected);
  return matrix ? getInitialValuesFromRates({
    rates: matrix.rates
  }) : {};
};
exports.getRatesTableInitialValues = getRatesTableInitialValues;
const createDropdownOptions = _ref4 => {
  let {
    rates = []
  } = _ref4;
  return rates.map(level => ({
    label: level.levelName,
    value: level.id
  })).sort((0, _arrayUtils.createAlphabeticalSorter)({
    property: 'label'
  }));
};
exports.createDropdownOptions = createDropdownOptions;
const createCell = _ref5 => {
  let {
    period,
    mileage,
    name
  } = _ref5;
  return {
    name,
    leasePeriod: period,
    rate: '',
    yearlyMileage: mileage
  };
};
const createRow = _ref6 => {
  let {
    name,
    periods,
    mileage
  } = _ref6;
  return periods.map(period => createCell({
    period,
    mileage,
    name
  }));
};
const createRates = _ref7 => {
  let {
    name,
    mileages,
    periods
  } = _ref7;
  return mileages.map(mileage => createRow({
    name,
    periods,
    mileage
  }));
};
exports.createRates = createRates;
const createMatrix = _ref8 => {
  let {
    mileages,
    periods,
    id,
    name
  } = _ref8;
  return {
    id,
    levelName: name,
    rates: createRates({
      name,
      mileages,
      periods
    })
  };
};
exports.createMatrix = createMatrix;
const joinTerms = _ref9 => {
  let {
    termsPROL,
    termsCMOL,
    property
  } = _ref9;
  const terms = [...new Set([...(termsPROL[property] || []), ...(termsCMOL[property] || [])])];
  return terms;
};
const getPeriodsAndMileages = _ref10 => {
  let {
    termsPROL,
    termsCMOL
  } = _ref10;
  if (_core.ObjectUtils.isNullOrEmpty(termsPROL) && _core.ObjectUtils.isNullOrEmpty(termsCMOL)) {
    return {
      yearlyMileages: [],
      leasePeriods: []
    };
  }
  return {
    yearlyMileages: joinTerms({
      termsPROL,
      termsCMOL,
      property: 'yearlyMileages'
    }),
    leasePeriods: joinTerms({
      termsPROL,
      termsCMOL,
      property: 'leasePeriods'
    })
  };
};
exports.getPeriodsAndMileages = getPeriodsAndMileages;
const findDiffBetweenArrays = _ref11 => {
  let {
    array1,
    array2
  } = _ref11;
  return array1.filter(element => !array2.includes(element));
};

/**
 *
 * @param {String} name - name of the matrix
 * @param {Array} currentPeriods - old matrix periods
 * @param {Array} oldMileages - old matrix mileages
 * @param {Array} matrix - matrix to be updated
 * @param {Array} mileages - extended mileages to be set in
 */
const extendMileagesIfNeeded = _ref12 => {
  let {
    name,
    oldPeriods,
    oldMileages,
    matrix,
    mileages
  } = _ref12;
  const mileagesDiff = findDiffBetweenArrays({
    array1: mileages,
    array2: oldMileages
  });
  return mileagesDiff.length ? [...matrix, ...mileagesDiff.map(mileage => createRow({
    name,
    periods: oldPeriods,
    mileage
  }))] : matrix;
};

/**
 *
 * @param {String} name - name of the matrix
 * @param {Array} oldPeriods - old matrix periods
 * @param {Array} matrix - matrix to be updated
 * @param {Array} periods - extended periods to be set in
 */
const extendPeriodsIfNeeded = _ref13 => {
  let {
    name,
    oldPeriods,
    matrix,
    periods
  } = _ref13;
  const periodsDiff = findDiffBetweenArrays({
    array1: periods,
    array2: oldPeriods
  });
  return periodsDiff.length ? matrix.map(row => [...row, ...periodsDiff.map(period => createCell({
    period,
    mileage: row[0].yearlyMileage,
    name
  }))]) : matrix;
};

/**
 *
 * @param {String} name - name of the matrix
 * @param {Array} matrix - matrix to be updated
 * @param {Array} mileages - extended mileages to be set in
 * @param {Array} periods - extended periods to be set in
 */
const extendMatrixIfNeeded = _ref14 => {
  let {
    name,
    matrix,
    mileages,
    periods
  } = _ref14;
  const oldPeriods = matrix[0].map(_ref15 => {
    let {
      leasePeriod
    } = _ref15;
    return leasePeriod;
  });
  const oldMileages = matrix.map(row => row[0].yearlyMileage);
  const matrixWithExtendedMileages = extendMileagesIfNeeded({
    name,
    oldPeriods,
    oldMileages,
    matrix,
    mileages
  });
  const matrixWithExtendedPeriods = extendPeriodsIfNeeded({
    name,
    oldPeriods,
    matrix: matrixWithExtendedMileages,
    periods
  });
  return matrixWithExtendedPeriods;
};
exports.extendMatrixIfNeeded = extendMatrixIfNeeded;