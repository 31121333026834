import { FC, ReactNode } from 'react';
import { SxProps, TableRow, Theme } from '@mui/material';

import { handleCustomSx } from '../../../../utils';

import { styles } from './DeprecatedMAFTableRowMobile.styles';
import { propTypes } from './DeprecatedMAFTableRowMobile.propTypes';

interface IMAFTableRowMobileProps {
  children: ReactNode;
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

const DeprecatedMAFTableRowMobile: FC<IMAFTableRowMobileProps> = ({ children, onClick, sx }) => {
  const rootClasses = [styles.root, Boolean(onClick) && styles.clickable, ...handleCustomSx(sx)];

  return (
    <TableRow onClick={onClick} sx={rootClasses}>
      {children}
    </TableRow>
  );
};

DeprecatedMAFTableRowMobile.propTypes = propTypes;

export default DeprecatedMAFTableRowMobile;
