import { ListItemText, MenuItem, MenuItemProps } from '@mui/material';

import { styles } from './MAFMultiSelectItem.styles';
import { propTypes } from './MAFMultiSelectItem.propTypes';

const MAFMultiSelectItem: React.FC<MenuItemProps> = ({ children, ...rest }) => (
  <MenuItem sx={styles.root} {...rest}>
    <ListItemText primary={children} />
  </MenuItem>
);

MAFMultiSelectItem.propTypes = propTypes;

export default MAFMultiSelectItem;
