"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const getContainer = breakpoints => ({
  width: '100%',
  display: 'flex',
  minHeight: '216px',
  [breakpoints.only('xs')]: {
    minHeight: 'auto'
  },
  height: 'auto'
});
const middle = {
  alignItems: 'center',
  width: '100%',
  margin: 'auto 120px',
  display: 'flex'
};
const styles = exports.styles = {
  upperContainer: _ref => {
    let {
      colors,
      breakpoints
    } = _ref;
    return _objectSpread(_objectSpread({}, getContainer(breakpoints)), colors.custom.footer.upper);
  },
  lowerContainer: _ref2 => {
    let {
      colors,
      breakpoints
    } = _ref2;
    return _objectSpread(_objectSpread({}, getContainer(breakpoints)), colors.custom.footer.lower);
  },
  upperMiddle: _ref3 => {
    let {
      breakpoints
    } = _ref3;
    return _objectSpread(_objectSpread({}, middle), {}, {
      flexDirection: 'row',
      justifyContent: 'space-between',
      [breakpoints.down('md')]: {
        margin: '24px',
        flexDirection: 'column'
      }
    });
  },
  lowerMiddle: _ref4 => {
    let {
      breakpoints
    } = _ref4;
    return _objectSpread(_objectSpread({}, middle), {}, {
      [breakpoints.down('md')]: {
        margin: '24px'
      }
    });
  },
  innerBox: _ref5 => {
    let {
      breakpoints
    } = _ref5;
    return {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [breakpoints.only('xs')]: {
        flexDirection: 'column'
      }
    };
  },
  title: _ref6 => {
    let {
      typography,
      fontSizes,
      breakpoints
    } = _ref6;
    return {
      fontFamily: typography.title,
      fontSize: fontSizes.extraLarge,
      textAlign: 'center',
      lineHeight: '42px',
      [breakpoints.up('sm')]: {
        lineHeight: '44px',
        textAlign: 'left'
      },
      [breakpoints.up('md')]: {
        maxWidth: 520
      },
      [breakpoints.down('md')]: {
        width: '100%'
      }
    };
  },
  titleText: _ref7 => {
    let {
      breakpoints
    } = _ref7;
    return {
      [breakpoints.up('sm')]: {
        display: 'inline'
      }
    };
  },
  text: _ref8 => {
    let {
      typography,
      fontSizes,
      breakpoints
    } = _ref8;
    return {
      fontFamily: typography.main,
      fontSize: fontSizes.tiny,
      textAlign: 'left',
      lineHeight: '24px',
      [breakpoints.up('sm')]: {
        lineHeight: '32px'
      }
    };
  },
  upperLink: _ref9 => {
    let {
      colors
    } = _ref9;
    return _objectSpread({
      textDecoration: 'none'
    }, colors.custom.footer.upper);
  },
  lowerLink: _ref10 => {
    let {
      colors
    } = _ref10;
    return _objectSpread({
      textDecoration: 'none'
    }, colors.custom.footer.lower);
  },
  button: _ref11 => {
    let {
      breakpoints
    } = _ref11;
    return {
      maxWidth: 213,
      minWidth: 142,
      margin: 'auto 0',
      [breakpoints.up('md')]: {
        marginLeft: '36px'
      },
      [breakpoints.only('sm')]: {
        marginRight: '36px',
        alignSelf: 'flex-end'
      },
      [breakpoints.only('xs')]: {
        alignSelf: 'center',
        margin: '36px 0 14px 0'
      }
    };
  },
  logo: _ref12 => {
    let {
      breakpoints
    } = _ref12;
    return {
      [breakpoints.up('xs')]: {
        marginLeft: '24px'
      },
      [breakpoints.only('xs')]: {
        marginTop: '24px',
        textAlign: 'right'
      }
    };
  },
  image: _ref13 => {
    let {
      breakpoints
    } = _ref13;
    return {
      [breakpoints.up('md')]: {
        width: 200
      }
    };
  },
  iframe: {
    display: 'none',
    position: 'absolute'
  }
};