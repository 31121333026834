import { ISxStyles } from 'src/theming/theme.type';

export const styles: ISxStyles = {
  checkbox: {
    '&&': {
      display: 'table-cell',
    },
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
};
