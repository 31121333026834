"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.responseMappers = exports.requestMappers = exports.formMappers = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _dayjs = _interopRequireDefault(require("dayjs"));
var _assetConstants = require("../assetConstants");
var _caseDetailConstants = require("../caseDetailConstants");
var _objectUtils = require("../../utils/object-utils");
const _excluded = ["idInAssetCatalogue", "name", "state", "frame"],
  _excluded2 = ["packageName", "idInAssetCatalogue"],
  _excluded3 = ["contactPersonDetails", "companyDetails"],
  _excluded4 = ["equipmentPrice"],
  _excluded5 = ["asset", "deliveryDate", "firstPaymentDate", "createdAt", "updatedAt", "deletedAt", "productType", "productSubType"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const getServices = _ref => {
  let {
    quoteDetails: {
      service,
      winterTires,
      serviceFee,
      winterTiresPrice,
      insurance,
      monthlyInsuranceFee
    }
  } = _ref;
  const arr = [];
  if (service) {
    arr.push({
      id: _assetConstants.SERVICE_TYPE_SERVICE,
      translationKey: 'case.service',
      price: serviceFee,
      money: true
    });
  }
  if (winterTires) {
    arr.push({
      id: _assetConstants.SERVICE_TYPE_WINTER_TIRES,
      translationKey: 'car_config.winter_tires_service',
      price: winterTiresPrice,
      removable: true,
      money: true
    });
  }
  if (insurance) {
    arr.push({
      id: _assetConstants.SERVICE_TYPE_INSURANCE,
      translationKey: 'car_config.insurance',
      price: monthlyInsuranceFee,
      removable: true,
      money: true
    });
  }
  return arr;
};
const mapAssetToStore = _ref2 => {
  let {
    asset,
    details
  } = _ref2;
  return (0, _objectUtils.mapObjectProperties)({
    obj: asset,
    map: {
      popName: [['frame', 'popName']],
      licencePlate: [['registrationNumber']],
      modelYear: [['frame', 'modelYear']],
      state: [['state']],
      vin: [['vinNumber']],
      mileage: [[details, 'mileage']],
      colour: [[details, 'colour']],
      brand: [['frame', 'brand']],
      model: [['frame', 'model']],
      transmissionType: [['frame', 'transmissionType']],
      drivingWheel: [['frame', 'drivingWheel']],
      enginePowerHp: [['frame', 'enginePower']],
      fuelType: [['frame', 'fuelType']],
      registrationDate: [[details, 'quoteDetails', 'registrationDate']],
      vehicleType: [['frame', 'vehicleType']],
      frameId: [['frame', 'idInAssetCatalogue']],
      framePicFile: [['frame', 'framePicFile']],
      powertrain: [['frame', 'powertrain']],
      vendorTermsId: [['vendorTermsId']]
    }
  });
};
const mapAssetFromOLCaseForDetails = _ref3 => {
  let {
      idInAssetCatalogue,
      name,
      state,
      frame
    } = _ref3,
    rest = (0, _objectWithoutProperties2.default)(_ref3, _excluded);
  return _objectSpread(_objectSpread({
    id: idInAssetCatalogue,
    name,
    state
  }, frame), rest);
};
const mapCaseAssetEquipment = caseResponse => {
  const {
    asset: {
      detailsPL,
      detailsCMOL,
      detailsHP,
      detailsCMFL,
      optionalAdditionalEquipmentPackages,
      optionalRetrofitEquipmentPackages
    }
  } = (0, _objectUtils.objectToCamelCase)({
    obj: caseResponse
  });
  const {
    quoteDetails
  } = detailsPL || detailsCMOL || detailsHP || detailsCMFL;
  return {
    standard: [],
    optional: [...optionalAdditionalEquipmentPackages, ...optionalRetrofitEquipmentPackages].map(_ref4 => {
      let {
          packageName: name,
          idInAssetCatalogue: id
        } = _ref4,
        rest = (0, _objectWithoutProperties2.default)(_ref4, _excluded2);
      return _objectSpread(_objectSpread({}, rest), {}, {
        name,
        id
      });
    }),
    services: getServices({
      quoteDetails
    })
  };
};
const mapEditableEquipment = _ref5 => {
  let {
    optionalAdditionalEquipmentPackages: additionals,
    optionalRetrofitEquipmentPackages: retrofit
  } = _ref5;
  return {
    additional: additionals.map(el => (0, _objectUtils.objectToCamelCase)({
      obj: el,
      override: {
        idInAssetCatalogue: 'id'
      }
    })),
    retrofit: retrofit.map(el => (0, _objectUtils.objectToCamelCase)({
      obj: el,
      override: {
        packageName: 'name'
      }
    }))
  };
};
const mapApplicant = applicant => {
  if (!applicant) {
    return {
      contactPerson: {},
      company: {}
    };
  }
  const {
      contactPersonDetails,
      companyDetails
    } = applicant,
    generalApplicantDetails = (0, _objectWithoutProperties2.default)(applicant, _excluded3);
  return _objectSpread({
    contactPerson: contactPersonDetails,
    company: {
      name: companyDetails.companyName,
      number: generalApplicantDetails.externalCustomerId,
      address: companyDetails.companyAddress
    }
  }, generalApplicantDetails);
};
const mapApplicantsFromCase = applicants => {
  const {
    applicantDetails,
    coApplicantDetails
  } = (0, _objectUtils.objectToCamelCase)({
    obj: applicants
  });
  return {
    applicant: mapApplicant(applicantDetails),
    coApplicant: mapApplicant(coApplicantDetails)
  };
};
const mapEquipmentPrices = _ref6 => {
  let {
    equipment,
    isWithVAT
  } = _ref6;
  return equipment.map(_ref7 => {
    let {
        equipmentPrice
      } = _ref7,
      rest = (0, _objectWithoutProperties2.default)(_ref7, _excluded4);
    return _objectSpread({
      equipmentPrice: isWithVAT ? equipmentPrice.vatIncl : equipmentPrice.vatExcl
    }, rest);
  });
};
const mapQuoteCMFL = details => (0, _objectUtils.overrideObjectProperties)({
  obj: (0, _objectUtils.objectToCamelCase)({
    obj: details
  }),
  override: {
    carRvReferenceExVat: 'carRvReference',
    finalFutureValueExVat: 'finalFutureValue',
    maxRvExVat: 'maxRv',
    minRvExVat: 'minRv',
    finalPresentValueExVat: 'finalPresentValue',
    carPriceExVat: 'carPrice',
    downPaymentValueExVat: 'downPaymentValue',
    climateBonusValueExVat: 'climateBonusValue',
    roundedQuoteExVat: 'quote'
  }
});
const mapQuoteDetailsPROL = details => (0, _objectUtils.overrideObjectProperties)({
  obj: details,
  override: {
    roundedQuoteInVat: 'quote',
    downPaymentExVat: 'downPayment',
    finalPresentValueExVat: 'finalPresentValue',
    finalFutureValueExVat: 'finalFutureValue',
    monthlyAdministrationFeesExVat: 'monthlyAdministrationFees',
    monthlyWinterTiresPriceExVat: 'monthlyWinterTiresPrice',
    documentFeeInVat: 'documentFee',
    overageCostInVat: 'overageCost',
    carPriceInVat: 'carPrice',
    carDiscountInVat: 'discount',
    monthlyServiceFeeInVat: 'serviceFee'
  }
});
const mapQuoteDetailsHP = details => (0, _objectUtils.overrideObjectProperties)({
  obj: details,
  override: {
    calculationId: 'quoteCalculationId'
  }
});
const mapQuoteDetailsCMOL = details => (0, _objectUtils.overrideObjectProperties)({
  obj: details,
  override: {
    roundedQuote: 'quote',
    overageCostExVat: 'overageCost',
    carPriceExVat: 'carPrice',
    carDiscountExVat: 'discount',
    monthlyServiceFee: 'serviceFee',
    downPaymentValueExVat: 'downPaymentValue',
    finalPresentValueExVat: 'finalPresentValue',
    retrofittedEquipmentPriceExVat: 'retrofittedEquipmentPrice',
    finalFutureValueExVat: 'finalFutureValue'
  }
});
const mapAssetDetailsPRHP = _ref8 => {
  let {
    idInAssetCatalogue: id,
    leasePeriod,
    vinNumber,
    registrationNumber,
    vendorTermsId,
    optionalAdditionalEquipmentPackages,
    optionalRetrofitEquipmentPackages,
    assetDetailsResponseSehp: {
      mileage,
      status,
      colour,
      tradeInCarRegistrationNumber,
      quoteDetails
    }
  } = _ref8;
  return {
    id,
    leasePeriod,
    vinNumber,
    registrationNumber,
    vendorTermsId,
    optionalAdditionalEquipmentPackages: mapEquipmentPrices({
      equipment: optionalAdditionalEquipmentPackages,
      isWithVAT: true
    }),
    optionalRetrofitEquipmentPackages: mapEquipmentPrices({
      equipment: optionalRetrofitEquipmentPackages,
      isWithVAT: true
    }),
    detailsHP: {
      mileage,
      status,
      colour,
      tradeInCarRegistrationNumber,
      quoteDetails: mapQuoteDetailsHP(quoteDetails)
    }
  };
};
const mapAssetDetailsPROL = _ref9 => {
  let {
    idInAssetCatalogue: id,
    leasePeriod,
    vinNumber,
    registrationNumber,
    optionalAdditionalEquipmentPackages,
    optionalRetrofitEquipmentPackages,
    assetDetailsResponseSepl: {
      yearlyMileage = 0,
      insurance,
      winterTires,
      quoteDetails
    }
  } = _ref9;
  return {
    id,
    leasePeriod,
    vinNumber,
    registrationNumber,
    optionalAdditionalEquipmentPackages: mapEquipmentPrices({
      equipment: optionalAdditionalEquipmentPackages,
      isWithVAT: true
    }),
    optionalRetrofitEquipmentPackages: mapEquipmentPrices({
      equipment: optionalRetrofitEquipmentPackages,
      isWithVAT: true
    }),
    detailsPL: {
      yearlyMileage,
      quoteDetails: mapQuoteDetailsPROL(_objectSpread(_objectSpread({}, quoteDetails), {}, {
        winterTires,
        insurance
      }))
    }
  };
};

// COMMERCIAL MAPPERS
const mapAssetDetailsCMOL = _ref10 => {
  let {
    idInAssetCatalogue: id,
    leasePeriod = 0,
    vinNumber,
    registrationNumber,
    optionalAdditionalEquipmentPackages,
    optionalRetrofitEquipmentPackages,
    assetDetailsResponseSecomol: {
      yearlyMileage,
      quoteDetails
    }
  } = _ref10;
  return {
    id,
    leasePeriod,
    vinNumber,
    registrationNumber,
    optionalAdditionalEquipmentPackages: mapEquipmentPrices({
      equipment: optionalAdditionalEquipmentPackages,
      isWithVAT: false
    }),
    optionalRetrofitEquipmentPackages: mapEquipmentPrices({
      equipment: optionalRetrofitEquipmentPackages,
      isWithVAT: false
    }),
    detailsCMOL: {
      yearlyMileage,
      quoteDetails: mapQuoteDetailsCMOL(quoteDetails)
    }
  };
};
const mapAssetDetailsCMFL = asset => {
  const {
    idInAssetCatalogue: id,
    leasePeriod,
    vinNumber,
    registrationNumber,
    vendorTermsId,
    optionalAdditionalEquipmentPackages,
    optionalRetrofitEquipmentPackages,
    assetDetailsResponseSecomfl: detailsCMFL
  } = asset;
  const {
    mileage,
    status,
    colour,
    quoteDetails
  } = detailsCMFL;
  return {
    id,
    leasePeriod,
    vinNumber,
    registrationNumber,
    vendorTermsId,
    optionalAdditionalEquipmentPackages: mapEquipmentPrices({
      equipment: optionalAdditionalEquipmentPackages,
      isWithVAT: false
    }),
    optionalRetrofitEquipmentPackages: mapEquipmentPrices({
      equipment: optionalRetrofitEquipmentPackages,
      isWithVAT: false
    }),
    detailsCMFL: {
      mileage,
      status,
      colour,
      quoteDetails: mapQuoteCMFL(_objectSpread(_objectSpread({}, quoteDetails), {}, {
        leasePeriod
      }))
    }
  };
};
const mapAssetDetails = _ref11 => {
  let {
    asset: unmappedAsset,
    productType,
    productSubType
  } = _ref11;
  if (!unmappedAsset) return {};
  const asset = (0, _objectUtils.objectToCamelCase)({
    obj: unmappedAsset
  });
  return {
    [_caseDetailConstants.PRODUCT_TYPE_PRIVATE]: {
      [_caseDetailConstants.PRODUCT_SUB_TYPE_OPERATIONAL_LEASE]: mapAssetDetailsPROL,
      [_caseDetailConstants.PRODUCT_SUB_TYPE_HIRE_PURCHASE]: mapAssetDetailsPRHP
    },
    [_caseDetailConstants.PRODUCT_TYPE_COMMERCIAL]: {
      [_caseDetailConstants.PRODUCT_SUB_TYPE_FINANCIAL_LEASE]: mapAssetDetailsCMFL,
      [_caseDetailConstants.PRODUCT_SUB_TYPE_OPERATIONAL_LEASE]: mapAssetDetailsCMOL
    }
  }[productType][productSubType](asset);
};
const mapCaseToStore = details => {
  const _objectToCamelCase = (0, _objectUtils.objectToCamelCase)({
      obj: details,
      override: {
        id: 'displayId',
        vendor_confirmed: 'vendorReviewedAgreement'
      }
    }),
    {
      asset,
      deliveryDate,
      firstPaymentDate,
      createdAt,
      updatedAt,
      deletedAt,
      productType,
      productSubType
    } = _objectToCamelCase,
    camelCasedDetails = (0, _objectWithoutProperties2.default)(_objectToCamelCase, _excluded5);
  return _objectSpread(_objectSpread({}, camelCasedDetails), {}, {
    productType,
    productSubType,
    asset: mapAssetDetails({
      asset,
      productType,
      productSubType
    }),
    deliveryDate: (0, _dayjs.default)(deliveryDate),
    firstPaymentDate: (0, _dayjs.default)(firstPaymentDate),
    createdAt: (0, _dayjs.default)(createdAt),
    updatedAt: (0, _dayjs.default)(updatedAt),
    deletedAt: (0, _dayjs.default)(deletedAt)
  });
};
const requestMappers = exports.requestMappers = {};
const responseMappers = exports.responseMappers = {
  mapAssetToStore,
  mapAssetFromOLCaseForDetails,
  mapCaseAssetEquipment,
  mapEditableEquipment,
  mapApplicantsFromCase,
  mapCase: mapCaseToStore,
  mapAssetDetails,
  mapQuoteDetailsPROL,
  mapQuoteDetailsCMOL,
  mapQuoteCMFL
};
const formMappers = exports.formMappers = {};
var _default = exports.default = {
  requestMappers,
  responseMappers,
  formMappers
};