import { Snackbar, SnackbarContent, Slide, SlideProps, SxProps, Theme } from '@mui/material';
import { FC, ReactChild, ReactNode } from 'react';

import { handleCustomSx } from '../../utils';

import { SnackbarType, DEFAULT_AUTO_HIDE_DURATION } from './MAFSnackbar.constants';
import { styles } from './MAFSnackbar.styles';
import { propTypes } from './MAFSnackbar.propTypes';

export type TMAFSnackbarPositionVariants = 'left' | 'center' | 'right';

export interface IMAFSnackbarProps {
  text: ReactChild;
  isOpen: boolean;
  onClose: () => void;
  horizontal?: TMAFSnackbarPositionVariants;
  type?: SnackbarType;
  action?: ReactNode;
  authoHideDurationMs?: number;
  sx?: SxProps<Theme>;
}

const SlideTransition: FC<SlideProps> = (props) => <Slide {...props} direction="up" />;

const MAFSnackbar: FC<IMAFSnackbarProps> = ({
  text,
  isOpen,
  onClose,
  horizontal = 'center',
  type = SnackbarType.SUCCESS,
  action,
  authoHideDurationMs = DEFAULT_AUTO_HIDE_DURATION,
  sx,
}) => {
  const contentStyles = [
    type === SnackbarType.SUCCESS && styles.success,
    type === SnackbarType.ERROR && styles.error,
    ...handleCustomSx(sx),
  ];

  return (
    <Snackbar
      autoHideDuration={authoHideDurationMs}
      open={isOpen}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'bottom', horizontal }}
      onClose={onClose}
      sx={[styles.bar, styles.bottomCenter]}
    >
      <SnackbarContent message={text} sx={contentStyles} action={action} />
    </Snackbar>
  );
};

MAFSnackbar.propTypes = propTypes;

export default MAFSnackbar;
