"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.TranslationToolProvider = void 0;
var _react = require("react");
var _reactRedux = require("react-redux");
var _appReducer = require("../modules/app/appReducer");
const IS_WITH_TRANSLATION_MODE = window.IS_WITH_TRANSLATION_MODE || false;
const TranslationToolProvider = _ref => {
  let {
    children
  } = _ref;
  const dispatch = (0, _reactRedux.useDispatch)();
  (0, _react.useEffect)(() => {
    const setTranslationModeOnListener = e => {
      if (e.repeat) {
        return;
      }
      if (e.altKey && e.key === 'r') {
        dispatch((0, _appReducer.setTranslationModeRequest)(true));
      }
    };
    const setTranslationModeOffListener = e => {
      if (e.key === 'r') {
        dispatch((0, _appReducer.setTranslationModeRequest)(false));
      }
    };
    if (IS_WITH_TRANSLATION_MODE) {
      document.addEventListener('keydown', setTranslationModeOnListener);
      document.addEventListener('keyup', setTranslationModeOffListener);
    }
    return () => {
      if (IS_WITH_TRANSLATION_MODE) {
        document.removeEventListener('keydown', setTranslationModeOnListener);
        document.removeEventListener('keyup', setTranslationModeOffListener);
      }
    };
  });
  return children;
};
exports.TranslationToolProvider = TranslationToolProvider;
var _default = exports.default = TranslationToolProvider;