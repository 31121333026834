import { ISxStyles } from '../../../../theming/theme.type';

export const styles: ISxStyles = {
  root: ({ colors }) => ({
    '&.MuiTableRow-root': {
      '&:hover': colors.custom.table.onHover,
    },
  }),
  clickable: {
    '&.MuiTableRow-root': {
      cursor: 'pointer',
    },
  },
};
