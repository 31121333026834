const InProgress: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="07-Case-overview" transform="translate(-1206.000000, -338.000000)" stroke="#fff">
        <g id="case-in-progress" transform="translate(1207.000000, 339.000000)">
          <circle id="Oval-5" cx="8.67857143" cy="8.67857143" r="9.17857143"></circle>
          <path
            d="M8.71020901,8.96842625 L17.9762792,8.96842625 C17.9036065,11.2234955 17.1320316,13.2138342 15.6615546,14.9394424 C13.0535005,18 8.71020901,18 8.71020901,18 C8.71020901,18 8.71020901,14.9894754 8.71020901,8.96842625 Z"
            id="Path-9"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default InProgress;
