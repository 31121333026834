import { FC, MouseEventHandler, CSSProperties } from 'react';
import { Button, SxProps, Theme } from '@mui/material';

import { handleCustomSx } from '../../utils';

import { styles } from './MAFDisplayNumber.styles';
import { propTypes } from './MAFDisplayNumber.propTypes';

export interface IMAFDisplayNumberProps {
  number?: number;
  size?: 'large' | 'medium';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>;
  style?: CSSProperties;
  buttonType?: 'submit' | 'button';
  tabIndex?: number;
}

const MAFDisplayNumber: FC<IMAFDisplayNumberProps> = ({
  number,
  size,
  onClick,
  sx,
  style,
  buttonType = 'submit',
  tabIndex,
}) => (
  <Button
    onClick={onClick}
    sx={[styles.number, size === 'large' && styles.large, ...handleCustomSx(sx)]}
    type={buttonType}
    style={style}
    tabIndex={tabIndex}
  >
    {number}
  </Button>
);

MAFDisplayNumber.propTypes = propTypes;

export default MAFDisplayNumber;
