import { IMAFLinkProps } from '../MAFLink';
import { IMAFSelectProps } from '../MAFSelect';

import { IMAFMenuItemProps } from './MAFMenu/components/MAFMenuItem';

export type TDrawerMenuItem = Omit<IMAFMenuItemProps, 'index'>;

export enum MAFAppBarTopLinkComponents {
  SELECT = 'MAFSelect',
  LINK = 'MAFLink',
}

export type TMAFAppBarTopLinkComponent = `${MAFAppBarTopLinkComponents}`;

export type TMAFAppBarTopLinkComponentProps = {
  MAFSelect: IMAFSelectProps;
  MAFLink: Omit<IMAFLinkProps, 'isLinkActive'>;
};

export type TMAFAppBarTopLink<TComponentType extends TMAFAppBarTopLinkComponent> = {
  componentType: TComponentType;
  props: Pick<TMAFAppBarTopLinkComponentProps, TComponentType>[TComponentType];
};

export type TMAFAppBarTopLinkConfig =
  | TMAFAppBarTopLink<MAFAppBarTopLinkComponents.LINK>
  | TMAFAppBarTopLink<MAFAppBarTopLinkComponents.SELECT>;
