const Remove: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-action/Remove/Darkblue">
        <g id="Icon" transform="translate(2.000000, 2.000000)">
          <path
            d="M14.0039,9.5522 L6.0039,9.5522 C5.7279,9.5522 5.5039,9.7762 5.5039,10.0522 C5.5039,10.3282 5.7279,10.5522 6.0039,10.5522 L14.0039,10.5522 C14.2799,10.5522 14.5039,10.3282 14.5039,10.0522 C14.5039,9.7762 14.2799,9.5522 14.0039,9.5522 M10,19 C5.038,19 1,14.963 1,10 C1,5.038 5.038,1 10,1 C14.963,1 19,5.038 19,10 C19,14.963 14.963,19 10,19 M10,0 C4.486,0 0,4.486 0,10 C0,15.514 4.486,20 10,20 C15.514,20 20,15.514 20,10 C20,4.486 15.514,0 10,0"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Remove;
