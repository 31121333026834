const Approved: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="22px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M8.89046116,22.421348 C8.56687849,22.421348 8.25698484,22.2883827 8.03126116,22.0522425 L0.340461159,14.0125903 C-0.121538841,13.5298201 -0.111938841,12.7549433 0.363261159,12.2843951 C0.838461159,11.8126247 1.59566116,11.8236245 2.06006116,12.3076169 L8.78366116,19.3365071 L21.8492612,2.05577705 C22.2512612,1.52045207 23.0072612,1.42023142 23.5328612,1.83333608 C24.0572612,2.24521853 24.1544612,3.0127621 23.7500612,3.54808707 L9.84086116,21.9446886 C9.62880342,22.225532 9.30734285,22.3986035 8.96006116,22.4189034 C8.93726116,22.4201256 8.91326116,22.421348 8.89046116,22.421348"
        id="approvedIcon"
      ></path>
    </defs>
    <g id="New-affordability-section" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="New-affordability" transform="translate(-174.000000, -996.000000)">
        <g id="Main-section" transform="translate(120.000000, 104.000000)">
          <g id="Section-6-Copy" transform="translate(0.000000, 757.000000)">
            <g id="Group-4" transform="translate(54.000000, 110.000000)">
              <g id="Group" transform="translate(0.000000, 24.000000)">
                <mask id="mask-2" fill="currentColor">
                  <use xlinkHref="#approvedIcon"></use>
                </mask>
                <use
                  id="Approved-green"
                  fill="currentColor"
                  fillRule="nonzero"
                  xlinkHref="#approvedIcon"
                ></use>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Approved;
