"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectPublicComments = exports.selectIsPublicCommentsLoading = exports.selectIsPublicCommentsCreating = exports.selectIsInternalCommentsLoading = exports.selectIsInternalCommentsCreating = exports.selectInternalComments = void 0;
var _reselect = require("reselect");
const selectCaseState = state => state.comments;
const selectIsPublicCommentsCreating = () => (0, _reselect.createSelector)(selectCaseState, caseState => caseState.get('postingPublicComment'));
exports.selectIsPublicCommentsCreating = selectIsPublicCommentsCreating;
const selectIsPublicCommentsLoading = () => (0, _reselect.createSelector)(selectCaseState, caseState => caseState.get('gettingPublicComments'));
exports.selectIsPublicCommentsLoading = selectIsPublicCommentsLoading;
const selectPublicComments = () => (0, _reselect.createSelector)(selectCaseState, caseState => caseState.get('publicComments').toJS());
exports.selectPublicComments = selectPublicComments;
const selectIsInternalCommentsCreating = () => (0, _reselect.createSelector)(selectCaseState, caseState => caseState.get('postingInternalComment'));
exports.selectIsInternalCommentsCreating = selectIsInternalCommentsCreating;
const selectIsInternalCommentsLoading = () => (0, _reselect.createSelector)(selectCaseState, caseState => caseState.get('gettingInternalComments'));
exports.selectIsInternalCommentsLoading = selectIsInternalCommentsLoading;
const selectInternalComments = () => (0, _reselect.createSelector)(selectCaseState, caseState => caseState.get('internalComments').toJS());
exports.selectInternalComments = selectInternalComments;