"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateWinterTiresMatrixResponse = exports.updateWinterTiresMatrixRequest = exports.updateServiceMatrixResponse = exports.updateServiceMatrixRequest = exports.updateSelectedLeasingTerm = exports.setVendorTerms = exports.setIsGettingFeatures = exports.saveSingleMatrix = exports.openNewServiceRateModal = exports.onWinterTiresMatrixCellValueChange = exports.onSelectedMatrixChange = exports.initialTermsState = exports.initialCommercialFinancialLeaseTerms = exports.getVendorTermsPROLResponse = exports.getVendorTermsPROLRequest = exports.getVendorTermsPRHPResponse = exports.getVendorTermsPRHPRequest = exports.getVendorTermsCMOLResponse = exports.getVendorTermsCMOLRequest = exports.getVendorTermsCMFLResponse = exports.getVendorTermsCMFLRequest = exports.getVendorResponse = exports.getVendorRequest = exports.getVendorFeaturesResponse = exports.getVendorFeaturesRequest = exports.getServiceRatesMatrixResponse = exports.getServiceRatesMatrixRequest = exports.getServiceNamesResponse = exports.getServiceNamesRequest = exports.getServiceMatrixResponse = exports.getServiceMatrixRequest = exports.getFirstServiceMatrixRequest = exports.getAllVendorTermsPROLResponse = exports.getAllVendorTermsPROLRequest = exports.getAllVendorTermsPRHPResponse = exports.getAllVendorTermsPRHPRequest = exports.getAllVendorTermsCMOLResponse = exports.getAllVendorTermsCMOLRequest = exports.getAllVendorTermsCMFLResponse = exports.getAllVendorTermsCMFLRequest = exports.deleteServiceMatrixResponse = exports.deleteServiceMatrixRequest = exports.default = exports.createServiceMatrixResponse = exports.createServiceMatrixRequest = exports.closeNewServiceRateModal = exports.clearSelectedTerms = exports.addRatesMatrix = exports.addMatrixSingle = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
var _utils = require("@northstar/core/utils");
var matrixUtils = _interopRequireWildcard(require("../../utils/matrix-utils"));
var vendorTermsUtils = _interopRequireWildcard(require("../../utils/vendor-terms-utils"));
var _vendorDashboardTermsConstants = require("./vendorDashboardTermsConstants");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const {
  createMatrix,
  createRates,
  getPeriodsAndMileages
} = vendorTermsUtils;
const getVendorTermsPROLRequest = exports.getVendorTermsPROLRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_VENDOR_TERMS_PRIVATE_OL_REQUEST');
const getVendorTermsPROLResponse = exports.getVendorTermsPROLResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_VENDOR_TERMS_PRIVATE_OL_RESPONSE');
const getVendorTermsPRHPRequest = exports.getVendorTermsPRHPRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_VENDOR_TERMS_PRIVATE_HP_REQUEST');
const getVendorTermsPRHPResponse = exports.getVendorTermsPRHPResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_VENDOR_TERMS_PRIVATE_HP_RESPONSE');
const getVendorTermsCMOLRequest = exports.getVendorTermsCMOLRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_VENDOR_TERMS_COMMERCIAL_OL_REQUEST');
const getVendorTermsCMOLResponse = exports.getVendorTermsCMOLResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_VENDOR_TERMS_COMMERCIAL_OL_RESPONSE');
const getVendorTermsCMFLRequest = exports.getVendorTermsCMFLRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_VENDOR_TERMS_FINANCIAL_OL_REQUEST');
const getVendorTermsCMFLResponse = exports.getVendorTermsCMFLResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_VENDOR_TERMS_FINANCIAL_OL_RESPONSE');
const addRatesMatrix = exports.addRatesMatrix = (0, _reduxActions.createAction)('DASHBOARD_ADD_RATES_MATRIX');
const onWinterTiresMatrixCellValueChange = exports.onWinterTiresMatrixCellValueChange = (0, _reduxActions.createAction)('DASHBOARD_ON_WINTER_TIRES_MATRIX_CELL_VALUE_CHANGE');
const onSelectedMatrixChange = exports.onSelectedMatrixChange = (0, _reduxActions.createAction)('DASHBOARD_SELECTED_MATRIX_CHANGE');
const updateSelectedLeasingTerm = exports.updateSelectedLeasingTerm = (0, _reduxActions.createAction)('DASHBOARD_LEASING_TERMS_UPDATE_SELECTED');
const clearSelectedTerms = exports.clearSelectedTerms = (0, _reduxActions.createAction)('DASHBOARD_LEASING_CLEAR_STATE');
const getVendorRequest = exports.getVendorRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_VENDOR_REQUEST');
const getVendorResponse = exports.getVendorResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_VENDOR_RESPONSE');
const saveSingleMatrix = exports.saveSingleMatrix = (0, _reduxActions.createAction)('DASHBOARD_SAVE_SINGLE_MATRIX');
const getServiceNamesRequest = exports.getServiceNamesRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_SERVICE_NAMES_REQUEST');
const getServiceNamesResponse = exports.getServiceNamesResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_SERVICE_NAMES_RESPONSE');
const openNewServiceRateModal = exports.openNewServiceRateModal = (0, _reduxActions.createAction)('DASHBOARD_SERVICE_MATRIX_OPEN');
const closeNewServiceRateModal = exports.closeNewServiceRateModal = (0, _reduxActions.createAction)('DASHBOARD_SERVICE_MATRIX_CLOSE');
const getServiceRatesMatrixRequest = exports.getServiceRatesMatrixRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_SERVICE_RATES_MATRIX_REQUEST');
const getServiceRatesMatrixResponse = exports.getServiceRatesMatrixResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_SERVICE_RATES_MATRIX_RESPONSE');
const getServiceMatrixRequest = exports.getServiceMatrixRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_SERVICE_MATRIX_REQUEST');
const getServiceMatrixResponse = exports.getServiceMatrixResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_SERVICE_MATRIX_RESPONSE');
const getFirstServiceMatrixRequest = exports.getFirstServiceMatrixRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_FIRST_SERVICE_MATRIX_REQUEST');
const updateServiceMatrixRequest = exports.updateServiceMatrixRequest = (0, _reduxActions.createAction)('DASHBOARD_UPDATE_SERVICE_MATRIX_REQUEST');
const updateServiceMatrixResponse = exports.updateServiceMatrixResponse = (0, _reduxActions.createAction)('DASHBOARD_UPDATE_SERVICE_MATRIX_RESPONSE');
const deleteServiceMatrixRequest = exports.deleteServiceMatrixRequest = (0, _reduxActions.createAction)('DASHBOARD_DELETE_SERVICE_MATRIX_REQUEST');
const deleteServiceMatrixResponse = exports.deleteServiceMatrixResponse = (0, _reduxActions.createAction)('DASHBOARD_DELETE_SERVICE_MATRIX_RESPONSE');
const createServiceMatrixRequest = exports.createServiceMatrixRequest = (0, _reduxActions.createAction)('DASHBOARD_CREATE_SERVICE_MATRIX_REQUEST');
const createServiceMatrixResponse = exports.createServiceMatrixResponse = (0, _reduxActions.createAction)('DASHBOARD_CREATE_SERVICE_MATRIX_RESPONSE');
const addMatrixSingle = exports.addMatrixSingle = (0, _reduxActions.createAction)('DASHBOARD_ADD_MATRIX_SINGLE');
const updateWinterTiresMatrixRequest = exports.updateWinterTiresMatrixRequest = (0, _reduxActions.createAction)('DASHBOARD_UPDATE_WINTER_TIRES_MATRIX_REQUEST');
const updateWinterTiresMatrixResponse = exports.updateWinterTiresMatrixResponse = (0, _reduxActions.createAction)('DASHBOARD_UPDATE_WINTER_TIRES_MATRIX_RESPONSE');
const getVendorFeaturesRequest = exports.getVendorFeaturesRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_VENDOR_FEATURES_REQUEST');
const getVendorFeaturesResponse = exports.getVendorFeaturesResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_VENDOR_FEATURES_RESPONSE');
const setIsGettingFeatures = exports.setIsGettingFeatures = (0, _reduxActions.createAction)('DASHBOARD_SET_IS_GETTING_FEATURES');
const getAllVendorTermsPROLRequest = exports.getAllVendorTermsPROLRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_ALL_VENDOR_TERMS_PROL_REQUEST');
const getAllVendorTermsPROLResponse = exports.getAllVendorTermsPROLResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_ALL_VENDOR_TERMS_PROL_RESPONSE');
const getAllVendorTermsPRHPRequest = exports.getAllVendorTermsPRHPRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_ALL_VENDOR_TERMS_PRHP_REQUEST');
const getAllVendorTermsPRHPResponse = exports.getAllVendorTermsPRHPResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_ALL_VENDOR_TERMS_PRHP_RESPONSE');
const getAllVendorTermsCMOLRequest = exports.getAllVendorTermsCMOLRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_ALL_VENDOR_TERMS_CMOL_REQUEST');
const getAllVendorTermsCMOLResponse = exports.getAllVendorTermsCMOLResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_ALL_VENDOR_TERMS_CMOL_RESPONSE');
const getAllVendorTermsCMFLRequest = exports.getAllVendorTermsCMFLRequest = (0, _reduxActions.createAction)('DASHBOARD_GET_ALL_VENDOR_TERMS_CMFL_REQUEST');
const getAllVendorTermsCMFLResponse = exports.getAllVendorTermsCMFLResponse = (0, _reduxActions.createAction)('DASHBOARD_GET_ALL_VENDOR_TERMS_CMFL_RESPONSE');
const setVendorTerms = exports.setVendorTerms = (0, _reduxActions.createAction)('DASHBOARD_SET_VENDOR_TERMS');
const defaultSelectedTerms = {
  mileage: _vendorDashboardTermsConstants.DEFAULT_MIN_MILEAGE,
  period: _vendorDashboardTermsConstants.DEFAULT_MAX_PERIOD
};
const initialPrivateLeasingTerms = {
  yearlyMileages: [],
  leasePeriods: [],
  winterTiresNames: [],
  [_vendorDashboardTermsConstants.RATES_TYPE_WINTER_TIRES]: [],
  serviceOverageCorrectionCoefficient: null,
  serviceUnderageCorrectionCoefficient: null,
  selectedMatrix: null
};
const initialCommercialOperationalLeaseTerms = {
  yearlyMileages: [],
  leasePeriods: []
};
const initialCommercialFinancialLeaseTerms = exports.initialCommercialFinancialLeaseTerms = {
  leasePeriods: []
};
const allTerms = {
  [_utils.VALIDITY_ALL]: [],
  [_utils.VALIDITY_VALID]: [],
  [_utils.VALIDITY_NOT_EXPIRED]: []
};
const initialTermsState = exports.initialTermsState = (0, _immutable.fromJS)({
  selected: defaultSelectedTerms,
  termsPROL: initialPrivateLeasingTerms,
  termsPRHP: {},
  termsCMOL: initialCommercialOperationalLeaseTerms,
  termsCMFL: initialCommercialFinancialLeaseTerms,
  vendor: {
    brands: [],
    orderCompanies: []
  },
  [_vendorDashboardTermsConstants.RATES_TYPE_SERVICE]: [],
  serviceNames: [],
  selectedMatrix: null,
  rates: [],
  isNewServiceRateOpen: false,
  gettingTermsPROL: false,
  gettingTermsPRHP: false,
  gettingTermsCMOL: false,
  gettingTermsCMFL: false,
  updatingTerms: false,
  gettingVendor: false,
  gettingServiceRates: false,
  loadingMatrixSingle: false,
  isGettingFeatures: true,
  allTermsPROL: allTerms,
  allTermsPRHP: allTerms,
  allTermsCMOL: allTerms,
  allTermsCMFL: allTerms,
  selectedVendorTermsId: 1
});
var _default = exports.default = (0, _reduxActions.handleActions)({
  [(0, _reduxActions.combineActions)(getVendorTermsPROLRequest, getAllVendorTermsPROLRequest)](state) {
    return state.set('gettingTermsPROL', true);
  },
  [getVendorTermsPROLResponse](state, _ref) {
    let {
      payload,
      error
    } = _ref;
    if (error) {
      return state.set('gettingTermsPROL', false).set('termsPROL', (0, _immutable.fromJS)(initialPrivateLeasingTerms));
    }
    const selected = state.get('selected');
    const selectedMatrix = state.getIn(['termsPROL', 'selectedMatrix']);
    return state.set('termsPROL', (0, _immutable.fromJS)(payload)).setIn(['termsPROL', 'selectedMatrix'], selectedMatrix || 0).set('selected', selected).set('gettingTermsPROL', false);
  },
  [addRatesMatrix](state, _ref2) {
    let {
      payload
    } = _ref2;
    const {
      name
    } = payload;
    const selectedVendorTermsId = state.get('selectedVendorTermsId');
    const allTermsPROL = state.get('allTermsPROL').get(_utils.VALIDITY_NOT_EXPIRED).toJS();
    const selectedTermsPROL = allTermsPROL.find(_ref3 => {
      let {
        vendorTermsId
      } = _ref3;
      return vendorTermsId === selectedVendorTermsId;
    });
    const indexTermsPROL = allTermsPROL.findIndex(_ref4 => {
      let {
        vendorTermsId
      } = _ref4;
      return vendorTermsId === selectedVendorTermsId;
    });
    const rates = selectedTermsPROL[_vendorDashboardTermsConstants.RATES_TYPE_WINTER_TIRES];
    const mileages = selectedTermsPROL.yearlyMileages.sort();
    const periods = selectedTermsPROL.leasePeriods.sort();
    const matrix = createMatrix({
      mileages,
      periods,
      id: rates.length,
      name
    });
    return state.updateIn(['allTermsPROL', _utils.VALIDITY_NOT_EXPIRED, indexTermsPROL, _vendorDashboardTermsConstants.RATES_TYPE_WINTER_TIRES], list => list.push((0, _immutable.fromJS)(matrix))).setIn(['termsPROL', 'selectedMatrix'], rates.length);
  },
  [saveSingleMatrix](state, _ref5) {
    let {
      payload
    } = _ref5;
    const {
      formValues
    } = payload;
    const selectedVendorTermsId = state.get('selectedVendorTermsId');
    const indexTermsPROL = state.get('allTermsPROL').get(_utils.VALIDITY_NOT_EXPIRED).toJS().findIndex(_ref6 => {
      let {
        vendorTermsId
      } = _ref6;
      return vendorTermsId === selectedVendorTermsId;
    });
    const selectedMatrix = state.getIn(['termsPROL', 'selectedMatrix']);
    return state.updateIn(['allTermsPROL', _utils.VALIDITY_NOT_EXPIRED, indexTermsPROL, _vendorDashboardTermsConstants.RATES_TYPE_WINTER_TIRES, selectedMatrix, 'rates'], rates => {
      const {
        name
      } = rates.toJS()[0][0];
      return (0, _immutable.fromJS)(matrixUtils.mapRateMatrixFromForm({
        formValues,
        name
      }));
    });
  },
  [updateWinterTiresMatrixRequest](state) {
    return state.set('updatingTerms', true);
  },
  [updateWinterTiresMatrixResponse](state) {
    return state.set('updatingTerms', false);
  },
  [openNewServiceRateModal](state) {
    return state.set('isNewServiceRateOpen', true).set('newRateMatrixName', '');
  },
  [closeNewServiceRateModal](state) {
    return state.set('isNewServiceRateOpen', false);
  },
  [onSelectedMatrixChange](state, _ref7) {
    let {
      payload
    } = _ref7;
    return state.setIn(['termsPROL', 'selectedMatrix'], payload);
  },
  [updateSelectedLeasingTerm](state, _ref8) {
    let {
      payload
    } = _ref8;
    return state.setIn(['selected', payload.key], payload.value);
  },
  [clearSelectedTerms](state) {
    let {
      payload = {}
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const {
      partialCleanup = false
    } = payload;
    return state.set('selected', partialCleanup ? state.get('selected') : (0, _immutable.fromJS)(defaultSelectedTerms));
  },
  [getVendorRequest](state) {
    return state.set('gettingVendor', true);
  },
  [getVendorResponse](state, _ref9) {
    let {
      payload,
      error
    } = _ref9;
    return error ? state.set('gettingVendor', false) : state.set('gettingVendor', false).set('vendor', (0, _immutable.fromJS)(payload));
  },
  [getVendorTermsPRHPRequest](state) {
    return state.set('gettingTermsPRHP', true);
  },
  [getVendorTermsPRHPResponse](state, _ref10) {
    let {
      payload,
      error
    } = _ref10;
    return error ? state.set('termsPRHP', (0, _immutable.fromJS)({})).set('gettingTermsPRHP', false) : state.set('termsPRHP', (0, _immutable.fromJS)(payload)).set('gettingTermsPRHP', false);
  },
  // ==========================================================
  // | Service rates matrix
  // | * Not working for commercial
  // ==========================================================
  [getServiceNamesRequest](state) {
    return state.set('gettingServiceRates', true);
  },
  [getServiceNamesResponse](state, _ref11) {
    let {
      payload,
      error
    } = _ref11;
    return error ? state.set('gettingServiceRates', false) : state.set('gettingServiceRates', false).set('serviceNames', (0, _immutable.fromJS)(payload));
  },
  [getServiceMatrixRequest](state, _ref12) {
    let {
      payload
    } = _ref12;
    return state.set('selectedMatrix', payload).set('loadingMatrixSingle', true);
  },
  [(0, _reduxActions.combineActions)(updateServiceMatrixRequest, createServiceMatrixRequest, deleteServiceMatrixRequest)](state) {
    return state.set('loadingMatrixSingle', true);
  },
  [deleteServiceMatrixResponse](state) {
    return state.set('loadingMatrixSingle', false);
  },
  [getServiceMatrixResponse](state, _ref13) {
    let {
      payload,
      error
    } = _ref13;
    return error ? state.set('loadingMatrixSingle', false) : state.set('rates', (0, _immutable.fromJS)(payload)).set('loadingMatrixSingle', false);
  },
  [addMatrixSingle](state, _ref14) {
    let {
      payload
    } = _ref14;
    const {
      name
    } = payload;
    // this part has to depend on one maximum term of the available product terms
    const termsPROL = state.get('termsPROL').toJS();
    const termsCMOL = state.get('termsCMOL').toJS();
    const {
      yearlyMileages,
      leasePeriods
    } = getPeriodsAndMileages({
      termsPROL,
      termsCMOL
    });
    const mileages = yearlyMileages.sort();
    const periods = leasePeriods.sort();
    return state.set('rates', (0, _immutable.fromJS)(createRates({
      name,
      mileages,
      periods
    }))).set('selectedMatrix', name);
  },
  // ==========================================================
  // | End of service rates matrix
  // ==========================================================
  [getVendorTermsCMOLRequest](state) {
    return state.set('gettingTermsCMOL', true);
  },
  [getVendorTermsCMOLResponse](state, _ref15) {
    let {
      payload,
      error
    } = _ref15;
    return error ? state.set('termsCMOL', (0, _immutable.fromJS)(initialCommercialOperationalLeaseTerms)).set('gettingTermsCMOL', false) : state.set('termsCMOL', (0, _immutable.fromJS)(payload)).set('gettingTermsCMOL', false);
  },
  [getVendorTermsCMFLRequest](state) {
    return state.set('gettingTermsCMFL', true);
  },
  [getVendorTermsCMFLResponse](state, _ref16) {
    let {
      payload,
      error
    } = _ref16;
    return error ? state.set('termsCMFL', (0, _immutable.fromJS)(initialCommercialFinancialLeaseTerms)).set('gettingTermsCMFL', false) : state.set('termsCMFL', (0, _immutable.fromJS)(payload)).set('gettingTermsCMFL', false);
  },
  [getVendorFeaturesRequest](state) {
    return state.set('isGettingFeatures', true);
  },
  [getVendorFeaturesResponse](state) {
    return state.set('isGettingFeatures', false);
  },
  [setIsGettingFeatures](state, _ref17) {
    let {
      payload
    } = _ref17;
    return state.set('isGettingFeatures', payload);
  },
  [getAllVendorTermsPROLResponse](state, _ref18) {
    let {
      payload,
      error
    } = _ref18;
    const {
      validity,
      terms
    } = payload;
    return error ? state.set('gettingTermsPROL', false) : state.set('gettingTermsPROL', false).setIn(['allTermsPROL', validity], (0, _immutable.fromJS)(terms));
  },
  [getAllVendorTermsPRHPResponse](state, _ref19) {
    let {
      payload,
      error
    } = _ref19;
    const {
      validity,
      terms
    } = payload;
    return error ? state : state.setIn(['allTermsPRHP', validity], (0, _immutable.fromJS)(terms));
  },
  [getAllVendorTermsCMOLResponse](state, _ref20) {
    let {
      payload,
      error
    } = _ref20;
    const {
      validity,
      terms
    } = payload;
    return error ? state : state.setIn(['allTermsCMOL', validity], (0, _immutable.fromJS)(terms));
  },
  [getAllVendorTermsCMFLResponse](state, _ref21) {
    let {
      payload,
      error
    } = _ref21;
    const {
      validity,
      terms
    } = payload;
    return error ? state : state.setIn(['allTermsCMFL', validity], (0, _immutable.fromJS)(terms));
  },
  [setVendorTerms](state, _ref22) {
    let {
      payload
    } = _ref22;
    return state.set('selectedVendorTermsId', payload);
  }
}, initialTermsState);