"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  header: _ref => {
    let {
      colors,
      breakpoints
    } = _ref;
    return {
      color: colors.custom.fontPrimary,
      lineHeight: '19px',
      paddingBottom: '30px',
      [breakpoints.down('lg')]: {
        textAlign: 'center'
      }
    };
  },
  withoutPadding: {
    paddingBottom: 0
  }
};