"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tryToRedirectRequest = exports.setNotificationWithRedirect = exports.redirectFromNotification = exports.readNotificationsResponse = exports.readNotificationsRequest = exports.initialState = exports.getAllNotificationsResponse = exports.getAllNotificationsRequest = exports.getAllNotificationsPollRequest = exports.default = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
const getAllNotificationsPollRequest = exports.getAllNotificationsPollRequest = (0, _reduxActions.createAction)('NOTIFICATIONS_GET_ALL_NOTIFICATIONS_POLL_REQUEST');
const getAllNotificationsRequest = exports.getAllNotificationsRequest = (0, _reduxActions.createAction)('NOTIFICATIONS_GET_ALL_NOTIFICATIONS_REQUEST');
const getAllNotificationsResponse = exports.getAllNotificationsResponse = (0, _reduxActions.createAction)('NOTIFICATIONS_GET_ALL_NOTIFICATIONS_RESPONSE');
const readNotificationsRequest = exports.readNotificationsRequest = (0, _reduxActions.createAction)('NOTIFICATIONS_READ_NOTIFICATIONS_REQUEST');
const readNotificationsResponse = exports.readNotificationsResponse = (0, _reduxActions.createAction)('NOTIFICATIONS_READ_NOTIFICATIONS_RESPONSE');
const redirectFromNotification = exports.redirectFromNotification = (0, _reduxActions.createAction)('NOTIFICATIONS_REDIRECT_FROM_NOTIFICATION');
const tryToRedirectRequest = exports.tryToRedirectRequest = (0, _reduxActions.createAction)('NOTIFICATIONS_READ_NOTIFICATION_REQUEST');
const setNotificationWithRedirect = exports.setNotificationWithRedirect = (0, _reduxActions.createAction)('NOTIFICATIONS_SET_NOTIFICATION_WITH_REDIRECT');
const initialState = exports.initialState = (0, _immutable.fromJS)({
  notifications: [],
  isGettingNotifications: false,
  isReadingNotifications: false,
  notificationWithRedirect: null,
  error: false
});
var _default = exports.default = (0, _reduxActions.handleActions)({
  [getAllNotificationsRequest](state) {
    return state.set('isGettingNotifications', true);
  },
  [getAllNotificationsResponse](state, _ref) {
    let {
      error,
      payload
    } = _ref;
    return error ? state.set('isGettingNotifications', false).set('notifications', (0, _immutable.fromJS)([])).set('error', true) : state.set('isGettingNotifications', false).set('notifications', (0, _immutable.fromJS)(payload)).set('error', false);
  },
  [readNotificationsRequest](state) {
    return state.set('isReadingNotifications', true);
  },
  [readNotificationsResponse](state) {
    return state.set('isReadingNotifications', false);
  },
  [setNotificationWithRedirect](state, _ref2) {
    let {
      payload
    } = _ref2;
    return state.set('notificationWithRedirect', (0, _immutable.fromJS)(payload));
  }
}, initialState);