"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renderReversedFullName = exports.renderFullName = exports.removeSpecialCharacters = exports.isNullOrEmpty = exports.formatValueToMoneyWithCentsFormat = exports.formatToYearsAndMonths = exports.formatToPercentage = exports.formatToMileage = exports.formatToLocalTimestamp = exports.formatToLocalDate = exports.convertToMoneyWithCents = exports.convertToMoney = exports.capitalizeFirstLetter = exports.EMPTY_VALUE = void 0;
var _dayjs = _interopRequireDefault(require("dayjs"));
var _utc = _interopRequireDefault(require("dayjs/plugin/utc"));
var _numeral = _interopRequireDefault(require("numeral"));
var _vendor = require("../vendor.config");
var _numberUtils = require("./number-utils");
_dayjs.default.extend(_utc.default);
const isNullOrEmpty = valueToCheck => typeof valueToCheck !== 'number' && (!valueToCheck || Object.keys(valueToCheck).length === 0);
exports.isNullOrEmpty = isNullOrEmpty;
const formatValueToMoneyWithCentsFormat = value => {
  if (!value && value !== 0) return '';
  let formattedValue = value;
  if (typeof formattedValue !== 'string') {
    formattedValue = formattedValue.toString().replace('.', ',');
  }
  const valueSplit = formattedValue.split(',');
  if (valueSplit.length > 2) return value;
  const [valueBeforeComma, valueAfterComma] = valueSplit;
  const regex = /^\d*(\.\d*)*$/;
  const valueBeforeCommaFormatted = regex.test(valueBeforeComma) ? (0, _numeral.default)(valueBeforeComma).format('0,0') : valueBeforeComma;
  if (valueSplit.length === 1) return valueBeforeCommaFormatted;
  const valueAfterCommaFormatted = regex.test(valueAfterComma) ? ",".concat(valueAfterComma.substring(0, 2)) : ",".concat(valueAfterComma);
  const valueFormatted = valueBeforeCommaFormatted.concat(valueAfterCommaFormatted);
  return valueFormatted;
};
exports.formatValueToMoneyWithCentsFormat = formatValueToMoneyWithCentsFormat;
const convertToMoney = function (value) {
  let unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'kr';
  return "".concat((0, _numeral.default)(Math.ceil(value)).format('0,0'), " ").concat(unit).trim();
};
exports.convertToMoney = convertToMoney;
const convertToMoneyWithCents = function (value) {
  let unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'kr';
  return "".concat((0, _numeral.default)(value).format('0.0,'), " ").concat(unit);
};
exports.convertToMoneyWithCents = convertToMoneyWithCents;
const formatToPercentage = function () {
  let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return "".concat((0, _numberUtils.convertToFixed)(value, 2, '0.00').replace('.', ','), " %");
};
exports.formatToPercentage = formatToPercentage;
const formatToYearsAndMonths = function () {
  let months = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return {
    years: Math.floor(months / 12),
    months: months % 12
  };
};
exports.formatToYearsAndMonths = formatToYearsAndMonths;
const formatToMileage = value => (0, _numeral.default)(value).format('0,0');
exports.formatToMileage = formatToMileage;
const formatToLocalDate = date => _dayjs.default.utc(date).local().format(_vendor.DEFAULT_DATE_FORMAT);
exports.formatToLocalDate = formatToLocalDate;
const capitalizeFirstLetter = value => {
  if (typeof value !== 'string') return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
};
exports.capitalizeFirstLetter = capitalizeFirstLetter;
const formatToLocalTimestamp = timestamp => _dayjs.default.utc(timestamp).local().format(_vendor.DEFAULT_TIMESTAMP_FORMAT);
exports.formatToLocalTimestamp = formatToLocalTimestamp;
const EMPTY_VALUE = exports.EMPTY_VALUE = '---';
const renderFullName = params => {
  if (!params) return EMPTY_VALUE;
  const {
    firstName = '',
    lastName = '',
    emptyValue = EMPTY_VALUE
  } = params;
  return filterEmptyVals([firstName, lastName]) || emptyValue;
};
exports.renderFullName = renderFullName;
const renderReversedFullName = params => {
  if (!params) return EMPTY_VALUE;
  const {
    firstName = '',
    lastName = '',
    emptyValue = EMPTY_VALUE
  } = params;
  return filterEmptyVals([lastName, firstName], ', ') || emptyValue;
};
exports.renderReversedFullName = renderReversedFullName;
const filterEmptyVals = function (vals) {
  let joinBy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ' ';
  return vals.filter(Boolean).join(joinBy).trim();
};
const removeSpecialCharacters = str =>
// eslint-disable-next-line no-useless-escape
str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
exports.removeSpecialCharacters = removeSpecialCharacters;