import { ISxStyles } from '../../../theming/theme.type';
import { APPBAR_MIN_HEIGHT_MOBILE } from '../MAFAppBar.styles';

export const styles: ISxStyles = {
  menuContainer: {
    display: 'flex',
  },
  menuButton: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  burgerIcon: {
    height: 32,
    width: 32,
  },
  closeIcon: {
    height: 32,
  },
  drawer: ({ breakpoints, colors }) => ({
    '& .MuiDrawer-paperAnchorRight': {
      width: 328,
      background: colors.danske.primary1,
      [breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
  closeBtn: {
    justifyContent: 'right',
  },
  mobileMenuTop: {
    padding: 'unset',
  },
  list: ({ breakpoints }) => ({
    [breakpoints.down('sm')]: {
      paddingTop: `${APPBAR_MIN_HEIGHT_MOBILE}px`,
    },
  }),
  tabs: ({ colors }) => ({
    background: colors.danske.primary6,
  }),
};
