import {
  app,
  auth,
  support,
  userProfile,
  cases,
  comments,
  documents,
  frameForm,
  userManagement,
  caseForQuote,
  notifications,
  vendorDashboard,
  insurance,
} from '@northstar/core-ui/modules';
import { all, fork } from 'redux-saga/effects';

import assetSaga from 'modules/asset/assetSaga';
import equipmentSaga from 'modules/asset/equipmentSaga';
import frameSaga from 'modules/asset/frameSaga';
import assetsSaga from 'modules/assets/assetsSaga';
import carListSaga from 'modules/carList/carListSaga';
import caseAssetSaga from 'modules/caseDetail/caseAssetSaga';
import caseDetailSaga from 'modules/caseDetail/caseDetailSaga';
import creditSaga from 'modules/credit/creditSaga';
import hirePurchaseSaga from 'modules/hirePurchase/hirePurchaseSaga';
import kycSaga from 'modules/kyc/kycSaga';
import quoteSaga from 'modules/quote/quoteSaga';
import vendorTermsSaga from 'modules/vendor/vendorTermsSaga';
import productTermsSaga from 'modules/productTerms/productTermsSaga';

const caseUnrelatedSagas = [
  assetsSaga,
  vendorTermsSaga,
  cases.casesSaga,
  userManagement.userManagementSaga,
  carListSaga,
  app.appSaga,
  auth.authSaga,
  support.supportSaga,
  userProfile.userProfileSaga,
  kycSaga,
  productTermsSaga,
  frameForm.frameFormSaga,
  caseForQuote.caseForQuoteSaga,
  notifications.notificationsSaga,
  vendorDashboard.vendorDashboardTermsSaga,
  vendorDashboard.vendorDashboardAssetListSaga,
  vendorDashboard.vendorDashboardAssetSaga,
  vendorDashboard.vendorDashboardFrameSaga,
  vendorDashboard.vendorDashboardEquipmentSaga,
  insurance.insuranceSaga,
];

const caseRelatedSagas = [
  assetSaga,
  frameSaga,
  equipmentSaga,
  documents.documentsSaga,
  hirePurchaseSaga,
  creditSaga,
  comments.commentsSaga,
  quoteSaga,
  caseAssetSaga,
  caseDetailSaga,
];

function* forkSagas(sagaList) {
  const forks = sagaList.map(fork);
  yield all(forks);
}

export const globalSagas = () => forkSagas(caseUnrelatedSagas);
export const caseManagementSagas = () => forkSagas(caseRelatedSagas);
