const Message: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="Icons-action/Message/White">
        <g id="Icon" transform="translate(2.000000, 4.000000)">
          <path
            d="M12.7606,8.054 L18.9996,1.846 L18.9996,14.293 L12.7606,8.054 Z M8.0516,8.818 L8.2466,9.015 C8.7176,9.489 9.3446,9.751 10.0136,9.754 L10.0206,9.754 C10.6866,9.754 11.3126,9.495 11.7846,9.025 L12.0516,8.759 L18.2926,15 L1.8696,15 L8.0516,8.818 Z M7.3476,8.109 L0.9996,14.456 L0.9996,1.713 L7.3476,8.109 Z M11.0796,8.316 C10.7956,8.599 10.4206,8.753 10.0206,8.753 L10.0166,8.753 C9.6156,8.752 9.2396,8.595 8.9566,8.31 L1.7016,1 L18.4326,1 L11.0796,8.316 Z M18.4996,0 L1.4996,0 C0.6726,0 -0.000400000004,0.673 -0.000400000004,1.5 L-0.000400000004,14.5 C-0.000400000004,15.327 0.6726,16 1.4996,16 L18.4996,16 C19.3276,16 19.9996,15.327 19.9996,14.5 L19.9996,1.5 C19.9996,0.673 19.3276,0 18.4996,0 L18.4996,0 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Message;
