import { Box, IconButton } from '@mui/material';
import ReactToPrint from 'react-to-print';
import { FormattedMessage } from 'react-intl';
import { ReactInstance } from 'react';
import { MAFTypography, Print } from '@asfi/maf';

import { styles } from './printQuote.styles';

interface IPrintQuoteProps {
  quoteRef?: ReactInstance;
  handlePrint: () => void;
  isOnlyIcon?: boolean;
}

const PrintQuote: React.FC<IPrintQuoteProps> = ({ quoteRef, handlePrint, isOnlyIcon }) => {
  const handleBeforeGetContent = () => {
    handlePrint();
    return Promise.resolve();
  };

  return (
    <ReactToPrint
      trigger={() => (
        <IconButton sx={[!isOnlyIcon && styles.iconButton]} size="large">
          <Box component={Print} sx={[!isOnlyIcon && styles.icon]} />
          {!isOnlyIcon && (
            <MAFTypography variant="body1">
              <FormattedMessage id="quote.print" />
            </MAFTypography>
          )}
        </IconButton>
      )}
      content={() => quoteRef || null}
      onBeforeGetContent={handleBeforeGetContent}
      onAfterPrint={handlePrint}
      removeAfterPrint
      pageStyle="@media print { html, body { margin: 0; }}"
    />
  );
};

export default PrintQuote;
