import { VEHICLE_TYPE_CAR } from '@northstar/core';
import { FILTER_VEHICLE_TYPES } from '@northstar/core-ui/modules';

export const AVAILABLE_FILTERS = {
  vehicle_types: [{ label: VEHICLE_TYPE_CAR, value: VEHICLE_TYPE_CAR }],
  brands: [],
  models: [],
  body_types: [],
  fuel_types: [],
  transmission_types: [],
  powertrains: [],
  engines: [],
  engine_power_hps: [],
};

export const FILTERS = Object.keys(AVAILABLE_FILTERS).filter(
  (filter) => filter !== FILTER_VEHICLE_TYPES,
);
