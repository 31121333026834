"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.responseMappers = exports.requestMappers = exports.formMappers = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _core = require("@northstar/core");
var _equipmentConstants = require("../equipmentConstants");
var objectUtils = _interopRequireWildcard(require("../../utils/object-utils"));
var _equipmentUtils = require("./equipmentUtils");
const _excluded = ["equipment_price"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const mapAssetEquipment = _ref => {
  let {
    equipment,
    preconfiguredEquipment,
    currentEquipment,
    withoutStandard,
    withoutRetrofit,
    enabled = true
  } = _ref;
  const mapSingleEquipment = item => {
    const {
        equipment_price: equipmentPriceObj = {}
      } = item,
      rest = (0, _objectWithoutProperties2.default)(item, _excluded);
    return objectUtils.objectToCamelCase({
      obj: _objectSpread(_objectSpread({}, rest), {}, {
        equipmentPrice: equipmentPriceObj.ex_vat,
        enabled
      })
    });
  };
  const eqWithPrice = equipment.map(mapSingleEquipment);
  const preWithPrice = preconfiguredEquipment === null || preconfiguredEquipment === void 0 ? void 0 : preconfiguredEquipment.map(mapSingleEquipment);
  const mappedEquipment = {
    standard: withoutStandard && currentEquipment && currentEquipment.standard ? currentEquipment.standard : eqWithPrice.filter(_ref2 => {
      let {
        category
      } = _ref2;
      return category === _equipmentConstants.EQUIPMENT_CATEGORY_STANDARD;
    }).sort((a, b) => a.id - b.id),
    additional: eqWithPrice.filter(_ref3 => {
      let {
        category,
        subCategory
      } = _ref3;
      return category === _equipmentConstants.EQUIPMENT_CATEGORY_ADDITIONAL || category === _equipmentConstants.EQUIPMENT_CATEGORY_PACKAGE || (0, _equipmentUtils.isInteriorColour)({
        category,
        subCategory
      });
    }),
    retrofit: withoutRetrofit && currentEquipment && currentEquipment.retrofit ? currentEquipment.retrofit : eqWithPrice.filter(_ref4 => {
      let {
        category
      } = _ref4;
      return category === _equipmentConstants.EQUIPMENT_CATEGORY_RETROFIT;
    }),
    colors: eqWithPrice.filter(_ref5 => {
      let {
        category,
        subCategory
      } = _ref5;
      return (0, _equipmentUtils.isExteriorColour)({
        category,
        subCategory
      });
    }),
    preconfigured: preWithPrice
  };
  if (currentEquipment) {
    Object.keys(mappedEquipment).forEach(key => {
      var _mappedEquipment$key;
      const equipmentIds = currentEquipment[key] ? currentEquipment[key].map(item => item.id) : [];
      const joinedObject = (_mappedEquipment$key = mappedEquipment[key]) === null || _mappedEquipment$key === void 0 ? void 0 : _mappedEquipment$key.filter(item => !equipmentIds.includes(item.id)).concat(currentEquipment[key] || []).sort((a, b) => a.id - b.id);
      mappedEquipment[key] = joinedObject;
    });
  }
  return mappedEquipment;
};
const extendCategory = _ref6 => {
  let {
    prev,
    curr,
    category
  } = _ref6;
  return _objectSpread(_objectSpread({}, prev), {}, {
    [category]: [...prev[category], curr]
  });
};
const mapEquipment = eq => Array.isArray(eq) ? eq.map(eqObj => objectUtils.objectToCamelCase({
  obj: eqObj
})) : objectUtils.objectToCamelCase({
  obj: eq
});
const mapEquipmentResponseFromConfiguration = _ref7 => {
  let {
    optional_equipment: optionalEquipment = [],
    included_equipment: includedEquipment = [],
    preconfigured_equipment: preconfiguredEquipment = []
  } = _ref7;
  return _objectSpread({
    standard: includedEquipment.sort((a, b) => a.id - b.id),
    preconfigured: preconfiguredEquipment.map(preEq => mapEquipment(preEq)).sort((a, b) => a.id - b.id)
  }, optionalEquipment.reduce((prev, curr) => {
    const {
      category
    } = curr;
    const mappedEq = mapEquipment(curr);
    switch (category) {
      case _equipmentConstants.EQUIPMENT_CATEGORY_ADDITIONAL:
      case _equipmentConstants.EQUIPMENT_CATEGORY_PACKAGE:
        return extendCategory({
          prev,
          curr: mappedEq,
          category: _equipmentConstants.EQUIPMENT_FORM_ADDITIONAL
        });
      case _equipmentConstants.EQUIPMENT_CATEGORY_RETROFIT:
        return extendCategory({
          prev,
          curr: mappedEq,
          category: _equipmentConstants.EQUIPMENT_FORM_RETROFIT
        });
      case _equipmentConstants.EQUIPMENT_CATEGORY_COLOUR:
        if ((0, _equipmentUtils.isInteriorColour)({
          category,
          subCategory: mappedEq.subCategory
        })) {
          return extendCategory({
            prev,
            curr: mappedEq,
            category: _equipmentConstants.EQUIPMENT_FORM_ADDITIONAL
          });
        }
        return extendCategory({
          prev,
          curr: mappedEq,
          category: _equipmentConstants.EQUIPMENT_FORM_COLORS
        });
      default:
        return prev;
    }
  }, {
    additional: [],
    retrofit: [],
    colors: []
  }));
};
const CATEGORY_FORM_SECTION_MAP = {
  [_equipmentConstants.EQUIPMENT_CATEGORY_ADDITIONAL]: _equipmentConstants.EQUIPMENT_FORM_ADDITIONAL,
  [_equipmentConstants.EQUIPMENT_CATEGORY_RETROFIT]: _equipmentConstants.EQUIPMENT_FORM_RETROFIT,
  [_equipmentConstants.EQUIPMENT_CATEGORY_PACKAGE]: _equipmentConstants.EQUIPMENT_FORM_ADDITIONAL,
  [_equipmentConstants.EQUIPMENT_CATEGORY_COLOUR]: _equipmentConstants.EQUIPMENT_FORM_COLORS
};
const mapEditableAdditionalAndColorsEq = _ref8 => {
  let {
    formValues = {},
    equipment: {
      additional,
      colors
    }
  } = _ref8;
  const additionalEnabled = additional.filter(_ref9 => {
    let {
      enabled
    } = _ref9;
    return enabled;
  });
  const colorsEnabled = colors.filter(_ref10 => {
    let {
      enabled
    } = _ref10;
    return enabled;
  });
  return additionalEnabled.concat(colorsEnabled).map(eq => {
    const {
      category,
      id
    } = eq;
    return {
      id,
      equipment_price: formValues["".concat(CATEGORY_FORM_SECTION_MAP[category], "-").concat(id)]
    };
  }).filter(_ref11 => {
    let {
      equipment_price: price
    } = _ref11;
    return !_core.ObjectUtils.isNullOrEmpty(price);
  });
};
const mapEquipmentToFormValues = equipment => equipment.filter(_ref12 => {
  let {
    enabled
  } = _ref12;
  return enabled;
}).map(_ref13 => {
  let {
    id,
    equipmentPrice
  } = _ref13;
  return {
    id,
    value: equipmentPrice
  };
});
const mapRetrofitToFormValues = _ref14 => {
  let {
    retrofit,
    isWithVAT
  } = _ref14;
  return retrofit.map(_ref15 => {
    let {
      id,
      equipmentPrice,
      name
    } = _ref15;
    return {
      id,
      value: {
        price: isWithVAT ? equipmentPrice.vatIncl : equipmentPrice.vatExcl,
        name
      }
    };
  });
};
const requestMappers = exports.requestMappers = {
  mapEditableAdditionalAndColorsEq
};
const responseMappers = exports.responseMappers = {
  mapAssetEquipment,
  mapEquipmentResponseFromConfiguration
};
const formMappers = exports.formMappers = {
  mapEquipmentToFormValues,
  mapRetrofitToFormValues
};
var _default = exports.default = {
  requestMappers,
  responseMappers,
  formMappers
};