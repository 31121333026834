"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    margin: '15px 0px'
  },
  disabledContainer: {},
  switch: {
    maxWidth: '60px',
    paddingRight: '5px'
  },
  title: {
    width: '160px',
    marginRight: '30px'
  },
  fieldLabel: {
    paddingRight: '5px'
  },
  disabledText: _ref => {
    let {
      colors
    } = _ref;
    return {
      color: colors.solid.lightRedGray
    };
  },
  rtl: {
    direction: 'rtl'
  },
  price: _ref2 => {
    let {
      typography: {
        main
      }
    } = _ref2;
    return {
      fontFamily: main,
      marginLeft: '40px',
      fontWeight: 'bold'
    };
  },
  text: _ref3 => {
    let {
      typography: {
        main
      }
    } = _ref3;
    return {
      fontFamily: main
    };
  },
  fullWidth: {
    width: '100%'
  }
};