"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.submitAsset = exports.hardDeleteAsset = exports.getCarInfo = exports.getAssetCommercial = exports.getAsset = exports.editPatchAsset = exports.deleteAsset = exports.checkAssetIdentity = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _requestHandler = _interopRequireDefault(require("../../utils/request-handler"));
const _excluded = ["vendorId"],
  _excluded2 = ["assetId"];
const {
  assetDomain
} = _requestHandler.default;
const getAsset = _ref => {
  let {
    assetId,
    assetVersionId,
    importerId,
    vendorId
  } = _ref;
  return assetDomain.GET_ASSET_PRIVATE.call({
    urlParams: {
      assetId,
      assetVersionId,
      importerId,
      vendorId
    }
  });
};
exports.getAsset = getAsset;
const getAssetCommercial = _ref2 => {
  let {
    assetId,
    assetVersionId,
    importerId,
    vendorId
  } = _ref2;
  return assetDomain.GET_ASSET_COMMERCIAL.call({
    urlParams: {
      assetId,
      assetVersionId,
      importerId,
      vendorId
    }
  });
};
exports.getAssetCommercial = getAssetCommercial;
const submitAsset = _ref3 => {
  let {
      vendorId
    } = _ref3,
    body = (0, _objectWithoutProperties2.default)(_ref3, _excluded);
  return assetDomain.CREATE.call({
    urlParams: {
      vendorId
    },
    body
  });
};
exports.submitAsset = submitAsset;
const editPatchAsset = _ref4 => {
  let {
      assetId
    } = _ref4,
    body = (0, _objectWithoutProperties2.default)(_ref4, _excluded2);
  return assetDomain.UPDATE_ASSET_PATCH.call({
    urlParams: {
      assetId
    },
    body
  });
};
exports.editPatchAsset = editPatchAsset;
const deleteAsset = exports.deleteAsset = editPatchAsset;
const hardDeleteAsset = _ref5 => {
  let {
    assetId,
    isCommercial
  } = _ref5;
  return assetDomain.DELETE_HARD_ASSET.call({
    urlParams: {
      assetId,
      isCommercial
    }
  });
};
exports.hardDeleteAsset = hardDeleteAsset;
const getCarInfo = _ref6 => {
  let {
    plateNumber,
    productType,
    importerId
  } = _ref6;
  return assetDomain.GET_ASSET_CAR_INFO.call({
    urlParams: {
      plateNumber,
      productType,
      lang: 'SE',
      importerId
    }
  });
};
exports.getCarInfo = getCarInfo;
const checkAssetIdentity = _ref7 => {
  let {
    assetId,
    isCommercial
  } = _ref7;
  return assetDomain.CHECK_ASSET_IDENTITY.call({
    urlParams: {
      assetId,
      isCommercial
    }
  });
};
exports.checkAssetIdentity = checkAssetIdentity;