import { useState, FC, ReactChild, MouseEvent, ChangeEvent } from 'react';
import { Table, TableBody, TablePagination, Box, SxProps, Theme } from '@mui/material';

import { handleCustomSx, useScreenSize } from '../../utils';

import {
  DeprecatedMAFTableRow,
  DeprecatedMAFTableRowMobile,
  DeprecatedMAFCell,
  DeprecatedMAFCellMobile,
  DeprecatedMAFTableHeader,
  DeprecatedMAFNoResults,
} from './components';
import {
  IDeprecatedMAFTableData,
  IDeprecatedMAFTableField,
  TDeprecatedMAFTableDirection,
} from './DeprecatedMAFTable.types';
import { styles } from './DeprecatedMAFTable.styles';
import { propTypes } from './DeprecatedMAFTable.propTypes';

export interface IDeprecatedMAFTableClasses {
  rowDesktop?: SxProps<Theme>;
  rowMobile?: SxProps<Theme>;
}

export interface IDeprecatedMAFTableProps {
  data: IDeprecatedMAFTableData[];
  fields: IDeprecatedMAFTableField[];
  onItemClick?: (item: IDeprecatedMAFTableData) => void;
  isWithPagination?: boolean;
  onOrder?: () => void;
  orderBy?: string;
  direction?: TDeprecatedMAFTableDirection;
  isFetching: boolean;
  isWithStickyHeader?: boolean;
  headerClass?: SxProps<Theme>;
  shouldAdjustHeaderPosition?: boolean;
  isUplift?: boolean;
  tableContentRef?: React.RefObject<HTMLTableElement> | (() => void);
  nsTarget?: string;
  isWithMobile?: boolean;
  noResultsMessage?: ReactChild;
  tableClasses?: IDeprecatedMAFTableClasses;
  tableContainerSx?: SxProps<Theme>;
}

type TGetCellValueParams = {
  item: IDeprecatedMAFTableData;
  name: string;
  subName?: string;
  fallbackName?: string;
};

const DeprecatedMAFTable: FC<IDeprecatedMAFTableProps> = ({
  data,
  fields,
  onItemClick,
  isWithPagination,
  onOrder,
  orderBy,
  direction,
  isFetching,
  isWithStickyHeader,
  headerClass,
  shouldAdjustHeaderPosition,
  isUplift,
  tableContentRef,
  nsTarget,
  isWithMobile,
  noResultsMessage,
  tableClasses,
  tableContainerSx,
}) => {
  const { isMobile } = useScreenSize();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: Nullable<MouseEvent<HTMLButtonElement>>, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCellValue = ({ item, name, subName, fallbackName }: TGetCellValueParams) => {
    if (subName) {
      return item[name]?.[subName] || (fallbackName && item[fallbackName]);
    }
    return item[name] || (fallbackName && item[fallbackName]);
  };

  const renderRow = (item: IDeprecatedMAFTableData) => {
    const RowComponent = isMobile ? DeprecatedMAFTableRowMobile : DeprecatedMAFTableRow;
    const CellComponent = isMobile ? DeprecatedMAFCellMobile : DeprecatedMAFCell;
    const tableClass = isMobile ? tableClasses?.rowMobile : tableClasses?.rowDesktop;
    return (
      <RowComponent
        key={item.id}
        onClick={() => onItemClick && onItemClick(item)}
        sx={tableClass}
        {...(isMobile ? { isUplift } : {})}
      >
        {fields.map(
          ({
            name,
            subName,
            fallbackName,
            component,
            shouldStopPropagation,
            renderFunc = ({ value }) => value,
          }) => {
            const renderedValue = renderFunc({
              value: getCellValue({ item, name, subName, fallbackName }),
              item,
            });

            return (
              <CellComponent
                key={name}
                {...item}
                isUplift={isUplift}
                shouldStopPropagation={shouldStopPropagation}
              >
                {component ? component({ value: renderedValue, isUplift }) : renderedValue}
              </CellComponent>
            );
          },
        )}
      </RowComponent>
    );
  };

  return isMobile && isWithMobile ? (
    <Box sx={[styles.tableContainer, ...handleCustomSx(tableContainerSx)]}>
      <Table ref={tableContentRef} sx={styles.tableRoot}>
        <TableBody>{data.map((item) => renderRow(item))}</TableBody>
      </Table>
      <DeprecatedMAFNoResults
        data={data}
        isFetching={isFetching}
        noResultsMessage={noResultsMessage}
      />
    </Box>
  ) : (
    <Box sx={[styles.tableContainer, ...handleCustomSx(tableContainerSx)]} ns-target={nsTarget}>
      <Table
        stickyHeader={isWithStickyHeader}
        ref={tableContentRef}
        sx={styles.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <DeprecatedMAFTableHeader
          onOrder={onOrder}
          orderBy={orderBy}
          direction={direction}
          labels={fields}
          sx={headerClass}
          isUplift={isUplift}
          shouldAdjustHeaderPosition={shouldAdjustHeaderPosition}
        />
        <TableBody>
          {(isWithPagination && rowsPerPage > 0
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
          ).map((item) => renderRow(item))}
        </TableBody>
      </Table>
      <DeprecatedMAFNoResults
        data={data}
        isFetching={isFetching}
        noResultsMessage={noResultsMessage}
      />
      {isWithPagination && (
        <TablePagination
          sx={styles.pagination}
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={20}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

DeprecatedMAFTable.propTypes = propTypes;

export default DeprecatedMAFTable;
