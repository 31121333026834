import { ReactChild, FC } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Box,
  CheckboxProps,
  SxProps,
  Theme,
  SvgIcon,
} from '@mui/material';

import { handleCustomSx } from '../../utils';
import MAFHelperText from '../MAFHelperText/MAFHelperText';
import { CheckMark } from '../Icons';

import { useStyles } from './MAFCheckbox.styles';
import { propTypes } from './MAFCheckbox.propTypes';

export type TPlacementType = 'bottom' | 'end' | 'top' | 'start';

export interface IMAFCheckboxProps extends Omit<CheckboxProps, 'color'> {
  isError?: boolean;
  helperText?: ReactChild;
  label?: ReactChild;
  labelClasses?: SxProps<Theme>;
  labelPlacement?: TPlacementType;
  sx?: SxProps<Theme>;
}

const MAFCheckbox: FC<IMAFCheckboxProps> = ({
  isError,
  label = '',
  labelClasses,
  disabled: isDisabled,
  labelPlacement = 'end',
  sx,
  helperText,
  checked: isChecked,
  ...rest
}) => {
  const styles = useStyles({ isError, isDisabled });
  return (
    <>
      <FormControlLabel
        sx={[styles.disabledLabel, styles.rootLabel, ...handleCustomSx(labelClasses)]}
        label={label} // if you want helper text, pass it as MAFTooltipLabel
        disabled={isDisabled}
        labelPlacement={labelPlacement}
        control={
          <Checkbox
            disabled={isDisabled}
            icon={<Box sx={styles.icon} />}
            checkedIcon={<SvgIcon component={CheckMark} sx={styles.checkedIcon} />}
            sx={[styles.colorSecondary, styles.root, ...handleCustomSx(sx)]}
            checked={isChecked}
            {...rest}
          />
        }
      />
      {helperText && (
        <Box sx={styles.error}>
          <MAFHelperText isError={isError}>{helperText}</MAFHelperText>
        </Box>
      )}
    </>
  );
};

MAFCheckbox.propTypes = propTypes;

export default MAFCheckbox;
