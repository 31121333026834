"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setVendorBrands = exports.setPreviousOptions = exports.setAvailableOptionsForField = exports.saveToHistory = exports.revertFields = exports.resetFields = exports.loadFromHistory = exports.initialState = exports.getInitialFrameOptionsRequest = exports.getFrameOptionsResponse = exports.getFrameOptionsRequest = exports.default = exports.clearState = exports.clearHistory = void 0;
var _core = require("@northstar/core");
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
const getFrameOptionsRequest = exports.getFrameOptionsRequest = (0, _reduxActions.createAction)('FRAME_FORM_GET_OPTIONS_REQUEST');
const getFrameOptionsResponse = exports.getFrameOptionsResponse = (0, _reduxActions.createAction)('FRAME_FORM_GET_OPTIONS_RESPONSE');
const getInitialFrameOptionsRequest = exports.getInitialFrameOptionsRequest = (0, _reduxActions.createAction)('FRAME_FORM_GET_INITIAL_OPTIONS_REQUEST');
const saveToHistory = exports.saveToHistory = (0, _reduxActions.createAction)('FRAME_FORM_SAVE_TO_HISTORY');
const loadFromHistory = exports.loadFromHistory = (0, _reduxActions.createAction)('FRAME_FORM_LOAD_FROM_HISTORY');
const clearHistory = exports.clearHistory = (0, _reduxActions.createAction)('FRAME_FORM_CLEAR_HISTORY');
const setAvailableOptionsForField = exports.setAvailableOptionsForField = (0, _reduxActions.createAction)('FRAME_FORM_SET_AVAILABLE_OPTIONS_FOR_FIELD');
const setPreviousOptions = exports.setPreviousOptions = (0, _reduxActions.createAction)('FRAME_FORM_SET_PREVIOUS_OPTIONS');
const revertFields = exports.revertFields = (0, _reduxActions.createAction)('FRAME_FORM_REVERT');
const resetFields = exports.resetFields = (0, _reduxActions.createAction)('FRAME_FORM_RESET');
const setVendorBrands = exports.setVendorBrands = (0, _reduxActions.createAction)('FRAME_FORM_SET_VENDOR_BRANDS');
const clearState = exports.clearState = (0, _reduxActions.createAction)('FRAME_FORM_CLEAR_STATE');
const initialState = exports.initialState = (0, _immutable.fromJS)({
  options: [],
  gettingFrameOptions: false,
  history: [],
  brandNames: [],
  modelNames: [],
  trimLevels: [],
  previousOptions: {},
  vendorBrands: []
});
var _default = exports.default = (0, _reduxActions.handleActions)({
  [(0, _reduxActions.combineActions)(getFrameOptionsRequest, getInitialFrameOptionsRequest)](state) {
    return state.set('gettingFrameOptions', true);
  },
  [getFrameOptionsResponse](state, _ref) {
    let {
      payload,
      error
    } = _ref;
    return !error && payload ? state.set('gettingFrameOptions', false).set('options', (0, _immutable.fromJS)(payload)) : state.set('gettingFrameOptions', false);
  },
  [saveToHistory](state, _ref2) {
    let {
      payload
    } = _ref2;
    if (_core.ObjectUtils.isNullOrEmpty(payload)) {
      return state;
    }
    const {
      form
    } = state.get('history').toJS().pop() || {
      form: {}
    };
    const historyLength = Object.keys(form).length;
    if (historyLength >= Object.keys(payload).length) {
      return state;
    }
    const history = (0, _immutable.fromJS)({
      form: payload
    });
    return state.update('history', list => list.push(history));
  },
  [loadFromHistory](state) {
    return state.update('history', history => history.pop());
  },
  [clearHistory](state) {
    return state.set('history', (0, _immutable.fromJS)([]));
  },
  [setAvailableOptionsForField](state, _ref3) {
    let {
      payload
    } = _ref3;
    const {
      field,
      values
    } = payload;
    return state.set(field, (0, _immutable.fromJS)(values));
  },
  [setPreviousOptions](state, _ref4) {
    let {
      payload
    } = _ref4;
    return state.set('previousOptions', (0, _immutable.fromJS)(payload));
  },
  [setVendorBrands](state, _ref5) {
    let {
      payload
    } = _ref5;
    return state.set('vendorBrands', (0, _immutable.fromJS)(payload));
  },
  [clearState](state) {
    const vendorBrands = state.get('vendorBrands').toJS();
    return initialState.set('vendorBrands', (0, _immutable.fromJS)(vendorBrands));
  }
}, initialState);