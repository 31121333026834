import { VEHICLE_TYPE_VAN } from '@northstar/core';
import {
  app,
  caseDetail,
  asset,
  EQUIPMENT_CATEGORY_ADDITIONAL,
  EQUIPMENT_CATEGORY_PACKAGE,
  EQUIPMENT_FORM_ADDITIONAL,
  EQUIPMENT_FORM_COLORS,
  CAR_CONFIGURATION_EQUIPMENT_FORM,
  EQUIPMENT_FORM_RETROFIT,
} from '@northstar/core-ui/modules';
import { change } from 'redux-form';
import { call, put, takeLatest, select, all, take } from 'redux-saga/effects';

import { selectVehicleType } from './assetSelectors';
import {
  getEquipmentRequest,
  getEquipmentResponse,
  enableEquipmentByIdWithPrice,
  enableEquipmentsByIds,
  prefillEditableRetrofit,
} from './equipmentReducer';
import { selectEquipment, selectEditableRetrofit } from './equipmentSelectors';
import { selectFrame } from './frameSelectors';
const { equipmentMappers } = asset;
const { responseMappers, formMappers } = equipmentMappers;

export function* loadEquipment({
  payload: { withoutRetrofit, withoutStandard, frameId: frame } = {},
} = {}) {
  try {
    const { id, frameVersionId } = yield select(selectFrame());
    const vehicleType = yield select(selectVehicleType());
    const frameId = frame || id;
    if (frameId > 0) {
      const apiCall =
        vehicleType === VEHICLE_TYPE_VAN ? asset.getFrameEquipmentVan : asset.getFrameEquipment;
      const response = yield call(apiCall, { frameId, frameVersionId });
      const currentEquipment = yield select(selectEquipment());
      yield put(
        getEquipmentResponse(
          responseMappers.mapAssetEquipment({
            equipment: response,
            preconfiguredEquipment: currentEquipment.preconfigured,
            currentEquipment,
            withoutRetrofit,
            withoutStandard,
            enabled: false,
          }),
        ),
      );
    }
  } catch (e) {
    yield put(app.displayError(e));
  }
}

export function* enableEquipments({ payload }) {
  yield all(
    Object.values(payload).map(({ id, category, equipmentPrice }) =>
      put(
        enableEquipmentByIdWithPrice({
          id,
          category:
            category === EQUIPMENT_CATEGORY_ADDITIONAL || category === EQUIPMENT_CATEGORY_PACKAGE
              ? EQUIPMENT_FORM_ADDITIONAL
              : EQUIPMENT_FORM_COLORS,
          equipmentPrice,
        }),
      ),
    ),
  );
}

export const prefillEquipmentCategory = ({ equipment, category }) =>
  all(
    equipment.map(({ id, value }) =>
      put(change(CAR_CONFIGURATION_EQUIPMENT_FORM, `${category}-${id}`, value)),
    ),
  );

export function* prefillEditableRetrofitHandler({ payload }) {
  try {
    // TODO: implement asset / case asset seperation
    if (payload.resourceId) {
      // Calling case again to prefill asset
      yield put(
        caseDetail.getCaseRequest({
          draftScenarioId: payload.draftScenarioId,
          resourceId: payload.resourceId,
          shouldStoreAsset: false,
        }),
      );
      yield take(caseDetail.getCaseResponse().type);
    }
    const { isWithVAT, action, actionPayload } = payload;
    const retrofit = yield select(selectEditableRetrofit());
    yield prefillEquipmentCategory({
      equipment: formMappers.mapRetrofitToFormValues({ retrofit, isWithVAT }),
      category: EQUIPMENT_FORM_RETROFIT,
    });
    if (typeof action === 'function') {
      yield call(action, actionPayload);
    }
  } catch (e) {
    yield put(app.displayError('errors.could_not_get_asset'));
  }
}

export default function* equipmentSagas() {
  yield takeLatest(getEquipmentRequest, loadEquipment);
  yield takeLatest(enableEquipmentsByIds, enableEquipments);
  yield takeLatest(prefillEditableRetrofit, prefillEditableRetrofitHandler);
}
