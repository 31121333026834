import {
  asset,
  EQUIPMENT_CATEGORY_RETROFIT,
  EQUIPMENT_FORM_RETROFIT,
  CAR_CONFIGURATION_EQUIPMENT_FORM,
} from '@northstar/core-ui/modules';
import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';
const { equipmentMappers } = asset;
const { requestMappers } = equipmentMappers;

const selectEquipmentState = (state) => state.asset.equipment;

export const selectEquipment = () =>
  createSelector(selectEquipmentState, (state) => state.get('equipment').toJS());

export const selectSelectedEquipment = () =>
  createSelector(selectEquipmentState, (state) => state.get('selected').toJS());

export const selectSelectedServices = () =>
  createSelector(selectSelectedEquipment(), (selected) => selected.services);

export const selectSelectedEquipmentWithServices = () =>
  createSelector(selectSelectedEquipment(), ({ optional, services }) => ({
    optional: Object.values(optional).map((item) => ({ price: item.equipmentPrice, ...item })),
    services,
  }));

export const selectEditableRetrofitFromForm = () =>
  createSelector(getFormValues(CAR_CONFIGURATION_EQUIPMENT_FORM), (formValues = {}) =>
    Object.keys(formValues).reduce((acc, key) => {
      if (key.includes(EQUIPMENT_FORM_RETROFIT)) {
        const { name, price } = formValues[key];
        const nameIsNotBlank = Boolean(name.trim());
        if (nameIsNotBlank) {
          return [
            ...acc,
            {
              package_name: name,
              equipment_price: price,
              category: EQUIPMENT_CATEGORY_RETROFIT,
            },
          ];
        }
      }
      return acc;
    }, []),
  );

export const selectEditableEquipmentFromCalculator = () =>
  createSelector(
    selectEquipment(),
    getFormValues(CAR_CONFIGURATION_EQUIPMENT_FORM),
    selectEditableRetrofitFromForm(),
    (equipment, formValues, retrofitEq) => ({
      optional_additional_equipment_packages: requestMappers.mapEditableAdditionalAndColorsEq({
        formValues,
        equipment,
      }),
      optional_retrofit_equipment_packages: retrofitEq,
    }),
  );

export const selectEditableRetrofit = () =>
  createSelector(selectEquipment(), ({ retrofit }) => retrofit.filter(({ dynamic }) => dynamic));
