import { ISxStyles } from '@asfi/maf';

export const styles: ISxStyles = {
  doubleButtons: ({ spacing }) => ({
    padding: spacing(8, 0, 4),
  }),
  button: ({ spacing }) => ({
    margin: spacing(8, 0, 4),
  }),
};
