import { ReactElement, FC, MouseEventHandler } from 'react';

import { IMAFButtonProps } from '../MAFButton/MAFButton';
import MAFDoubleButtons from '../MAFDoubleButtons/MAFDoubleButtons';
import MAFDialog, { IMAFDialogProps } from '../MAFDialog/MAFDialog';

import { propTypes } from './MAFConfirmDialog.propTypes';

export interface IMAFConfirmDialogProps extends IMAFDialogProps {
  title?: ReactElement | string;
  message?: ReactElement | string;
  onConfirm?: MouseEventHandler<HTMLButtonElement>;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  confirmButtonText: ReactElement | string;
  cancelButtonText: ReactElement | string;
  okButtonProps?: IMAFButtonProps;
  cancelButtonProps?: IMAFButtonProps;
  isVerticalButtons?: boolean;
}

const MAFConfirmDialog: FC<IMAFConfirmDialogProps> = ({
  title,
  message,
  isLoading,
  onConfirm,
  onCancel,
  onClose,
  confirmButtonText,
  cancelButtonText,
  okButtonProps,
  cancelButtonProps,
  isVerticalButtons,
  ...props
}) => (
  <MAFDialog {...props} isLoading={isLoading} title={title} message={message} onClose={onClose}>
    <MAFDoubleButtons
      isVertical={isVerticalButtons}
      primaryButtonProps={{
        disabled: isLoading,
        onClick: onConfirm,
        'ns-target': 'confirm-dialog-ok',
        ...okButtonProps,
      }}
      primaryButtonText={confirmButtonText}
      secondaryButtonProps={{
        disabled: isLoading,
        onClick: onCancel,
        ...cancelButtonProps,
      }}
      secondaryButtonText={cancelButtonText}
    />
  </MAFDialog>
);

MAFConfirmDialog.propTypes = propTypes;

export default MAFConfirmDialog;
