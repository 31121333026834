import { GlobalStyles } from '@mui/material';

import { propTypes } from './MAFGlobalStyle.propTypes';

const MAFGlobalStyle: React.FC = () => (
  <GlobalStyles
    styles={{
      html: {
        '-ms-overflow-style': '-ms-autohiding-scrollbar',
        '-ms-overflow-x': 'hidden',
      },
    }}
  />
);

MAFGlobalStyle.propTypes = propTypes;

export default MAFGlobalStyle;
