"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  primary: {
    fill: '#3a4344'
  },
  secondary: {
    opacity: 0.8
  },
  primaryNegative: {
    fill: '#fff'
  },
  secondaryNegative: {
    opacity: 0.8
  },
  container: {
    cursor: 'pointer',
    height: '50px',
    display: 'block'
  },
  containerImage: {
    cursor: 'pointer',
    display: 'block'
  }
};