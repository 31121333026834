const CloseOutlined: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-action/Something-doesnt-work/Darkblue">
        <g id="Icon" transform="translate(2.000000, 2.000000)">
          <path
            d="M13.8396,6.0964 C13.6446,5.9014 13.3276,5.9014 13.1326,6.0964 L9.9506,9.2784 L6.7686,6.0964 C6.5736,5.9014 6.2566,5.9014 6.0616,6.0964 C5.8666,6.2914 5.8666,6.6084 6.0616,6.8034 L9.2436,9.9854 L6.0616,13.1674 C5.8666,13.3624 5.8666,13.6794 6.0616,13.8744 C6.1596,13.9714 6.2876,14.0204 6.4156,14.0204 C6.5436,14.0204 6.6716,13.9714 6.7686,13.8744 L9.9506,10.6924 L13.1326,13.8744 C13.2306,13.9714 13.3586,14.0204 13.4856,14.0204 C13.6136,14.0204 13.7416,13.9714 13.8396,13.8744 C14.0346,13.6794 14.0346,13.3624 13.8396,13.1674 L10.6576,9.9854 L13.8396,6.8034 C14.0346,6.6084 14.0346,6.2914 13.8396,6.0964 M10,19 C5.037,19 1,14.963 1,10 C1,5.037 5.037,1 10,1 C14.963,1 19,5.037 19,10 C19,14.963 14.963,19 10,19 M10,0 C4.477,0 1.77635684e-15,4.477 1.77635684e-15,10 C1.77635684e-15,15.523 4.477,20 10,20 C15.523,20 20,15.523 20,10 C20,4.477 15.523,0 10,0"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default CloseOutlined;
