import { Grid } from '@mui/material';
import { FC, ReactChild, ReactElement } from 'react';

import MAFHighLightItem, {
  TMAFHighlightItem,
  IMAFHighLightItemProps,
} from '../MAFHighlightItem/MAFHighLightItem';

import { styles } from './MAFHighlightItems.styles';
import { propTypes } from './MAFHighlightItems.propTypes';

export type TMAFHighlightItemsFormatTextProps = {
  text: ReactChild;
  // Rules used in formatText function, e. g. isMoney: true;
  formatRules?: any;
};

export interface IMAFCustomHighlightItemProps extends Partial<IMAFHighLightItemProps> {
  formatRules?: any;
}

export interface IMAFCustomHighlightItems
  extends Partial<IMAFCustomHighlightItemProps>,
    TMAFHighlightItem {
  item?: TMAFHighlightItem;
}

export interface IMAFHighLightItemsProps {
  // Function to format highlightedItem, e. g. if(isMoney) return `${item} kr`;
  formatText?: (value: TMAFHighlightItemsFormatTextProps) => ReactElement | string;
  highlightedItems: IMAFCustomHighlightItems[];
}

const MAFHighLightItems: FC<IMAFHighLightItemsProps> = ({
  formatText = ({ text }) => text,
  highlightedItems,
}) => (
  <Grid container spacing={1}>
    {highlightedItems.map((item, index) => (
      <Grid key={index} sx={styles.gridItem} item xs={12} sm={6} md={3}>
        <MAFHighLightItem
          isWithEllipsis={item.isWithEllipsis}
          item={{
            label: item.label,
            text: formatText({ text: item.text, formatRules: item.formatRules }),
          }}
          isNumber={item.isNumber}
        />
      </Grid>
    ))}
  </Grid>
);

MAFHighLightItems.propTypes = propTypes;

export default MAFHighLightItems;
