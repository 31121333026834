"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sendQuoteDocumentResponse = exports.sendQuoteDocumentRequest = exports.sendDocumentResponse = exports.sendDocumentRequest = exports.sendAffordabilityQuoteDocumentResponse = exports.sendAffordabilityQuoteDocumentRequest = exports.initialState = exports.getDocumentResponse = exports.getDocumentRequest = exports.getDocumentAndDownloadResponse = exports.getDocumentAndDownloadRequest = exports.default = exports.createQuoteDocumentLinkRequest = exports.clearDocumentsState = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
var _documentsConstants = require("./documentsConstants");
const createQuoteDocumentLinkRequest = exports.createQuoteDocumentLinkRequest = (0, _reduxActions.createAction)('DOCUMENTS_CREATE_QUOTE_DOCUMENT_LINK_REQUEST');
const sendQuoteDocumentRequest = exports.sendQuoteDocumentRequest = (0, _reduxActions.createAction)('DOCUMENTS_SEND_QUOTE_DOCUMENT_REQUEST');
const sendQuoteDocumentResponse = exports.sendQuoteDocumentResponse = (0, _reduxActions.createAction)('DOCUMENTS_SEND_QUOTE_DOCUMENT_RESPONSE');
const sendAffordabilityQuoteDocumentRequest = exports.sendAffordabilityQuoteDocumentRequest = (0, _reduxActions.createAction)('DOCUMENTS_SEND_AFFORDABILITY_QUOTE_DOCUMENT_REQUEST');
const sendAffordabilityQuoteDocumentResponse = exports.sendAffordabilityQuoteDocumentResponse = (0, _reduxActions.createAction)('DOCUMENTS_SEND_AFFORDABILITY_QUOTE_DOCUMENT_RESPONSE');
const getDocumentRequest = exports.getDocumentRequest = (0, _reduxActions.createAction)('DOCUMENTS_GET_DOCUMENT_REQUEST');
const getDocumentResponse = exports.getDocumentResponse = (0, _reduxActions.createAction)('DOCUMENTS_GET_DOCUMENT_RESPONSE');
const clearDocumentsState = exports.clearDocumentsState = (0, _reduxActions.createAction)('DOCUMENTS_CLEAR_DOCUMENTS_STATE');
const sendDocumentRequest = exports.sendDocumentRequest = (0, _reduxActions.createAction)('DOCUMENTS_SEND_DOCUMENT_REQUEST');
const sendDocumentResponse = exports.sendDocumentResponse = (0, _reduxActions.createAction)('DOCUMENTS_SEND_DOCUMENT_RESPONSE');
const getDocumentAndDownloadRequest = exports.getDocumentAndDownloadRequest = (0, _reduxActions.createAction)('DOCUMENTS_GET_DOCUMENT_AND_DOWNLOAD_REQUEST');
const getDocumentAndDownloadResponse = exports.getDocumentAndDownloadResponse = (0, _reduxActions.createAction)('DOCUMENTS_GET_DOCUMENT_AND_DOWNLOAD_RESPONSE');
const initialState = exports.initialState = (0, _immutable.fromJS)({
  invoicePdfBinary: '',
  signingAgreementPdfBinary: '',
  signingAgreementPdfBinaryAppendix: '',
  proofOfDeliveryPaperPdfBinary: '',
  guarantorAgreementPdfBinary: '',
  isSendingDocument: false,
  isGettingDocument: false
});
const documentMap = {
  [_documentsConstants.DOCUMENT_TYPE_SIGNING_AGREEMENT]: 'signingAgreementPdfBinary',
  [_documentsConstants.DOCUMENT_TYPE_PROOF_OF_DELIVERY]: 'proofOfDeliveryPaperPdfBinary',
  [_documentsConstants.DOCUMENT_TYPE_VENDOR_INVOICE]: 'invoicePdfBinary',
  [_documentsConstants.DOCUMENT_TYPE_GUARANTOR_AGREEMENT]: 'guarantorAgreementPdfBinary'
};
var _default = exports.default = (0, _reduxActions.handleActions)({
  [sendDocumentRequest](state) {
    return state.set('isSendingDocument', true);
  },
  [sendDocumentResponse](state) {
    return state.set('isSendingDocument', false);
  },
  [getDocumentResponse](state, _ref) {
    let {
      payload,
      error
    } = _ref;
    const {
      type,
      content,
      appendix
    } = payload;
    const updatedState = appendix ? state.set("".concat(documentMap[type], "Appendix"), appendix.content) : state;
    return !error ? updatedState.set(documentMap[type], content) : state;
  },
  [getDocumentAndDownloadRequest](state) {
    return state.set('isGettingDocument', true);
  },
  [getDocumentAndDownloadResponse](state) {
    return state.set('isGettingDocument', false);
  },
  [clearDocumentsState]() {
    return initialState;
  }
}, initialState);