"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.verifyUser = exports.session = exports.selfOnboard = exports.logout = exports.loginInternal = exports.login = exports.finishHandshake = void 0;
var _axios = require("axios");
var _requestHandler = _interopRequireDefault(require("../../utils/request-handler"));
const {
  authDomain,
  userDomain
} = _requestHandler.default;
const session = () => authDomain.CHECK_SESSION.call();
exports.session = session;
const login = body => authDomain.LOGIN.call({
  body
});
exports.login = login;
const loginInternal = body => userDomain.AD_LOGIN.call({
  body
});
exports.loginInternal = loginInternal;
const logout = () => authDomain.LOGOUT.call();
exports.logout = logout;
const verifyUser = body => userDomain.VERIFY_USER.call({
  body
});
exports.verifyUser = verifyUser;
const finishHandshake = _ref => {
  let {
    redirectUrl
  } = _ref;
  return (0, _axios.get)(redirectUrl.includes('http://') || redirectUrl.includes('https://') ? redirectUrl : "redirect".concat(redirectUrl));
};
exports.finishHandshake = finishHandshake;
const selfOnboard = body => userDomain.REGENERATE_CONFIRMATION_TOKEN.call({
  body
});
exports.selfOnboard = selfOnboard;