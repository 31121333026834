"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  container: _ref => {
    let {
      fontSizes: {
        extraSmall
      }
    } = _ref;
    return {
      '&.MuiIconButton-root': {
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        outline: 'none',
        fontSize: extraSmall,
        borderRadius: '25%'
      }
    };
  },
  label: {
    '&.MuiIconButton-root': {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  icon: {
    height: 40,
    width: 40
  },
  text: _ref2 => {
    let {
      typography: {
        baseFont
      }
    } = _ref2;
    return _objectSpread({}, baseFont);
  },
  primaryPRIcon: _ref3 => {
    let {
      colors
    } = _ref3;
    return {
      '& g': {
        fill: colors.custom.primaryPR.solid.background,
        color: colors.custom.primaryPR.solid.background
      }
    };
  },
  primaryCMIcon: _ref4 => {
    let {
      colors
    } = _ref4;
    return {
      '& g': {
        fill: colors.custom.primaryCM.solid.background,
        color: colors.custom.primaryCM.solid.background
      }
    };
  },
  redIcon: _ref5 => {
    let {
      colors
    } = _ref5;
    return {
      '& g': {
        fill: colors.solid.red,
        color: colors.solid.red
      }
    };
  },
  greenIcon: _ref6 => {
    let {
      colors
    } = _ref6;
    return {
      '& g': {
        fill: colors.solid.green,
        color: colors.solid.green
      }
    };
  },
  primaryPRText: _ref7 => {
    let {
      colors
    } = _ref7;
    return {
      color: colors.custom.primaryPR.solid.background
    };
  },
  primaryCMText: _ref8 => {
    let {
      colors
    } = _ref8;
    return {
      color: colors.custom.primaryCM.solid.background
    };
  },
  redText: _ref9 => {
    let {
      colors
    } = _ref9;
    return {
      color: colors.solid.red
    };
  },
  greenText: _ref10 => {
    let {
      colors
    } = _ref10;
    return {
      color: colors.solid.green
    };
  },
  inactiveIcon: _ref11 => {
    let {
      colors
    } = _ref11;
    return {
      '& g': {
        fill: colors.solid.gray,
        color: colors.solid.gray
      }
    };
  },
  inactiveText: _ref12 => {
    let {
      colors
    } = _ref12;
    return {
      color: colors.solid.gray
    };
  },
  tooltip: {
    maxWidth: 500
  }
};