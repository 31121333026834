"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREDIT_APPLICATION_STATUS = exports.DOCUMENT_STATUS = exports.CASE_STAGE = exports.CASE_STATUS = exports.NORTHSTAR_CASE_PREFIX = exports.VALIDITY_NOT_EXPIRED = exports.VALIDITY_VALID = exports.VALIDITY_ALL = exports.PROCESS_VARIANCES_VALUES = exports.AVAILABLE_PROCESS_VARIANCES = exports.PROCESS_VARIANCES = exports.INTERNAL_PROJECT_NAME = exports.EXTERNAL_PROJECT_NAME = exports.ASSET_STATUS_DEPRECATED = exports.ASSET_STATUS_DECLINED = exports.ASSET_STATUS_PENDING = exports.ASSET_STATUS_APPROVED = exports.FUNDING_TYPE_ALLMAN = exports.FUNDING_TYPE_STIBOR3 = exports.VEHICLE_TYPE_VAN = exports.VEHICLE_TYPE_CAR = exports.VENDOR_TYPE_IMPORTER = exports.VENDOR_TYPE_DEALERSHIP = exports.VENDOR_TYPE_STANDALONE_VENDOR = exports.DEMO_TOKEN_ID = exports.IDENTITY_HEADER = exports.PRODUCT_SUB_TYPE_FINANCIAL_LEASE = exports.PRODUCT_SUB_TYPE_HIRE_PURCHASE = exports.PRODUCT_SUB_TYPE_OPERATIONAL_LEASE = exports.PRODUCT_TYPE_COMMERCIAL = exports.PRODUCT_TYPE_PRIVATE = exports.EXTERNAL_ROLES = exports.INTERNAL_ROLES = exports.USER_ROLE_SUB_TYPE_READONLY = exports.USER_ROLE_SUB_TYPE_DEFAULT = exports.USER_ROLE_ASSET_ADMINISTRATOR = exports.USER_ROLE_FINANCE = exports.USER_ROLE_OPERATIONS = exports.USER_ROLE_ASSETS = exports.USER_ROLE_VENDOR_EMPLOYEE = exports.USER_ROLE_VENDOR_ADMIN = exports.USER_ROLE_ONBOARDER = exports.IT_SUPPORT_NORTHSTAR_EMAIL = exports.HTTP_METHODS = exports.KYC_API_BASE = exports.CASE_HANDLER_API_BASE = exports.ASSET_API_BASE = exports.AUTH_API_BASE = exports.SELF_API_BASE = exports.RouteConstants = void 0;
exports.CASE_STATUS_MAP = exports.SIGNEE_TYPES = void 0;
var RouteConstants_1 = require("./RouteConstants/RouteConstants");
Object.defineProperty(exports, "RouteConstants", { enumerable: true, get: function () { return RouteConstants_1.default; } });
Object.defineProperty(exports, "SELF_API_BASE", { enumerable: true, get: function () { return RouteConstants_1.SELF_API_BASE; } });
Object.defineProperty(exports, "AUTH_API_BASE", { enumerable: true, get: function () { return RouteConstants_1.AUTH_API_BASE; } });
Object.defineProperty(exports, "ASSET_API_BASE", { enumerable: true, get: function () { return RouteConstants_1.ASSET_API_BASE; } });
Object.defineProperty(exports, "CASE_HANDLER_API_BASE", { enumerable: true, get: function () { return RouteConstants_1.CASE_HANDLER_API_BASE; } });
Object.defineProperty(exports, "KYC_API_BASE", { enumerable: true, get: function () { return RouteConstants_1.KYC_API_BASE; } });
Object.defineProperty(exports, "HTTP_METHODS", { enumerable: true, get: function () { return RouteConstants_1.HTTP_METHODS; } });
exports.IT_SUPPORT_NORTHSTAR_EMAIL = 'it.support.northstar@danskebank.se';
exports.USER_ROLE_ONBOARDER = 'USER_ROLE_ONBOARDER';
exports.USER_ROLE_VENDOR_ADMIN = 'USER_ROLE_VENDOR_ADMIN';
exports.USER_ROLE_VENDOR_EMPLOYEE = 'USER_ROLE_VENDOR_EMPLOYEE';
exports.USER_ROLE_ASSETS = 'USER_ROLE_ASSETS';
exports.USER_ROLE_OPERATIONS = 'USER_ROLE_OPERATIONS';
exports.USER_ROLE_FINANCE = 'USER_ROLE_FINANCE';
exports.USER_ROLE_ASSET_ADMINISTRATOR = 'USER_ROLE_ASSET_ADMINISTRATOR';
exports.USER_ROLE_SUB_TYPE_DEFAULT = 'DEFAULT';
exports.USER_ROLE_SUB_TYPE_READONLY = 'READONLY';
exports.INTERNAL_ROLES = [
    exports.USER_ROLE_ASSET_ADMINISTRATOR,
    exports.USER_ROLE_FINANCE,
    exports.USER_ROLE_ONBOARDER,
    exports.USER_ROLE_OPERATIONS,
];
exports.EXTERNAL_ROLES = [exports.USER_ROLE_VENDOR_ADMIN, exports.USER_ROLE_VENDOR_EMPLOYEE];
exports.PRODUCT_TYPE_PRIVATE = 'PRIVATE';
exports.PRODUCT_TYPE_COMMERCIAL = 'COMMERCIAL';
exports.PRODUCT_SUB_TYPE_OPERATIONAL_LEASE = 'OL';
exports.PRODUCT_SUB_TYPE_HIRE_PURCHASE = 'HP';
exports.PRODUCT_SUB_TYPE_FINANCIAL_LEASE = 'FL';
exports.IDENTITY_HEADER = 'X-Identity-Id';
exports.DEMO_TOKEN_ID = 'demo-purposes-token';
exports.VENDOR_TYPE_STANDALONE_VENDOR = 'STANDALONE';
exports.VENDOR_TYPE_DEALERSHIP = 'DEALERSHIP';
exports.VENDOR_TYPE_IMPORTER = 'IMPORTER';
exports.VEHICLE_TYPE_CAR = 'CAR';
exports.VEHICLE_TYPE_VAN = 'VAN';
exports.FUNDING_TYPE_STIBOR3 = 'STIBOR3';
exports.FUNDING_TYPE_ALLMAN = 'ALLMAN';
exports.ASSET_STATUS_APPROVED = 'APPROVED';
exports.ASSET_STATUS_PENDING = 'PENDING';
exports.ASSET_STATUS_DECLINED = 'DECLINED';
exports.ASSET_STATUS_DEPRECATED = 'DEPRECATED';
exports.EXTERNAL_PROJECT_NAME = 'external';
exports.INTERNAL_PROJECT_NAME = 'internal';
var PROCESS_VARIANCES;
(function (PROCESS_VARIANCES) {
    PROCESS_VARIANCES["USE_SELF_INVOICING"] = "USE_SELF_INVOICING";
    PROCESS_VARIANCES["USE_AUTOMATIC_LEASING_CORE_PAYOUT"] = "USE_AUTOMATIC_LEASING_CORE_PAYOUT";
    PROCESS_VARIANCES["USE_INSURANCE"] = "USE_INSURANCE";
    PROCESS_VARIANCES["PRIVATE_OL"] = "PRIVATE_OL";
    PROCESS_VARIANCES["PRIVATE_HP"] = "PRIVATE_HP";
    PROCESS_VARIANCES["COMMERCIAL_OL"] = "COMMERCIAL_OL";
    PROCESS_VARIANCES["COMMERCIAL_FL"] = "COMMERCIAL_FL";
})(PROCESS_VARIANCES = exports.PROCESS_VARIANCES || (exports.PROCESS_VARIANCES = {}));
exports.AVAILABLE_PROCESS_VARIANCES = Object.values(PROCESS_VARIANCES);
var PROCESS_VARIANCES_VALUES;
(function (PROCESS_VARIANCES_VALUES) {
    PROCESS_VARIANCES_VALUES["PROCESS_VARIANCE_ENABLED"] = "YES";
    PROCESS_VARIANCES_VALUES["PROCESS_VARIANCE_DISABLED"] = "NO";
})(PROCESS_VARIANCES_VALUES = exports.PROCESS_VARIANCES_VALUES || (exports.PROCESS_VARIANCES_VALUES = {}));
exports.VALIDITY_ALL = 'ALL';
exports.VALIDITY_VALID = 'VALID';
exports.VALIDITY_NOT_EXPIRED = 'NOT_EXPIRED';
exports.NORTHSTAR_CASE_PREFIX = 'NORTHSTAR_';
var CASE_STATUS;
(function (CASE_STATUS) {
    CASE_STATUS["IN_PROGRESS"] = "IN_PROGRESS";
    CASE_STATUS["CANCELLED"] = "CANCELLED";
    CASE_STATUS["CUSTOMER_DATA_GATHERING"] = "CUSTOMER_DATA_GATHERING";
    CASE_STATUS["CREDIT_AWAITING_AUTOMATIC_DECISION"] = "CREDIT_AWAITING_AUTOMATIC_DECISION";
    CASE_STATUS["CREDIT_AWAITING_MANUAL_DECISION"] = "CREDIT_AWAITING_MANUAL_DECISION";
    CASE_STATUS["CREDIT_REJECTED"] = "CREDIT_REJECTED";
    CASE_STATUS["CREDIT_AUTO_REJECTED"] = "CREDIT_AUTO_REJECTED";
    CASE_STATUS["CREDIT_APPROVED"] = "CREDIT_APPROVED";
    CASE_STATUS["CREDIT_CONDITIONALLY_APPROVED"] = "CREDIT_CONDITIONALLY_APPROVED";
    CASE_STATUS["CREDIT_AGREEMENT_CANCELLED"] = "CREDIT_AGREEMENT_CANCELLED";
    CASE_STATUS["CREDIT_AGREEMENT_EXPIRED"] = "CREDIT_AGREEMENT_EXPIRED";
    CASE_STATUS["CREDIT_AGREEMENT_AWAITING_SIGNING"] = "CREDIT_AGREEMENT_AWAITING_SIGNING";
    CASE_STATUS["GUARANTOR_DOCUMENT_CANCELLED"] = "GUARANTOR_DOCUMENT_CANCELLED";
    CASE_STATUS["GUARANTOR_DOCUMENT_EXPIRED"] = "GUARANTOR_DOCUMENT_EXPIRED";
    CASE_STATUS["DOCUMENTS_AWAITING_SIGNING"] = "DOCUMENTS_AWAITING_SIGNING";
    CASE_STATUS["DOCUMENTS_CANCELED"] = "DOCUMENTS_CANCELED";
    CASE_STATUS["DOCUMENTS_EXPIRED"] = "DOCUMENTS_EXPIRED";
    CASE_STATUS["GUARANTOR_DOCUMENT_AWAITING_SIGNING"] = "GUARANTOR_DOCUMENT_AWAITING_SIGNING";
    CASE_STATUS["CREDIT_AGREEMENT_SIGNED_PR_HP"] = "CREDIT_AGREEMENT_SIGNED_PR_HP";
    CASE_STATUS["AWAITING_ASSET"] = "AWAITING_ASSET";
    CASE_STATUS["DELIVERY_AGREEMENT_CANCELLED"] = "DELIVERY_AGREEMENT_CANCELLED";
    CASE_STATUS["DELIVERY_AGREEMENT_EXPIRED"] = "DELIVERY_AGREEMENT_EXPIRED";
    CASE_STATUS["DELIVERY_AGREEMENT_AWAITING_SIGNING"] = "DELIVERY_AGREEMENT_AWAITING_SIGNING";
    CASE_STATUS["AWAITING_REFERENCE_NUMBER"] = "AWAITING_REFERENCE_NUMBER";
    CASE_STATUS["AGREEMENT_ACTIVATED"] = "AGREEMENT_ACTIVATED";
    CASE_STATUS["UNKNOWN_STATUS"] = "UNKNOWN_STATUS";
    CASE_STATUS["PREPARING_PROOF_OF_DELIVERY"] = "PREPARING_PROOF_OF_DELIVERY";
    CASE_STATUS["FINANCIER_AWAITING_ASSET_DELIVERED"] = "FINANCIER_AWAITING_ASSET_DELIVERED";
})(CASE_STATUS = exports.CASE_STATUS || (exports.CASE_STATUS = {}));
var CASE_STAGE;
(function (CASE_STAGE) {
    CASE_STAGE["INITIAL"] = "INITIAL";
    CASE_STAGE["CANCELLED"] = "CANCELLED";
    CASE_STAGE["CREDIT_DECISION"] = "CREDIT_DECISION";
    CASE_STAGE["AGREEMENT_SIGNING"] = "AGREEMENT_SIGNING";
    CASE_STAGE["AGREEMENT_CANCELLED"] = "AGREEMENT_CANCELLED";
    CASE_STAGE["AWAITING_ASSET"] = "AWAITING_ASSET";
    CASE_STAGE["PROOF_OF_DELIVERY_SIGNING"] = "PROOF_OF_DELIVERY_SIGNING";
    CASE_STAGE["AWAITING_FINANCIER"] = "AWAITING_FINANCIER";
    CASE_STAGE["FINANCIER_VERIFIED"] = "FINANCIER_VERIFIED";
    CASE_STAGE["CUSTOMER_DATA_GATHERING"] = "CUSTOMER_DATA_GATHERING";
})(CASE_STAGE = exports.CASE_STAGE || (exports.CASE_STAGE = {}));
var DOCUMENT_STATUS;
(function (DOCUMENT_STATUS) {
    DOCUMENT_STATUS["GENERATED"] = "GENERATED";
    DOCUMENT_STATUS["REJECTED"] = "REJECTED";
    DOCUMENT_STATUS["AWAITING_SIGNING"] = "AWAITING_SIGNING";
    DOCUMENT_STATUS["SIGNED"] = "SIGNED";
    DOCUMENT_STATUS["EXPIRED"] = "EXPIRED";
    DOCUMENT_STATUS["CANCELLED"] = "CANCELLED";
    DOCUMENT_STATUS["ACCEPTED"] = "ACCEPTED";
})(DOCUMENT_STATUS = exports.DOCUMENT_STATUS || (exports.DOCUMENT_STATUS = {}));
var CREDIT_APPLICATION_STATUS;
(function (CREDIT_APPLICATION_STATUS) {
    CREDIT_APPLICATION_STATUS["PENDING"] = "PENDING";
    CREDIT_APPLICATION_STATUS["ERROR"] = "ERROR";
    CREDIT_APPLICATION_STATUS["AUTO_APPROVAL"] = "AUTO_APPROVAL";
    CREDIT_APPLICATION_STATUS["AUTO_DECLINE"] = "AUTO_DECLINE";
    CREDIT_APPLICATION_STATUS["STRICT_DECLINE"] = "STRICT_DECLINE";
    CREDIT_APPLICATION_STATUS["MANUAL_APPROVAL"] = "MANUAL_APPROVAL";
    CREDIT_APPLICATION_STATUS["MANUAL_DECLINE"] = "MANUAL_DECLINE";
    CREDIT_APPLICATION_STATUS["SUBMITTED_FOR_MANUAL"] = "SUBMITTED_FOR_MANUAL";
    CREDIT_APPLICATION_STATUS["CONDITIONAL_APPROVAL"] = "CONDITIONAL_APPROVAL";
})(CREDIT_APPLICATION_STATUS = exports.CREDIT_APPLICATION_STATUS || (exports.CREDIT_APPLICATION_STATUS = {}));
var SIGNEE_TYPES;
(function (SIGNEE_TYPES) {
    SIGNEE_TYPES["APPLICANT"] = "APPLICANT";
    SIGNEE_TYPES["CO_APPLICANT"] = "CO_APPLICANT";
})(SIGNEE_TYPES = exports.SIGNEE_TYPES || (exports.SIGNEE_TYPES = {}));
exports.CASE_STATUS_MAP = (_a = {},
    _a[CASE_STATUS.IN_PROGRESS] = [
        {
            stage: CASE_STAGE.INITIAL,
        },
    ],
    _a[CASE_STATUS.CANCELLED] = [
        {
            stage: CASE_STAGE.CANCELLED,
        },
    ],
    _a[CASE_STATUS.CUSTOMER_DATA_GATHERING] = [
        {
            stage: CASE_STAGE.CUSTOMER_DATA_GATHERING,
        },
    ],
    _a[CASE_STATUS.CREDIT_AWAITING_AUTOMATIC_DECISION] = [
        {
            stage: CASE_STAGE.CREDIT_DECISION,
            credit_application_status: CREDIT_APPLICATION_STATUS.PENDING,
        },
    ],
    _a[CASE_STATUS.CREDIT_CONDITIONALLY_APPROVED] = [
        {
            stage: CASE_STAGE.CREDIT_DECISION,
            credit_application_status: CREDIT_APPLICATION_STATUS.CONDITIONAL_APPROVAL,
        },
    ],
    _a[CASE_STATUS.CREDIT_AWAITING_MANUAL_DECISION] = [
        {
            stage: CASE_STAGE.CREDIT_DECISION,
            credit_application_status: CREDIT_APPLICATION_STATUS.SUBMITTED_FOR_MANUAL,
        },
    ],
    _a[CASE_STATUS.CREDIT_REJECTED] = [
        {
            stage: CASE_STAGE.CREDIT_DECISION,
            credit_application_status: CREDIT_APPLICATION_STATUS.MANUAL_DECLINE,
        },
        {
            stage: CASE_STAGE.CREDIT_DECISION,
            credit_application_status: CREDIT_APPLICATION_STATUS.ERROR,
        },
        {
            stage: CASE_STAGE.CREDIT_DECISION,
            credit_application_status: CREDIT_APPLICATION_STATUS.STRICT_DECLINE,
        },
    ],
    _a[CASE_STATUS.CREDIT_AUTO_REJECTED] = [
        {
            stage: CASE_STAGE.CREDIT_DECISION,
            credit_application_status: CREDIT_APPLICATION_STATUS.AUTO_DECLINE,
        },
    ],
    _a[CASE_STATUS.CREDIT_APPROVED] = [
        {
            stage: CASE_STAGE.CREDIT_DECISION,
            credit_application_status: CREDIT_APPLICATION_STATUS.MANUAL_APPROVAL,
        },
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.GENERATED,
        },
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.GENERATED,
            guarantor_agreement_status: DOCUMENT_STATUS.GENERATED,
        },
    ],
    _a[CASE_STATUS.DOCUMENTS_CANCELED] = [
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.AWAITING_SIGNING,
            guarantor_agreement_status: DOCUMENT_STATUS.CANCELLED,
        },
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.AWAITING_SIGNING,
            guarantor_agreement_status: DOCUMENT_STATUS.REJECTED,
        },
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.CANCELLED,
            guarantor_agreement_status: DOCUMENT_STATUS.AWAITING_SIGNING,
        },
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.REJECTED,
            guarantor_agreement_status: DOCUMENT_STATUS.AWAITING_SIGNING,
        },
    ],
    _a[CASE_STATUS.DOCUMENTS_EXPIRED] = [
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.AWAITING_SIGNING,
            guarantor_agreement_status: DOCUMENT_STATUS.EXPIRED,
        },
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.EXPIRED,
            guarantor_agreement_status: DOCUMENT_STATUS.AWAITING_SIGNING,
        },
    ],
    _a[CASE_STATUS.GUARANTOR_DOCUMENT_AWAITING_SIGNING] = [
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.SIGNED,
            guarantor_agreement_status: DOCUMENT_STATUS.AWAITING_SIGNING,
        },
    ],
    _a[CASE_STATUS.DOCUMENTS_AWAITING_SIGNING] = [
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.AWAITING_SIGNING,
            guarantor_agreement_status: DOCUMENT_STATUS.AWAITING_SIGNING,
        },
    ],
    _a[CASE_STATUS.CREDIT_AGREEMENT_AWAITING_SIGNING] = [
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.AWAITING_SIGNING,
        },
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.AWAITING_SIGNING,
            guarantor_agreement_status: DOCUMENT_STATUS.SIGNED,
        },
    ],
    _a[CASE_STATUS.CREDIT_AGREEMENT_CANCELLED] = [
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.CANCELLED,
        },
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.REJECTED,
        },
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.CANCELLED,
            guarantor_agreement_status: DOCUMENT_STATUS.SIGNED,
        },
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.REJECTED,
            guarantor_agreement_status: DOCUMENT_STATUS.SIGNED,
        },
    ],
    _a[CASE_STATUS.GUARANTOR_DOCUMENT_CANCELLED] = [
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.SIGNED,
            guarantor_agreement_status: DOCUMENT_STATUS.CANCELLED,
        },
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.SIGNED,
            guarantor_agreement_status: DOCUMENT_STATUS.REJECTED,
        },
    ],
    _a[CASE_STATUS.GUARANTOR_DOCUMENT_EXPIRED] = [
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.SIGNED,
            guarantor_agreement_status: DOCUMENT_STATUS.EXPIRED,
        },
    ],
    _a[CASE_STATUS.CREDIT_AGREEMENT_EXPIRED] = [
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.EXPIRED,
        },
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.EXPIRED,
            guarantor_agreement_status: DOCUMENT_STATUS.SIGNED,
        },
    ],
    _a[CASE_STATUS.PREPARING_PROOF_OF_DELIVERY] = [
        {
            stage: CASE_STAGE.AWAITING_ASSET,
            contract_processed: false,
        },
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.SIGNED,
            guarantor_agreement_status: DOCUMENT_STATUS.SIGNED,
            contract_processed: false,
        },
    ],
    _a[CASE_STATUS.AWAITING_ASSET] = [
        {
            stage: CASE_STAGE.AWAITING_ASSET,
            contract_processed: true,
        },
        {
            stage: CASE_STAGE.AGREEMENT_SIGNING,
            signing_agreement_status: DOCUMENT_STATUS.SIGNED,
            guarantor_agreement_status: DOCUMENT_STATUS.SIGNED,
            contract_processed: true,
        },
        {
            stage: CASE_STAGE.PROOF_OF_DELIVERY_SIGNING,
            proof_of_delivery_paper_status: DOCUMENT_STATUS.GENERATED,
        },
    ],
    _a[CASE_STATUS.CREDIT_AGREEMENT_SIGNED_PR_HP] = [
        {
            stage: CASE_STAGE.AWAITING_FINANCIER,
            asset_delivered: false,
        },
    ],
    _a[CASE_STATUS.DELIVERY_AGREEMENT_CANCELLED] = [
        {
            stage: CASE_STAGE.PROOF_OF_DELIVERY_SIGNING,
            proof_of_delivery_paper_status: DOCUMENT_STATUS.CANCELLED,
        },
        {
            stage: CASE_STAGE.PROOF_OF_DELIVERY_SIGNING,
            proof_of_delivery_paper_status: DOCUMENT_STATUS.REJECTED,
        },
    ],
    _a[CASE_STATUS.DELIVERY_AGREEMENT_EXPIRED] = [
        {
            stage: CASE_STAGE.PROOF_OF_DELIVERY_SIGNING,
            proof_of_delivery_paper_status: DOCUMENT_STATUS.EXPIRED,
        },
    ],
    _a[CASE_STATUS.DELIVERY_AGREEMENT_AWAITING_SIGNING] = [
        {
            stage: CASE_STAGE.PROOF_OF_DELIVERY_SIGNING,
            proof_of_delivery_paper_status: DOCUMENT_STATUS.AWAITING_SIGNING,
        },
    ],
    _a[CASE_STATUS.AWAITING_REFERENCE_NUMBER] = [
        {
            stage: CASE_STAGE.AWAITING_FINANCIER,
            asset_delivered: true,
            use_self_invoicing: true,
            self_invoice_ref_num_exists: false,
        },
    ],
    _a[CASE_STATUS.FINANCIER_AWAITING_ASSET_DELIVERED] = [
        {
            stage: CASE_STAGE.AWAITING_FINANCIER,
            asset_delivered: true,
        },
    ],
    _a[CASE_STATUS.AGREEMENT_ACTIVATED] = [
        {
            stage: CASE_STAGE.FINANCIER_VERIFIED,
        },
    ],
    _a[CASE_STATUS.UNKNOWN_STATUS] = [],
    _a);
