import { alpha } from '@mui/material/styles';

import { FILE_IMAGE_BASE } from './constants';
import { mainColors } from './common';

export const PUBLIC_DEFAULT_IMAGE_PATH = `${FILE_IMAGE_BASE}/theming/DanskeBank/`;

export const CUSTOM_LOGO = `${PUBLIC_DEFAULT_IMAGE_PATH}danske_bank_logo.png`;

export const baseThemeColors = {
  backgrounds: {
    background: '#FBFDFE',
    backgroundPosition: 'center',
    gradient: `linear-gradient(${alpha('#FBFDFE', 0)} 80%, ${alpha('#FBFDFE', 1)} 100%)`,
  },
  carCards: {
    gradients: {
      carCard: 'linear-gradient(180deg, #E5EBEE 0%, rgba(229,235,238,0) 100%)',
      carCardHover: 'linear-gradient(180deg, #E5EBEE 0%, #E5EBEE 30%)',
      carRow: 'linear-gradient(180deg, #E5EBEE 0%, rgba(229,235,238,0) 100%)',
      carRowHover: alpha(mainColors.solid.white, 0.6),
      carCase: 'linear-gradient(180deg, #E5EBEE 0%, rgba(229,235,238,0.4) 100%)',
    },
    solidBackground: '#FBFDFE',
    header: mainColors.solid.cadedBlue,
    primary: mainColors.solid.cadedBlue,
  },
  caseImage: {
    primary: mainColors.solid.cadedBlue,
    background: 'linear-gradient(180deg, #E5EBEE 0%, rgba(229,235,238,0) 100%)',
    solidBackground: '#FBFDFE',
  },
  primaryPR: {
    highlight: {
      background: 'linear-gradient(180deg, #004163 0%, #00304A 100%)',
      color: mainColors.solid.white,
    },
    action: {
      background: 'linear-gradient(0deg, #0097CF 0%, #00AAE8 100%)',
      color: mainColors.solid.white,
      border: '1px solid #37B0DE',
      '&:active': {
        transition: 'none',
        background: mainColors.solid.blue,
      },
    },
    solid: {
      background: '#71BBCE',
      color: mainColors.solid.white,
    },
    chip: {
      background: mainColors.solid.blue,
      color: mainColors.solid.white,
    },
  },
  primaryCM: {
    highlight: {
      background: 'linear-gradient(180deg, #004163 0%, #00304A 100%)',
      color: mainColors.solid.white,
    },
    action: {
      background: 'linear-gradient(0deg, #0097CF 0%, #00AAE8 100%)',
      color: mainColors.solid.white,
      border: '1px solid #37B0DE',
      '&:active': {
        transition: 'none',
        background: mainColors.solid.blue,
      },
    },
    solid: {
      background: '#456B80',
      color: mainColors.solid.white,
    },
    chip: {
      background: mainColors.solid.blue,
      color: mainColors.solid.white,
    },
  },
  disabled: {
    color: mainColors.solid.cadedGray,
    borderColor: mainColors.solid.lighterGray,
    backgroundColor: mainColors.solid.lighterGray,
    backgroundImage: 'none',
    pointerEvents: 'none',
    opacity: 0.5,
  },
  warning: '#fbb273',
  background: {
    background: '#FBFDFE',
    highlight: alpha(mainColors.solid.black, 0.02),
  },
  toolbar: {
    background: alpha(mainColors.solid.black, 0.02),
  },
  sidebar: {
    main: { background: mainColors.solid.white, color: mainColors.solid.cadedBlue },
    borderColor: alpha(mainColors.solid.cadedBlue, 0.2),
    active: {
      background: alpha(mainColors.solid.cadedBlue, 0.4),
      color: mainColors.solid.cadedBlue,
    },
    link: {
      background: alpha(mainColors.solid.cadedBlue, 0.2),
    },
  },
  equipmentBox: {
    background: mainColors.solid.whiteBlue,
  },
  footer: {
    type: 'light',
    upper: {
      background: mainColors.solid.cadedBlue,
      color: mainColors.solid.white,
    },
    lower: {
      background: mainColors.solid.white,
      color: '#7F9BAA',
    },
  },
  select: {
    selected: {
      background: mainColors.solid.blue,
      color: mainColors.solid.white,
    },
  },
  tabs: {
    background: mainColors.solid.white,
    color: mainColors.solid.cadedGray,
    maf: {
      color: mainColors.danske.primary70,
      selectedColor: mainColors.danske.primary100,
      divider: mainColors.danske.greyC60,
    },
  },
  headers: {
    background: mainColors.solid.cadedBlue,
    color: mainColors.solid.white,
  },
  chatMessage: {
    own: {
      background: mainColors.solid.blue,
      color: mainColors.solid.white,
    },
    externalReply: {
      background: mainColors.solid.whiteGray,
      color: mainColors.solid.cadedBlue,
    },
    internalReply: null,
  },
  content: {
    background: mainColors.solid.whiteBlue,
    color: mainColors.solid.cadedBlue,
  },
  contentSecondary: {
    background: '#F0F7FA',
    color: mainColors.solid.cadedBlue,
  },
  contentAlternative: {
    main: {
      background: '#FBFDFE',
      color: mainColors.solid.cadedBlue,
    },
    highlight: {
      background: alpha(mainColors.solid.cadedBlue, 0.54),
      color: mainColors.solid.white,
    },
  },
  cardList: {
    card: {
      background: mainColors.solid.white,
      boxShadow: 'rgb(213 213 213 / 80%) 0px 3px 8px 0px',
    },
    title: {
      color: mainColors.solid.blue,
    },
    icon: {
      fill: mainColors.solid.blue,
    },
    header: {
      backgroundColor: '#E7F1F7',
      color: mainColors.solid.cadedBlue,
    },
  },
  chipsAlternative: {
    background: alpha(mainColors.solid.cadedBlue, 0.4),
    color: mainColors.solid.white,
  },
  appBar: {
    background: mainColors.solid.white,
    color: mainColors.solid.cadedBlue,
    boxShadow: `0px 0px 1px ${mainColors.solid.cadedBlue}`,
  },
  toggleElements: {
    background: mainColors.solid.white,
    color: mainColors.solid.blue,
    secondary: mainColors.solid.white,
    border: mainColors.solid.blue,
  },
  quoteDocument: {
    background: '#f6fafc',
  },
  fontPrimary: mainColors.solid.cadedBlue,
  fontSecondary: mainColors.solid.darkGray,
  buttons: {
    secondary: {
      background: `linear-gradient(${mainColors.solid.white}, #f5f5f5)`,
      color: mainColors.solid.cadedBlue,
      border: `1px solid ${mainColors.danske.greyC60}`,
    },
    gray: {
      background: 'linear-gradient(180deg, #FFFFFF 0%, #FAFAFA 100%)',
      color: mainColors.solid.cadedBlue,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: alpha(mainColors.solid.lightGray, 0.3),
        color: mainColors.solid.cadedBlue,
      },
    },
    transparentGray: {
      background: 'transparent',
      color: mainColors.solid.cadedBlue,
      border: `1px solid ${mainColors.danske.action}`,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: alpha(mainColors.solid.lightGray, 0.3),
      },
    },
    blank: {
      background: 'inherit',
      color: alpha(mainColors.solid.cadedBlue, 0.8),
      '&:hover': {
        color: mainColors.solid.cadedBlue,
      },
    },
    loading: {
      background: mainColors.solid.cadedBlue,
    },
    base: {
      '&:hover': {
        boxShadow:
          'rgba(102, 102, 102, 0.2) 0px 1px 2px 0px, rgba(102, 102, 102, 0.2) 0px 2px 4px 0px',
      },
    },
    sticky: {
      background: `linear-gradient(${alpha(mainColors.danske.primary1, 0)} 0%, ${
        mainColors.danske.primary1
      } 25%, ${mainColors.danske.primary1} 100%)`,
    },
  },
  latestInfo: {
    background: mainColors.solid.cadedBlue,
    color: mainColors.solid.white,
  },
  slider: {
    thumb: { backgroundColor: mainColors.solid.blue },
    track: { background: mainColors.solid.cadedBlue },
    trackAlternative: { background: mainColors.solid.cadedBlue },
  },
  selectLayout: {
    mainBlock: {
      boxShadow: `-3px -3px 3px ${alpha(mainColors.solid.black, 0.05)}, 3px 3px 3px ${alpha(
        mainColors.solid.black,
        0.05,
      )}, -3px 3px 3px ${alpha(mainColors.solid.black, 0.05)}, 3px -3px 3px ${alpha(
        mainColors.solid.black,
        0.05,
      )}`,
    },
    sidebar: {
      boxShadow: `0px -3px 3px ${alpha(mainColors.solid.black, 0.05)}, 0px 3px 3px ${alpha(
        mainColors.solid.black,
        0.05,
      )}, 3px 3px 3px ${alpha(mainColors.solid.black, 0.05)}, 3px -3px 3px ${alpha(
        mainColors.solid.black,
        0.05,
      )}`,
    },
  },
  caseDetail: {
    sidebarSection: {
      borderTop: `1px solid ${alpha(mainColors.solid.cadedBlue, 0.1)}`,
      borderBottom: `1px solid ${alpha(mainColors.solid.cadedBlue, 0.1)}`,
    },
    indicatorIcon: {
      color: mainColors.solid.cadedBlue,
    },
    mainContainer: {
      background: mainColors.solid.whiteBlue,
    },
  },
  specifications: {
    borderBottom: `1px solid ${alpha(mainColors.solid.cadedBlue, 0.48)}`,
    color: mainColors.solid.cadedBlue,
  },
  applicationForm: {
    solid: {
      background: mainColors.solid.white,
    },
    transparent: {
      backgroundColor: alpha(mainColors.solid.white, 0.8),
    },
  },
  carousel: {
    pagination: {
      active: {
        backgroundColor: mainColors.solid.cadedBlue,
      },
      inactive: {
        backgroundColor: alpha(mainColors.solid.cadedBlue, 0.12),
      },
    },
  },
  kyc: {
    card: {
      backgroundColor: alpha(mainColors.solid.white, 0.4),
    },
    cardAction: {
      backgroundColor: mainColors.solid.white,
      '&:hover': {
        backgroundColor: mainColors.solid.whiteBlue,
      },
    },
    dialog: null,
  },
  search: {
    background: mainColors.solid.white,
    border: {
      border: `1px solid ${mainColors.danske.greyC60}`,
    },
  },
  inputFull: {
    input: {
      color: `${mainColors.solid.cadedBlue} !important`,
      background: mainColors.solid.white,
      border: `1px solid ${mainColors.danske.greyC60}`,
    },
    disabled: {
      '-webkitTextFillColor': '#93989C !important',
      color: '#93989C',
      background: '#F3F5F4',
      border: `1px solid ${mainColors.danske.greyC60}`,
    },
    error: {
      color: `${mainColors.solid.white} !important`,
      background: mainColors.solid.red,
      boxShadow: `0px 2px 6px ${alpha(mainColors.solid.black, 0.3)}`,
    },
    helper: {
      color: `${mainColors.solid.white} !important`,
      background: mainColors.solid.lightBlue,
    },
    hover: {
      border: `1px solid ${mainColors.solid.blue}`,
    },
    label: {
      color: mainColors.danske.primary70,
    },
  },
  tooltip: {
    color: mainColors.solid.cadedBlue,
    background: mainColors.solid.white,
  },
  spinner: {
    primaryColor: mainColors.solid.cadedBlue,
    secondaryColor: mainColors.solid.blue,
  },
  loginBlock: {
    background: mainColors.solid.white,
    boxShadow: `0 0 4px 0 ${alpha(mainColors.solid.lightRedGray, 0.5)}`,
  },
  dialog: { background: mainColors.solid.white },
  icon: { fill: mainColors.solid.cadedBlue },
  switch: {
    base: {
      '&:hover': {
        boxShadow:
          'rgba(102, 102, 102, 0.2) 0px 1px 2px 0px, rgba(102, 102, 102, 0.2) 0px 2px 4px 0px',
      },
    },
    thumb: {
      color: mainColors.solid.white,
    },
    enabled: {
      backgroundColor: `${mainColors.solid.blue} !important`,
    },
    disabled: {
      backgroundColor: `${mainColors.solid.lighterGray} !important`,
    },
  },
  calendar: {
    background: {
      backgroundColor: mainColors.solid.white,
    },
    arrow: {
      fill: mainColors.solid.blue,
    },
    selected: {
      backgroundColor: `${mainColors.solid.blue} !important`,
    },
  },
  dropDown: {
    selected: {
      backgroundColor: mainColors.solid.blue,
      color: mainColors.solid.white,
    },
  },
  carSelection: {
    boxShadow: `0 2px 5px 0 ${alpha(mainColors.solid.black, 0.2)}`,
  },
  body: { background: mainColors.danske.primary6, color: mainColors.solid.cadedBlue },
  vendorTerms: { color: mainColors.solid.cadedBlue },
  filters: { backgroundColor: mainColors.solid.white },
  accordion: {
    color: mainColors.danske.primary100,
    bottomColor: mainColors.danske.greyC60,
    background: mainColors.danske.white,
    focusBackground: mainColors.danske.info40,
  },
  badge: {
    base: {
      color: mainColors.danske.white,
      backgroundColor: mainColors.danske.action,
    },
    error: {
      fill: mainColors.danske.white,
      backgroundColor: mainColors.danske.alert100,
    },
  },
  choosable: {
    background: mainColors.danske.white,
    color: `${mainColors.danske.primary100} !important`,
  },
  cookiesConsent: {
    content: {
      backgroundColor: alpha(mainColors.danske.info20, 0.97),
    },
    buttons: {
      background: `linear-gradient(to bottom, ${alpha(mainColors.danske.info20, 0)} 0, ${alpha(
        mainColors.danske.info20,
        1,
      )} 66px)`,
    },
  },
  ribbon: {
    PR: {
      background: 'linear-gradient(180deg, #004163 0%, #00304A 100%)',
      boxShadow:
        '0 1px 2px 0 rgba(102,102,102,0.2), 0 2px 4px 0 rgba(102,102,102,0.2), 0 4px 8px 0 rgba(102,102,102,0.2)',
      '&::before': {
        borderLeft: `2px solid ${mainColors.danske.primary100}`,
        borderRight: '2px solid transparent',
        borderBottom: '2px solid transparent',
        borderTop: `2px solid ${mainColors.danske.primary100}`,
      },
      '&::after': {
        borderRight: `2px solid ${mainColors.danske.primary100}`,
        borderLeft: '2px solid transparent',
        borderBottom: '2px solid transparent',
        borderTop: `2px solid ${mainColors.danske.primary100}`,
      },
    },
    CM: {
      background: 'linear-gradient(180deg, #004163 0%, #00304A 100%)',
      boxShadow:
        '0 1px 2px 0 rgba(102,102,102,0.2), 0 2px 4px 0 rgba(102,102,102,0.2), 0 4px 8px 0 rgba(102,102,102,0.2)',
      '&::before': {
        borderLeft: `2px solid ${mainColors.danske.primary100}`,
        borderRight: '2px solid transparent',
        borderBottom: '2px solid transparent',
        borderTop: `2px solid ${mainColors.danske.primary100}`,
      },
      '&::after': {
        borderRight: `2px solid ${mainColors.danske.primary100}`,
        borderLeft: '2px solid transparent',
        borderBottom: '2px solid transparent',
        borderTop: `2px solid ${mainColors.danske.primary100}`,
      },
    },
  },
  stepper: {
    intro: {
      subText: {
        color: mainColors.danske.primary70,
      },
    },
    active: mainColors.danske.primary100,
    inactive: mainColors.danske.primary6,
    activeStep: { backgroundColor: mainColors.danske.action },
    underline: mainColors.danske.greyC60,
  },
  radio: {
    select: {
      color: mainColors.danske.primary100,
      backgroundColor: mainColors.danske.white,
      '&:hover': {
        color: mainColors.danske.primary100,
        backgroundColor: mainColors.danske.white,
      },
    },
    selected: {
      color: mainColors.danske.primary50,
    },
    button: {
      borderColor: mainColors.danske.greyC60,
    },
    disabled: {
      color: mainColors.danske.greyA80,
      backgroundColor: mainColors.danske.greyC20,
    },
  },
  summaryEdit: {
    topBar: {
      boxShadow: `${alpha(mainColors.solid.black, 0.3)} 0px -2px 12px 0px`,
    },
    wrapper: {
      boxShadow: 'rgb(102 102 102 / 20%) 0px 2px 4px 0px, rgb(102 102 102 / 20%) 0px 4px 8px 0px',
    },
  },
  cardSelect: {
    dropDown: {
      alternative: {
        background: mainColors.danske.primary1,
      },
    },
    selected: {
      color: mainColors.danske.primary70,
    },
  },
  table: {
    borderColor: mainColors.danske.primary10,
    onHover: {
      backgroundColor: mainColors.danske.info1,
    },
  },
};
