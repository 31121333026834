import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]).isRequired,
  direction: PropTypes.oneOf(['asc', 'desc']),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.func,
      fallbackName: PropTypes.string,
      isHidden: PropTypes.bool,
      isUnsortable: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
        .isRequired,
      name: PropTypes.string.isRequired,
      renderFunc: PropTypes.func,
      shouldStopPropagation: PropTypes.bool,
      sortField: PropTypes.string,
      sortTitle: PropTypes.node,
      subName: PropTypes.string,
    }),
  ).isRequired,
  hasCheckbox: PropTypes.bool,
  hasData: PropTypes.bool.isRequired,
  headerClass: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  isFetching: PropTypes.bool.isRequired,
  isUplift: PropTypes.bool,
  isWithMobile: PropTypes.bool,
  isWithPagination: PropTypes.bool,
  isWithStickyHeader: PropTypes.bool,
  noResultsMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
    .isRequired,
  nsTarget: PropTypes.string,
  onOrder: PropTypes.func,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func,
  orderBy: PropTypes.string,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  shouldAdjustHeaderPosition: PropTypes.bool,
  tableContainerSx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  tableContentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.object,
    }),
  ]),
} as any;
