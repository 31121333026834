import { ReactElement, forwardRef } from 'react';
import { Box, SxProps, Theme } from '@mui/material';

import { propTypes } from './MAFForwarder.propTypes';

export interface IMAFForwarderProps {
  children?: ReactElement | string;
  sx?: SxProps<Theme>;
}

const MAFForwarder = forwardRef<HTMLDivElement, IMAFForwarderProps>(
  ({ children, ...props }, ref) => (
    <Box {...props} ref={ref}>
      {children}
    </Box>
  ),
);

MAFForwarder.displayName = 'MAFForwarder';

MAFForwarder.propTypes = propTypes;

export default MAFForwarder;
