"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.postPublicCommentsResponse = exports.postPublicCommentRequest = exports.postInternalCommentsResponse = exports.postInternalCommentRequest = exports.initialState = exports.getPublicCommentsResponse = exports.getPublicCommentsRequest = exports.getInternalCommentsResponse = exports.getInternalCommentsRequest = exports.default = exports.clearCommentState = void 0;
var _immutable = require("immutable");
var _reduxActions = require("redux-actions");
const getInternalCommentsRequest = exports.getInternalCommentsRequest = (0, _reduxActions.createAction)('CASE_DETAIL_GET_INTERNAL_COMMENTS_REQUEST');
const getPublicCommentsRequest = exports.getPublicCommentsRequest = (0, _reduxActions.createAction)('CASE_DETAIL_GET_PUBLIC_COMMENTS_REQUEST');
const getInternalCommentsResponse = exports.getInternalCommentsResponse = (0, _reduxActions.createAction)('CASE_DETAIL_GET_INTERNAL_COMMENTS_RESPONSE');
const getPublicCommentsResponse = exports.getPublicCommentsResponse = (0, _reduxActions.createAction)('CASE_DETAIL_GET_PUBLIC_COMMENTS_RESPONSE');
const postInternalCommentRequest = exports.postInternalCommentRequest = (0, _reduxActions.createAction)('CASE_DETAIL_POST_INTERNAL_COMMENTS_REQUEST');
const postPublicCommentRequest = exports.postPublicCommentRequest = (0, _reduxActions.createAction)('CASE_DETAIL_POST_PUBLIC_COMMENTS_REQUEST');
const postInternalCommentsResponse = exports.postInternalCommentsResponse = (0, _reduxActions.createAction)('CASE_DETAIL_POST_INTERNAL_COMMENTS_RESPONSE');
const postPublicCommentsResponse = exports.postPublicCommentsResponse = (0, _reduxActions.createAction)('CASE_DETAIL_POST_PUBLIC_COMMENTS_RESPONSE');
const clearCommentState = exports.clearCommentState = (0, _reduxActions.createAction)('CASE_DETAIL_CLEAR_COMMENTS');
const initialState = exports.initialState = (0, _immutable.fromJS)({
  internalComments: [],
  publicComments: [],
  gettingInternalComments: false,
  postingInternalComment: false,
  gettingPublicComments: false,
  postingPublicComment: false
});
var _default = exports.default = (0, _reduxActions.handleActions)({
  [getInternalCommentsRequest](state) {
    return state.set('gettingInternalComments', true);
  },
  [getPublicCommentsRequest](state) {
    return state.set('gettingPublicComments', true);
  },
  [getInternalCommentsResponse](state, _ref) {
    let {
      payload,
      error
    } = _ref;
    return !error ? state.set('gettingInternalComments', false).set('internalComments', (0, _immutable.fromJS)(payload)) : state.set('gettingInternalComments', false).set('internalComments', (0, _immutable.fromJS)([]));
  },
  [getPublicCommentsResponse](state, _ref2) {
    let {
      payload,
      error
    } = _ref2;
    return !error ? state.set('gettingPublicComments', false).set('publicComments', (0, _immutable.fromJS)(payload)) : state.set('gettingPublicComments', false).set('publicComments', (0, _immutable.fromJS)([]));
  },
  [postInternalCommentRequest](state) {
    return state.set('postingInternalComment', true);
  },
  [postPublicCommentRequest](state) {
    return state.set('postingPublicComment', true);
  },
  [postInternalCommentsResponse](state, _ref3) {
    let {
      error,
      payload
    } = _ref3;
    return !error ? state.update('internalComments', internalComments => internalComments.push(payload)).set('postingInternalComment', false) : state.set('postingInternalComment', false);
  },
  [postPublicCommentsResponse](state, _ref4) {
    let {
      error,
      payload
    } = _ref4;
    return !error ? state.update('publicComments', publicComments => publicComments.push(payload)).set('postingPublicComment', false) : state.set('postingPublicComment', false);
  },
  [clearCommentState]() {
    return initialState;
  }
}, initialState);