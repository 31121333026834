import { ISxStyles } from '../../../../theming/theme.type';

export const styles: ISxStyles = {
  hoverableRow: ({ colors }) => ({
    '&.MuiTableRow-root': {
      '&:hover': colors.custom.table.onHover,
    },
  }),
  hoverableRowUplift: ({ colors }) => ({
    '&.MuiTableRow-root': {
      '&:hover': colors.custom.table.onHover,
    },
  }),
  headTableRow: {
    '&.MuiTableRow-root': {
      verticalAlign: 'bottom',
      height: 56,
    },
  },
  headTableRowUplift: {
    '&.MuiTableRow-root': {
      verticalAlign: 'middle',
    },
  },
  clickable: {
    '&.MuiTableRow-root': {
      cursor: 'pointer',
    },
  },
};
