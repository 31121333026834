"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  tooltip: {
    maxWidth: 500
  },
  circle: _ref => {
    let {
      colors,
      spacing
    } = _ref;
    return {
      backgroundColor: colors.danske.greyC60,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      height: spacing(3),
      width: spacing(3),
      borderRadius: '50%',
      marginLeft: spacing(1),
      '&:hover': {
        backgroundColor: colors.solid.lighterGray
      }
    };
  }
};