import dayjs, { type Dayjs } from 'dayjs';
import { useRef, useEffect, useState, FC } from 'react';
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/lab';

import { CalendarIcon, ArrowBack, ArrowNext, ArrowDown } from '../Icons';
import MAFIcon from '../MAFIcon/MAFIcon';

import MAFCalendarInput, { IMAFCalendarInputProps } from './MAFCalendarInput/MAFCalendarInput';
import { styles } from './MAFCalendar.styles';
import { propTypes } from './MAFCalendar.propTypes';

export interface IMAFCalendarProps
  extends Omit<
      DesktopDatePickerProps<Dayjs>,
      'InputProps' | 'inputRef' | 'label' | 'mask' | 'renderInput'
    >,
    Omit<IMAFCalendarInputProps, 'onBlur' | 'onChange' | 'onError' | 'value'> {
  onBlur?: (date: Dayjs) => void;
  onChange: (date: Dayjs) => void;
}

const createReadableDateFormatter =
  (format = 'YYYY-MM-DD') =>
  (date: Dayjs) =>
    dayjs(date).format(format);

const MAFCalendar: FC<IMAFCalendarProps> = ({
  onBlur = () => {},
  mask,
  maskedFormat,
  value,
  inputFormat,
  placeholder,
  textFieldClass,
  containerClass,
  disabled: isDisabled,
  error: isError,
  helperText,
  label,
  ...props
}) => {
  const dayJsValue = dayjs(value);
  const convertToReadableDate = createReadableDateFormatter(inputFormat);
  const dateRef = useRef(dayJsValue);
  const [inputValue, setInputValue] = useState<string>(convertToReadableDate(dayJsValue));

  // Handle when new value comes from a prop
  useEffect(() => {
    setInputValue(convertToReadableDate(dayJsValue));
  }, [dayJsValue]);

  const onChange = (datePickerDateObj: Dayjs) => {
    setInputValue(convertToReadableDate(datePickerDateObj));
    dateRef.current = datePickerDateObj;
    props.onChange(datePickerDateObj);
  };

  const onClose = () => {
    onBlur(dateRef.current);
  };

  return (
    <DesktopDatePicker
      {...props}
      mask={maskedFormat.replace(/#/g, '_')}
      components={{
        LeftArrowIcon: ArrowBack,
        RightArrowIcon: ArrowNext,
        SwitchViewIcon: ArrowDown,
        OpenPickerIcon: () => <MAFIcon Icon={CalendarIcon} sx={styles.calendar} />,
      }}
      componentsProps={{
        leftArrowButton: { sx: styles.icon },
        rightArrowButton: { sx: styles.icon },
        switchViewButton: { sx: styles.icon },
      }}
      renderInput={({ inputProps, InputProps, inputRef }) => (
        <MAFCalendarInput
          value={inputValue}
          label={label}
          mask={mask}
          maskedFormat={maskedFormat}
          placeholder={placeholder}
          onBlur={inputProps?.onBlur}
          onChange={inputProps?.onChange}
          onFocus={inputProps?.onFocus}
          InputProps={InputProps}
          inputRef={inputRef}
          textFieldClass={textFieldClass}
          containerClass={containerClass}
          disabled={isDisabled}
          error={isError}
          helperText={helperText}
        />
      )}
      onChange={onChange}
      onClose={onClose}
      value={dayJsValue || null}
      disableHighlightToday
      inputFormat={inputFormat}
      disabled={isDisabled}
    />
  );
};

MAFCalendar.propTypes = propTypes;

export default MAFCalendar;
