import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  activeStep: PropTypes.number,
  bubblePosition: PropTypes.number,
  buttonType: PropTypes.oneOf(['button', 'submit']),
  children: PropTypes.node,
  customIcon: PropTypes.oneOfType([
    PropTypes.oneOf([
      'a',
      'abbr',
      'address',
      'animate',
      'animateMotion',
      'animateTransform',
      'area',
      'article',
      'aside',
      'audio',
      'b',
      'base',
      'bdi',
      'bdo',
      'big',
      'blockquote',
      'body',
      'br',
      'button',
      'canvas',
      'caption',
      'circle',
      'cite',
      'clipPath',
      'code',
      'col',
      'colgroup',
      'data',
      'datalist',
      'dd',
      'defs',
      'del',
      'desc',
      'details',
      'dfn',
      'dialog',
      'div',
      'dl',
      'dt',
      'ellipse',
      'em',
      'embed',
      'feBlend',
      'feColorMatrix',
      'feComponentTransfer',
      'feComposite',
      'feConvolveMatrix',
      'feDiffuseLighting',
      'feDisplacementMap',
      'feDistantLight',
      'feDropShadow',
      'feFlood',
      'feFuncA',
      'feFuncB',
      'feFuncG',
      'feFuncR',
      'feGaussianBlur',
      'feImage',
      'feMerge',
      'feMergeNode',
      'feMorphology',
      'feOffset',
      'fePointLight',
      'feSpecularLighting',
      'feSpotLight',
      'feTile',
      'feTurbulence',
      'fieldset',
      'figcaption',
      'figure',
      'filter',
      'footer',
      'foreignObject',
      'form',
      'g',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'head',
      'header',
      'hgroup',
      'hr',
      'html',
      'i',
      'iframe',
      'image',
      'img',
      'input',
      'ins',
      'kbd',
      'keygen',
      'label',
      'legend',
      'li',
      'line',
      'linearGradient',
      'link',
      'main',
      'map',
      'mark',
      'marker',
      'mask',
      'menu',
      'menuitem',
      'meta',
      'metadata',
      'meter',
      'mpath',
      'nav',
      'noindex',
      'noscript',
      'object',
      'ol',
      'optgroup',
      'option',
      'output',
      'p',
      'param',
      'path',
      'pattern',
      'picture',
      'polygon',
      'polyline',
      'pre',
      'progress',
      'q',
      'radialGradient',
      'rect',
      'rp',
      'rt',
      'ruby',
      's',
      'samp',
      'script',
      'section',
      'select',
      'symbol',
      'slot',
      'small',
      'source',
      'span',
      'style',
      'stop',
      'strong',
      'sub',
      'summary',
      'sup',
      'svg',
      'switch',
      'table',
      'tbody',
      'td',
      'template',
      'text',
      'textarea',
      'textPath',
      'tfoot',
      'th',
      'thead',
      'time',
      'title',
      'tr',
      'track',
      'tspan',
      'u',
      'ul',
      'use',
      'var',
      'video',
      'view',
      'wbr',
      'webview',
    ]),
    PropTypes.func,
  ]),
  shouldDisableAnimation: PropTypes.bool,
  style: PropTypes.object,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  tabIndex: PropTypes.number,
} as any;
