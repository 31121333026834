const CarFront: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-action/Car/Darkblue">
        <g id="Icon" transform="translate(2.000000, 3.000000)">
          <path
            d="M4.5,13 C5.32842712,13 6,12.3284271 6,11.5 C6,10.6715729 5.32842712,10 4.5,10 C3.67157288,10 3,10.6715729 3,11.5 C3,12.3284271 3.67157288,13 4.5,13 Z M15.5,13 C16.3284271,13 17,12.3284271 17,11.5 C17,10.6715729 16.3284271,10 15.5,10 C14.6715729,10 14,10.6715729 14,11.5 C14,12.3284271 14.6715729,13 15.5,13 Z M18,7 L18,1.5 C18,0.7 17.4,0 16.5,0 L3.5,0 C2.7,0 2,0.7 2,1.5 L2,7 C0.9,7.3 0,8.4 0,9.8 L0,15.5 C0,15.8 0.2,16 0.5,16 L3,16 L3,17.5 C3,18.3 3.7,19 4.5,19 C5.3,19 6,18.3 6,17.5 L6,16 L14,16 L14,17.5 C14,18.3 14.7,19 15.5,19 C16.3,19 17,18.3 17,17.5 L17,16 L19.4,16 C19.7,16 19.9,15.8 19.9,15.5 L19.9,9.7 C20,8.4 19.2,7.3 18,7 L18,7 Z M3.5,1 L16.5,1 C16.8,1 17,1.2 17,1.5 L17,7 L3,7 L3,1.5 C3,1.2 3.3,1 3.5,1 L3.5,1 Z M19,15 L1,15 L1,9.8 C1,8.9 1.7,8 2.5,8 L2.7,8 L17.4,8 L17.6,8 C18.4,8 19,8.8 19,9.7 L19,15 L19,15 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default CarFront;
