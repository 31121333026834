"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.request = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _core = require("@northstar/core");
var _axios = _interopRequireDefault(require("axios"));
var _authSingleton = _interopRequireDefault(require("./auth-singleton"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const routeConstants = new _core.RouteConstants();

// BASE_API_URL is retrieved from the environment file (env-*.js)
// eslint-disable-next-line no-undef,no-unneeded-ternary
const BASE_URL = (typeof BASE_API_URL !== 'undefined' ? BASE_API_URL : '').concat(process.env.REACT_APP_CURR_PROJECT === _core.INTERNAL_PROJECT_NAME || process.env.REACT_APP_BANK_ID_REDIRECT_URL ? '' : '/api/bff-se-proxy');
const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  'Cache-Control': 'max-age=0, private, no-cache, no-store, must-revalidate',
  Expires: -1,
  Pragma: 'no-cache',
  SecuritySystem: 'UEC',
  Channel: 'WEB',
  Brand: 'DB',
  'X-Independent-Logon': 'true',
  'X-Client-Id': 'NOST',
  // TODO: change to environment-based
  ClientId: 'a1168794-7ee1-11e8-adc0-fa7ae01bbebc',
  ClientSecret: 'aacdaf92-7ee1-11e8-adc0-fa7ae01bbebc'
};

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
const parseJSON = response => ({
  status: response.status,
  statusText: response.statusText,
  body: response.data
});

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = _ref => {
  let {
    status: httpStatus,
    body
  } = _ref;
  if (httpStatus >= 200 && httpStatus < 300 && !body.httpResponse) {
    return body;
  }
  if (!body.httpResponse) {
    if (body.code) {
      const error = new Error(body.message);
      error.httpResponse = {
        status: body.code,
        reason: body.message
      };
      throw error;
    }

    // TOOD: add generic translation key
    throw new Error('errors.credit_decision_request_failed');
  }
  const error = new Error(body.httpResponse.reason);
  error.httpResponse = body.httpResponse;
  throw error;
};
const constructUrl = url => {
  if (process.env.REACT_APP_CURR_PROJECT === _core.EXTERNAL_PROJECT_NAME && url === routeConstants.domains.authDomainConstants.LOGOUT.buildUrl()) {
    return routeConstants.domains.authDomainConstants.ASHE_LOGOUT.buildUrl();
  }
  return BASE_URL.concat(url);
};
const dispatchRequest = async _ref2 => {
  let {
    options,
    headers,
    url
  } = _ref2;
  try {
    return await (0, _axios.default)(_objectSpread(_objectSpread({}, options), {}, {
      headers,
      withCredentials: true,
      url: constructUrl(url)
    }));
  } catch (e) {
    var _e$response, _e$response2;
    const error = new Error('errors.server_error');
    error.httpResponse = {
      status: ((_e$response = e.response) === null || _e$response === void 0 ? void 0 : _e$response.status) || 500,
      reason: ((_e$response2 = e.response) === null || _e$response2 === void 0 ? void 0 : _e$response2.statusText) || 'errors.server_error'
    };
    throw error;
  }
};

/**
 * Requests a URL
 *
 * @param  {string} url Name of the route to be requested
 * @param  {string} options
 *
 * @return {object} promise
 */
const request = async _ref3 => {
  let {
    url,
    options
  } = _ref3;
  const {
    headers = {}
  } = options;
  // TODO: why do we need this? seems weird, should be null by default
  headers.Authorization = _authSingleton.default.getAuthSecret() || null;
  headers[_core.IDENTITY_HEADER] = _authSingleton.default.getIdentityId() || null;
  const response = await dispatchRequest({
    options,
    headers,
    url
  });
  const parsedJSON = await parseJSON(response);
  return checkStatus(parsedJSON);
};

/**
 * PUT request
 *
 * @param  {string} url Name of the route to be requested
 * @param  {string} options
 *
 * @return {object} promise
 */
exports.request = request;
const put = _ref4 => {
  let {
    url,
    body,
    options
  } = _ref4;
  return request({
    url,
    options: _objectSpread({
      headers: DEFAULT_HEADERS,
      method: 'put',
      data: JSON.stringify(body || {})
    }, options)
  });
};
/**
 * POST request
 *
 * @param  {string} url Name of the route to be requested
 * @param  {string} options
 *
 * @return {object} promise
 */
const post = _ref5 => {
  let {
    url,
    body,
    options
  } = _ref5;
  return request({
    url,
    options: _objectSpread({
      headers: _objectSpread(_objectSpread({}, DEFAULT_HEADERS), {}, {
        'Content-Type': 'application/json'
      }),
      method: 'post',
      data: JSON.stringify(body || {})
    }, options)
  });
};
/**
 * DELETE request
 *
 * @param  {string} url Name of the route to be requested
 * @param  {string} options
 *
 * @return {object} promise
 */
const del = _ref6 => {
  let {
    url,
    body,
    options
  } = _ref6;
  return request({
    url,
    options: _objectSpread({
      headers: DEFAULT_HEADERS,
      method: 'delete',
      body: JSON.stringify(body)
    }, options)
  });
};
/**
 * GET request
 *
 * @param  {string} url Name of the route to be requested
 * @param  {string} options
 *
 * @return {object} promise
 */
const get = _ref7 => {
  let {
    url,
    options
  } = _ref7;
  return request({
    url,
    options: _objectSpread({
      headers: DEFAULT_HEADERS,
      method: 'get'
    }, options)
  });
};
/**
 * PATCH request
 *
 * @param  {string} url Name of the route to be requested
 * @param  {string} options
 *
 * @return {object} promise
 */
const patch = _ref8 => {
  let {
    url,
    body,
    options
  } = _ref8;
  return request({
    url,
    options: _objectSpread({
      headers: DEFAULT_HEADERS,
      method: 'patch',
      data: JSON.stringify(body || {})
    }, options)
  });
};
const apiAgent = {
  get,
  post,
  put,
  patch,
  delete: del
};
routeConstants.bindApiAgent(apiAgent);
const domains = {
  assetDomain: routeConstants.domains.assetDomainConstants,
  authDomain: routeConstants.domains.authDomainConstants,
  caseDomain: routeConstants.domains.caseDomainConstants,
  vendorCaseDomain: routeConstants.domains.vendorCaseDomainConstants,
  creditDomain: routeConstants.domains.creditDomainConstants,
  featureDomain: routeConstants.domains.featureDomainConstants,
  frameDomain: routeConstants.domains.frameDomainConstants,
  productDomain: routeConstants.domains.productDomainConstants,
  userDomain: routeConstants.domains.userDomainConstants,
  vendorDomain: routeConstants.domains.vendorDomainConstants,
  supportDomain: routeConstants.domains.supportDomainConstants,
  kycDomain: routeConstants.domains.kycDomainConstants,
  notificationsDomain: routeConstants.domains.notificationsDomainConstants,
  emailDomain: routeConstants.domains.emailDomainConstants,
  insuranceDomain: routeConstants.domains.insuranceDomainConstants,
  featureToggleDomain: routeConstants.domains.featureToggleDomainConstants
};
var _default = exports.default = domains;