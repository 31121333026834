"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = equipmentSagas;
exports.enableEquipments = enableEquipments;
exports.loadEquipment = loadEquipment;
var _core = require("@northstar/core");
var _effects = require("redux-saga/effects");
var equipmentApi = _interopRequireWildcard(require("../asset/equipmentApi"));
var _equipmentMappers = require("../asset/equipmentMappers");
var _appReducer = require("../app/appReducer");
var _equipmentConstants = require("../equipmentConstants");
var _vendorDashboardAssetSelectors = require("./vendorDashboardAssetSelectors");
var _vendorDashboardEquipmentReducer = require("./vendorDashboardEquipmentReducer");
var _vendorDashboardEquipmentSelectors = require("./vendorDashboardEquipmentSelectors");
var _vendorDashboardFrameSelectors = require("./vendorDashboardFrameSelectors");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function enableEquipments(_ref) {
  let {
    payload
  } = _ref;
  return function* () {
    yield (0, _effects.all)(Object.values(payload).map(_ref2 => {
      let {
        id,
        category,
        equipmentPrice
      } = _ref2;
      return (0, _effects.put)((0, _vendorDashboardEquipmentReducer.enableEquipmentByIdWithPrice)({
        id,
        category: category === _equipmentConstants.EQUIPMENT_CATEGORY_ADDITIONAL || category === _equipmentConstants.EQUIPMENT_CATEGORY_PACKAGE ? _equipmentConstants.EQUIPMENT_FORM_ADDITIONAL : _equipmentConstants.EQUIPMENT_FORM_COLORS,
        equipmentPrice
      }));
    }));
  }();
}
function loadEquipment() {
  let {
    payload: {
      withoutRetrofit,
      withoutStandard,
      frameId: frame
    } = {}
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function* () {
    try {
      const {
        id,
        frameVersionId
      } = yield (0, _effects.select)((0, _vendorDashboardFrameSelectors.selectFrame)());
      const vehicleType = yield (0, _effects.select)((0, _vendorDashboardAssetSelectors.selectVehicleType)());
      const frameId = frame || id;
      if (frameId > 0) {
        const apiCall = vehicleType === _core.VEHICLE_TYPE_VAN ? equipmentApi.getFrameEquipmentVan : equipmentApi.getFrameEquipment;
        const response = yield (0, _effects.call)(apiCall, {
          frameId,
          frameVersionId
        });
        const currentEquipment = yield (0, _effects.select)((0, _vendorDashboardEquipmentSelectors.selectEquipment)());
        yield (0, _effects.put)((0, _vendorDashboardEquipmentReducer.getEquipmentResponse)(_equipmentMappers.responseMappers.mapAssetEquipment({
          equipment: response,
          preconfiguredEquipment: currentEquipment.preconfigured,
          currentEquipment,
          withoutRetrofit,
          withoutStandard,
          enabled: false
        })));
      }
    } catch (e) {
      yield (0, _effects.put)((0, _appReducer.displayError)(e));
    }
  }();
}
function* equipmentSagas() {
  yield (0, _effects.takeLatest)(_vendorDashboardEquipmentReducer.getEquipmentRequest, loadEquipment);
  yield (0, _effects.takeLatest)(_vendorDashboardEquipmentReducer.enableEquipmentsByIds, enableEquipments);
}