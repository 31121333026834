import { ReactChild, MouseEventHandler, FC } from 'react';
import { Button, ButtonProps, Box, SxProps, Theme } from '@mui/material';

import { useThrottle, handleCustomSx } from '../../utils';
import MAFLoader from '../MAFLoader/MAFLoader';

import { styles } from './MAFButton.styles';
import { propTypes } from './MAFButton.propTypes';

export type TMAFButtonColor =
  | 'default'
  | 'primaryPR'
  | 'primaryCM'
  | 'primaryPRHighlight'
  | 'primaryCMHighlight'
  | 'secondary'
  | 'blank'
  | 'gray'
  | 'blue'
  | 'green'
  | 'red'
  | 'transparent-gray'
  | 'dark-gray'
  | 'transparent-dark-gray';

export type TMAFButtonSize = 'wide' | 'small' | 'tiny';

export type TMAFButtonPropsOmitted = Omit<ButtonProps, 'color' | 'size' | 'onClick'>;

export interface IMAFButtonProps extends TMAFButtonPropsOmitted {
  children?: ReactChild;
  color?: TMAFButtonColor;
  size?: TMAFButtonSize;
  isRounded?: boolean;
  shouldAlignLeft?: boolean;
  sx?: SxProps<Theme>;
  isLink?: boolean;
  isLoading?: boolean;
  isCentered?: boolean;
  isSticky?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  clickThrottleTime?: number;
  containerClass?: SxProps<Theme>;
}

const MAFButton: FC<IMAFButtonProps> = ({
  children,
  color = 'default',
  size,
  isRounded = true,
  shouldAlignLeft = false,
  sx,
  isLink = false,
  isLoading = false,
  isCentered = false,
  disabled: isDisabled = false,
  isSticky = false,
  onClick = () => {},
  clickThrottleTime = 250,
  containerClass,
  ...props
}) => {
  const handleClick = useThrottle(onClick, clickThrottleTime);

  const iconClass = size === 'small' ? styles.iconSmall : styles.icon;

  const btnClasses = [
    color === 'default' && styles.default,
    color === 'primaryPR' && styles.primaryPR,
    color === 'primaryCM' && styles.primaryCM,
    color === 'primaryPRHighlight' && styles.primaryPRHighlight,
    color === 'primaryCMHighlight' && styles.primaryCMHighlight,
    color === 'secondary' && styles.secondary,
    color === 'blank' && styles.blank,
    color === 'gray' && styles.gray,
    color === 'green' && styles.green,
    color === 'red' && styles.red,
    color === 'transparent-gray' && styles.transparentGray,
    color === 'dark-gray' && styles.darkGray,
    color === 'transparent-dark-gray' && styles.transparentDarkGray,
    color === 'blue' && styles.blue,
    size === 'wide' && styles.wide,
    size === 'small' && styles.small,
    size === 'tiny' && styles.tiny,
    isRounded && styles.rounded,
    isDisabled && !isLink && styles.disabled,
    shouldAlignLeft && styles.alignLeft,
    isLink && styles.link,
    Boolean(props.startIcon) && iconClass,
    Boolean(props.endIcon) && iconClass,
    ...handleCustomSx(sx),
  ];

  const containerClasses = [
    isCentered && styles.center,
    size === 'wide' && styles.containerWide,
    isSticky && styles.sticky,
    ...handleCustomSx(containerClass),
  ];

  return (
    <Box component="span" sx={containerClasses}>
      <Button
        sx={[styles.buttonAccessibility, ...handleCustomSx(btnClasses)]}
        onClick={handleClick}
        {...props}
        disabled={isLoading || isDisabled}
        tabIndex={0}
      >
        {isLoading ? <MAFLoader /> : children}
      </Button>
    </Box>
  );
};

MAFButton.propTypes = propTypes;

export default MAFButton;
