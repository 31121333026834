"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  icon: _ref => {
    let {
      colors
    } = _ref;
    return {
      '& g': {
        fill: colors.alpha(colors.custom.contentAlternative.main.color, 0.4)
      }
    };
  },
  iconButton: _ref2 => {
    let {
      colors
    } = _ref2;
    return {
      width: 48,
      height: 48,
      '&:hover g': {
        fill: colors.solid.lightBlue
      }
    };
  },
  spinnerContainer: {
    width: 48,
    top: 7,
    height: 'auto',
    display: 'inline-block',
    position: 'relative'
  },
  spinner: {
    fontSize: 24
  }
};