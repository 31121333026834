import * as PropTypes from 'prop-types';

export const propTypes /* remove-proptypes */ = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |              To update them run `npm run proptypes`                |
  // ----------------------------------------------------------------------
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  containerClass: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  /**
   * The default value. Use when the component is not controlled.
   */
  defaultValue: PropTypes.any,
  formHelperTextProps: PropTypes.object,
  helperText: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  id: PropTypes.string,
  inputComponent: PropTypes.element,
  isError: PropTypes.bool,
  isFocused: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isVertical: PropTypes.bool,
  label: PropTypes.node,
  /**
   * The name used to reference the value of the control.
   * If you don't provide this prop, it falls back to a randomly generated name.
   */
  name: PropTypes.string.isRequired,
  tooltipProps: PropTypes.shape({
    additionalIconClass: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
      PropTypes.func,
      PropTypes.object,
    ]),
    additionalTooltipClass: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
      PropTypes.func,
      PropTypes.object,
    ]),
    leaveDelay: PropTypes.number,
    leaveTouchDelay: PropTypes.number,
    onClick: PropTypes.func,
    placement: PropTypes.oneOf([
      'bottom-end',
      'bottom-start',
      'bottom',
      'left-end',
      'left-start',
      'left',
      'right-end',
      'right-start',
      'right',
      'top-end',
      'top-start',
      'top',
    ]),
    shouldDisableHoverListener: PropTypes.bool,
    sx: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
      PropTypes.func,
      PropTypes.object,
    ]),
  }),
  tooltipText: PropTypes.node,
  /**
   * Value of the selected radio button. The DOM API casts this to a string.
   */
  value: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      isDisabled: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
        .isRequired,
      value: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string])
        .isRequired,
    }),
  ).isRequired,
} as any;
