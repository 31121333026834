const ArrowUpFull: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="14px" height="20px" viewBox="0 0 14 20" version="1.1" {...props}>
    <g id="Select-field" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-HD-Copy" transform="translate(-1590.000000, -722.000000)" fillRule="nonzero">
        <g id="Group-3-Copy" transform="translate(1343.000000, 708.000000)">
          <g id="Button-/-Round-/-Actionblue-/-Small" transform="translate(236.000000, 6.000000)">
            <g id="arrow-up_-_white" transform="translate(11.000000, 8.000000)">
              <path
                d="M13.7870662,5.94203125 L7.45246821,0.144921875 C7.41698641,0.112721354 7.38149745,0.0885677083 7.3460013,0.0724609375 C7.31051951,0.0563541667 7.27503054,0.0483007812 7.2395344,0.0483007812 C7.16857081,0.0161002604 7.08872601,0 7,0 C6.91127399,0 6.83142919,0.0161002604 6.7604656,0.0483007812 C6.72496946,0.0483007812 6.68948049,0.0563541667 6.6539987,0.0724609375 C6.61850255,0.0885677083 6.58301359,0.112721354 6.54753179,0.144921875 L0.212933812,5.94203125 C0.0709779374,6.03864583 0,6.16746745 0,6.32849609 C0,6.48952474 0.0709779374,6.61835286 0.212933812,6.71498047 C0.319393544,6.84379557 0.461342245,6.90820312 0.638779915,6.90820312 C0.816217585,6.90820312 0.958173459,6.84379557 1.06464754,6.71498047 L6.33459798,1.93236328 L6.33459798,19.420293 C6.33459798,19.5813216 6.39670188,19.7181966 6.52090968,19.830918 C6.64511749,19.9436393 6.79594022,20 6.97337789,20 C7.15081556,20 7.3016383,19.9436393 7.4258461,19.830918 C7.55005391,19.7181966 7.61215781,19.5813216 7.61215781,19.420293 L7.61215781,1.93236328 L12.8821298,6.71498047 C12.9531077,6.77939453 13.0329525,6.82770182 13.1216642,6.85990234 C13.2103902,6.89210286 13.2902421,6.90820312 13.3612201,6.90820312 C13.432198,6.90820312 13.5120428,6.89210286 13.6007545,6.85990234 C13.6894805,6.82770182 13.7515844,6.77939453 13.7870662,6.71498047 C13.9290221,6.61835286 14,6.48952474 14,6.32849609 C14,6.16746745 13.9290221,6.03864583 13.7870662,5.94203125 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowUpFull;
