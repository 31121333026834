"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
const styles = exports.styles = {
  form: _ref => {
    let {
      breakpoints
    } = _ref;
    return {
      display: 'flex',
      flexWrap: 'wrap',
      width: 900,
      [breakpoints.down('lg')]: {
        width: 600
      },
      [breakpoints.only('xs')]: {
        width: 300
      }
    };
  },
  field: {
    margin: '12px'
  },
  details: {
    textAlign: 'center',
    width: '100%',
    marginTop: '44px'
  },
  margin: {
    marginBottom: 3
  }
};