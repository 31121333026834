"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFrameVan = exports.getFrameOptionsVan = exports.getFrameOptions = exports.getFrame = exports.findFrameVan = exports.findFrame = void 0;
var _requestHandler = _interopRequireDefault(require("../../utils/request-handler"));
const {
  frameDomain
} = _requestHandler.default;
const getFrameOptions = function () {
  let body = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return frameDomain.GET_FILTERS.call({
    body
  });
};
exports.getFrameOptions = getFrameOptions;
const findFrame = body => frameDomain.FIND_FRAME.call({
  body
});
exports.findFrame = findFrame;
const getFrameOptionsVan = function () {
  let body = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return frameDomain.GET_FILTERS_VAN.call({
    body
  });
};
exports.getFrameOptionsVan = getFrameOptionsVan;
const findFrameVan = body => frameDomain.FIND_FRAME_VAN.call({
  body
});
exports.findFrameVan = findFrameVan;
const getFrame = urlParams => frameDomain.GET_FRAME.call({
  urlParams
});
exports.getFrame = getFrame;
const getFrameVan = urlParams => frameDomain.GET_FRAME_VAN.call({
  urlParams
});
exports.getFrameVan = getFrameVan;