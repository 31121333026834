const Insurance: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="18px"
    height="20px"
    viewBox="0 0 18 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>insurance_darkblue</title>
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Desktop-HD"
        transform="translate(-405.000000, -1161.000000)"
        fill="#003755"
        fillRule="nonzero"
      >
        <g id="insurance_darkblue" transform="translate(405.000000, 1161.000000)">
          <path
            d="M11.7561622,9.50037838 C11.7226486,6.58145946 11.134,3.58956757 10.0518378,2.32091892 C13.9712973,2.85551351 16.7264324,5.69335135 16.8496757,9.50037838 L11.7561622,9.50037838 Z M5.63508108,9.50037838 L0.540486486,9.50037838 C0.66427027,5.69335135 3.41940541,2.85605405 7.33940541,2.32091892 C6.25724324,3.59010811 5.66913514,6.58145946 5.63508108,9.50037838 L5.63508108,9.50037838 Z M8.96372973,1.68578378 L8.96372973,0.267945946 C8.96372973,0.119837838 8.84372973,-0.000162162162 8.69562162,-0.000162162162 C8.54751351,-0.000162162162 8.42751351,0.119837838 8.42751351,0.267945946 L8.42751351,1.68578378 C3.60589189,1.81010811 -5.40540541e-05,5.2447027 -5.40540541e-05,9.76848649 C-5.40540541e-05,9.91659459 0.119945946,10.0365946 0.268054054,10.0365946 L8.42751351,10.0365946 L8.42751351,17.9268649 C8.42751351,18.8403784 9.19940541,19.6122703 10.1129189,19.6122703 L10.6875135,19.6122703 C11.5804865,19.6122703 12.3350811,18.8403784 12.3350811,17.9268649 C12.3350811,17.7787568 12.2150811,17.6587568 12.066973,17.6587568 C11.9188649,17.6587568 11.7988649,17.7787568 11.7988649,17.9268649 C11.7988649,18.5501081 11.2902162,19.0760541 10.6875135,19.0760541 L10.1129189,19.0760541 C9.50102703,19.0760541 8.96372973,18.5387568 8.96372973,17.9268649 L8.96372973,10.0365946 L17.1221081,10.0365946 C17.2702162,10.0365946 17.3902162,9.91659459 17.3902162,9.76848649 C17.3902162,5.2447027 13.7853514,1.81010811 8.96372973,1.68578378 L8.96372973,1.68578378 Z"
            id="Combined-shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Insurance;
