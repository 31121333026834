"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectYearlyMileages = exports.selectWinterTiresRatesChanged = exports.selectVendorTermsPROL = exports.selectVendorTermsPRHP = exports.selectVendorTermsCMOL = exports.selectVendorTermsCMFL = exports.selectVendor = exports.selectServiceRatesNames = exports.selectServiceRates = exports.selectSelectedVendorTermsId = exports.selectSelectedVendorTermsFromAll = exports.selectSelectedMatrixSingle = exports.selectSelectedMatrix = exports.selectProcessVariances = exports.selectLeasingTermsUpdating = exports.selectLeasePeriods = exports.selectIsTermsCMOLLoaded = exports.selectIsServiceRateModalOpen = exports.selectIsServiceMatrixNew = exports.selectIsNewMatrix = exports.selectIsLoadingVendorInfo = exports.selectIsLoadingSingleMatrix = exports.selectIsGettingVendor = exports.selectIsGettingTermsPROL = exports.selectIsGettingTermsCMOL = exports.selectIsGettingTermsCMFL = exports.selectIsGettingServiceNames = exports.selectIsGettingFeatures = exports.selectHighestYearlyMileagesAndPeriods = exports.selectDropdownOptionsForServices = exports.selectAllVendorTermsPROL = exports.selectAllVendorTermsPRHP = exports.selectAllVendorTermsCMOL = exports.selectAllVendorTermsCMFL = void 0;
var _utils = require("@northstar/core/utils");
var _reselect = require("reselect");
var arrayUtils = _interopRequireWildcard(require("../../utils/array-utils"));
var vendorTermsUtils = _interopRequireWildcard(require("../../utils/vendor-terms-utils"));
var _vendorDashboardTermsConstants = require("./vendorDashboardTermsConstants");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const {
  getPeriodsAndMileages
} = vendorTermsUtils;
const selectVendorTermsState = state => state.vendorDashboard.terms;
const selectGettingTermsHP = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('gettingTermsPRHP'));
const selectVendorTermsPROL = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('termsPROL').toJS());
exports.selectVendorTermsPROL = selectVendorTermsPROL;
const selectIsTermsCMOLLoaded = () => (0, _reselect.createSelector)(selectVendorTermsCMOL(), state => state.yearlyMileages.length > 0);
exports.selectIsTermsCMOLLoaded = selectIsTermsCMOLLoaded;
const selectLeasingTermsUpdating = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('updatingTerms'));
exports.selectLeasingTermsUpdating = selectLeasingTermsUpdating;
const selectServiceRatesNames = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('serviceNames').toJS().map(name => ({
  value: name,
  label: name
})).sort(arrayUtils.createAlphabeticalSorter({
  property: 'label'
})));
exports.selectServiceRatesNames = selectServiceRatesNames;
const selectWinterTiresRatesChanged = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('winterTiresRatesChanged'));
exports.selectWinterTiresRatesChanged = selectWinterTiresRatesChanged;
const selectIsServiceRateModalOpen = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('isNewServiceRateOpen'));
exports.selectIsServiceRateModalOpen = selectIsServiceRateModalOpen;
const selectSelectedMatrix = () => (0, _reselect.createSelector)(selectVendorTermsPROL(), state => state.selectedMatrix);
exports.selectSelectedMatrix = selectSelectedMatrix;
const selectVendor = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('vendor').toJS());
exports.selectVendor = selectVendor;
const selectProcessVariances = () => (0, _reselect.createSelector)(selectVendor(), vendor => vendor === null || vendor === void 0 ? void 0 : vendor.processVariances);
exports.selectProcessVariances = selectProcessVariances;
const selectIsGettingTermsPROL = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('gettingTermsPROL'));
exports.selectIsGettingTermsPROL = selectIsGettingTermsPROL;
const selectVendorTermsPRHP = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('termsPRHP').toJS());
exports.selectVendorTermsPRHP = selectVendorTermsPRHP;
const selectIsGettingVendor = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('gettingVendor'));
exports.selectIsGettingVendor = selectIsGettingVendor;
const selectIsLoadingVendorInfo = () => (0, _reselect.createSelector)(selectIsGettingTermsPROL(), selectGettingTermsHP(), selectIsGettingVendor(), (gettingPL, gettingHP, gettingVendor) => gettingPL || gettingHP || gettingVendor);
exports.selectIsLoadingVendorInfo = selectIsLoadingVendorInfo;
const selectYearlyMileages = () => (0, _reselect.createSelector)(selectSelectedVendorTermsFromAll(), terms => terms === null || terms === void 0 ? void 0 : terms.yearlyMileages);
exports.selectYearlyMileages = selectYearlyMileages;
const selectLeasePeriods = () => (0, _reselect.createSelector)(selectSelectedVendorTermsFromAll(), terms => terms === null || terms === void 0 ? void 0 : terms.leasePeriods);
exports.selectLeasePeriods = selectLeasePeriods;
const selectServiceRates = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('rates').toJS());
exports.selectServiceRates = selectServiceRates;
const selectIsNewMatrix = () => (0, _reselect.createSelector)(selectSelectedVendorTermsFromAll(), selectedVendorTerms => selectedVendorTerms && selectedVendorTerms.winterTiresNames.length !== selectedVendorTerms[_vendorDashboardTermsConstants.RATES_TYPE_WINTER_TIRES].length);
exports.selectIsNewMatrix = selectIsNewMatrix;
const selectIsGettingServiceNames = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('gettingServiceRates'));
exports.selectIsGettingServiceNames = selectIsGettingServiceNames;
const selectSelectedMatrixSingle = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('selectedMatrix'));
exports.selectSelectedMatrixSingle = selectSelectedMatrixSingle;
const selectIsLoadingSingleMatrix = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('loadingMatrixSingle'));
exports.selectIsLoadingSingleMatrix = selectIsLoadingSingleMatrix;
const selectIsServiceMatrixNew = () => (0, _reselect.createSelector)(selectServiceRatesNames(), selectSelectedMatrixSingle(), (serviceNames, selected) => selected && !serviceNames.find(service => service.value === selected));
exports.selectIsServiceMatrixNew = selectIsServiceMatrixNew;
const selectDropdownOptionsForServices = () => (0, _reselect.createSelector)(selectServiceRatesNames(), selectSelectedMatrixSingle(), selectIsServiceMatrixNew(), (serviceNames, selected, isNew) => isNew ? serviceNames.concat({
  label: selected,
  value: selected
}).sort(arrayUtils.createAlphabeticalSorter({
  property: 'label'
})) : serviceNames);
exports.selectDropdownOptionsForServices = selectDropdownOptionsForServices;
const selectVendorTermsCMOL = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('termsCMOL').toJS());
exports.selectVendorTermsCMOL = selectVendorTermsCMOL;
const selectHighestYearlyMileagesAndPeriods = () => (0, _reselect.createSelector)(selectVendorTermsPROL(), selectVendorTermsCMOL(), (termsPROL, termsCMOL) => {
  const {
    yearlyMileages,
    leasePeriods
  } = getPeriodsAndMileages({
    termsPROL,
    termsCMOL
  });
  const mileages = yearlyMileages.sort();
  const periods = leasePeriods.sort();
  return {
    mileages,
    periods
  };
});
exports.selectHighestYearlyMileagesAndPeriods = selectHighestYearlyMileagesAndPeriods;
const selectIsGettingTermsCMOL = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('gettingTermsCMOL'));
exports.selectIsGettingTermsCMOL = selectIsGettingTermsCMOL;
const selectVendorTermsCMFL = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('termsCMFL').toJS());
exports.selectVendorTermsCMFL = selectVendorTermsCMFL;
const selectIsGettingTermsCMFL = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('gettingTermsCMFL'));
exports.selectIsGettingTermsCMFL = selectIsGettingTermsCMFL;
const selectIsGettingFeatures = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('isGettingFeatures'));
exports.selectIsGettingFeatures = selectIsGettingFeatures;
const selectAllVendorTermsPROL = validity => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('allTermsPROL').get(validity).toJS());
exports.selectAllVendorTermsPROL = selectAllVendorTermsPROL;
const selectAllVendorTermsPRHP = validity => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('allTermsPRHP').get(validity).toJS());
exports.selectAllVendorTermsPRHP = selectAllVendorTermsPRHP;
const selectAllVendorTermsCMOL = validity => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('allTermsCMOL').get(validity).toJS());
exports.selectAllVendorTermsCMOL = selectAllVendorTermsCMOL;
const selectAllVendorTermsCMFL = validity => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('allTermsCMFL').get(validity).toJS());
exports.selectAllVendorTermsCMFL = selectAllVendorTermsCMFL;
const selectSelectedVendorTermsId = () => (0, _reselect.createSelector)(selectVendorTermsState, state => state.get('selectedVendorTermsId'));
exports.selectSelectedVendorTermsId = selectSelectedVendorTermsId;
const selectSelectedVendorTermsFromAll = () => (0, _reselect.createSelector)(selectAllVendorTermsPROL(_utils.VALIDITY_NOT_EXPIRED), selectSelectedVendorTermsId(), (termsPROL, selectedVendorTermsId) => termsPROL.find(_ref => {
  let {
    vendorTermsId
  } = _ref;
  return vendorTermsId === selectedVendorTermsId;
}));
exports.selectSelectedVendorTermsFromAll = selectSelectedVendorTermsFromAll;