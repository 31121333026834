import { ISxStyles } from 'src/theming/theme.type';

export const styles: ISxStyles = {
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableContainer: {
    width: '100%',
    boxSizing: 'border-box',
  },
  tableRoot: {
    '&.MuiTable-root': {
      borderCollapse: 'separate',
    },
  },
  pagination: ({ typography, fontSizes, colors }) => ({
    '& .MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-displayedRows':
      {
        fontFamily: typography.main,
        fontSize: fontSizes.extraSmall,
        color: colors.custom.fontPrimary,
      },
  }),
};
