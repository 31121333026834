import { InputBase, Grid } from '@mui/material';

import Search from '../Icons/Search';
import Close from '../Icons/Close';
import MAFIcon from '../MAFIcon/MAFIcon';
import MAFButton from '../MAFButton/MAFButton';

import { styles } from './MAFSearchField.styles';
import { propTypes } from './MAFSearchField.propTypes';

export interface IMAFSearchFieldProps {
  onClick: () => void;
  onClear: () => void;
  value: string;
  placeholder?: string;
}

const MAFSearchField: React.FC<IMAFSearchFieldProps> = ({
  onClick,
  onClear,
  value,
  placeholder,
  ...props
}) => (
  <Grid container>
    <Grid item container xs sx={styles.inputContainer} alignItems="center">
      <Grid item xs>
        <InputBase
          placeholder={placeholder}
          sx={[styles.inputRoot, styles.inputInput]}
          value={value}
          autoComplete="off"
          {...props}
        />
      </Grid>
      <Grid item sx={styles.iconBox}>
        {value && <MAFIcon Icon={Close} sx={styles.iconClear} onClick={onClear} />}
      </Grid>
    </Grid>
    <Grid item>
      <MAFButton sx={styles.button} type="submit" color="primaryPR" onClick={onClick} isCentered>
        <MAFIcon Icon={Search} sx={styles.iconSearch} />
      </MAFButton>
    </Grid>
  </Grid>
);

MAFSearchField.propTypes = propTypes;

export default MAFSearchField;
