import { requestHandler } from '@northstar/core-ui/utils';
const { kycDomain, vendorCaseDomain } = requestHandler;

export const updateKyc = ({ requestBody }) =>
  kycDomain.UPDATE_KYC.call({
    body: requestBody,
  });

export const createKycLink = ({ requestBody }) =>
  kycDomain.CREATE_KYC_LINK.call({
    body: requestBody,
  });

export const getKycStatus = ({ organisationNumber }) =>
  kycDomain.GET_KYC_STATUS.call({
    urlParams: { organisationNumber },
  });

export const getKycExpiration = ({ organisationNumber }) =>
  kycDomain.GET_KYC_EXPIRATION.call({
    urlParams: { organisationNumber },
  });

export const validateKycInformation = ({ organisationNumber, tokenId }) =>
  kycDomain.VALIDATE_KYC_INFORMATION.call({
    urlParams: { organisationNumber, tokenId },
  });

export const demoKycForm = ({ requestBody = {} }) =>
  vendorCaseDomain.SUBMIT_KYC_DEMO.call({ body: requestBody });
