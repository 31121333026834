import { ZIndex } from '@mui/material/styles/zIndex';

import { ISxStyles } from '../../theming/theme.type';

const getTransition = (zIndex: ZIndex) => ({
  zIndex: zIndex.appBar + 1,
  transition: 'all 1s',
  opacity: 0,
});

export const styles: ISxStyles = {
  container: {
    textAlign: 'center',
  },
  subText: ({ colors }) => ({
    ...colors.custom.stepper.intro.subText,
    margin: '20px 0px 30px',
  }),
  buttons: ({ spacing, breakpoints }) => ({
    marginTop: spacing(8),
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.only('xs')]: {
      flexDirection: 'column-reverse',
    },
  }),
  stepContainer: ({ colors }) => ({
    borderTop: `1px solid ${colors.alpha(colors.solid.black, 0.1)}`,
    position: 'relative',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
  transitionText: ({ zIndex }) => ({
    ...getTransition(zIndex),
    transform: 'translateY(-200px)',
  }),
  transition: ({ zIndex }) => ({
    ...getTransition(zIndex),
    border: 'none',
  }),
  transitionFirstStep: {
    opacity: 1,
    backgroundColor: 'transparent',
  },
};
