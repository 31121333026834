import { useState } from 'react';
import { Box, Button } from '@mui/material';

import MAFDialog from '../MAFDialog/MAFDialog';
import MAFTypography from '../MAFTypography/MAFTypography';
import { MAFTextField, TMAFTextFieldOnChangeEvent } from '../MAFTextField';
import MAFButton from '../MAFButton/MAFButton';
import {
  VerySad,
  VerySadFilled,
  VeryHappy,
  VeryHappyFilled,
  Thanks,
  Sad,
  SadFilled,
  Neutral,
  NeutralFilled,
  Happy,
  HappyFilled,
} from '../Icons';

import { styles } from './MAFFeedbackSurvey.styles';
import { propTypes } from './MAFFeedbackSurvey.propTypes';

enum Smiles {
  NONE,
  VERY_SAD,
  SAD,
  NEUTRAL,
  HAPPY,
  VERY_HAPPY,
}

export enum DialogPages {
  FEEDBACK = 'FEEDBACK',
  THANK_YOU = 'THANK_YOU',
}

export interface IMAFFeedbackSurveyProps {
  onSend: (activeOption: Smiles, fieldValue: string) => void;
  isOpen?: boolean;
  onClose?: () => void;
  dialogTitle: React.ReactChild;
  textAreaPlaceholder?: string;
  buttonText: React.ReactChild;
  thankYouTitle: React.ReactChild;
  thankYouText: React.ReactChild;
  initialDialogPage?: DialogPages;
}

const MAFFeedbackSurvey: React.FC<IMAFFeedbackSurveyProps> = ({
  buttonText,
  dialogTitle,
  onSend,
  thankYouText,
  thankYouTitle,
  isOpen,
  onClose,
  textAreaPlaceholder,
  initialDialogPage,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [activeOption, setActiveOption] = useState(Smiles.NONE);
  const [fieldValue, setFieldValue] = useState('');
  const [dialogPage, setDialogPage] = useState(initialDialogPage || DialogPages.FEEDBACK);

  const toggleOpen = (activeValue?: Smiles) => {
    if (activeValue) setActiveOption(activeValue);
    if (onClose !== undefined && isOpen !== undefined) {
      onClose();
    } else {
      setIsDialogOpen(!isDialogOpen);
    }
  };

  const handleSubmit = () => {
    onSend(activeOption, fieldValue);
    setDialogPage(DialogPages.THANK_YOU);
  };

  const handleTextChange = (e: TMAFTextFieldOnChangeEvent) => {
    setFieldValue(e.target.value);
  };

  const buttonArray = [
    {
      smile: Smiles.VERY_SAD,
      defaultClass: styles.verySad,
      defaultIcon: <VerySad />,
      filledClass: styles.verySadFilled,
      filledIcon: <VerySadFilled />,
    },
    {
      smile: Smiles.SAD,
      defaultClass: styles.sad,
      defaultIcon: <Sad />,
      filledClass: styles.sadFilled,
      filledIcon: <SadFilled />,
    },
    {
      smile: Smiles.NEUTRAL,
      defaultClass: styles.neutral,
      defaultIcon: <Neutral />,
      filledClass: styles.neutralFilled,
      filledIcon: <NeutralFilled />,
    },
    {
      smile: Smiles.HAPPY,
      defaultClass: styles.happy,
      defaultIcon: <Happy />,
      filledClass: styles.happyFilled,
      filledIcon: <HappyFilled />,
    },
    {
      smile: Smiles.VERY_HAPPY,
      defaultClass: styles.veryHappy,
      defaultIcon: <VeryHappy />,
      filledClass: styles.veryHappyFilled,
      filledIcon: <VeryHappyFilled />,
    },
  ];

  return (
    <>
      <MAFDialog
        header={
          dialogPage === DialogPages.THANK_YOU && (
            <Box sx={styles.thankYouLogoWrapper}>
              <Thanks />
            </Box>
          )
        }
        title={dialogPage === DialogPages.FEEDBACK ? dialogTitle : thankYouTitle}
        message={dialogPage === DialogPages.THANK_YOU ? thankYouText : undefined}
        open={isOpen || isDialogOpen}
        onClose={toggleOpen}
      >
        {dialogPage === DialogPages.FEEDBACK && (
          <Box sx={styles.dialogContent}>
            <Box sx={styles.dialogSmilesWrapper}>
              {buttonArray.map((button) => (
                <Button
                  key={button.smile}
                  onClick={() => setActiveOption(button.smile)}
                  sx={styles.button}
                >
                  {activeOption === button.smile ? (
                    <Box sx={button.filledClass}>{button.filledIcon}</Box>
                  ) : (
                    <Box sx={button.defaultClass}>{button.defaultIcon}</Box>
                  )}
                </Button>
              ))}
            </Box>
            <Box sx={styles.textAreaWrapper}>
              <MAFTextField
                textFieldClass={styles.textField}
                placeholder={textAreaPlaceholder}
                onChange={handleTextChange}
                multiline
              />
            </Box>
            <MAFButton onClick={handleSubmit} color="primaryPR">
              {buttonText}
            </MAFButton>
          </Box>
        )}
      </MAFDialog>
      {isOpen === undefined && (
        <>
          <MAFTypography variant="label1">
            {dialogPage === DialogPages.FEEDBACK ? dialogTitle : thankYouTitle}
          </MAFTypography>
          <Box sx={styles.smilesWrapper}>
            {buttonArray.map((button) => (
              <Box
                key={button.smile}
                sx={[button.defaultClass, styles.iconButton]}
                onClick={() => toggleOpen(button.smile)}
              >
                {button.defaultIcon}
              </Box>
            ))}
          </Box>
        </>
      )}
    </>
  );
};

MAFFeedbackSurvey.propTypes = propTypes;

export default MAFFeedbackSurvey;
