"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = supportSaga;
exports.getActiveSystemMessage = getActiveSystemMessage;
var _effects = require("redux-saga/effects");
var _authSelectors = require("../auth/authSelectors");
var _localStorageUtils = require("../../utils/localStorage-utils");
var _reduxSagaUtils = require("../../utils/redux-saga-utils");
var supportAPI = _interopRequireWildcard(require("./supportApi"));
var _supportReducer = require("./supportReducer");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function refreshSystemMessage(_ref) {
  let {
    payload: {
      message
    }
  } = _ref;
  return function* () {
    const username = yield (0, _effects.select)((0, _authSelectors.selectUsername)());
    const localSystemMessageArr = (0, _localStorageUtils.getValueByKey)(_localStorageUtils.KEYS.SYSTEM_MESSAGE) || [];
    const localSystemMessageObj = localSystemMessageArr.find(item => item.username === username) || {};
    const isNewSystemMessage = localSystemMessageObj.message !== message;
    (0, _localStorageUtils.setValueByKey)(_localStorageUtils.KEYS.SYSTEM_MESSAGE, [...localSystemMessageArr.filter(item => item.username !== username), {
      username,
      message,
      display: isNewSystemMessage
    }]);
    yield (0, _effects.put)((0, _supportReducer.getActiveSystemMessageResponse)({
      message,
      display: isNewSystemMessage
    }));
  }();
}
function* getActiveSystemMessage() {
  try {
    const {
      message
    } = yield (0, _effects.call)(supportAPI.getActiveSystemMessage);
    if (!message) {
      yield (0, _effects.put)((0, _supportReducer.getActiveSystemMessageResponse)({
        message,
        display: false
      }));
      return;
    }
    const username = yield (0, _effects.select)((0, _authSelectors.selectUsername)());
    const localSystemMessageArr = (0, _localStorageUtils.getValueByKey)(_localStorageUtils.KEYS.SYSTEM_MESSAGE) || [];
    const userExistInLocalStore = localSystemMessageArr.find(item => item.username === username);
    const localSystemMessageObj = userExistInLocalStore || {};
    const isNewSystemMessage = localSystemMessageObj.message !== message;
    if (isNewSystemMessage || !userExistInLocalStore) {
      yield (0, _effects.put)((0, _supportReducer.refreshSystemMessageRequest)({
        message
      }));
    } else if (userExistInLocalStore) {
      yield (0, _effects.put)((0, _supportReducer.getActiveSystemMessageResponse)({
        message: localSystemMessageObj.message,
        display: localSystemMessageObj.display
      }));
    }
  } catch (e) {
    yield (0, _effects.put)((0, _supportReducer.getActiveSystemMessageResponse)(e));
  }
}
const getSystemMessage = (0, _reduxSagaUtils.createApiSaga)({
  endpoint: supportAPI.getSystemMessage,
  successActions: [_supportReducer.getSystemMessageResponse],
  errorActions: [_supportReducer.getSystemMessageResponse]
});
function updateSystemMessage(_ref2) {
  let {
    payload
  } = _ref2;
  return function* () {
    try {
      const {
        message,
        active
      } = payload;
      const body = {
        message,
        active
      };
      const response = yield (0, _effects.call)(supportAPI.updateSystemMessage, body);
      yield (0, _effects.put)((0, _supportReducer.updateSystemMessageResponse)(response));
      yield (0, _effects.put)((0, _supportReducer.getActiveSystemMessageRequest)());
    } catch (e) {
      yield (0, _effects.put)((0, _supportReducer.updateSystemMessageResponse)(e));
    }
  }();
}
function* supportSaga() {
  yield (0, _effects.takeLatest)(_supportReducer.refreshSystemMessageRequest, refreshSystemMessage);
  yield (0, _effects.takeLatest)(_supportReducer.getActiveSystemMessageRequest, getActiveSystemMessage);
  yield (0, _effects.takeLatest)(_supportReducer.getSystemMessageRequest, getSystemMessage);
  yield (0, _effects.takeLatest)(_supportReducer.updateSystemMessageRequest, updateSystemMessage);
}