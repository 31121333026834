"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const getCommonStyles = _ref => {
  let {
    fontSizes,
    typography
  } = _ref;
  return {
    fontFamily: typography.main,
    fontSize: fontSizes.small
  };
};
const getRoot = _ref2 => {
  let {
    typography
  } = _ref2;
  return {
    fontFamily: typography.main,
    maxWidth: '72%',
    padding: '6px 18px',
    marginTop: 1,
    width: 'fit-content',
    display: 'table',
    // width: 'fit-content' effect for IE browsers.
    position: 'relative',
    wordBreak: 'break-word'
  };
};
const styles = exports.styles = {
  default: _ref3 => {
    let {
      typography,
      colors
    } = _ref3;
    return _objectSpread(_objectSpread({}, getRoot({
      typography
    })), {}, {
      background: colors.alpha(colors.solid.black, 0.1),
      borderRadius: 1
    });
  },
  sidebar: _ref4 => {
    let {
      typography
    } = _ref4;
    return _objectSpread(_objectSpread({}, getRoot({
      typography
    })), {}, {
      borderRadius: 4
    });
  },
  externalReply: _ref5 => {
    let {
      colors
    } = _ref5;
    return _objectSpread({}, colors.custom.chatMessage.externalReply);
  },
  internalReply: _ref6 => {
    let {
      colors
    } = _ref6;
    return _objectSpread({}, colors.custom.chatMessage.internalReply);
  },
  own: _ref7 => {
    let {
      colors
    } = _ref7;
    return _objectSpread(_objectSpread({}, colors.custom.chatMessage.own), {}, {
      marginLeft: 'auto'
    });
  },
  avatar: _ref8 => {
    let {
      colors,
      typography: {
        main
      }
    } = _ref8;
    return _objectSpread(_objectSpread({}, colors.custom.chatMessage.own), {}, {
      position: 'absolute',
      width: 30,
      height: 30,
      fontFamily: main,
      textTransform: 'uppercase',
      top: -15,
      left: -15
    });
  },
  name: _ref9 => {
    let {
      fontSizes,
      typography
    } = _ref9;
    return _objectSpread({
      marginBottom: '3px'
    }, getCommonStyles({
      fontSizes,
      typography
    }));
  },
  dateWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
    marginTop: '12px',
    marginBottom: '12px',
    '&:first-child': {
      marginTop: 0
    }
  },
  date: _ref10 => {
    let {
      typography,
      fontSizes
    } = _ref10;
    return {
      fontFamily: typography.main,
      fontSize: fontSizes.tiny
    };
  },
  comment: _ref11 => {
    let {
      typography,
      fontSizes
    } = _ref11;
    return _objectSpread(_objectSpread({}, getCommonStyles({
      fontSizes,
      typography
    })), {}, {
      lineHeight: '24px'
    });
  },
  roleWrapper: _ref12 => {
    let {
      typography,
      fontSizes
    } = _ref12;
    return {
      marginTop: '3px',
      fontFamily: typography.main,
      width: 'fit-content',
      display: 'table',
      // width: 'fit-content' effect for IE browsers.
      lineHeight: '18px',
      fontSize: fontSizes.extraSmall,
      padding: '0 18px',
      wordBreak: 'break-word'
    };
  },
  tooltip: _ref13 => {
    let {
      colors
    } = _ref13;
    return {
      '.MuiTooltip-tooltip': {
        backgroundColor: colors.solid.whiteGray,
        color: colors.solid.cadedBlue,
        fontSize: 16
      }
    };
  }
};