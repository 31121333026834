import {
  DomainUtils,
  PROCESS_VARIANCES,
  USER_ROLE_VENDOR_ADMIN,
  USER_ROLE_VENDOR_EMPLOYEE,
  VENDOR_TYPE_IMPORTER,
} from '@northstar/core';
import { auth, cases, notifications, asset } from '@northstar/core-ui/modules';
import {
  setRedirectPath,
  selectIdentityRequest,
} from '@northstar/core-ui/modules/auth/authReducer';
import { push } from 'connected-react-router';
import { takeLatest, put, call, select } from 'redux-saga/effects';

import Paths from 'paths';

import { runSagas, stopSagas, restartCaseManagementSagas } from '../../store';
import { clearCaseState, restartCaseSagas } from '../caseDetail/caseDetailReducer';

function* resetState() {
  yield put(clearCaseState());
  yield put(cases.filterClear());
}

function* runAllSagas() {
  yield call(runSagas);
}

function* stopAllSagas() {
  yield call(stopSagas);
}

function* restartSagasForCase() {
  yield call(restartCaseManagementSagas);
}

export function* redirectToLandingPage({ payload }) {
  const { role, refresh = false, vendor } = payload;
  const redirectAction = refresh
    ? (route) => {
        window.location.pathname = route;
      }
    : (route) => put(push(route));

  const isVendorTypeImporter = yield select(auth.selectIsVendorTypeImporter());
  switch (role) {
    case USER_ROLE_VENDOR_EMPLOYEE:
      yield redirectAction(Paths.LANDING.path);
      break;
    case USER_ROLE_VENDOR_ADMIN:
      if ((vendor && vendor.type === VENDOR_TYPE_IMPORTER) || (!vendor && isVendorTypeImporter)) {
        yield call(redirectImporterToLandingPage, { vendor, redirectAction });
      } else {
        yield redirectAction(Paths.LANDING.path);
      }
      break;
    default:
      yield redirectAction(Paths.VENDOR_AUTH.path);
      break;
  }
}

export function* redirectImporterToLandingPage({ vendor, redirectAction }) {
  const currentVendor = vendor || (yield select(auth.selectVendor()));

  const canHaveAssets =
    DomainUtils.isProcessVarianceEnabled(
      PROCESS_VARIANCES.PRIVATE_OL,
      currentVendor.process_configuration.process_variances,
      {},
    ) ||
    DomainUtils.isProcessVarianceEnabled(
      PROCESS_VARIANCES.COMMERCIAL_OL,
      currentVendor.process_configuration.process_variances,
      {},
    );

  yield redirectAction(
    Paths.VENDOR_DASHBOARD.buildPath({
      tab: canHaveAssets ? Paths.VENDOR_DASHBOARD.tabs.assets : Paths.VENDOR_DASHBOARD.tabs.users,
    }),
  );
}

function* redirectToPath({ payload }) {
  const {
    redirectPath: { path, searchParams },
    refresh = false,
  } = payload;

  if (!refresh) {
    yield put(
      push({
        pathname: path,
        search: searchParams && `?${searchParams}`,
      }),
    );
  } else {
    const querySearchParams = searchParams ? `?${searchParams}` : '';
    window.location = `${path}${querySearchParams}`;
  }
}

function* redirectToForbidden() {
  yield put(push(Paths.FORBIDDEN.path));
}

function redirectToLogin() {
  window.location = Paths.BASE.path;
}

function* redirectToResource({ payload: { resourceId } }) {
  yield call(redirectToPath, {
    payload: {
      redirectPath: {
        path: Paths.CASE.buildPath({ resourceId }),
      },
      refresh: true,
    },
  });
}

function* redirectToResourceWithIdentitySwitch({ payload }) {
  const { resourceId, identityId, assetVersionId, isAsset, isCommercial } = payload;
  const path = isAsset
    ? Paths.VENDOR_DASHBOARD.buildPath({
        tab: 'assets',
        assetId: resourceId,
        assetVersionId,
      })
    : Paths.CASE.buildPath({ resourceId });

  const searchParams = isAsset && Paths.VENDOR_DASHBOARD.buildSearch({ isCommercial });
  yield put(setRedirectPath({ path, searchParams }));
  yield put(selectIdentityRequest({ id: identityId }));
}

export function* checkForIdentitySwitch({ payload }) {
  try {
    const {
      params: { assetId, assetVersionId, isCommercial, resourceId },
    } = payload;

    if (assetId || resourceId) {
      const { otherIdentity } = yield assetId
        ? call(asset.checkAssetIdentity, {
            assetId,
            isCommercial,
          })
        : call(cases.casesApi.checkCaseIdentity, {
            resourceId,
          });

      if (otherIdentity) {
        const identityId = otherIdentity.id;
        yield put(
          auth.setIdentityForSwitching({
            resourceId: resourceId || assetId,
            assetVersionId,
            identityId,
            isAsset: Boolean(assetId),
            isCommercial,
          }),
        );
      }
    }

    yield put(auth.checkForIdentitySwitchResponse());
  } catch (e) {
    yield put(auth.checkForIdentitySwitchResponse(e));
  }
}

export default function* authSaga() {
  yield takeLatest(auth.resetAppState, resetState);
  yield takeLatest(auth.runSagas, runAllSagas);
  yield takeLatest(auth.stopSagas, stopAllSagas);
  yield takeLatest(restartCaseSagas, restartSagasForCase);
  yield takeLatest(auth.redirectToForbidden, redirectToForbidden);
  yield takeLatest(auth.redirectToLogin, redirectToLogin);
  yield takeLatest(auth.redirectToHome, redirectToLandingPage);
  yield takeLatest(auth.redirectToPath, redirectToPath);
  yield takeLatest(notifications.redirectFromNotification, redirectToResource);
  yield takeLatest(auth.redirectWithIdentitySwitch, redirectToResourceWithIdentitySwitch);
  yield takeLatest(auth.checkForIdentitySwitchRequest, checkForIdentitySwitch);
}
