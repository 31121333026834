import { ReactChild, ReactNode, FC, ElementType, SVGProps } from 'react';
import { Card, CardHeader, CardContent, Box, SxProps, Theme } from '@mui/material';

import MAFIcon from '../MAFIcon/MAFIcon';
import { handleCustomSx } from '../../utils';

import { styles } from './MAFCard.styles';
import { propTypes } from './MAFCard.propTypes';

interface IMAFCardProps {
  Icon?: ElementType<SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  title?: ReactChild;
  children: ReactNode;
  sx?: SxProps<Theme>;
}

const MAFCard: FC<IMAFCardProps> = ({ Icon, onClick, title, children, sx }) => {
  const Title = () => (
    <Box sx={styles.title}>
      <Box component="span" sx={styles.child}>
        {Icon && (
          <MAFIcon
            Icon={Icon}
            sx={styles.icon}
            onClick={onClick}
            iconButtonClass={styles.iconButton}
          />
        )}
      </Box>
      <Box component="span">{title}</Box>
      <Box component="span" sx={styles.child} />
    </Box>
  );

  return (
    <Card sx={[styles.card, ...handleCustomSx(sx)]}>
      <CardHeader sx={styles.header} title={<Title />} />
      <CardContent sx={styles.cardContent}>{children}</CardContent>
    </Card>
  );
};

MAFCard.propTypes = propTypes;

export default MAFCard;
