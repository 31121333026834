"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isInteriorColour = exports.isExteriorColour = void 0;
var _equipmentConstants = require("../equipmentConstants");
const isExteriorColour = _ref => {
  let {
    category,
    subCategory
  } = _ref;
  return category === _equipmentConstants.EQUIPMENT_CATEGORY_COLOUR && subCategory !== _equipmentConstants.EQUIPMENT_SUB_CATEGORY_INTERIOR_COLOUR;
};
exports.isExteriorColour = isExteriorColour;
const isInteriorColour = _ref2 => {
  let {
    category,
    subCategory
  } = _ref2;
  return category === _equipmentConstants.EQUIPMENT_CATEGORY_COLOUR && subCategory === _equipmentConstants.EQUIPMENT_SUB_CATEGORY_INTERIOR_COLOUR;
};
exports.isInteriorColour = isInteriorColour;