const BabyPram: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-action/Baby-prom/Darkblue">
        <g id="Icon" transform="translate(3.000000, 2.000000)">
          <path
            d="M12.5,0 L10.5,0 C10.2,0 10,0.2 10,0.5 L10,7.5 C10,7.8 10.2,8 10.5,8 L17.5,8 C17.8,8 18,7.8 18,7.5 L18,5.5 C18,2.5 15.5,0 12.5,0 L12.5,0 Z M6,20 C7.1045695,20 8,19.1045695 8,18 C8,16.8954305 7.1045695,16 6,16 C4.8954305,16 4,16.8954305 4,18 C4,19.1045695 4.8954305,20 6,20 Z M14,20 C15.1045695,20 16,19.1045695 16,18 C16,16.8954305 15.1045695,16 14,16 C12.8954305,16 12,16.8954305 12,18 C12,19.1045695 12.8954305,20 14,20 Z M17.5,9 L3,9 L3,6.5 C3,6.2 2.8,6 2.5,6 L0.5,6 C0.2,6 0,6.2 0,6.5 C0,6.8 0.2,7 0.5,7 L2,7 L2,9.5 C2,9.5 2,9.5 2,9.6 L2,14.5 C2,14.8 2.2,15 2.5,15 L17.5,15 C17.8,15 18,14.8 18,14.5 L18,9.5 C18,9.2 17.8,9 17.5,9 L17.5,9 Z M17,14 L3,14 L3,10 L17,10 L17,14 L17,14 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default BabyPram;
