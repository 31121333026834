import { memo, useState } from 'react';

import { IMAFTableData, IMAFTableClasses, IMAFTableField } from '../../MAFTable.types';
import MAFTableRowMobile from '../MAFTableRowMobile/MAFTableRowMobile';
import MAFTableRow from '../MAFTableRow/MAFTableRow';
import MAFCellMobile from '../MAFCellMobile/MAFCellMobile';
import MAFCell from '../MAFCell/MAFCell';
import { handleCustomSx, useScreenSize } from '../../../../utils';
import { MAFCheckbox } from '../../../MAFCheckbox';

import { styles } from './MAFRow.styles';
import { propTypes } from './MAFRow.propTypes';

type TGetCellValueParams<T extends IMAFTableData> = {
  item: T;
  name: string;
  subName?: string;
  fallbackName?: string;
};

function getCellValue<T extends IMAFTableData>({
  item,
  name,
  fallbackName,
  subName,
}: TGetCellValueParams<T>) {
  if (subName) {
    return item[name]?.[subName] || (fallbackName && item[fallbackName]);
  }
  return item[name] || (fallbackName && item[fallbackName]);
}

export interface IMAFRowProps<T extends IMAFTableData> {
  item: { data: T; hasCheckbox?: boolean };
  fields: IMAFTableField<T>[];
  hasCheckbox?: boolean;
  isWithPagination?: boolean;
  rowsPerPage?: number;
  page?: number;
  selectCase?: (item: T) => void;
  onItemClick?: (item: T) => void;
  tableClasses?: IMAFTableClasses;
  isUplift?: boolean;
  isSelected?: boolean;
}

const MAFRow = <T extends IMAFTableData>({
  item,
  tableClasses,
  fields,
  hasCheckbox = false,
  selectCase,
  onItemClick,
  isUplift = false,
  isSelected = false,
}: IMAFRowProps<T>) => {
  const { isMobile } = useScreenSize();
  const RowComponent = isMobile ? MAFTableRowMobile : MAFTableRow;
  const CellComponent = isMobile ? MAFCellMobile : MAFCell;
  const tableClass = isMobile ? tableClasses?.rowMobile : tableClasses?.rowDesktop;
  const [isChecked, setIsChecked] = useState(isSelected);
  const isArchivable = Boolean(item.hasCheckbox);

  const handleClick = () => {
    setIsChecked((isPrevState) => !isPrevState);
    selectCase && selectCase(item.data);
  };

  const handleItemClick = () => {
    onItemClick && onItemClick(item.data);
    isArchivable && !onItemClick && handleClick();
  };

  const itemData = item.data;

  type TItemData = typeof itemData;

  return (
    <RowComponent
      sx={[styles.hover, ...handleCustomSx(tableClass)]}
      {...(isMobile ? { isUplift } : {})}
    >
      {hasCheckbox && (
        <CellComponent sx={styles.checkbox} isUplift={isUplift}>
          {isArchivable && <MAFCheckbox checked={isChecked} onClick={handleClick} />}
        </CellComponent>
      )}

      {fields.map(
        ({
          name,
          subName,
          fallbackName,
          component,
          shouldStopPropagation,
          renderFunc = ({ value }) => value,
        }) => {
          const renderedValue = renderFunc({
            value: getCellValue<TItemData>({ item: itemData, name, subName, fallbackName }),
            item: itemData,
          });

          return (
            <CellComponent
              key={`${name}-${itemData.id}`}
              {...item}
              shouldStopPropagation={shouldStopPropagation}
              isUplift={isUplift}
              handleItemClick={handleItemClick}
            >
              {component ? component({ value: renderedValue, isUplift }) : renderedValue}
            </CellComponent>
          );
        },
      )}
    </RowComponent>
  );
};

MAFRow.propTypes = propTypes;

// casting MAFRow type because React.memo does not infer it by default.
export default memo(MAFRow) as unknown as typeof MAFRow;
