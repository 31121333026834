import { ISxStyles } from '../../../theming/theme.type';

interface IProps {
  isSide: boolean;
  topOffset?: number;
}

export const useStyles = ({ isSide, topOffset }: IProps): ISxStyles => ({
  mobileProgressStepsParentContainer: ({ zIndex }) => ({
    position: 'fixed',
    zIndex: zIndex.mobileStepper + 1,
  }),
  withAppBar: {
    height: 'calc(100% - 64px) !important',
  },
  mobileProgressStepsContainer: ({ colors }) => ({
    position: 'fixed',
    left: 0,
    top: () => {
      if (topOffset) return `${topOffset}px`;
      return isSide ? 'initial' : '0';
    },
    bottom: isSide ? '0' : 'initial',
    width: isSide ? '320px' : '100%',
    height: isSide ? '100%' : 'auto',
    backgroundColor: colors.solid.white,
  }),
  mobileProgressStepsWrapper: {
    overflowY: 'auto',
    maxHeight: '100vh',
  },
  closeButton: ({ colors }) => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: '24px 0',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: colors.danske.white,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: colors.custom.stepper.inactive,
    },
  }),
});
