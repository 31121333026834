const Print: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-action/Print/Darkblue" fill="#003755">
        <g id="Icon" transform="translate(3.000000, 2.000000)">
          <path
            d="M3.0015054,16.0023804 L1.42698192,16.0023804 C0.65090403,16.0023804 0,15.368234 0,14.6121365 L0,6.3902439 C0,5.63414634 0.65090403,5 1.42698192,5 L3.0015054,5 L3.0015054,0 L11.6411683,0 L15.0039062,2.90243903 L15.0039062,5 L16.5730181,5 C17.349096,5 18,5.65853659 18,6.41463415 L18,14.6365267 C18,15.3926243 17.349096,16.0267706 16.5730181,16.0267706 L15.0039062,16.0267706 L15.0039062,20 L3.0015054,20 L3.0015054,16.0023804 Z M14,19.004224 L14,13 L4,13 L4,19.004224 L14,19.004224 Z M16.1504425,15 C16.5988201,15 17,14.6643357 17,14.2447552 L17,6.75524475 C17,6.35664336 16.6224189,6 16.1504425,6 L15.0029449,6 L15.0029449,7.00556946 L3.00628662,7.00556946 L3.00628662,6 L1.84955752,6 C1.40117994,6 1,6.33566433 1,6.75524475 L1,14.2447552 C1,14.6433566 1.37758112,15 1.84955752,15 L3.00628662,15 L3.00628662,12.005188 L15.0029449,12.005188 L15.0029449,15 L16.1504425,15 Z M14,6 L14,3.02484131 L11.0000001,3.02484131 L11.0000001,1 L4,1 L4,6 L14,6 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Print;
