"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const rowBase = {
  padding: '10px 20px'
};
const styles = exports.styles = {
  profileDetails: _ref => {
    let {
      colors
    } = _ref;
    return {
      display: 'flex',
      borderBottom: "1px solid ".concat(colors.alpha(colors.solid.white, 0.48)),
      fontWeight: 'bold',
      minHeight: 50,
      '& > div:nth-child(1n)': _objectSpread(_objectSpread({}, colors.custom.body), {}, {
        alignItems: 'center',
        display: 'flex'
      }),
      '& > div:nth-child(2n)': _objectSpread(_objectSpread({
        textAlign: 'left',
        fontWeight: 'normal',
        width: '100%'
      }, colors.custom.contentSecondary), {}, {
        flexWrap: 'nowrap'
      }),
      '&:last-child': {
        border: 0
      }
    };
  },
  singleRow: _objectSpread(_objectSpread({}, rowBase), {}, {
    alignItems: 'flex-start'
  }),
  label: _objectSpread(_objectSpread({}, rowBase), {}, {
    minWidth: 450,
    width: 450
  }),
  topicRow: _ref2 => {
    let {
      colors
    } = _ref2;
    return _objectSpread(_objectSpread(_objectSpread({}, rowBase), colors.custom.headers), {}, {
      display: 'flex',
      textAlign: 'left',
      fontWeight: 'bold'
    });
  },
  toolbar: _ref3 => {
    let {
      colors
    } = _ref3;
    return _objectSpread({
      fontWeight: 'normal',
      width: '100%',
      padding: '1px'
    }, colors.custom.contentSecondary);
  },
  toolbarContainer: {
    padding: '10px 20px'
  },
  toolbarTitle: _ref4 => {
    let {
      fontSizes
    } = _ref4;
    return {
      fontSize: fontSizes.medium,
      maxWidth: '280px !important'
    };
  },
  deleteIcon: _ref5 => {
    let {
      colors
    } = _ref5;
    return {
      marginLeft: 1,
      '& g': {
        fill: colors.custom.primaryPR.solid.background,
        color: colors.custom.primaryPR.solid.background
      }
    };
  }
};