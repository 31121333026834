import { Box, Drawer, List, ListItem } from '@mui/material';

import { handleCustomSx, useScreenSize } from 'src/utils';
import { IMAFTabsProps, MAFTabs } from 'src/components/MAFTabs';

import { BurgerMenu, Close } from '../../Icons';
import MAFIcon from '../../MAFIcon/MAFIcon';
import MAFTypography from '../../MAFTypography/MAFTypography';
import { TDrawerMenuItem } from '../MAFAppBar.types';

import { styles } from './MAFMenu.styles';
import { propTypes } from './MAFMenu.propTypes';
import MAFMenuItem from './components/MAFMenuItem';

export interface IMAFMenuProps {
  menuIconLabel?: React.ReactChild;
  additionalComponent?: React.ReactNode;
  isDrawerOpen: boolean;
  drawerMenuItems?: TDrawerMenuItem[];
  drawerTopTabsProps?: IMAFTabsProps;
  onDrawerClose?: () => void;
}

const MAFMenu: React.FC<IMAFMenuProps> = ({
  menuIconLabel,
  additionalComponent,
  isDrawerOpen,
  drawerMenuItems = [],
  drawerTopTabsProps,
  onDrawerClose,
}) => {
  const { isMobile } = useScreenSize();

  const handleDrawerClose = (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onDrawerClose && onDrawerClose();
  };

  return (
    <Box sx={styles.menuContainer}>
      {additionalComponent}
      <MAFIcon
        Icon={isDrawerOpen ? Close : BurgerMenu}
        sx={[isDrawerOpen ? styles.closeIcon : styles.burgerIcon]}
        iconButtonClass={styles.menuButton}
        onClick={handleDrawerClose}
      >
        <MAFTypography variant="body3">{menuIconLabel}</MAFTypography>
      </MAFIcon>
      <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose} sx={styles.drawer}>
        <List sx={styles.list}>
          <ListItem sx={[styles.closeBtn, isMobile && styles.mobileMenuTop]}>
            {!isMobile && <MAFIcon Icon={Close} onClick={handleDrawerClose} />}
          </ListItem>
          {isMobile && drawerTopTabsProps?.tabs && (
            <MAFTabs
              {...drawerTopTabsProps}
              variant="scrollable"
              scrollButtons={true}
              allowScrollButtonsMobile
              // eslint-disable-next-line react/jsx-no-useless-fragment
              customDivider={<></>}
              centered={true}
              sx={[styles.tabs, ...handleCustomSx(drawerTopTabsProps?.sx)]}
            />
          )}

          {drawerMenuItems.map((item, index) => (
            <MAFMenuItem {...item} index={index} />
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

MAFMenu.propTypes = propTypes;

export default MAFMenu;
