import {
  FC,
  useState,
  useRef,
  useEffect,
  ReactChild,
  RefObject,
  FocusEvent,
  MouseEvent,
  ChangeEvent,
} from 'react';
import { Box, Grid, Menu, MenuItem, SxProps, Theme } from '@mui/material';

import { useScreenSize, handleCustomSx } from '../../utils';
import MAFIcon from '../MAFIcon/MAFIcon';
import Add from '../Icons/Add';
import MAFTypography from '../MAFTypography/MAFTypography';
import MAFHelperText from '../MAFHelperText/MAFHelperText';
import MAFButton from '../MAFButton/MAFButton';

import { styles } from './MAFCardSelect.styles';
import { propTypes } from './MAFCardSelect.propTypes';

export type TMAFCardSelectOptionsValue = string | number;

export interface IMAFCardSelectOptions {
  label: string;
  value: TMAFCardSelectOptionsValue;
}

export interface IMAFCardSelectProps {
  title: ReactChild;
  defaultTitle: ReactChild;
  onChange: (value: TMAFCardSelectOptionsValue) => void;
  onDelete?: () => void;
  onFocus?: (e: FocusEvent<HTMLButtonElement>) => void;
  value?: TMAFCardSelectOptionsValue;
  options: IMAFCardSelectOptions[];
  sx?: SxProps<Theme>;
  isError?: boolean;
  helperText?: ReactChild;
}

const MAFCardSelect: FC<IMAFCardSelectProps> = ({
  value,
  onChange,
  onDelete,
  onFocus,
  title,
  defaultTitle,
  options,
  sx,
  isError = false,
  helperText,
}) => {
  const { isDesktop } = useScreenSize();

  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLDivElement>>(null);
  const [hasSelected, setHasSelected] = useState(Boolean(value));

  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef() as RefObject<HTMLDivElement>;

  useEffect(() => {
    setAnchorEl(ref.current);
  }, [ref.current]);

  useEffect(() => {
    if (!value) {
      setHasSelected(false);
      setIsOpen(false);
    }
  }, [value]);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleSelect = (selectedValue: TMAFCardSelectOptionsValue) => {
    onChange(selectedValue);
    setHasSelected(true);
    handleClose();
  };

  const handleDelete = (e: MouseEvent<HTMLDivElement>) => {
    if (value && onDelete) {
      e.stopPropagation();
      onDelete();
    }
  };

  const selectedOption = options.find((item) => item.value === value) || false;

  const isInitState = !value && !hasSelected && !isOpen;

  return (
    <>
      <Box sx={styles.container}>
        <MAFButton
          sx={[
            styles.card,
            selectedOption && styles.cardSelected,
            isError && styles.error,
            ...handleCustomSx(sx),
          ]}
          onFocus={onFocus}
          onClick={handleOpen}
        >
          <Grid container sx={styles.buttonContainer} ref={ref}>
            <Grid item onClick={handleDelete}>
              <MAFIcon sx={[styles.icon, hasSelected && styles.iconRotated]} Icon={Add} />
            </Grid>
            <Grid item sx={styles.text}>
              <MAFTypography
                variant="body1"
                sx={[styles.title, isInitState && styles.initStateTitle]}
              >
                {!isInitState ? title : defaultTitle}
              </MAFTypography>
              {selectedOption && (
                <MAFTypography variant="body3" sx={styles.selected}>
                  {selectedOption.label}
                </MAFTypography>
              )}
            </Grid>
          </Grid>
        </MAFButton>
        {isDesktop ? (
          <Menu
            open={isOpen}
            anchorEl={anchorEl}
            sx={[styles.paper, styles.list, styles.rootPaper]}
            container={anchorEl}
            disablePortal
            disableAutoFocusItem
            onClose={handleClose}
          >
            {options.map((option, index) => (
              <MenuItem
                key={index}
                sx={[styles.menuItem, index % 2 !== 0 && styles.menuItemOdd]}
                onClick={() => handleSelect(option.value)}
              >
                <MAFTypography variant="body1">{option.label}</MAFTypography>
              </MenuItem>
            ))}
          </Menu>
        ) : (
          <Box
            component="select"
            onBlur={handleClose}
            sx={styles.select}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => handleSelect(e.target.value)}
          >
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </Box>
        )}
      </Box>
      {helperText && (
        <MAFHelperText sx={styles.helperText} isError={isError}>
          {helperText}
        </MAFHelperText>
      )}
    </>
  );
};

MAFCardSelect.propTypes = propTypes;

export default MAFCardSelect;
