"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const styles = exports.styles = {
  container: _ref => {
    let {
      typography: {
        baseFont
      },
      colors
    } = _ref;
    return _objectSpread(_objectSpread({}, baseFont), {}, {
      padding: '16px 5px',
      background: colors.custom.tabs.background,
      color: colors.custom.tabs.color,
      width: '100%',
      cursor: 'pointer',
      boxShadow: "0 1px 2px 0 ".concat(colors.alpha(colors.solid.black, 0.15)),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': _objectSpread({}, colors.custom.contentSecondary)
    });
  },
  active: _ref2 => {
    let {
      colors
    } = _ref2;
    return _objectSpread({}, colors.custom.contentSecondary);
  }
};