import { ISxStyles } from '@asfi/maf';

export const styles: ISxStyles = {
  column: {
    display: 'flex',
    flexDirection: 'column',
    '&:not(:last-child)': {
      paddingBottom: 3,
    },
  },
  title: {
    textAlign: 'left',
    marginBottom: 2,
  },
  row: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 2,
  },
  line: ({ colors }) => ({
    borderBottom: `1px solid ${colors.solid.gainsboro}`,
  }),
  indented: {
    marginLeft: 1,
  },
};
