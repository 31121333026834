"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PRODUCT_TYPES = exports.PRODUCT_SUB_TYPES = exports.FILTER_FIELD_STATUS_SELECTED = exports.FILTER_FIELD_STATUSES = exports.FILTER_FIELD_PRODUCT_TYPE_SELECTED = exports.FILTER_FIELD_PRODUCT_SUB_TYPE_SELECTED = exports.FILTER_FIELD_IMPORTER_NAME = exports.FILTER_FIELD_EMPLOYEE_SELECTED = exports.FILTER_FIELD_EMPLOYEE = exports.FILTER_FIELD_DATE_TO_SELECTED = exports.FILTER_FIELD_DATE_OPTION_SELECTED = exports.FILTER_FIELD_DATE_FROM_SELECTED = exports.FAMILY_RELATION_OTHER = exports.FAMILY_RELATION_COUPLE = exports.DATE_OPTION_PAST_WEEK = exports.DATE_OPTION_PAST_MONTH = exports.DATE_OPTION_CUSTOM = exports.DATE_OPTION_ANY_TIME = exports.CASES_ONLY_NON_DELETED = exports.CASES_ONLY_DELETED = exports.CASES_INCLUDE_ALL = void 0;
var _caseDetailConstants = require("../caseDetailConstants");
const FILTER_FIELD_STATUS_SELECTED = exports.FILTER_FIELD_STATUS_SELECTED = 'status_selected';
const FILTER_FIELD_PRODUCT_TYPE_SELECTED = exports.FILTER_FIELD_PRODUCT_TYPE_SELECTED = 'product_type_selected';
const FILTER_FIELD_PRODUCT_SUB_TYPE_SELECTED = exports.FILTER_FIELD_PRODUCT_SUB_TYPE_SELECTED = 'product_sub_type_selected';
const FILTER_FIELD_IMPORTER_NAME = exports.FILTER_FIELD_IMPORTER_NAME = 'importer_name';
const FILTER_FIELD_DATE_OPTION_SELECTED = exports.FILTER_FIELD_DATE_OPTION_SELECTED = 'date_option_selected';
const FILTER_FIELD_DATE_FROM_SELECTED = exports.FILTER_FIELD_DATE_FROM_SELECTED = 'date_from_selected';
const FILTER_FIELD_DATE_TO_SELECTED = exports.FILTER_FIELD_DATE_TO_SELECTED = 'date_to_selected';
const FILTER_FIELD_EMPLOYEE_SELECTED = exports.FILTER_FIELD_EMPLOYEE_SELECTED = 'employee_selected';
const FILTER_FIELD_EMPLOYEE = exports.FILTER_FIELD_EMPLOYEE = 'employees';
const FILTER_FIELD_STATUSES = exports.FILTER_FIELD_STATUSES = 'statuses';
const DATE_OPTION_ANY_TIME = exports.DATE_OPTION_ANY_TIME = 'any_time';
const DATE_OPTION_PAST_WEEK = exports.DATE_OPTION_PAST_WEEK = 'past_week';
const DATE_OPTION_PAST_MONTH = exports.DATE_OPTION_PAST_MONTH = 'past_month';
const DATE_OPTION_CUSTOM = exports.DATE_OPTION_CUSTOM = 'custom';
const FAMILY_RELATION_COUPLE = exports.FAMILY_RELATION_COUPLE = 'MARRIED_COHABITANT';
const FAMILY_RELATION_OTHER = exports.FAMILY_RELATION_OTHER = 'OTHER';
const PRODUCT_TYPES = exports.PRODUCT_TYPES = [{
  value: _caseDetailConstants.PRODUCT_TYPE_PRIVATE,
  translationKey: "cases.type.dynamic.".concat(_caseDetailConstants.PRODUCT_TYPE_PRIVATE)
}, {
  value: _caseDetailConstants.PRODUCT_TYPE_COMMERCIAL,
  translationKey: "cases.type.dynamic.".concat(_caseDetailConstants.PRODUCT_TYPE_COMMERCIAL)
}];
const PRODUCT_SUB_TYPES = exports.PRODUCT_SUB_TYPES = [{
  value: _caseDetailConstants.PRODUCT_SUB_TYPE_FINANCIAL_LEASE,
  translationKey: "cases.subType.dynamic.".concat(_caseDetailConstants.PRODUCT_SUB_TYPE_FINANCIAL_LEASE)
}, {
  value: _caseDetailConstants.PRODUCT_SUB_TYPE_HIRE_PURCHASE,
  translationKey: "cases.subType.dynamic.".concat(_caseDetailConstants.PRODUCT_SUB_TYPE_HIRE_PURCHASE)
}, {
  value: _caseDetailConstants.PRODUCT_SUB_TYPE_OPERATIONAL_LEASE,
  translationKey: "cases.subType.dynamic.".concat(_caseDetailConstants.PRODUCT_SUB_TYPE_OPERATIONAL_LEASE)
}];
const CASES_INCLUDE_ALL = exports.CASES_INCLUDE_ALL = 'INCLUDE_ALL';
const CASES_ONLY_DELETED = exports.CASES_ONLY_DELETED = 'ONLY_DELETED';
const CASES_ONLY_NON_DELETED = exports.CASES_ONLY_NON_DELETED = 'ONLY_NON_DELETED';