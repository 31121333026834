const mapFilters = (filters) => {
  const remappedFilters = {};
  Object.keys(filters).forEach((filterKey) => {
    const arrayWithDuplicates = filters[filterKey]
      .map((filterValue) =>
        filterValue.value
          ? filterValue
          : {
              label: filterValue.name ? filterValue.name : filterValue,
              value: filterValue.name ? filterValue.name : filterValue,
            },
      )
      .sort(({ label: a }, { label: b }) => {
        if (typeof a === 'string') {
          return a.localeCompare(b);
        }
        return a - b;
      });
    // removing duplicate values
    remappedFilters[filterKey] = arrayWithDuplicates.filter(
      ({ value }, index) => index === arrayWithDuplicates.findIndex((el) => el.value === value),
    );
  });
  return remappedFilters;
};

const mapAppliedFilters = ({ appliedFilters }) =>
  Object.keys(appliedFilters).reduce((prev, filterKey) => {
    const currentFilter = appliedFilters[filterKey];
    if (Array.isArray(currentFilter) && currentFilter.length > 0) {
      return { ...prev, [filterKey]: currentFilter.map((filter) => filter.value) };
    } else if (!Array.isArray(currentFilter)) {
      return { ...prev, [filterKey]: currentFilter.value };
    }
    return prev;
  }, {});

export const requestMappers = {
  mapAppliedFilters,
};

export const responseMappers = {
  mapFilters,
};
