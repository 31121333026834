const User: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-action/User-profile/Darkblue">
        <g id="Icon" transform="translate(4.000000, 2.000000)">
          <path
            d="M14.9965,19.0008 L13.0155,19.0008 L13.0155,14.8788 C13.0155,14.6028 12.7925,14.3788 12.5155,14.3788 C12.2395,14.3788 12.0165,14.6028 12.0165,14.8788 L12.0165,19.0008 L3.9795,19.0008 L3.9795,14.8788 C3.9795,14.6028 3.7565,14.3788 3.4805,14.3788 C3.2045,14.3788 2.9805,14.6028 2.9805,14.8788 L2.9805,19.0008 L0.9995,19.0008 L0.9995,13.8728 C0.9995,12.1558 2.9175,10.9978 3.5045,10.6848 C4.8085,9.9888 6.1525,9.6918 7.9975,9.6918 C9.8445,9.6918 11.1875,9.9888 12.4915,10.6848 C13.0795,10.9978 14.9965,12.1558 14.9965,13.8728 L14.9965,19.0008 Z M4.1515,4.8458 C4.1515,4.5538 4.1905,4.2718 4.2525,3.9978 L6.3165,2.8838 L11.6845,3.8078 C11.7785,4.1398 11.8445,4.4838 11.8445,4.8458 C11.8445,6.9668 10.1195,8.6928 7.9975,8.6928 C5.8765,8.6928 4.1515,6.9668 4.1515,4.8458 L4.1515,4.8458 Z M12.9615,9.8028 C12.1975,9.3958 11.4215,9.1168 10.5695,8.9368 C11.9305,8.0778 12.8435,6.5708 12.8435,4.8458 C12.8435,3.0418 11.8415,1.4838 10.3755,0.6498 C10.2595,0.5828 10.1445,0.5148 10.0235,0.4578 C9.9105,0.4058 9.7965,0.3618 9.6805,0.3188 C9.5105,0.2548 9.3375,0.2018 9.1605,0.1568 C9.0635,0.1328 8.9695,0.1058 8.8705,0.0878 C8.5855,0.0358 8.2955,-0.0002 7.9975,-0.0002 C7.6955,-0.0002 7.4015,0.0348 7.1135,0.0898 C7.0045,0.1098 6.9015,0.1418 6.7945,0.1698 C6.6265,0.2138 6.4605,0.2618 6.2995,0.3238 C6.1655,0.3738 6.0365,0.4308 5.9085,0.4928 C5.7995,0.5458 5.6945,0.6048 5.5895,0.6658 C5.4335,0.7558 5.2815,0.8488 5.1375,0.9548 C5.1125,0.9738 5.0895,0.9958 5.0655,1.0148 C3.9105,1.9008 3.1515,3.2808 3.1515,4.8458 C3.1515,6.5708 4.0645,8.0788 5.4265,8.9368 C4.5745,9.1168 3.7985,9.3958 3.0345,9.8028 C2.1215,10.2898 0.000499999995,11.6618 0.000499999995,13.8728 L0.000499999995,19.5008 C0.000499999995,19.7768 0.2235,19.9998 0.4995,19.9998 L15.4965,19.9998 C15.7725,19.9998 15.9955,19.7768 15.9955,19.5008 L15.9955,13.8728 C15.9955,11.6618 13.8745,10.2898 12.9615,9.8028 L12.9615,9.8028 Z"
            id="path-1"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default User;
