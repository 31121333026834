import { ISxStyles } from '../../theming/theme.type';

interface IProps {
  isWithValue?: boolean;
}

export const useStyles = ({ isWithValue }: IProps): ISxStyles => ({
  container: {
    marginTop: '10px',
    display: 'flex',
    '& :first-child': {
      paddingLeft: 0,
    },
  },
  row: ({ colors }) => ({
    '&.MuiFormGroup-root': {
      justifyContent: 'space-between',
      '& .Mui-focusVisible > div': {
        boxShadow: `${colors.alpha(colors.solid.blue, 0.7)} 0px 0px 0px 1px`,
        zIndex: 1,
      },
    },
    '& .Mui-focusVisible > div': {
      boxShadow: `${colors.alpha(colors.solid.blue, 0.7)} 0px 0px 0px 1px`,
      zIndex: 1,
    },
  }),
  rowError: ({ colors }) => ({
    '&.MuiFormGroup-root': {
      '& > span > div': {
        borderLeftColor: colors.solid.red,
        borderRightColor: colors.solid.red,
      },
      '& span:first-child > div': {
        borderTopColor: colors.solid.red,
      },
      '& span:last-child > div': {
        borderBottomColor: colors.solid.red,
      },
    },
  }),
  rowErrorHorizontal: ({ colors }) => ({
    '&.MuiFormGroup-root': {
      '& > span > div': {
        borderTopColor: colors.solid.red,
        borderBottomColor: colors.solid.red,
      },
      '& span:first-child > div': {
        borderLeftColor: colors.solid.red,
      },
      '& span:last-child > div': {
        borderRightColor: colors.solid.red,
      },
    },
  }),
  rowElement: ({ colors }) => ({
    '&.MuiRadio-root': {
      flex: 1,
      padding: 0,
      ...colors.custom.radio.select,
      color: isWithValue ? colors.custom.radio.selected.color : colors.custom.radio.select.color,
      transition: 'color 300ms ease',
      borderRadius: 0,
      // Workaround collapsing borders
      margin: '-1px 0 0 -1px',
    },
  }),
  vertical: ({ breakpoints }) => ({
    '&.MuiRadio-root': {
      width: 420,
      [breakpoints.only('xs')]: {
        width: 272,
      },
    },
  }),
  fullWidth: {
    '&.MuiRadio-root': {
      width: '100%',
    },
  },
  labelRoot: ({ fontSizes }) => ({
    '&.MuiFormLabel-root': {
      fontSize: fontSizes.extraSmall,
      display: 'inline-block',
      marginBottom: 1,
    },
  }),
  input: {
    marginTop: '10px',
  },
});
